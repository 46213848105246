/* eslint-disable no-mixed-spaces-and-tabs */
import { Collapse, Grid, Group, Text } from '@mantine/core';
import Icon from 'assets/icons';
import { useCheckPosLinks } from 'hooks/api/discounts/useCheckPosLinks';
import { useGetDiscountById } from 'hooks/api/discounts/useGetDiscountById';
import { useUpdateDiscount } from 'hooks/api/discounts/useUpdateDiscount';
import { currency } from 'lib';
import { useModals } from 'providers/ModalsProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Breadcrumbs,
	Box,
	Button,
	CancelButton,
	DateField,
	Divider,
	Field,
	Formik,
	FormPriceInput,
	FormTextInput,
	ImageUploader,
	MultiSelectField,
	SelectBrand,
	SelectField,
	Stack,
	TextField,
	ViewContainer,
} from '../../../components';
import { SwitchField } from '../../../components/SwitchField';
import { DiscountEnum } from '../../../constants/discount.enum';
import { useGetPaymentMethodsByBrandId } from '../../../hooks/api/paymentmethods/useGetPaymentMethods';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { toFormikValidationSchema } from '../../../lib/helpers';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from '../../../providers/AppStateProvider';
import { useNotification } from '../../../providers/NotificationProvider';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';
import {
	UpdateDiscountSchema,
	UpdateDiscountSchemaWithBC,
} from '../../../schemas/discount.schema';
import { DeleteDiscountModal } from './Modals';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const discountTypeOptions = [
	{ value: DiscountEnum.TYPE.FIXED_AMOUNT, label: 'Fixed Amount ($)' },
	{ value: DiscountEnum.TYPE.FIXED_PERCENT, label: 'Fixed Percentage (%)' },
	{ value: DiscountEnum.TYPE.VAR_AMOUNT, label: 'Variable Amount ($)' },
	{ value: DiscountEnum.TYPE.VAR_PERCENT, label: 'Variable Percentage (%)' },
];

const ViewDiscounts = () => {
	useDisableSelectCompany();
	const { id } = useParams();
	const { selectedBrand, isCompanyBCIntegrated } = useAppState();
	const { data: paymentMethodsData } = useGetPaymentMethodsByBrandId({
		brandId: selectedBrand?.toString() || '0',
		isFiltered: true,
		discount_id: Number(id ?? '0'),
	});
	const navigate = useNavigate();
	const {permissions} = useAuth()

	const { data: discount } = useGetDiscountById(Number(id ?? '0'));

	const [setEndDate, setSetEndDate] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (discount && discount.end_date) {
			setSetEndDate(true);
		}
	}, [discount]);

	const { mutate } = useUpdateDiscount();

	const notify = useNotification();
	const modal = useModals();

	const onDeleteClickHandler = async () => {
		useCheckPosLinks(Number(id ?? '0')).then((links) => {
			modal.showModal({
				title: '',
				body: (
					<DeleteDiscountModal
						close={modal.closeModal}
						data={{ pos: links.pos, id: [id || '0'] }}
					/>
				),
			});
		});
	};

	if (!discount) {
		return null;
	}

	return (
		<Formik
			enableReinitialize
			initialValues={{
				id: discount?.id || '',
				name: discount?.name || '',
				description: discount?.description || '',
				discount_code: discount?.discount_code || '',
				scope: discount?.scope || DiscountEnum.SCOPE.ORDER,
				brand_id: discount?.brand_id || 0,
				type: discount?.type || DiscountEnum.TYPE.FIXED_AMOUNT,
				auto_apply_on:
					discount?.auto_apply_on ||
					DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PAY_METHOD,
				is_stackable: discount?.is_stackable || false,
				start_date: discount?.start_date
					? new Date(discount?.start_date)
					: null,
				end_date: discount?.end_date ? new Date(discount?.end_date) : null,
				is_manager_only: discount?.is_manager_only || false,
				value: Number(discount?.value) || 0,
				percent: Number(discount?.percent) || 0,
				image_url: discount?.image_url || '',
				payment_method_ids: discount?.payment_method_ids || [],
			}}
			validationSchema={
				isCompanyBCIntegrated
					? toFormikValidationSchema(UpdateDiscountSchemaWithBC)
					: toFormikValidationSchema(UpdateDiscountSchema)
			}
			onSubmit={(values) => {
				if (
					values.type === DiscountEnum.TYPE.FIXED_PERCENT &&
					values?.percent < 1
				) {
					notify.error('Fixed Percentage cannot be lesser than 1');
				} else if (
					values.type === DiscountEnum.TYPE.FIXED_AMOUNT &&
					values.value < 0.1
				) {
					notify.error('Fixed Amount cannot be lesser than 0.01');

				} else if (
					values['auto_apply_on'] !== 'manual' &&
					values.payment_method_ids.length === 0
				) {
					notify.error('Please select some payment methods');
				} else if (
					values['auto_apply_on'] === 'specific_pay_method' &&
					[
						DiscountEnum.TYPE.VAR_AMOUNT,
						DiscountEnum.TYPE.VAR_PERCENT,
					].includes(values['type'])
				) {
					notify.error('Please change the discount type');
				} else {
					mutate(values, {
						onSuccess: () => {
							navigate(ConfigurationRoutes.Discount, {}, true);
						},
						onError: (error) => {
							notify.error(`Error creating discounts`);
						},
					});
				}
			}}
		>
			{({ values, errors, setFieldValue, submitForm, dirty, isSubmitting }) => {
				(permissions?.includes("Discounts.Edit")) &&
					useStopNavigateDirtyForm(dirty);
				return (
					<ViewContainer>
						<Box style={{ position: 'relative', marginBottom: '20px' }}>
							<Group w="100%" position="apart">
								<Breadcrumbs
									items={[
										{
											label: 'Discount',
											onClick: () => navigate(ConfigurationRoutes.Discount),
										},
										{ label: values?.name ?? '' },
									]}
								/>
									<RequirePermissions permissions={['Discounts.Delete']}>
									<Button
											color="red"
											leftIcon={<Icon name="delete" />}
											onClick={onDeleteClickHandler}
										>
											Delete
										</Button>
									</RequirePermissions>
							</Group>
							
						</Box>
						<Divider/>
						<fieldset disabled={!permissions?.includes("Discounts.Edit") 
								|| isSubmitting} >
						
						<Box style={{ width: '100%', marginTop:'20px' }}>
							<Stack direction="row" gap={30}>
									<Stack direction="column" gap={10}>
										<Field
										width="600px"
											as={FormTextInput}
											label="Discount Name"
											isRequired={true}
											name="name"
											placeholder="Example: Staff Discount"
												error={
												(errors['name'] as string) && 'This field is required.'
											}
										/>
										<Field
										width="600px"
											as={FormTextInput}
											label="Description"
											name="description"
											/>
										<Field
										width="600px"
											as={FormTextInput}
											hidden={!isCompanyBCIntegrated}
											label="BC Discount Code"
											isRequired
											name="discount_code"
											placeholder="Example: 333001"
												error={
												(errors['discount_code'] as string) &&
												'This field is required.'
											}
										/>
										<SelectField
										labelWidth={160}
											label="Auto applied on"
										width={450}
											required
											name="auto_apply_on"
											value={values?.auto_apply_on}
											data={[
												{ value: 'manual', label: 'Manual' },
												{
													value: 'specific_pay_method',
													label: 'Specific Payment Method',
												},
											]}
											onChange={(value) => {
												setFieldValue('auto_apply_on', value);
												setFieldValue('type', DiscountEnum.TYPE.FIXED_AMOUNT);
											}}
										/>
										<Collapse
											in={values['auto_apply_on'] === 'specific_pay_method'}
										>
											<MultiSelectField
											width="600px"
												required={
													values['auto_apply_on'] === 'specific_pay_method'
												}
												label="Please specify payment method(s)"
												data={
													paymentMethodsData?.paymentMethods?.map((e: any) => ({
														value: e?.id?.toString(),
														label: e?.name,
													})) || []
												}
												onChange={(values) => {
													setFieldValue(
														'payment_method_ids',
														values.map((value) => +value)
													);
												}}
												value={
													values?.payment_method_ids?.map((e: number) =>
														String(e)
													) || []
												}
											/>
										</Collapse>
										<SelectField
											label="Discount Type"
										labelWidth={160}
										width={450}
											required
											name="type"
											value={values['type']}
											placeholder="Please Select"
											// Options when auto_apply_on = to 'specific_pay_method' , only two choices avaliable due to product requirements.
											data={
												values['auto_apply_on'] === 'specific_pay_method'
													? discountTypeOptions.slice(0, 2)
													: discountTypeOptions
											}
											onChange={(value) => setFieldValue('type', value)}
											bottomSection={
												<Collapse
													in={
														values?.type === DiscountEnum.TYPE.VAR_AMOUNT ||
														values?.type === DiscountEnum.TYPE.VAR_PERCENT
													}
												>
													<Text my="sm" color="dark" weight={500} size="sm">
														Amount to be entered at the time of sale
													</Text>
												</Collapse>
											}
										/>
										<Collapse
											in={
												values?.type === DiscountEnum.TYPE.FIXED_AMOUNT ||
												values?.type === DiscountEnum.TYPE.FIXED_PERCENT
											}
										>
											<FormPriceInput
											width="600px"
												placeholder="Example 0.00"
												precision={2}
												error={
													values.type.includes('percent')
														? errors['percent']
														: errors['value']
												}
												required={true}
												step={values.type.includes('percent') ? 1 : 0.1}
												label={
													discountTypeOptions.find(
														({ value }) => value === values['type']
													)?.label
												}
												value={
													values.type.includes('percent')
														? values.percent === 0
															? ''
															: values.percent
														: values.value === 0 
															? ''
															: currency(values.value, { fromCents: true }).value
												}
												name={
													values.type.includes('percent') ? 'percent' : 'value'
												}
												min={values.type.includes('percent') ? 1 : 0.01}
												onChange={(value) => {
													if (values.type.includes('percent')) {
														setFieldValue(
															'percent',
															value
														);
													} else {
														setFieldValue(
															'value',
															currency(value).intValue
														);
													}
												}}
											/>
										</Collapse>
										<SwitchField
										width="611px"
											required
											label="Stackable"
											name="is_stackable"
											size="lg"
											onLabel="True"
											offLabel="False"
											checked={values?.is_stackable}
											onChange={(e) =>
												setFieldValue('is_stackable', e.currentTarget.checked)
											}
										/>

		
									<DateField
										width="611px"
										required
										label="Start Date"
										value={values?.start_date}
										onChange={(date) => {
											setFieldValue('start_date', date);
										}}
										valueFormat="DD/MM/YYYY"
										error={
											errors['start_date'] ? 'This field is required' : null
										}
									/>
									<SwitchField
										width="611px"
										label="Set End Date"
										size="lg"
										onLabel="True"
										offLabel="False"
										checked={setEndDate}
										onChange={(e) => {
											setSetEndDate(e.currentTarget.checked);
											if (!e.currentTarget.checked) {
												setFieldValue('end_date', null);
											}
										}}
									/>
									<Collapse in={setEndDate}>
										<DateField
											width="611px"
											required
											label="End Date"
											value={values?.end_date}
											onChange={(date) => {
												setFieldValue('end_date', date);
											}}
											valueFormat="DD/MM/YYYY"
											error={
												errors['end_date'] ? 'This field is required' : null
											}
										/>
									</Collapse>
									<SwitchField
										width="611px"
										label="Discount can only be applied by Manager"
										name="is_manager_only"
										size="lg"
										onLabel="True"
										offLabel="False"
										checked={values?.is_manager_only}
										onChange={(e) => {
											setFieldValue('is_manager_only', e.currentTarget.checked);
										}}
									/>
									
									
									<Stack 
										width="100%"
										style={{display:"flex", 
											justifyContent:"flex-start", 
											marginTop:"15px"}}
										>
										<RequirePermissions permissions={['Discounts.Edit']}>
											<Button onClick={() => submitForm()}>Save changes</Button>
										</RequirePermissions>
										<CancelButton
											onClick={() =>
												navigate(ConfigurationRoutes.Payment + '/?tab=discount')
											}
										>
											Cancel
										</CancelButton>
										<Text size="xs">{`* stands for required`}</Text>
									</Stack>
									</Stack>
									<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
											<ImageUploader
												url={values?.image_url}
												onChange={(url) => setFieldValue('image_url', url)}
												inactiveTabs={[
													'Products',
													'Category Groups',
													'Brands',
													'Payment Methods',
													'Advertisements',
												]}
											/>
										<Text align="left" fs="italic" size="xs" color="gray" >
											Recommended Dimensions: W 315px x H 140px
										</Text>
									</Stack>
								</Stack>
							</Box>
						</fieldset>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default ViewDiscounts;

import { Box, Image, Text } from '@mantine/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from '../../../../../assets/icons';
import {
	ActionIcon,
	Div,
	ImageUploader,
	ListGroup,
	Stack,
} from '../../../../../components';
import { wrap } from 'module';

const AddAdvertisement = () => {
	const { values, setFieldValue } = useFormikContext<{
		advertisements: string[];
	}>();
	return (
		<Box>
			<DragDropContext
				onDragEnd={({ source, destination }) => {
					if (source && destination) {
						// Copy values
						const _items = [...values.advertisements];
						// Get item to be removed
						const _item = values?.advertisements[source.index];
						// Remove item
						_items.splice(source.index, 1);
						// Add item
						_items.splice(destination.index, 0, _item);
						// Set new values
						setFieldValue('advertisements', _items);
					}
				}}
			>
				<Droppable droppableId="create-pos-advertistments">
					{(provided) => (
						<Div {...provided.droppableProps} ref={provided.innerRef}>
							{values?.advertisements?.length ? (
								<ListGroup isBordered width="100%">
									{values?.advertisements?.map((e, i) => (
										<Draggable key={e} index={i} draggableId={`${e}-${i}`}>
											{(provided) => (
												<ListGroup.Item
													key={e}
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Stack
														width="100%"
														align="center"
														justify="space-between"
													>
														<Text size="xs" w="210px" truncate>
															{e}
														</Text>
														<Image
															src={e}
															width={160}
															height={90}
															withPlaceholder
														/>
														<Stack>
															<ActionIcon
																onClick={() => {
																	setFieldValue(
																		'advertisements',
																		values?.advertisements?.filter(
																			(_, index) => {
																				return index !== i;
																			}
																		)
																	);
																}}
															>
																<Icon name="delete" />
															</ActionIcon>
															<ActionIcon {...provided.dragHandleProps}>
																<Icon name="draghandle" />
															</ActionIcon>
														</Stack>
													</Stack>
												</ListGroup.Item>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</ListGroup>
							) : null}
							<Div style={{ margin: '15px 0px' }}>
								<ImageUploader
									h="90px"
									w="100%"
									onChange={(url) =>
										setFieldValue(
											'advertisements',
											values?.advertisements.concat([url])
										)
									}
									inactiveTabs={[
										'Payment Methods',
										'Products',
										'Brands',
										'Discounts',
										'Category Groups',
									]}
								/>
								<Text align="center" mt={5} fs="italic" size="xs" color="gray" >
									Recommended Dimensions: W 1366px x H 768px
								</Text>
							</Div>
						</Div>
					)}
				</Droppable>
			</DragDropContext>
		</Box>
	);
};

export default AddAdvertisement;

import React, { useEffect } from 'react';
import { Button, CancelButton, Div, ModalWrapper, Stack } from 'components';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'providers/RouterProvider';
import { useNotification } from 'providers/NotificationProvider';
import styled from '@emotion/styled';
import { useArchiveOutlet, useDeleteOutlet } from 'hooks/outlets';
import { useRemoveOutletFromUsers } from 'hooks/api/users/useRemoveOutletFromUsers';

type Data = {
	id: number;
	name: string;
	brand_id:number;
};

export type ModalProps = {
	close: () => void;
	data?: Data;
};

type DataArray = {
	[key: string]: string[];
};

export type ModalPropsArray = {
	close: () => void;
	data?: DataArray;
};

export const StyledDiv = styled.div`
	background-color: ${({ theme }) => theme.colors.grey05};
	padding: 1rem;
	margin: 1rem 0;

	p {
		color: ${({ theme }) => theme.colors.grey50};
	}

	ul {
		margin-top: 0;
		list-style-type: none;
		padding: 0;
	}
`;

export const DiscardCreationModal = ({ close }: ModalProps) => {
	const navigate = useNavigate();
	return (
		<Div>
			<h3>
				Do you want to continue to edit this page, or discard the changes and
				leave?
			</h3>
			<Stack
				style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
			>
				<Button
					variant="outline"
					color="red"
					type="button"
					leftIcon={<DeleteIcon />}
					onClick={() => {
						close();
						navigate('/configuration/outlets');
					}}
				>
					Discard and leave
				</Button>
				<Button type="button" onClick={close}>
					Stay on this page
				</Button>
			</Stack>
		</Div>
	);
};

export const LinkedEntityModal = ({ close, data }: ModalPropsArray) => {
	return (
		<>
			{data && (
				<ModalWrapper>
					<h3>
						This brand is used in the following places, please unlink before{' '}
						{data.action}:
					</h3>
					<StyledDiv>
						<p>POSes</p>
						<ul>
							{data.pos.map((el, index) => {
								return <li key={index}>{el}</li>;
							})}
						</ul>
					</StyledDiv>
					<StyledDiv>
						<p>USER</p>
						<ul>
							{data.users.map((el, index) => {
								return <li key={index}>{el}</li>;
							})}
						</ul>
					</StyledDiv>
					<Stack
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button type="button" onClick={close}>
							Ok
						</Button>
					</Stack>
				</ModalWrapper>
			)}
		</>
	);
};

export const DeleteEntityModal = ({
	close,
	data = { id: 0, name: '', brand_id:0 },
}: ModalProps) => {
	const [isOutletRemoved, setIsOutletRemoved] = React.useState(false)
	const { mutateAsync: removeOutletFromUsers } = useRemoveOutletFromUsers();
	const { mutateAsync: deleteOneOutlet } = useDeleteOutlet();
	const navigate = useNavigate();
	const toast = useNotification();

	useEffect(() => {
		
		if(isOutletRemoved == true){
			deleteOneOutlet(Number(data.id))
			.then(() => {
				close();
				navigate('/configuration/outlets');
				toast.success('Outlet has been successfully deleted');
			})
			.catch(() => {
				toast.error('An error occured');
			});
		}

	}, [isOutletRemoved])

	const confirmDelete = () => {

		removeOutletFromUsers({brandId: Number(data.brand_id), outletId: Number(data.id)})
			.then(() => {
				setIsOutletRemoved(true)
				toast.success('Successfully removed outlet from existing users');
			})
			.catch(() => {
				toast.error('An error occured');
			});

			
	};
	return (
		<>
			<Div>
				<h3>Are you sure you want to delete?</h3>
				{data && (
					<StyledDiv>
						<p style={{ display: 'inline-flex', paddingRight: '1rem' }}>
							OUTLET
						</p>
						<span>{data.name}</span>
					</StyledDiv>
				)}
				<Stack
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<CancelButton onClick={close}>No, Cancel</CancelButton>
					<Button
						leftIcon={<DeleteIcon />}
						type="button"
						color="red"
						onClick={confirmDelete}
					>
						Yes, Delete
					</Button>
				</Stack>
			</Div>
		</>
	);
};

export const ArchiveEntityModal = ({
	close,
	data = { id: 0, name: '', brand_id:0 },
}: ModalProps) => {
	const { mutateAsync: archiveBrand } = useArchiveOutlet();
	const navigate = useNavigate();
	const toast = useNotification();

	const confirmArchive = () => {
		archiveBrand(data.id)
			.then(() => {
				close();
				navigate('/configuration/outlets/archived');
				toast.success('outlets has been successfully archived');
			})
			.catch(() => {
				toast.error('An error occured');
			});
	};
	return (
		<>
			<Div>
				<h3>Are you sure you want to archive?</h3>
				{data && (
					<StyledDiv>
						<p style={{ display: 'inline-flex', paddingRight: '1rem' }}>
							OUTLET
						</p>
						<span>{data.name}</span>
					</StyledDiv>
				)}
				<Stack
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<CancelButton onClick={close}>No, Cancel</CancelButton>
					<Button type="button" color="primary" onClick={confirmArchive}>
						Yes, Archive
					</Button>
				</Stack>
			</Div>
		</>
	);
};

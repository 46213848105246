import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetPosById(posId?: number | string) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['pos', posId],
		async () =>
			configApi
				.get(`pos/${posId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!posId,
			refetchOnWindowFocus: false,
		}
	);
}

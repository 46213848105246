import { useMutation, useQueryClient } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useUpdateCategoryGroupById() {
	const queryClient = useQueryClient();
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({ id, values }: { id: string | number; values: any }) =>
			configApi
				.put(`/categorygroups/${id}`, values, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: (_, vars) => {
				queryClient.invalidateQueries([
					'category-groups',
					vars?.id?.toString(),
				]);
			},
		}
	);
}

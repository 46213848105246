import React from 'react';
import { Draggable, DraggableProps } from 'react-beautiful-dnd';
import Icon from '../../../../assets/icons';
import {
	ActionIcon,
	Div,
	DivProps,
	ListGroup,
	Tooltip,
	useFormikContext,
} from '../../../../components';
import { useGetCategoryById } from '../../../../hooks';
import RequirePermissions from '../../../../guards/RequirePermissions';

type Props = Omit<DraggableProps, 'children'> & {
	categoryId: string | number;
	leftSectionProps?: Omit<DivProps, 'children'>;
	unlinkHandler?: (categoryId: number | string) => void;
};

const MenuPreviewCategoryItem = ({
	categoryId,
	draggableId,
	isDragDisabled,
	index,
	leftSectionProps,
	unlinkHandler,
}: Props) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const { data: category } = useGetCategoryById(categoryId as string);

	return !category ? null : (
		<Draggable index={index} draggableId={draggableId} isDragDisabled={isDragDisabled}>
			{(provided) => (
				
				<ListGroup.Item
					ref={provided.innerRef}
					{...provided.draggableProps}
					css={(theme) => ({
						backgroundColor:
							categoryId === values?.selectedCategory
								? theme.colors.grey10
								: 'none',
					})}
					leftSection={
						<RequirePermissions permissions={['Categories.Edit']}>
							<Div {...leftSectionProps}>
								<Tooltip label={'Unlink'}>
									<ActionIcon
										onClick={() => {
											if (unlinkHandler) {
												unlinkHandler(categoryId);
											}
										}}
									>
										<Icon name="unlink" />
									</ActionIcon>
								</Tooltip>
							</Div>
						</RequirePermissions>
					}
					rightSection={
						<Tooltip label={'Reorder'}>
							<ActionIcon {...provided.dragHandleProps}>
								<RequirePermissions permissions={['Categories.Edit']}>
										<Icon name="draghandle" />
								</RequirePermissions>
							</ActionIcon>
						</Tooltip>
					}
				>
					<Div
						style={{ width: '100%', textAlign: 'left' }}
						onClick={() => setFieldValue('selectedCategory', categoryId)}
					>
						{category.name}
					</Div>
				</ListGroup.Item>
			)}
		</Draggable>
	);
};

export default MenuPreviewCategoryItem;

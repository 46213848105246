import React from 'react';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	FormTextInput,
	SelectBrand,
	Stack,
	ViewContainer,
} from 'components';
import { Field, Formik } from 'formik';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from '../../../lib/navigate';
import { KitchenGroupSchemaCreate } from 'schemas/kitchengroup.schema';
import { useCreateNewKitchenGroup } from 'hooks/kitchengroups';
import { useNotification } from 'providers/NotificationProvider';
import { useAuth } from 'providers/AuthProvider';

const KitchenGroupsCreate = () => {
	const toast = useNotification();
	const { selectedBrand, setDirty } = useAppState();
	const { user } = useAuth();
	const { mutateAsync: createNewKitchenGroup } = useCreateNewKitchenGroup();
	const navigate = useNavigate();
	return (
		<ViewContainer>
			<Breadcrumbs
				items={[
					{
						label: 'Kitchen Groups',
						onClick: () => navigate('/products/kitchengroups'),
					},
					{
						label: 'Create Kitchen Group',
						onClick: () => navigate('#'),
					},
				]}
			/>
			<Formik
				initialValues={{
					brand_id: selectedBrand ?? 0,
					name: '',
					updated_by: user?.name ?? '',
				}}
				validate={(values) => {
					try {
						KitchenGroupSchemaCreate.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={(values, actions) => {
					createNewKitchenGroup(values, {
						onSuccess: (_) => {
							setDirty(false);
							navigate('/products/kitchengroups', {}, true);
							toast.success('Kitchen Group has been successfully created');
						},
						onError: (err: any) => {
							toast.error(err?.response?.data?.error ?? 'An error occured');
						},
					});
				}}
			>
				{({ values, errors, touched, dirty, setFieldValue, submitForm }) => {
					React.useEffect(() => {
						if (dirty) {
							setDirty(true);
						}

						return () => setDirty(false);
					}, [dirty]);

					React.useEffect(() => {
						setFieldValue('brand_id', selectedBrand);
					}, [selectedBrand]);
					return (
						<Div style={{ marginTop: '20px' }}>
							{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
							{/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
							<SelectBrand />
							<Stack direction="column" gap={10} style={{ marginTop: '20px' }}>
								<Field
									width="611px"
									as={FormTextInput}
									name="name"
									label="Kitchen Group Name"
									isRequired={true}
									maxLength={80}
									error={errors.name && touched.name ? errors.name : null}
								/>
							</Stack>
							<Stack
								width={'100%'}
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									marginTop: '20px',
								}}
							>
								<Button type="submit" onClick={submitForm}>
									Create
								</Button>
								<CancelButton
									onClick={() => navigate('/products/kitchengroups')}
								>
									Cancel
								</CancelButton>
							</Stack>
						</Div>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default KitchenGroupsCreate;

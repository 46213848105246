import React, { useEffect } from 'react';
import { EmptyState, Div, Stack, ViewContainer } from 'components';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useGetBrands } from 'hooks/brands';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from '../../../lib/navigate';
import dayjs from 'dayjs';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Button } from '@mantine/core';
import Icon from '../../../assets/icons';
import RequirePermissions from  '../../../guards/RequirePermissions';

const Brands = () => {
	const { selectedCompany } = useAppState();
	const { data } = useGetBrands(selectedCompany || 0);
	const navigate = useNavigate();

	const getSelectedRow = (row: any) => {
		navigate(`/configuration/brands/${row.original.id}`);
	};

	const breadcrumbsArray = [
		{
			label: 'Brands'
		},
	];

	return (
		<>
			<ViewContainer>
				<Div style={{ position: 'relative', marginBottom: '20px' }}>
					<Breadcrumbs items={breadcrumbsArray} />
					<Stack style={{ position: 'absolute', top: 0, right: 0 }}>
						<RequirePermissions permissions={['Brands.Archive']}>
							<Button
								color="gray.2"
								style={{ color: 'black' }}
								leftIcon={<Icon name="archive" />}
								onClick={() => navigate('/configuration/brands/archived')}
							>
								Archived
							</Button>
						</RequirePermissions>
						<RequirePermissions permissions={['Brands.Create']}>
							<Button
								leftIcon={<Icon name="add" />}
								onClick={() => navigate('/configuration/brands/create')}
							>
								Create Brand
							</Button>
						</RequirePermissions>
					</Stack>
				</Div>

				<SimpleDataGrid
					data={data?.brands || []}
					columnVisibility={{ id: false }}
					columns={[
						{ accessorKey: 'id', header: 'Id' },
						{ accessorKey: 'name', header: 'Name' },
						{
							accessorKey: 'updated_at',
							header: 'Last Updated',
							cell: (row: any) =>
								dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssA'),
						},
					]}
					onClickHandler={getSelectedRow}
				/>
				{data?.brands?.length === 0 && <EmptyState resource="brands" />}
			</ViewContainer>
		</>
	);
};

export default Brands;

import {z} from 'zod'
import { BaseSchema } from './base.schema'

export const KitchenGroupSchema = BaseSchema.extend({
    name: z.string(
        {required_error: 'This field is required.'}
    ).min(1,'This field is required.')
    .max(80, 'Only a maximum of 80 characters is allowed'),
    brand_id: z.number()
})

export type KitchenGroupSchemaType = z.infer<typeof KitchenGroupSchema>

export const KitchenGroupSchemaCreate = KitchenGroupSchema.pick({name: true, brand_id: true})

export type KitchenGroupSchemaCreateType = z.infer<typeof KitchenGroupSchemaCreate>

export const KitchenGroupSchemaUpdate = KitchenGroupSchemaCreate

export type KitchenGroupSchemaUpdateType = z.infer<typeof KitchenGroupSchemaUpdate>
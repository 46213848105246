import {
	BackgroundImage,
	Flex,
	Group,
	Stack,
	TextInput,
	Title,
} from '@mantine/core';
import { isAxiosError } from 'axios';
import {
	Alert,
	Box,
	Button,
	Formik,
	Image,
	Text,
	UnstyledButton,
} from 'components';
import { toFormikValidationSchema } from 'lib/helpers';
import { useNavigate } from 'lib/navigate';
import { useAuth } from 'providers/AuthProvider';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CreateLoginSchema } from 'schemas/login.schema';
import packageJson from '../../package.json';
import { authApi } from '../apis';
import fsLogo from './assets/logo.png';
import otoposLogo from './assets/otopos-logo-white.png';
import {browserName, browserVersion} from 'react-device-detect'

const Login = () => {
	const { isLoading } = useAuth();
	const { state } = useLocation();
	const navigate = useNavigate();

	const [error, setError] = React.useState<string | null>(null);
	
	return (
		<Formik
			initialValues={{
				username: state?.username ?? '',
				password: state?.password ?? '',
			}}
			onSubmit={(values) =>
				authApi
					.post('/auth/login/dashboard', values)
					.then(({ data }) => {
						navigate('/company', { state: { data } });
					})
					.catch((err) => {
						if (isAxiosError(err)) {
							setError(err.response?.data?.error);
						}
					})
			}
			validationSchema={toFormikValidationSchema(CreateLoginSchema)}
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
		>
			{({ submitForm, errors, values, setFieldValue }) => {
				const focusRef = React.useRef<HTMLInputElement>(null);

				React.useEffect(() => {
					if (focusRef.current) {
						focusRef.current.focus();
					}
				}, [focusRef]);

				return (
					<Box>
						{/* Header */}
						<Box
							style={{
								position: 'absolute',
								top: '20px',
								left: '40px',
								padding: '20px 40px',
							}}
						>
							<Image src={otoposLogo} alt="otopos-logo" width="30%" />
						</Box>
						<Flex
							h="100%"
							w="100%"
							justify="right"
							align="center"
						>
							<Box
								style={{
									width: '100%',
									height: '100%',
									background: 'white',
									borderRadius: '4px',
									overflow:'hidden'
								}}
							>
								{!error ? null : (
									<Alert mb="lg" color="red" variant="outline">
										{error}
									</Alert>
								)}
								{state?.loginError ? (
									<Alert mb="lg" color="red" variant="outline">
										Invalid username or password
									</Alert>
								) : state?.requestExceedError ? (
									<Alert mb="lg" color="red" variant="outline">
										Please try again later
									</Alert>
								) : state?.fromPasswordReset ? (
									<Alert mb="lg" color="red" variant="outline">
										Set password successful, please login again
									</Alert>
								) : (
									<Stack w="100%">
										<Image src={fsLogo} alt="logo" width="50%" />
									</Stack>
								)}
								{

									(browserName == 'Safari' 
										&& parseFloat(browserVersion) == parseFloat('15.4')) ?

									(
										<Stack w="100%" py="md" spacing={10} my="sm" mah="75%" pos="relative">											
											<Text>Sorry, we have disabled the login for your version of browser as we do not support
												{" "+browserName} on versions older than 15.4.</Text>
											<Text>Please check in with the development team on the versions the dashboard supports.</Text>
										</Stack>
									)
									:
									( 
										<Stack w="100%" py="md" spacing={10} my="sm" mah="75%" pos="relative">

											<TextInput
												ref={focusRef}
												value={values?.username}
												onChange={(e) =>
													setFieldValue('username', e.currentTarget.value)
												}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														submitForm();
													}
												}}
												name="username"
												label="Username or Email"
												required
												disabled={isLoading}
												error={errors['username'] as string}
											/>
											<TextInput
												value={values?.password}
												onChange={(e) => {
													setFieldValue('password', e.currentTarget.value);
												}}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														submitForm();
													}
												}}
												name="password"
												required
												label="Password"
												type="password"
												disabled={isLoading}
												error={errors['password'] as string}
											/>
										
									
										
											<Button
												w="100%"
												size="md"
												onClick={() => submitForm()}
												loading={isLoading}
											>
												Login
											</Button> 
									
										</Stack>
									)
								}

								{
									!(browserName == 'Safari' 
										&& parseFloat(browserVersion) == parseFloat('15.4'))
									
									&& 
									
									(

										<Stack spacing={10} w="100%">
											<UnstyledButton
												onClick={() => navigate(`/password/recovery`)}
											>
												Forget Password
											</UnstyledButton>
											<Text
												size="sm"
												color="gray"
											>{`v${packageJson.version}`}</Text>
										</Stack>

									)

								}
								
							</Box>
						</Flex>
						{/* Footer */}
						<Group
							position="apart"
							w="100vw"
							px={50}
							py="xl"
							sx={{ position: 'absolute', bottom: 10, left: 0}}
						>
							<Group>
								<Text color="white">Terms & Conditions</Text>
								<Text color="white">Privacy Policy</Text>
							</Group>
							<Box>
								<Text color="white">©2022 A5 Tec Pte Ltd</Text>
							</Box>
						</Group>
					</Box>
				);
			}}
		</Formik>
	);
};

export default Login;

import { Box, Checkbox, Grid } from '@mantine/core';
import React from 'react';
import { Select, useFormikContext } from '../../../../components';
import { useGetRolesByCompany } from '../../../../hooks/api/roles/useGetRolesByCompany';

type Props = {
	companyName: string;
	companyId: string | number;
};

const CompanyRole: React.FC<Props> = ({ companyName, companyId }) => {
	const { data: rolesData } = useGetRolesByCompany({
		companyId,
		includePermissions: false,
	});
	const { setFieldValue, values } = useFormikContext<any>();

	const roleIds = rolesData?.roles?.map((e: any) => e.id);

	const [selectedRole, setSelectedRole] = React.useState<string | null>(null);
	const [isThisCompanySelected, setIsThisCompanySelected] =
		React.useState<boolean>(values?.companies?.includes(companyId));

	React.useEffect(() => {
		if (values?.companies?.length) {
			setIsThisCompanySelected(values?.companies?.includes(companyId));
		}
	}, [values?.companies]);

	return (
		<Box
			sx={(theme) => ({
				borderInline: `1px solid ${theme.colors.gray[3]}`,
				borderBottom: `1px solid ${theme.colors.gray[3]}`,
				':first-child': {
					borderTop: `1px solid ${theme.colors.gray[3]}`,
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
				},
				':last-child': {
					borderBottomLeftRadius: '4px',
					borderBottomRightRadius: '4px',
					borderBottom: `1px solid ${theme.colors.gray[3]}`,
				},
			})}
			p="md"
		>
			<Grid align="center">
				<Grid.Col lg={6}>
					{/* <Checkbox
						label={companyName}
						checked={values?.companies?.includes(companyId)}
						onChange={(e) => {
							setIsThisCompanySelected(e.currentTarget.checked);
							if (!e.currentTarget.checked) {
								const roles = values?.roles?.filter(
									(e: string) => !roleIds?.includes(+e)
								);
								const companies = values?.companies.filter(
									(e: number) => e !== companyId
								);
								setFieldValue(`roles`, roles);
								setFieldValue(`companies`, companies);
							} else {
								const companies = values?.companies?.concat([companyId]);
								setFieldValue(`companies`, companies);
							}
						}}
					/> */}
					<p>{companyName}</p>
				</Grid.Col>
				<Grid.Col lg={6}>
					{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
					<Select
						value={
							values?.roles
								?.find((e: any) => e.company_id === companyId)
								?.id?.toString() || 'null'
						}
						// disabled={!isThisCompanySelected}
						w="100%"
						data={[{ label: 'No role', value: 'null' }].concat(
							rolesData?.roles?.map((e: any) => ({
								value: String(e?.id),
								label: e?.name,
							})) || []
						)}
						placeholder="Please select"
						onChange={(value) => {
							if (value === 'null') {
								const roles = values?.roles?.filter(
									(e: any) => e.company_id !== +companyId
								);
								setFieldValue(`roles`, roles);
								return;
							}
							if (value) {
								const roles = values?.roles
									?.filter((e: any) => e.company_id !== +companyId)
									?.concat([
										rolesData?.roles?.find((e: any) => e.id === +value),
									]);

								console.log(`roles`, roles);
								setFieldValue(`roles`, roles);
								setSelectedRole(value);
							}
						}}
					/>
				</Grid.Col>
			</Grid>
		</Box>
	);
};

export default CompanyRole;

import { Button, Group, Stack, Divider } from '@mantine/core';
import React from 'react';
import {
	Breadcrumbs,
	Field,
	Formik,
	ViewContainer,
	TextField,
	CancelButton,
} from '../../components';
import { SwitchField } from '../../components/SwitchField';
import { useCreateUser } from '../../hooks/api/users/useCreateUser';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAuth } from '../../providers/AuthProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';
import { CreateUserSchema } from '../../schemas/user.schema';
import BrandsAndOutlets from './CreateUser/BrandsAndOutlets';
import UserAccess from './CreateUser/UserAccess';
import { notifications } from '@mantine/notifications';
import { isAxiosError } from 'axios';

const CreateUser = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { mutate } = useCreateUser();

	return (
		<Formik
			initialValues={{
				name: '',
				email: '',
				username: '',
				terminal_pin: '',
				is_superadmin: false,
				is_active: true,
				send_activation_email: 'yes',
				roles: [],
				companies: [],
				brand_outlet: {},
			}}
			validationSchema={toFormikValidationSchema(CreateUserSchema)}
			onSubmit={({ roles, companies, brand_outlet, ...rest }) => {
				mutate(
					{
						payload: {
							...rest,
							roles: roles?.map((e: any) => e.id),
							brand_outlet,
						},
					},
					{
						onSuccess: () => {
							navigate(PermissionRoutes.Users);
							notifications.show({
								message: `New user created: ${rest?.name}`,
							});
						},
						onError: (err) => {
							if (isAxiosError(err)) {
								notifications.show({
									color: 'red',
									message:
										err.response?.data?.error || 'Error: User not created',
								});
							}
						},
					}
				);
			}}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ values, errors, submitForm, setFieldValue }) => {
				return (
					<ViewContainer
						title={
							<Breadcrumbs
								items={[
									{
										label: 'Users',
										onClick: () => navigate(PermissionRoutes.Users),
									},
									{
										label: 'Create User',
									},
								]}
							/>
						}
					>
						<Stack w="100%" spacing="xl" my="50px">
							<Field
								as={TextField}
								name="name"
								label="Name"
								required
								maxLength={80}
								error={errors['name'] as string}
							/>
							<Field
								as={TextField}
								name="email"
								label="Email"
								error={errors['email'] as string}
							/>
							<Field
								as={TextField}
								name="username"
								label="Username"
								required
								maxLength={80}
								error={errors['username'] as string}
							/>
							<Field
								as={TextField}
								name="terminal_pin"
								label="Terminal 8 digit PIN"
								required
								maxLength={8}
								error={errors['terminal_pin'] as string}
							/>
							{!user?.is_superadmin ? null : (
								<div style={{ margin: '20px 0 0px' }}>
									<SwitchField
										label="Super Administrator"
										switchLabel={values?.is_superadmin ? 'True' : 'False'}
										checked={values?.is_superadmin}
										onChange={(e) =>
											setFieldValue('is_superadmin', e.currentTarget.checked)
										}
										hint="The superadmin role will be granted full access to all companies, brands and outlets."
									/>
								</div>
							)}
						</Stack>
						<Divider my="30px" />
						<UserAccess />
						<Divider my="30px" />
						<BrandsAndOutlets />
						<Group>
							<Button onClick={() => submitForm()}>Create</Button>
							<CancelButton onClick={() => navigate(PermissionRoutes.Users)}>
								Cancel
							</CancelButton>
						</Group>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default CreateUser;

import React from 'react';
import Icon, { iconsMapper } from '../../../assets/icons';
import { Chip } from '../../../components';
import { ThemeColors } from '../../../components/types/ThemeColors';

type Props = React.PropsWithChildren<unknown>;

const statusColor: Record<string, ThemeColors> = {
	sync_required: 'red',
	up_to_date: 'green',
	sync_initiated: 'grey50',
	sync_failed: 'red',
};

const statusLabel: Record<string, string> = {
	sync_required: 'Sync Required',
	up_to_date: 'Up-to-date',
	sync_initiated: 'Sync Initiated',
	sync_failed: 'Sync Failed',
};

const statusIcon: Record<string, keyof typeof iconsMapper> = {
	sync_required: 'sync',
	up_to_date: 'checkmark',
	sync_initiated: 'upload',
	sync_failed: 'cancel',
};

const StatusChip: React.FC<Props> = ({ children }) => {
	return (
		<Chip
			color={statusColor[children as string]}
			leftIcon={<Icon name={statusIcon[children as string]} />}
		>
			{statusLabel[children as string]}
		</Chip>
	);
};

export default StatusChip;

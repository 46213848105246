import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { ThemeColors } from '../types/ThemeColors';
import { StyledSpinner } from './Spinner.styled';

export type SpinnerProps = DefaultProps<HTMLSpanElement> & {
	size?: React.CSSProperties['height'];
	color?: ThemeColors;
};

const defaultProps = {
	size: '16px',
};

export const Spinner = React.forwardRef<HTMLSpanElement, SpinnerProps>(
	(rest, ref) => {
		return <StyledSpinner {...rest} ref={ref} />;
	}
);

Spinner.displayName = 'Spinner';
Spinner.defaultProps = defaultProps;

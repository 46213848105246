import React from 'react';
import { Div, SelectBrand, ViewContainer } from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useGetBrands } from 'hooks/brands';
import { useGetArchivedOutlets } from 'hooks/outlets';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useNavigate } from '../../../lib/navigate';
import dayjs from 'dayjs';

const Outlets = () => {
	const { selectedCompany, selectedBrand } = useAppState();

	const {
		data: response,
		isFetching: fetchingOutlets,
		refetch: refetchOutlets,
	} = useGetArchivedOutlets(Number(selectedBrand || 0));

	const navigate = useNavigate();

	const breadcrumbsArray = [
		{
			label: 'Outlets',
			onClick: () => navigate('/configuration/outlets'),
		},
		{
			label: 'Archived',
			onClick: () => navigate('#'),
		},
	];

	const getSelectedRow = (row: any) => {
		navigate(`/configuration/outlets/${row.original.id}`);
	};

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} />
			<Div
				style={{
					position: 'relative',
					marginBottom: '20px',
					marginTop: '20px',
				}}
			>
				<Div>
					<SelectBrand />
				</Div>
			</Div>
			<SimpleDataGrid
				data={response?.outlets || []}
				columnVisibility={{ id: false }}
				columns={[
					{ accessorKey: 'name', header: 'Name' },
					{ accessorKey: 'id', header: 'Outlet ID' },
					{
						accessorKey: 'updated_at',
						header: 'Last Updated',
						cell: (row: any) =>
							dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
					},
				]}
				onClickHandler={getSelectedRow}
			/>
		</ViewContainer>
	);
};

export default Outlets;

import React from 'react';
import { Add, Archive } from '@mui/icons-material';
import {
	ActionIcon,
	Breadcrumbs,
	Button,
	Div,
	Menu,
	SelectBrand,
	Stack,
	ViewContainer,
	Text,
	Tooltip,
	EmptyState,
} from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { useNavigate } from '../../../lib/navigate';
import { useGetBrands } from 'hooks/brands';
import { useGetKitchenGroups } from 'hooks/kitchengroups';
import { useAppState } from 'providers/AppStateProvider';
import { useModals } from 'providers/ModalsProvider';
import { LinkedPOSModal } from './Modals';
import dayjs from 'dayjs';
import Icon from 'assets/icons';
import { useAuth } from 'providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const KitchenGroups = () => {
	const { permissions } = useAuth();
	const modal = useModals();
	const navigate = useNavigate();
	const breadcrumbsArray = [
		{
			label: 'Kitchen Groups',
			onClick: () => navigate('#'),
		},
	];

	const { selectedCompany, selectedBrand } = useAppState();
	const { data: brandsData } = useGetBrands(selectedCompany!);
	const { data: response_kitchen_groups } = useGetKitchenGroups(
		selectedBrand ?? 0
	);

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} />
			<Div style={{ position: 'relative', marginTop: '10px' }}>
				<SelectBrand />

				<Stack gap={10} style={{ marginBottom: '10px' }}>
					<Div
						style={{
							position: 'absolute',
							right: 0,
							top: 0,
						}}
					>
						{brandsData?.brands?.length ? (
							<RequirePermissions permissions={['KitchenGroups.Create']}>
								<Button
									type="button"
									leftIcon={<Add fontSize="inherit" />}
									onClick={() => navigate('/products/kitchengroups/create')}
								>
									Create Kitchen Group
								</Button>
							</RequirePermissions>
						) : null}
					</Div>

					<Div></Div>
				</Stack>

				{/* <pre>{selectedKG}</pre>
				<pre>{JSON.stringify(linkedPos, null, 2)}</pre> */}
				<SimpleDataGrid
					data={response_kitchen_groups?.kitchen_groups}
					columns={[
						{ accessorKey: 'name', header: 'Name' },
						// {
						// 	accessorKey: 'id',
						// 	header: 'POS Using',
						// 	cell: ({ getValue, row }: { getValue: any; row: any }) => (
						// 		<Div style={{ display: 'flex' }}>
						// 			{/* <Text style={{ alignSelf: 'center' }}>
						// 				{row.getValue()} POS
						// 			</Text> */}

						// 			<Tooltip
						// 				position="bottom-start"
						// 				onMouseEnter={() => {
						// 					setSelectedKG(parseInt(getValue()));
						// 				}}
						// 				label={linkedPos}
						// 			>
						// 				<ActionIcon>
						// 					<Icon name="info" />
						// 				</ActionIcon>
						// 			</Tooltip>
						// 		</Div>
						// 	),
						// },
						{
							accessorKey: 'updated_at',
							header: 'Last Updated',
							cell: (row: any) =>
								dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
						},
						{
							accessorKey: 'id',
							header: '',
							cell: ({ getValue, row }: { getValue: any; row: any }) => {
								return (
									<>
										<Menu position="bottom-start">
											<Menu.Target>
												<ActionIcon>
													<Icon name="morehort" />
												</ActionIcon>
											</Menu.Target>
											<Menu.Dropdown>
												<Menu.Item
													onClick={() => {
														modal.showModal({
															title: '',
															body: (
																<LinkedPOSModal
																	id={row.original.id}
																	close={modal.closeModal}
																/>
															),
														});
													}}
												>
													View Linked POS
												</Menu.Item>
											</Menu.Dropdown>
										</Menu>
									</>
								);
							},
							meta: {
								isNotClickable: true,
							},
						},
					]}
					onClickHandler={(row) =>
						navigate(`/products/kitchengroups/${row.original.id}`)
					}
				/>

				{brandsData?.brands?.length === 0 ? (
					<EmptyState
						resource="brands"
						secondaryMessage="Please create Brand before creating Option Group."
						CallToAction={
							permissions?.includes('Brands.Create') && (
								<Button
									type="button"
									leftIcon={<Add fontSize="inherit" />}
									onClick={() => navigate('/configuration/brands/create')}
								>
									Create Brand
								</Button>
							)
						}
					/>
				) : (
					response_kitchen_groups?.kitchen_groups?.length === 0 && (
						<EmptyState resource="Kitchen Group" />
					)
				)}
			</Div>
		</ViewContainer>
	);
};

export default KitchenGroups;

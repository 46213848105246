import {
	Anchor,
	Box,
	Checkbox,
	Group,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { NumberField, useFormikContext } from 'components';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FieldWrapper } from '../../../../components/FieldWrapper';
import { useGetPosById } from '../../../../hooks/api/pos/useGetPosById';
import { useGetPaymentMethods } from '../../../../hooks/payments';
import { useAppState } from '../../../../providers/AppStateProvider';
import { ConfigurationRoutes } from '../../../../router/constants/Configuration.routes';

const Payment = () => {
	const { selectedBrand, isCompanyBCIntegrated } = useAppState();
	const { data: paymentMethods } = useGetPaymentMethods(selectedBrand!);
	const { values, setFieldValue, errors } = useFormikContext<any>();

	return (
		<>
			<Title order={2}>Payment</Title>
			<FieldWrapper label="Payment Methods">
				<Box>
					{!paymentMethods ? (
						<Text>No payment methods have been created</Text>
					) : (
						paymentMethods?.paymentMethods?.map((e: any, idx: number) => {
							return (
								<Group
									key={`payment-method-${e?.id}`}
									w="690px"
									position="apart"
									p="sm"
									sx={{
										':not(:first-of-type)': {
											borderTop: '0.5px solid gray',
										},
									}}
								>
									<Checkbox
										label={e?.name}
										disabled={values?.payment_methods[idx]?.is_read_only}
										checked={values?.payment_methods[idx]?.is_active}
										size="md"
										onChange={(e) => {
											setFieldValue(
												`payment_methods.${idx}.is_active`,
												e.currentTarget.checked
											);
										}}
									/>
									<div hidden={!isCompanyBCIntegrated}>
										<TextInput
											required={false}
											size="xs"
											placeholder="BC Customer Code"
											value={values?.payment_methods[idx]?.pos_payment_code || ''}
											onChange={(e) => {
												setFieldValue(
													`payment_methods.${idx}.pos_payment_code`,
													e.currentTarget.value
												);
											}}
											error={
												isCompanyBCIntegrated &&
													values?.payment_methods[idx]?.is_active &&
													!values?.payment_methods[idx]?.pos_payment_code
													? 'This field is required.'
													: null
											}
										/>
									</div>
								</Group>
							);
						})
					)}
				</Box>
				<Box my="lg">
					<Anchor
						component={NavLink}
						color="orange"
						to={ConfigurationRoutes.Payment}
					>
						Manage payment types
					</Anchor>
				</Box>
			</FieldWrapper>
			<NumberField
				w="690px"
				label="Default Opening Cash Amount ($)"
				required
				name="default_opening_cash"
				value={String(values?.default_opening_cash)}
				onChange={(e) => {
					setFieldValue('default_opening_cash', +e.currentTarget.value);
				}}
				error={errors.default_opening_cash as string}
				step={1}
			/>
		</>
	);
};

export default Payment;

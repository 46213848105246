import { useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';

type SellingTimeType = {
    id?: number, 
    brand_id: number,
    name: string,
    startTime: string,
    endTime: string,
}

export function useUpdateOneSellingTime(){
    const { getAccessToken } = useAuth();
    const updateSellingTime = async (body: any ) => {
        const response = await configApi.put(`selling-times/${body.id}`, body,{
            headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
        })

        return response.data
    }

    const queryClient = useQueryClient();
	return useMutation(updateSellingTime, {
		onSuccess: (e) => {
			queryClient.invalidateQueries('selling_times');
		},
	});
}
import React from 'react';
import FinanceReports from 'views/Reports/FinanceReports';
import Analytics from 'views/Reports/Analytics';
import { ProtectedRoute } from '../ProtectedRoute';

export const reportsRoutes = [
	{
		name: 'Finance Reports',
		path: 'reports/finance',
		element: (
			<ProtectedRoute permissions={['Finance.View']}>
				<FinanceReports />
			</ProtectedRoute>
		),
	},
	{
		name: 'Analytics',
		path: 'reports/analytics',
		element: (
			<ProtectedRoute permissions={['Analytics.View']}>
				<Analytics />
			</ProtectedRoute>
		
		),
	},
];

import { ActionIcon, Badge, Flex, Grid, Text, TextInput } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import React from 'react';
import {
	Breadcrumbs,
	LastUpdatedLabel,
	ViewContainer,
} from 'components';
import { useGetMalls, useUpdateMallList, useCheckLinks } from 'hooks/malls';
import { useNavigate } from '../../../lib/navigate';
import { useAuth } from 'providers/AuthProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useMutation, useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAppState } from '../../../providers/AppStateProvider';
import { StyledDiv } from './Modals';

const Malls = () => {
	const { getAccessToken } = useAuth();
	const navigate = useNavigate();
	const toast = useNotification();

	const breadcrumbsArray = [
		{
			label: 'Malls'
		},
	];

	const [showInput, setShowInput] = React.useState(false);
	const [value, setValue] = React.useState<string>('');
	const { selectedCompany } = useAppState();

	const { data: malls, refetch: refetchMalls } = useQuery(
		['malls', selectedCompany],
		async (): Promise<{
			malls: {
				id: number;
				name: string;
				is_archived: boolean;
				created_at: string;
				updated_at: string;
				updated_by: string;
			}[];
			last_updated: {
				updated_at: string;
				updated_by: string;
			};
		}> =>
			configApi
				.get('/malls', {
					params: {
						company_id: selectedCompany,
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!selectedCompany,
		}
	);

	const { mutate: create } = useMutation(
		async ({ name }: { name: string }) =>
			configApi
				.post(
					'/malls',
					{
						name,
						is_archived: false,
					},
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data),
		{
			onSuccess: (_, { name }) => {
				toast.show({
					color: 'green',
					message: `${name} created`,
				});
				setShowInput(false);
				setValue('');
				refetchMalls();
			},
		}
	);

	const { mutate: remove } = useMutation(
		async ({ id }: { id: string }) =>
			configApi
				.delete(`/malls/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSettled: (data) => {
				if (!data.success) {
					modals.openConfirmModal({
						withCloseButton: false,
						size: 'lg',
						title: (
							<Text size="xl" weight="bold">
								This mall is used in the following places, please unlink before
								deleting
							</Text>
						),
						children: (
							<StyledDiv>
								<p>OUTLETS</p>
								<ul>
									{data.outlets.map((el: string, i: number) => {
										return <li key={i}>{el}</li>;
									})}
								</ul>
							</StyledDiv>
						),
						labels: {
							cancel: 'OK',
							confirm: '',
						},
						cancelProps: {
							variant: 'filled',
							color: 'orange',
						},
						confirmProps: {
							style: {
								display: 'none',
							},
						},
					});
				}
				refetchMalls();
			},
		}
	);

	const ref = useFocusTrap();

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} />
			<Grid my="lg" maw={1200}>
				<Grid.Col sm={12} md={3}>
					<p>List Of Malls</p>
				</Grid.Col>
				<Grid.Col sm={12} md={7}>
					<Flex
						wrap="wrap"
						gap="sm"
						p="md"
						sx={(theme) => ({
							border: `1px solid ${theme.colors.dark[0]}`,
							borderRadius: theme.radius.sm,
						})}
					>
						{malls?.malls?.map((e) => (
							<Badge
								styles={{
									root: {
										textTransform: 'capitalize',
									},
								}}
								variant="outline"
								color="dark"
								size="lg"
								py="md"
								key={e?.id?.toString()}
								rightSection={
									<ActionIcon
										size="sm"
										onClick={() => {
											if (e.id) {
												remove({ id: e.id.toString() });
											}
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="icon icon-tabler icon-tabler-x"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											strokeWidth="2"
											stroke="currentColor"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
										>
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<path d="M18 6l-12 12"></path>
											<path d="M6 6l12 12"></path>
										</svg>
									</ActionIcon>
								}
							>
								<Text
									sx={{ cursor: 'pointer' }}
									onClick={() =>
										navigate(`/configuration/malls/${e.id}`, {}, true)
									}
								>
									{e.name}
								</Text>
							</Badge>
						))}
						{!showInput ? (
							<Badge
								styles={{
									root: {
										textTransform: 'capitalize',
										cursor: 'pointer',
									},
								}}
								onClick={() => setShowInput((p) => !p)}
								leftSection="+"
								variant="filled"
								color="orange"
								size="lg"
								py="md"
							>
								Add
							</Badge>
						) : null}
					</Flex>
					{!showInput ? null : (
						<TextInput
							ref={ref}
							variant="unstyled"
							size="sm"
							my="xs"
							placeholder="Please enter to add malls"
							value={value}
							onBlur={() => setShowInput(false)}
							onChange={(e) => setValue(e.currentTarget.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									const currentMalls = malls?.malls?.map((e) =>
										e.name.toLowerCase()
									);
									const isExist = currentMalls?.includes(value?.toLowerCase());

									if (isExist && value) {
										notifications.show({
											message: `Error: ${value} already exists`,
											color: 'red',
										});
										return;
									}

									if(value.length > 80){
										notifications.show({
											message: `Only a maximum of 80 characters is allowed`,
											color: 'red',
										});
										return;
									}

									if (value) {
										create({ name: value });
									}
								}
							}}
						/>
					)}
				</Grid.Col>
				<Grid.Col sm={12} md={2}>
					<LastUpdatedLabel
						lastUpdated={malls?.last_updated?.updated_at ?? new Date()}
						editor={malls?.last_updated?.updated_by ?? ''}
					/>
				</Grid.Col>
			</Grid>
		</ViewContainer>
	);
};

export default Malls;

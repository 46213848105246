import React from 'react';
import { Button, CancelButton, Div, ModalWrapper, Stack } from 'components';
import { useDeleteOneSellingTime } from 'hooks/api/sellingtimes/useDeleteSellingTime';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'providers/RouterProvider';
import { useNotification } from 'providers/NotificationProvider';
import styled from '@emotion/styled';
import { ProductRoutes } from 'router/constants/Products.routes';

type Data = {
	id: number;
	name: string;
};

type DataArray = {
	[key: string]: string[];
};

export type ModalPropsString = {
	close: () => void;
	data?: Data;
};

export type ModalPropsArray = {
	close: () => void;
	data?: DataArray;
};

export const StyledDiv = styled.div`
	background-color: ${({ theme }) => theme.colors.grey05};
	padding: 1rem;
	margin: 1rem 0;

	p {
		color: ${({ theme }) => theme.colors.grey50};
	}

	ul {
		margin-top: 0;
		list-style-type: none;
		padding: 0;
	}
`;

export const LinkedSellingTimeModal = ({ close, data }: ModalPropsArray) => {
	return (
		<>
			<>
				{data && (
					<ModalWrapper>
						<h3>
							This selling time group is used in the following places, please
							unlink before deleting:
						</h3>
						<StyledDiv>
							<p>Categories</p>
							<ul>
								{data?.categories?.map((el, index) => {
									return <li key={index}>{el}</li>;
								})}
							</ul>
						</StyledDiv>

						<Stack
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button style={{ float: 'right' }} type="button" onClick={close}>
								Ok
							</Button>
						</Stack>
					</ModalWrapper>
				)}
			</>
		</>
	);
};

export const DeleteSellingTimeModal = ({
	close,
	data = { id: 0, name: '' },
}: ModalPropsString) => {
	const { mutateAsync: deleteSellingTime } = useDeleteOneSellingTime();
	const navigate = useNavigate();
	const toast = useNotification();

	const confirmDelete = () => {
		deleteSellingTime(data.id)
			.then(() => {
				close();
				navigate(ProductRoutes.SellingTimes);
				toast.success('Selling Time Group has been successfully deleted');
			})
			.catch(() => {
				toast.error('An error occured');
			});
	};
	return (
		<>
			<Div>
				<h3>Are you sure you want to delete?</h3>
				{data && (
					<StyledDiv>
						<p style={{ display: 'inline-flex', paddingRight: '1rem' }}>
							SELLING TIME
						</p>
						<span>{data.name}</span>
					</StyledDiv>
				)}
				<Stack
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<CancelButton onClick={close}>No, Cancel</CancelButton>
					<Button
						leftIcon={<DeleteIcon />}
						type="button"
						color="red"
						onClick={confirmDelete}
					>
						Yes, Delete
					</Button>
				</Stack>
			</Div>
		</>
	);
};

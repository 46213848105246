import { Box, Flex, Text } from '@mantine/core';
import React from 'react';
import EmptyStateJpg from './assets/emptystate.jpg';

type EmptyStateType = {
	resource: string;
	secondaryMessage?: string;
	CallToAction?: React.ReactNode;
};

const EmptyState = ({
	resource,
	secondaryMessage,
	CallToAction,
}: EmptyStateType) => {
	return (
		<Flex
			direction="column"
			align="center"
			gap="xl"
			sx={{ clear: 'both', textAlign: 'center' }}
		>
			<img width="300px" src={EmptyStateJpg} />
			<div>
				<Text weight="bold" size="24px" sx={{ margin: 'auto' }}>
					You don&#39;t have any {resource} yet!
				</Text>
				<Text sx={{ margin: 'auto' }}>
					{secondaryMessage ?? `Your ${resource} will appear here.`}
				</Text>
			</div>
			<Box>{CallToAction ?? null}</Box>
		</Flex>
	);
};

export { EmptyState };

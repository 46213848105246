import React from 'react';
import { Button, Div, Image, Stack, Text } from '../components';
import error404 from 'assets/images/error-404.jpg';
import { useAppState } from '../providers/AppStateProvider';
import { useNavigate } from '../lib/navigate';

const Error404 = () => {
	const { setIsSidebarHidden: set } = useAppState();
	const navigate = useNavigate();

	React.useEffect(() => {
		set(true);
		return () => set(false);
	}, []);

	return (
		<Div
			style={{
				display: 'grid',
				height: 'calc(100vh - 62px)',
				width: '100%',
				placeItems: 'center',
			}}
		>
			<Stack direction="column" gap={20} align="center">
				<Image src={error404} alt="404 Not Found" width="724px" />
				<Text weight={600} size="24px">
					Page Not Found
				</Text>
				<Text size="16px">
					We are sorry, but we cannot find the page you want.
				</Text>
				<Button onClick={() => navigate('/dashboard')}>Go to Dashboard</Button>
			</Stack>
		</Div>
	);
};

export default Error404;

import React from 'react';
import Brands from 'views/Configuration/Brands/Brands';
import BrandsArchived from 'views/Configuration/Brands/BrandsArchived';
import BrandsCreate from 'views/Configuration/Brands/BrandsCreate';
import BrandsView from 'views/Configuration/Brands/BrandsView';
import Companies from 'views/Configuration/Companies/Companies';
import CompaniesArchived from 'views/Configuration/Companies/CompaniesArchived';
import CompaniesCreate from 'views/Configuration/Companies/CompaniesCreate';
import CompaniesView from 'views/Configuration/Companies/CompaniesView';
import Outlets from 'views/Configuration/Outlets/Outlets';
import OutletsArchived from 'views/Configuration/Outlets/OutletsArchived';
import OutletsCreate from 'views/Configuration/Outlets/OutletsCreate';
import OutletsView from 'views/Configuration/Outlets/OutletsView';
import Payment from 'views/Configuration/Payment/Payment';
import CreatePayment from 'views/Configuration/Payment/PaymentCreate';
import PaymentView from 'views/Configuration/Payment/PaymentView';
import CreateOrderDiscounts from 'views/Configuration/Payment/OrderDiscountsCreate';
import ViewOrderDiscounts from 'views/Configuration/Payment/OrderDiscountsView';
import CreateProductDiscounts from 'views/Configuration/Payment/ProductDiscountsCreate';
import ViewProductDiscounts from 'views/Configuration/Payment/ProductDiscountsView';
import PointOfSale from 'views/Configuration/PointOfSale';
import PointOfSaleCreate from 'views/Configuration/PointOfSale/PointOfSaleCreate';
import PointOfSaleView from 'views/Configuration/PointOfSale/PointOfSaleView';
import Malls from 'views/Configuration/Malls/Malls';
import MallsView from 'views/Configuration/Malls/MallsView';
import { ConfigurationRoutes } from '../constants/Configuration.routes';
import Order from 'views/Configuration/PointOfSale/Order';
import { ProtectedRoute } from '../ProtectedRoute';
import ViewClosing from '../../views/Configuration/PointOfSale/Closing/ViewClosing';

export const configurationRoutes = [
	{
		name: 'Malls',
		path: ConfigurationRoutes.Malls,
		element: (
			<ProtectedRoute superAdminOnly>
				<Malls />
			</ProtectedRoute>
		),
	},
	{
		name: 'ViewMalls',
		path: ConfigurationRoutes.ViewMalls,
		element: (
			<ProtectedRoute superAdminOnly>
				<MallsView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Companies',
		path: ConfigurationRoutes.Companies,
		element: (
			<ProtectedRoute superAdminOnly>
				<Companies />
			</ProtectedRoute>
		),
	},
	{
		name: 'Archived Companies',
		path: ConfigurationRoutes.ArchivedCompanies,
		element: (
			<ProtectedRoute superAdminOnly>
				<CompaniesArchived />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create new company',
		path: ConfigurationRoutes.CreateCompany,
		element: (
			<ProtectedRoute superAdminOnly>
				<CompaniesCreate />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Company',
		path: ConfigurationRoutes.ViewCompany,
		element: (
			<ProtectedRoute superAdminOnly>
				<CompaniesView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Brands',
		path: ConfigurationRoutes.Brands,
		element: (
			<ProtectedRoute permissions={['Brands.View', 'Brands.Edit']}>
				<Brands />
			</ProtectedRoute>
		),
	},
	{
		name: 'Brands Archived',
		path: ConfigurationRoutes.ArchivedBrands,
		element: (
			<ProtectedRoute
				permissions={['Brands.View', 'Brands.Edit', 'Brands.Archive']}
			>
				<BrandsArchived />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create new Brand',
		path: ConfigurationRoutes.CreateBrand,
		element: (
			<ProtectedRoute permissions={['Brands.Create']}>
				<BrandsCreate />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Brand',
		path: ConfigurationRoutes.ViewBrand,
		element: (
			<ProtectedRoute permissions={['Brands.View', 'Brands.Edit']}>
				<BrandsView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Outlets',
		path: ConfigurationRoutes.Outlets,
		element: (
			<ProtectedRoute permissions={['Outlets.View', 'Outlets.Edit']}>
				<Outlets />
			</ProtectedRoute>
		),
	},
	{
		name: 'Outlets Archived',
		path: ConfigurationRoutes.ArchivedOutlets,
		element: (
			<ProtectedRoute
				permissions={['Outlets.View', 'Outlets.Edit', 'Outlets.Archive']}
			>
				<OutletsArchived />
			</ProtectedRoute>
		),
	},
	{
		name: 'Outlets Create',
		path: ConfigurationRoutes.CreateOutlet,
		element: (
			<ProtectedRoute permissions={['Outlets.Create']}>
				<OutletsCreate />
			</ProtectedRoute>
		),
	},
	{
		name: 'Outlets View',
		path: ConfigurationRoutes.ViewOutlet,
		element: (
			<ProtectedRoute permissions={['Outlets.View','Outlets.Edit']}>
				<OutletsView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Point of Sale',
		path: ConfigurationRoutes.Pos,
		element: (
			<ProtectedRoute permissions={['PosList.View', 'PaymentMethods.View','Discounts.View',]}>
				<PointOfSale />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Point of Sale',
		path: ConfigurationRoutes.CreatePos,
		element: (
			<ProtectedRoute permissions={['PosConfig.Create']}>
				<PointOfSaleCreate />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Point of Sale',
		path: ConfigurationRoutes.ViewPos,
		element: (
			<ProtectedRoute permissions={['PosConfig.View', 'PosConfig.Edit']}>
				<PointOfSaleView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Payment',
		path: ConfigurationRoutes.Payment,
		element: (
			<ProtectedRoute
				permissions={['PaymentMethods.View', 'PaymentMethods.Edit']}
			>
				<Payment />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Order Discount',
		path: ConfigurationRoutes.CreateOrderDiscount,
		element: (
			<ProtectedRoute permissions={['Discounts.Create']}>
				<CreateOrderDiscounts />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Order Discount',
		path: ConfigurationRoutes.ViewOrderDiscount,
		element: (
			<ProtectedRoute permissions={['Discounts.View', 'Discounts.Edit']}>
				<ViewOrderDiscounts />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Product Discount',
		path: ConfigurationRoutes.CreateProductDiscount,
		element: (
			<ProtectedRoute permissions={['Discounts.Create']}>
				<CreateProductDiscounts />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Product Discount',
		path: ConfigurationRoutes.ViewProductDiscount,
		element: (
			<ProtectedRoute permissions={['Discounts.View', 'Discounts.Edit']}>
				<ViewProductDiscounts />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Payment',
		path: ConfigurationRoutes.CreatePayment,
		element: (
			<ProtectedRoute permissions={['PaymentMethods.Create']}>
				<CreatePayment />
			</ProtectedRoute>
		),
	},

	{
		name: 'PaymentView',
		path: ConfigurationRoutes.ViewPayment,
		element: (
			<ProtectedRoute permissions={['PaymentMethods.View', 'Orders.View', 'Closings.View']}>
				<PaymentView />
			</ProtectedRoute>
		),
	},
	{
		name: 'Order',
		path: ConfigurationRoutes.Order,
		element: (
			<ProtectedRoute permissions={['Orders.View', 'Orders.Edit']}>
				<Order />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Closing',
		path: ConfigurationRoutes.ViewClosing,
		element: (
			<ProtectedRoute permissions={['Closings.View', 'Closings.Edit']}>
				<ViewClosing />
			</ProtectedRoute>
		),
	},
];

import { useQuery } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetUserById(userId: string) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['users', userId],
		async () =>
			authApi
				.get(`/users/${userId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ enabled: !!userId, refetchOnWindowFocus: false }
	);
}

import React from 'react';
import { TAuthContext, TAuthState } from './types';
export const initialAuthState: TAuthState = {
	isAuthenticated: false,
	user: null,
	accessToken: null,
	exp: null,
	isLoading: false,
	isError: false,
	userId: null,
	permissions: null,
	dispatchLogin: () => {
		return;
	},
	dispatchDangerously: () => {
		return;
	},
};

export const initialAuthContext: TAuthContext = {
	...initialAuthState,
	login: () => {
		return;
	},
	logout: () => {
		return;
	},
	getAccessToken: () => {
		return;
	},
	getUserInfo: () => {
		return;
	},
};

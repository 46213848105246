import { useQuery } from 'react-query';
import { txReadApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetOrderByOrderId(orderId: string | null) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['orders', 'orderId', orderId],
		async () =>
			txReadApi
				.get(`/transactions/${orderId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!orderId,
		}
	);
}

export type OptionType = {
	label: string;
	value: string;
};

export const initialPeriod = {
    startTime: "",
    endTime: ""
}

export type ErrorType = {
	[key: string]: string;
};

export const Days = {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thurday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday"
}

export type PeriodType = {
	startTime: string;
	endTime: string;
};

export const timeDropdown: OptionType[] = [];
for (let i = 0; i < 24; i++) {
	timeDropdown.push({
		label: `${i < 10 ? `0${i}` : i}:00`,
		value: `${i < 10 ? `0${i}` : i}:00`,
	});
	if (i < 24) {
		timeDropdown.push({
			label: `${i < 10 ? `0${i}` : i}:30`,
			value: `${i < 10 ? `0${i}` : i}:30`,
		});
	}
	
}
timeDropdown.push({
	label: "24:00",
	value: "23:59"
})
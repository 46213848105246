import { z } from 'zod';

export const CreateRoleSchema = z.object({
	name: z.string(),
	company_id: z.number(),
	description: z.string().optional(),
	permissions: z.object({
		product: z.array(z.string()).optional(),
		configuration: z.array(z.string()).optional(),
		pointOfSales: z.array(z.string()).optional(),
		payment: z.array(z.string()).optional(),
		permissions: z.array(z.string()).optional(),
		fsIntegration: z.array(z.string()).optional(),
		reports: z.array(z.string()).optional(),
		analytics: z.array(z.string()).optional(),
		terminal: z.array(z.string()).optional(),
	}),
});

export type TCreateRole = z.infer<typeof CreateRoleSchema>;

/* eslint-disable no-mixed-spaces-and-tabs */
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	Row,
	RowSelectionState,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { StyledDataGrid, StyledRow } from './DataGrid.styled';
import { Div, DivProps } from '../Div';
import { FormCheckBox } from '../FormCheckBox';
import { Pagination, PaginationProps } from '../Pagination';
import { Stack } from '../Stack';
import { ActionIcon } from '@mantine/core';
import Icon from '../../assets/icons';

declare module '@tanstack/react-table' {
	interface ColumnMeta<TData, TValue> {
		isNotClickable?: boolean;
	}
}

export type DataGridProps = Omit<DivProps, 'children'> & {
	/** Data array to display in table */
	data: Record<string, any>[];

	/** Array of column definitions */
	columns: ColumnDef<unknown>[];
	pageSize?: number;
	isSelectable?: boolean;
	onRowSelectionChange?: (selected: RowSelectionState) => void;
	onPaginationChange?: (page: number) => void;
	onSortChange?: (sort: string | null) => void;
	paginationProps?: Partial<PaginationProps>;
	activePage: number;
	totalPages?: number;
	totalRecords?: number;
	onClickHandler?: (row: any) => void;
	columnVisibility?: Record<string, boolean>;
};

const defaultProps: Partial<DataGridProps> = {
	pageSize: 10,
	totalPages: 1,
};

export const DataGrid = React.forwardRef<HTMLDivElement, DataGridProps>(
	(
		{
			data,
			columns,
			pageSize,
			isSelectable,
			onRowSelectionChange,
			onPaginationChange,
			onSortChange,
			activePage,
			paginationProps,
			totalPages,
			totalRecords,
			onClickHandler,
			hidden,
			columnVisibility,
			...rest
		},
		ref
	) => {
		const [trueSelections, setTrueSelections] = React.useState<
			Record<string, boolean>
		>({});

		const [sorting, setSorting] = React.useState<SortingState>([]);

		const table = useReactTable({
			data,
			columns,
			state: {
				sorting,
				columnVisibility,
			},
			onSortingChange: setSorting,
			getPaginationRowModel: getPaginationRowModel(),
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			enableMultiRowSelection: true,
		});

		React.useEffect(() => {
			if (pageSize) {
				table.setPageSize(pageSize);
			}
		}, [pageSize]);

		React.useEffect(() => {
			if (onSortChange && sorting.length) {
				const [sortItem] = sorting;
				const _sort = `${sortItem.id}:${
					sortItem['desc'] === true ? 'desc' : 'asc'
				}`;
				onSortChange(_sort);
			}
			if (onSortChange && !sorting.length) {
				onSortChange(null);
			}
			setTrueSelections({});
		}, [sorting]);

		React.useEffect(() => {
			if (onRowSelectionChange) {
				onRowSelectionChange(trueSelections);
			}
		}, [trueSelections]);

		return !data ? null : (
			<>
				{/* <pre>{JSON.stringify({ trueSelections }, null, 2)}</pre> */}
				{/* <pre>{JSON.stringify({ sorting }, null, 2)}</pre> */}
				<StyledDataGrid {...rest} hidden={hidden} ref={ref} component="table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{isSelectable ? <th></th> : null}
								{headerGroup.headers.map((header) => {
									const _sortOrder = header.column.getIsSorted();

									const sortIcon = () => {
										if (_sortOrder === 'asc') {
											return (
												<Icon
													name="arrowup"
													style={{ height: '15px', width: '15px' }}
												/>
											);
										}
										if (_sortOrder === 'desc') {
											return (
												<Icon
													name="arrowdown"
													style={{ height: '15px', width: '15px' }}
												/>
											);
										}
										return (
											<Icon
												name="unfoldmore"
												style={{ height: '15px', width: '15px' }}
											/>
										);
									};

									return (
										<th key={header.id}>
											<Stack align="center">
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
												<ActionIcon
													style={{
														padding: '0',
														height: '10px',
														width: '10px',
													}}
													onClick={header.column.getToggleSortingHandler()}
												>
													{sortIcon()}
												</ActionIcon>
											</Stack>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{table?.getRowModel()?.rows?.map((row) => {
							const rowDataId = row.getValue('id') as string;
							return (
								<StyledRow
									component="tr"
									key={row.id}
									css={(theme) => ({
										background: trueSelections[rowDataId as string]
											? theme.colors.accent
											: theme.colors.white,
									})}
								>
									{isSelectable ? (
										<td style={{ paddingRight: '0' }}>
											<FormCheckBox
												onChange={(e) => {
													if (e.currentTarget.checked) {
														setTrueSelections((p) => ({
															...p,
															[rowDataId]: true,
														}));
													} else {
														setTrueSelections((p) => ({
															...p,
															[rowDataId]: false,
														}));
													}
												}}
												checked={trueSelections[rowDataId] || false}
											/>
										</td>
									) : null}
									{row.getVisibleCells().map((cell, idx) => (
										// <pre key={idx}>
										// 	{JSON.stringify(
										// 		cell.column.columnDef.meta?.isClickable ?? 'Hello',
										// 		null,
										// 		2
										// 	)}
										// </pre>
										<td
											key={cell.id}
											onClick={() =>
												!cell.column.columnDef.meta?.isNotClickable &&
												onClickHandler &&
												onClickHandler(row)
											}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</StyledRow>
							);
						})}
					</tbody>
				</StyledDataGrid>
				<Stack width="100%" justify="space-between">
					<Stack
						width="100%"
						justify="flex-end"
						css={{ padding: '16px 0' }}
						hidden={hidden}
					>
						<Pagination
							pageSize={30}
							totalRecords={totalRecords}
							totalPages={totalPages || 1}
							initialPage={table.getState().pagination.pageIndex + 1}
							onChange={(page) => {
								table.setPageIndex(page - 1);
								onPaginationChange && onPaginationChange(page);
							}}
							prevPageHandler={() => {
								table.previousPage();
								paginationProps?.prevPageHandler &&
									paginationProps?.prevPageHandler(activePage);
							}}
							nextPageHandler={() => {
								table.nextPage();
								paginationProps?.nextPageHandler &&
									paginationProps?.nextPageHandler(activePage);
							}}
						/>
					</Stack>
				</Stack>
			</>
		);
	}
);

DataGrid.displayName = 'Datagrid';
DataGrid.defaultProps = defaultProps;

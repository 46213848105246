import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import {
	StyledCheckmark,
	StyledFormCheckBox,
	StyledIndeterminateMark,
} from './FormCheckBox.styled';

export type FormCheckBoxProps = DefaultProps<HTMLInputElement> & {
	checked?: boolean;
	readOnly?: boolean;
	label?: React.ReactNode;
	isDeterminate?: boolean;
	labelProps?: DefaultProps<HTMLParagraphElement>;
	containerProps?: DefaultProps<HTMLLabelElement>;
	isDisabled?: boolean;
};

export const FormCheckBox = React.forwardRef<
	{ checkRef: HTMLInputElement | null },
	FormCheckBoxProps
>(
	(
		{
			label,
			readOnly,
			isDeterminate,
			labelProps,
			containerProps,
			isDisabled,
			...rest
		},
		ref
	) => {
		const checkRef = React.useRef<HTMLInputElement>(null);

		React.useImperativeHandle(ref, () => ({
			get checkRef() {
				return checkRef.current;
			},
		}));

		React.useEffect(() => {
			if (checkRef.current) {
				checkRef.current.indeterminate = isDeterminate || false;
			}
		}, [isDeterminate]);

		return (
			<label
				{...containerProps}
				style={{
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					cursor: isDisabled ? 'default' : 'pointer',
					...containerProps?.style,
				}}
			>
				<StyledFormCheckBox
					{...rest}
					disabled={isDisabled}
					ref={checkRef}
					type="checkbox"
					readOnly={readOnly}
				/>
				<StyledCheckmark
					data-label="styled-checkmark"
					isDisabled={isDisabled || false}
				/>
				<StyledIndeterminateMark data-label="styled-indeterminatemark" />
				{label ? (
					<p
						{...labelProps}
						style={{
							marginLeft: '30px',
							height: '100%',
							marginTop: '1.5px',
							...labelProps?.style,
						}}
					>
						{label}
					</p>
				) : null}
			</label>
		);
	}
);

FormCheckBox.displayName = 'FormCheckBox';

import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCreateDiscount() {
	const { getAccessToken } = useAuth();
	return useMutation(async (values: any) =>
		configApi
			.post('/discounts', values, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

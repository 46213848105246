import { Badge, Box, Grid, Select, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { ColumnFiltersState } from '@tanstack/react-table';
import { currency, dayjs } from 'lib';
import React from 'react';
import { useQuery } from 'react-query';
import { txReadApi } from '../../../apis';
import { EmptyState, Text } from '../../../components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from '../../../providers/AppStateProvider';

const Orders = ({ outlet_id }: { outlet_id: number | null }) => {
	const { selectedBrand } = useAppState();

	// new code

	const navigate = useNavigate();

	const [salesDate, setSalesDate] = React.useState<Date>(new Date());

	const [filters, setFilters] = React.useState<{
		order_id?: string;
		is_voiding?: boolean;
		voided?: boolean;
	}>({});

	const sales_date = dayjs(salesDate).format('YYYY-MM-DD');

	const { data: orders } = useQuery(
		[
			'orders',
			selectedBrand,
			{
				outlet_id,
				sales_date,
			},
		],
		async () =>
			txReadApi
				.get(`/transactions/brand/${selectedBrand}`, {
					params: {
						outlet_id,
						sales_date,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!selectedBrand,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);

	const columnFilters: ColumnFiltersState =
		Object.entries(filters || {})?.map(([key, value]) => ({
			id: key,
			value,
		})) || undefined;

	// console.log(columnFilters);

	return (
		<>
			<Grid my="lg">
				<Grid.Col md={6}>
					{/* <pre>{JSON.stringify(filters, null, 2)}</pre> */}
					{/* <pre>{JSON.stringify(columnFilters, null, 2)}</pre> */}
					<TextInput
						placeholder="Search by receipt number"
						value={filters?.order_id}
						onChange={(e) => {
							setFilters((p) => ({
								...p,
								order_id: e?.target?.value,
							}));
						}}
					/>
				</Grid.Col>
				<Grid.Col md={3}>
					<Select
						placeholder="Status"
						data={[
							{ value: 'all', label: 'All' },
							{
								value: 'paid',
								label: 'Paid',
							},
							{
								value: 'voided',
								label: 'Voided',
							},
							// { value:, 'voiding', label: 'Voiding' },
						]}
						onChange={(value) => {
							if (value === 'paid') {
								setFilters((p) => ({
									...p,
									is_voiding: false,
									voided: false,
								}));
								return;
							}
							if (value === 'voided') {
								setFilters((p) => ({
									...p,
									is_voiding: false,
									voided: true,
								}));
								return;
							}
							// if (value === 'voiding') {
							// 	setFilters(({ voided, ...p }) => ({
							// 		...p,
							// 		is_voiding: true,
							// 	}));
							// 	return;
							// }
              // Product requirement does not need us to have "voiding", kept it here, in case we need it.
							if (value === 'all') {
								setFilters(({ is_voiding, voided, ...p }) => ({
									...p,
								}));
								return;
							}
						}}
					/>
				</Grid.Col>
				<Grid.Col md={3}>
					<DateInput
						styles={{
							input: {
								padding: '23px 18px',
							},
						}}
						valueFormat="DD/MM/YYYY"
						maxDate={dayjs().toDate()}
						onChange={(date) => {
							if (date) {
								setSalesDate(date);
							}
						}}
						value={salesDate}
					/>
				</Grid.Col>
			</Grid>
			{!orders?.length ? (
				<Box my="xl">
					<EmptyState resource="orders" />
				</Box>
			) : (
				<SimpleDataGrid
					columnVisibility={{
						is_voiding: false,
					}}
					columnFilters={columnFilters}
					onClickHandler={(row) => {
						navigate(`/orders/${row.original.order_id}`);
					}}
					data={orders}
					columns={[
						{ accessorKey: 'order_id', header: 'Receipt Number' },
						{
							accessorKey: 'terminal_name',
							header: 'Point of Sales',
						},
						{
							accessorKey: 'voided',
							header: 'Status',
							cell: ({ row, getValue }) => {
								const isVoiding = (row.original as any)?.is_voiding;
								const isVoided = getValue() as boolean;

								return (
									<Badge
										w="90px"
										size="md"
										color={isVoided ? 'red' : isVoiding ? 'orange' : 'green'}
									>
										{isVoided ? 'Voided' : isVoiding ? 'Voiding' : 'Paid'}
									</Badge>
								);
							},
						},
						{
							accessorKey: 'is_voiding',
							header: 'Voiding',
						},
						{
							accessorKey: 'amount_due',
							header: 'Amount',
							cell: ({ getValue }) => {
								const value = getValue() as number;
								return (
									<Text>
										{currency(value, {
											fromCents: true,
											symbol: 'S$ ',
										}).format()}
									</Text>
								);
							},
						},
						{
							accessorKey: 'order_datetime',
							header: 'Date',
							cell: ({ getValue }) => {
								const value = getValue() as string;
								return (
									<Text>{dayjs(value).format('DD/MM/YYYY hh:mm:ssA')}</Text>
								);
							},
						},
					]}
				/>
			)}
		</>
	);
};

export default Orders;

import React from 'react';
import { Div, ViewContainer } from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useArchivedCompanies } from 'hooks/companies';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';

type Data = { key: string; header: string }[];

const CompaniesArchived = () => {
	const [sortOrder, setSortOrder] = React.useState<string>('name:asc');
	const [selectedPage, setSelectedPage] = React.useState<number>(1);
	const { data, isFetching, refetch } = useArchivedCompanies();
	const navigate = useNavigate();

	// If user isn't an admin, redirect him to the details page of his own company
	const { selectedCompany } = useAppState();

	const { user } = useAuth();

	// if (user?.role !== 1) {
	// 	return (
	// 		<Navigate replace to={`/configuration/companies/${selectedCompany}`} />
	// 	);
	// }

	const getSelectedRow = (row: any) => {
		const id = row.original.id;
		navigate(`/configuration/companies/${id}`);
	};

	const breadcrumbsArray = [
		{
			label: 'Companies',
			onClick: () => navigate('/configuration/companies'),
		},
		{
			label: 'Archived',
			onClick: () => navigate('#'),
		},
	];

	React.useEffect(() => {
		refetch();
	}, [sortOrder]);

	return (
		<>
			<ViewContainer>
				<Div style={{ position: 'relative', marginBottom: '20px' }}>
					<Breadcrumbs items={breadcrumbsArray} />
				</Div>
				<SimpleDataGrid
					data={data?.companies || []}
					columns={[
						{ accessorKey: 'id', header: 'Id' },
						{ accessorKey: 'name', header: 'Name' },
					]}
					columnVisibility={{
						id: false,
					}}
					onClickHandler={getSelectedRow}
				/>
			</ViewContainer>
		</>
	);
};

export default CompaniesArchived;

import styled from '@emotion/styled'
import { theme } from '../../providers/ThemeProvider/index';

interface TagListItemProps {
	onClickTag?: (tag:string)=> void;
}

export const FormTagsWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	padding: 0 8px;
	border: 1px solid #d6d8da;
	border-radius: 4px;

	:focus-within {
		border: 1px solid ${theme.colors.grey05};
	}
`;

export const FormTagsInput = styled.input`
	flex: 1;
	border: none;
	height: 100%;
	width: 611px;
	font-size: 14px;
	padding: 0 0 0 0;
	font-family: 'Mulish';

	:focus {
		outline: transparent;
	}
`;

export const FormTagsListItem = styled.li<TagListItemProps>`
	width: 'fit-content';
	min-height: 30px;
	display: inline-flex;
	align-items: left;
	justify-content: center;
	color: ${theme.colors.grey70};
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 20px;
	margin: 0 8px 8px 0;
	background: white;
	border: 1px solid ${theme.colors.grey70};

	span {
		padding-top: 4px;
		white-space: pre;
		display: flex;
		align-self: center;
	}

	/* .tag-title {
		margin-top: 3px;
	} */

	.tag-close-icon {
		display: block;
		width: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color: ${theme.colors.grey70};
		/* border-radius: 50%; */
		/* background: ${theme.colors.grey70};; */
		cursor: pointer;
	}

	:hover {
		cursor: ${props => props.onClickTag && 'pointer'};
	}

	
`;

export const AddButton = styled.span`
	width: 70px;
	min-height: 30px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: ${theme.colors.grey05};
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 20px;
	background: ${theme.colors.primary};

	:hover {
		cursor: pointer;
	}
`;

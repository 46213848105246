import React from 'react';
import { useAppState } from 'providers/AppStateProvider';
import {
	Button,
	Div,
	FormTextInput,
	FormSwitch,
	Stack,
	Title,
	InputWrapper,
} from 'components';
import { Field, Formik } from 'formik';
import { CopyOptionGroup } from 'schemas/Products/optiongroup.schema';
import { useCopyOptionGroup } from 'hooks';
import { useNavigate } from 'lib/navigate';
import { useNotification } from 'providers/NotificationProvider';

type CopyOptionGroupDataType = {
	close: () => void;
	id: number;
};

export const CopyOptionGroupModal = ({
	close,
	id,
}: CopyOptionGroupDataType) => {
	const { setDirty } = useAppState();
	const { mutateAsync: copyOptionGroup } = useCopyOptionGroup();
	const navigate = useNavigate();
	const toast = useNotification();
	return (
		<Formik
			initialValues={{
				id,
				name: '',
				isCopyDescription: false,
			}}
			validate={(values) => {
				try {
					CopyOptionGroup.parse(values);
				} catch (error: any) {
					return error.formErrors.fieldErrors;
				}
			}}
			onSubmit={(values, actions) => {
				copyOptionGroup(values, {
					onSuccess: (data) => {
						setDirty(false);
						navigate('/products/optiongroups', {}, true);
						close();
						toast.success('Option group has been successfully created');
					},
					onError: (_) => {
						toast.error('An error occured');
					},
				});

				actions.setSubmitting(false);
			}}
			enableReinitialize
		>
			{({ values, errors, touched, dirty, setFieldValue, submitForm }) => {
				React.useEffect(() => {
					if (dirty) {
						setDirty(true);
					}

					return () => setDirty(false);
				}, [dirty]);
				return (
					<Div>
						<Title order={2}>Copy Option Group</Title>
						{/* {JSON.stringify(values, null, 2)}
						{JSON.stringify(errors, null, 2)} */}

						<Field
							width="611px"
							as={FormTextInput}
							name="name"
							label="Option Group Name"
							isRequired={true}
							maxLength={80}
							error={errors.name && touched.name ? errors.name : null}
						/>

						<Field
							component={FormSwitch}
							name="isCopyDescription"
							isChecked={values.isCopyDescription}
							onChange={(isChecked: boolean) => {
								setFieldValue('isCopyDescription', isChecked);
							}}
							width={'611px'}
							label="Copy Description"
						/>

						<Button onClick={submitForm}>Submit</Button>
					</Div>
				);
			}}
		</Formik>
	);
};

import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Breadcrumbs,
	Button,
	Div,
	SelectBrand,
	Stack,
	Text,
	Title,
	ViewContainer,
} from '../../../components';
import { useGetOrderByOrderId } from '../../../hooks/api/orders/useGetOrderByOrderId';
import { useNavigate } from '../../../lib/navigate';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';
import { currency, dayjs } from 'lib';
import OrderReceipt from './Order/OrderReceipt';
import { useModals } from '../../../providers/ModalsProvider';
import { useGetClosingByUuid } from '../../../hooks/api/closings/useGetClosingByUuid';
import { useGetOnlinePos } from '../../../hooks/api/pos/useGetOnlinePos';
import { usePutOrderIsVoiding } from '../../../hooks/api/orders/usePutOrderIsVoiding';
import { useNotification } from '../../../providers/NotificationProvider';
import { useQueryClient } from 'react-query';
import OrderVoidedIndicator from './Order/OrderVoidedIndicator';
import OrderVoidingIndicator from './Order/OrderVoidingIndicator';
import RequirePermissions from '../../../guards/RequirePermissions';

const Order = () => {
	const { orderId } = useParams();
	const navigate = useNavigate();
	const { data: order } = useGetOrderByOrderId(orderId!);
	const { showConfirmModal } = useModals();
	const notify = useNotification();
	const queryClient = useQueryClient();

	/**
	 * Conditions to enable void sale
	 * 1. Check current date is not before business date
	 * 2. Check closing not done
	 * 3. Check that pos is online
	 */

	// 1. Check current date is not before business date
	const isOrderInCurrentBusinessDate = React.useMemo(() => {
		return dayjs(order?.order_datetime?.split(' ')[0]).isSame(
			dayjs(new Date()),
			'day'
		);
	}, [order]);

	const { data: session } = useGetClosingByUuid(order?.session_uid);

	// 2. Check closing not done
	const isOrderSessionOpened = React.useMemo(() => {
		return !session?.session_end;
	}, [order, session]);

	// 3. Check that pos is online
	const { data: onlinePos } = useGetOnlinePos();

	const isPosOnline = React.useMemo(() => {
		return onlinePos?.includes(order?.pos_id);
	}, [onlinePos, order]);

	const isOrderVoidable =
		!order?.is_voiding &&
		!order?.voided &&
		isOrderInCurrentBusinessDate &&
		isOrderSessionOpened &&
		isPosOnline;

	const { mutate: voidOrder } = usePutOrderIsVoiding();

	return (
		<ViewContainer>
			<Div
				style={{
					display: 'grid',
					gridTemplateColumns: '736px 400px',
				}}
			>
				{/* Left */}
				<Div>
					<Breadcrumbs
						items={[
							{
								label: 'Orders',
								onClick: () => {
									navigate(`${ConfigurationRoutes.Pos}?tab=orders`);
								},
							},
							{
								label: `Orders ${orderId}`,
							},
						]}
					/>
					<Div style={{ margin: '20px 0' }}>
						<SelectBrand isDisabled />
					</Div>
					{/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
					<Div
						css={(theme) => ({
							background: theme.colors.grey10,
							padding: '40px',
							display: 'grid',
							placeItems: 'center',
							height: '680px',
							width: '736px',
							overflow: 'scroll',
							position: 'relative',
						})}
					>
						{order?.is_voiding ? (
							<OrderVoidingIndicator />
						) : order?.voided ? (
							<OrderVoidedIndicator />
						) : null}
						<OrderReceipt receipt={order} />
					</Div>
				</Div>
				{/* Right */}
				<Div>
					<Stack
						justify="right"
						width="100%"
						direction="column"
						gap={30}
						style={{ paddingTop: '50px' }}
					>
						<Stack direction="column" align="left">
							{isOrderVoidable && (
								<RequirePermissions permissions={['Orders.Edit']}>
									<Button
										color="red"
										onClick={() => {
											showConfirmModal({
												props: {
													onConfirm: () => {
														voidOrder(
															{ order_id: order?.order_id, pos_id: order?.pos_id }
															// 	{
															// 		onSuccess: () => {
															// 			notify.success(`Order ${order?.id} Voided`);
															// 			queryClient.invalidateQueries([
															// 				'orders',
															// 				'orderId',
															// 				order?.id,
															// 			]);
															// 		},
															// 	}
														);
													},
												},
												body: (
													<>
														<Title order={4}>
															Are you sure you want to void sale?
														</Title>
														<Div
															css={(theme) => ({
																background: theme.colors.grey05,
																borderRadius: '10px',
																padding: '20px',
																margin: '20px 0px',
															})}
														>
															<Text>ORDER {order?.order_id}</Text>
															<Text>
																TOTAL{' '}
																{currency(order?.amount_total, {
																	fromCents: true,
																}).format()}
															</Text>
														</Div>
													</>
												),
											});
										}}
									>
										Void Sale
									</Button>
								</RequirePermissions>
							)}
							<Div>
								<Text color="gray" size="12px" weight={600}>
									POINT OF SALE
								</Text>
								<Text>{order?.terminal_name}</Text>
							</Div>
							<Div>
								<Text color="gray" size="12px" weight={600}>
									TRANSACTION DATE
								</Text>
								<Text>{order?.order_datetime}</Text>
							</Div>
						</Stack>
					</Stack>
				</Div>
			</Div>
		</ViewContainer>
	);
};

export default Order;

import {
	Box,
	Button,
	Center,
	Group,
	Header,
	JsonInput,
	Select,
	Stack,
	Title,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authApi, configApi } from '../../apis';
import { Brand } from '../../components';
import otoposLogo from '../assets/otopos-logo-orange.png';

const Company = () => {
	const { state }: { state: Record<string, any> } = useLocation();

	const [decoded, setDecoded] = React.useState<Record<string, any>>({});
	const [user, setUser] = React.useState<Record<string, any>>({});
	const [companies, setCompanies] = React.useState<any[]>([]);

	const [prevSelectedCompany, setPrevSelectedCompany] = useLocalStorage<
		string | null
	>({
		key: 'otopos-selected-company',
		defaultValue: null,
	});

	React.useEffect(() => {
		if (decoded.sub) {
			authApi.get(`/users/${decoded.sub}`).then(({ data }) => setUser(data));
		}
	}, [decoded]);

	React.useEffect(() => {
		if (state?.data?.access_token) {
			setDecoded(jwtDecode(state.data.access_token));
		}
	}, [state]);

	React.useEffect(() => {
		if (user.companies) {
			configApi
				.get(`companies`, {
					params: {
						dropdown: 'yes',
						deleted: 'no',
						archived: 'no',
					},
					headers: {
						Authorization: `Bearer ${state.data.access_token}`,
					},
				})
				.then(({ data }) => {
					setCompanies(data.companies);
				});
		}
	}, [user]);

	const [exchange, setExchange] = React.useState<any>({});
	const navigate = useNavigate();

	return (
		<Formik
			onSubmit={({ selectedCompany }) => {
				authApi
					.post('/auth/token/dashboard', {
						company_id: selectedCompany,
					})
					.then(({ data }) => {
						setExchange(data);
						navigate('/redirect', {
							state: {
								selectedCompany,
								decoded: jwtDecode(data.access_token),
								data: {
									...state.data,
									access_token: data.access_token,
								},
								redirect: state.redirect,
							},
							replace: true,
						});
					});
			}}
			initialValues={{
				selectedCompany:
					prevSelectedCompany || companies?.at(0)?.id?.toString() || null,
			}}
			enableReinitialize
		>
			{({ setFieldValue, values, submitForm, isSubmitting }) => {
				return (
					<Box>
						<Header height="62px" px="1rem" py="auto">
							<Group
								noWrap
								align="center"
								px="1rem"
								w="100%"
								h="56px"
								position="apart"
								spacing={20}
							>
								<Brand imgUrl={otoposLogo} onClick={() => navigate('/')} />
							</Group>
						</Header>
						<Center h="70vh">
							<Stack w="440px" spacing={30} mx="auto">
								<Title order={3}>Which company would you like to access?</Title>
								<Select
									size="lg"
									onChange={(value) => {
										if (value) {
											setFieldValue('selectedCompany', value);
											setPrevSelectedCompany(value);
										}
									}}
									value={values['selectedCompany']}
									placeholder="Select company"
									data={
										companies?.map((e) => ({
											value: e.id.toString(),
											label: e.name,
										})) || []
									}
								/>
								<Button
									size="lg"
									disabled={!values.selectedCompany}
									onClick={() => submitForm()}
								>
									Proceed to Dashboard
								</Button>
								{/* <JsonInput
									value={JSON.stringify({ exchange, user, state }, null, 2)}
									autosize
									maxRows={10}
								/> */}
							</Stack>
						</Center>
					</Box>
				);
			}}
		</Formik>
	);
};

export default Company;

import React from 'react';
import { InputWrapper, InputWrapperProps } from './InputWrapper';
import { NumberInput } from '@mantine/core';

export type FormPriceInputProps = {
	inputWrapperProps?: Omit<InputWrapperProps, 'error' | 'label'>;
	required?: boolean;
	error?: React.ReactNode;
	label?: React.ReactNode;
	width?: React.CSSProperties['width'];
	name:string;
	placeholder?: string;
	precision?: number;
	min?: number;
	max?: number;
	step?: number;
	value: number | "";
	hidden?: boolean;
	disabled?:boolean;
	onChange: (value: number | '') => void;
	onFocus?:() => void;
	onBlur?: () => void;
};


export const FormPriceInput = React.forwardRef<
	HTMLInputElement,
	FormPriceInputProps
>(
	(
		{
			inputWrapperProps,
			required,
			error,
			label,
			width,
			value,
			hidden,
			disabled,
			...rest
		},
	) => {

		const widthNumber = Number(String(width).replace('px',''))

		return (
			<InputWrapper
				{...inputWrapperProps}
				label={label}
				width={width}
				hasAsterisk={required}
			>
				<NumberInput
					hidden={hidden}
					disabled={disabled}
					hideControls
					error={error}
					width={String((widthNumber * (75/100)))+'px'}
					styles={{
						input: {
							padding: '24px 18px',
						},
					}}
					value={value === '' ? '' : value} // Ensure value is controlled properly
					{...rest}
				/>
			</InputWrapper>
		);
	}
);

FormPriceInput.displayName = 'FormPriceInput';
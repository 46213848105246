import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { ThemeColors } from '../types/ThemeColors';
import { StyledDivider } from './Divider.styled';

export type DividerProps = DefaultProps<HTMLHRElement> & {
	orientation?: 'vertical' | 'horizontal';
	size?: React.CSSProperties['height'] | React.CSSProperties['width'];
	color?: ThemeColors;
	margin?: React.CSSProperties['margin'];
};

const defaultProps: Partial<DividerProps> = {
	orientation: 'horizontal',
};

export const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
	({ children, ...rest }, ref) => {
		return (
			<StyledDivider {...rest} ref={ref}>
				{children}
			</StyledDivider>
		);
	}
);

Divider.displayName = 'Divider';
Divider.defaultProps = defaultProps;

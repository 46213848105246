import { z } from 'zod';
import { BaseSchema } from './base.schema';

export const CategoryGroupSchema = BaseSchema.extend({
	name: z.string().max(255),
	name_other_languages: z
		.object({
			zh: z.string().max(40).optional(),
			ms: z.string().max(80).optional(),
		})
		.optional(),
	image_url: z.string().max(2083).nullable().optional(),
	is_allow_same_group_only: z.boolean(),
	is_follow_pos_payment_methods: z.boolean(),
	hex_color: z.string().max(7),
	is_archived: z.boolean().nullable(),
	brand_id: z.number().int(),
	updated_by: z.string().optional(),
})

export type TCategoryGroupSchema = z.infer<typeof CategoryGroupSchema>;

export const CreateCreateCategoryGroupSchema = CategoryGroupSchema.pick({
	name: true,
	name_other_languages: true,
	image_url: true,
	is_allow_same_group_only: true,
	is_follow_pos_payment_methods: true,
	hex_color: true,
	brand_id: true,
});

export const UpdateCreateCategoryGroupSchema = CreateCreateCategoryGroupSchema;

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

type OutletType = {
	id?: number | string;
	name: string;
	mall_id: number | null;
	brand_id: number;
	currency_id: number;
	address_line1: string;
	address_line2?: string;
	zip_code: string;
	phone_number: string;
	tax_reg_number: string;
	dimension_code_prefix: string;
};

export function useGetOutlets(brand_id: number) {
	const { getAccessToken } = useAuth();

	const getOutlets = async () => {
		const response = await configApi.get(`/outlets`, {
			params: {
				brand_id,
				archived: 'no',
				deleted: 'no',
				summary: 'yes',
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['outlets', `brand_${brand_id}`], getOutlets, {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!brand_id,
	});
}

export function useGetArchivedOutlets(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getOutlets = async () => {
		const response = await configApi.get(`outlets`, {
			
			params: {
				brand_id,
				archived: 'yes',
				deleted: 'no',
				summary: 'yes',
			},headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		} );
		return response.data;
	};

	return useQuery(['outletsArchived', brand_id], getOutlets, {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
}

export function useCreateNewOutlet() {
	const { getAccessToken } = useAuth();
	const createNewOutlet = async (body: OutletType) => {
		const response = await configApi.post('outlets', body,{headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(createNewOutlet, {
		onSuccess: () => {
			queryClient.invalidateQueries('outlets');
			queryClient.invalidateQueries('outlets_dropdown');
		},
	});
}

export function useGetOneOutlet(id: number) {
	const { getAccessToken } = useAuth();
	const getOneOutlet = async () => {
		const response = await configApi.get(`outlets/${id}`,{headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	return useQuery(['outlets', id], getOneOutlet, {
		refetchOnWindowFocus: false,
	});
}

export function useUpdateOutlet() {
	const { getAccessToken } = useAuth();
	const updateOutlet = async (body: OutletType) => {
		const id = body.id;
		const updatedOutlet = { ...body, updated_at: new Date() };

		const response = await configApi.put(`outlets/${id}`, updatedOutlet,{headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateOutlet, {
		onSuccess: () => {
			queryClient.invalidateQueries('outlets');
			queryClient.invalidateQueries('outlets_dropdown');
		},
	});
}

export function useDeleteOutlet() {
	const { getAccessToken } = useAuth();
	const deleteOneOutlet = async (id: number) => {
		const response = await configApi.delete(`outlets/${id}`,{headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOneOutlet, {
		onSuccess: () => {
			queryClient.invalidateQueries('outlets');
			queryClient.invalidateQueries('outlets_dropdown');
		},
	});
}

export function useArchiveOutlet() {
	const { getAccessToken } = useAuth();
	const archiveOutlet = async (id: number) => {
		const response = await configApi.post(`outlets/${id}/archive`,{}, {headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(archiveOutlet, {
		onSuccess: () => {
			queryClient.invalidateQueries('outletsArchived');
			queryClient.invalidateQueries('outlets_dropdown');
		},
	});
}

export function useUnarchiveOutlet() {
	const { getAccessToken } = useAuth();
	const unarchiveOutlet = async (id: number) => {
		const response = await configApi.post(`outlets/${id}/unarchive`, {}, {headers: {
			Authorization: `Bearer ${await getAccessToken()}`,
		},});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(unarchiveOutlet, {
		onSuccess: () => {
			queryClient.invalidateQueries('outletsArchived');
			queryClient.invalidateQueries('outlets_dropdown');
		},
	});
}

export function useCheckLinks(id: number) {
	const { getAccessToken } = useAuth();
	const  getLinks = async() => {

		const response = await configApi.get(`outlets/${id}/checklinks`,{
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	}

	return useQuery(['outlets_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})
}

import { Title } from '@mantine/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { SelectField } from '../../../../components';
import { SwitchField } from '../../../../components/SwitchField';

const BillsAndReceipts = () => {
	const { setFieldValue, values } = useFormikContext<any>();
	return (
		<>
			<Title order={2}>Bills and Receipts</Title>
			<SwitchField
				size="lg"
				label="Preview Receipt Screen"
				onLabel="True"
				offLabel="False"
				checked={values?.is_preview_receipt}
				onChange={(e) => {
					setFieldValue('is_preview_receipt', e.currentTarget.checked);
				}}
			/>
			<SelectField
				labelWidth={240}
				width={690}
				label="Number of receipts to print for each new transaction"
				required
				data={['0', '1', '2', '3']}
				value={String(values?.num_receipt_per_transaction)}
				onChange={(value) => {
					if (value) {
						setFieldValue('num_receipt_per_transaction', +value);
					}
				}}
			/>
			<SwitchField
				size="lg"
				label="Display queue number on receipt"
				onLabel="True"
				offLabel="False"
				checked={values?.is_display_queue}
				onChange={(e) => {
					setFieldValue('is_display_queue', e.currentTarget.checked);
				}}
			/>
		</>
	);
};

export default BillsAndReceipts;

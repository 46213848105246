import { Div } from './Div';
import styled from '@emotion/styled';

type Props = {
	children?: React.ReactNode;
};

const ModalWrapper = styled(Div)<Props>`
	max-height: calc(100vh - 110px);
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-behavior: smooth;
`;

export { ModalWrapper };

import styled from '@emotion/styled';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Divider,
	LastUpdatedLabel,
	SelectBrand,
	Text,
	ViewContainer,
} from 'components';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React from 'react';
import Icon from '../../../assets/icons';
import { ErrorType } from './Constants';
import { DeleteEntityModal, PairedWarningModal } from './Modals';
import { currency } from 'lib';

// API Hooks
import { Grid, Group, Stack } from '@mantine/core';
import { useCheckPairedPos } from 'hooks/api/pos/useCheckPairedPos';
import { useGetPosById } from 'hooks/api/pos/useGetPosById';
import { useUpdatePos } from 'hooks/api/pos/useUpdatePos';
import { useGetPaymentMethods } from 'hooks/payments';
import { useAppState } from 'providers/AppStateProvider';
import { useModals } from 'providers/ModalsProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useParams } from 'providers/RouterProvider';
import { theme } from 'providers/ThemeProvider/index';
import { UpdatePosSchema } from 'schemas/pos.schema';
import { useNavigate } from '../../../lib/navigate';
import BasisSettings from './PointOfSaleCreate/BasisSettings';
import BillsAndReceipts from './PointOfSaleCreate/BillsAndReceipts';
import ClosingPosSession from './PointOfSaleCreate/ClosingPosSession';
import Discount from './PointOfSaleCreate/Discount';
import Payment from './PointOfSaleCreate/Payment';
import PosAdvertisement from './PointOfSaleCreate/PosAdvertisement';
import PosInterface from './PointOfSaleCreate/PosInterface';
import PosOperation from './PointOfSaleCreate/PosOperation';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useAuth } from 'providers/AuthProvider';
import { ConfigurationRoutes } from 'router/constants/Configuration.routes';
import RequirePermissions from 'guards/RequirePermissions';
import { useRedirectWhenRefresh } from '../../../hooks/useRedirectWhenRefresh';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';

export const ResetButton = styled(Button)`
	background-color: #fef8f3;
	color: ${({ theme }) => theme.colors.primary};
`;

export const FormTagsListItem = styled.span`
	width: 'fit-content';
	min-height: 30px;
	display: inline-flex;
	align-items: left;
	justify-content: center;
	color: ${theme.colors.grey70};
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 20px;
	margin: 0 8px 8px 0;
	background: white;
	border: 1px solid ${theme.colors.grey70};
	align-items: center;

	span {
		padding-top: 4px;
		white-space: pre;
		display: flex;
		align-self: center;
	}

	/* .tag-title {
		margin-top: 3px;
	} */

	.tag-close-icon {
		display: block;
		width: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color: ${theme.colors.grey70};
		cursor: pointer;
	}
`;

const PointOfSaleView = () => {
	useRedirectWhenRefresh();
	useDisableSelectCompany();
	const { id } = useParams();
	const { permissions, user } = useAuth();
	const navigate = useNavigate();
	const toast = useNotification();
	const { data: pos, isFetching: isFetchingPos } = useGetPosById(
		parseInt(id ?? '0')
	);

	React.useEffect(() => {
		if (!isFetchingPos && !pos?.id) {
			navigate('/configuration/pos');
			toast.error('No POS exists with this ID');
			// return <Navigate to="/configuration/pos" />;
		}
	}, [isFetchingPos, pos]);

	const KitchenReceiptList = () => {
		const kitchen_receipts = pos?.kitchen_receipts ?? [];
		return (
			<>
				<Text
					size={'24px'}
					weight="bold"
					style={{ display: 'flex', alignSelf: 'flex-start' }}
				>
					Kitchen Receipt
				</Text>
				{kitchen_receipts?.map((e: any, idx: number) => {
					return (
						<Stack key={`printer-${idx}`}>
							<Grid>
								<Grid.Col span={4}>
									<Text>{e?.printer_name ?? ''}</Text>
								</Grid.Col>
								<Grid.Col span={4}>
									<Text>{e?.printer_type ?? ''}</Text>
								</Grid.Col>
								<Grid.Col span={4}>
									{e.kitchen_groups.map((kg: string, idx: number) => {
										return (
											<FormTagsListItem
												key={`kitchen-group-${e?.printer_name ?? ''}-${idx}`}
											>
												{kg}
											</FormTagsListItem>
										);
									})}
								</Grid.Col>
							</Grid>
						</Stack>
					);
				})}
			</>
		);
	};

	const modal = useModals();
	const { selectedBrand, isCompanyBCIntegrated } =
		useAppState();

	const { data: paymentMethodsData, isFetching: isFetchingPaymentMethods } =
		useGetPaymentMethods(selectedBrand ?? 0);

	const { mutate: updatePos } = useUpdatePos();

	const breadcrumbsArray = [
		{
			label: 'Point of Sales',
			onClick: () => navigate('/configuration/pos'),
		},
		{
			label: pos?.name ?? ''
		},
	];

	const splitDeviceCodeIntoChunks = (device_code: string) => {
		return device_code?.match(/.{1,4}/g)?.join(' ') ?? '';
	};

	const _assignedPaymentMethods = Object.fromEntries(
		pos?.payment_methods?.map((e: any) => [e.id, e]) || []
	);
	const _brandPaymentMethods = Object.fromEntries(
		paymentMethodsData?.paymentMethods?.map((e: any) => [
			e.id,
			{
				id: e.id,
				pos_payment_code: '',
				sort_order: 99,
				is_active: false,
				is_read_only: e.is_read_only,
			},
		]) || []
	);
	const _merged = Object.values({
		..._brandPaymentMethods,
		..._assignedPaymentMethods,
	});

	const formInitialValues = {
		id: pos?.id ?? 0,
		name: pos?.name ?? '',
		grab_store_id: pos?.grab_store_id ?? '',
		outlet_id: pos?.outlet_id ?? 0,
		outlet_name: pos?.outlet_name ?? '',
		device_code: pos?.device_code ?? '',
		service_charge: Number(pos?.service_charge) ?? 0,
		is_service_charge_in_item_price:
			pos?.is_service_charge_in_item_price ?? false,
		has_quick_sign_in: pos?.has_quick_sign_in ?? true,
		is_display_cash_denominations: pos?.is_display_cash_denominations ?? true,
		is_display_product_price: pos?.is_display_product_price ?? true,
		cash_exact_type: pos?.cash_exact_type ?? 'show_exact_button',
		has_manager_pin_on_view_report:
			pos?.has_manager_pin_on_view_report ?? false,
		is_24h: pos?.is_24h ?? false,
		op_opening_time: pos?.op_opening_time ?? '00:00:00',
		op_closing_time: pos?.is_24h ? null : pos?.op_closing_time ?? '00:30:00',
		closing_grace_period_hour: pos?.closing_grace_period_hour ?? 1,
		number_of_shift: pos?.number_of_shift ?? 1,
		is_change_shift_mandatory: pos?.is_change_shift_mandatory ?? true,
		is_equal_shift_interval: pos?.is_equal_shift_interval ?? true,
		change_shift_intervals: pos?.change_shift_intervals?.split(',') ?? [],
		payment_methods: _merged || [],
		default_opening_cash: currency(pos?.default_opening_cash ?? 0, {
			fromCents: true,
		}).value,
		discounts: pos?.discounts ?? [],
		is_preview_receipt: pos?.is_preview_receipt ?? false,
		is_paired: pos?.is_paired ?? false,
		num_receipt_per_transaction: pos?.num_receipt_per_transaction ?? 1,
		is_display_queue: pos?.is_display_queue ?? false,
		is_allow_zero_cash_collection: pos?.is_allow_zero_cash_collection ?? false,
		authorised_difference: currency(pos?.authorised_difference ?? 0, {
			fromCents: true,
		}).value,
		has_report_preview_before_confirm:
			pos?.has_report_preview_before_confirm ?? false,
		advertisement_duration_seconds: pos?.advertisement_duration_seconds ?? 1,
		advertisements: pos?.advertisements || ([] as string[]),
		updated_at: pos?.updated_at ?? new Date(),
		updated_by: user?.name ?? '',
	};

	const onDeleteButtonClickHandler = () => {
		useCheckPairedPos(parseInt(id || '0')).then((is_paired) => {
			if (is_paired) {
				modal.showModal({
					title: '',
					body: <PairedWarningModal close={modal.closeModal} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteEntityModal
							close={modal.closeModal}
							data={{ id: Number(id) }}
						/>
					),
				});
			}
		});
	};

	return (
		<ViewContainer
			title={
				<>
					<Group w="100%" position="apart">
						<Breadcrumbs items={breadcrumbsArray} />
						<RequirePermissions permissions={['PosConfig.Delete']}>
							<Button
								color="red"
								leftIcon={<Icon name="delete" />}
								onClick={onDeleteButtonClickHandler}
							>
								Delete
							</Button>
						</RequirePermissions>
					</Group>
					<Group w="100%" position="apart">
					<SelectBrand my="lg" disabled />
					<LastUpdatedLabel
							lastUpdated={pos?.updated_at}
							editor={pos?.updated_by}/>
					</Group>
				</>
			}
		>
			<Divider />

			<Grid>
				<Grid.Col lg={12}>
					<Stack>
						<Formik
							enableReinitialize
							initialValues={formInitialValues}
							validate={(values) => {
								try {
									UpdatePosSchema.parse(values);
									const errors: ErrorType = {};

									// Validates that the closing time comes after the opening time
									if (
										!values.is_24h &&
										!dayjs(`1970-01-01 ${values.op_closing_time}`).isAfter(
											dayjs(`1970-01-01 ${values.op_opening_time}`)
										)
									) {
										errors.op_closing_time =
											'Closing time must be after opening time';
									}

									// Validates that the number of shift timings chosen is 1 less than number of total shifts
									if (
										values.number_of_shift > 1 &&
										!values.is_equal_shift_interval &&
										values.number_of_shift -
											values.change_shift_intervals.length !==
											1
									) {
										errors.change_shift_intervals =
											'Total selected intervals must be one less than number of shifts';
									}

									// Validates pos_payment_code exist if payment method selected
									if (
										isCompanyBCIntegrated &&
										values.payment_methods.some((p: any) => {
											return p.is_active && !p.pos_payment_code;
										})
									) {
										errors.payment_methods = 'This field is required';
									}
									return errors;
								} catch (error: any) {
									return error.formErrors.fieldErrors;
								}
							}}
							onSubmit={async (values, actions) => {
								const {
									outlet_name,
									updated_at,
									authorised_difference,
									default_opening_cash,
									...restOfValues
								} = values;
								updatePos(
									{
										authorised_difference: currency(authorised_difference)
											.intValue,
										default_opening_cash:
											currency(default_opening_cash).intValue,
										...restOfValues,
									},
									{
										onSuccess: () => {
											toast.success(
												'POS settings has been successfully updated'
											);
											navigate('/configuration/pos', {}, true);
										},
										onError: () => {
											toast.error('An error occured');
										},
									}
								);
							}}
						>
							{({ dirty, submitForm, isSubmitting }) => {

								(permissions?.includes("PosConfig.Edit")) 
									&& useStopNavigateDirtyForm(dirty);

								return (
									<fieldset disabled={!permissions?.includes("PosConfig.Edit") 
										|| isSubmitting} >
										<Stack w="100%" mt={30} spacing="xl">
											{/* <JsonInput
												value={JSON.stringify({ values, errors }, null, 2)}
												autosize
												maxRows={20}
											/> */}
											<BasisSettings showDeviceCode />
											<Divider />
											<PosInterface />
											<Divider />
											<PosOperation />
											<Divider />
											<Payment />
											<Divider />
											<Discount />
											<Divider />
											<BillsAndReceipts />
											<Divider />
											<ClosingPosSession />
											<Divider />
											<PosAdvertisement />
											<Group my="xl">
												<RequirePermissions permissions={['PosConfig.Edit']}>
													<Button onClick={() => submitForm()}>Save changes</Button>
													<CancelButton
														onClick={() => navigate(ConfigurationRoutes.Pos)}
													>
														Cancel
													</CancelButton>
												</RequirePermissions>
											</Group>
										</Stack>
									</fieldset>
								);
							}}
						</Formik>
						<Stack>
							<Divider />
							<KitchenReceiptList />
						</Stack>
					</Stack>
				</Grid.Col>
			</Grid>
		</ViewContainer>
	);
};

export default PointOfSaleView;

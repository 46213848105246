import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCopyProducts() {
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({
			product_ids,
			category_ids,
		}: {
			product_ids: number[];
			category_ids: number[];
		}) =>
			configApi
				.post(
					`/categories/copy-products`,
					{
						product_ids,
						category_ids,
					},
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data)
	);
}

import {
	Box,
	BoxProps,
	Grid,
	Image,
	Text,
	UnstyledButton,
} from '@mantine/core';
import React from 'react';
import { useFilePreviewContext } from './FilePreview';
import folderIcon from './folder-icon.svg';

export type FolderListProps = {
	folders?: {
		Prefix: string;
	}[];
	title: string;
} & BoxProps;

export const FolderList = React.forwardRef<HTMLDivElement, FolderListProps>(
	({ folders, title }, ref) => {
		const { setPrefix } = useFilePreviewContext();

		if (!folders) {
			return null;
		}
		return (
			<Box component="ul" sx={{ listStyle: 'none' }} w="100%">
				{folders?.map(({ Prefix }) => {
					const folderName = Prefix.split('/').at(-2);
					return (
						<Box component="li" key={`${title}-folder-${folderName}`}>
							<UnstyledButton w="100%" p="sm" onClick={() => setPrefix(Prefix)}>
								<Grid
									w="100%"
									sx={(theme) => ({
										':hover': {
											background: theme.colors.gray[1],
										},
									})}
									align="center"
								>
									<Grid.Col span={2}>
										<Image
											width={80}
											height={60}
											fit="contain"
											color="blue"
											src={folderIcon}
										/>
									</Grid.Col>
									<Grid.Col span={10}>
										<Text>{folderName}</Text>
									</Grid.Col>
								</Grid>
							</UnstyledButton>
						</Box>
					);
				})}
			</Box>
		);
	}
);

FolderList.displayName = 'FolderList';

import { CSSObject } from '@emotion/react';
import { theme } from '../../providers/ThemeProvider/theme';
import { CSSObjectFunction } from '../types/DefaultProps';

export function isCssObjectFunction(css: unknown): css is CSSObjectFunction {
	if (typeof css === 'function') {
		return true;
	}
	return false;
}

export function isCssObject(css: unknown): css is CSSObject {
	if (typeof css === 'object') {
		return true;
	}
	return false;
}

export function resolveCssProp(css: CSSObjectFunction | CSSObject) {
	if (isCssObjectFunction(css)) {
		return css(theme);
	}
	return css;
}

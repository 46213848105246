import React from 'react';
import { Field, Formik } from 'formik';
import { Button, Box} from '@mantine/core';
import { FormTextInput, Stack } from 'components';
import { useCreateFolder } from 'hooks/images';
import { useNotification } from 'providers/NotificationProvider';

type AddFolderProps = {
	prefix: string;
	refetchFiles: () => void;
	closeModal: () => void;
};

type Error = {
	folderName?: string;
};

const AddFolder = ({ prefix, closeModal, refetchFiles }: AddFolderProps) => {

	const toast = useNotification();
	const { mutate: createFolder } = useCreateFolder();

	return (
		<Formik
			initialValues={{
				folderName: "",
			}}
			validate={(values) => {
				
				const errors: Error = {}

				if(!values.folderName){
					errors.folderName = 'Folder name is required.'
				}

				return errors
			}}
			onSubmit={async (values, actions) => {
				const folderName = values.folderName
				createFolder({ folderName, prefix },{
					onSuccess: () => {
						refetchFiles()
						closeModal()
					},
					onError: () => {
						toast.error(`An error occured`);
					}
				})
				actions.setSubmitting(false)
					
			}}
		>

			{({ errors, touched, isSubmitting, submitForm }) => {

				return (
					<Box>
						<Stack style={{marginTop:"20px", marginBottom:"20px"}}>
							<Field
								width="450px"
								as={FormTextInput}
								isRequired={true}
								name="folderName"
								label="Folder Name"
								maxLength={80}
								error={errors.folderName && touched.folderName ? errors.folderName : null}
							/>
							
						</Stack>
						<Stack direction="row" width="100%" style={{justifyContent:"flex-end"}}>
							<Button 
								onClick={submitForm}
								loading={isSubmitting}
								loaderPosition='center'
								disabled={isSubmitting}> 
								
								Add
							
							</Button>
						</Stack>
						
					</Box>
				)
			}}

		</Formik>
		
	);
};

export default AddFolder;

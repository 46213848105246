import React from 'react';
import {
	ActionIcon,
	Button,
	Div,
	FormCheckBox,
	FormTextInput,
	Image,
	ListGroup,
	SelectField,
	Stack,
	Text,
	Tooltip,
	FormPriceInput,
} from 'components';
import { Select } from '@mantine/core';
import {
	Table,
	TableHead,
	TableHeadCol,
	TableRow,
	TableDataCol,
	TableIconCol,
} from './ServiceHours.components';
import { useNotification } from 'providers/NotificationProvider';
import { timeDropdown, Days } from './constants';
import { Center } from '@mantine/core';
import dayjs from 'dayjs';

type Period = {
	startTime: string;
	endTime: string;
};

type _ServiceHoursType = {
	openPeriodType?: string;
	fullname?: string;
	periods?: Period[];
};

type ServiceHours = {
	[key: string]: _ServiceHoursType;
};

type OptionsListType = {
	setFieldValue: (field: string, value: any) => void;
	serviceHours: ServiceHours;
};

const ServiceHoursList = ({ setFieldValue, serviceHours }: OptionsListType) => {
	const toast = useNotification();

	return (
		<Stack direction="column">
			<Text
				size={'24px'}
				weight="bold"
				style={{ display: 'flex', alignSelf: 'flex-start' }}
			>
				Service Hours
			</Text>
			<Table style={{ width: '100%' }}>
				<TableHead>
					<TableHeadCol>Day</TableHeadCol>
					<TableHeadCol>Start Time</TableHeadCol>
					<TableHeadCol>End Time</TableHeadCol>
					<TableHeadCol></TableHeadCol>
				</TableHead>

				{Object.keys(serviceHours).map((day) => {
					return (
						<TableRow key={day}>
							<TableDataCol width="120px">
								{Days[day as keyof typeof Days] ?? day.toUpperCase()}
							</TableDataCol>
							<TableDataCol width="210px">
								<Center>
									<Select
										data={timeDropdown}
										placeholder="Please Select"
										value={serviceHours[day]?.periods?.at(0)?.startTime ?? ''}
										onChange={(value) => {
											if (
												serviceHours[day]?.periods?.at(0)?.endTime &&
												dayjs(`1970-01-01 ${value}:00`).isAfter(
													`1970-01-01 ${
														serviceHours[day]?.periods?.at(0)?.endTime
													}:00`
												)
											) {
												toast.error('Start Time must be before End Time');
											} else if (
												serviceHours[day]?.periods?.at(0)?.endTime &&
												dayjs(`1970-01-01 ${value}:00`).isSame(
													`1970-01-01 ${
														serviceHours[day]?.periods?.at(0)?.endTime
													}:00`
												)
											) {
												toast.error('Start and End Times must be different');
											} else {
												setFieldValue(
													`serviceHours[${day}].openPeriodType`,
													'OpenPeriod'
												);
												setFieldValue(
													`serviceHours[${day}].periods[0].startTime`,
													value
												);
											}
										}}
									/>
								</Center>
							</TableDataCol>
							<TableDataCol width="210px">
								<Center>
									<Select
										data={timeDropdown}
										placeholder="Please Select"
										value={serviceHours[day]?.periods?.at(0)?.endTime ?? ''}
										onChange={(value) => {
											if (
												serviceHours[day]?.periods?.at(0)?.startTime &&
												dayjs(`1970-01-01 ${value}:00`).isBefore(
													`1970-01-01 ${
														serviceHours[day]?.periods?.at(0)?.startTime
													}:00`
												)
											) {
												toast.error('End Time must be after Start Time');
											} else if (
												serviceHours[day]?.periods?.at(0)?.startTime &&
												dayjs(`1970-01-01 ${value}:00`).isSame(
													`1970-01-01 ${
														serviceHours[day]?.periods?.at(0)?.startTime
													}:00`
												)
											) {
												toast.error('Start and End Times must be different');
											} else {
												setFieldValue(
													`serviceHours[${day}].openPeriodType`,
													'OpenPeriod'
												);
												setFieldValue(
													`serviceHours[${day}].periods[0].endTime`,
													value
												);
											}
										}}
									/>
								</Center>
							</TableDataCol>
							<TableDataCol width="max-content">
								<Button
									variant="light"
									onClick={() => {
										setFieldValue(`serviceHours[${day}]`, {
											openPeriodType: 'CloseAllDay',
										});
									}}
								>
									Clear
								</Button>
							</TableDataCol>
						</TableRow>
					);
				})}
			</Table>
		</Stack>
	);
};

export default ServiceHoursList;

import { z } from 'zod';
import { BaseSchema } from './base.schema';

export const ProductSchema = BaseSchema.extend({
	name: z.string().max(255),
	name_other_languages: z.object({
		zh: z.string().max(40).optional(),
		ms: z.string().max(80).optional(),
	}),
	display_label: z.string().max(255).optional(),
	sort_order: z.number().default(0),
	bc_code: z.string().max(255),
	bc_name: z.string().max(255),
	image_url: z.string().max(2083).optional(),
	price: z.number().default(0),
	end_date: z.string().nullable().default(null),
	is_kitchen_item: z.boolean().default(false),
	is_open_price: z.boolean().default(false),
	category_id: z.number().nullable().default(null).optional(),
	brand_id: z.number().int().optional(),
	grab_price: z.number().default(0),
	updated_by: z.string().default("")
});

export type TProductSchema = z.infer<typeof ProductSchema>;

export const CreateProductSchema = ProductSchema.pick({
	name: true,
	name_other_languages: true,
	display_label: true,
	image_url: true,
	// price: true,
	end_date: true,
	is_kitchen_item: true,
	category_id: true,
	price: true,
	grab_price:true,
	updated_by: true
}).extend({
	kitchen_group_id: z.number().int().optional().nullable(),
	option_groups: z.array(z.number().int()).optional(),
	grab_option_groups: z.array(z.number().int()).optional(),
});

export const EditProductsSchema = CreateProductSchema

export const ProductSchemaWithPos = ProductSchema.extend({
	pos_name: z.string(),
});


export const CreateProductWithBcIntegrationSchema = ProductSchema.pick({
	name: true,
	name_other_languages: true,
	display_label: true,
	bc_name: true,
	bc_code: true,
	image_url: true,
	// price: true,
	end_date: true,
	is_kitchen_item: true,
	category_id: true,
	price: true,
	grab_price:true,
	updated_by: true
}).extend({
	kitchen_group_id: z.number().int().optional().nullable(),
	option_groups: z.array(z.number().int()).optional(),
	grab_option_groups: z.array(z.number().int()).optional(),
});
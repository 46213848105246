import { SelectField, Title, useFormikContext } from 'components';
import React from 'react';
import { FieldWrapper } from '../../../../components/FieldWrapper';
import { durationDropdown } from '../Constants';
import AddAdvertisement from './PosAdvertisment/AddAdvertisement';

const PosAdvertisement = () => {
	const { setFieldValue, values } = useFormikContext<any>();
	return (
		<>
			<Title order={2}>POS Advertisement</Title>
			<FieldWrapper label="Add Images or Videos">
				<AddAdvertisement />
			</FieldWrapper>
			<SelectField
				labelWidth={240}
				width={690}
				label="Each advertisement will be displayed for"
				placeholder="Please Select"
				data={durationDropdown}
				value={String(values?.advertisement_duration_seconds)}
				onChange={(value) => {
					if (value) {
						setFieldValue('advertisement_duration_seconds', +value);
					}
				}}
			/>
		</>
	);
};

export default PosAdvertisement;

import styled from '@emotion/styled';
import { Div } from '../Div';
import { ImageProps } from './Image';

export const StyledImageWrapper = styled(Div)<
	Pick<ImageProps, 'width' | 'height' | 'imageWrapperProps' | 'fit'>
>`
	position: relative;
	width: ${({ width }) => width ?? '100%'};
	overflow: hidden;

	${({ fit: objectFit, width, height }) => `
   > img {
    display: block;
    width: ${width ?? '100%'}; 
    height: ${height ?? 'auto'}; 
    object-fit: ${objectFit ?? 'cover'}; 
    }
    `};
`;

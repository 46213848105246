import React from 'react';
import { DivProps } from '../Div';
import { StyledOverlay } from './Overlay.styled';

export type OverlayProps = DivProps & {
	fill?: boolean;
};

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
	(props, ref) => {
		return <StyledOverlay {...props} ref={ref} />;
	}
);

Overlay.displayName = 'Overlay';

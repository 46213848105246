import { Box, Group, Text} from '@mantine/core';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	Divider,
	FormTextInput,
	ImageUploader,
	LastUpdatedLabel,
	SelectBrand,
	Stack,
	TextField,
	ViewContainer,
} from 'components';
import { Field, Formik } from 'formik';
import { useCheckLinks } from 'hooks/api/paymentmethods/useCheckLinks';
import { useEditPaymentMethod } from 'hooks/api/paymentmethods/useEditPaymentMethod';
import { useGetPaymentMethodById } from 'hooks/api/paymentmethods/useGetPaymentMethodById';
import { useAppState } from 'providers/AppStateProvider';
import { useAuth } from 'providers/AuthProvider';
import { useModals } from 'providers/ModalsProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useParams } from 'providers/RouterProvider';
import React from 'react';
import {
	PaymentMethodSchemaUpdate,
	PaymentMethodSchemaUpdateWithBC,
} from 'schemas/payment_method.schema';
import Icon from '../../../assets/icons';
import { SwitchField } from '../../../components/SwitchField';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useNavigate } from '../../../lib/navigate';
import { DeleteEntityModal, LinkedEntityModal } from './Modals';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import RequirePermissions from '../../../guards/RequirePermissions';

const PaymentView = () => {
	useDisableSelectCompany();
	const { id } = useParams();
	const navigate = useNavigate();
	const modal = useModals();
	const { permissions, user } = useAuth();
	const {  isCompanyBCIntegrated } = useAppState();

	const { data: payment_method, isFetching: isFetchingPaymentMethod } =
		useGetPaymentMethodById(Number(id));
	const notify = useNotification();

	const { mutateAsync: editOnePaymentMethod, isLoading } =
		useEditPaymentMethod();

	const onDeleteClickHandler = () => {
		useCheckLinks(Number(id)).then((links) => {
			if (links.pos.length > 0 || links.category_groups.length > 0) {
				modal.showModal({
					title: '',
					body: <LinkedEntityModal close={modal.closeModal} data={links} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteEntityModal
							close={modal.closeModal}
							data={{ id: Number(id), name: payment_method?.name ?? '' }}
						/>
					),
				});
			}
		});
	};

	const breadcrumbsArray = [
		{
			label: 'Payment Method',
			onClick: () => navigate('/configuration/payment'),
		},
		{
			label: payment_method?.name || '',
		},
	];
	return (
		<>

			{!isFetchingPaymentMethod && (
				<Formik
					initialValues={{
						id: payment_method?.id ?? 0,
						name: payment_method?.name ?? '',
						payment_code: payment_method?.payment_code ?? '',
						image_url: payment_method?.image_url ?? '',
						is_cash_payment: payment_method?.is_cash_payment ?? false,
						is_read_only: payment_method?.is_read_only ?? false,
						is_discount_compatible:
							payment_method?.is_discount_compatible ?? true,
						updated_by: user?.name || '',
					}}
					validate={(values) => {
						try {
							isCompanyBCIntegrated
								? PaymentMethodSchemaUpdateWithBC.parse(values)
								: PaymentMethodSchemaUpdate.parse(values);
						} catch (error: any) {
							return error.formErrors.fieldErrors;
						}
					}}
					onSubmit={async (values, actions) => {
						actions.setSubmitting(true);
						await editOnePaymentMethod(
							{
								updatedPaymentMethod: values,
								paymentMethodId: id || '',
							},
							{
								onSuccess: () => {
									notify.success(
										'Payment method has been successfully updated'
									);
									navigate('/configuration/payment', {}, true);
								},
							}
						);
					}}
				>
					{({
						values,
						errors,
						dirty,
						touched,
						setFieldValue,
						submitForm,
						isSubmitting,
					}) => {
						(permissions?.includes("PaymentMethods.Edit"))
							&& useStopNavigateDirtyForm(dirty)
						return (
							<>
								<ViewContainer>
								<Box style={{ position: 'relative', marginBottom: '20px' }}>
									<Breadcrumbs items={breadcrumbsArray} />
									<RequirePermissions permissions={['PaymentMethods.Delete']}>
										<Div
											style={{
												display: 'inline-flex',
												position: 'absolute',
												right: 0,
												top: 0,
												gap: '15px',
											}}
										>
											<Button
												disabled={values?.is_read_only}
												color="red"
												leftIcon={<Icon name="delete" />}
												onClick={onDeleteClickHandler}
											>
												Delete
											</Button>
						
										</Div>

									</RequirePermissions>

									<Box style={{ clear: 'both' }}></Box>
								</Box>
								<Group w="100%" position="apart" mb={20}>
									<SelectBrand disabled />
									<LastUpdatedLabel
										lastUpdated={payment_method?.updated_at ?? new Date()}
										editor={payment_method?.updated_by ?? ''}
									/>
								</Group>
								<Divider/>
								<fieldset disabled={!permissions?.includes("PaymentMethods.Edit") 
										|| isSubmitting} >
								<Box style={{
									width:'100%',
									marginTop:'30px'
									}}>
									<Stack direction="row" gap={30}>
										<Stack direction="column" gap={10}>
												
											<Field
												width="600px"
												as={FormTextInput}
												name="name"
												label="Payment Method Name"
												isRequired={true}
												disabled={values?.is_read_only}
												maxLength={80}
												value={values?.name}
												onChange={(e:any) =>
													setFieldValue('name', e.currentTarget.value)
												}
												error={
													errors.name && touched.name
														? (errors.name as string)
														: null
												}
											/>
											<TextField
												name="payment_code"
												label="Payment Code"
												hidden
												required={isCompanyBCIntegrated}
												maxLength={30}
												value={values?.payment_code}
												onChange={(e) =>
													setFieldValue('payment_code', e.currentTarget.value)
												}
												error={
													errors.payment_code && touched.payment_code
														? (errors.payment_code as string)
														: null
												}
											/>
											<SwitchField
												width='600px'
												name="is_cash_payment"
												label="This is a Cash payment"
												size="lg"
												checked={values?.is_cash_payment}
												disabled={values?.is_read_only}
												onChange={(e) => {
													setFieldValue(
														'is_cash_payment',
														e.currentTarget.checked
													);
												}}
												onLabel="True"
												offLabel="False"
												hint="Indicate a type of payment method as Cash. When marked TRUE, Cash drawer will open and number pad will be displayed to allow staff to enter cash collected amount from customer."
											/>
											<SwitchField
												width='600px'
												name="is_discount_compatible"
												label="Allow Discount to be applied on this payment method"
												size="lg"
												checked={values.is_discount_compatible}
												onChange={(e) => {
													setFieldValue(
														'is_discount_compatible',
														e.currentTarget.checked
													);
												}}
												onLabel="True"
												offLabel="False"
											/>
											<Stack
												width={"100%"}
												style={{
													display:'flex',
													justifyContent:'flex-start',
													marginTop:'15px'
												}}
											>
												<RequirePermissions permissions={['PaymentMethods.Edit']}>
													<Button onClick={submitForm} loading={isLoading}>
														Save Changes
													</Button>
												</RequirePermissions>
												<CancelButton
													onClick={() => navigate('/configuration/payment')}
													loading={isLoading}
												>
													Cancel
												</CancelButton>
											</Stack>
										</Stack>
										<Stack direction="column" style={{
											alignSelf:"flex-start"
											}}>
											<ImageUploader
												url={values['image_url']}
												onChange={(url) => setFieldValue('image_url', url)}
												inactiveTabs={[
													'Products',
													'Category Groups',
													'Discounts',
													'Brands',
													'Advertisements',
												]}
											/>
											<Text mt={15} align="center" fs="italic" size="xs" color="gray" >
												Recommended Dimensions: W 330px x H 120px
											</Text>
										</Stack>
									</Stack>
								</Box>
							</fieldset>

							</ViewContainer>
							</>
						);
					}}
				</Formik>
			)
		}
	</>);
};

export default PaymentView;

/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Accordion,
	Box,
	Button,
	Checkbox,
	Collapse,
	Group,
	Loader,
	Pagination,
} from '@mantine/core';
import { useAppState } from '../../providers/AppStateProvider';
import { useGetBrands } from '../../hooks/brands';
import { modals } from '@mantine/modals';
import { EmptyState, SelectBrand, ViewContainer } from 'components';
import { nanoid } from 'nanoid';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../assets/icons';
import { useGetCategories } from '../../hooks';
import { useNavigate } from '../../lib/navigate';
import { ProductRoutes } from '../../router/constants/Products.routes';
import CategoriesSearchFilter from './Categories/CategoriesSearchFilter';
import Category from './Categories/Category';
import { useCategoriesContext } from './Categories/context';
import CopyProducts from './Categories/modals/CopyProducts';
import { Add } from '@mui/icons-material';
import { useAuth } from '../../providers/AuthProvider';
import RequirePermissions from '../../guards/RequirePermissions';

const Categories = () => {
	const { selectedCompany } = useAppState();
	const { permissions } = useAuth();
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const page = params.get('page');

	const { searchParams, disableDnd, setSearchParams } = useCategoriesContext();
	const { data: brandsData } = useGetBrands(selectedCompany!);
	const {
		data: categories,
		isLoading,
		isFetching,
		refetch,
	} = useGetCategories({
		...searchParams,
		category_group_id: searchParams?.category_group_id?.join(',') || '',
	});

	const { numSelectProducts, selectedProducts, setSelectedProducts } =
		useCategoriesContext();

	const allProducts =
		categories?.categories
			?.map((cat) => cat.products)
			.flat()
			.map((e) => e.id) || [];

	React.useEffect(() => {
		if (categories?.categories && allProducts) {
			setSelectedProducts(
				Object.fromEntries(allProducts.map((e) => [e, false]))
			);
		}
	}, []);

	React.useEffect(() => {
		if (page) {
			setSearchParams((p) => ({
				...p,
				requested_page: +page,
			}));
		}
	}, [page]);

	return (
		<ViewContainer title="Categories">
			{/* <pre>{JSON.stringify(categories, null, 2)}</pre> */}
			<Group w="100%" position="apart" my="xl">
				<SelectBrand />
				<Group>
					<RequirePermissions permissions={['Categories.Archive']}>
						<Button
							color="gray.2"
							style={{ color: 'black' }}
							variant="light"
							leftIcon={<Icon name="archive" />}
							size="md"
						>
							Archived
						</Button>
					</RequirePermissions>
					{brandsData?.brands?.length ? (
						<RequirePermissions permissions={['Categories.Create']}>
							<Button
							size="md"
							leftIcon={<Icon name="add" />}
							onClick={() => navigate(ProductRoutes.CreateCategory)}
							>
								Create Category
							</Button>
						</RequirePermissions>
						
					) : null}
				</Group>
			</Group>
			<CategoriesSearchFilter />
			<Collapse in={numSelectProducts > 0}>
				<Group w="100%" my="lg" py="lg" position="left" spacing="xl">
					<Box>
						<Checkbox
							label={`${numSelectProducts} items selected`}
							checked={numSelectProducts === allProducts?.length}
							indeterminate={
								numSelectProducts > 0 && numSelectProducts < allProducts?.length
							}
							onChange={(e) => {
								if (allProducts && e) {
									setSelectedProducts(
										Object.fromEntries(
											allProducts?.map((p) => [p, e.currentTarget.checked])
										)
									);
								}
							}}
						/>
					</Box>
					<Box>
						<Group>
							<RequirePermissions permissions={['Categories.Create']}>
								<Button
									color="gray.2"
									sx={{ color: 'black' }}
									onClick={() => {
										const modalId = nanoid(12);
										modals.open({
											modalId,
											size: 'xl',
											children: (
												<CopyProducts
													refetch={refetch}
													modalId={modalId}
													productIds={
														Object.entries(selectedProducts)
															.filter(([, value]) => !!value)
															.map(([key]) => +key) || []
													}
												/>
											),
										});
									}}
								>
									Make a copy
								</Button>
								</RequirePermissions>
							<RequirePermissions permissions={['Categories.Archive']}>
								<Button color="gray.2" sx={{ color: 'black' }}>
									Archive
								</Button>
							</RequirePermissions>
							<RequirePermissions permissions={['Categories.Delete']}>
								<Button color="gray.2" sx={{ color: 'black' }}>
										Delete
								</Button>
							</RequirePermissions>
						</Group>
					</Box>
				</Group>
			</Collapse>
			<Accordion
				miw="950px"
				chevronPosition="left"
				styles={{
					content: {
						padding: 0,
					},
				}}
				multiple={disableDnd}
				defaultValue={
					disableDnd
						? categories?.categories?.map(
								(e) => `category-accordion-item-${e.id}`
						  )
						: null
				}
			>
				{isLoading || isFetching ? (
					<Loader />
				) : !brandsData?.brands?.length ? (
					<EmptyState
						resource="brands"
						secondaryMessage="Please create Brand before creating a Category."
						CallToAction={
							permissions?.includes('Brands.Create') && (
								<Button
									type="button"
									leftIcon={<Add fontSize="inherit" />}
									onClick={() => navigate('/configuration/brands/create')}
								>
									Create Brand
								</Button>
							)
						}
					/>
				) : !categories?.categories?.length ? (
					<EmptyState
						resource="Categories"
						CallToAction={
							permissions?.includes('Categories.Create') && (
								<Button
									size="md"
									color="primary"
									leftIcon={<Icon name="add" />}
									onClick={() => navigate(ProductRoutes.CreateCategory)}
								>
									Create Category
								</Button>)
						}
					/>
				) : (
					categories?.categories?.map((e) => (
						<Category
							key={`category-accordion-item-${e.id}`}
							value={`category-accordion-item-${e.id}`}
							categoryId={e?.id}
							categoryName={e?.name}
							categoryGroup={e?.category_group}
							description={e?.description}
							acctCategory={e?.acct_category}
							products={e?.products}
							lastUpdated={e?.updated_at}
							updatedBy={e?.updated_by}
							pos={e?.pos}
						/>
					))
				)}
			</Accordion>

			<Pagination
				my="lg"
				position="right"
				value={searchParams?.requested_page}
				total={categories?.num_of_pages ?? 1}
				onChange={(page) =>
					setSearchParams((p) => ({
						...p,
						requested_page: page,
					}))
				}
			/>
		</ViewContainer>
	);
};
export default Categories;

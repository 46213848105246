import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function usePostPosSortOrder() {
	const { getAccessToken } = useAuth();
	const patchPosSortOrder = async (values: {
		pos_id: number;
		categories: number[];
	}) => {
		const { data } = await configApi.post(
			`/pos/${values.pos_id}/categories`,
			{
				categories: values?.categories,
			},
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return data;
	};

	return useMutation(patchPosSortOrder);
}

/* eslint-disable no-mixed-spaces-and-tabs */
import { Button, Group, JsonInput, Text } from '@mantine/core';
import { Field, Formik, useFormikContext } from 'formik';
import { currency } from 'lib';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	Box,
	BcItemProps,
	BcItemSelectItem,
	Breadcrumbs,
	DateField,
	Divider,
	MultiSelectField,
	SearchableDropdownField,
	SelectBrand,
	SelectField,
	Stack,
	ViewContainer,
	ImageUploader,
	CancelButton,
	FormTextInput,
	FormPriceInput
} from '../../components';
import { SwitchField } from '../../components/SwitchField';
import { useGetCategoryById, useGetOptionGroups } from '../../hooks';
import { useGetKitchenGroups } from '../../hooks/kitchengroups';
import { useGetProductDiscounts } from 'hooks/discounts';
import { useCreateProduct } from '../../hooks/api/products/useCreateProduct';
import { useGetBcItems } from '../../hooks/bcitems';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { ProductRoutes } from '../../router/constants/Products.routes';
import { BcItem } from '../../schemas/bc_item.schema';
import {
	CreateProductSchema,
	CreateProductWithBcIntegrationSchema,
} from '../../schemas/product.schema';
import LocalisedPricing from './CreateProduct/LocalisedPricing';
import { useAuth } from 'providers/AuthProvider';
import { ProductGrabIntegration } from './ProductGrabIntegration';
import { useGetCategoryGrabStatus } from '../../hooks';

const _CreateProduct = () => {
	const {
		values,
		errors,
		setFieldValue,
		submitForm,
		isValid,
		setFieldError,
		touched,
		isSubmitting,
	} = useFormikContext<any>();
	const [searchParams] = useSearchParams();
	const { selectedBrand, isCompanyBCIntegrated } = useAppState();
	const notify = useNotification();
	const { data } = useGetCategoryById(values?.category_id);
	const { data: kitchenGroupsData } = useGetKitchenGroups(selectedBrand!);
	const { data: optionGroupsData } = useGetOptionGroups(selectedBrand!);
	const { data: response_discounts } = useGetProductDiscounts(selectedBrand!);

	const [bcSearchTerm, setBcSearchTerm] = React.useState<string>('');
	const [bcSearchResult, setBcSearchResult] = React.useState<BcItem[]>([]);
	const { data: bcItemData } = useGetBcItems(bcSearchTerm);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (bcItemData) {
			setBcSearchResult(bcItemData);
		}
	}, [bcItemData]);

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Group w="100%" position="apart">
					<Breadcrumbs
						items={[
							{
								label: 'Categories',
								onClick: () => navigate(ProductRoutes.Categories),
							},
							{
								label: data?.name || '',
								onClick: () => navigate(ProductRoutes.Categories)
							},
							{ label: values?.name || 'Create Product' },
						]}
					/>
					
				</Group>
				<Group w="100%" position="apart" my="xl">
					<SelectBrand disabled />
				</Group>
				<Divider/>
			</Box>
			<Box style={{ width: '100%' }}>
				<Stack direction="row" gap={30}>
								
					{searchParams.get('debug') === 'true' ? (
						<JsonInput
							my="md"
							maxRows={15}
							autosize
							value={JSON.stringify({ values, errors, touched }, null, 2)}
						/>
					) : null}

					<Stack direction="column" gap={10}>
						<Field
							width="600px"
							as={FormTextInput}
							label="Product Name"
							isRequired={true}
							name="name"
							maxLength={80}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								setFieldValue('name', e.currentTarget.value);

								if (e.currentTarget.value) {
									setFieldError('name', '');
								}
							}}
							error={
								touched['name'] &&
								(errors['name'] as string) &&
								'This field is required.'
							}
						/>
						<Field
							width="600px"
							as={FormTextInput}
							label="Chinese Name"
							name="name_other_languages.zh"
							maxLength={40}
						/>
						<Field
							width="600px"
							as={FormTextInput}
							label="Malay Name"
							name="name_other_languages.ms"
							maxLength={80}
						/>

						<Field
							width="600px"
							as={FormTextInput}
							label="Category"
							name="category_id"
							value={data?.name}
							onChange={() => {
								return;
							}}
							disabled
						/>
						<SwitchField
							width="600px"
							required
							label="Make this product open price"
							name="is_open_price"
							size="lg"
							onLabel="True"
							offLabel="False"
							checked={values?.is_open_price}
							onChange={(e:any) => {
								setFieldValue('is_open_price', e.currentTarget.checked);
							}}
						/>
						<FormPriceInput
							width="600px"
							placeholder="Example 0.00"
							precision={2}
							required={true}
							step={0.1}
							hidden={values?.is_open_price}
							value={parseFloat(values?.price) ?? 0.0}
							disabled={values?.is_open_price ?? false}
							min={0.01}
							onChange={(value: any) => {
								if (value === '') {
									value = 0;
								}
								setFieldValue('price', value);
							}}
							label="Default Selling Price ($)"
							name="price"
						/>
						<Field width="600px" as={FormTextInput} label="Display Label" name="display_label" />
						{isCompanyBCIntegrated && (
							<SearchableDropdownField
								label="BC Item Code"
								name="bc_code"
								placeholder="Search Item Code or Item Name"
								required
								data={
									bcSearchResult?.map((e: BcItem) => ({
										name: e?.item_name,
										label: e?.item_code,
										value: `${e?.item_code},${e?.item_name}`,
									})) || []
								}
								searchable={true}
								itemComponent={BcItemSelectItem}
								onChange={(selectedItem: string) => {
									const itemDetail = selectedItem?.split(',');
									setFieldValue('bc_code', itemDetail?.at(0));
									setTimeout(() => {
										setFieldValue('bc_name', itemDetail?.at(1));
									}, 1);
								}}
								onSearchChange={(query: string) => {
									setBcSearchTerm(query);
								}}
								filter={(value: string, item: BcItemProps) =>
									item?.name
										?.toLowerCase()
										?.includes(value?.toLowerCase()?.trim()) ||
									item?.value
										?.toLowerCase()
										?.includes(value?.toLowerCase()?.trim())
								}
								error={
									((touched['bc_code'] && (errors['bc_code'] as string)) ||
										(touched['bc_name'] && (errors['bc_name'] as string))) &&
									'This field is required.'
								}
							/>
						)}
						<DateField
							width="600px"
							label="Expiry Date"
							name="end_date"
							onChange={(date) =>
								setFieldValue('end_date', date?.toISOString())
							}
							valueFormat="DD/MM/YYYY"
						/>

						<MultiSelectField
							width="600px"
							label="Option Groups"
							disabled={values?.is_open_price}
							data={
								optionGroupsData?.option_groups?.map((e) => ({
									value: e.id.toString(),
									label: e.name,
								})) || []
							}
							onChange={(selectedItems) => {
								setFieldValue(
									'option_groups',
									selectedItems.map((e) => +e)
								);
							}}
						/>
						<SelectField
							width={450}
							name="kitchen_group_id"
							label="Kitchen Groups"
							labelWidth={150}
							data={
								kitchenGroupsData?.kitchen_groups?.map((e: any) => ({
									value: e.id.toString(),
									label: e.name,
								})) || []
							}
							clearable
							onChange={(value) => {
								if (value) {
									setFieldValue('kitchen_group_id', +(value || '0'));
								}
							}}
						/>

						<MultiSelectField
							width="600px"
							label="Product Discounts"
							disabled={values?.is_open_price}
							data={
								response_discounts?.discounts?.map((e: any) => ({
									value: e.id,
									label: e.name,
								})) || []
							}
							onChange={(selectedItems) => {
								setFieldValue(
									'discount_ids',
									selectedItems.map((e) => +e)
								);
							}}
						/>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<LocalisedPricing />
						</Stack>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<ProductGrabIntegration
								hidden={!values?.is_grab_category}
								optionGroups={optionGroupsData?.option_groups}
							/>
						</Stack>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<Button
								loading={isSubmitting}
								disabled={isSubmitting}
								onClick={() => {
									if (!isValid) {
										notify.error(
											'Invalid fields is preventing you from saving the form',
											{ autoClose: 3000 }
										);
									} else {
										submitForm();
									}
								}}
							>
								Create Product
							</Button>
							<CancelButton
								onClick={() => navigate(ProductRoutes.Categories, {}, true)}
								disabled={isSubmitting}
							>
								Cancel
							</CancelButton>
						</Stack>
					</Stack>

					<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
						<ImageUploader
							url={values['image_url']}
							onChange={(url) => setFieldValue('image_url', url)}
							inactiveTabs={[
								'Category Groups',
								'Payment Methods',
								'Brands',
								'Discounts',
								'Advertisements',
							]}
						/>
						<Text align="left" fs="italic" size="xs" color="gray" >
							Recommended Dimensions: W 300px x H 216px
						</Text>
					</Stack>
				</Stack>
			</Box>
		</ViewContainer>
	);
};

const CreateProduct = () => {
	const { user, getAccessToken } = useAuth();
	const { setDirty, isCompanyBCIntegrated } = useAppState();
	const [searchParams] = useSearchParams();
	const categoryId = searchParams.get('categoryId');
	const navigate = useNavigate();
	const notify = useNotification();
	const { mutate } = useCreateProduct({
		onSuccess: () => {
			setDirty(false);
			navigate(ProductRoutes.Categories, {}, true);
			notify.success(
				<p>
					Product has been <strong>successfully</strong> created
				</p>
			);
		},
	});

	const { data: category } = useGetCategoryGrabStatus(+categoryId!);

	return (
		<Formik
			initialValues={{
				name: '',
				name_other_languages: {
					ms: '',
					zh: '',
				},
				display_label: '',
				bc_name: '',
				bc_code: '',
				image_url: '',
				price: 0,
				is_open_price: false,
				end_date: null,
				is_kitchen_item: false,
				category_id: categoryId ? +categoryId : 0,
				is_grab_category: category?.is_grab_category,
				kitchen_group_id: null,
				option_groups: [],
				discount_ids: [],
				localized_price: [],
				grab_price: 0,
				grab_option_groups: [],
				updated_by: user?.name ?? '',
			}}
			onSubmit={(values) => {
				const {
					grab_price,
					is_grab_category,
					price,
					localized_price,
					...rest
				} = values;

				const priceInCents = currency(price).intValue;
				const grabPriceInCents = currency(grab_price).intValue;
				const localizedPriceInCents = localized_price?.map((e: any) => ({
					pos_id: e.pos_id,
					price: currency(e.price).intValue,
					is_hidden: e.is_hidden,
				}));

				mutate({
					...rest,
					price: priceInCents,
					localized_price: localizedPriceInCents,
					grab_price: grabPriceInCents,
				});
			}}
			validationSchema={toFormikValidationSchema(
				isCompanyBCIntegrated
					? CreateProductWithBcIntegrationSchema
					: CreateProductSchema
			)}
			enableReinitialize
		>
			<_CreateProduct />
		</Formik>
	);
};

export default CreateProduct;

import styled from '@emotion/styled'

export const StyledToggleSwitch = styled.label`
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
	cursor: pointer;

	input[type='checkbox'] {
		display: none;
	}

	.switch {
		position: absolute;
		background-color: #cccccc;
		border-radius: 25px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: background-color 0.2s ease;
	}

	.switch::before {
		position: absolute;
		content: '';
		left: 2px;
		top: 2px;
		width: 21px;
		height: 21px;
		background-color: #fff;
		border-radius: 50%;
		transition: transform 0.3s ease;
		z-index: 10;
	}

	input[type='checkbox']:checked + .switch::before {
		transform: translateX(25px);
	}

	input[type='checkbox']:checked + .switch {
		background-color: #2da3ad;
	}

	input[type='checkbox']:checked + .switch::before {
		transform: translateX(25px);
	}
`;

export const StyledSwitchLabel = styled.p`
	margin-left: 1rem;
`;

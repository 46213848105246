import { useQuery } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';

export function useGetOneSellingTime(id: number) {
	const { getAccessToken } = useAuth();
	const getOneSellingTime = async () => {
		const response = await configApi.get(`selling-times/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});

		return response.data;
	};

	return useQuery(['selling_time', id], getOneSellingTime, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
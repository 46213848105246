import React from 'react';
import Icon from '../../../assets/icons';
import { EmptyState, ViewContainer } from 'components';
import { useGetCompanies } from 'hooks/companies';
import { useNavigate } from 'react-router-dom';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Box, Button, Group, Title } from '@mantine/core';

const Companies = () => {
	const { data } = useGetCompanies();
	const navigate = useNavigate();

	const getSelectedRow = (row: any) => {
		const id = row.original.id;
		navigate(`/configuration/companies/${id}`);
	};

	return (
		<>
			<ViewContainer
				title={
					<Group w="100%" position="apart">
						<Title order={2}>Companies</Title>
						<Group>
							<Button
								color="gray.2"
								style={{ color: 'black' }}
								leftIcon={<Icon name="archive" />}
								onClick={() => navigate('/configuration/companies/archived')}
							>
								Archived
							</Button>
							<Button
								leftIcon={<Icon name="add" />}
								onClick={() => navigate('/configuration/companies/create')}
							>
								Create Company
							</Button>
						</Group>
					</Group>
				}
			>
				<Box my="xl">
					<SimpleDataGrid
						data={data?.companies || []}
						columns={[
							{ accessorKey: 'id', header: 'Id' },
							{ accessorKey: 'name', header: 'Name' },
						]}
						columnVisibility={{
							id: false,
						}}
						onClickHandler={getSelectedRow}
					/>
				</Box>
				{data?.companies?.length === 0 && <EmptyState resource={'Companies'} />}
			</ViewContainer>
		</>
	);
};

export default Companies;

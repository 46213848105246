import { Button, Chip, Group, Text, Title } from '@mantine/core';
import React from 'react';
import Icon from '../../assets/icons';
import { ViewContainer } from '../../components';
import { SimpleDataGrid } from '../../components/SimpleDataGrid';
import RequirePermissions from '../../guards/RequirePermissions';
import { useGetRolesByCompany } from '../../hooks/api/roles/useGetRolesByCompany';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { useAuth } from '../../providers/AuthProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';

const Roles = () => {
	const { selectedCompany } = useAppState();
	const { user } = useAuth();
	const { data: rolesData } = useGetRolesByCompany({
		companyId: selectedCompany!,
		includePermissions: true,
	});

	const navigate = useNavigate();

	const _data = React.useMemo(() => {
		if (user?.is_superadmin) {
			return [
				{
					id: 0,
					name: 'Super Administrator',
					company_id: 0,
					is_admin: true,
					is_superadmin: true,
				},
			].concat(rolesData?.roles || []);
		}
		return rolesData?.roles;
	}, [rolesData]);

	return (
		<ViewContainer
			title={
				<Group w="100%" position="apart">
					<Title color="dark" order={2}>
						Roles
					</Title>
					<RequirePermissions permissions={['Roles.Create']}>
						<Button
							leftIcon={<Icon name="add" />}
							onClick={() => navigate(PermissionRoutes.CreateRole)}
						>
							Create Role
						</Button>
					</RequirePermissions>
				</Group>
			}
		>
			{/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
			<SimpleDataGrid
				data={_data}
				my="xl"
				columns={[
					{
						accessorKey: 'name',
						header: 'Name',
						cell: ({ row }) => {
							const roleName = (row.original as any)?.name;
							const roleIsAdmin = (row.original as any)?.is_admin;
							const roleIsSuperAdmin = (row.original as any)?.is_superadmin;

							const addBadge = roleName === roleIsSuperAdmin || roleIsAdmin;

							return (
								<Group spacing={5}>
									<Text>{roleName}</Text>
									{!addBadge ? null : <Icon name="adminbadge" />}
								</Group>
							);
						},
					},
					{
						accessorKey: 'permissions',
						header: 'Access',
						enableSorting: false,
						cell: ({ row, getValue }) => {
							const value = getValue() as string[];
							const roleIsAdmin = (row.original as any)?.is_admin;
							const roleIsSuperAdmin = (row.original as any)?.is_superadmin;

							const hasTerminal =
								value?.includes('Terminal.Basic') ||
								value?.includes('Terminal.Full');

							const addBadge = roleIsSuperAdmin || roleIsAdmin;

							const hasDashboard = value?.filter(
								(e) => e !== 'Terminal.Basic' && e !== 'Terminal.Full'
							).length;

							const noAccess = !hasTerminal && !hasDashboard;

							return (
								<Group position="apart">
									{roleIsAdmin ? (
										<Text>Dashboard, Terminal</Text>
									) : noAccess ? (
										<Text color="gray">No Access</Text>
									) : (
										<Text>
											{hasTerminal && hasDashboard
												? 'Dashboard, '
												: hasDashboard
												? 'Dashboard'
												: null}
											{hasTerminal ? 'Terminal' : null}
										</Text>
									)}
									{!addBadge ? null : <Chip color="gray">Undeletable</Chip>}
								</Group>
							);
						},
					},
				]}
				onClickHandler={(row) => {
					const roleId = row?.original?.id;
					if (roleId === 0) {
						navigate(PermissionRoutes.SuperAdminRole);
					} else {
						navigate(PermissionRoutes.EditRole.replace(':roleId', roleId));
					}
				}}
			/>
		</ViewContainer>
	);
};

export default Roles;

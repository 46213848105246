export const theme = {
	font: {
		sansSerif:
			"Mulish, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
	},
	radius: { default: '4px' },
	colors: {
		primary: '#E77615',
		secondary: '#2DA3AD',
		green: '#218838',
		red: '#C8453A',
		accent: '#E9F6F7',
		grey05: '#F8F9FA',
		grey10: '#E8EAED',
		grey30: '#B0B4B8',
		grey50: '#787E84',
		grey70: '#5F6368',
		grey100: '#2A2E33',
		black: '#2A2E33',
		white: '#FFFFFF',
		red05: '#FDEBEB',
		red15:'#C8453A'
	},
};

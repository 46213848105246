import React from 'react';
import styled from '@emotion/styled';
import { Button, CancelButton, Div, Stack } from 'components';
import Icon from '../../../assets/icons';
import { useNavigate } from 'providers/RouterProvider';
import { useNotification } from 'providers/NotificationProvider';

import { useDeletePos } from 'hooks/api/pos/useDeletePosById';
import { useUnpairPos } from '../../../hooks/api/pos/useUnpairPos';

type Data = {
	// id: number;
	// name?: string;
	[key: string]: number | string;
};

export type ModalProps = {
	close: () => void;
	data?: Data;
	setFieldValue?: (field: string, value: any) => void;
};

export const StyledDiv = styled.div`
	background-color: ${({ theme }) => theme.colors.grey05};
	padding: 1rem;
	margin: 1rem 0;

	p {
		color: ${({ theme }) => theme.colors.grey50};
	}

	ul {
		margin-top: 0;
		list-style-type: none;
		padding: 0;
	}
`;

export const PairedWarningModal = ({ close }: ModalProps) => {
	return (
		<>
			<h3>
				This POS is paired with the POS hardware, please unpair before deleting.
			</h3>
			<Stack
				style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
			>
				<Button type="button" onClick={close}>
					OK
				</Button>
			</Stack>
		</>
	);
};

export const DeleteEntityModal = ({ close, data = { id: 0 } }: ModalProps) => {
	const { mutateAsync: deleteOnePosById } = useDeletePos();
	const navigate = useNavigate();
	const toast = useNotification();

	const confirmDelete = () => {
		deleteOnePosById(Number(data.id))
			.then(() => {
				close();
				navigate('/configuration/pos');
				toast.success('POS has been successfully deleted');
			})
			.catch(() => {
				toast.error('An error occured');
			});
	};

	return (
		<>
			<div>
				<h3>Are you sure you want to delete this POS?</h3>

				<Stack
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<CancelButton onClick={close}>No, Cancel</CancelButton>
					<Button
						leftIcon={<Icon name="delete" />}
						type="button"
						color="red"
						onClick={confirmDelete}
					>
						Yes, Delete
					</Button>
				</Stack>
			</div>
		</>
	);
};

export const ResetDeviceCodeWarning = ({
	close,
	data = { id: 0, outlet_name: '', pos_name: '' },
	setFieldValue,
}: ModalProps) => {
	const navigate = useNavigate();
	const { mutate: unpair } = useUnpairPos();
	return (
		<>
			<div>
				<h3>
					WARNING: Unpairing the POS now will cause disruption to the operator
					using the Terminal. Do you wish to Proceed?
				</h3>
				<StyledDiv style={{ position: 'relative' }}>
					<Stack direction="row">
						<p>POS</p>
						<span>{`${data.outlet_name} - ${data.pos_name}`}</span>
					</Stack>
				</StyledDiv>
			</div>
		</>
	);
};

export const ResetOfflineDeviceCodeWarning = ({
	close,
	data = { id: 0, outlet_name: '', pos_name: '' },
	setFieldValue,
}: ModalProps) => {
	const navigate = useNavigate();
	const { mutate: unpair } = useUnpairPos();
	return (
		<>
			<div>
				<h3>
					WARNING: POS is offline. Unpairing the POS now may lead to data loss.
					Do you wish to proceed?
				</h3>
				<StyledDiv style={{ position: 'relative' }}>
					<Stack direction="row">
						<p>POS</p>
						<span>{`${data.outlet_name} - ${data.pos_name}`}</span>
					</Stack>
				</StyledDiv>
			</div>
		</>
	);
};

import React from 'react';
import { Input, InputProps } from '../Input';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type FormTextInputProps = InputProps & {
	inputWrapperProps?: Omit<InputWrapperProps, 'error' | 'label'>;
	error?: React.ReactNode;
	label?: React.ReactNode;
	width?: React.CSSProperties['width'];
	hint?: string;
	type?: string;
	noSpecialCharacters?: boolean;
};

const defaultProps = {
	error: undefined,
};

const specialCharactersRegex = /[^a-zA-Z0-9]+/;

export const FormTextInput = React.forwardRef<
	HTMLInputElement,
	FormTextInputProps
>(
	(
		{
			inputWrapperProps,
			error,
			label,
			isRequired,
			isInvalid,
			width,
			hint,
			hidden,
			value,
			noSpecialCharacters,
			type,
			bgColor,
			...rest
		},
		ref
	) => {
		const [_error, set_error] = React.useState<string | null>();
		const disableScrolling = (e: any) => {
			// Prevent the input value change
			e.target.blur();

			// Prevent the page/container scrolling
			e.stopPropagation();

			// Refocus immediately, on the next tick (after the current function is done)
			setTimeout(() => {
				e.target.focus();
			}, 0);
		};
		React.useEffect(() => {
			if (noSpecialCharacters) {
				if (specialCharactersRegex.test(value.toString())) {
					set_error('No special characters allowed');
				} else {
					set_error(null);
				}
			}
		}, [value]);

		return (
			<InputWrapper
				{...inputWrapperProps}
				hidden={hidden}
				label={label}
				width={width}
				hasAsterisk={isRequired}
				error={error || isInvalid || _error}
			>
				<Input
					bgColor={bgColor}
					type={type ?? 'text'}
					value={value}
					hidden={hidden}
					onWheel={disableScrolling}
					{...rest}
					isInvalid={!!error || isInvalid || !!_error}
					ref={ref}
				/>
			</InputWrapper>
		);
	}
);

FormTextInput.displayName = 'FormTextInput';
FormTextInput.defaultProps = defaultProps;

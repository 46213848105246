import { useQuery, useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

type MallType = {
	id?: number;
	name: string;
};

export function useGetMalls() {
	const { getAccessToken } = useAuth();
	const getMalls = async () => {
		const response = await configApi.get(`malls`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery('malls', getMalls, {
		refetchOnWindowFocus: false,
	});
}

export function useGetOneMall(id: number) {
	const { getAccessToken } = useAuth();
	const getOneMall = async () => {
		const response = await configApi.get(`malls/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['malls', id], getOneMall, {
		refetchOnWindowFocus: false,
	});
}

export function useUpdateMallList() {
	const { getAccessToken } = useAuth();
	const updateMallList = async (body: any) => {
		const response = await configApi.put('malls/update-all', body, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateMallList, {
		onSuccess: () => {
			queryClient.invalidateQueries('malls');
		},
	});
}

export function useUpdateOneMall() {
	const { getAccessToken } = useAuth();
	const updateMall = async ({ id, ...rest }: MallType) => {
		const response = await configApi.put(`malls/${id}`, rest, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateMall, {
		onSuccess: () => {
			queryClient.invalidateQueries('malls');
		},
	});
}

export function useDeleteOneMall() {
	const { getAccessToken } = useAuth();
	const deleteOneMall = async (id: number) => {
		const response = await configApi.delete(`malls/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOneMall, {
		onSuccess: () => {
			queryClient.invalidateQueries('malls');
		},
	});
}

export function useCheckLinks(id: number) {
	const { getAccessToken } = useAuth();
	const getLinks = async() => {

		const response = await configApi.get(`malls/${id}/checklinks`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	}

	return useQuery(['malls_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})
}

import React from 'react';
import { useAppState } from '../providers/AppStateProvider';

export function useStopNavigateDirtyForm(dirty: boolean) {
	const { setDirty } = useAppState();

	React.useEffect(() => {
		if (dirty) {
			setDirty(dirty);
		}
		return () => setDirty(false);
	}, [dirty]);
}

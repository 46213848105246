import { Title } from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { NumberField } from '../../../../components';
import { SwitchField } from '../../../../components/SwitchField';

const ClosingPosSession = () => {
	const { setFieldValue, errors, values } = useFormikContext<any>();
	return (
		<>
			<Title order={2}>Closing POS Session</Title>
			<SwitchField
				size="lg"
				label="Allow $0 Cash Collection"
				onLabel="True"
				offLabel="False"
				checked={values?.is_allow_zero_cash_collection}
				onChange={(e) => {
					setFieldValue(
						'is_allow_zero_cash_collection',
						e.currentTarget.checked
					);
				}}
			/>
			{!values?.is_allow_zero_cash_collection && (
				<NumberField
					w="690px"
					label="Authorised Difference ($)"
					required
					hint="This will be the maximum deviation allowed for the cash colelcted amount during daily closing"
					error={errors?.authorised_difference as string}
					value={String(values?.authorised_difference)}
					onChange={(e) => {
						setFieldValue(
							'authorised_difference',
							e.currentTarget.valueAsNumber
						);
					}}
					step={1}
				/>
			)}
			<SwitchField
				size="lg"
				label="Allow Z-Report preview before confirmation"
				onLabel="True"
				offLabel="False"
				checked={values?.has_report_preview_before_confirm}
				onChange={(e) => {
					setFieldValue(
						'has_report_preview_before_confirm',
						e.currentTarget.checked
					);
				}}
			/>
		</>
	);
};

export default ClosingPosSession;

import React from 'react';
import { Stack, StackProps } from '../Stack';

export type ListGroupItemProps = StackProps & {
	leftSection?: React.ReactNode;
	rightSection?: React.ReactNode;
};

export const ListGroupItem = React.forwardRef<
	HTMLDivElement,
	ListGroupItemProps
>(({ children, leftSection, rightSection, style, ...rest }, ref) => {
	return (
		<Stack
			data-label="list_group_item"
			component="li"
			width="100%"
			align="center"
			justify="left"
			style={{ padding: '10px', ...style }}
			{...rest}
			ref={ref}
		>
			{leftSection ? (
				<Stack align="center" justify="center" width="fit-content">
					{leftSection}
				</Stack>
			) : null}
			{children}
			{rightSection ? (
				<Stack
					align="center"
					justify="center"
					width="fit-content"
					style={{ marginLeft: 'auto' }}
				>
					{rightSection}
				</Stack>
			) : null}
		</Stack>
	);
});

ListGroupItem.displayName = 'ListGroupItem';

import React from 'react';
import { theme } from '../../providers/ThemeProvider/index';
import { DivProps, Div } from '../Div';
import { Stack } from '../Stack';
import { Text, TextProps } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { InputSwitch } from './InputSwitch';
import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';

const StyledWrapper = styled(Div)``;

export type StyledWrapperProps = DivProps & {
	isChecked: boolean;
	setIsChecked: (tags: boolean) => void;
	label?: React.ReactNode;
	error?: React.ReactNode;
	labelProps?: TextProps;
	hasAsterisk?: boolean;
	direction?: React.CSSProperties['flexDirection'];
	width?: React.CSSProperties['width'];
	hint?: string;
	trueLabel?: string;
	falseLabel?: string;
};

const StyledInfoIcon = styled(InfoOutlined)`
	color: ${({ theme }) => theme.colors.grey50};

	:hover {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}
`;

export const InputSwitchWrapper = React.forwardRef<
	HTMLDivElement,
	StyledWrapperProps
>(
	(
		{
			trueLabel,
			falseLabel,
			children,
			direction,
			label,
			error,
			hasAsterisk,
			labelProps,
			width,
			hint,
			isChecked,
			setIsChecked,
			...rest
		},
		ref
	) => {
		const widthNumber = Number(String(width).replace('px',''))

		return (
			<StyledWrapper {...rest} ref={ref}>
				<Stack direction={direction} width="100%" align="center">
					<Div css={{ 
						alignSelf: 'flex-start',
						width: String((widthNumber * (25/100)))+'px',
						textAlign: 'left' 
						}}>
						{label && (
							<Text
								size={14}
								css={{
									display: 'inline-block',
								}}
								{...labelProps}
							>
								{label}
								{hasAsterisk && (
									<Text
										component="span"
										color="red"
										css={{ marginLeft: '4px' }}
									>
										*
									</Text>
								)}
							</Text>
						)}
						{hint && (
							<Div style={{ display: 'inline-block', marginLeft: '4px' }}>
								<Tooltip
									label={hint}
									p={'bottom-start'}
									css={{ maxWidth: '200px', wordBreak: 'break-word' }}
								>
									<StyledInfoIcon fontSize="small" />
								</Tooltip>
							</Div>
						)}
					</Div>

					<span
						style={{
							width: String((widthNumber * (75/100)))+'px',
							display: 'flex',
							alignContent: 'flex-start',
							padding: '16.5px 0px',
						}}
					>
						{/* <input
							type="checkbox"
							checked={isChecked}
							onChange={() => setIsChecked(!isChecked)}
						/> */}
						<InputSwitch
							trueLabel={trueLabel}
							falseLabel={falseLabel}
							checked={isChecked}
							onChange={() => setIsChecked(!isChecked)}
						/>
						{error ? (
							<span style={{ marginTop: '6px', textAlign: 'left' }}>
								<Text component="small" color={theme.colors.red} size="14px">
									{error}
								</Text>
							</span>
						) : null}
					</span>
				</Stack>
			</StyledWrapper>
		);
	}
);

InputSwitchWrapper.displayName = 'Input Switch Wrapper';

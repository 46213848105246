/* eslint-disable no-mixed-spaces-and-tabs */
import React, { PropsWithChildren } from 'react';
import { Modal, ModalProps } from '../components';
import { ConfirmModal, ConfirmModalProps } from './ModalsProvider/ConfirmModal';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';

export type TModal =
	| {
			props?: Partial<Omit<ModalProps, 'title'>>;
			type: 'content';
			body: React.ReactNode;
			title?: React.ReactNode;
			closeOnClickOutside?: boolean;
	  }
	| {
			props?: Partial<Omit<ConfirmModalProps, 'title'>>;
			type: 'confirm';
			body: React.ReactNode;
			title?: React.ReactNode;
			closeOnClickOutside?: boolean;
	  };

export type TModalContext = {
	showModal: (current: Omit<TModal, 'type'>) => void;
	showConfirmModal: (current: {
		props?: Partial<Omit<ConfirmModalProps, 'title'>>;
		body: React.ReactNode;
		title?: React.ReactNode;
		closeOnClickOutside?: boolean;
	}) => void;
	closeModal: () => void;
	current: TModal | null;
};

const ModalContext = React.createContext<TModalContext>({
	showModal: () => {
		return;
	},
	showConfirmModal: () => {
		return;
	},
	closeModal: () => {
		return;
	},
	current: null,
});

const ModalsProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [current, setCurrent] = React.useState<TModal | null>(null);

	const closeModal = () => {
		setCurrent(null);
	};

	const showModal = (current: Omit<TModal, 'type'>) => {
		setCurrent({ type: 'content', ...current });
	};

	const showConfirmModal = (current: Omit<TModal, 'type'>) => {
		setCurrent({ type: 'confirm', ...current });
	};

	const value = { current, showModal, closeModal, showConfirmModal };

	return (
		<ModalContext.Provider value={value}>
			<MantineModalsProvider
				modalProps={{ centered: true, withinPortal: true }}
			>
				{children}
			</MantineModalsProvider>
			{current?.type === 'confirm' ? (
				<ConfirmModal
					{...current?.props}
					title={current?.title}
					open={!!current}
					onClose={() => {
						closeModal();
						current?.props?.onClose && current?.props?.onClose(false);
					}}
					onCancel={() => {
						current?.props?.onCancel && current?.props?.onCancel();
						closeModal();
					}}
					onConfirm={() => {
						current?.props?.onConfirm && current?.props?.onConfirm();
						closeModal();
					}}
				>
					{current?.body}
				</ConfirmModal>
			) : null}
			{current?.type === 'content' ? (
				<Modal
					{...current?.props}
					title={current?.title}
					open={!!current}
					onClose={() => {
						closeModal();
						current?.props?.onClose && current?.props?.onClose(false);
					}}
				>
					{current?.body}
				</Modal>
			) : null}
		</ModalContext.Provider>
	);
};

export default ModalsProvider;

export const useModals = () => React.useContext(ModalContext);

import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './react-datepicker.scss';
import { Input, InputProps } from '../Input';
import Icon from '../../assets/icons';
import { Div } from '../Div';
import { UnstyledButton } from 'components';
import { ActionIcon } from 'components';
import { Button } from '@mantine/core';

dayjs.extend(customParseFormat);

export type DatePickerProps = ReactDatePickerProps & {
	onClear?: () => void;
};

const CustomDateInput = React.forwardRef<HTMLInputElement, InputProps>(
	({ value, onClick, onChange }, ref) => (
		<Input
			onClick={onClick}
			onChange={onChange}
			value={value}
			rightIcon={<Icon name="calendar" />}
			ref={ref}
		/>
	)
);

CustomDateInput.displayName = 'CustomDateInput';

export const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(
	({ children, onChange, onClear, value, ...rest }, ref) => {
		const [opened, setOpened] = React.useState<boolean>(false);
		const [startDate, setStartDate] = React.useState<Date | null>(
			dayjs(value, 'DD/MM/YYYY').isValid()
				? dayjs(value, 'DD/MM/YYYY').toDate()
				: null
		);

		// React.useEffect(() => {
		// 	console.log(dayjs(value, 'DD/MM/YYYY').toDate());
		// 	console.log(startDate);
		// }, [startDate]);
		return (
			<ReactDatePicker
				selected={startDate}
				onInputClick={() => setOpened(true)}
				customInput={<CustomDateInput value={''} />}
				formatWeekDay={(formattedDate) =>
					formattedDate.slice(0, 3).toUpperCase()
				}
				renderCustomHeader={({
					date,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
				}) => (
					<Div
						css={{
							width: '100%',
							display: 'grid',
							gridTemplateColumns: 'auto 1fr auto',
						}}
					>
						<ActionIcon
							onClick={decreaseMonth}
							disabled={prevMonthButtonDisabled}
						>
							<Icon name="chevronleft" />
						</ActionIcon>
						<UnstyledButton css={{ textAlign: 'center', fontSize: '16px' }}>
							{dayjs(date).format('MMM YYYY')}
						</UnstyledButton>
						<ActionIcon onClick={increaseMonth}>
							<Icon name="chevronright" />
						</ActionIcon>
					</Div>
				)}
				onChange={(date, e) => {
					if (date) {
						setStartDate(dayjs(date).startOf('day').toDate());
						onChange && onChange(dayjs(date).startOf('day').toDate(), e);
					}
				}}
				ref={ref}
				minDate={new Date()}
				shouldCloseOnSelect={false}
				open={opened}
				dateFormat="dd/MM/yyyy"
				onClickOutside={() => setOpened(false)}
				{...rest}
			>
				<div>
					<Button
						compact
						css={{ float: 'right', margin: '10px' }}
						onClick={() => {
							setOpened(false);
							onClear && onClear();
							setStartDate(null);
						}}
					>
						{startDate ? 'Clear' : 'Confirm'}
					</Button>
				</div>
			</ReactDatePicker>
		);
	}
);

DatePicker.displayName = 'DatePicker';

import React from 'react';
import {
	Breadcrumbs,
	Button,
	Chip,
	DataGrid,
	Box,
	EmptyState,
	PaymentMethod,
	SelectBrand,
	Stack,
	Tabs,
	Text,
	ViewContainer,
} from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import Icon from 'assets/icons';
import { useAppState } from 'providers/AppStateProvider';
import { useGetBrands } from 'hooks/brands';
import { useGetDiscounts } from 'hooks/discounts';
import { useGetPaymentMethods } from 'hooks/payments';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Group } from '@mantine/core';
import { DiscountEnum } from 'constants/discount.enum';
import { Add } from '@mui/icons-material';
import { useAuth } from 'providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const PAYMENT_METHOD = 'payment_method';
const DISCOUNT = 'discount';

const dateFormatter = (value: string) => {
	return dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : '-';
};

const statusChipFormatter = (value: string) => {
	return (
		<Chip
			style={{ textTransform: 'capitalize' }}
			color={value === 'active' ? 'green' : 'red'}
			leftIcon={<Icon name={value === 'active' ? 'checkmark' : 'close'} />}
		>
			{value}
		</Chip>
	);
};

dayjs.extend(utc);
dayjs.extend(timezone);

const Payment = () => {
	const { permissions } = useAuth();
	const [params] = useSearchParams();
	const tabParam = params.get('tab');
	const [selectedTab, setSelectedTab] = React.useState<string>(
		tabParam ?? PAYMENT_METHOD
	);

	const navigate = useNavigate();
	const { selectedCompany, selectedBrand } = useAppState();

	const { data: brandsData } = useGetBrands(selectedCompany!);
	const { data: response_paymentMethods } = useGetPaymentMethods(
		selectedBrand || 0
	);

	const { data: response_discounts } = useGetDiscounts(selectedBrand || 0);

	const dateFormatter = (value: string) => {
		return dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : '-';
	};

	const statusChipFormatter = (value: string) => {
		return (
			<Chip
				style={{ textTransform: 'capitalize' }}
				color={value === 'active' ? 'green' : 'red'}
				leftIcon={<Icon name={value === 'active' ? 'checkmark' : 'close'} />}
			>
				{value}
			</Chip>
		);
	};

	const getSelectedPaymentRow = (row: any) => {
		const id = row.original.id;
		navigate(`/configuration/payment/${id}`);
	};

	const getSelectedDiscountRow = (row: any) => {
		const { id, scope } = row.original;

		if (scope) {
			navigate(
				scope === DiscountEnum.SCOPE.ORDER
					? `/configuration/payment/discount/${id}`
					: scope === DiscountEnum.SCOPE.PRODUCT
					? `/configuration/payment/discount/product/${id}`
					: ''
			);
		}
	};

	return (
		<>
			<ViewContainer title={<Breadcrumbs items={[{ label: 'Payment' }]} />}>
				<Stack align="center" style={{ margin: '20px 0px' }}>

					<SelectBrand />

					{selectedTab === PAYMENT_METHOD && brandsData?.brands?.length ? (
						<RequirePermissions permissions={['PaymentMethods.Create']}>
							<Button
								style={{ position: 'absolute', right: 0 }}
								type="button"
								leftIcon={<Icon name="add" />}
								onClick={() => navigate('/configuration/payment/create')}
							>
							Create Payment Method
						</Button>
						</RequirePermissions>
					) : null}

					{selectedTab === DISCOUNT && brandsData?.brands?.length ? (
						<Stack direction="row" style={{ position: 'absolute', right: 0 }}>
							<RequirePermissions permissions={['Discounts.Create']}>
								<Button
									type="button"
									leftIcon={<Icon name="add" />}
									onClick={() =>
										navigate('/configuration/payment/discount/create')
									}
								>
									Create Order Discount
								</Button>
								<Button
									type="button"
									leftIcon={<Icon name="add" />}
									onClick={() =>
										navigate('/configuration/payment/discount/product/create')
									}
								>
									Create Product Discount
								</Button>
							</RequirePermissions>
						</Stack>
					) : null}

				</Stack>
				<Box style={{ clear: 'both' }}></Box>
				<Box style={{ marginTop: '40px' }}>
					<Tabs defaultValue={selectedTab}>
						<Tabs.List>
							<RequirePermissions permissions={['PaymentMethods.View']}>
								<Tabs.Tab
									onClick={() => setSelectedTab(PAYMENT_METHOD)}
									value={PAYMENT_METHOD}
								>
									Payment method
								</Tabs.Tab>
							</RequirePermissions>
							<RequirePermissions permissions={['Discounts.View']}>
								<Tabs.Tab
									onClick={() => setSelectedTab(DISCOUNT)}
									value={DISCOUNT}
								>
									Discount
								</Tabs.Tab>
							</RequirePermissions>
						</Tabs.List>
						{/* Payment Method Panel starts here */}
						<Tabs.Panel value={PAYMENT_METHOD}>
							<SimpleDataGrid
								data={response_paymentMethods?.paymentMethods || []}
								columns={[
									{ accessorKey: 'id', header: 'Id' },
									{
										accessorKey: 'name',
										header: 'Name',
										cell: (data: any) => {
											return (
												<Group>
													<PaymentMethod
														type={data.getValue()}
														src={data.row.original.image_url}
													/>

													<Box style={{ display: 'inline-flex' }}>
														{data.getValue()}
													</Box>
												</Group>
											);
										},
									},
									{
										accessorKey: 'is_cash_payment',
										header: 'Type',
										cell: (row) => {
											return row.getValue() ? 'Cash' : '';
										},
									},
									{
										accessorKey: 'updated_at',
										header: 'Last updated',
										cell: (row: any) =>
											dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
									},
								]}
								columnVisibility={{
									id: false,
								}}
								onClickHandler={getSelectedPaymentRow}
							/>

							{brandsData?.brands?.length === 0 ? (
								<EmptyState
									resource="brands"
									secondaryMessage="Please create Brand before creating Option Group."
									CallToAction={
										permissions?.includes('Brands.Create') && (
											
											<Button
												type="button"
												leftIcon={<Add fontSize="inherit" />}
												onClick={() => navigate('/configuration/brands/create')}
											>
												Create Brand
											</Button>
										)
									}
								/>
							) : (
								response_paymentMethods?.paymentMethods?.length === 0 && (
									<EmptyState resource={'payment method'} />
								)
							)}
						</Tabs.Panel>
						{/* Discount Panel starts here */}
						<Tabs.Panel value={DISCOUNT}>
							<SimpleDataGrid
								data={response_discounts?.discounts || []}
								columns={[
									{ accessorKey: 'id', header: 'Id' },
									{ accessorKey: 'name', header: 'Name' },
									{
										accessorKey: 'scope',
										header: 'Discount',
										cell: ({ getValue }) => {
											return (
												<Text>
													{getValue() === DiscountEnum.SCOPE.ORDER
														? 'Order Discount'
														: getValue() === DiscountEnum.SCOPE.PRODUCT
														? 'Product Discount'
														: null}
												</Text>
											);
										},
									},
									{ accessorKey: 'description', header: 'Description' },
									{
										accessorKey: 'status',
										header: 'Status',
										cell: (row: any) => {
											return statusChipFormatter(row.getValue());
										},
									},
									{
										accessorKey: 'start_date',
										header: 'Start Date',
										cell: (row: any) => {
											return dateFormatter(row.getValue());
										},
									},
									{
										accessorKey: 'end_date',
										header: 'End Date',
										cell: (row: any) => {
											return dateFormatter(row.getValue());
										},
									},
								]}
								columnVisibility={{
									id: false,
								}}
								onClickHandler={getSelectedDiscountRow}
							/>

							{brandsData?.brands?.length === 0 ? (
								<EmptyState
									resource="brands"
									secondaryMessage="Please create Brand before creating Option Group."
									CallToAction={
										permissions?.includes('Brands.Create') && (
											<Button
												type="button"
												leftIcon={<Add fontSize="inherit" />}
												onClick={() => navigate('/configuration/brands/create')}
											>
												Create Brand
											</Button>
										)
									}
								/>
							) : (
								response_discounts?.discounts?.length === 0 && (
									<EmptyState resource={'discounts'} />
								)
							)}
						</Tabs.Panel>
					</Tabs>
				</Box>
			</ViewContainer>
		</>
	);
};

export default Payment;

import React from 'react';
import { Box, Input, Tooltip, ActionIcon, InputProps } from '@mantine/core';
import Icon from '../assets/icons';

export type FieldWrapperProps = React.PropsWithChildren<{
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
}>;

export const FieldWrapper = React.forwardRef<HTMLDivElement, FieldWrapperProps>(
	({ label, error, hint, required, children, ...rest }, ref) => {
		return (
			<Box
				ref={ref}
				sx={{
					display: 'grid',
					gridTemplateColumns: '240px 1fr',
					alignItems: 'flex-start',
				}}
			>
				<div>
					<Input.Label required={required} mt="15px" mr="10px">
						{label}
					</Input.Label>
					{!hint ? null : (
						<Tooltip label={hint} position="bottom-start">
							<ActionIcon
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon name="info" />
							</ActionIcon>
						</Tooltip>
					)}
				</div>
				<Box>
					{children}
					{error ? (
						<Input.Error my="10px">This field is required</Input.Error>
					) : null}
				</Box>
			</Box>
		);
	}
);

FieldWrapper.displayName = 'FieldWrapper';

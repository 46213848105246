import { z } from 'zod';
import { BaseSchema, zodDateStringSchema } from '../base.schema';

export const OptionSchemaWithId = BaseSchema.extend({
	name: z.string().max(255),
	name_other_languages: z.object({
		zh: z.string().max(255).default(""),
		ms: z.string().max(255).default(""),
	}),
	is_default: z.boolean(),
	option_value: z
		.number()
		.default(0)
});

export const OptionSchemaWithoutId = OptionSchemaWithId.omit({
	id: true,
	created_at: true,
	updated_at: true,
});

export const OptionSchemaWithIdOptional = OptionSchemaWithId.extend({
	id: z.number().int().optional(),
	created_at: zodDateStringSchema.optional(),
	updated_at: zodDateStringSchema.optional(),
});

export type IOptionWithId = z.infer<typeof OptionSchemaWithId>;
export type IOptionWithoutId = z.infer<typeof OptionSchemaWithoutId>;

export const CreateOptionSchema = OptionSchemaWithoutId.pick({
	name: true,
	name_other_languages: true,
	is_default: true,
	option_value: true,
});

export const UpdateOptionSchema = OptionSchemaWithoutId.extend({
	id: z.number().int().optional(),
	created_at: zodDateStringSchema.optional(),
	updated_at: zodDateStringSchema.optional(),
});

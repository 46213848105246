import React from 'react';
import { FormMultiSelect } from '../../components/FormMultiSelect';
import { useGetBrandName } from '../../hooks/api/managements/useGetBrandName';
import { useGetOutlets } from '../../hooks/api/managements/useGetOutlets';

type BrandProps = {
	brandId: number;
	brandOutlets: string[];
};

interface Outlet {
	id: number;
	name: string;
}

const OutletOptions = ({ brandId, brandOutlets }: BrandProps) => {
	const { data: outletsData, isFetching: outletFetching } =
		useGetOutlets(brandId);
	const { data: brand, isFetching: companyNameFetching } =
		useGetBrandName(brandId);
	const outlets =
		outletsData?.map((outlet: Outlet) => ({
			value: outlet.id,
			label: outlet.name,
		})) || [];

	return (
		<div>
			{!outletFetching && !companyNameFetching && (
				<FormMultiSelect
					width="720px"
					options={outlets}
					placeholder="Please select"
					defaultSelectedOptions={
						(options) => options.filter(
							(e) => brandOutlets?.includes(String(e.value))) 
					}
					label={brand?.name}
					isDisabled={true}
				/>
			)}
		</div>
	);
};

export default OutletOptions;

import React from 'react';
import { theme } from '../../providers/ThemeProvider/index';
import { DivProps, Div } from '../Div';
import { Stack } from '../Stack';
import { Text, TextProps } from '@mantine/core';
import styled from '@emotion/styled';
import { InputPhoneNumber } from './InputPhoneNumber';

const StyledWrapper = styled(Div)`
	margin-bottom: '10px';
`;

export type StyledWrapperProps = DivProps & {
	name: string;
	value: number;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label?: React.ReactNode;
	error?: React.ReactNode;
	labelProps?: TextProps;
	isReadOnly?: boolean;
	isRequired?: boolean;
	direction?: React.CSSProperties['flexDirection'];
	width?: React.CSSProperties['width'];
	hint?: string;
	maxLength?: number;
};

export const InputPhoneNumberWrapper = React.forwardRef<
	HTMLDivElement,
	StyledWrapperProps
>(
	(
		{
			name,
			value,
			onChange,
			children,
			direction,
			label,
			error,
			isReadOnly,
			isRequired,
			labelProps,
			width,
			hint,
			maxLength,
			...rest
		},
		ref
	) => {

		const widthNumber = Number(String(width).replace('px',''))

		return (
			<StyledWrapper {...rest}>
				<Stack direction={direction} width="100%" align="center">
					<Div css={{ width:String((widthNumber * (25/100)))+'px', textAlign: 'left' }}>
						{label && (
							<Text
								size={14}
								css={{
									display: 'inline-block',
								}}
								{...labelProps}
							>
								{label}
								{isRequired && (
									<Text
										component="span"
										color="red"
										css={{ marginLeft: '4px' }}
									>
										*
									</Text>
								)}
							</Text>
						)}
					</Div>

					<span
						style={{
							width: (String((widthNumber * (75/100)))+'px'),
							textAlign: 'left',
							alignItems: 'center',
						}}
					>
						<InputPhoneNumber
							width={String((widthNumber * (75/100)))+'px'}
							error={error}
							name={name}
							value={value}
							onChange={onChange}
							isReadOnly={isReadOnly}
							maxLength={maxLength}
						/>
						{error ? (
							<span style={{ marginTop: '6px' }}>
								<Text component="small" color={theme.colors.red} size="14px">
									{error}
								</Text>
							</span>
						) : null}
					</span>
				</Stack>
			</StyledWrapper>
		);
	}
);

InputPhoneNumberWrapper.displayName = 'InputPhoneNumberWrapper';

import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledChervon } from './Chevron.styled';

export type ChevronProps = DefaultProps<HTMLSpanElement> & {
	isRotate?: boolean;
};

export const Chevron: React.FC<ChevronProps> = ({ isRotate, ...rest }) => (
	<StyledChervon isRotate={isRotate} {...rest} />
);

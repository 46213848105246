import React from 'react';
import { Div } from 'components';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	ChartDataLabels,
	Title,
	Tooltip,
	Legend
);

export function BarChart({
	labels,
	data,
	type,
}: {
	labels: string[];
	data: number[];
	type: string;
}) {
	const max = data?.length > 0 ? Math.max(...data) : 100;
	const options = {
		responsive: true,
		elements: {
			bar: {
				borderWidth: 1,
			},
		},
		plugins: {
			title: {
				display: false,
				text: 'Chart.js Bar Chart',
			},
			datalabels: {
				display: true,
				color: 'black',
				formatter: function (value: string, context: any) {
					return `$${parseFloat(value).toFixed(2)}`;
				},
				anchor: 'end' as const,
				align: 'top' as const,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				stepSize: Math.round(max + max / 5) / 5,
				min: 0,
				max: Math.round(max + max / 5),
				ticks: {
					callback(value: any) {
						return `$${value}`;
					},
				},
			},
		},
	};

	const _data = {
		labels,
		datasets: [
			{
				label: `${type} Sales`,
				data: data,
				backgroundColor: 'rgba(231, 118, 21, 0.1)',
				borderColor: 'rgba(231, 118, 21,0.8)',
			},
		],
	};

	return (
		<>
			<Div>
				<Bar options={options} data={_data} height={'70%'} />
			</Div>
		</>
	);
}

export default BarChart;

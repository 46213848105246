import React from 'react';
import { Group } from '@mantine/core';
import {
	Box,
	Breadcrumbs,
	Divider,
	FormMultiSelect,
	FormTextInput,
	SelectItem,
	Stack,
	ViewContainer,
	Button,
	CancelButton,
	SelectField,
} from 'components';
import OptionsList from './OptionsList';
import { useNavigate } from '../../../lib/navigate';
import { useNotification } from 'providers/NotificationProvider';
import { useAppState } from 'providers/AppStateProvider';
import { Field, Formik } from 'formik';
import { CreateOptionGroupSchema } from 'schemas/Products/optiongroup.schema';
import { useCreateOptionGroup } from 'hooks';
import { useGetBrands } from 'hooks/brands';
import { newOption } from './OptionGroup.constants';
import { ErrorType } from 'views/Configuration/PointOfSale/Constants';
import { useAuth } from 'providers/AuthProvider';
import { JsonInput } from '@mantine/core';

type BrandType = {
	id: number;
	name: string;
};

const OptionGroupCreate = () => {
	const navigate = useNavigate();
	const toast = useNotification();
	const { selectedBrand, selectedCompany, setDirty } = useAppState();
	const { user } = useAuth();

	const { data: response_brands, isFetching: isFetchingBrands } = useGetBrands(
		selectedCompany || 0
	);

	const { mutateAsync: createOptionGroup } = useCreateOptionGroup();

	const breadcrumbsArray = [
		{
			label: 'Option Groups',
			onClick: () => navigate('/products/optiongroups'),
		},
		{
			label: 'Create Option Group',
			onClick: () => navigate('#'),
		},
	];

	const max_selections_array = ['1', '2', '3', '4', '5', 'Any'];
	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Group w="100%" position="apart">
					<Breadcrumbs items={breadcrumbsArray} />
				</Group>
			</Box>

			<Formik
				initialValues={{
					name: '',
					name_other_languages: {
						zh: '',
						ms: '',
					},
					description: '',
					min_selections: '0',
					max_selections: '1',
					brand_ids: selectedBrand ? [selectedBrand] : [],
					options: [newOption, newOption],
					updated_by: user?.name ?? '',
				}}
				validate={(values) => {
					try {
						CreateOptionGroupSchema.parse(values);
						const errors: ErrorType = {};
					} catch (err: any) {
						return err.formErrors.fieldErrors;
					}
				}}
				onSubmit={(values, actions) => {
					const numOfDefaults = values.options.filter(
						(e) => e.is_default === true
					).length;

					const emptyOptionNames = values.options.filter(
						(e) => e.name === ''
					).length;

					if (
						values.max_selections !== 'Any' &&
						numOfDefaults > parseInt(values.max_selections)
					) {
						toast.error(
							`Cannot mark more than the maximum number of selections (${values.max_selections} options) as default selections`
						);
					} else if (emptyOptionNames > 0) {
						toast.error(`Option name is required`);
					} else if (values.min_selections > values.max_selections) {
						toast.error(
							`Minimum number of options selected cannot be higher than maximum number of options.`
						);
					} else if (values.brand_ids.length === 0) {
						toast.error(`Please select at least one brand`);
					} else {
						createOptionGroup(values, {
							onSuccess: (_) => {
								setDirty(false);
								navigate('/products/optiongroups', {}, true);
								toast.success('Option Group has been successfully created');
							},
							onError: (_) => {
								toast.error('An error occured');
							},
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{({ values, errors, touched, dirty, setFieldValue, submitForm }) => {
					React.useEffect(() => {
						if (dirty) {
							setDirty(true);
						}

						return () => setDirty(false);
					}, [dirty]);
					return (
						<Box style={{ width: '100%' }}>
							<Stack direction="row" gap={30}>
								<Stack direction="column" gap={10}>
									<Stack
										direction="column"
										style={{
											display: 'flex',
											alignSelf: 'flex-start',
											justifyContent: 'flex-start',
											marginTop: '20px',
										}}
									>
										{!isFetchingBrands && (
											<FormMultiSelect
												label="Assign To Brand"
												width="600px"
												defaultSelectedOptions={(options) =>
													options.filter(
														(e) => e.value == selectedBrand?.toString()
													)
												}
												style={{
													display: 'flex',
													alignSelf: 'flex-start',
												}}
												placeholder={
													values.brand_ids.length > 0
														? `${values.brand_ids.length} Brand(s) Selected`
														: 'Select Brand(s)'
												}
												showPlaceholder
												options={
													response_brands?.brands?.map((e: BrandType) => ({
														value: e.id,
														label: e.name,
													})) || []
												}
												onChange={(items: SelectItem[]) => {
													setFieldValue(
														'brand_ids',
														items.map(({ value }) => +value)
													);
												}}
											/>
										)}

										<Field
											width="600px"
											as={FormTextInput}
											name="name"
											label="Option Group Name"
											isRequired={true}
											maxLength={80}
											error={errors.name && touched.name ? errors.name : null}
										/>

										<Field
											width="600px"
											as={FormTextInput}
											name="zh_name"
											label="Chinese Name"
											maxLength={80}
											onChange={(e: any) => {
												setFieldValue(
													'name_other_languages.zh',
													e.target.value
												);
											}}
										/>
										<Field
											width="600px"
											as={FormTextInput}
											name="ms_name"
											label="Malay Name"
											maxLength={80}
											onChange={(e: any) => {
												setFieldValue(
													'name_other_languages.ms',
													e.target.value
												);
											}}
										/>
										<Field
											width="600px"
											as={FormTextInput}
											name="description"
											label="Description"
											maxLength={80}
										/>

										<SelectField
											labelWidth={160}
											width={450}
											label="Minimum number of options to be selected"
											hint={'For Grab Delivery Integration only'}
											value={values?.min_selections}
											data={[
												{ value: '0', label: '0' },
												...(values.options?.map((e: any, i: number) => ({
													label: (i + 1).toString(),
													value: (i + 1).toString(),
												})) ?? []),
											]}
											placeholder="Please select"
											onChange={(value) => {
												setFieldValue('min_selections', value);
											}}
										/>

										<SelectField
											labelWidth={160}
											width={450}
											label="Maximum number of options that can be selected"
											value={values?.max_selections}
											data={[
												...(values.options?.map((e: any, i: number) => ({
													label: (i + 1).toString(),
													value: (i + 1).toString(),
												})) ?? []),
											]}
											placeholder="Please select"
											onChange={(value) => {
												setFieldValue('max_selections', value);
											}}
										/>
									</Stack>
									<Divider />
									<Stack
										width={'100%'}
										style={{
											display: 'flex',
											justifyContent: 'flex-start',
											marginTop: '20px',
										}}
									>
										<OptionsList optionsList={values.options} />
									</Stack>

									<Divider />

									<Stack
										width={'100%'}
										style={{
											display: 'flex',
											justifyContent: 'flex-start',
											marginTop: '20px',
										}}
									>
										<Button type="submit" onClick={submitForm}>
											Create
										</Button>
										<CancelButton
											onClick={() => navigate('/products/optiongroups')}
										>
											Cancel
										</CancelButton>
									</Stack>
								</Stack>
							</Stack>
						</Box>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default OptionGroupCreate;

import { reportAnalyticApi } from '../../../apis';
import { exportData } from './helpers/exportData';
import Papa from 'papaparse';

type TRawReport = {
	fileName: string,
	report_ids: string[];
	terminal_ids: string[] | number[];
};

export async function useGetRawReport({
	fileName,
	report_ids,
	terminal_ids,
}: TRawReport) {
	const response = await reportAnalyticApi.post(
		'/financial-reports/raw-reports',
		{
			report_ids,
			terminal_ids,
		}
	);

	const parsedData = Papa.unparse(response.data.csvData);

	exportData(parsedData, `RAW REPORT ${fileName}.csv`, 'text/csv;charset=utf-8;');

	return response.data;
}

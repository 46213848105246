import { Collapse, Grid, Group, Text } from '@mantine/core';
import {
	Box,
	Breadcrumbs,
	Button,
	CancelButton,
	DateField,
	Divider,
	Field,
	Formik,
	ImageUploader,
	FormPriceInput,
	FormTextInput,
	SelectBrand,
	SelectField,
	Stack,
	TextField,
	ViewContainer,
} from 'components';
import { DiscountEnum } from 'constants/discount.enum';
import { useCreateDiscount } from 'hooks/api/discounts/useCreateDiscount';
import { useGetPaymentMethodsByBrandId } from 'hooks/api/paymentmethods/useGetPaymentMethods';
import { currency } from 'lib';
import { toFormikValidationSchema } from 'lib/helpers';
import { useNavigate } from 'lib/navigate';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import React from 'react';
import { ConfigurationRoutes } from 'router/constants/Configuration.routes';
import {
	CreateDiscountSchema,
	CreateDiscountSchemaWithBC,
} from 'schemas/discount.schema';
import { SwitchField } from '../../../components/SwitchField';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { FormText } from 'reactstrap';

const discountTypeOptions = [
	{ value: DiscountEnum.TYPE.FIXED_AMOUNT, label: 'Fixed Amount ($)' },
	{ value: DiscountEnum.TYPE.FIXED_PERCENT, label: 'Fixed Percentage (%)' },
];

const CreateDiscounts = () => {
	const { selectedBrand, isCompanyBCIntegrated, setDirty } = useAppState();

	const navigate = useNavigate();
	const [setEndDate, setSetEndDate] = React.useState<boolean>(false);

	const { mutate } = useCreateDiscount();
	const notify = useNotification();

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			initialValues={{
				name: '',
				description: '',
				discount_code: '',
				scope: DiscountEnum.SCOPE.PRODUCT,
				brand_id: selectedBrand || 0,
				type: DiscountEnum.TYPE.FIXED_AMOUNT,
				auto_apply_on: DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PRODUCT,
				is_stackable: false,
				start_date: null,
				end_date: null,
				is_manager_only: false,
				value: 0,
				percent: 0,
				image_url: '',
				payment_method_ids: [],
			}}
			validationSchema={
				isCompanyBCIntegrated
					? toFormikValidationSchema(CreateDiscountSchemaWithBC)
					: toFormikValidationSchema(CreateDiscountSchema)
			}
			onSubmit={(values) => {
				if (
					values.type === DiscountEnum.TYPE.FIXED_PERCENT &&
					values.percent < 1
				) {
					notify.error('Fixed Percentage cannot be lesser than 1');
				} else if (
					values.type === DiscountEnum.TYPE.FIXED_AMOUNT &&
					values.value < 0.1
				) {
					notify.error('Fixed Amount cannot be lesser than 0.1');
				} else {
					mutate(values, {
						onSuccess: () => {
							setDirty(false);
							navigate(ConfigurationRoutes.Discount, {}, true);
						},
						onError: () => {
							notify.error(`Error creating discounts`);
						},
					});
				}
			}}
			enableReinitialize
		>
			{({ values, errors, setFieldValue, submitForm, dirty }) => {
				useStopNavigateDirtyForm(dirty);
				return (
					<ViewContainer>
						<Box style={{ position: 'relative', marginBottom: '20px' }}>
							<Breadcrumbs
								items={[
									{
										label: 'Discount',
										onClick: () => navigate(ConfigurationRoutes.Discount),
									},
									{ label: 'Create Product Discount' },
								]}
							/>
							<SelectBrand my="xl" />
							<Divider/>
						</Box>

						<Box style={{ width: '100%' }}>
							<Stack direction="row" gap={30}>
								<Stack direction="column" gap={10}>
									<Field
										width="600px"
										as={FormTextInput}
										label="Discount Name"
										isRequired={true}
										name="name"
										placeholder="Example: Staff Discount"
										error={
											(errors['name'] as string) && 'This field is required.'
										}
									/>
									<Field
										width="600px"
										as={FormTextInput}
										label="Description"
										name="description"
									/>

									<SelectField
										label="Discount Type"
										labelWidth={160}
										width={450}
										required
										name="type"
										value={values['type']}
										placeholder="Please Select"
										// Options when auto_apply_on = to 'specific_pay_method' , only two choices avaliable due to product requirements.
										data={discountTypeOptions}
										onChange={(value) => setFieldValue('type', value)}
									/>
									<Collapse
										in={
											values?.type === DiscountEnum.TYPE.FIXED_AMOUNT ||
											values?.type === DiscountEnum.TYPE.FIXED_PERCENT
										}
									>
										<FormPriceInput
											width="600px"
											placeholder="Example 0.00"
											precision={2}
											error={
												values.type.includes('percent')
													? errors['percent']
													: errors['value']
											}
											required={true}
											step={values.type.includes('percent') ? 1 : 0.1}
											label={
												discountTypeOptions.find(
													({ value }) => value === values['type']
												)?.label
											}
											value={
												values.type.includes('percent')
													? values['percent'] === 0
														? ''
														: values['percent']
													: values.value === 0 
														? ''
														: currency(values['value'], { fromCents: true }).value
											}
											name={
												values.type.includes('percent') ? 'percent' : 'value'
											}
											min={values.type.includes('percent') ? 1 : 0.01}
											onChange={(value) => {
												if (values.type.includes('percent')) {
													setFieldValue(
														'percent',
														value
													);
												} else {
													setFieldValue(
														'value',
														currency(value).intValue
													);
												}
											}}
										/>
									</Collapse>
									<SwitchField
										width="611px"
										required
										label="Stackable with Order Discounts"
										name="is_stackable"
										size="lg"
										onLabel="True"
										offLabel="False"
										checked={values?.is_stackable}
										onChange={(e) =>
											setFieldValue('is_stackable', e.currentTarget.checked)
										}
									/>
									<DateField
										width="611px"
										required
										label="Start Date"
										value={values?.start_date}
										onChange={(date) => {
											setFieldValue('start_date', date);
										}}
										valueFormat="DD/MM/YYYY"
										error={
											errors['start_date'] ? 'This field is required' : null
										}
									/>
									<SwitchField
										width="611px"
										label="Set End Date"
										size="lg"
										onLabel="True"
										offLabel="False"
										checked={setEndDate}
										onChange={(e) => {
											setSetEndDate(e.currentTarget.checked);
											if (!e.currentTarget.checked) {
												setFieldValue('end_date', null);
											}
										}}
									/>
									<Collapse in={setEndDate}>
										<DateField
											width="611px"
											required
											label="End Date"
											value={values?.end_date}
											onChange={(date) => {
												setFieldValue('end_date', date);
											}}
											valueFormat="DD/MM/YYYY"
											error={
												errors['end_date'] ? 'This field is required' : null
											}
										/>
									</Collapse>
									<SwitchField
										width="611px"
										label="Discount can only be applied by Manager"
										name="is_manager_only"
										size="lg"
										onLabel="True"
										offLabel="False"
										checked={values?.is_manager_only}
										onChange={(e) => {
											setFieldValue('is_manager_only', e.currentTarget.checked);
										}}
									/>
									<Stack 
										width="100%"
										style={{display:"flex", 
											justifyContent:"flex-start", 
											marginTop:"15px"}}
										>
										<Button onClick={() => submitForm()}>Create</Button>
										<CancelButton
											onClick={() =>
												navigate(ConfigurationRoutes.Payment + '/?tab=discount')
											}
										>
											Cancel
										</CancelButton>
										<Text size="xs">{`* stands for required`}</Text>
									</Stack>

								</Stack>
								<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
									<ImageUploader
										url={values?.image_url}
										onChange={(url) => setFieldValue('image_url', url)}
										inactiveTabs={[
											'Products',
											'Category Groups',
											'Brands',
											'Payment Methods',
											'Advertisements',
										]}
									/>
									<Text align="left" fs="italic" size="xs" color="gray" >
										Recommended Dimensions: W 315px x H 140px
									</Text>
								</Stack>
							</Stack>
						</Box>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default CreateDiscounts;

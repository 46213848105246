import React from 'react';
import { useFormikContext } from '../../../../components';
import Icon from '../../../../assets/icons';
import MenuPreviewCategoryGroup from './MenuPreviewCategoryGroup';

const MenuPreviewCategoryGroups = () => {
	const { values, setFieldValue } = useFormikContext<{
		_categoryGroups: Record<string, number[]>;
	}>();

	return !values?._categoryGroups ? null : (
		<>
			{Object.entries(values?._categoryGroups)?.map(
				([categoryGroupId, categoryIds]) =>
					!categoryIds.length ? null : (
						<MenuPreviewCategoryGroup
							categoryGroupId={categoryGroupId}
							categoryIds={categoryIds}
							key={`categoryGroup-${categoryGroupId}`}
						/>
					)
			)}
		</>
	);
};

export default MenuPreviewCategoryGroups;

import styled from '@emotion/styled';
import { Button } from 'components';

export const newOption = {
	name: '',
	name_other_languages: {
		zh: '',
		ms: '',
	},
	option_value: 0,
	is_default: false,
};

export const AddRowButton = styled(Button)`
	background-color: #fef8f3;
	color: ${({ theme }) => theme.colors.primary};
`;

import React from 'react';
import { Box, Input, Tooltip, ActionIcon } from '@mantine/core';
import Icon from '../assets/icons';
import { DateInput, DateInputProps } from '@mantine/dates';

export type DateFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	width?:string;
	required?: boolean;
} & DateInputProps;

export const DateField = React.forwardRef<HTMLDivElement, DateFieldProps>(
	({ label, error, hint, required, width, ...rest }, ref) => {
		
		const labelWidth = (width) && (Number(width.replace('px','')) * (25/100))

		return (
			<Box
				ref={ref}
				sx={{
					display: 'grid',
					gridTemplateColumns: ((width && labelWidth) 
					? String(labelWidth)+'px '+String(labelWidth*3)+'px' 
					: '240px 1fr'),
					alignItems: 'flex-start',
				}}
			>
				<div>
					<Input.Label required={required} mt="15px" mr="10px">
						{label}
					</Input.Label>
					{!hint ? null : (
						<Tooltip label={hint} position="bottom-start">
							<ActionIcon
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon name="info" />
							</ActionIcon>
						</Tooltip>
					)}
				</div>
				<Box>
					<DateInput
						allowDeselect
						error={error}
						{...rest}
						styles={{
							input: {
								padding: '27px 18px',
							},
						}}
						rightSection={
							<Icon
								name="calendar"
								fontSize="small"
								style={{ marginRight: '15px' }}
							/>
						}
					/>
				</Box>
			</Box>
		);
	}
);

DateField.displayName = 'DateField';

import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Breadcrumbs,
	CancelButton,
	Field,
	Formik,
	LastUpdatedLabel,
	SelectField,
	TextField,
	ViewContainer,
} from '../../components';
import { useEditRole } from '../../hooks/api/roles/useEditRole';
import { useGetRoleById } from '../../hooks/api/roles/useGetRoleById';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAuth } from '../../providers/AuthProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';
import { CreateRoleSchema } from '../../schemas/roles.schema';
import DashboardPanel from './EditRole/DashboardPanel';
import TerminalPanel from './EditRole/TerminalPanel';
import { Group, Tabs, Button, Grid, Title } from '@mantine/core';
import Icon from '../../assets/icons';
import { modals } from '@mantine/modals';
import { useDeleteRoleById } from '../../hooks/api/roles/useDeleteRoleById';
import { useGetRolesByCompany } from '../../hooks/api/roles/useGetRolesByCompany';
import { useAppState } from '../../providers/AppStateProvider';
import { notifications } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import { useDisableSelectCompany } from '../../hooks/useDisableSelectCompany';
import RequirePermissions from '../../guards/RequirePermissions';
import { useStopNavigateDirtyForm } from 'hooks/useStopNavigateDirtyForm';

const EditRole = () => {
	useDisableSelectCompany();
	const { roleId } = useParams();
	const { selectedCompany } = useAppState();
	const navigate = useNavigate();
	const { data: role } = useGetRoleById(roleId!);
	const { mutate } = useEditRole();
	const { data: roles } = useGetRolesByCompany({ companyId: selectedCompany! });
	const { mutate: remove, data } = useDeleteRoleById();
	// 'remove' because 'delete' is a reserved keyword
	const [reassignedId, setReassignedId] = React.useState<number | null>(null);

	const { permissions } = useAuth();

	React.useEffect(() => {
		if (data?.success === 'false') {
			modals.openConfirmModal({
				title: '',
				size: 'lg',
				children: (
					<Box>
						<Title order={3}>
							This role is used by users, please reassign users to another role
							before deleting:
						</Title>
						<SelectField
							width={120}
							dropdownPosition="bottom"
							maxDropdownHeight={120}
							wrapperProps={{
								py: 'xl',
								mb: '70px',
							}}
							data={roles?.roles?.map((e: any) => ({
								label: e.name,
								value: e.id,
							}))}
							label="Move to role"
							required
							onChange={(value) => {
								if (value) {
									setReassignedId(+value);
								}
							}}
							labelWidth={60}
						/>
					</Box>
				),

				labels: {
					confirm: 'Delete',
					cancel: 'Cancel',
				},
				//Suggested to implement useReducer instead as useState cannot manage process of reassigning
				confirmProps:{disabled: (reassignedId) ? false : true}, 
				onConfirm: () => {
					
					if (roleId && reassignedId) {
						modals.closeAll()
						remove({ id: roleId, reassignedRoleId: reassignedId });
					}
				},
				onCancel: () => {
					modals.closeAll() //A bit of a hack, Reason for this due to logic of opening up modal when reassignedId state is changed.
				}
			});
		}
	}, [data, reassignedId]);

	return (
		<Formik
			initialValues={{
				name: role?.name,
				company_id: role?.company_id,
				description: role?.description,
				permissions: role?.permissions_by_sections as {
					product: string[];
					configuration: string[];
					pointOfSales: string[];
					payment: string[];
					permissions: string[];
					fsIntegration: string[];
					reports: string[];
					analytics: string[];
					terminal: string[];
				},
			}}
			validationSchema={toFormikValidationSchema(CreateRoleSchema)}
			onSubmit={({ name, description, permissions, company_id }) => {

				if(name.toLowerCase().replace(" ", "") != "superadministrator"){
					mutate(
						{
							roleId: roleId!,
							payload: {
								name,
								description,
								company_id,
								permissions: Object.values(permissions).flat(),
							},
						},
						{
							onSuccess: () => {
								
									notifications.show({
										message: 'Role updated',
										color: 'green',
									});
									navigate(PermissionRoutes.Roles, {}, true);
								
							},
							onError: (err) => {
								if (isAxiosError(err)) {
									notifications.show({
										color: 'red',
										message: err.response?.data.error,
									});
								}
							},
						}
					)

				} else {

					notifications.show({
						color: 'red',
						message: "Sorry, the Super Administrator role name is reserved.",
					});
				}

			}}
			enableReinitialize
		>
			{({ submitForm, dirty, isSubmitting }) => {

				(permissions?.includes("Roles.Edit"))
					&& useStopNavigateDirtyForm(dirty)

				return (
					<ViewContainer
						title={
							<Group position="apart">
								<Breadcrumbs
									items={[
										{
											label: 'Roles',
											onClick: () => navigate(PermissionRoutes.Roles),
										},
										{ label: role?.name || '' },
									]}
								/>
								{role?.is_admin ? null : (
									<RequirePermissions permissions={['Roles.Delete']}>
										<Button
											color="red"
											size="sm"
											leftIcon={<Icon name="delete" />}
											onClick={() => {
												if (roleId) {
													remove(
														{ id: roleId },
														{
															onSuccess: (data) => {
																if(data?.success == true){
																	notifications.show({
																		message: 'Role deleted!',
																		color: 'green',
																	});
																	navigate(PermissionRoutes.Roles, {}, true);
																}
																
															},
															onError: (err) => {
																if (isAxiosError(err)) {
																	notifications.show({
																		color: 'red',
																		message: err.response?.data.error,
																	});
																}
															},
														}
														
														);
												}
											}}
										>
											Delete
										</Button>
									</RequirePermissions>
								)}
							</Group>
						}
					>	
						<fieldset disabled={!permissions?.includes("Roles.Edit") 
								|| isSubmitting} >
						<Grid align="center" my="40px">
							<Grid.Col md={12} lg={10}>
								<Box>
									<Field
										as={TextField}
										name="name"
										label="Role Name"
										maxLength={80}
										required
									/>
								</Box>
							</Grid.Col>
							<Grid.Col md={12} lg={2}>
								<LastUpdatedLabel lastUpdated={role?.updated_at} />
							</Grid.Col>
						</Grid>
						</fieldset>
						<Tabs defaultValue="dashboard">
							<Tabs.List>
								<Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
								<Tabs.Tab value="terminal">Terminal</Tabs.Tab>
							</Tabs.List>
							<fieldset disabled={!permissions?.includes("Roles.Edit") 
								|| isSubmitting} >
							<Tabs.Panel value="dashboard" py="xl">
								<DashboardPanel />
							</Tabs.Panel>
							<Tabs.Panel value="terminal" py="xl">
								<TerminalPanel />
							</Tabs.Panel>
							</fieldset>
						</Tabs>
						<Group my="xl">
							<RequirePermissions permissions={['Roles.Edit']}>
								<Button onClick={() => submitForm()} disabled={role?.is_admin}>
									Save changes
								</Button>
							
								<CancelButton onClick={() => navigate(PermissionRoutes.Roles)}>
									Cancel
								</CancelButton>
							</RequirePermissions>
						</Group>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default EditRole;

import { SelectItem } from 'components';

export const cashOptions = [
	{
		label: 'Show Exact Button',
		value: 'show_exact_button',
	},
	{
		label: 'Hide Exact Button',
		value: 'hide_exact_button',
	},
	{
		label: 'Auto populate exact cash tender',
		value: 'auto_populate_tender',
	},
];

export type OptionType = {
	label: string;
	value: string;
};

export type ErrorType = {
	[key: string]: string;
};

export const timeDropdown: OptionType[] = [];
for (let i = 0; i < 24; i++) {
	timeDropdown.push({
		label: `${i < 10 ? `0${i}` : i}:00`,
		value: `${i < 10 ? `0${i}` : i}:00:00`,
	});
	if (i < 24) {
		timeDropdown.push({
			label: `${i < 10 ? `0${i}` : i}:30`,
			value: `${i < 10 ? `0${i}` : i}:30:00`,
		});
	}
}

export const shiftDropdown: OptionType[] = [];
for (let j = 1; j <= 6; j++) {
	shiftDropdown.push({
		label: j.toString(),
		value: j.toString(),
	});
}

export const durationDropdown: OptionType[] = [];
for (let m = 1; m <= 30; m++) {
	durationDropdown.push({
		label: m.toString(),
		value: m.toString(),
	});
}

export const shiftTimingDropdown = ['07:30', '08:00', '08:30', '09:00'].map(
	(e) => ({
		label: e,
		value: `${e}:00`,
	})
);

export const slicedTimeArray = (
	timeArray: SelectItem[],
	opening_time: string,
	closing_time: string
) => {
	const valueArray = timeArray.map((e) => e.value);


	if(!closing_time){
		return timeArray
	}

	return timeArray.slice(
		valueArray.indexOf(opening_time) + 1,
		valueArray.indexOf(closing_time)
	);
};

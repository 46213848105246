import { initialAuthState } from './constants';
import { Action, TAuthState } from './types';

export const reducer = (
	state: TAuthState,
	action: { type: Action; payload?: any }
) => {
	switch (action.type) {
		case Action.LOGIN_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case Action.LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isError: false,
				accessToken: action.payload.accessToken,
				userId: action.payload.userId,
				exp: action.payload.exp,
				isAuthenticated: true,
			};
		case Action.LOGIN_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				accessToken: null,
				isAuthenticated: false,
			};
		case Action.ACCESS_TOKEN_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case Action.ACCESS_TOKEN_FAILURE:
			return {
				...state,
				isLoading: false,
				accessToken: null,
				isAuthenticated: false,
			};
		case Action.ACCESS_TOKEN_SUCCESS:
			return {
				...state,
				isLoading: false,
				...action.payload,
				isAuthenticated: true,
			};
		case Action.CURRENT_USER_SUCCESS:
			return {
				...state,
				user: action.payload,
			};
		case Action.RESET:
			return initialAuthState;

		default:
			return state;
	}
};

/** @jsxImportSource @emotion/react */

import React from 'react';
import { theme } from '../../providers/ThemeProvider/index';
import { DefaultProps } from '../types/DefaultProps';
import { PolymorphicRef } from '../types/PolymorphicRef';
import { isThemeColor } from '../utils/colors';
import { resolveCssProp } from '../utils/css-object';

export type DivProps = DefaultProps<'div'>;

export const Div = React.forwardRef<HTMLDivElement, DivProps>(
	(
		{ className, component, css, p, bgColor, style, ...rest },
		ref: PolymorphicRef<'div'>
	) => {
		const Element = component || 'div';

		const props: DivProps = { ...rest };

		if (css) {
			props.css = resolveCssProp(css);
		}

		return (
			<Element
				ref={ref}
				className={className}
				style={{
					padding: p,
					backgroundColor: isThemeColor(bgColor)
						? theme['colors'][bgColor]
						: bgColor,
					...style,
				}}
				{...props}
			/>
		);
	}
);

Div.displayName = 'Div';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

type KitchenGroupType = {
	id?: number;
	brand_id: number;
	name: string;
};

export function useGetKitchenGroups(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getKitchenGroups = async (): Promise<any> => {
		const response = await configApi.get(`kitchen-groups`, {
			params: { brand_id, deleted: 'no' },
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['kitchen_groups', brand_id], getKitchenGroups, {
		refetchOnWindowFocus: false,
		enabled: !!brand_id,
	});
}

export function useGetKitchenGroupsList(
	brand_id: number,
	sort_order: string,
	requested_page: number
) {
	const { getAccessToken } = useAuth();
	const getKitchenGroups = async () => {
		const response = await configApi.get(
			`kitchen-groups?brand_id=${brand_id}&sort_order=${sort_order}&deleted=no&dropdown=no&requested_page=${requested_page}`,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	return useQuery(
		['kitchen_groups', brand_id, requested_page],
		getKitchenGroups,
		{
			refetchOnWindowFocus: false,
			enabled: !!brand_id,
		}
	);
}

export function useGetOneKitchenGroup(id: number) {
	const { getAccessToken } = useAuth();
	const getOneKitchenGroup = async () => {
		const response = await configApi.get(`kitchen-groups/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});

		return response.data;
	};

	return useQuery(['kitchen_group', id], getOneKitchenGroup, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function useCreateNewKitchenGroup() {
	const { getAccessToken } = useAuth();
	const createKitchenGroup = async (body: KitchenGroupType) => {
		const response = await configApi.post('kitchen-groups', body, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(createKitchenGroup, {
		onSuccess: () => {
			queryClient.invalidateQueries('kitchen_groups');
		},
	});
}

export function useUpdateOneKitchenGroup() {
	const { getAccessToken } = useAuth();
	const updateKitchenGroup = async (body: KitchenGroupType) => {
		const id = body.id;
		const updatedKitchenGroup = { ...body, updated_at: new Date() };

		const response = await configApi.put(
			`kitchen-groups/${id}`,
			updatedKitchenGroup,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateKitchenGroup, {
		onSuccess: () => {
			queryClient.invalidateQueries('kitchen_groups');
		},
	});
}

export function useDeleteOneKitchenGroup() {
	const { getAccessToken } = useAuth();
	const deleteOneKitchenGroup = async (id: number) => {
		const response = await configApi.delete(`kitchen-groups/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOneKitchenGroup, {
		onSuccess: () => {
			queryClient.invalidateQueries('kitchen_groups');
		},
	});
}

export function useCheckLinks(id: number) {
	const { getAccessToken } = useAuth();
	const  getLinks = async() => {

		const response = await configApi.get(`kitchen-groups/${id}/checklinks`,{
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		
		return response.data;
	}
	
	return useQuery(['kitchen_group_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})
}

export function useCheckLinkedPos(id:number|null) {
	const { getAccessToken } = useAuth();
	const checkLinkedPos = async () => {
		if(id){
			
			const response = await configApi.get(`kitchen-groups/${id}/pos`, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			return response.data
		}
	}

	return useQuery(['kitchen_group', id], checkLinkedPos, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

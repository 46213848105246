import React from 'react';
import Icon from '../../assets/icons';
import { ActionIcon, ActionIconProps } from '@mantine/core';
import { StyledSortIcon } from './SortIcon.styled';

export type TSortState = 'asc' | 'desc' | 'none';

export type SortIconProps = ActionIconProps & {
	onStateChange?: (sortState: TSortState) => void;
};

const icon: Record<TSortState, React.ReactNode> = {
	none: <Icon name="unfoldmore" style={{ height: '20px' }} />,
	asc: <Icon name="arrowup" style={{ height: '20px' }} />,
	desc: <Icon name="arrowdown" style={{ height: '20px' }} />,
};

export const SortIcon = React.forwardRef<HTMLButtonElement, SortIconProps>(
	({ onStateChange, ...rest }, ref) => {
		const [sortState, setSortState] = React.useState<TSortState>('none');

		const sortStateHandler = () => {
			setSortState((p) => {
				if (p === 'none') {
					onStateChange && onStateChange('asc');
					return 'asc';
				}
				if (p === 'asc') {
					onStateChange && onStateChange('desc');
					return 'desc';
				}
				onStateChange && onStateChange('none');
				return 'none';
			});
		};

		return (
			<ActionIcon
				{...rest}
				sx={{ padding: '0', height: 'auto', width: 'auto', display: 'inline' }}
				onClick={() => sortStateHandler()}
				ref={ref}
			>
				{icon[sortState]}
			</ActionIcon>
		);
	}
);

SortIcon.displayName = 'SortIcon';

import React, { useState, useEffect, useRef } from 'react';
import {
	AddButton,
	FormTagsWrapper,
	FormTagsInput,
	FormTagsListItem,
} from './InputTags.styled';
import AddIcon from '@mui/icons-material/Add';

export type TagsInputProps = {
	tags: string[];
	setTags: (tags: string[]) => void;
	onAddTag?: (tags: string) => void;
	onDeleteTag?: (tags: string) => void;
	onClickTag?: (tags: string) => void;
	isReadOnly?: boolean;
};

export const InputTags = React.forwardRef<HTMLInputElement, TagsInputProps>(
	({ tags, setTags, onAddTag, onDeleteTag, onClickTag, isReadOnly }, ref) => {
		const [isTyping, setIsTyping] = useState<boolean>(false);
		const inputRef = useRef<HTMLInputElement>(null);
		const addButtonClickHandler = () => {
			setIsTyping(true);
		};

		const addTags = async (e: any) => {
			if (e.key === 'Enter') {
				if (e.target.value !== '') {
					const value = e.target.value;

					// check if value is already inside array, only add if it is not
					if (!tags.includes(value)) {
						setTags([...tags, value]);

						if (onAddTag) {
							onAddTag(value);
						}
					}

					setIsTyping(false);
				} else {
					setIsTyping(false);
				}
				e.target.value = '';
			}
		};

		const removeTags = (index: number) => {
			if (onDeleteTag) {
				onDeleteTag(tags?.at(index) ?? '');
			} else {
				setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
			}
		};

		useEffect(() => {
			if (inputRef.current && isTyping) {
				inputRef.current.focus();
			}
		}, [isTyping]);

		// useEffect(() => {
		// 	setIsTyping(false);
		// }, [tags]);
		return (
			<FormTagsWrapper className="tags-input">
				<ul
					style={{
						padding: '16.5px 0px',
						listStyleType: 'none',
						textAlign: 'left',
						display: 'inline-flex',
						flexWrap: 'wrap',
					}}
				>
					{tags &&
						tags.map((tag, index) => {
							return (
								<FormTagsListItem
									key={index}
									onClickTag={onClickTag}
									className="tag"
								>
									<span
										className="tag-title"
										onClick={() => {
											onClickTag && onClickTag(tag);
										}}
									>
										{tag}
									</span>
									{!isReadOnly && (
										<span
											className="tag-close-icon"
											onClick={() => removeTags(index)}
										>
											x
										</span>
									)}
								</FormTagsListItem>
							);
						})}
					<li>
						{!isTyping && !isReadOnly && (
							<AddButton onClick={addButtonClickHandler}>
								<span className="tag-title">
									<AddIcon fontSize="inherit" style={{ textAlign: 'center' }} />
									Add
								</span>
							</AddButton>
						)}

						<FormTagsInput
							ref={inputRef}
							hidden={!isTyping}
							type="text"
							maxLength={80}
							onKeyUp={addTags}
							placeholder="Please enter to add tags"
						/>
					</li>
				</ul>
			</FormTagsWrapper>
		);
	}
);

InputTags.displayName = 'TagsInput';

import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { Row } from '@tanstack/react-table';
import {
	Alert,
	Button,
	DataGrid,
	Div,
	FormSelectInput,
	FormTextInput,
	InputPhoneNumberWrapper,
	LastUpdatedLabel,
	Stack,
	SelectBrand,
	Tabs,
	Text,
	Tooltip,
	ViewContainer,
	Box,
	CancelButton,
} from 'components';
import Icon from '../../../assets/icons';
import StatusChip from '../PointOfSale/StatusChip';
import PaymentTypes from '../PointOfSale/PaymentTypes';
import DiscountsTooltip from '../PointOfSale/DiscountsTooltip';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Field, Formik } from 'formik';
import {
	useCheckLinks,
	useGetOneOutlet,
	useUnarchiveOutlet,
	useUpdateOutlet,
} from 'hooks/outlets';
import { useGetMalls } from 'hooks/malls';
import { useGetPos } from 'hooks/api/pos/useGetPos';
import { useModals } from 'providers/ModalsProvider';
import { useNotification } from 'providers/NotificationProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
	OutletSchemaUpdate,
	OutletSchemaUpdateWithBC,
} from 'schemas/outlet.schema';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from 'providers/AppStateProvider';
import {
	ArchiveEntityModal,
	DeleteEntityModal,
	LinkedEntityModal,
} from './Modals';
import { useAuth } from 'providers/AuthProvider';
import dayjs from 'dayjs';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import RequirePermissions from '../../../guards/RequirePermissions';

type OutletType = {
	value: string;
	label: string;
};

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	background-color: #e8eaed;
	border: none;
`;

const OutletsView = () => {
	useDisableSelectCompany();
	const { id } = useParams();
	const { permissions, user } = useAuth();
	const navigate = useNavigate();
	const { selectedBrand, isCompanyBCIntegrated } =
		useAppState();
	// const { data: response, isFetching: isFetchingBrands } = useGetBrands(
	// 	selectedCompany || 0
	// );
	const { data: outlet, isFetching: isFetchingOutlet } = useGetOneOutlet(
		Number(id)
	);

	const { mutateAsync: updateOneOutlet } = useUpdateOutlet();
	const { mutateAsync: unarchiveOutlet } = useUnarchiveOutlet();
	const { data: response_malls, isFetching: isFetchingMalls } = useGetMalls();
	const { data: posData } = useGetPos({
		brandId: selectedBrand || 0,
		outletId: Number(id),
	});
	const { refetch: checkLinks } = useCheckLinks(Number(id));

	const currencyList = [{ value: '1', label: 'SGD' }];
	const toast = useNotification();
	const modal = useModals();

	const onDeleteButtonClickHandler = async () => {
		await checkLinks().then((e) => {
			const data = e.data.data;
			if (data?.pos?.length > 0 || data?.users?.length > 0) {
				const _data = { ...data, action: 'deleting' };
				modal.showModal({
					title: '',
					body: <LinkedEntityModal close={modal.closeModal} data={_data} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteEntityModal
							close={modal.closeModal}
							data={{ id: Number(id), name: outlet.name, brand_id:Number(outlet?.brand_id) }}
						/>
					),
				});
			}
		});
	};

	const onArchiveButtonClickHandler = async () => {
		if (!outlet.is_archived) {
			await checkLinks().then((e) => {
				const data = e.data.data;
				if (data?.pos?.length > 0 || data?.users?.length > 0) {
					const _data = { ...data, action: 'deleting' };
					modal.showModal({
						title: '',
						body: <LinkedEntityModal close={modal.closeModal} data={_data} />,
					});
				} else {
					modal.showModal({
						title: '',
						body: (
							<ArchiveEntityModal
								close={modal.closeModal}
								data={{ id: Number(id), name: outlet.name, brand_id:Number(outlet?.brand_id) }}
							/>
						),
					});
				}
			});
		} else {
			unarchiveOutlet(Number(id))
				.then(() => {
					navigate('/configuration/outlets');
					toast.success('Outlet has been successfully unarchived');
				})
				.catch(() => {
					toast.error('An error occured');
				});
		}
	};

	return (
		<ViewContainer>
			{!isFetchingOutlet && !isFetchingMalls && (
				<>
					<Div style={{ position: 'relative', marginBottom: '20px' }}>
						<Breadcrumbs
							items={[
								{
									label: 'Outlets',
									onClick: () => navigate('/configuration/outlets'),
								},
								outlet.is_archived && {
									label: 'Archived',
									onClick: () => navigate('/configuration/outlets/archived'),
								},
								{
									label: outlet.name,
									onClick: () => navigate('#'),
								},
							]}
						/>

						<Div
							style={{
								display: 'inline-flex',
								position: 'absolute',
								right: 0,
								top: 0,
								gap: '15px',
							}}
						>
							<RequirePermissions permissions={['Outlets.Delete']}>
								<Button
									color="red"
									leftIcon={<DeleteIcon />}
									type="button"
									onClick={onDeleteButtonClickHandler}
								>
									Delete
								</Button>
							</RequirePermissions>
							<RequirePermissions permissions={['Outlets.Archive']}>
								<Button
									type="button"
									color="gray.3"
									style={{ color: 'black' }}
									onClick={onArchiveButtonClickHandler}
								>
									{outlet.is_archived ? 'Unarchive' : 'Archive'}
								</Button>
							</RequirePermissions>
						</Div>
					</Div>

					<Box my="xl">
						<SelectBrand isDisabled />
					</Box>

					<Tabs defaultValue={'settings'}>
						<Tabs.List>
							<Tabs.Tab value="settings">Settings</Tabs.Tab>
							<Tabs.Tab value="pos">Point of Sales</Tabs.Tab>
						</Tabs.List>
						<Tabs.Panel value="settings">
							<Formik
								initialValues={{
									id: outlet?.id ?? 0,
									name: outlet?.name ?? '',
									mall_id: outlet?.mall_id ?? null,
									brand_id: outlet?.brand_id ?? 0,
									currency_id: outlet?.currency_id ?? 1,
									address_line1: outlet?.address_line1 ?? '',
									address_line2: outlet?.address_line2 ?? '',
									zip_code: outlet?.zip_code ?? '',
									phone_number: outlet?.phone_number ?? '',
									tax_reg_number: outlet?.tax_reg_number ?? '',
									outlet_code: outlet?.outlet_code ?? '',
									dimension_code_prefix: outlet?.dimension_code_prefix ?? '',
									dimension_code_discount:
										outlet?.dimension_code_discount ?? '',
									updated_by: user?.name || '',
								}}
								validate={(values) => {
									try {
										isCompanyBCIntegrated
											? OutletSchemaUpdateWithBC.parse(values)
											: OutletSchemaUpdate.parse(values);
									} catch (error: any) {
										return error.formErrors.fieldErrors;
									}
								}}
								onSubmit={async (values, actions) => {
									await updateOneOutlet(values)
										.then(() => {
											navigate('/configuration/outlets', {}, true);
											toast.success('Outlet has been successfully updated');
										})
										.catch((_) => {
											toast.error('An error occured');
										});
									actions.setSubmitting(false);
								}}
							>
								{({
									errors,
									dirty,
									touched,
									setFieldValue,
									submitForm,
									isSubmitting
								}) => {
									(permissions?.includes("Outlets.Edit"))
										&& useStopNavigateDirtyForm(dirty)
									return (
										<>
											<fieldset disabled={!permissions?.includes("Outlets.Edit") 
												|| isSubmitting} >
												<Stack
													direction="column"
													className="settings"
													gap={25}
													css={{ marginTop: '25px' }}
												>
													{/* {
														<FormSelectInput
															isDisabled
															placeholder="Select Brand"
															defaultOption={{
																value: outlet.brand_id?.toString() || '0',
																label:
																	response?.brands?.find(
																		(e: any) => e.id === outlet.brand_id
																	)?.name ?? '',
															}}
															onChange={({ value }) =>
																setFieldValue('brand_id', +value)
															}
															options={
																response?.brands?.map((e: any) => ({
																	value: e.id.toString(),
																	label: e.name,
																})) ?? []
															}
															style={{ width: '192px' }}
														/>
													} */}

													{outlet.is_archived && (
														<Alert>
															{'You are viewing an archived (read only) version'}
														</Alert>
													)}

													<Stack direction="row">
														<Stack direction="column">
															<Field
																width="600px"
																as={FormTextInput}
																name="name"
																label="Outlet Name"
																isReadOnly={outlet.is_archived}
																isRequired={true}
																maxLength={80}
																error={
																	errors.name && touched.name ? errors.name : null
																}
															/>

															<Field
																width="600px"
																as={FormTextInput}
																name="id"
																label="Outlet ID"
																isReadOnly
															/>

															{outlet.is_archived ? (
																<Field
																	width="600px"
																	as={FormTextInput}
																	name="mall"
																	label="Mall"
																	isReadOnly={true}
																	isRequired={true}
																	value={
																		response_malls?.malls?.find(
																			(e: any) => e.id === outlet.mall_id
																		)?.['name'] || ''
																	}
																/>
															) : (
																<FormSelectInput
																	label="Mall"
																	defaultOption={{
																		value: outlet.mall_id,
																		label:
																			response_malls?.malls?.find(
																				(e: any) => e.id === outlet.mall_id
																			)?.['name'] || '',
																	}}
																	options={[
																		{
																			value: null,
																			label: '',
																		},
																		...(response_malls?.malls?.map((e: any) => ({
																			value: e.id,
																			label: e.name,
																		})) ?? []),
																	]}
																	placeholder="Select a mall here"
																	onChange={(items: OutletType) => {
																		if (items.value)
																			setFieldValue(
																				'mall_id',
																				Number(items.value)
																			);
																		else setFieldValue('mall_id', null);
																	}}
																	width="600px"
																/>
															)}
															{isCompanyBCIntegrated ? (
																<>
																	<Field
																		width="600px"
																		as={FormTextInput}
																		name="dimension_code_prefix"
																		label="BC Dimension Code (Outlet prefix)"
																		isReadOnly={outlet.is_archived}
																		isRequired={true}
																		maxLength={30}
																		error={
																			errors.dimension_code_prefix &&
																			touched.dimension_code_prefix
																				? errors.dimension_code_prefix
																				: null
																		}
																		placeholder="Example: ET-ARC"
																	/>

																<Field
																	width="600px"
																	as={FormTextInput}
																	name="dimension_code_discount"
																	label="BC Dimension Code (Discount)"
																	isReadOnly={outlet.is_archived}
																	isRequired={true}
																	maxLength={80}
																	error={
																		errors.dimension_code_discount &&
																		touched.dimension_code_discount
																			? errors.dimension_code_discount
																			: null
																	}
																	placeholder="Example: ET-ARC-COM"
																/>
															</>
														) : (
															<Field
																width="600px"
																as={FormTextInput}
																name="outlet_code"
																label="Outlet Code"
																isReadOnly={outlet.is_archived}
																maxLength={80}
																error={
																	errors.outlet_code && touched.outlet_code
																		? errors.outlet_code
																		: null
																}
																placeholder="Example: ET-ARC-COM"
															/>
														)}

															<Divider />

															<Text
																component={'h1'}
																style={{
																	fontSize: '1.625rem',
																	left: 0,
																	width: '100%',
																}}
															>
																Address
															</Text>

															<Field
																width="600px"
																as={FormTextInput}
																name="address_line1"
																label="Street"
																isReadOnly={outlet.is_archived}
																isRequired={true}
																maxLength={80}
																error={
																	errors.address_line1 && touched.address_line1
																		? errors.address_line1
																		: null
																}
															/>

															<Field
																width="600px"
																as={FormTextInput}
																name="address_line2"
																label="Unit"
																isReadOnly={outlet.is_archived}
																isRequired={false}
																maxLength={30}
																error={
																	errors.address_line2 && touched.address_line2
																		? errors.address_line2
																		: null
																}
															/>

															<Field
																width="600px"
																as={FormTextInput}
																name="zip_code"
																label="Postal Code"
																isReadOnly={outlet.is_archived}
																isRequired={true}
																maxLength={30}
																error={
																	errors.zip_code && touched.zip_code
																		? errors.zip_code
																		: null
																}
															/>

															<Divider />

															{outlet.is_archived ? (
																<Field
																	width="600px"
																	as={FormTextInput}
																	name="phone_number"
																	label="Phone Number"
																	isReadOnly={true}
																	isRequired={true}
																	value={`${
																		outlet.phone_dial_code
																	} ${outlet.phone_number
																		.match(/.{1,4}/g)
																		.toString()
																		.replaceAll(',', ' ')}`}
																/>
															) : (
																<Field
																	width="600px"
																	as={InputPhoneNumberWrapper}
																	name="phone_number"
																	label="Phone Number"
																	isReadOnly={outlet.is_archived}
																	isRequired={true}
																	maxLength={8}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>
																	) =>
																		setFieldValue('phone_number', e.target.value)
																	}
																	error={
																		errors.phone_number && touched.phone_number
																			? errors.phone_number
																			: null
																	}
																/>
															)}
															{outlet.is_archived ? (
																<Field
																	width="600px"
																	as={FormTextInput}
																	name="currency"
																	label="Currency"
																	isReadOnly={true}
																	isRequired={true}
																	value={currencyList[0].label}
																/>
															) : (
																<FormSelectInput
																	label="Currency"
																	defaultOption={{
																		value: currencyList[0]
																			? currencyList[0].value
																			: '',
																		label: currencyList[0]
																			? currencyList[0].label
																			: '',
																	}}
																	options={currencyList}
																	placeholder="Select a currency here"
																	onChange={(items: OutletType) => {
																		setFieldValue(
																			'currency_id',
																			Number(items.value)
																		);
																	}}
																	width="600px"
																/>
															)}

															{!outlet.is_archived && (
																<Stack
																	width={'100%'}
																	style={{
																		display: 'flex',
																		justifyContent: 'flex-start',
																	marginTop:'25px'
																	}}
																>
																	<RequirePermissions permissions={['Outlets.Edit']}>
																		<Button type="submit" onClick={submitForm}>
																			Save Changes
																		</Button>
																		<CancelButton
																			onClick={() =>
																				navigate('/configuration/outlets')
																			}
																		>
																			Cancel
																		</CancelButton>
																	</RequirePermissions>
																</Stack>
															)}
														</Stack>
														<Div
															style={{
																alignSelf: 'flex-start',
																marginLeft: '100px',
															}}
														>
															<LastUpdatedLabel
																lastUpdated={outlet?.updated_at ?? new Date()}
																editor={outlet?.updated_by ?? ''}
															/>
														</Div>
													</Stack>
												</Stack>
											</fieldset>
										</>
									);
								}}
							</Formik>
						</Tabs.Panel>
						<Tabs.Panel value="pos">
							<DataGrid
								style={{ width: '100%', marginTop: '25px' }}
								activePage={1}
								data={posData?.pos}
								onClickHandler={(row) =>
									navigate(
										`${ConfigurationRoutes.ViewPos.replace(
											':id',
											row.original.id
										)}`
									)
								}
								columns={[
									{
										accessorKey: 'name',
										header: 'Name',
										cell: ({ getValue }) => (
											<Text style={{ whiteSpace: 'nowrap' }}>
												{getValue() as string}
											</Text>
										),
									},
									{
										accessorKey: 'id',
										header: 'POS Id',
									},
									{
										accessorKey: 'sync_status',
										header: 'Status',
										cell: ({ getValue }: { getValue: any }) =>
											getValue() ? (
												<StatusChip>{getValue() as string}</StatusChip>
											) : null,
										// <StatusChip>{getValue() as string}</StatusChip>
									},
									{
										accessorKey: 'payment_methods',
										header: 'Payment Types',
										cell: ({ row }: { row: Row<any> }) => {
											return <PaymentTypes posId={row?.original?.id} />;
										},
									},
									{
										accessorKey: 'discounts',
										header: 'Discounts',
										cell: ({ getValue }) => {
											const discounts: Array<{id:number, name:string}> 
												= getValue() as Array<{id:number, name:string}>;
											return (
												<Stack align="center">
													<Text style={{ whiteSpace: 'nowrap' }}>
														{discounts
															? `${discounts.length} Discounts`
															: 'No Discounts'}
													</Text>
													{discounts?.length ? (
													<Tooltip
														position="right"
														offset={0}
														label={<DiscountsTooltip 
															discounts={discounts} />}>
														<Div
															css={() => ({
																cursor: 'pointer',
																height: '32px',
																width: '32px',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
															})}
														>
															<Icon name="info"/>
														</Div>
													</Tooltip>
												) : null} 
												</Stack>
											);
										},
									},
									{
										accessorKey: 'updated_at',
										header: 'Last updated',
										cell: ({ getValue }) => (
											<Text style={{ whiteSpace: 'nowrap' }}>
												{dayjs(getValue() as string).format(
													'DD/MM/YYYY h:mm:ss A'
												)}
											</Text>
										),
									},
								]}
							/>
						</Tabs.Panel>
					</Tabs>
				</>
			)}
		</ViewContainer>
	);
};

export default OutletsView;

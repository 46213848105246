import { useQuery, useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

type CreatePaymentMethodType = {
	name: string;
	brand_ids: number[];
	image_url: string;
	payment_code: string;
	is_cash_payment: boolean;
	is_discount_compatible: boolean;
};

export function useGetPaymentMethods(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getPaymentMethods = async () => {
		const response = await configApi.get(`payment-methods`, {
			params: {
				brand_id,
				summary: 'yes',
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['payment_methods', brand_id], getPaymentMethods, {
		refetchOnWindowFocus: false,
		enabled: !!brand_id,
	});
}

export function useCreateNewPaymentMethod() {
	const { getAccessToken } = useAuth();
	const createNewPaymentMethod = async (body: CreatePaymentMethodType) => {
		const newPaymentMethod = { ...body };

		const response = await configApi.post('payment-methods', newPaymentMethod, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(createNewPaymentMethod, {
		onSuccess: () => {
			queryClient.invalidateQueries('payment_methods');
		},
	});
}

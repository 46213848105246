export {
	Add,
	Sort,
	ArchiveOutlined,
	DragHandle,
	MoreVert,
	ImageOutlined as ImageIcon,
	InfoOutlined,
	Close,
	MenuBookOutlined,
} from '@mui/icons-material';

import { SvgIconTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { iconsMapper } from './icons';

export interface IconProps
	extends DefaultComponentProps<
		SvgIconTypeMap<unknown, 'svg'> & {
			muiName: string;
		}
	> {
	name: keyof typeof iconsMapper;
}

const Icon = ({ name, ...rest }: IconProps) => iconsMapper[name](rest);

export default Icon;
export * from './icons';

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAppState } from '../../../providers/AppStateProvider';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetDiscounts() {
	const { selectedBrand } = useAppState();
	const { getAccessToken } = useAuth();
	return useQuery(
		['discounts', selectedBrand],
		async () =>
			configApi
				.get(`/discounts`, {
					params: {
						brand_id: selectedBrand?.toString(),
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ keepPreviousData: true, refetchOnWindowFocus: false }
	);
}

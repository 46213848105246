import {z} from 'zod'
import { BaseSchema } from './base.schema'

export const PaymentMethodSchema = BaseSchema.extend({
    name: z.string({required_error: 'This field is required.'}).min(1, 'This field is required.').max(80, 'Only a maximum of 80 characters is allowed'),
    payment_code: z.string({required_error: 'This field is required.'}).min(1, 'This field is required.').max(30, 'Only a maximum of 30 characters is allowed'),
    image_url: z.string().nullable(),
    is_read_only: z.boolean(),
    is_cash_payment: z.boolean(),
    is_discount_compatible: z.boolean(),
    updated_by: z.string()
})

export type PaymentMethodSchemaType = z.infer<typeof PaymentMethodSchema>

const _PaymentMethodSchemaCreate = PaymentMethodSchema.extend({
    brand_ids: z.array(z.number())
})

export const PaymentMethodSchemaCreate = _PaymentMethodSchemaCreate.pick({
    name: true,
    image_url: true,
    is_cash_payment: true,
    is_discount_compatible: true,
    brand_ids: true,
    updated_by: true
})

export type PaymentMethodSchemaCreateType = z.infer<typeof PaymentMethodSchemaCreate>

export const PaymentMethodSchemaUpdate = _PaymentMethodSchemaCreate.pick({
    name: true,
    image_url: true,
    is_cash_payment: true,
    is_read_only: true,
    is_discount_compatible: true,
    updated_by: true
})

export const PaymentMethodSchemaCreateWithBC = _PaymentMethodSchemaCreate.pick({
    name: true,
    payment_code: true,
    image_url: true,
    is_cash_payment: true,
    is_discount_compatible: true,
    brand_ids: true,
    updated_by: true
})

export type PaymentMethodSchemaCreateTypeWithBC = z.infer<typeof PaymentMethodSchemaCreateWithBC>

export const PaymentMethodSchemaUpdateWithBC = _PaymentMethodSchemaCreate.pick({
    name: true,
    payment_code: true,
    image_url: true,
    is_cash_payment: true,
    is_read_only: true,
    is_discount_compatible: true,
    updated_by: true
})
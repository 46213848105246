import React from 'react';
import { StackProps } from '../Stack';
import { StyledListGroup } from './ListGroup.styled';
import { ListGroupItem } from './ListGroupItem';

export type ListGroupProps = StackProps & {
	isHoverable?: boolean;
	isBordered?: boolean;
};

export type IListGroup = React.ForwardRefExoticComponent<
	ListGroupProps & React.RefAttributes<HTMLDivElement>
> & {
	Item: typeof ListGroupItem;
};

export const ListGroup = React.forwardRef<HTMLDivElement, ListGroupProps>(
	({ children, isHoverable, isBordered, ...rest }, ref) => {
		return (
			<StyledListGroup
				component="ul"
				width="100%"
				direction="column"
				css={(theme) => ({
					border: isBordered ? `1px solid ${theme.colors.grey10}` : 'none',
					borderBottom: `1px solid ${theme.colors.grey10}`,
					borderRadius: '4px',
					'li[data-label="list_group_item"]': {
						cursor: isHoverable ? 'pointer' : 'inherit',
					},
					'li[data-label="list_group_item"]:hover': {
						backgroundColor: isHoverable
							? theme.colors.grey05
							: theme.colors.white,
						boxShadow: isHoverable
							? `-2px 0px 0px 0px ${theme.colors.primary}`
							: 'none',
					},
				})}
				{...rest}
				ref={ref}
			>
				{children}
			</StyledListGroup>
		);
	}
) as IListGroup;

ListGroup.displayName = 'ListGroup';
ListGroup.Item = ListGroupItem;

import {
	ActionIcon,
	Box,
	Checkbox,
	Grid,
	Group,
	Stack,
	Image,
	Text,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import React from 'react';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from '../../../assets/icons';
import { usePatchProductsSortOrder } from '../../../hooks';
import { useNavigate } from '../../../lib/navigate';
import { ProductRoutes } from '../../../router/constants/Products.routes';
import { useCategoriesContext } from './context';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

type Props = {
	products: {
		id: number;
		name: string;
		price: number;
		display_label: string;
		sort_order: number;
		image_url: string;
		is_open_price: boolean;
		end_date: Date;
		category_id: number;
		brand_id: number;
		product_option_groups: Array<{name:string}>;
	}[];
};

const Products = ({ products }: Props) => {
	const { mutate: patchProductsSortOrder } = usePatchProductsSortOrder();
	const navigate = useNavigate();
	const { selectedProducts, setSelectedProducts } = useCategoriesContext();
	const { disableDnd } = useCategoriesContext();
	const { permissions } = useAuth();

	return (
		<Formik
			initialValues={{ products }}
			onSubmit={(values) => {
				const newSortOrder = values?.products?.map(({ id }, idx) => ({
					id,
					sort_order: idx,
				}));
				if (newSortOrder?.length) {
					patchProductsSortOrder({ products: newSortOrder });
				}
			}}
			enableReinitialize
		>
			{({ values, setFieldValue, submitForm }) => {
				return (
					<DragDropContext
						onDragEnd={({ source, destination }) => {
							if (source && destination) {
								// Copy values
								const _items = [...values.products];
								// Get item to be removed
								const _item = values.products[source.index];
								// Remove item
								_items.splice(source.index, 1);
								// Add item
								_items.splice(destination.index, 0, _item);
								// Set new values
								setFieldValue(
									'products',
									_items.map((e, i) => ({
										...e,
										sort_order: i,
									}))
								);
								// Patch sort order
								setTimeout(() => {
									submitForm();
								}, 300);
							}
						}}
					>
						<Droppable
							droppableId={`droppable-${products?.at(0)?.category_id}`}
							direction="vertical"
						>
							{(provided, snapshot) => (
								<Box {...provided.droppableProps} ref={provided.innerRef}>
									{values?.products
										?.sort((a, b) => a.sort_order - b.sort_order)
										?.map((prod, idx) => (
											<Draggable
												key={prod.id}
												index={idx}
												draggableId={`product-${prod.id}`}
												isDragDisabled={(
													disableDnd || !permissions?.includes('Categories.Edit')
												)}>
												{(provided) => (
													<Box
														ref={provided.innerRef}
														key={`category-accordion-panel-category-${prod.category_id}-product-${prod?.id}`}
														{...provided.draggableProps}
														sx={(theme) => ({
															':hover': {
																background: theme.colors.gray[0],
															},
															backgroundColor: snapshot.isDraggingOver
																? theme.colors.gray[0]
																: 'inherit',
														})}
													>
														<Grid
															align="center"
															p="sm"
															mih="90px"
															justify="space-between"
															w="100%"
														>
															<Grid.Col span="content">
																<Checkbox
																	checked={selectedProducts[String(prod.id)]}
																	onChange={(e) => {
																		if (e?.currentTarget?.checked) {
																			setSelectedProducts((p) => ({
																				...p,
																				[String(prod.id)]: true,
																			}));
																		}
																		if (!e?.currentTarget?.checked) {
																			setSelectedProducts((p) => ({
																				...p,
																				[String(prod.id)]: false,
																			}));
																		}
																	}}
																/>
															</Grid.Col>
															<Grid.Col span="auto">
																<UnstyledButton
																	w="100%"
																	onClick={() =>
																		navigate(
																			ProductRoutes.EditProduct.replace(
																				':categoryId',
																				prod?.category_id?.toString()
																			).replace(
																				':productId',
																				prod?.id?.toString()
																			)
																		)
																	}
																>
																	<Group
																		align="center"
																		position="apart"
																		w="100%"
																	>
																		<Group w="100%">
																			<Text truncate style={{ width: '100px' }}>
																				{prod?.display_label}
																			</Text>

																			<Image
																				width={90}
																				fit="cover"
																				src={prod?.image_url}
																				withPlaceholder
																			/>
																			<Stack w="50%" spacing="xs">
																				<Text weight="bold">{prod?.name}</Text>
																				<Group style={{width:'100%'}} spacing="xs">
																					{ 
																						prod?.product_option_groups?.map((val, key) => (
																							<Text key={key} fz="xs" c="dimmed">
																								{
																									(key < (prod?.product_option_groups?.length-1)) 
																										? val.name+" |" 
																										: val.name
																								}
																							</Text>
																						))
																							
																					}
																				</Group>
																			</Stack>
																		</Group>
																		{!prod?.end_date ? null : (
																			<Text>
																				Till{' '}
																				{dayjs(prod?.end_date).format(
																					'DD/MM/YYYY'
																				)}
																			</Text>
																		)}
																	</Group>
																</UnstyledButton>
															</Grid.Col>
															<Grid.Col span={2}>
																<Group align="center" position="right">
																	{prod?.is_open_price ? (
																		<Text color="gray">Open price</Text>
																	) : (
																		<Text>
																			{currency(prod?.price, {
																				fromCents: true,
																			}).format()}
																		</Text>
																	)}
																	<Tooltip label={'Reorder'}>
																		<ActionIcon
																			{...provided.dragHandleProps}
																			hidden={(
																				disableDnd 
																					|| !permissions?.includes('Categories.Edit')
																		)}>
																			<RequirePermissions permissions={['Categories.Edit']}>														
																				<Icon name="draghandle" />
																			</RequirePermissions>
																				
																		</ActionIcon>
																	</Tooltip>
																</Group>
															</Grid.Col>
														</Grid>
													</Box>
												)}
											</Draggable>
										))}
									{provided.placeholder}
								</Box>
							)}
						</Droppable>
					</DragDropContext>
				);
			}}
		</Formik>
	);
};

export default Products;

/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Div,
	Image,
	ListGroup,
	Stack,
	Text,
	useFormikContext,
} from '../../../../components';
import { currency } from '../../../../lib';
import { useQuery } from 'react-query';
import { configApi } from '../../../../apis';
import { useAuth } from '../../../../providers/AuthProvider';
import { Loader, LoadingOverlay } from '@mantine/core';

const MenuPreviewProducts = () => {
	const { posId } = useParams();
	const { values } = useFormikContext<any>();

	const categoryId = values?.selectedCategory || null;

	const { getAccessToken } = useAuth();
	const { data: category, isFetching } = useQuery(
		['category', 'menu', categoryId],
		async () =>
			configApi
				.get(`/categories/${categoryId}/menu`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!categoryId,
		}
	);
	return (
		<ListGroup style={{ position: 'relative', height: '100%' }}>
			{!values?.selectedCategory ? (
				<Div></Div>
			) : !category || isFetching ? (
				<LoadingOverlay visible />
			) : category?.products?.length > 0 ? (
				category?.products?.map((e: any) => (
					<ListGroup.Item
						key={`product-${e.id}`}
						style={{ padding: '20px 30px' }}
					>
						<Stack align="center" justify="space-between" width="100%">
							<Stack align="center" justify="left" width="80%" gap={30}>
								<Text>{e.display_label}</Text>
								<Image
									src={e.image_url}
									isPlaceholder={!e.image_url}
									alt={e.name}
									width="51px"
									fit="cover"
								/>
								<Stack direction="column">
									<Text>{e.name}</Text>
								</Stack>
							</Stack>
							<Div>
								<Text>
									{currency(
										e.localized_price?.find(
											(t: any) => t.pos_id === Number(posId) && !t.is_hidden
										)?.price ?? e.price,
										{ fromCents: true }
									).format()}
								</Text>
							</Div>
						</Stack>
					</ListGroup.Item>
				))
			) : (
				<Div style={{ padding: '20px' }}>
					<p>No Products Available</p>
				</Div>
			)}
		</ListGroup>
	);
};

export default MenuPreviewProducts;

import { z } from 'zod';
import { BaseSchema } from './base.schema';

export const LoginSchema = BaseSchema.extend({
	username: z
		.string({
			required_error: 'This field is required.',
		})
		.max(80),
        password: z
		.string({
			required_error: 'This field is required.',
		})
		.max(80),
	
});

export type  TLoginSchema = z.infer<typeof LoginSchema>;

export const CreateLoginSchema = LoginSchema.pick({
	username: true,
    password:true

});

import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import React, { PropsWithChildren } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../apis';
import { useAppState } from './AppStateProvider';
import {
	ILoginCredentials,
	_getAccessToken,
	_getUserInfo,
	_login,
} from './AuthProvider/actions';
import { initialAuthContext, initialAuthState } from './AuthProvider/constants';
import { reducer } from './AuthProvider/reducer';
import { Action } from './AuthProvider/types';

export const AuthContext = React.createContext(initialAuthContext);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initialAuthState);
	const navigate = useNavigate();
	const { selectedCompany } = useAppState();
	const queryClient = useQueryClient();

	const login = React.useCallback(async (credentials: ILoginCredentials) => {
		dispatch({ type: Action.LOGIN_LOADING });

		try {
			const { data } = await _login(credentials);

			dispatch({
				type: Action.LOGIN_SUCCESS,
				payload: {
					accessToken: data.token,
					exp: data.exp,
					userId: data.userId,
				},
			});
		} catch (err) {
			if (isAxiosError(err)) {
				dispatch({ type: Action.LOGIN_FAILURE });
			}
		}
	}, []);

	const getAccessToken = React.useCallback(
		async (toDispatch: 'yes' | 'no' = 'yes') => {
			const accessToken = state.accessToken;
			const isExpired = !state.exp
				? false
				: dayjs(state.exp * 1000).isBefore(dayjs());

			if (accessToken && !isExpired) {
				return accessToken;
			}

			console.log('Access Token Expired');

			if (toDispatch === 'yes') {
				dispatch({
					type: Action.ACCESS_TOKEN_LOADING,
				});
			}

			try {
				const data: {
					access_token: string;
					exp: number;
					userId: string;
				} = await _getAccessToken({
					company_id: selectedCompany?.toString() || '',
				});

				const decoded: any = jwtDecode(data.access_token);

				if (toDispatch === 'yes') {
					dispatch({
						type: Action.ACCESS_TOKEN_SUCCESS,
						payload: {
							accessToken: data.access_token,
							exp: decoded.exp,
							userId: data.userId,
							permissions: decoded?.permissions || null,
						},
					});
				}

				return data.access_token;
			} catch (err) {
				console.log('token failure', err);
				dispatch({ type: Action.ACCESS_TOKEN_FAILURE });
				// authApi.get('/auth/logout', {
				// 	headers: {
				// 		Authorization: `Bearer ${state.accessToken}`,
				// 	},
				// });
				// We stopped forcing the user to logout, once the access token call fails, because it will cause an infinite loop
				queryClient.clear();
				dispatch({ type: Action.RESET });
			}
		},
		[selectedCompany, state]
	);

	const logout = React.useCallback(async () => {
		await authApi.get('/auth/logout', {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		queryClient.clear();
		dispatch({ type: Action.RESET });
		navigate('/login', { replace: true, state: {} });
	}, []);

	const getUserInfo = React.useCallback(async (userId: number) => {
		const user = await _getUserInfo(userId);
		dispatch({
			type: Action.CURRENT_USER_SUCCESS,
			payload: user,
		});
	}, []);

	const value = {
		...state,
		login,
		getAccessToken,
		logout,
		getUserInfo,
		dispatchLogin: (payload: {
			accessToken: string;
			exp: string | number;
			userId: string;
		}) => dispatch({ type: Action.LOGIN_SUCCESS, payload }),
		dispatchDangerously: dispatch,
	};

	React.useEffect(() => {
		// getAccessToken();
    // We stopped doing this because user now have to choose company manually, rather than we fetch the access token dynamically, when they switch companies at the top bar
	}, [selectedCompany]);

	React.useEffect(() => {
		state.userId && getUserInfo(state.userId);
	}, [state?.userId]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);

import React from 'react';
import {
	Button,
	Box,
	FormTextInput,
	ViewContainer,
	Stack,
	ImageUploader,
} from 'components';
import { Text } from '@mantine/core';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { InputTagsWrapper } from 'components/InputTags/InputTagsWrapper';
import { InputSwitchWrapper } from 'components/InputSwitch/InputSwitchWrapper';
import { Field, Formik } from 'formik';
import { useNotification } from 'providers/NotificationProvider';
import { useModals } from 'providers/ModalsProvider';
import { useCreateNewBrand } from 'hooks/brands';
import { useAppState } from 'providers/AppStateProvider';
import { useGetOneCompany } from 'hooks/companies';
import { useAuth } from 'providers/AuthProvider';

import {
	BrandSchemaCreate,
	BrandSchemaCreateType,
} from 'schemas/brands.schema';
import { useNavigate } from '../../../lib/navigate';

const BrandsCreate = () => {
	const navigate = useNavigate();
	const { selectedCompany, setBrand, setDirty } = useAppState();
	const { data: company, isFetching: fetchingCompany } = useGetOneCompany(
		Number(selectedCompany)
	);
	const { user } = useAuth();
	const initialValues: BrandSchemaCreateType = {
		name: '',
		sales_tax: 0.0,
		company_id: selectedCompany || 0,
		is_sales_tax_in_item_price: true,
		email_recipients: [],
		image_url: '',
		updated_by: user?.name || '',
	};
	const { mutateAsync: createNewBrand } = useCreateNewBrand();
	const toast = useNotification();
	const modal = useModals();

	const breadcrumbsArray = [
		{
			label: 'Brands',
			onClick: () => navigate('/configuration/brands'),
		},
		{
			label: 'Create Brand',
			onClick: () => navigate('#'),
		},
	];

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} style={{ marginBottom: '20px' }} />
			<Formik
				initialValues={initialValues}
				validate={(values) => {
					try {
						BrandSchemaCreate.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					values.company_id = Number(selectedCompany);
					await createNewBrand(values)
						.then((newBrand) => {
							setDirty(false);
							setBrand(newBrand.id);
							navigate('/configuration/brands', {}, true);
							toast.success('Brand has been successfully created');
						})
						.catch((error) => {
							toast.error('An error occured');
						});

					// values.email_recipients = [..._emailRecipients];
					actions.setSubmitting(false);
				}}
			>
				{({ values, errors, touched, dirty, submitForm, setFieldValue }) => {
					React.useEffect(() => {
						if (dirty) {
							setDirty(true);
						}

						return () => setDirty(false);
					}, [dirty]);
					return (
						<Box>
							<Stack direction="row" gap={25}>
								<Stack direction="column" gap={10}>
									<Field
										width="600px"
										as={FormTextInput}
										name="name"
										label="Brand Name"
										isRequired={true}
										maxLength={80}
										error={errors.name && touched.name ? errors.name : null}
									/>
									<Field
										width="600px"
										as={FormTextInput}
										name="company_name"
										label="Company Name"
										isReadOnly={true}
										hint="Company is the business entity that owns the Brand"
										value={fetchingCompany ? '' : company?.name}
									/>
									<Field
										width="600px"
										type="number"
										as={FormTextInput}
										name="sales_tax"
										label="GST (%)"
										hint={`Good and Services Tax. This value will be used to calculate in sale transactions.\r\rIf not applicable, set value to 0`}
										error={
											errors.sales_tax && touched.sales_tax
												? errors.sales_tax
												: null
										}
									/>

									<Field
										component={InputSwitchWrapper}
										name="is_sales_tax_in_item_price"
										isChecked={values.is_sales_tax_in_item_price}
										setIsChecked={(isChecked: boolean) =>
											setFieldValue('is_sales_tax_in_item_price', isChecked)
										}
										width={'600px'}
										label="Is Service Charge and GST included in selling price?"
										hint="If No, Service Charge and GST will be calculated and added on top of the total bill at checkout"
									/>

									<Field
										label="Email Recipients"
										width="600px"
										direction="row"
										tags={values.email_recipients}
										setTags={(tags: string[]) =>
											setFieldValue('email_recipients', [...tags])
										}
										component={InputTagsWrapper}
										error={errors.email_recipients}
									/>

									<Stack
										width={'100%'}
										style={{ display: 'flex', justifyContent: 'flex-start' }}
									>
										<Button onClick={submitForm}>Create</Button>
										<Button
											color="gray.3"
											style={{ color: 'black' }}
											onClick={() => navigate('/configuration/brands')}
										>
											Cancel
										</Button>
									</Stack>
								</Stack>
								<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
									<ImageUploader
										onChange={(file) => setFieldValue('image_url', file)}
										url={values?.image_url}
										inactiveTabs={[
											'Products',
											'Category Groups',
											'Payment Methods',
											'Discounts',
											'Advertisements',
										]}
									/>
									<Text align="left" fs="italic" size="xs" color="gray" >
										Recommended Dimensions: W 300px x H 216px
									</Text>
								</Stack>
							</Stack>
						</Box>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default BrandsCreate;

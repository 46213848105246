import { z } from 'zod';

export const CreateUserSchema = z.object({
	name: z.string({ required_error: 'This field is required' }).max(80),
	email: z
		.string({ required_error: 'This field is required' })
		.email()
		.optional(),
	username: z.string({ required_error: 'This field is required' }).max(80),
	terminal_pin: z
		.string({ required_error: 'This field is required' })
		.length(8, 'Must contain 8 characters')
		// Regex accepts only numbers
		.regex(/^[0-9]*$/, {
			message: 'Only contain numbers 0 to 9',
		}),
	is_superadmin: z.boolean().default(false),
	is_active: z.boolean().default(false),
	send_activation_email: z.string().default('no'),
	roles: z.array(z.any()).min(1),
	// Omit role_ids & brand_outlet, as it will be constructed during pre-submit form
	// brand_outlet: z.record(z.string(), z.array(z.number())),
	// role_ids: z.array(z.number()),
});

export type TCreateUserSchema = z.infer<typeof CreateUserSchema>;

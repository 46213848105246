import { useMutation, useQueryClient } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useDeleteCategoryGroupById() {
	const queryClient = useQueryClient();
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({ id }: { id: string | number }) =>
			configApi
				.delete(`/categorygroups/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['category-groups']);
			},
		}
	);
}

import { AxiosError } from 'axios';
import {
	useMutation,
	UseMutationOptions,
	useQuery,
	useQueryClient,
} from 'react-query';
import { configApi } from '../apis';
import { integerToDollars } from '../lib/helpers';
import { useAuth } from '../providers/AuthProvider';
import {
	ICreateOptionGroup,
	IOptionGroupWithId,
	IOptionGroupWithIdAndOptions,
	TUpdateOptionGroupFormikSchema,
	CopyOptionGroupType,
} from '../schemas/Products/optiongroup.schema';

export function useGetOptionGroups(brand_id = 0) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['optiongroups', `brand_${brand_id}`],
		async (): Promise<{ option_groups: IOptionGroupWithId[] }> =>
			configApi
				.get(`/optiongroups?brand_id=${brand_id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			enabled: !!brand_id,
		}
	);
}

type TOptionGroupList = {
	num_of_pages: number;
	requested_page: number;
	option_groups: IOptionGroupWithId[];
	total_records: number;
};

export function useGetOptionGroupsList(brand_id = 0) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['optiongroups', `brand_${brand_id}`],
		async (): Promise<TOptionGroupList> =>
			configApi
				.get(`/optiongroups`, {
					params: { brand_id, summary: 'yes', archived: 'no', deleted: 'no' },
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			enabled: !!brand_id,
		}
	);
}

export function useGetOptionGroupsArchive() {
	const { getAccessToken } = useAuth();
	return useQuery(
		'optiongroups_archived',
		async (): Promise<IOptionGroupWithId[]> =>
			configApi
				.get('/optiongroups?is_archived=true', {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

export function useGetOptionGroupById(id: number) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['optiongroups', id],
		async (): Promise<IOptionGroupWithIdAndOptions> =>
			configApi
				.get(`/optiongroups/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => ({
					...data,
					options: data.options.map((e: any) => ({
						...e,
						option_value: integerToDollars(e.option_value),
					})),
				})),
		{
			enabled: !!id,
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
}

export function useCreateOptionGroup(
	opts?: Omit<
		UseMutationOptions<any, AxiosError, ICreateOptionGroup, any>,
		'mutateFn'
	>
) {
	const { getAccessToken } = useAuth();
	return useMutation(
		async (createOptionGroupSchema: ICreateOptionGroup) =>
			configApi
				.post(`/optiongroups`, createOptionGroupSchema, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			...opts,
		}
	);
}

export function useCopyOptionGroup(
	opts?: Omit<
		UseMutationOptions<any, AxiosError, CopyOptionGroupType, any>,
		'mutateFn'
	>
) {
	const { getAccessToken } = useAuth();
	const queryClient = useQueryClient();
	return useMutation(
		async (body: CopyOptionGroupType) =>
			configApi
				.post(`optiongroups/${body.id}/duplicate`, body, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),

		{
			onSuccess: (_, vars) => {
				queryClient.invalidateQueries(['optiongroups']);
			},
		}
	);
}

export function useUpdateOptionGroup(
	opts?: Omit<
		UseMutationOptions<any, AxiosError, TUpdateOptionGroupFormikSchema, any>,
		'mutateFn'
	>
) {
	const { getAccessToken } = useAuth();
	const queryClient = useQueryClient();
	return useMutation(
		async (updateOptionGroupSchema: TUpdateOptionGroupFormikSchema) =>
			configApi
				.put(
					`optiongroups/${updateOptionGroupSchema.id}`,
					updateOptionGroupSchema,
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data),

		{
			...opts,
			onSuccess: (_, vars) => {
				queryClient.invalidateQueries(['optiongroups', vars.id]);
			},
		}
	);
}

export function useArchiveOptionGroup(
	opts?: Omit<
		UseMutationOptions<any, AxiosError, { id: number }, any>,
		'mutateFn'
	>
) {
	const { getAccessToken } = useAuth();
	const queryClient = useQueryClient();
	return useMutation(
		async (id: number) =>
			configApi
				.post(`optiongroups/${id}/archive`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['optiongroups']);
			},
		}
	);
}

export function useUnarchiveOptionGroup(
	opts?: Omit<
		UseMutationOptions<any, AxiosError, { id: number }, any>,
		'mutateFn'
	>
) {
	const { getAccessToken } = useAuth();
	const queryClient = useQueryClient();
	return useMutation(
		async (id: number) =>
			configApi
				.post(`optiongroups/${id}/unarchive`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['optiongroups']);
			},
		}
	);
}

export function useGetProductsUsingOptionGroup(optionGroupId: number) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['option_groups_products', optionGroupId],
		async () =>
			configApi
				.get(`/optiongroups/${optionGroupId}/products`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			enabled: !!optionGroupId,
		}
	);
}

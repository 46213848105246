import { useQuery } from 'react-query';
import { configApi } from '../apis';
import { useAuth } from '../providers/AuthProvider';
import { BcItem } from '../schemas/bc_item.schema';

export function useGetBcItems(searchTerm: string) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['bcitems', `${searchTerm}`],
		async (): Promise<BcItem[]> =>
			configApi
				.post(
					`/bc/items`,
					{ search_term: searchTerm },
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			enabled: !!searchTerm && searchTerm.length > 5 && searchTerm.length < 10,
		}
	);
}

/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Button, Select } from '@mantine/core';
import {
	Breadcrumbs,
	EmptyState,
	SelectBrand,
	useSelectBrand,
	ViewContainer,
} from 'components';
import { useAppState } from 'providers/AppStateProvider';
import { useGetPosList } from 'hooks/menus';
import { useNavigate } from '../../../lib/navigate';
import dayjs from 'dayjs';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';
import {
	SelectOutlet,
	useSelectOutlet,
} from '../../../components/SelectOutlet';
import { Box, Group } from '@mantine/core';
import Icon from '../../../assets/icons';
import { ProductRoutes } from '../../../router/constants/Products.routes';

const PosEmptyState = () => {
	const navigate = useNavigate();
	return (
		<EmptyState
			resource="Point of Sales"
			secondaryMessage="Please create Point Of Sales before assigning Categories to Menu"
			CallToAction={
				<Button
					leftIcon={<Icon name="add" />}
					size="md"
					onClick={() => navigate(ConfigurationRoutes.CreatePos)}
				>
					Create Point Of Sales
				</Button>
			}
		/>
	);
};

const OutletEmptyState = () => {
	const navigate = useNavigate();
	return (
		<EmptyState
			resource="Outlets"
			secondaryMessage="Please create Outlet before creating Point of Sale for Menu"
			CallToAction={
				<Button
					leftIcon={<Icon name="add" />}
					size="md"
					onClick={() => navigate(ConfigurationRoutes.CreateOutlet)}
				>
					Create Outlet
				</Button>
			}
		/>
	);
};

const BrandEmptyState = () => {
	const navigate = useNavigate();
	return (
		<EmptyState
			resource="Brands"
			secondaryMessage="Please create Brand & Outlet before creating Point of Sale for Menu"
			CallToAction={
				<Button
					leftIcon={<Icon name="add" />}
					size="md"
					onClick={() => navigate(ConfigurationRoutes.CreateBrand)}
				>
					Create Brand
				</Button>
			}
		/>
	);
};

const Menu = () => {
	const { selectedBrand } = useAppState();
	const [selectedOutlet, setSelectedOutlet] = React.useState<string | null>(
		null
	);
	const { hasOutlets } = useSelectOutlet(selectedBrand!);
	const { hasBrands } = useSelectBrand();
	const navigate = useNavigate();

	const { data: response } = useGetPosList({
		brand_id: selectedBrand!,
		outlet_id: selectedOutlet,
	});

	const hasPos = !!response?.length;

	React.useEffect(() => {
		setSelectedOutlet(null);
	}, [selectedBrand]);

	return (
		<ViewContainer
			title={
				<Breadcrumbs
					items={[
						{
							label: 'Menu',
							onClick: () => null,
						},
					]}
				/>
			}
		>
			<Group my="xl">
				<SelectBrand />
				{hasBrands && (
					<SelectOutlet
						brandId={selectedBrand!}
						value={selectedOutlet || null}
						onChange={(value) => {
							if (value) {
								setSelectedOutlet(value);
							} else {
								setSelectedOutlet(null);
							}
						}}
					/>
				)}
			</Group>
			<Box py="xl">
				{!hasBrands ? (
					<BrandEmptyState />
				) : !hasOutlets ? (
					<OutletEmptyState />
				) : !hasPos ? (
					<PosEmptyState />
				) : (
					<SimpleDataGrid
						data={response}
						columnFilters={
							selectedOutlet
								? [
										{
											id: 'outlet_id',
											value: Number(selectedOutlet),
										},
								  ]
								: []
						}
						columnVisibility={{
							outlet_id: false,
						}}
						columns={[
							{ accessorKey: 'id', header: 'ID' },
							{
								accessorKey: 'outlet_id',
								header: 'outlet_id',
								enableHiding: true,
								filterFn: 'equals',
							},
							{ accessorKey: 'name', header: 'Name' },
							{
								accessorKey: 'updated_at',
								header: 'Last Updated',
								cell: (row: any) =>
									dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
							},
						]}
						onClickHandler={(row) => {
							navigate(
								ProductRoutes.MenuPreview.replace(':posId', row?.original?.id)
							);
						}}
					/>
				)}
			</Box>
		</ViewContainer>
	);
};

export default Menu;

import { Field, Formik } from 'formik';
import React from 'react';
import {
	Box,
	Breadcrumbs,
	CancelButton,
	TextField,
	ViewContainer,
} from '../../components';
import { useGetPermissions } from '../../hooks/api/permission/useGetPermissions';
import { useCreateRole } from '../../hooks/api/roles/useCreateRole';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';
import TerminalPanel from './CreateRole/TerminalPanel';
import DashboardPanel from './CreateRole/DashboardPanel';
import { toFormikValidationSchema } from '../../lib/helpers';
import { CreateRoleSchema } from '../../schemas/roles.schema';
import { useNotification } from '../../providers/NotificationProvider';
import { Group, Tabs, Button } from '@mantine/core';
import { isAxiosError } from 'axios';
import { notifications } from '@mantine/notifications';
import e from 'express';

const CreateRole = () => {
	const { selectedCompany } = useAppState();
	const { mutate } = useCreateRole();
	const navigate = useNavigate();
	const notify = useNotification();
	return (
		<Formik
			initialValues={{
				name: '',
				company_id: selectedCompany,
				description: '',
				permissions: {
					product: [] as string[],
					configuration: [] as string[],
					pointOfSales: [] as string[],
					payment: [] as string[],
					permissions: [] as string[],
					fsIntegration: [] as string[],
					reports: [] as string[],
					analytics: [] as string[],
					terminal: [] as string[],
				},
			}}
			validationSchema={toFormikValidationSchema(CreateRoleSchema)}
			onSubmit={({ name, company_id, permissions }) => {
				const _permissions = Object.values(permissions).reduce((acc, val) => {
					return acc.concat(val);
				}, [] as string[]);

				if(name.toLowerCase().replace(" ", "") != "superadministrator"){
					mutate(
						{
							name,
							company_id: company_id || 0,
							description: name,
							permissions: _permissions,
						},
						{
							onSuccess: (_, { name }) => {
								notifications.show({
									color: 'green',
									message: `Created role ${name}`,
								});
								navigate(PermissionRoutes.Roles);
							},
							onError: (err) => {
								if (isAxiosError(err)) {
									notifications.show({
										color: 'red',
										message: err.response?.data.error,
									});
								}
							},
						}
					);

				} else {

					notifications.show({
						color: 'red',
						message: "Sorry, the Super Administrator role name is reserved.",
					});
				}


				
			}}
			enableReinitialize
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ submitForm, values, errors }) => {
				return (
					<ViewContainer
						title={
							<Breadcrumbs
								items={[
									{
										label: 'Roles',
										onClick: () => navigate(PermissionRoutes.Roles),
									},
									{
										label: 'Create Role',
									},
								]}
							/>
						}
					>
						{/* <pre>{JSON.stringify({ values, errors }, null, 2)}</pre> */}
						<Box my="40px">
							<Field
								as={TextField}
								name="name"
								label="Role Name"
								maxLength={80}
								required
							/>
						</Box>
						<Tabs defaultValue="dashboard">
							<Tabs.List>
								<Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
								<Tabs.Tab value="terminal">Terminal</Tabs.Tab>
							</Tabs.List>
							<Tabs.Panel value="dashboard" py="xl">
								<DashboardPanel />
							</Tabs.Panel>
							<Tabs.Panel value="terminal" py="xl">
								<TerminalPanel />
							</Tabs.Panel>
						</Tabs>
						<Group my="xl">
							<Button onClick={() => submitForm()}>Create</Button>
							<CancelButton onClick={() => navigate(PermissionRoutes.Roles)}>
								Cancel
							</CancelButton>
						</Group>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default CreateRole;

import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useDuplicateCateogry() {
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({ categoryId, payload }: { categoryId: string; payload: any }) =>
			configApi
				.post(`/categories/${categoryId}/duplicate`, payload, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

export type TAuthState = {
	isAuthenticated: boolean;
	userId: number | null;
	user: Record<string, any> | null;
	accessToken: string | null;
	exp: number | null;
	isLoading: boolean;
	isError: boolean;
	permissions: string[] | null;
	dispatchLogin: (payload: {
		accessToken: string;
		exp: string | number;
		userId: string;
		permissions: string[] | null;
	}) => void;
	dispatchDangerously: React.Dispatch<{
		type: Action;
		payload?: any;
	}>;
};
export type TAuthContext = TAuthState & {
	login: (data: any) => void;
	logout: () => void;
	getAccessToken: (toDispatch?: 'no' | 'yes') => any;
	getUserInfo: (userId: number) => any;
};

export type User = {
	id: number;
	firstName: string;
	lastName: string;
	name: any;
	role: any;
	permissions: any;
	email: string;
};

export enum Action {
	LOGIN_LOADING,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	ACCESS_TOKEN_LOADING,
	ACCESS_TOKEN_SUCCESS,
	ACCESS_TOKEN_FAILURE,
	RESET,
	CURRENT_USER_SUCCESS,
}

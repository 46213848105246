import {z} from 'zod'
import { BaseSchema } from './base.schema'

export const BrandSchema = BaseSchema.extend({
    name: z.string(
        {required_error: 'This field is required.'}
    ).min(1,'This field is required.')
    .max(80, 'Only a maximum of 80 characters is allowed'),
    company_id: z.number(),
    sales_tax: z.number({
        required_error: "This field is required.",
        invalid_type_error: "This field can only contain numeric values and \".\"",
      }).gte(0,"GST must be a positive number").lte(99.99,"GST must be below 99.99"),
    is_sales_tax_in_item_price: z.boolean(),
    email_recipients: z.array(z.string().email().max(80)).nullable(),
    image_url: z.string(),
    updated_by: z.string()
})

export type BrandSchemaType = z.infer<typeof BrandSchema>

export const BrandSchemaCreate = BrandSchema.pick({
    name: true, company_id: true, sales_tax: true, is_sales_tax_in_item_price: true, email_recipients: true, image_url: true, updated_by: true
})

export type BrandSchemaCreateType = z.infer<typeof BrandSchemaCreate>

export const BrandSchemaUpdate = BrandSchema.pick({
    name: true, company_id: true, sales_tax: true, is_sales_tax_in_item_price: true, email_recipients: true, image_url: true, updated_by: true
})

export type BrandSchemaUpdateType = z.infer<typeof BrandSchemaUpdate>
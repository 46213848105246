import { useQuery } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

export function useGetPosList({
	brand_id,
	outlet_id,
}: {
	brand_id: number | string;
	outlet_id: number | string | null;
}) {
	const { getAccessToken } = useAuth();
	const getPosList = async () => {
		const response = await configApi.get(`/menu`, {
			params: {
				brand_id,
				outlet_id,
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['menu_pos_list', { brand_id, outlet_id }], getPosList, {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!brand_id,
	});
}

export function useGetPosMenuById(posId: number | string) {
	const { getAccessToken } = useAuth();
	const getPosMenu = async () => {
		const response = await configApi.get(`/menu/${posId}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['menu_preview', posId], getPosMenu, {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!posId,
	});
}

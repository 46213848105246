import styled from '@emotion/styled';
import { UnstyledButton } from '@mantine/core';
import { FormSelectInputProps } from './FormSelectInput';

export const StyledSelectBox = styled(UnstyledButton)<FormSelectInputProps>`
	border: 1px solid ${({ theme }) => theme.colors.grey10};
	border-radius: 4px;
	padding: 16px 12px;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.font.sansSerif};
	width: ${({ width }) => width ?? '100%'};
	display: flex;
	align-items: center;
	justify-content: space-between;
	justify-self: start;

	${({ isDisabled }) => isDisabled && `pointer-events: none;`}
`;

export const StyledSelectLabel = styled.label`
	font-family: ${({ theme }) => theme.font.sansSerif};
`;

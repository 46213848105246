import React from 'react';
import { theme } from '../../providers/ThemeProvider/index';
import { DivProps, Div } from '../Div';
import { Stack } from '../Stack';
import { Text, TextProps } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';
import { InputTags } from './InputTags';

const StyledWrapper = styled(Div)``;

export type StyledWrapperProps = DivProps & {
	tags: string[];
	setTags: (tags: string[]) => void;
	onAddTag?: (tag: string) => void;
	onDeleteTag?: (tag: string) => void;
	onClickTag?: (tag: string) => void;
	isReadOnly?: boolean;
	label?: React.ReactNode;
	error?: React.ReactNode;
	labelProps?: TextProps;
	hasAsterisk?: boolean;
	direction?: React.CSSProperties['flexDirection'];
	width?: React.CSSProperties['width'];
	hint?: string;
};

const StyledInfoIcon = styled(InfoOutlinedIcon)`
	color: ${({ theme }) => theme.colors.grey50};

	:hover {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}
`;

export const InputTagsWrapper = React.forwardRef<
	HTMLDivElement,
	StyledWrapperProps
>(
	(
		{
			children,
			direction,
			label,
			error,
			hasAsterisk,
			labelProps,
			width,
			hint,
			tags,
			setTags,
			onAddTag,
			onDeleteTag,
			onClickTag,
			isReadOnly,
			...rest
		},
		ref
	) => {
		const errorArray = error?.toString().split(',') ?? [];
		const latestError = errorArray[errorArray.length - 1];
		const widthNumber = Number(String(width).replace('px',''))
		
		return (
			<StyledWrapper {...rest} ref={ref}>
				<Stack direction={direction} width="100%" align="center">
					<Div
						css={{ 
							width: String((widthNumber * (25/100)))+'px',
							textAlign: 'left', 
							alignSelf: 'flex-start' 
						}}
					>
						{label && (
							<Text
								size={14}
								sx={{
									display: 'inline-block',
								}}
								{...labelProps}
							>
								{label}
								{hasAsterisk && (
									<Text
										component="span"
										color="red"
										css={{ marginLeft: '4px' }}
									>
										*
									</Text>
								)}
							</Text>
						)}
						{hint && (
							<Tooltip
								label={hint}
								position={'bottom-start'}
								css={{ display: 'inline-block' }}
							>
								<StyledInfoIcon fontSize="small" />
							</Tooltip>
						)}
					</Div>

					<span
						style={{ width: String((widthNumber * (75/100)))+'px', textAlign: 'left', alignItems: 'center' }}
					>
						<InputTags
							tags={tags}
							setTags={setTags}
							onAddTag={onAddTag}
							onDeleteTag={onDeleteTag}
							onClickTag={onClickTag}
							isReadOnly={isReadOnly}
						/>
						{latestError ? (
							<span style={{ marginTop: '6px' }}>
								<Text component="small" color={theme.colors.red} size="14px">
									{latestError}
								</Text>
							</span>
						) : null}
					</span>
				</Stack>
			</StyledWrapper>
		);
	}
);

InputTagsWrapper.displayName = 'Input Tags Wrapper';

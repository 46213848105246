import React from 'react';
import { Grid, MultiSelect, Select, TextInput } from '@mantine/core';
import { useGetCategoryGroups } from '../../../hooks';
import { useAppState } from '../../../providers/AppStateProvider';
import { useCategoriesContext } from './context';
import { useDebouncedValue } from '@mantine/hooks';

const CategoriesSearchFilter = () => {
	const { searchParams, setDisableDnd, setSearchParams } =
		useCategoriesContext();
	const { selectedBrand } = useAppState();
	const { data: categoryGroups } = useGetCategoryGroups({
		brandId: selectedBrand!,
	});

	const [searchKeyword, setSearchKeyword] = React.useState('');
	const [debouncedValue] = useDebouncedValue(searchKeyword, 500);

	React.useEffect(() => {
		setSearchParams((p) => ({
			...p,
			search_keyword: debouncedValue,
		}));
	}, [debouncedValue]);

	return (
		<Grid w="100%" my="xl">
			<Grid.Col lg={2}>
				<Select
					value={searchParams?.search_by}
					onChange={(value) => {
						if (value === 'category' || value === 'product') {
							setSearchParams((p) => ({ ...p, search_by: value }));
						}

						if (value === 'product') {
							setDisableDnd(true);
						} else {
							setDisableDnd(false);
						}
					}}
					data={[
						{ value: 'category', label: 'Search by category' },
						{ value: 'product', label: 'Search by product' },
					]}
				/>
			</Grid.Col>
			<Grid.Col lg={4}>
				<TextInput
					value={searchKeyword}
					onChange={(e) => {
						setSearchKeyword(e.currentTarget.value);
						if (e.currentTarget.value) {
							setDisableDnd(true);
						} else {
							setDisableDnd(false);
						}
					}}
					placeholder="Search…"
				/>
			</Grid.Col>
			<Grid.Col lg={2}>
				<Select
					placeholder="Product Type"
					data={[
						{ value: 'all', label: 'All Product Types' },
						{ value: 'yes', label: 'With Option Groups' },
						{ value: 'no', label: 'Without Option Groups' },
					]}
					onChange={(value) => {
						if (value) {
							setSearchParams((p) => ({
								...p,
								with_opt_groups: value,
							}));
						}
					}}
				/>
			</Grid.Col>
			<Grid.Col lg={2}>
				<MultiSelect
					data={
						[
							{ value: 'null', label: 'Without Category Groups' },
							...(categoryGroups?.categoryGroups?.map((e) => ({
								value: e?.id?.toString(),
								label: e?.name,
							})) || []),
						] || []
					}
					placeholder="Category Group"
					value={searchParams?.category_group_id}
					onChange={(value) => {
						if (value) {
							setSearchParams((p) => ({ ...p, category_group_id: value }));
						}
					}}
				/>
				{/* <Select
					data={}
					onChange={(value) => {
						if (value || value === '') {
							setSearchParams((p) => ({ ...p, category_group_id: +value }));
						}
					}}
				/> */}
			</Grid.Col>
			<Grid.Col lg={2}>
				<Select
					placeholder="Sort"
					value={searchParams?.sort}
					onChange={(value) => {
						if (value) {
							setSearchParams((p) => ({ ...p, sort: value }));
						}
					}}
					data={[
						{
							label: 'Name',
							value: 'name:asc',
						},
						{
							label: 'Last updated',
							value: 'updated_at:desc',
						},
						{
							label: 'Description',
							value: 'description:asc',
						},
					]}
				/>
			</Grid.Col>
		</Grid>
	);
};

export default CategoriesSearchFilter;

import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledInputRadio, StyledRadiomark } from './InputRadio.styled';

export type InputRadioProps = DefaultProps<HTMLInputElement> & {
	checked?: boolean;
	readOnly?: boolean;
	label?: React.ReactNode;
	labelProps?: DefaultProps<HTMLLabelElement>;
	name?: string;
};

export const InputRadio = React.forwardRef<HTMLInputElement, InputRadioProps>(
	({ label, ...rest }, ref) => {
		return (
			<label
				style={{
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					cursor: 'pointer',
				}}
			>
				<StyledInputRadio {...rest} type="radio" ref={ref} />
				<StyledRadiomark data-label="styled-radiomark" />
				{label ? (
					<p style={{ marginLeft: '30px', height: '100%', marginTop: '1.5px' }}>
						{label}
					</p>
				) : null}
			</label>
		);
	}
);

InputRadio.displayName = 'InputRadio';

import { Dialog } from '@headlessui/react';
import React, { PropsWithChildren } from 'react';
import Icon from '../../assets/icons';
import { ActionIcon } from '@mantine/core';
import { Div, DivProps } from '../Div';
import { Overlay } from '../Overlay';
import { Paper } from '../Paper';
import { Stack } from '../Stack';
import { Text, TextProps } from '@mantine/core';

export type ModalProps = Omit<DivProps, 'title'> & {
	open: boolean;
	onClose: (value: boolean) => void;
	title?: React.ReactNode;
	closeOnClickOutside?: boolean;
	hideCloseButton?: boolean;
	hideTitle?: boolean;
};

const defaultProps = {
	closeOnClickOutside: true,
};

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
	(
		{
			children,
			open,
			onClose,
			title,
			closeOnClickOutside,
			hideCloseButton,
			hideTitle,
			...rest
		},
		ref
	) => {
		const [opened, setOpened] = React.useState<boolean>(false);

		React.useEffect(() => {
			setOpened(open);
		}, [open]);

		const closeModalHandler = () => {
			setOpened(false);
			onClose && onClose(false);
		};

		return opened ? (
			<Dialog
				open={opened}
				onClose={() => {
					if (closeOnClickOutside) {
						closeModalHandler();
					}
				}}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100vh',
					width: '100vw',
					display: 'grid',
					placeItems: 'center',
					zIndex: 1000,
				}}
			>
				<Overlay />
				<Dialog.Panel as={Div} style={{ zIndex: 1999 }} ref={ref}>
					<Paper
						css={{
							position: 'relative',
							width: '500px',
							height: 'auto',
							padding: '16px',
							zIndex: 1999,
						}}
						{...rest}
					>
						{!hideTitle ? (
							<Stack justify="space-between" align="center" width="100%">
								<Text>{title ?? null}</Text>
								{!hideCloseButton ? (
									<ActionIcon
										css={{ padding: '0px', height: 'auto', width: 'auto' }}
										onClick={() => {
											closeModalHandler();
											onClose && onClose(false);
										}}
									>
										<Icon name="close" />
									</ActionIcon>
								) : null}
							</Stack>
						) : null}
						<Div css={{ padding: '0px' }}>{children}</Div>
					</Paper>
				</Dialog.Panel>
			</Dialog>
		) : null;
	}
);

Modal.displayName = 'Modal';
Modal.defaultProps = defaultProps;

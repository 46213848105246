import styled from '@emotion/styled';
import { Div } from '../Div';
import { BreadcrumbsProps } from './Breadcrumbs';

export const StyledBreadcrumbs = styled(Div)<Omit<BreadcrumbsProps, 'onChange' | 'items' | 'label'>>`
	display: flex;
	flex-direction: row;
	gap: 20px;
	list-style: none;
	margin: 0;
	padding: 0;
	align-items: center;

	li {
		margin: 0;
		padding: 0;
	}
`;

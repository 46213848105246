import {z} from 'zod'
import { BaseSchema } from './base.schema'

export const OutletSchema = BaseSchema.extend({
    name: z.string({required_error: 'This field is required.'}).min(1, 'This field is required.').max(80, 'Only a maximum of 80 characters is allowed'),
	mall_id: z.number().int().nullable(),
	brand_id: z.number().int(),
	currency_id: z.number().int(),
	address_line1: z.string({required_error: 'This field is required.'}).min(1, 'This field is required.').max(80, 'Only a maximum of 80 characters is allowed'),
	address_line2: z.string().max(30, 'Only a maximum of 30 characters is allowed'),
	zip_code: z.string({ required_error: 'This field is required.'}).min(1, 'This field is required.').max(30, 'Only a maximum of 30 characters is allowed').regex(
		new RegExp('^[0-9]*$'),
	'This field can only contain numbers'),
	phone_number: z.string().regex(
		new RegExp('^[0-9]*$'),
	'This field can only contain numbers').min(1,'This field is required').max(8, 'Only a maximum of 8 characters is allowed'),
	tax_reg_number: z.string().max(255),
	dimension_code_prefix: z.string().min(1, 'This field is required.').max(30, 'Only a maximum of 30 characters is allowed' ),
	dimension_code_discount: z.string().min(1, 'This field is required.').max(80, 'Only a maximum of 80 characters is allowed'),
	updated_by: z.string()
})

export type OutletSchemaType = z.infer<typeof OutletSchema>

export const OutletSchemaCreate = OutletSchema.pick({
	name: true,
	mall_id: true,
	brand_id: true,
	currency_id: true,
	address_line1: true,
	address_line2: true,
	zip_code: true,
	phone_number: true,
	tax_reg_number: true,
	updated_by: true
})

export type OutletSchemaCreateType = z.infer<typeof OutletSchemaCreate>

export const OutletSchemaUpdate = OutletSchemaCreate

export const OutletSchemaCreateWithBC = OutletSchema.pick({
	name: true,
	mall_id: true,
	brand_id: true,
	currency_id: true,
	address_line1: true,
	address_line2: true,
	zip_code: true,
	phone_number: true,
	tax_reg_number: true,
	updated_by: true,
	dimension_code_discount: true,
	dimension_code_prefix: true
})

export type OutletSchemaCreateWithBCType = z.infer<typeof OutletSchemaCreateWithBC>

export const OutletSchemaUpdateWithBC = OutletSchemaCreateWithBC
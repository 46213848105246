import React from 'react';
import { Group } from '@mantine/core';
import {
	Breadcrumbs,
	Box,
	Button,
	DateField,
	Divider,
	SelectField,
	Stack,
	ViewContainer,
} from 'components';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useGetBrands } from 'hooks/brands';
import { useGetOutlets } from 'hooks/outlets';
import { useGetPos } from 'hooks/api/pos/useGetPos';
import {
	useGetRawReport,
	useGetSummaryReport,
	useGetZReport,
} from 'hooks/api/financereports';
import Icon from 'assets/icons';
import RequirePermissions from '../../guards/RequirePermissions';

type ErrorType = {
	[key: string]: string;
};

const FinanceReports = () => {
	const [selectedOutlet, setSelectedOutlet] = React.useState<string>('all');
	const { selectedCompany, selectedBrand, setBrand, setDirty } = useAppState();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const toast = useNotification();

	// Hooks
	const { data: response_brands, isFetching: isFetchingBrands } = useGetBrands(
		selectedCompany || 0
	);
	const { data: response_outlets } = useGetOutlets(selectedBrand ?? 0);
	const { data: response_pos, refetch: refetch_pos } = useGetPos({
		brandId: selectedBrand || 0,
		outletId: selectedOutlet === 'all' ? null : +selectedOutlet,
	});

	const REPORT_TYPES = [
		{
			value: 'z-report',
			label: 'Z-Report',
		},
		{
			value: 'raw-report',
			label: 'Raw Report',
		},
		{
			value: 'sale-summary-report',
			label: 'Sale Summary Report',
		},
	];

	React.useEffect(() => {
		refetch_pos();
	}, [selectedBrand, selectedOutlet]);

	return (
		<ViewContainer>
			<Formik
				initialValues={{
					brand_id: selectedBrand,
					outlet: 'all',
					pos: 'all',
					start_date: dayjs(new Date()).subtract(1, 'day'),
					end_date: dayjs(new Date()).subtract(1, 'day'),
					report_type: REPORT_TYPES?.at(0)?.value,
				}}
				validate={(values) => {
					try {
						const errors: ErrorType = {};
						const today = dayjs(new Date());
						const end_date = `${values.end_date.format(
							'YYYY-MM-DD'
						)}T23:59:59.000Z`;

						// Make sure that date range is between 30 days, end_date is after start_date and end_date is not in the future
						if (dayjs(values.start_date).isAfter(dayjs(end_date))) {
							errors.end_date =
								'Unable to download report as selected start date cannot be later than the end date';
						} else if (
							Math.abs(dayjs(values.start_date).diff(dayjs(end_date), 'days')) >
							30
						) {
							errors.end_date =
								'Unable to download report as selected date range is more than 30 days';
						} else if (dayjs(end_date).isAfter(today)) {
							errors.end_date =
								'Unable to download report as the date range selected cannot include a future date';
						}

						// Check if start_date is not a future date
						if (dayjs(values.start_date).isAfter(today)) {
							errors.start_date =
								'Unable to download report as the date range selected cannot include a future date';
						}

						// Check if brand_id is selected
						if (values.brand_id === 0 || !values.brand_id) {
							errors.brand_id = 'Please select a brand';
						}

						return errors;
					} catch (error: any) {
						toast.error('An error occured');
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					try {
						setIsLoading(true);
						const report_ids: string[] = [];
						const { brand_id, outlet, pos, start_date, end_date, report_type } =
							values;
						let fileName = `${
							response_brands?.brands?.find((e: any) => e.id === brand_id)?.name
						}`;

						if (outlet !== 'all') {
							fileName += `-${
								response_outlets?.outlets?.find((e: any) => e.id === +outlet)
									?.name
							}`;
						}

						const outlet_list =
							outlet === 'all'
								? response_outlets?.outlets?.map((outlet: any) => outlet.id)
								: [+outlet];

						if (response_outlets?.outlets?.length > 0) {
							const pos_list = pos === 'all' ? [] : [+pos];

							if (pos !== 'all') {
								fileName += `-${
									response_pos?.pos?.find((e: any) => e.id === +pos)?.name
								}`;
							}

							const date_list = [];
							const date_cursor = dayjs(start_date);
							const num_of_days = Math.abs(
								date_cursor.diff(dayjs(end_date), 'day')
							);

							for (let i = 0; i <= num_of_days; i++) {
								date_list.push(date_cursor.add(i, 'day').format('YYYYMMDD'));
							}

							date_list.map((date: string) => {
								outlet_list.map((item: string) => {
									report_ids.push(`${date}-${item}-${brand_id}`);
								});
							});

							fileName += `-${dayjs(start_date).format('YYYYMMDD')}-${dayjs(
								end_date
							).format('YYYYMMDD')}`;

							if (report_type === 'raw-report') {
								await useGetRawReport({
									fileName,
									report_ids,
									terminal_ids: pos_list,
								});
							} else if (report_type === 'sale-summary-report') {
								await useGetSummaryReport({
									fileName,
									report_ids,
									terminal_ids: pos_list,
								});
							} else if (report_type === 'z-report') {
								await useGetZReport({
									fileName,
									report_ids,
									terminal_ids: pos_list,
								});
							}

							toast.success(
								`${report_type
									?.replaceAll('-', ' ')
									.replace(/(^\w|\s\w)/g, (m) =>
										m.toUpperCase()
									)} has been successfully downloaded.`
							);
						} else {
							toast.error(
								'Unable to download report as selected brand has no outlets'
							);
						}

						setIsLoading(false);

						actions.setSubmitting(false);
					} catch (error) {
						setIsLoading(false);

						actions.setSubmitting(false);
					}
				}}
			>
				{({ values, errors, setFieldValue, submitForm }) => {
					return (
						<>
							<Box style={{ position: 'relative', marginBottom: '20px' }}>
								<Group w="100%" position="apart">
									<Breadcrumbs
										items={[
											{
												label: 'Finance Reports',
											},
										]}
									/>
									<RequirePermissions permissions={['Finance.Download']}>
										<Button
											leftIcon={<Icon name="download" />}
											onClick={submitForm}
											loading={isLoading}
										>
											Download
										</Button>
									</RequirePermissions>
								</Group>
							</Box>
							<Divider />
							<Stack
								direction="column"
								gap={15}
								style={{
									marginTop: '15px',
								}}
							>
								<SelectField
									labelWidth={240}
									width={690}
									label="Brand"
									placeholder="Select Brand"
									value={String(values?.brand_id)}
									onChange={(value) => {
										if (value) {
											setTimeout(() => {
												setFieldValue('brand_id', +value);
											}, 100);
											setFieldValue('outlet', 'all');
											setFieldValue('pos', 'all');

											setBrand(+value);
										}
									}}
									data={
										response_brands?.brands?.map((e: any) => ({
											value: e.id.toString(),
											label: e.name,
										})) ?? []
									}
									error={errors?.brand_id ?? null}
								/>

								<SelectField
									labelWidth={240}
									width={690}
									label="Outlet"
									name="outlet"
									value={String(values?.outlet)}
									placeholder="Select an outlet here"
									data={[
										{ value: 'all', label: 'All Outlets' },
										...(response_outlets?.outlets?.map((e: any) => ({
											value: String(e?.id),
											label: e?.name,
										})) ??
											([] || [])),
									]}
									onChange={(value) => {
										if (value) {
											setFieldValue('outlet', value);
											setFieldValue('pos', 'all');
											setSelectedOutlet(value);
										}
									}}
								/>
								<SelectField
									labelWidth={240}
									width={690}
									label="POS"
									name="pos"
									value={String(values?.pos)}
									placeholder="Select a POS here"
									data={[
										{ value: 'all', label: 'All Point of Sales' },
										...(response_pos?.pos?.map((e: any) => ({
											value: String(e?.id),
											label: e?.name,
										})) ??
											([] || [])),
									]}
									onChange={(value) => {
										if (value) {
											setFieldValue('pos', value);
										}
									}}
								/>

								<DateField
									width="920px"
									label="Start Date"
									value={values?.start_date?.toDate() ?? new Date()}
									onChange={(value) => {
										if (value) {
											const valueArray = value?.toISOString().split('T');
											setFieldValue(
												'start_date',
												dayjs(`${valueArray.at(0)}T00:00:00.000Z`)
											);
										}
									}}
									valueFormat="DD/MM/YYYY"
								/>
								<DateField
									width="920px"
									label="End Date"
									value={values?.end_date?.toDate() ?? new Date()}
									onChange={(value) => {
										if (value) {
											const valueArray = value?.toISOString().split('T');
											setFieldValue(
												'end_date',
												dayjs(`${valueArray.at(0)}T00:00:00.000Z`)
											);
										}
									}}
									valueFormat="DD/MM/YYYY"
									error={errors.end_date as string}
								/>
								<SelectField
									labelWidth={240}
									width={690}
									label="Report Type"
									name="report_type"
									value={String(values?.report_type)}
									placeholder="Select a Report Type here"
									data={REPORT_TYPES}
									onChange={(value) => {
										if (value) {
											setFieldValue('report_type', value);
										}
									}}
								/>
							</Stack>
						</>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default FinanceReports;

import React from 'react';
import {
	Div,
	ListGroup,
	useFormikContext,
	DragDropContext,
	Droppable,
} from '../../../../components';
import MenuPreviewCategoryItem from './MenuPreviewCategoryItem';

type Props = {
	selectedCategory: number | null;
	setSelectedCategory: (value: number | null) => void;
	userPermissions: string[] | null;
};

const MenuPreviewCategories = ({
	selectedCategory,
	setSelectedCategory,
	userPermissions
}: Props) => {
	
	const { values, setFieldValue } = useFormikContext<any>();

	React.useEffect(() => {
		
		if (values?._categories?.length) {
			setSelectedCategory(values?._categories[0]);
		}

	}, [values]);

	React.useEffect(() => {
		
		setFieldValue('selectedCategory', 
			selectedCategory)

	}, [selectedCategory]);

	return !values?._categories?.length ? null : (
		<DragDropContext
			onDragEnd={({ source, destination }) => {
				if (source && destination) {
					// Copy values
					const _items = [...values._categories];
					// Get item to be removed
					const _item = values?._categories[source.index];
					// Remove item
					_items.splice(source.index, 1);
					// Add item
					_items.splice(destination.index, 0, _item);
					// Set new values
					setFieldValue('_categories', _items);
				}
			}}
		>
			<Droppable
				droppableId={`droppable-categories-pos_id-${values?.posId}`}
				direction="vertical"
			>
				{(provided) => (
					<Div {...provided.droppableProps} ref={provided.innerRef}>
						<ListGroup isHoverable>
							{values?._categories
								?.map((e: any, idx: number) => (
									<MenuPreviewCategoryItem
										key={e}
										index={idx}
										draggableId={`category-${e}`}
										categoryId={e}
										isDragDisabled={!userPermissions?.includes("Categories.Edit")}
										unlinkHandler={(categoryId) => {
											setFieldValue(
												'_categories',
												values?._categories?.filter(
													(cat: any) => cat !== categoryId
												)
											);

											if (selectedCategory === categoryId) {
												setFieldValue('selectedCategory', null);
											}
										}}
									/>
								))}
						</ListGroup>
						{provided.placeholder}
					</Div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default MenuPreviewCategories;

import styled from '@emotion/styled';
import { theme } from '../../providers/ThemeProvider/index';
import { InputPhoneNumberProps } from './InputPhoneNumber';

export const StyledDiv = styled.div<Pick<InputPhoneNumberProps, 'width' | 'error'>>`
	border: 1px solid ${({ theme }) => theme.colors.grey10};
	border-radius: 4px;
	height: 55px;
	padding: 0 0 0 16px;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.font.sansSerif};
	display: inline-block;
	width: ${({ width }) => width};

	${({ error }) =>
		error &&
		`
        border: 1px solid ${theme.colors.red};
    `}
`;

export const StyledSpan = styled.span`
	border-right: 1px solid ${({ theme }) => theme.colors.grey05};
	width: 50px;
	padding-right: 2rem;
`;

export const StyledInput = styled.input`
	font-family: inherit;
	border: none;
	width: calc(100% - 60px);
	height: 53px;
	border-left: 1px solid ${({ theme }) => theme.colors.grey10};
	box-sizing: border-box;

	:focus {
		outline: none;
		background-color: ${({ theme }) => theme.colors.grey05};
	}
`;

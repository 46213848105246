import { Box, Button, Divider, Group, Stack } from '@mantine/core';
import Icon from '../../assets/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import {
	Breadcrumbs,
	CancelButton,
	TextField,
	ViewContainer,
} from '../../components';
import { SwitchField } from '../../components/SwitchField';
import { useEditUser } from '../../hooks/api/users/useEditUser';
import { useGetUserById } from '../../hooks/api/users/userGetUserById';
import { useStopNavigateDirtyForm } from '../../hooks/useStopNavigateDirtyForm';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAuth } from '../../providers/AuthProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';
import { CreateUserSchema } from '../../schemas/user.schema';
import BrandsAndOutlets from './EditUser/BrandsAndOutlets';
import UserAccess from './EditUser/UserAccess';
import { useDisableSelectCompany } from '../../hooks/useDisableSelectCompany';
import { useModals } from 'providers/ModalsProvider';
import { DeleteUserModal } from './Modals';
import RequirePermissions from '../../guards/RequirePermissions';

const EditUser = () => {
	useDisableSelectCompany();
	const { userId } = useParams();
	const { user: userFromAuth, permissions } = useAuth();
	const { data: user, isLoading } = useGetUserById(userId!);
	const notify = useNotification();
	const navigate = useNavigate();
	const { mutate } = useEditUser();
	const modal = useModals();

	const isViewingSelf = user?.id === userFromAuth?.id;

	return (
		<Formik
			initialValues={{
				name: user?.name || '',
				email: user?.email || '',
				username: user?.username || '',
				terminal_pin: user?.terminal_pin || '',
				is_superadmin: user?.is_superadmin || false,
				is_active: user?.is_active || false,
				brand_outlet: user?.brand_outlet || {},
				companies: user?.roles?.map((e: any) => e.company_id),
				roles: user?.roles,
			}}
			validationSchema={toFormikValidationSchema(
				CreateUserSchema.omit({ send_activation_email: true })
			)}
			enableReinitialize
			onSubmit={({ is_superadmin, companies, roles, brand_outlet, ...rest }) => {
				
				//Remove empty arrays from brand_outlet, to keep the user unlinked if they do not have an outlet assigned. If is_superadmin, clean it out entirely.
				const cleaned_brand_outlet = (is_superadmin) 
					? {} 
					: Object.fromEntries(Object.entries(brand_outlet)
						.filter(([key, value]: any) => value.length > 0));

				mutate(
					{
						userId: user?.id,
						payload: {
							...rest,
							is_superadmin : is_superadmin,
							brand_outlet : cleaned_brand_outlet,
							roles: ((is_superadmin) ? [] : roles.map((e: { id: number }) => +e.id)), //Empty roles out if is_superadmin
						},
					},
					{
						onSuccess: () => {
							navigate(PermissionRoutes.Users, {}, true);
							notify.success(`Updated ${rest?.name}`);
						},
					}
				);
			}}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ errors, values, submitForm, setFieldValue, dirty, isSubmitting }) => {
				(permissions?.includes("Users.Edit"))
					&& useStopNavigateDirtyForm(dirty);

				return (
					<ViewContainer
						style={{ position: 'relative' }}
						title={
							<Group>
								<Breadcrumbs
									items={[
										{
											label: 'Users',
											onClick: () => navigate(PermissionRoutes.Users),
										},
										{
											label: user?.name,
										},
									]}
								/>
								{!isViewingSelf && permissions?.includes('Users.Delete') ? (
									<Box
										style={{
											display: 'inline-flex',
											position: 'absolute',
											right: 0,
											top: 0,
											gap: '15px',
										}}
									>
										<Button
											color="red"
											leftIcon={<Icon name="delete" />}
											onClick={() => {
												if (user.id && user.name) {
													modal.showModal({
														title: '',
														body: (
															<DeleteUserModal
																close={modal.closeModal}
																data={{ id: user?.id, name: user?.name }}
															/>
														),
													});
												}
											}}
										>
											Delete
										</Button>
									</Box>
								) : null}
							</Group>
						}
					>
						<fieldset disabled={!permissions?.includes("Users.Edit") 
							|| isSubmitting} >
							<Stack w="100%" spacing="xl" my="50px">
								<Field
									as={TextField}
									name="name"
									label="Name"
									required
									maxLength={80}
									error={errors['name'] as string}
								/>
								<Field
									as={TextField}
									name="email"
									label="Email"
									error={errors['email'] as string}
								/>
								<Field
									as={TextField}
									name="username"
									label="Username"
									required
									maxLength={80}
									error={errors['username'] as string}
								/>
								<Field
									as={TextField}
									name="terminal_pin"
									label="Terminal 8 digit PIN"
									required
									maxLength={8}
									error={errors['terminal_pin'] as string}
								/>
								<div hidden={isViewingSelf} style={{ margin: '20px 0 0px' }}>
									<SwitchField
										label="Is Active"
										required
										switchLabel={values?.is_active ? 'True' : 'False'}
										checked={values?.is_active}
										onChange={(e) =>
											setFieldValue('is_active', e.currentTarget.checked)
										}
									/>
								</div>
								{!userFromAuth?.is_superadmin ? null : (
									<div hidden={isViewingSelf} style={{ margin: '20px 0 0px' }}>
										<SwitchField
											label="Super Administrator"
											switchLabel={values?.is_superadmin ? 'True' : 'False'}
											checked={values?.is_superadmin}
											onChange={(e) =>
												setFieldValue('is_superadmin', e.currentTarget.checked)
											}
											hint="The superadmin role will be granted full access to all companies, brands and outlets."
										/>
									</div>
								)}
							</Stack>
							{isLoading ? null : !user?.is_superadmin ? (
								<>
									<Divider my="30px" />
									<UserAccess />
									<Divider my="30px" />
									<BrandsAndOutlets />
								</>
							) : null}
							<Group>
								<RequirePermissions permissions={['Users.Edit']}>
									<Button onClick={() => submitForm()}>Save changes</Button>
									<CancelButton onClick={() => navigate(PermissionRoutes.Users)}>
										Cancel
									</CancelButton>
								</RequirePermissions>
							</Group>
						</fieldset>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default EditUser;

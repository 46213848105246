import { ActionIcon, Group } from '@mantine/core';
import React from 'react';
import Icon from '../../../assets/icons';
import {
	SingleSelectCheckBoxGrid,
	Text,
	Tooltip,
	useFormikContext,
} from '../../../components';
import { TCreateRole } from '../../../schemas/roles.schema';

const TerminalPanel = () => {
	const { values, setFieldValue } = useFormikContext<TCreateRole>();
	return (
		<SingleSelectCheckBoxGrid
			initialValues={values?.permissions?.terminal}
			headers={[
				{
					label: (
						<Group spacing={0}>
							<Text size="12px" weight={500} align="center" color="gray">
								BASIC
							</Text>
							<Tooltip
								w="200px"
								multiline
								label={
									<small>
										User can access all functional features of Terminal
										except manager functions. Manager pin and password is
										mandatory for access to manager functions
									</small>
								}
							>
								<ActionIcon variant="transparent">
									<Icon name="info" />
								</ActionIcon>
							</Tooltip>
						</Group>
					),
					value: 'Basic',
				},
				{
					label: (
						<Group spacing={0}>
							<Text size="12px" weight={500} align="center" color="gray">
								FULL
							</Text>
							<Tooltip
								w="200px"
								multiline
								label={
									<small>
										User can access all functional features of Terminal
										including manager functions. Manager pin and password is
										not required for access to manager functions.
									</small>
								}
							>
								<ActionIcon variant="transparent">
									<Icon name="info" />
								</ActionIcon>
							</Tooltip>
						</Group>
					),
					value: 'Full',
				},
			]}
			rows={[{ label: 'Access', value: 'Terminal' }]}
			onChange={(values) => {
				setFieldValue('permissions.terminal', values);
			}}
		/>
	);
};

export default TerminalPanel;

import React from 'react';
import { Collapse, Radio, Title } from '@mantine/core';
import { MultiSelectField, SelectField, useFormikContext } from 'components';
import { SwitchField } from 'components/SwitchField';
import { FieldWrapper } from '../../../../components/FieldWrapper';
import { shiftDropdown, slicedTimeArray, timeDropdown } from '../Constants';

const PosOperation = () => {
	const { setFieldValue, values } = useFormikContext<any>();
	const shiftChangeOptions = slicedTimeArray(
		timeDropdown,
		values?.op_opening_time,
		values?.op_closing_time
	);
	return (
		<>
			<Title order={2}>POS Operation</Title>
			<SwitchField
				size="lg"
				label="Regular outlet or 24hr Outlet"
				onLabel="24 Hr Outlet"
				offLabel="Regular"
				checked={values?.is_24h}
				onChange={(e) => {
					setFieldValue('is_24h', e.currentTarget.checked);
					if (e.currentTarget.checked) {
						setFieldValue('op_closing_time', null);
					}
				}}
			/>
			<SelectField
				labelWidth={240}
				width={690}
				data={timeDropdown}
				label="Opening Time"
				required
				placeholder="Please Select"
				name="op_opening_time"
				value={values?.op_opening_time}
				onChange={(value) => setFieldValue('op_opening_time', value)}
			/>
			<SelectField
				labelWidth={240}
				width={690}
				data={timeDropdown}
				label="Closing Time"
				required
				disabled={values?.is_24h}
				placeholder="Please Select"
				name="op_closing_time"
				value={values?.is_24h ? '-' : values?.op_closing_time}
				onChange={(value) => setFieldValue('op_closing_time', value)}
			/>
			<SelectField
				labelWidth={240}
				width={690}
				data={['0.5', '1', '1.5', '2']}
				label="Closing Grace Period (hour)"
				required
				placeholder="Please Select"
				name="closing_grace_period_hour"
				value={values?.closing_grace_period_hour?.toString()}
				onChange={(value) => {
					if (value) {
						setFieldValue('closing_grace_period_hour', parseFloat(value));
					}
				}}
			/>
			<SelectField
				labelWidth={240}
				width={690}
				data={shiftDropdown}
				label="Number of Shifts per day"
				required
				value={String(values?.number_of_shift)}
				onChange={(value) => {
					if (value) {
						setFieldValue('number_of_shift', +value);
					}
				}}
			/>

			<Collapse in={values?.number_of_shift > 1}>
				<FieldWrapper label="Change Shift Type">
					<Radio.Group
						name="is_change_shift_mandatory"
						value={String(values?.is_change_shift_mandatory)}
						onChange={(value) => {
							if (value) {
								setFieldValue('is_change_shift_mandatory', value === 'true');
							}
						}}
					>
						<Radio value="true" label="Mandatory Change Shift" my="sm" />
						<Radio value="false" label="Non-Mandatory Change Shift" my="sm" />
					</Radio.Group>
				</FieldWrapper>

				<FieldWrapper label="Change Shift Interval">
					<Radio.Group
						name="is_equal_shift_interval"
						value={String(values?.is_equal_shift_interval)}
						onChange={(value) => {
							if (value) {
								setFieldValue('is_equal_shift_interval', value === 'true');
							}
						}}
					>
						<Radio value="true" label="Equal Interval" my="sm" />
						<Radio value="false" label="Custom Interval" my="sm" />
					</Radio.Group>
				</FieldWrapper>

				<Collapse in={!values?.is_equal_shift_interval}>
					<MultiSelectField
						width='920px'
						label="Please specify the change shift timing for each shift"
						value={values?.change_shift_intervals}
						placeholder={`Please select (${
							shiftChangeOptions?.length
						} timings) ${values?.op_opening_time} - ${
							values?.op_closing_time ?? values?.op_opening_time + ' (24 Hrs)'
						}`}
						data={shiftChangeOptions || []}
						onChange={(values) =>
							setFieldValue('change_shift_intervals', values)
						}
					/>
				</Collapse>
			</Collapse>
		</>
	);
};

export default PosOperation;

import styled from '@emotion/styled';
import { ChipProps } from './Chip';
import { transparentize } from 'polished';
import { Div } from '../Div';

export const StyledChip = styled(Div)<ChipProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 4px 12px;
	border-radius: 25px;
	width: fit-content;
	font-size: 0.75rem;
	font-weight: 500;
	color: ${({ theme, color }) =>
		color ? theme.colors[color] : theme.colors.primary};
	background-color: ${({ theme, color }) =>
		color
			? transparentize(0.9, theme.colors[color])
			: transparentize(0.9, theme.colors.primary)};

	> svg {
		height: 16px;
		width: 16px;
	}
`;

import {
	NotificationProps,
	Notifications,
	notifications,
} from '@mantine/notifications';
import React, { PropsWithChildren } from 'react';
import Icon from '../assets/icons';

const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Notifications position="bottom-center" />
			{children}
		</>
	);
};

export default NotificationProvider;

const success = (
	message: React.ReactNode,
	opts?: Omit<NotificationProps, 'message'>
) =>
	notifications.show({
		icon: <Icon name="checkcircle" style={{ color: 'green' }} />,
		message,
		styles: (theme) => ({
			root: {
				backgroundColor: '#2A2E33',
				'&::before': { backgroundColor: theme.white },
			},
			icon: {
				color: theme.white,
				background:
					'radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(128,128,128,0) 0%)',
			},
			title: { color: theme.white },
			description: { color: theme.white },
			closeButton: {
				color: theme.white,
				'&:hover': { backgroundColor: theme.colors.orange },
			},
		}),
		...opts,
	});

const error = (
	message: React.ReactNode,
	opts?: Omit<NotificationProps, 'message'>
) =>
	notifications.show({
		icon: <Icon name="cancelerror" />,
		message,
		styles: (theme) => ({
			root: {
				backgroundColor: '#2A2E33',
				'&::before': { backgroundColor: theme.white },
			},
			icon: {
				color: theme.white,
				background:
					'radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(128,128,128,0) 0%)',
			},
			title: { color: theme.white },
			description: { color: theme.white },
			closeButton: {
				color: theme.white,
				'&:hover': { backgroundColor: theme.colors.orange },
			},
		}),
		...opts,
	});

export function useNotification() {
	return {
		...notifications,
		success,
		error,
	};
}

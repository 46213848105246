import { AxiosError, isAxiosError } from 'axios';
import React from 'react';
import {
	Box,
	Button,
	CancelButton,
	DivProps,
	Field,
	Formik,
	FormMultiSelect,
	FormSwitch,
	FormTextInput,
	InputWrapper,
	Stack,
} from '../../../../components';
import { useDuplicateCateogry } from '../../../../hooks/api/categories/useDuplicateCategory';
import { useGetPos } from '../../../../hooks/api/pos/useGetPos';
import { useAppState } from '../../../../providers/AppStateProvider';
// import { useModals } from '../../../../providers/ModalsProvider';
import { useNotification } from '../../../../providers/NotificationProvider';
import { useQueryClient } from 'react-query';
import { Title } from '@mantine/core';
import { modals } from '@mantine/modals';

type DuplicateCategoryProps = DivProps & {
	currentCategoryName: string;
	currentCategoryId: string;
};

const DuplicateCategory = ({
	currentCategoryName,
	currentCategoryId,
	...rest
}: DuplicateCategoryProps) => {
	const { selectedBrand } = useAppState();
	const { data: posData } = useGetPos({ brandId: selectedBrand || 1 });
	// const { closeModal } = useModals();
	const { mutate } = useDuplicateCateogry();
	const notify = useNotification();
	const queryClient = useQueryClient();

	return (
		<Formik
			initialValues={{
				new_category_name: currentCategoryName,
				is_copy_description: false,
				pos_list: [],
			}}
			onSubmit={(values) =>
				mutate(
					{ categoryId: currentCategoryId, payload: values },
					{
						onSuccess: () => {
							notify.success('Category has been\nduplicated successfully!');
							modals.closeAll();
							queryClient.invalidateQueries('categories');
						},
						onError: (err) => {
							if (isAxiosError(err)) {
								notify.error(err.response?.data.error);
							}
						},
					}
				)
			}
		>
			{({ values, setFieldValue, submitForm }) => {
				return (
					<Box p="lg">
						<Title>Copy category to...</Title>
						<Stack
							direction="column"
							gap={20}
							width="100%"
							css={{ padding: '40px 0px' }}
						>
							<Field
								as={FormTextInput}
								width={'660px'}
								label="Category name"
								name="new_category_name"
								isRequired
							/>

							<Field
								as={FormSwitch}
								width={'660px'}
								label="Copy Description"
								name="is_copy_description"
								onChange={(value: boolean) =>
									setFieldValue('is_copy_description', value)
								}
							/>

							<FormMultiSelect
								label="Assign to"
								placeholder="Please Select"
								width={'660px'}
								options={
									posData?.pos?.map((e: any) => ({
										value: e.id,
										label: e.name,
									})) || []
								}
								onChange={(options) => {
									const ids = options.map((e) => e.value);
									setFieldValue('pos_list', ids);
								}}
							/>
						</Stack>
						<Stack width="100%" align="center" justify="right">
							<CancelButton onClick={() => modals.closeAll()}>
								Cancel
							</CancelButton>
							<Button onClick={() => submitForm()}>Create Categories</Button>
						</Stack>
					</Box>
				);
			}}
		</Formik>
	);
};

export default DuplicateCategory;

import { authApi } from '../../apis';

export type ILoginCredentials = {
	username: string;
	password: string;
};

export async function _login(credentials: ILoginCredentials) {
	return await authApi.post('/auth/login/dashboard', credentials);
}

export async function _getAccessToken(payload?: { company_id: string }) {
	const { data } = await authApi.post('/auth/token/dashboard', payload);
	return data;
}

export async function _getUserInfo(userId: number) {
	const { data } = await authApi.get(`/users/${userId}`);
	return data;
}

export async function _getPermissions(userId: number) {
	const { data } = await authApi.get(`/auth/token/permissions/${userId}`);
	return data;
}

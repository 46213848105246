import { useMutation, UseMutationOptions } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useEditProduct(
	opts?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn'>
) {
	const { getAccessToken } = useAuth();
	return useMutation(
		async (values: any) =>
	{const {id, ...rest} = values
		configApi
			.put(`/products/${id}`, rest, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	},opts
	);
}

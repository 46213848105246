/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	Button,
	Group,
	JsonInput,
	Text,
} from '@mantine/core';
import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
	BcItemSelectItem,
	Breadcrumbs,
	DateField,
	Divider,
	Field,
	Formik,
	Stack,
	ImageUploader,
	LastUpdatedLabel,
	MultiSelectField,
	PaginationSimple,
	SearchableDropdownField,
	SelectBrand,
	SelectField,
	FormTextInput,
	FormPriceInput,
	useFormikContext,
	ViewContainer,
	CancelButton,
} from '../../components';
import { SwitchField } from '../../components/SwitchField';
import { useGetCategoryById, useGetCategoryGrabStatus, useGetOptionGroups } from '../../hooks';
import { useGetKitchenGroups } from '../../hooks/kitchengroups';
import { useGetProductById } from '../../hooks/api/products/useGetProductById';
import { useGetBcItems } from '../../hooks/bcitems';
import { useGetProductDiscounts } from 'hooks/discounts';
import { currency } from '../../lib';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { ProductRoutes } from '../../router/constants/Products.routes';
import { BcItem } from '../../schemas/bc_item.schema';
import {
	CreateProductWithBcIntegrationSchema,
	EditProductsSchema,
} from '../../schemas/product.schema';
import LocalisedPricing from './EditProduct/LocalisedPricing';
import Icon from '../../assets/icons';
import { modals } from '@mantine/modals';
import DeleteProduct from './Categories/modals/DeleteProduct';
import { configApi } from '../../apis';
import { useAuth } from '../../providers/AuthProvider';
import { useEditProduct } from 'hooks/api/products/useEditProduct';
import { useDisableSelectCompany } from '../../hooks/useDisableSelectCompany';
import { ProductGrabIntegration } from './ProductGrabIntegration';
import RequirePermissions from '../../guards/RequirePermissions';
import { useStopNavigateDirtyForm } from '../../hooks/useStopNavigateDirtyForm';

const _EditProduct = ({
	updated_by,
	updated_at,
}: {
	updated_by: string;
	updated_at: Date;
}) => {
	useDisableSelectCompany();

	const { permissions } = useAuth();
	const {
		values,
		errors,
		setFieldValue,
		dirty,
		submitForm,
		touched,
		isSubmitting,
	} = useFormikContext<any>();

	permissions?.includes('Categories.Edit') && useStopNavigateDirtyForm(dirty);

	const { isCompanyBCIntegrated, selectedBrand } = useAppState();
	const [searchParams] = useSearchParams();
	const notify = useNotification();
	const { pathname } = useLocation();
	const { data: category } = useGetCategoryById(values.category_id);
	const { data: kitchenGroupsData } = useGetKitchenGroups(selectedBrand!);
	const { data: optionGroupsData, isFetching: isFethingOptionGroups } =
		useGetOptionGroups(category?.brand_id);
	const { data: response_discounts } = useGetProductDiscounts(selectedBrand!);
	const { getAccessToken } = useAuth();

	const links = category?.products?.map(
		(e: any) => `/products/categories/edit/${e.category_id}/${e.id}`
	);

	const [bcSearchTerm, setBcSearchTerm] = React.useState<string>('');
	const [bcSearchResult, setBcSearchResult] = React.useState<BcItem[]>([]);

	const { data: bcItemData } = useGetBcItems(bcSearchTerm);

	const navigate = useNavigate();

	React.useEffect(() => {
		setBcSearchResult(
			bcItemData || [{ item_code: values?.bc_code, item_name: values?.bc_name }]
		);
	}, [bcItemData, values?.bc_code]);

	const { productId } = useParams<{
		categoryId: string;
		productId: string;
	}>();

	const { mutate: deleteProduct } = useMutation(
		async ({ productId }: { productId: string | number }) =>
			configApi
				.delete(`/products/${productId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Group w="100%" position="apart">
					<Breadcrumbs
							items={[
								{
									label: 'Categories',
									onClick: () => navigate(ProductRoutes.Categories),
								},
								{
									label: category?.name || '',
									onClick: () => navigate(ProductRoutes.Categories),
								},
								{ label: values?.name },
							]}
						/>
					<RequirePermissions permissions={['Categories.Delete']}>
						<Button
							leftIcon={<Icon name="delete" />}
							color="red"
							onClick={() =>
								modals.openConfirmModal({
									size: 'xl',
									labels: {
										confirm: 'Yes, Delete',
										cancel: 'No, Cancel',
									},
									confirmProps: {
										leftIcon: <Icon name="delete" />,
										color: 'red',
									},
									onConfirm: () => {
										if (productId) {
											deleteProduct(
												{ productId },
												{
													onSuccess: () => {
														navigate(ProductRoutes.Categories);
														notify.success(
															`Product has been successfully deleted`
														);
													},
												}
											);
										}
									},
									children: (
										<DeleteProduct
											productId={productId}
											productName={values?.name}
										/>
									),
								})
							}
						>
							Delete
						</Button>
					</RequirePermissions>
				</Group>
				<Group w="100%" position="apart" my="xl">
					<SelectBrand isDisabled />
					<PaginationSimple
						links={links || []}
						activeIndex={links?.indexOf(pathname) ?? 0}
					/>
					<LastUpdatedLabel
						style={{
							width:"210px"
						}}
						lastUpdated={updated_at ?? new Date()}
						editor={updated_by ?? ''}
					/>
				</Group>
				<Divider/>
			</Box>
			<fieldset disabled={!permissions?.includes("Categories.Edit") 
				|| isSubmitting} >
			<Box style={{ width: '100%' }}>
					<Stack direction="row" gap={30}>
						{searchParams.get('debug') === 'true' ? (
							<JsonInput
								my="md"
								maxRows={15}
								autosize
								value={JSON.stringify({ values, errors, touched }, null, 2)}
							/>
						) : null}

						<Stack direction="column" gap={10}>
							<Field
							width="600px"
								as={FormTextInput}
								label="Product Name"
								isRequired={true}
								name="name"
								maxLength={80}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									setFieldValue('name', e.currentTarget.value);
								}}
								error={
									touched['name'] &&
									(errors['name'] as string) &&
									'This field is required.'
								}
							/>
							<Field
							width="600px"
								as={FormTextInput}
								label="Chinese Name"
								name="name_other_languages.zh"
								maxLength={40}
							/>
							<Field
							width="600px"
								as={FormTextInput}
								label="Malay Name"
								name="name_other_languages.ms"
								maxLength={80}
							/>

						<Field
							width="600px"
							as={FormTextInput}
							label="Category"
							name="category_id"
							value={category?.name}
							onChange={() => {
								return;
							}}
							disabled
						/>
						<SwitchField
							width="600px"
							required
							label="Make this product open price"
							name="is_open_price"
							size="lg"
							onLabel="True"
							offLabel="False"
							checked={values?.is_open_price}
							onChange={(e:any) => {
								setFieldValue('is_open_price', e.currentTarget.checked);
							}}
						/>
						<FormPriceInput
							width="600px"
							placeholder="Example 0.00"
							precision={2}
							required={true}
							step={0.1}
							hidden={values?.is_open_price}
							value={parseFloat(values?.price) ?? 0.0}
							disabled={values?.is_open_price ?? false}
							min={0.01}
							onChange={(value: any) => {
								if (value === '') {
									value = 0;
								}
								setFieldValue('price', value);
							}}
							label="Default Selling Price ($)"
							name="price"
						/>
						<Field 
							width="600px" 
							as={FormTextInput} 
							label="Display Label" 
							name="display_label" 
						/>
						{isCompanyBCIntegrated && (
							<SearchableDropdownField
								label="BC Item Code"
								name="bc_code"
								placeholder="Search Item Code or Item Name"
								required
								data={
									bcSearchResult?.map((e: BcItem) => ({
										name: e?.item_name,
										label: e?.item_code,
										value: `${e?.item_code},${e?.item_name}`,
									})) || []
								}
								value={`${values?.bc_code},${values?.bc_name}`}
								searchable={true}
								itemComponent={BcItemSelectItem}
								onChange={(selectedItem) => {
									const itemDetail = selectedItem?.split(',');
									setFieldValue('bc_code', itemDetail?.at(0));
									setFieldValue('bc_name', itemDetail?.at(1));
								}}
								onSearchChange={(query) => {
									setBcSearchTerm(query);
								}}
								filter={(value, item) =>
									item?.name
										?.toLowerCase()
										?.includes(value?.toLowerCase()?.trim()) ||
									item?.value
										?.toLowerCase()
										?.includes(value?.toLowerCase()?.trim())
								}
								error={
									((touched['bc_code'] && (errors['bc_code'] as string)) ||
										(touched['bc_name'] && (errors['bc_name'] as string))) &&
									'This field is required.'
								}
							/>
						)}
						<DateField
							width="600px"
							label="Expiry Date"
							name="end_date"
							onChange={(date) => {
								if (date) {
									setFieldValue('end_date', date?.toISOString());
								} else {
									setFieldValue('end_date', null);
								}
							}}
							valueFormat="DD/MM/YYYY"
							value={values['end_date'] ? new Date(values['end_date']) : null}
						/>
						<MultiSelectField
							width="600px"
							label="Option Groups"
							disabled={values?.is_open_price}
							data={
								optionGroupsData?.option_groups?.map((e) => ({
									value: e.id.toString(),
									label: e.name,
								})) || []
							}
							defaultValue={values?.option_groups?.map((e: number) =>
								String(e)
							)}
							value={values?.option_groups?.map((e: number) => String(e))}
							onChange={(selectedItems) => {
								setFieldValue(
									'option_groups',
									selectedItems.map((e) => +e)
								);
							}}
						/>
						<SelectField
							width={450}
							name="kitchen_group_id"
							label="Kitchen Groups"
							labelWidth={150}
							data={
								kitchenGroupsData?.kitchen_groups?.map((e: any) => ({
									value: e.id.toString(),
									label: e.name,
								})) || []
							}
							clearable
							value={String(values?.kitchen_group_id)}
							onChange={(value) =>
								setFieldValue('kitchen_group_id', value ? +value : null)
							}
						/>
						<MultiSelectField
							width="600px"
							label="Product Discounts"
							disabled={values?.is_open_price}
							data={
								response_discounts?.discounts?.map((e: any) => ({
									value: e.id.toString(),
									label: e.name,
								})) || []
							}
							defaultValue={values?.discount_ids?.map((e: number) => String(e))}
							value={values?.discount_ids?.map((e: number) => String(e))}
							onChange={(selectedItems) => {
								setFieldValue(
									'discount_ids',
									selectedItems.map((e) => +e)
								);
							}}
						/>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<LocalisedPricing />
						</Stack>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<ProductGrabIntegration
								hidden={!values?.is_grab_category}
								optionGroups={optionGroupsData?.option_groups}
							/>
						</Stack>
						<Stack 
							width="100%"
							style={{display:"flex", 
								justifyContent:"flex-start", 
								marginTop:"15px"}}
							>
							<RequirePermissions permissions={['Categories.Edit']}>
								<Button
									onClick={() => {
										submitForm();
									}}
								>
									Save changes
								</Button>
							</RequirePermissions>
							<CancelButton
								onClick={() => navigate(ProductRoutes.Categories)}
							>
								Cancel
							</CancelButton>
						</Stack>
					</Stack>
					<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
						<ImageUploader
							url={values['image_url']}
							onChange={(url) => setFieldValue('image_url', url)}
							inactiveTabs={[
								'Category Groups',
								'Payment Methods',
								'Brands',
								'Discounts',
								'Advertisements',
							]}
						/>
						<Text align="left" fs="italic" size="xs" color="gray" >
							Recommended Dimensions: W 300px x H 216px
						</Text>
					</Stack>
				</Stack>
			</Box>
			</fieldset>
		</ViewContainer>
	);
};

const EditProduct = () => {
	const { user } = useAuth();
	const { isCompanyBCIntegrated } = useAppState();
	const navigate = useNavigate();
	const notify = useNotification();
	const { mutate } = useEditProduct({
		onSuccess: () => {
			navigate(ProductRoutes.Categories, {}, true);
			notify.success(
				<p>
					Product has been <strong>successfully</strong> edited
				</p>
			);
		},
	});

	const { categoryId, productId } = useParams<{
		categoryId: string;
		productId: string;
	}>();
	const { data: product } = useGetProductById(productId);
	const { state } = useLocation();

	const {
		brand_id,
		id,
		sort_order,
		created_at,
		updated_at,
		option_groups,
		grab_option_groups,
		localized_price,
		...restOfProduct
	} = product || {};

	const convertedLocalizedPrice = localized_price?.map((e: any) => ({
		pos_id: e.pos_id,
		price: currency(e.price, {
			fromCents: true,
		}).value,
		is_hidden: e.is_hidden,
	}));

	const { data: category } = useGetCategoryGrabStatus(+categoryId!);

	return (
		<Formik
			initialValues={{
				name: '',
				name_other_languages: {
					ms: '',
					zh: '',
				},
				display_label: '',
				image_url: '',
				end_date: null,
				is_kitchen_item: false,
				category_id: +state?.categoryId ?? 0,
				is_grab_category: category?.is_grab_category,
				// brand_id: selectedBrand,
				kitchen_group_id: null,
				option_groups: option_groups?.map((e: any) => e.id),
				localized_price: convertedLocalizedPrice || [],
				discount_ids: [],
				...restOfProduct,
				price: currency(restOfProduct?.price, {
					fromCents: true,
				}).value,
				grab_price: currency(restOfProduct?.grab_price, {
					fromCents: true,
				}).value,
				grab_option_groups: grab_option_groups?.map((e: any) => e.id),
				updated_by: user?.name ?? '',
			}}
			onSubmit={(values) => {
				const {
					price,
					grab_price,
					is_grab_category,
					localized_price,
					is_deleted,
					...rest
				} = values;
				const priceInCents = currency(price).intValue;
				const grabPriceInCents = currency(grab_price).intValue;
				const localizedPriceInCents = localized_price.map((e: any) => ({
					pos_id: e.pos_id,
					price: currency(e.price).intValue,
					is_hidden: e.is_hidden,
				}));

				if (productId) {
					mutate({
						...rest,
						id: productId,
						price: priceInCents,
						localized_price: localizedPriceInCents,
						grab_price: grabPriceInCents,
					});
				}
			}}
			validationSchema={toFormikValidationSchema(
				isCompanyBCIntegrated
					? CreateProductWithBcIntegrationSchema
					: EditProductsSchema
			)}
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
		>
			<_EditProduct
				updated_by={restOfProduct?.updated_by ?? ''}
				updated_at={updated_at ?? new Date()}
			/>
		</Formik>
	);
};

export default EditProduct;

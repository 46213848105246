import { Button, Grid, Group, Select, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { ColumnFiltersState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React from 'react';
import Icon from '../../assets/icons';
import { Breadcrumbs, Chip, ViewContainer } from '../../components';
import { SimpleDataGrid } from '../../components/SimpleDataGrid';
import { useGetUsers } from '../../hooks/api/users/useGetUsers';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { PermissionRoutes } from '../../router/constants/Permissions.routes';
import RequirePermissions from '../../guards/RequirePermissions';

const Users = () => {
	const { selectedCompany, isDirty } = useAppState();
	const [search, setSearch] = React.useState<string>('');

	const [searchKeyword] = useDebouncedValue(search, 500);
	const [activeFilter, setActiveFilter] = React.useState<ColumnFiltersState>(
		[]
	);

	const { data: usersData, isLoading } = useGetUsers({
		companyId: selectedCompany!,
	});

	const navigate = useNavigate();
	return (
		<ViewContainer
			title={
				<Group w="100%" position="apart">
					<Breadcrumbs
						items={[
							{
								label: 'Users',
								onClick: () => {
									return;
								},
							},
						]}
					/>
					<RequirePermissions permissions={['Users.Create']}>
						<Button
							leftIcon={<Icon name="add" />}
							onClick={() => navigate(PermissionRoutes.CreateUser)}
						>
							Create User
						</Button>
					</RequirePermissions>
				</Group>
			}
		>
			<Grid my="xl">
				<Grid.Col md={12} lg={10}>
					<TextInput
						value={search}
						placeholder="Search by name, username, or email"
						onChange={(e) => setSearch(e.currentTarget.value)}
					/>
				</Grid.Col>
				<Grid.Col md={12} lg={2}>
					<Select
						defaultValue="[]"
						data={[
							{ label: 'All Status', value: '[]' },
							{ label: 'Active', value: '[{"id":"is_active","value":true}]' },
							{
								label: 'Inactive',
								value: '[{"id":"is_active","value":false}]',
							},
						]}
						onChange={(value) => {
							if (value) {
								setActiveFilter(JSON.parse(value));
							}
						}}
					/>
				</Grid.Col>
			</Grid>
			<SimpleDataGrid
				columnFilters={activeFilter}
				globalFilter={searchKeyword}
				data={usersData?.users}
				columnVisibility={{
					id: false,
					email: false,
				}}
				onClickHandler={(row) => {
					navigate(
						PermissionRoutes.EditUser.replace(':userId', row?.original?.id)
					);
				}}
				columns={[
					{ accessorKey: 'id', enableGlobalFilter: false },
					{ accessorKey: 'email' },
					{
						accessorKey: 'name',
						header: 'Name',
					},
					{ accessorKey: 'username', header: 'Username' },
					{
						accessorKey: 'last_login_at',
						header: 'Last Login',
						enableGlobalFilter: false,
						cell: ({ getValue }) => {
							const datetime = getValue() as string;

							return !datetime
								? '-'
								: dayjs(datetime).format('DD/MM/YYYY hh:mm:ssA');
						},
					},
					{
						accessorKey: 'is_active',
						header: 'Status',
						enableGlobalFilter: false,
						cell: ({ getValue }) => {
							const isActive = getValue() as boolean;
							return isActive ? (
								<Chip color="green" leftIcon={<Icon name="checkmark" />}>
									Active
								</Chip>
							) : (
								<Chip color="red" leftIcon={<Icon name="cancel" />}>
									Inactive
								</Chip>
							);
						},
					},
				]}
			/>
		</ViewContainer>
	);
};

export default Users;

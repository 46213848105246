/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { ListGroup } from '../ListGroup';
import { ListGroupItemProps } from '../ListGroup/ListGroupItem';
import { RadioListGroupItem } from './RadioListGroupItem';
import {
	SelectableListGroupItem,
	SelectableListGroupItemProps,
} from './SelectableListGroupItem';
import { useListState } from './use-list-state';

type TOption = {
	value: string;
	label: string;
	isSelected?: boolean;
};

export type SelectableListGroupProps = ListGroupItemProps & {
	options: TOption[];
	onSelectionChange?: (options: TOption[]) => void;
	type?: 'checkbox' | 'radio';
	name?: string;
	isBordered?: boolean;
	showSelectAll?: boolean;
	listItemProps?: ( index?:number ) =>Omit<
		SelectableListGroupItemProps,
		'option' | 'label' | 'addSelectedItem' | 'removeSelectedItem' | 'checked' | 'rightSection'
	>;
	initialValues?: (options: TOption[]) => TOption[];
};

export type ISelectableListGroup =
	React.ForwardRefExoticComponent<SelectableListGroupProps> & {
		Item: typeof SelectableListGroupItem;
	};

export const SelectableListGroup = React.forwardRef<
	HTMLDivElement,
	SelectableListGroupProps
>(
	(
		{
			options,
			onSelectionChange,
			type = 'checkbox',
			name,
			isBordered,
			showSelectAll,
			css,
			listItemProps,
			initialValues,
			...rest
		},
		ref
	) => {
		const [selected, { append, remove, setState }] = useListState<TOption>();

		React.useEffect(() => {
			if (onSelectionChange) {
				onSelectionChange(selected);
			}
		}, [selected]);

		React.useEffect(() => {
			if (initialValues) {
				setState(initialValues(options));
			}
		}, []);

		return (
			<ListGroup
				css={(theme) => ({
					border: isBordered ? `1px solid ${theme.colors.grey10}` : 'none',
					...css,
				})}
				{...rest}
				ref={ref}
			>
				{type == 'checkbox' && showSelectAll ?
					<SelectableListGroupItem
						option={{
							value: '',
							label: ''
						}}
						label={'Select All'}
						addSelectedItem={() => {
							setState(options);
						}}
						removeSelectedItem={() => {
							setState([]);
						}}
						{...listItemProps} /> : null
				}
				{type === 'checkbox'
					?
					options?.map((e, index) => (
						<SelectableListGroupItem
							key={e.value}
							label={e.label}
							option={e}
							checked={selected.findIndex((s) => s.value === e.value) > -1}
							addSelectedItem={append}
							removeSelectedItem={() => {
								const indexOf = selected.findIndex(
									(s) => s.value === e.value
								);
								remove(indexOf);
							}}
							{...listItemProps?.(index)}
						/>
					))
					: options?.map((option) => (
						<RadioListGroupItem
							key={option.value}
							label={option.label}
							name={name || ''}
							checked={
								selected.findIndex((e) => e.value === option.value) > -1
							}
							onChange={(e) => {
								if (e.currentTarget.checked) {
									setState([option]);
								}
							}}
							{...listItemProps?.()}
						/>
					))}
			</ListGroup>
		);
	}
) as ISelectableListGroup;

SelectableListGroup.displayName = 'SelectableListGroup';
SelectableListGroup.Item = SelectableListGroupItem;

import { useMutation, useQueryClient } from 'react-query';
import { UserInfo } from '../../../views/User/AccountSettings';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export const useEditUser = () => {
	const queryClient = useQueryClient();
	const { getAccessToken } = useAuth();
	const editUser = async ({
		updatedUserInfo,
		userId,
	}: {
		updatedUserInfo: UserInfo;
		userId: number | null;
	}) => {
		const res = await authApi.put(`/users/${userId}`, updatedUserInfo, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return res.data;
	};
	return useMutation(editUser, {
		onSuccess: () => {
			queryClient.invalidateQueries('userId');
		},
	});
};

import {
	BoxProps,
	Center,
	Group,
	Image,
	Text,
	UnstyledButton,
} from '@mantine/core';
import React from 'react';
import Icon from '../../assets/icons';
import { modals } from '@mantine/modals';
import { FileManager, FileManagerProps } from './FileManager';

export type ImageUploaderProps = {
	onChange: (selected: string) => void;
	url?: string;
	inactiveTabs?: FileManagerProps['inactiveTabs'];
} & Omit<BoxProps, 'children'>;

export const ImageUploader = React.forwardRef<
	HTMLDivElement,
	ImageUploaderProps
>(({ url = "", inactiveTabs, onChange, ...rest }, ref) => {
	

	return (
		<>
			<UnstyledButton
				h="250px"
				w="300px"
				sx={(theme) => ({
					background: theme.colors.gray[1],
					border: `1px solid ${theme.colors.gray[3]}`,
					borderRadius: '4px',
				})}
				{...rest}
				onClick={() =>
					modals.openConfirmModal({
						size: '1000px',
						children: (
							<FileManager url={url}
								onChange={onChange}
								inactiveTabs={inactiveTabs} />
						),
						withCloseButton: false,
						closeOnClickOutside: false,
						centered: true,
						padding: 0,
						groupProps: {
							m: 'md',
						},
						cancelProps: {
							style: {
								backgroundColor: '#dee2e6',
								color: '#2a2e33',
								borderStyle: 'none',
							},
						},
						confirmProps: {
							style: {
								display:'none',
							},
						},
						labels: {
							confirm: 'Confirm',
							cancel: 'Close',
						},
					})
				}
			>
				<Center>
					<Group>
						{url ? (
							<Image
								fit="contain"
								height={250}
								width={300}
								src={url}
								withPlaceholder
								alt={url}
							/>
						) : (
							<>
								<Icon name="upload" />
								<Text>Upload or select image</Text>
							</>
						)}
					</Group>
				</Center>
			</UnstyledButton>

		</>
	);
});

ImageUploader.displayName = 'ImageUploader';

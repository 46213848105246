import React from 'react';
import styled from '@emotion/styled';
import {
	Breadcrumbs,
	Button,
	Div,
	FormTextInput,
	FormSelectInput,
	LastUpdatedLabel,
	Stack,
	Tabs,
	Text,
	ViewContainer,
	CancelButton,
} from 'components';
import { Image } from '@mantine/core';
import { Field, Formik } from 'formik';
import { useGetOneKitchenGroup, useCheckLinks } from 'hooks/kitchengroups';
import { useParams } from 'providers/RouterProvider';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useModals } from 'providers/ModalsProvider';
import { useUpdateOneKitchenGroup } from 'hooks/kitchengroups';
import { KitchenGroupSchemaUpdate } from 'schemas/kitchengroup.schema';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteEntityModal, LinkedEntityModal } from './Modals';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const KitchenGroupView = () => {
	useDisableSelectCompany();
	const { kitchenGroupId } = useParams();
	const { data: kitchenGroup, isFetching: isFetchingKitchenGroup } =
		useGetOneKitchenGroup(parseInt(kitchenGroupId!));
	const { mutateAsync: updateKitchenGroup } = useUpdateOneKitchenGroup();
	const navigate = useNavigate();
	const toast = useNotification();
	const modal = useModals();
	const { permissions } = useAuth();
	

	const { refetch: checkLinks } = useCheckLinks(Number(kitchenGroupId));

	const onDeleteButtonClickHandler = async () => {
		await checkLinks().then((e) => {
			const data = e.data.data;
			if (data?.pos?.length > 0) {
				const _data = { ...data, action: 'deleting' };

				modal.showModal({
					title: '',
					body: <LinkedEntityModal close={modal.closeModal} data={_data} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteEntityModal
							close={modal.closeModal}
							data={{
								id: Number(kitchenGroupId),
								name: kitchenGroup.name,
							}}
						/>
					),
				});
			}
		});
	};

	const ProductsList = () => {
		const products = kitchenGroup.products;

		return (
			<>
				{products?.map((product: any, idx: number) => {
					return (
						<Div
							style={{
								width: '1024px',
								borderBottom: '1px solid #E8EAED',
								padding: '25px 68px',
							}}
							key={`products=${idx}`}
							onClick={() =>
								navigate(
									`/products/categories/edit/${product.category_id}/${product.product_id}`
								)
							}
						>
							<Div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Stack direction="row">
									<Stack>
										<Div style={{ width: '150px', paddingRight: '10px' }}>
											<Text truncate>{product.display_label}</Text>
										</Div>
										<Image
											src={product.image_url}
											width="51px"
											height="38px"
											fit="contain"
											style={{ borderRadius: '4px' }}
											withPlaceholder
										/>
										<Div style={{ minHeight: '38px' }}>
											<Text
												style={{
													fontSize: '16px',
													fontWeight: '600',
													display: 'inline-flex',
													verticalAlign: 'top',
												}}
											>
												{product.product_name}
											</Text>
											<Text style={{ fontSize: '14px', color: '#5F6368' }}>
												{product.option_groups.join(', ') ?? ''}
											</Text>
										</Div>
									</Stack>
								</Stack>

								<Text style={{ color: '#5f6368' }}>{product.pos_name}</Text>
							</Div>
							{/* <pre>{JSON.stringify(product.option_groups, null, 2)}</pre> */}
						</Div>
					);
				})}
			</>
		);
	};

	const PrintersList = () => {
		const printers = kitchenGroup.printers;
		return (
			<>
				{printers?.map((e: any, idx: number) => {
					return (
						<Div
							style={{
								width: '1024px',
								borderBottom: '1px solid #E8EAED',
								padding: '25px 68px',
							}}
							key={`printer-${idx}`}
						>
							<Div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Text>{e.printer_name}</Text>
								<Text style={{ color: '#5f6368' }}>{e?.pos_name}</Text>
							</Div>
						</Div>
					);
				})}
			</>
		);
	};

	return (
		<ViewContainer>
			{!isFetchingKitchenGroup && (
				<>
					<Div
						style={{
							width: '100%',
							position: 'relative',
							left: 0,
							paddingBottom: '10px',
						}}
					>
						<Breadcrumbs
							items={[
								{
									label: 'Kitchen Groups',
									onClick: () => navigate('/products/kitchengroups'),
								},
								{
									label: kitchenGroup?.name ?? '',
								},
							]}
						/>
						<RequirePermissions permissions={['KitchenGroups.Delete']}>
							<Button
								color="red"
								style={{ position: 'absolute', right: 0, top: 0 }}
								leftIcon={<DeleteIcon />}
								type="button"
								onClick={onDeleteButtonClickHandler}
							>
								Delete
							</Button>
						</RequirePermissions>

					</Div>
					<Stack direction="column">
						<Div
							style={{
								display: 'flex',
								alignSelf: 'flex-start',
								position: 'relative',
								paddingBottom: '10px',
							}}
						>
							<FormSelectInput
								isDisabled
								width="192px"
								defaultOption={{
									value: kitchenGroup?.brand_id,
									label: kitchenGroup?.brand_name,
								}}
								options={[
									{
										value: kitchenGroup?.brand_id ?? '',
										label: kitchenGroup?.brand_name ?? '',
									},
								]}
							/>
						</Div>

						<Div style={{ width: '100%' }}>
							<Tabs defaultValue={'settings'}>
								<Tabs.List>
									<Tabs.Tab value="settings">Settings</Tabs.Tab>
									<Tabs.Tab value="products">Products</Tabs.Tab>
									<Tabs.Tab value="printers">Printers</Tabs.Tab>
								</Tabs.List>
								<Tabs.Panel value="settings">
									<Formik
										initialValues={kitchenGroup}
										validate={(values) => {
											try {
												KitchenGroupSchemaUpdate.parse(values);
											} catch (error: any) {
												return error.formErrors.fieldErrors;
											}
										}}
										onSubmit={(values, actions) => {
											updateKitchenGroup(values, {
												onSuccess: (_: any) => {
													navigate('/products/kitchengroups', {}, true);
													toast.success('Kitchen Group successfully updated');
												},
												onError: (err: any) => {
													toast.error(
														err?.response?.data?.error ?? 'An error occured'
													);
												},
											});

											actions.setSubmitting(false);
										}}
									>
										{({
											values,
											errors,
											touched,
											dirty,
											setFieldValue,
											submitForm,
											isSubmitting
										}) => {

											(permissions?.includes("KitchenGroups.Edit")) 
												&& useStopNavigateDirtyForm(dirty)

											return (
												<Div style={{ marginTop: '20px' }}>
													{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
													{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
													<fieldset disabled={!permissions?.includes("KitchenGroups.Edit") 
														|| isSubmitting} >
														<Stack direction="row">
															<Stack
																direction="column"
																gap={10}
																style={{ marginTop: '20px' }}
															>
																<Field
																	width="611px"
																	as={FormTextInput}
																	name="name"
																	label="Kitchen Group Name"
																	isRequired={true}
																	maxLength={80}
																	error={
																		errors.name && touched.name
																			? errors.name
																			: null
																	}
																/>
																<Stack
																	width={'100%'}
																	style={{
																		display: 'flex',
																		justifyContent: 'flex-start',
																		marginTop: '20px',
																	}}
																>
																	<RequirePermissions permissions={['KitchenGroups.Edit']}>
																		<Button type="submit" onClick={submitForm}>
																			Save Changes
																		</Button>
																	
																		<CancelButton
																			onClick={() =>
																				navigate('/products/kitchengroups')
																			}
																		>
																			Cancel
																		</CancelButton>
																	</RequirePermissions>
																</Stack>
															</Stack>

															<Div
																style={{
																	alignSelf: 'flex-start',
																	marginLeft: '100px',
																}}
															>
																<LastUpdatedLabel
																	lastUpdated={values?.updated_at ?? new Date()}
																	editor={values?.updated_by ?? ''}
																/>
															</Div>
														</Stack>
													</fieldset>
												</Div>
											);
										}}
									</Formik>
								</Tabs.Panel>
								<Tabs.Panel value="products">
									<ProductsList />
								</Tabs.Panel>
								<Tabs.Panel value="printers">
									<PrintersList />
								</Tabs.Panel>
							</Tabs>
						</Div>
					</Stack>
				</>
			)}
		</ViewContainer>
	);
};

export default KitchenGroupView;

import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useNavigate } from '../../../lib/navigate';
import { useAuth } from '../../../providers/AuthProvider';
import { useNotification } from '../../../providers/NotificationProvider';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';

export function useUnpairPos() {
	const notify = useNotification();
	const navigate = useNavigate();
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({ posId }: { posId: number | string }) =>
			configApi
				.post(`/pos/${posId}/unpair/dashboard`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: (data, { posId }) => {
				navigate(ConfigurationRoutes.Pos, {}, true);
				if (data.notify) {
					notify.show({ message: data?.notify });
				}
			},
		}
	);
}

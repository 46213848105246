import React from 'react';

type Props = {
	discountName: string;
};

const Discount = ({ discountName }: Props) => {

	return <p style={{ textAlign: 'left' }}>{discountName}</p> 
};

export default Discount;

import { useMutation } from 'react-query';
import { authApi } from '../../../apis';
import { useNavigate } from '../../../lib/navigate';
import { useAuth } from '../../../providers/AuthProvider';
import { useNotification } from '../../../providers/NotificationProvider';
import { PermissionRoutes } from '../../../router/constants/Permissions.routes';

export function useDeleteRoleById() {
	const notify = useNotification();
	const navigate = useNavigate();
	const { getAccessToken } = useAuth();

	return useMutation(
		async ({
			id,
			reassignedRoleId,
		}: {
			id: string | number;
			reassignedRoleId?: string | number;
		}) =>
			authApi
				.delete(`/roles/${id}`, {
					data: {
						reassigned_id: reassignedRoleId,
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: (data) => {
				if (data?.success === 'true') {
					notify.success(`Delete role ${data?.deleted?.name}`);
					navigate(PermissionRoutes.Roles, {}, true);
				}
			},
		}
	);
}

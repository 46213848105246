import { z } from 'zod';

export const zodDateStringSchema = z.preprocess((arg) => {
	if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
}, z.date());

export const BaseSchema = z.object({
	id: z.number().int(),
	created_at: zodDateStringSchema,
	updated_at: zodDateStringSchema,
});

import React from 'react';
import { Grid, Group, Text } from '@mantine/core';
import {
	Box,
	Breadcrumbs,
	Button,
	CancelButton,
	FormTextInput,
	ImageUploader,
	MultiSelectField,
	Stack,
	TextField,
	ViewContainer,
} from 'components';
import { Field, Formik } from 'formik';
import { useGetBrands } from 'hooks/brands';
import { useCreateNewPaymentMethod } from 'hooks/payments';
import { useAppState } from 'providers/AppStateProvider';
import { useAuth } from 'providers/AuthProvider';
import { useNotification } from 'providers/NotificationProvider';
import {
	PaymentMethodSchemaCreate,
	PaymentMethodSchemaCreateWithBC,
} from 'schemas/payment_method.schema';
import { SwitchField } from '../../../components/SwitchField';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useNavigate } from '../../../lib/navigate';

type BrandType = {
	id: number;
	name: string;
};

const PaymentCreate = () => {
	const navigate = useNavigate();
	const toast = useNotification();
	const { user } = useAuth();
	const { selectedCompany, isCompanyBCIntegrated, setDirty } = useAppState();
	const {
		data: response,
		isFetching: fetchingBrands,
		refetch: refetchBrands,
	} = useGetBrands(selectedCompany || 0);

	const { mutate: createNewPaymentMethod } = useCreateNewPaymentMethod();

	const breadcrumbsArray = [
		{
			label: 'Payment',
			onClick: () => navigate('/configuration/payment'),
		},
		{
			label: 'Create payment method',
		},
	];
	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Breadcrumbs items={breadcrumbsArray} />
				<Box style={{ clear: 'both' }}></Box>
			</Box>
			<Formik
				initialValues={{
					name: '',
					brand_ids: [],
					payment_code: '',
					image_url: '',
					is_cash_payment: false,
					is_discount_compatible: true,
					updated_by: user?.name || '',
				}}
				validate={(values) => {
					try {
						isCompanyBCIntegrated
							? PaymentMethodSchemaCreateWithBC.parse(values)
							: PaymentMethodSchemaCreate.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					if (values.brand_ids.length === 0) {
						toast.error('Please select brands');
					} else {
						createNewPaymentMethod(values, {
							onSuccess: () => {
								setDirty(false);
								navigate('/configuration/payment', {}, true);
								toast.success('Payment Method has been successfully created');
							},
							onError: () => {
								toast.error('An error occured');
							},
						});
					}
					actions.setSubmitting(false);
				}}
			>
				{({ values, errors, dirty, touched, setFieldValue, submitForm }) => {
					useStopNavigateDirtyForm(dirty);
					return (
						<>
				
							<Box style={{ width: '100%' }}>
								<Stack direction="row" gap={30}>
									<Stack direction="column" gap={10}>
										<MultiSelectField
											width="600px"
											label="Assign Payment to Brands:"
											placeholder="No brand selected"
											data={
												response?.brands?.map((e: BrandType) => ({
													value: e.id,
													label: e.name,
												})) || []
											}
											onChange={(items) => {
												if (items) {
													setFieldValue(
														'brand_ids',
														items.map((value) => +value)
													);
												}
											}}
										/>
										<Field
											width="600px"
											as={FormTextInput}
											name="name"
											label="Payment Method Name"
											isRequired={true}
											maxLength={80}
											value={values?.name}
											onChange={(e:any) =>
												setFieldValue('name', e.currentTarget.value)
											}
											error={errors.name && touched.name ? errors.name : null}
										/>
										<TextField
											name="payment_code"
											label="Payment Code"
											hidden
											required={isCompanyBCIntegrated}
											maxLength={30}
											value={values?.payment_code}
											onChange={(e) =>
												setFieldValue('payment_code', e.currentTarget.value)
											}
											error={
												errors.payment_code && touched.payment_code
													? errors.payment_code
													: null
											}
										/>
										<SwitchField
											width="600px"
											name="is_cash_payment"
											label="This is a Cash payment"
											size="lg"
											checked={values.is_cash_payment}
											onChange={(e) => {
												setFieldValue(
													'is_cash_payment',
													e.currentTarget.checked
												);
											}}
											onLabel="True"
											offLabel="False"
											hint="Indicate a type of payment method as Cash. When marked TRUE, Cash drawer will open and number pad will be displayed to allow staff to enter cash collected amount from customer."
										/>
									<SwitchField
										width="600px"
										name="is_discount_compatible"
										label="Allow Discount to be applied on this payment method"
										size="lg"
										checked={values.is_discount_compatible}
										onChange={(e) => {
											setFieldValue(
												'is_discount_compatible',
												e.currentTarget.checked
											);
										}}
										onLabel="True"
										offLabel="False"
									/>
										
										<Stack
											width={"100%"}
											style={{
												display:'flex',
												justifyContent:'flex-start',
												marginTop:'15px'
											}}
										>
											<Button onClick={submitForm}>Create</Button>
											<CancelButton
												onClick={() => navigate('/configuration/payment')}
											>
												Cancel
											</CancelButton>
										</Stack>
									</Stack>
									<Stack direction="column" style={{
										alignSelf:"flex-start"
									}}>
										<ImageUploader
											url={values?.image_url}
											onChange={(url) => setFieldValue('image_url', url)}
											inactiveTabs={[
												'Products',
												'Category Groups',
												'Discounts',
												'Brands',
												'Advertisements',
											]}
										/>
										<Text mt={15} align="center" fs="italic" size="xs" color="gray" >
											Recommended Dimensions: W 330px x H 120px
										</Text>
									</Stack>
								</Stack>
							</Box>
						</>
					);
				}}
			</Formik>
		
		</ViewContainer>
	);
};

export default PaymentCreate;

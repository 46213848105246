import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledShow } from './Show.styled';

export type ShowProps = DefaultProps<HTMLSpanElement> & {
	largerThan?: number;
	smallerThan?: number;
};

export const Show = React.forwardRef<HTMLElement, ShowProps>(
	({ children, ...rest }, ref) => {
		return (
			<StyledShow {...rest} ref={ref}>
				{children}
			</StyledShow>
		);
	}
);

Show.displayName = 'Show';

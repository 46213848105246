/* eslint-disable react/display-name */
import { useFocusWithin } from '@mantine/hooks';
import React, { useState } from 'react';
import Icon from '../../assets/icons';
import { ActionIcon } from '@mantine/core';
import { Div, DivProps } from '../Div';
import { DefaultProps } from '../types/DefaultProps';
import { StyledInput } from './Input.styled';

export type InputProps = DefaultProps<HTMLInputElement> &
	React.InputHTMLAttributes<HTMLInputElement> & {
		value: string | string[] | number;
		isRequired?: boolean;
		htmlSize?: number;
		plaintext?: boolean;
		isReadOnly?: boolean;
		isDisabled?: boolean;
		onChange?: React.ChangeEventHandler<HTMLInputElement>;
		type?: string;
		isValid?: boolean;
		isInvalid?: boolean;
		leftIcon?: React.ReactNode;
		isClearable?: boolean;
		rightIcon?: React.ReactNode;
		bgColor?: React.CSSProperties['background'];
	};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			required,
			isRequired,
			isDisabled,
			disabled,
			leftIcon,
			css,
			isClearable,
			onChange,
			rightIcon,
			bgColor,
			...rest
		},
		ref
	) => {
		const inputRef = React.useRef<HTMLInputElement>(null);
		const { ref: focusRef, focused } = useFocusWithin();

		React.useImperativeHandle(
			ref,
			() => inputRef.current as HTMLInputElement,
			[]
		);

		React.useImperativeHandle(
			focusRef,
			() => inputRef.current as HTMLInputElement,
			[]
		);

		const clearInputHandler = () => {
			if (inputRef.current) {
				inputRef.current.value = '';
				onChange &&
					onChange({
						currentTarget: { value: '' },
					} as React.FormEvent<HTMLInputElement>);
			}
		};

		return (
			<Div css={{ position: 'relative' }}>
				{leftIcon ? (
					<Div
						css={{
							position: 'absolute',
							top: '16px',
							left: '18px',
							'> svg': {
								height: '18px',
								width: '18px',
							},
						}}
					>
						{leftIcon}
					</Div>
				) : null}
				<StyledInput
					required={required || isRequired}
					disabled={disabled || isDisabled}
					bgColor={bgColor}
					leftIcon={leftIcon}
					isClearable={isClearable}
					css={css}
					onChange={onChange}
					{...rest}
					ref={inputRef}
				/>
				{isClearable && inputRef?.current?.value ? (
					<ActionIcon
						css={{
							position: 'absolute',
							right: '1%',
							top: '8px',
							svg: {
								height: '18px',
								width: '18px',
							},
						}}
						onClick={() => clearInputHandler()}
					>
						<Icon name="cancel" />
					</ActionIcon>
				) : rightIcon ? (
					<Div
						css={(theme) => ({
							color: theme.colors.primary,
							position: 'absolute',
							right: '18px',
							top: '16px',
							'> svg': {
								height: '18px',
								width: '18px',
							},
						})}
					>
						{rightIcon}
					</Div>
				) : null}
			</Div>
		);
	}
);

Input.displayName = 'Input';

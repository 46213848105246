import React from 'react';
import {
	useNavigate as _useNavigate,
	NavigateOptions,
	To,
} from 'react-router-dom';
import Icon from '../assets/icons';
import { useAppState } from '../providers/AppStateProvider';
import { modals } from '@mantine/modals';
import { Box, Title } from '@mantine/core';

export const useNavigate = () => {
	const { isDirty } = useAppState();
	const nav = _useNavigate();

	const navigate = (to: To, options?: NavigateOptions, force?: boolean) => {
		if (force) {
			nav(to, options);
			return;
		}
		if (isDirty) {
			modals.openConfirmModal({
				size: 'lg',
				children: (
					<Box px="lg">
						<Title order={3} pb="lg">
							Do you want to continue edit this page or discard changes and
							leave?
						</Title>
					</Box>
				),
				confirmProps: {
					color: 'red',
					leftIcon: <Icon name="delete" />,
					size: 'md',
				},
				cancelProps: {
					size: 'md',
					style: {
						backgroundColor: '#dee2e6',
						color: '#2a2e33',
						borderStyle: 'none',
					},
				},
				onConfirm: () => {
					nav(to, options);
				},
				labels: {
					confirm: 'Discard and leave',
					cancel: 'Stay on this page',
				},
			});
		} else {
			nav(to, options);
		}
	};

	return navigate;
};

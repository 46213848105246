import { ProductSchema, ProductSchemaWithPos } from 'schemas/product.schema';
import { z } from 'zod';
import { BaseSchema } from '../base.schema';
import {
	OptionSchemaWithId,
	OptionSchemaWithoutId,
	UpdateOptionSchema,
	OptionSchemaWithIdOptional,
} from './option.schema';

/**
 * Option Group Schema
 */

export const OptionGroupSchemaWithId = BaseSchema.extend({
	name: z.string().min(1,"This field is required").max(255),
	name_other_languages: z
		.object({
			zh: z.string().max(255).default(""),
			ms: z.string().max(255).default(""),
		})
		.default({ zh: '' }),
	description: z.string().max(255).optional(),
	min_selections: z.string().nullable().default("0"),
	max_selections: z.string().nullable().default("1"),
	brand_id: z.number().int(),
});

export const OptionGroupSchemaWithoutId = OptionGroupSchemaWithId.omit({
	id: true,
	created_at: true,
	updated_at: true,
});

const OptionGroupSchemaWithIdAndOptions = OptionGroupSchemaWithId.extend({
	options: z.array(OptionSchemaWithIdOptional),
	products: z.array(ProductSchemaWithPos),
	brand_name: z.string(),
	updated_by: z.string()
});

/**
 * Option Group Types
 */

export type IOptionGroupWithId = z.infer<typeof OptionGroupSchemaWithId>;
export type IOptionGroupWithoutId = z.infer<typeof OptionGroupSchemaWithoutId>;
export type IOptionGroupWithIdAndOptions = z.infer<
	typeof OptionGroupSchemaWithIdAndOptions
>;

/**
 * Create Option Group Schema and Type
 */

export const CreateOptionGroupSchema = OptionGroupSchemaWithoutId.omit({
	brand_id:true
}).extend({
	options: z.array(OptionSchemaWithoutId),brand_ids: z.array(z.number())
});

export type ICreateOptionGroup = z.infer<typeof CreateOptionGroupSchema>;

/**
 * Update Option Group Schema and Type
 */

export const UpdateOptionGroupFormikSchema = OptionGroupSchemaWithId.omit({
	created_at: true,
	updated_at: true
}).extend({
	options: z.array(OptionSchemaWithoutId),
});

export type TUpdateOptionGroupFormikSchema = z.infer<typeof UpdateOptionGroupFormikSchema>

export const UpdateOptionGroupApiSchema = OptionGroupSchemaWithId.extend({
	options: z.object({
		create: z.array(OptionSchemaWithoutId),
		update: z.array(OptionSchemaWithId),
		remove: z.array(OptionSchemaWithId),
	}),
});

export type IUpdateOptionGroupApiSchema = z.infer<
	typeof UpdateOptionGroupApiSchema
>;

export const CopyOptionGroup = z.object({
	id: z.number(),
    name: z.string().min(1,"This field is required").max(80),
    isCopyDescription: z.boolean()
})

export type CopyOptionGroupType = z.infer<typeof CopyOptionGroup>
import { Dialog } from '@headlessui/react';
import React from 'react';
import { Div, DivProps } from '../Div';
import { Overlay } from '../Overlay';

export type PrivateModalProps = Omit<DivProps, 'title'> & {
	open: boolean;
	onClose: (value: boolean) => void;
	title?: React.ReactNode;
	closeOnClickOutside?: boolean;
	hideCloseButton?: boolean;
};

const defaultProps = {
	closeOnClickOutside: true,
};

export const PrivateModal = React.forwardRef<HTMLDivElement, PrivateModalProps>(
	(
		{
			children,
			open,
			onClose,
			title,
			closeOnClickOutside,
			hideCloseButton,
			...rest
		},
		ref
	) => {
		const [opened, setOpened] = React.useState<boolean>(false);

		React.useEffect(() => {
			setOpened(open);
		}, [open]);

		const closePrivateModalHandler = () => {
			setOpened(false);
			onClose && onClose(false);
		};

		return opened ? (
			<Dialog
				open={opened}
				onClose={() => {
					if (closeOnClickOutside) {
						closePrivateModalHandler();
					}
				}}
				style={{
					position: 'fixed',
					bottom: 0,
					// left: 0,
					height: '100vh',
					width: '100vw',
					display: 'grid',
					placeItems: 'center',
					zIndex: 1000,
				}}
			>
				<Overlay />
				<Dialog.Panel as={Div} style={{ zIndex: 1999 }} {...rest} ref={ref}>
					{children}
				</Dialog.Panel>
			</Dialog>
		) : null;
	}
);

PrivateModal.displayName = 'PrivateModal';
PrivateModal.defaultProps = defaultProps;

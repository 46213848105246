import { z } from 'zod';
import { DiscountEnum } from '../constants/discount.enum';
import { zodDateStringSchema } from './base.schema';

export const CreateDiscountSchema = z.object({
	name: z.string().max(255),
	brand_id: z.number().int(),
	description: z.string().max(1028).nullable().default(null),
	discount_code: z.string().max(255).optional(),
	scope: z.enum([
		DiscountEnum.SCOPE.ORDER,
		DiscountEnum.SCOPE.PRODUCT
	]),
	image_url: z.string().max(2083).nullable().default(null),
	type: z
		.enum([
			DiscountEnum.TYPE.FIXED_AMOUNT,
			DiscountEnum.TYPE.FIXED_PERCENT,
			DiscountEnum.TYPE.VAR_AMOUNT,
			DiscountEnum.TYPE.VAR_PERCENT,
		])
		.default(DiscountEnum.TYPE.VAR_AMOUNT),
	value: z.number().int().nullable().default(null),
	percent: z.number().max(100).nullable().default(null),
	is_stackable: z.boolean().default(true),
	auto_apply_on: z
		.enum([
			DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PAY_METHOD,
			DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PRODUCT,
			DiscountEnum.AUTO_APPLY_ON.MANUAL,
		])
		.default(DiscountEnum.AUTO_APPLY_ON.MANUAL),
	start_date: zodDateStringSchema.nullable().default(null),
	end_date: zodDateStringSchema.nullable().default(null),
	is_manager_only: z.boolean().default(false),
	payment_method_ids: z.array(z.number().int()).nullable().default(null),
});

export const CreateDiscountSchemaWithBC = z.object({
	name: z.string().max(255),
	brand_id: z.number().int(),
	description: z.string().max(1028).nullable().default(null),
	discount_code: z.string().max(255),
	scope: z.enum([
		DiscountEnum.SCOPE.ORDER,
		DiscountEnum.SCOPE.PRODUCT
	]),
	image_url: z.string().max(2083).nullable().default(null),
	type: z
		.enum([
			DiscountEnum.TYPE.FIXED_AMOUNT,
			DiscountEnum.TYPE.FIXED_PERCENT,
			DiscountEnum.TYPE.VAR_AMOUNT,
			DiscountEnum.TYPE.VAR_PERCENT,
		])
		.default(DiscountEnum.TYPE.VAR_AMOUNT),
	value: z.number().int().nullable().default(null),
	percent: z.number().max(100).nullable().default(null),
	is_stackable: z.boolean().default(true),
	auto_apply_on: z
		.enum([
			DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PAY_METHOD,
			DiscountEnum.AUTO_APPLY_ON.SPECIFIC_PRODUCT,
			DiscountEnum.AUTO_APPLY_ON.MANUAL,
		])
		.default(DiscountEnum.AUTO_APPLY_ON.MANUAL),
	start_date: z.date(),
	// end_date: z.date(),
	is_manager_only: z.boolean().default(false),
	payment_method_ids: z.array(z.number().int()).nullable().default(null),
});

export const UpdateDiscountSchema = CreateDiscountSchema;
export const UpdateDiscountSchemaWithBC = CreateDiscountSchemaWithBC;

export const ProductRoutes = {
	// Menu
	Menu: '/products/menu',
	MenuPreview: '/products/menu/preview/:posId',
	// Categories
	Categories: '/products/categories',
	CategoryGroups: '/products/categorygroups',
	CreateCategory: '/products/categories/create',
	EditCategory: '/products/categories/edit/:categoryId',
	// Category Groups
	CreateCategoryGroups: '/products/categorygroups/create',
	EditCategoryGroups: '/products/categorygroups/edit/:categoryGroupId',
	// Option Groups
	OptionGroups: '/products/optiongroups',
	CreateOptionGroup: '/products/optiongroups/create',
	EditOptionGroup: '/products/optiongroups/:id',
	// Products
	CreateProduct: '/products/create',
	EditProduct: '/products/categories/edit/:categoryId/:productId',
	// Kitchen Groups
	KitchenGroups: '/products/kitchengroups',
	CreateKitchenGroups: '/products/kitchengroups/create',
	EditKitchenGroups: '/products/kitchengroups/:kitchenGroupId',
	// Selling times
	SellingTimes: '/products/sellingtimes',
	CreateSellingTimes: '/products/sellingtimes/create',
	EditSellingTimes: '/products/sellingtimes/:id'
};

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { TPaymentMethod } from '../../../schemas/paymentmethods.schema';

export function useGetPaymentMethodsByBrandId({brandId, isFiltered, discount_id = null }:{brandId: string|number, isFiltered: boolean, discount_id: number|null}) {
	const { getAccessToken } = useAuth();

	return useQuery(
		['paymentmethods', brandId],
		async (): Promise<{
			paymentMethods: TPaymentMethod[];
			total_records: number;
		}> =>
			configApi
				.get(`/payment-methods`, {
					params: { brand_id: brandId, isFiltered,discount_id},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ keepPreviousData: true, refetchOnWindowFocus: false }
	);
}

export function useGetPaymentMethodsByPosId(posId: string) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['paymentmethods_post', posId],
		async (): Promise<TPaymentMethod[]> =>
			configApi
				.get(`/payment-methods`, {
					params: { pos_id: posId },
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ keepPreviousData: true, refetchOnWindowFocus: false }
	);
}

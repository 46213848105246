import { useMutation } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCreateUser() {
	const { getAccessToken } = useAuth();
	return useMutation(async ({ payload }: { payload: any }) =>
		authApi
			.post(`/users`, payload, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

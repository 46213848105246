import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export type TUseGetCategoriesParams = {
	brand_id: number;
	dropdown?: 'yes';
	category_group_id?: string;
	with_opt_groups?: string;
	sort?: string;
	include_pos?: string;
	limit?: number;
	search_keyword?: string | null;
	search_by?: string;
	requested_page?: number;
};

export type _UseGetCategoriesParams = {
	brand_id: number;
	dropdown?: 'yes';
	category_group_id?: string[];
	with_opt_groups?: string;
	sort?: string;
	include_pos?: string;
	limit?: number;
	search_keyword?: string | null;
	search_by?: string;
	requested_page?: number;
};

export function useGetCategories(params?: TUseGetCategoriesParams) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['categories', params],
		async (): Promise<{
			num_of_pages: number;
			categories: any[];
			total_records: number;
		}> => {
			return configApi
				.get(`/categories`, {
					params,
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data);
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false }
	);
}

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { TCategorySchema } from '../../../schemas/category.schema';
import { TProductSchema } from '../../../schemas/product.schema';

type TCategoryWithProducts = TCategorySchema & {
	products: TProductSchema[];
};

export function useGetCategoryById(id?: string) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['category', id],
		async (): Promise<TCategoryWithProducts> =>
			configApi
				.get(`/categories/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!id,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);
}

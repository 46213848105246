import React from 'react';
import { theme } from '../../providers/ThemeProvider/index';
import { DivProps, Div } from '../Div';
import { Stack } from '../Stack';
import { Text, TextProps } from '@mantine/core';
import { StyledInputWrapper } from './InputWrapper.styled';
import { Tooltip } from '@mantine/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@emotion/styled';
import { DefaultProps } from '../types/DefaultProps';

export type InputWrapperProps = DivProps & {
	label?: React.ReactNode;
	direction?: React.CSSProperties['flexDirection'];
	error?: React.ReactNode;
	labelProps?: TextProps;
	hasAsterisk?: boolean;
	width?: React.CSSProperties['width'];
	hint?: string;
	inputContainerProps?: DefaultProps<HTMLSpanElement>;
	textPadding?: React.CSSProperties['padding'];
	style?: React.CSSProperties;
};

const StyledInfoIcon = styled(InfoOutlinedIcon)`
	color: ${({ theme }) => theme.colors.grey50};

	:hover {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}
`;

const defaultProps: Partial<InputWrapperProps> = {
	direction: 'row',
	width: '100%',
};

export const InputWrapper = React.forwardRef<HTMLDivElement, InputWrapperProps>(
	(
		{
			children,
			direction,
			label,
			error,
			labelProps,
			hasAsterisk,
			width,
			hint,
			inputContainerProps,
			style,
			textPadding,
			...rest
		},
		ref
	) => {

		const widthNumber = Number(String(width).replace('px',''))
		return (
			<StyledInputWrapper {...rest} ref={ref}>
				<Stack direction={direction} width="100%" align="top" style={{justifyContent:'flex-start'}}>
					{label && (
						<Div
							css={{
								alignSelf: 'flex-start',
								width: String((widthNumber * (25/100)))+'px',
								textAlign: 'left',
								display: 'inline-block',
							}}
						>
							<Text
								size={14}
								sx={{
									display: 'inline-block',
									textAlign: 'left',
									padding: textPadding ? textPadding : '16px 0',
								}}
								w={widthNumber * (0.25)+'px'}
								{...labelProps}
							>
								{label}
								{hasAsterisk && (
									<Text
										component="span"
										color="red"
										css={{ marginLeft: '4px' }}
									>
										*
									</Text>
								)}

								{hint && (
									<Tooltip
										label={hint}
										css={{
											maxWidth: '200px',
											display: 'inline-block',
											verticalAlign: 'bottom',
										}}
									>
										<StyledInfoIcon fontSize="small" />
									</Tooltip>
								)}
							</Text>
						</Div>
					)}

					<span
						{...inputContainerProps}
						style={{
							width: String((widthNumber * (75/100)))+'px',
							textAlign: 'left',
							...style,
							...inputContainerProps?.style,
						}}
					>
						{children}
						{error ? (
							<div style={{ marginTop: '6px', textAlign: 'left' }}>
								<Text component="small" color={theme.colors.red} size="14px">
									{error}
								</Text>
							</div>
						) : null}
					</span>
				</Stack>
			</StyledInputWrapper>
		);
	}
);

InputWrapper.displayName = 'InputWrapper';
InputWrapper.defaultProps = defaultProps;

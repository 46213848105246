import Fuse from 'fuse.js';
import {
	Box,
	Breadcrumbs,
	Modal,
	Group,
	Title,
	Stack,
	Text,
	ScrollArea,
	Button,
	Anchor,
	Image,
	TextInput,
	Loader,
} from '@mantine/core';
import React from 'react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Icon from '../../assets/icons';
import { useCreateImage, useGetImages } from '../../hooks/images';
import { useAppState } from '../../providers/AppStateProvider';
import { FolderList } from './FolderList';
import { FileList } from './FileList';
import chevronRight from './chevron-right.svg';
import { notifications } from '@mantine/notifications';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import AddFolder from './AddFolder';

const MAX_FILE_SIZE_BYTES = 1048576; // Limit: 1MB. 1MB -> 1048576 Bytes;

export type FilePreviewProps = {
	title: string;
	environment: 'local' | 'development' | 'staging' | 'production';
};

const FilePreviewContext = React.createContext<{
	prefix: string;
	setPrefix: React.Dispatch<React.SetStateAction<string>>;
}>({
	prefix: '',
	setPrefix: () => {
		return;
	},
});

export const useFilePreviewContext = () => React.useContext(FilePreviewContext);

export const FilePreview = React.forwardRef<HTMLDivElement, FilePreviewProps>(
	({ title, environment }, ref) => {
		const [prefix, setPrefix] = React.useState(
			`images/${environment}/${title}/`
		);

		const { selectedCompany } = useAppState();

		const [opened, { close, open }] = useDisclosure(false);

		const { data, refetch, isFetching } = useGetImages({
			company_id: String(selectedCompany || 0),
			prefix,
		});

		const value = { prefix, setPrefix };

		const secondCrumb = prefix?.split(`${title}/`)?.at(-1)?.slice(0, -1);

		const { mutate: upload } = useCreateImage();

		const [searchKeyword, setSearchKeyword] = React.useState('');

		const [debouncedValue] = useDebouncedValue(searchKeyword, 500);

		const folderSearch = new Fuse(data?.CommonPrefixes || [], {
			minMatchCharLength: 4,
			keys: ['Folder'],
		});

		const fileSearch = new Fuse(data?.Contents || [], {
			minMatchCharLength: 4,
			keys: ['Filename'],
		});

		const _folders = React.useMemo(() => {
			if (debouncedValue) {
				return folderSearch.search(debouncedValue).map((e) => e.item);
			} else {
				return data?.CommonPrefixes;
			}
		}, [folderSearch, debouncedValue]);

		const _files = React.useMemo(() => {
			if (debouncedValue) {
				return fileSearch.search(debouncedValue).map((e) => e.item);
			} else {
				return data?.Contents;
			}
		}, [fileSearch, debouncedValue]);

		return (
			<FilePreviewContext.Provider value={value}>
				<Stack p="xl" ref={ref} spacing="md">
					<Group position="apart" w="100%">
						<Breadcrumbs separator={<Image src={chevronRight} />}>
							<Title
								size="md"
								order={2}
								onClick={() => setPrefix(`images/${environment}/${title}/`)}
							>
								<Anchor>{title}</Anchor>
							</Title>
							{secondCrumb ? <Title size="md" order={2}>{secondCrumb}</Title> : null}
						</Breadcrumbs>
						<Group>
							<TextInput
								placeholder="Search in this folder"
								value={searchKeyword}
								styles={{
										input:{
											height:'36px',
											padding:'12px 12px'
										}
								}}
								onChange={(e) => setSearchKeyword(e.currentTarget.value)}
							/>
							{(secondCrumb || isFetching) ? "" : 
							
								<Button variant="outline" 
									onClick={open}>
									Add Folder
								</Button>
							}
						</Group>
					</Group>
					<Dropzone
						p="xs"
						px="lg"
						accept={IMAGE_MIME_TYPE}
						maxSize={MAX_FILE_SIZE_BYTES}
						onReject={(rejectedFiles) => {
							if (rejectedFiles[0].errors[0].code === 'file-too-large') {
								notifications.show({
									color: 'red',
									message: 'File must be less than 1MB',
								});
							}
						}}
						onDrop={(acceptedFiles) => {
							if (acceptedFiles.length) {
								const file = acceptedFiles[0];
								const formData = new FormData();
								formData.append('prefix', prefix);
								formData.append('image', file);
								upload(formData, {
									onSuccess: () => {
										refetch();
										notifications.show({
											color: 'green',
											message: `Image uploaded to ${title}`,
										});
									},
								});
							}
						}}
					>
						<Group>
							<Icon name="upload" />
							<Stack spacing={0}>
								<Text weight={600} size="sm">
									Upload image
								</Text>
								<Text size="xs" color="gray">
									Drag and drop here or browse
								</Text>
							</Stack>
							<Stack ml="auto" spacing={0}>
								<Text size="sm" align="center">
									JPG/PNG
								</Text>
								<Text size="sm" align="center">{`< 1MB`}</Text>
							</Stack>
						</Group>
					</Dropzone>
					{isFetching ? 
					
						(
							<Box h={240} style={{width:'100%', 
								display:'flex', justifyContent:'center'}}>
									<Box h="100%" style={{ display:'flex', 
										justifyContent:'center', 
										flexDirection:'column'}}>
										<Loader color="cyan" />
									</Box>
							</Box>

						)

						: 
					
						(
							<ScrollArea h={240} my="sm">
								<FolderList folders={_folders} title={title} />
								<FileList files={_files} title={title} />
							</ScrollArea>
						)
					}
					
				</Stack>
				<Modal 
					opened={opened} 
					onClose={close} 
					size="auto" 
					zIndex={1000} 
					title={<Text fw={700}>Add New Folder</Text>}>

					<AddFolder prefix={prefix} closeModal={close} refetchFiles={refetch} />
				
				</Modal>
			</FilePreviewContext.Provider>
		);
	}
);

FilePreview.displayName = 'FilePreview';

import React from 'react';
import {
	MenuBookOutlined,
	DragHandle,
	FastfoodOutlined,
	TakeoutDiningOutlined,
	FilterNoneOutlined,
	PrintOutlined,
	LocalMallOutlined,
	ApartmentOutlined,
	RestaurantOutlined,
	StorefrontOutlined,
	PointOfSaleOutlined,
	CreditCardOutlined,
	GroupOutlined,
	AdminPanelSettingsOutlined,
	BusinessCenterOutlined,
	Add,
	Sort,
	ArchiveOutlined,
	MoreVert,
	ImageOutlined,
	InfoOutlined,
	Close,
	ChevronLeft,
	ChevronRight,
	SyncProblem,
	Done,
	Sync,
	Cancel,
	UnfoldMore,
	ArrowUpward,
	ArrowDownward,
	Delete,
	Upload,
	Folder,
	Event,
	MoreHoriz,
	LinkOff,
	CheckCircle,
	ExpandMore,
	LocalPoliceOutlined,
	FileDownload,
	Analytics,
	Schedule,
} from '@mui/icons-material';
import { IconProps } from './index';

export const icons = {
	// Products
	menu: <MenuBookOutlined />,
	categories: <FastfoodOutlined />,
	categorygroups: <TakeoutDiningOutlined />,
	optiongroups: <FilterNoneOutlined />,
	kitchengroups: <PrintOutlined />,
	sellingtimes: <Schedule />,
	// Configuration
	malls: <LocalMallOutlined />,
	companies: <ApartmentOutlined />,
	brands: <RestaurantOutlined />,
	outlets: <StorefrontOutlined />,
	pos: <PointOfSaleOutlined />,
	payment: <CreditCardOutlined />,
	// Permissions
	users: <GroupOutlined />,
	roles: <AdminPanelSettingsOutlined />,
	bcint: <BusinessCenterOutlined />,
	// Others
	add: <Add />,
	sort: <Sort />,
	archive: <ArchiveOutlined />,
	draghandle: <DragHandle />,
	morevert: <MoreVert />,
	morehort: <MoreHoriz />,
	image: <ImageOutlined />,
	info: <InfoOutlined />,
	close: <Close />,
	chevronleft: <ChevronLeft />,
	chevronright: <ChevronRight />,
	requiresync: <SyncProblem />,
	checkmark: <Done />,
	sync: <Sync />,
	cancel: <Cancel />,
	unfoldmore: <UnfoldMore />,
	arrowup: <ArrowUpward />,
	arrowdown: <ArrowDownward />,
	calendar: <Event />,
};

export const iconsMapper = {
	// Products
	menu: (props: Omit<IconProps, 'name'>) => <MenuBookOutlined {...props} />,
	categories: (props: Omit<IconProps, 'name'>) => (
		<FastfoodOutlined {...props} />
	),
	categorygroups: (props: Omit<IconProps, 'name'>) => (
		<TakeoutDiningOutlined {...props} />
	),
	optiongroups: (props: Omit<IconProps, 'name'>) => (
		<FilterNoneOutlined {...props} />
	),
	kitchengroups: (props: Omit<IconProps, 'name'>) => (
		<PrintOutlined {...props} />
	),
	sellingtimes: (props: Omit<IconProps, 'name'>) => <Schedule {...props} />,
	// Configuration
	malls: (props: Omit<IconProps, 'name'>) => <LocalMallOutlined {...props} />,
	companies: (props: Omit<IconProps, 'name'>) => (
		<ApartmentOutlined {...props} />
	),
	brands: (props: Omit<IconProps, 'name'>) => <RestaurantOutlined {...props} />,
	outlets: (props: Omit<IconProps, 'name'>) => (
		<StorefrontOutlined {...props} />
	),
	pos: (props: Omit<IconProps, 'name'>) => <PointOfSaleOutlined {...props} />,
	payment: (props: Omit<IconProps, 'name'>) => (
		<CreditCardOutlined {...props} />
	),
	// Permissions
	users: (props: Omit<IconProps, 'name'>) => <GroupOutlined {...props} />,
	roles: (props: Omit<IconProps, 'name'>) => (
		<AdminPanelSettingsOutlined {...props} />
	),
	bcint: (props: Omit<IconProps, 'name'>) => (
		<BusinessCenterOutlined {...props} />
	),
	// Others
	add: (props: Omit<IconProps, 'name'>) => <Add {...props} />,
	sort: (props: Omit<IconProps, 'name'>) => <Sort {...props} />,
	archive: (props: Omit<IconProps, 'name'>) => <ArchiveOutlined {...props} />,
	draghandle: (props: Omit<IconProps, 'name'>) => <DragHandle {...props} />,
	morevert: (props: Omit<IconProps, 'name'>) => <MoreVert {...props} />,
	morehort: (props: Omit<IconProps, 'name'>) => <MoreHoriz {...props} />,
	image: (props: Omit<IconProps, 'name'>) => <ImageOutlined {...props} />,
	info: (props: Omit<IconProps, 'name'>) => <InfoOutlined {...props} />,
	close: (props: Omit<IconProps, 'name'>) => <Close {...props} />,
	chevronleft: (props: Omit<IconProps, 'name'>) => <ChevronLeft {...props} />,
	chevronright: (props: Omit<IconProps, 'name'>) => <ChevronRight {...props} />,
	requiresync: (props: Omit<IconProps, 'name'>) => <SyncProblem {...props} />,
	checkmark: (props: Omit<IconProps, 'name'>) => <Done {...props} />,
	sync: (props: Omit<IconProps, 'name'>) => <Sync {...props} />,
	cancel: (props: Omit<IconProps, 'name'>) => <Cancel {...props} />,
	unfoldmore: (props: Omit<IconProps, 'name'>) => <UnfoldMore {...props} />,
	arrowup: (props: Omit<IconProps, 'name'>) => <ArrowUpward {...props} />,
	arrowdown: (props: Omit<IconProps, 'name'>) => <ArrowDownward {...props} />,
	delete: (props: Omit<IconProps, 'name'>) => <Delete {...props} />,
	upload: (props: Omit<IconProps, 'name'>) => <Upload {...props} />,
	folder: (props: Omit<IconProps, 'name'>) => <Folder {...props} />,
	calendar: (props: Omit<IconProps, 'name'>) => <Event {...props} />,
	cancelerror: (props: Omit<IconProps, 'name'>) => (
		<Cancel {...props} style={{ color: 'red' }} />
	),
	unlink: (props: Omit<IconProps, 'name'>) => <LinkOff {...props} />,
	checkcircle: (props: Omit<IconProps, 'name'>) => <CheckCircle {...props} />,
	chevrondown: (props: Omit<IconProps, 'name'>) => <ExpandMore {...props} />,
	adminbadge: (props: Omit<IconProps, 'name'>) => (
		<LocalPoliceOutlined {...props} />
	),
	download: (props: Omit<IconProps, 'name'>) => <FileDownload {...props} />,
	analytics: (props: Omit<IconProps, 'name'>) => <Analytics {...props} />,
};

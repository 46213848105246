import React from 'react';
import { z } from 'zod';
import { useSearchParams } from 'react-router-dom';
import {
	Alert,
	Button,
	Div,
	Field,
	Formik,
	FormTextInput,
	Stack,
	Title,
} from '../../components';
import { useGetCheckResetToken } from '../../hooks/api/password/useGetCheckResetToken';
import { usePostResetPassword } from '../../hooks/api/password/usePostResetPassword';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';

const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
// passwordRegex source: https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a#:~:text=Password%20must%20contain%20one%20digit,be%208%2D16%20characters%20long.&text=Explanation%3A,digit%20from%201%20to%209.

const passwordValidationMessage =
	'Password should be minimum 8 characters, contains 1 uppercase, 1 number, 1 special character';

const validationSchema = z.object({
	email: z.string().email(),
	newPassword: z
		.string()
		.min(8, passwordValidationMessage)
		.max(80)
		.regex(passwordRegex, passwordValidationMessage),
	retypePassword: z
		.string()
		.min(8, passwordValidationMessage)
		.max(80)
		.regex(passwordRegex, passwordValidationMessage),
});

const AccountActivation = () => {
	const [params] = useSearchParams();
	const navigate = useNavigate();
	const { data: tokenCheck } = useGetCheckResetToken({
		email: params.get('email') || '',
		resetToken: params.get('token') || '',
	});

	const { mutate, isLoading } = usePostResetPassword();

	return (
		<Formik
			initialValues={{
				email: params.get('email') ?? '',
				newPassword: '',
				retypePassword: '',
			}}
			validationSchema={toFormikValidationSchema(validationSchema)}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={({ newPassword, retypePassword, email }, { setFieldError }) => {
				if (newPassword !== retypePassword) {
					setFieldError('retypePassword', 'Passwords do not match');
					return;
				}
				if (newPassword === retypePassword) {
					mutate(
						{
							email,
							password: retypePassword,
							reset_password_token: params.get('token') || '',
						},
						{
							onSuccess: () => {
								navigate('/login', {
									state: {
										fromPasswordReset: true,
									},
								});
							},
						}
					);
				}
			}}
			enableReinitialize
		>
			{({ errors, submitForm }) => {
				return (
					<Div>
						<Title>Set password</Title>
						{!params.get('email') ||
						!params.get('token') ||
						tokenCheck?.error ? (
							<Alert style={{ margin: '24px 0' }}>
								This reset password link has expired.
							</Alert>
						) : null}
						{tokenCheck?.valid ? (
							<Stack
								direction="column"
								gap={20}
								style={{ margin: '24px 0' }}
								width="100%"
							>
								<Field
									as={FormTextInput}
									name="email"
									label="Email"
									inputWrapperProps={{
										direction: 'column',
										labelProps: {
											style: { padding: '0' },
										},
									}}
									error={errors['email'] ?? null}
									isDisabled
								/>
								<Field
									as={FormTextInput}
									name="newPassword"
									isRequired
									label="New Password"
									inputWrapperProps={{
										direction: 'column',
										labelProps: {
											style: { padding: '0' },
										},
									}}
									type="password"
									isDisabled={isLoading}
								/>
								<Field
									as={FormTextInput}
									name="retypePassword"
									isRequired
									label="Retype Password"
									inputWrapperProps={{
										direction: 'column',
										labelProps: {
											style: { padding: '0' },
										},
									}}
									type="password"
									isDisabled={isLoading}
								/>
								{errors['retypePassword'] ? (
									<Alert>{errors['retypePassword']}</Alert>
								) : null}
								<Button
                w="100%"
									onClick={() => submitForm()}
									loading={isLoading}
								>
									Set password
								</Button>
							</Stack>
						) : null}
					</Div>
				);
			}}
		</Formik>
	);
};

export default AccountActivation;

import { Box, Checkbox, Grid } from '@mantine/core';
import React from 'react';
import { Select, useFormikContext } from '../../../../components';
import { useGetRolesByCompany } from '../../../../hooks/api/roles/useGetRolesByCompany';

type Props = {
	companyName: string;
	companyId: string | number;
};

const CompanyRole: React.FC<Props> = ({ companyName, companyId }) => {
	const { data: rolesData } = useGetRolesByCompany({
		companyId,
		includePermissions: false,
	});
	const { setFieldValue, values } = useFormikContext<any>();

	const roleIds = rolesData?.roles?.map((e: any) => e.id);

	const [selectedRole, setSelectedRole] = React.useState<string | null>(null);
	const [isThisCompanySelected, setIsThisCompanySelected] =
		React.useState<boolean>(false);

	return (
		<Box
			sx={(theme) => ({
				borderInline: `1px solid ${theme.colors.gray[3]}`,
				borderBottom: `1px solid ${theme.colors.gray[3]}`,
				':first-of-type': {
					borderTop: `1px solid ${theme.colors.gray[3]}`,
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
				},
				':last-of-type': {
					borderBottomLeftRadius: '4px',
					borderBottomRightRadius: '4px',
					borderBottom: `1px solid ${theme.colors.gray[3]}`,
				},
			})}
			p="md"
		>
			<Grid align="center">
				<Grid.Col lg={6}>
					<Checkbox
						label={companyName}
						checked={values?.companies?.includes(companyId)}
						onChange={(e) => {
							setIsThisCompanySelected(e.currentTarget.checked);

							if (!e.currentTarget.checked) {
								const roles = values?.roles?.filter(
									(e: string) => !roleIds?.includes(+e)
								);
								const companies = values?.companies.filter(
									(e: number) => e !== companyId
								);
								setFieldValue(`roles`, roles);
								setFieldValue(`companies`, companies);
							} else {
								const companies = values?.companies?.concat([companyId]);
								setFieldValue(`companies`, companies);
							}
						}}
					/>
				</Grid.Col>
				<Grid.Col lg={6}>
					<Select
						disabled={!isThisCompanySelected}
						w="100%"
						value={values?.roles
							?.find((e: any) => e.company_id === companyId)
							?.id?.toString()}
						data={
							rolesData?.roles?.map((e: any) => ({
								value: String(e?.id),
								label: e?.name,
							})) || []
						}
						placeholder="Please select"
						onChange={(value) => {
							if (value) {
								setSelectedRole((p) => {
									const roles = values?.roles
										?.filter((e: any) => e.company_id !== +companyId)
										.concat([
											rolesData?.roles?.find((e: any) => e.id === +value),
										]);
									setFieldValue(`roles`, roles);
									return value;
								});
							}
						}}
					/>
				</Grid.Col>
			</Grid>
		</Box>
	);
};

export default CompanyRole;

import { useMutation } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useEditRole() {
	const { getAccessToken } = useAuth();
	return useMutation(
		async ({ roleId, payload }: { roleId: string; payload: any }) =>
			authApi
				.put(`/roles/${roleId}`, payload, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

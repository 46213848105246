import React from 'react';
import { useGetCategories } from '../../hooks';
import { useAppState } from '../../providers/AppStateProvider';
import { FormSelectInput } from '../FormSelectInput';
import { InputWrapper } from '../InputWrapper';

export type SelectCategoryProps = any;

export const SelectCategory = React.forwardRef<
	HTMLElement,
	SelectCategoryProps
>((rest, ref) => {
	const { selectedBrand } = useAppState();
	const { data } = useGetCategories({ brand_id: selectedBrand || 0 });
	return (
		<InputWrapper>
			<FormSelectInput
				{...rest}
				ref={ref}
				options={
					data?.categories.map((e) => ({
						value: e.id.toString(),
						label: e.name,
					})) || []
				}
			/>
		</InputWrapper>
	);
});

SelectCategory.displayName = 'SelectCategory';

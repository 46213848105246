import { useFormikContext } from 'formik';
import React from 'react';
import { MultiSelectField, Title } from 'components';
import { Anchor, Box } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { ConfigurationRoutes } from '../../../../router/constants/Configuration.routes';
import { useGetDiscounts } from '../../../../hooks/api/discounts/useGetDiscounts';

const Discount = () => {
	const { setFieldValue, values } = useFormikContext<any>();
	const { data: response_discounts, isFetching: isFetchingDiscounts } =
		useGetDiscounts();
	return (
		<>
			<Title order={2}>Discount</Title>
			<MultiSelectField
				w="690px"
				label="Accepted discounts"
				// hint={
				// 	'Discounts configured to be auto applied on specific payment method will not be displayed'
				// }
				placeholder="Please Select"
				value={values?.discounts?.map((e: number) => String(e))}
				disabled={!response_discounts?.discounts || isFetchingDiscounts}
				data={
					response_discounts?.discounts?.map((e: any) => ({
						value: e.id.toString(),
						label: e.name,
					})) ?? []
				}
				onChange={(values) => {
					if (values) {
						setFieldValue(
							'discounts',
							values.map((e) => +e)
						);
					}
				}}
			>
				<Box my="lg">
					<Anchor
						component={NavLink}
						color="orange"
						to={ConfigurationRoutes.Payment}
					>
						Manage discount types
					</Anchor>
				</Box>
			</MultiSelectField>
		</>
	);
};

export default Discount;

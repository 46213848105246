import { LoadingOverlay } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppState } from '../providers/AppStateProvider';
import { useAuth } from '../providers/AuthProvider';
import { Action } from '../providers/AuthProvider/types';

const Redirect = () => {
	const { state } = useLocation();
	const { setCompany, setBrand } = useAppState();
	const { isAuthenticated, user, getAccessToken, dispatchDangerously } =
		useAuth();

	React.useEffect(() => {
		if (!state) {
			return;
		}

		if (user?.brand_outlets) {
			const brands = Object.keys(user?.brand_outlets);
			if (brands.length && Number.isInteger(+brands[0])) {
				setBrand(+brands[0]);
			}
		}

		if (state.selectedCompany) {
			setCompany(+state?.selectedCompany);
		}

		if (state.decoded && state.data) {
			dispatchDangerously({
				type: Action.ACCESS_TOKEN_SUCCESS,
				payload: {
					userId: state?.decoded?.sub,
					accessToken: state?.data?.access_token,
					exp: state?.decoded?.exp,
					permissions: state?.decoded.permissions || null,
				},
			});
		}
	}, [state]);

	const navigate = useNavigate();

	const timer = useTimeout(
		() => {
			navigate('/');
		},
		5000,
		{ autoInvoke: true }
	);

	useTimeout(
		() => {
			if (state && isAuthenticated) {
				timer.clear();
				navigate(state.redirect || '/dashboard');
			} else {
				getAccessToken().then((token: string | void) => {
					if (token) {
						const decoded: Record<string, any> = jwtDecode(token);
						timer.clear();
						navigate('/company', {
							state: {
								redirect: state.redirect || '/dashboard',
								decoded,
								data: {
									access_token: token,
								},
								sub: decoded?.sub,
							},
						});
					}
				});
			}
		},
		1000,
		{ autoInvoke: true }
	);

	return (
		<div>
			<LoadingOverlay visible />
			{/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
		</div>
	);
};

export default Redirect;

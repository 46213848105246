import { useQuery } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetCheckResetToken(params: {
	email: string;
	resetToken: string;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		'password-token-check',
		async () =>
			authApi
				.post(
					`/password/token`,
					{
						email: params?.email,
						reset_password_token: params?.resetToken,
					},
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data),
		{
			enabled: !!params,
		}
	);
}

import React from 'react';
import { SelectField } from 'components';
import { Flex, Collapse, Title, Text } from '@mantine/core';
import { SwitchField } from 'components/SwitchField';
import { useGetSellingTimes } from 'hooks/api/sellingtimes/useGetSellingTimes';
import { useAppState } from 'providers/AppStateProvider';
import { useFormikContext } from 'formik';

export const GrabIntegration = () => {
	const { setFieldValue, values, errors } = useFormikContext<any>();
	const { selectedBrand } = useAppState();
	const { data: response, isFetching } = useGetSellingTimes(selectedBrand ?? 0);

	return (
		<Flex direction="column" gap="20px" style={{ alignSelf: 'start' }}>
			<Title order={2}>Grab Integration</Title>

			<SwitchField
				width="630px"
				required
				label="Delivery Category"
				onLabel="TRUE"
				offLabel="FALSE"
				checked={values?.is_grab_category}
				onChange={(e) => {
					setFieldValue('is_grab_category', e.currentTarget.checked);
					if (!e.currentTarget.checked) {
						setFieldValue('selling_time_id', null);
					}
				}}
			/>

			<Collapse in={values?.is_grab_category && !isFetching}>
				<SelectField
					required
					labelWidth={160}
					width={450}
					label="Selling Time Group"
					value={values?.selling_time_id}
					data={[
						...(response?.selling_times?.map((e: any) => ({
							label: e.name,
							value: e.id,
						})) ?? []),
					]}
					placeholder="Please select"
					onChange={(value) => {
						if (value) setFieldValue('selling_time_id', +value);
						else setFieldValue('selling_time_id', null);
					}}
				/>
			</Collapse>
		</Flex>
	);
};

import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { DatePicker, DatePickerProps } from '../DatePicker';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type FormDatePickerProps = DatePickerProps & {
	inputWrapperProps?: Omit<InputWrapperProps, 'error' | 'label'>;
	error?: React.ReactNode;
	label?: React.ReactNode;
	width?: React.CSSProperties['width'];
	hint?: string;
	isRequired?: boolean;
	onChange: (
		date: Date | null,
		e: React.SyntheticEvent<any, Event> | undefined
	) => void;
	hidden?: boolean;
};

export const FormDatePicker = React.forwardRef<
	ReactDatePicker,
	FormDatePickerProps
>(
	(
		{
			inputWrapperProps,
			error,
			label,
			isRequired,
			width,
			onChange,
			hidden,
			...rest
		},
		ref
	) => {
		return (
			<InputWrapper
				{...inputWrapperProps}
				label={label}
				width={width}
				hasAsterisk={isRequired}
				error={error}
				hidden={hidden}
			>
				<DatePicker
					onChange={(date, e) => onChange(date, e)}
					{...rest}
					ref={ref}
				/>
			</InputWrapper>
		);
	}
);

FormDatePicker.displayName = 'FormDatePicker';

import { useMutation } from 'react-query';
import { useNotification } from '../../../providers/NotificationProvider';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

type TPatchSortOrder = {
	products: { sort_order: number; id: number }[];
};

export function usePatchProductsSortOrder() {
	const notify = useNotification();
	const { getAccessToken } = useAuth();

	return useMutation({
		mutationFn: async ({ products }: TPatchSortOrder) =>
			configApi.patch(`/products`, products, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}),
		onError: () => {
			notify.error(
				'Error updating products sort order. Changes are not saved.',
				{ autoClose: 36000 }
			);
		},
	});
}

import React from 'react';
import { Box, Input, Tooltip, ActionIcon, InputProps } from '@mantine/core';
import Icon from '../assets/icons';

export type NumberFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
	hidden?: boolean;
} & InputProps &
	React.InputHTMLAttributes<HTMLInputElement>;

export const NumberField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
	({ label, error, hint, required, hidden, ...rest }, ref) => {
		const disableScrolling = (e: any) => {
			// Prevent the input value change
			e.target.blur();

			// Prevent the page/container scrolling
			e.stopPropagation();

			// Refocus immediately, on the next tick (after the current function is done)
			setTimeout(() => {
				e.target.focus();
			}, 0);
		};
		return (
			<Box
				ref={ref}
				hidden={hidden}
				sx={{
					display: 'grid',
					gridTemplateColumns: '240px 1fr',
					alignItems: 'flex-start',
				}}
			>
				<div>
					<Input.Label required={required} mt="15px" mr="10px">
						{label}
					</Input.Label>
					{!hint ? null : (
						<Tooltip label={hint} position="bottom-start">
							<ActionIcon
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon name="info" />
							</ActionIcon>
						</Tooltip>
					)}
				</div>
				<Box>
					<Input
						error={error}
						step={0.01}
						{...rest}
						styles={{
							input: {
								padding: '27px 18px',
							},
						}}
						type="number"
						inputMode="numeric"
						onWheel={disableScrolling}
					/>
					{error ? <Input.Error my="10px">{error}</Input.Error> : null}
				</Box>
			</Box>
		);
	}
);

NumberField.displayName = ' NumberField';

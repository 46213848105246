import React from 'react';

const Foodpanda = () => {
	return (
		<svg
			// width="735px"
			height="170%"
			preserveAspectRatio="xMinYMin slice"
			viewBox="0 0 735 452"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Foodpanda-Logo.wine"
					transform="translate(0.158949, 0.000000)"
					fill="#D70F64"
					fillRule="nonzero"
				>
					<g id="g1045" transform="translate(0.000000, 298.776692)">
						<path
							d="M0,38.1519497 L13.2472048,38.1519497 L13.2472048,25.4346331 C13.2472048,10.0678756 21.7254158,5.32907052e-15 38.6818379,5.32907052e-15 L47.1600489,1.58966457 L47.1600489,16.9564221 L38.1519497,15.3667575 C32.8530678,15.3667575 30.733515,18.5460867 30.733515,23.8449686 L30.733515,38.1519497 L47.6899371,38.1519497 L47.6899371,53.5187072 L30.733515,53.5187072 L30.733515,117.10529 L13.7770929,117.10529 L13.7770929,53.5187072 L0,53.5187072 L0,38.1519497 Z"
							id="path1027"
						></path>
						<path
							d="M90.6108805,36.5622851 C112.336296,36.5622851 131.942159,53.5187072 131.942159,77.8935639 C131.942159,102.268421 112.866184,119.224843 90.6108805,119.224843 C68.3555765,119.224843 49.2796017,102.268421 49.2796017,77.8935639 C49.2796017,52.988819 68.8854647,36.5622851 90.6108805,36.5622851 Z M90.6108805,103.858085 C106.507526,103.858085 114.985737,92.2005451 114.985737,77.8935639 C114.985737,63.5865828 106.507526,51.9290426 90.6108805,51.9290426 C74.7142348,51.9290426 66.2360238,63.5865828 66.2360238,77.8935639 C66.2360238,91.6706569 74.7142348,103.858085 90.6108805,103.858085 L90.6108805,103.858085 Z"
							id="path1029"
						></path>
						<path
							d="M180.161985,36.5622851 C201.8874,36.5622851 221.493263,53.5187072 221.493263,77.8935639 C221.493263,102.268421 202.417289,119.224843 180.161985,119.224843 C157.906681,119.224843 138.830706,102.268421 138.830706,77.8935639 C139.360594,52.988819 158.436569,36.5622851 180.161985,36.5622851 Z M180.161985,103.858085 C196.05863,103.858085 204.536841,92.2005451 204.536841,77.8935639 C204.536841,63.5865828 196.05863,51.9290426 180.161985,51.9290426 C164.265339,51.9290426 155.787128,63.5865828 155.787128,77.8935639 C155.787128,91.6706569 164.265339,103.858085 180.161985,103.858085 L180.161985,103.858085 Z"
							id="path1031"
						></path>
						<path
							d="M309.454703,117.10529 L294.087945,117.10529 L292.498281,110.216744 L291.438505,109.156967 L290.378728,110.216744 C285.079846,115.515625 277.131523,118.694955 268.653312,118.694955 C246.927897,118.694955 228.38181,101.738532 228.38181,77.3636757 C228.38181,52.988819 246.927897,36.0323969 268.653312,36.0323969 C277.131523,36.0323969 285.609734,39.2117261 290.378728,44.510608 L291.438505,45.5703843 L292.498281,44.510608 L292.498281,0.52988819 L309.454703,0.52988819 L309.454703,117.10529 Z M269.713089,51.3991544 C253.816443,51.3991544 245.338232,63.0566946 245.338232,77.3636757 C245.338232,91.6706569 253.816443,103.328197 269.713089,103.328197 C284.02007,103.328197 294.087945,91.6706569 294.087945,77.3636757 C294.087945,63.5865828 284.02007,51.3991544 269.713089,51.3991544 L269.713089,51.3991544 Z"
							id="path1033"
						></path>
						<path
							d="M326.411125,38.1519497 L341.777883,38.1519497 L343.367547,45.0404962 L344.427324,46.1002725 L345.4871,45.0404962 C350.785982,39.7416143 358.734305,36.5622851 367.212516,36.5622851 C388.937931,36.5622851 407.484018,53.5187072 407.484018,77.8935639 C407.484018,102.268421 388.937931,119.224843 367.212516,119.224843 C358.734305,119.224843 350.256094,116.045514 345.4871,110.746632 L344.427324,109.686855 L343.367547,110.746632 L343.367547,153.137687 L326.941013,153.137687 L326.941013,38.1519497 L326.411125,38.1519497 Z M365.622851,103.858085 C381.519497,103.858085 389.997708,92.2005451 389.997708,77.8935639 C389.997708,63.5865828 381.519497,51.9290426 365.622851,51.9290426 C351.31587,51.9290426 341.247994,63.5865828 341.247994,77.8935639 C341.247994,91.6706569 351.31587,103.858085 365.622851,103.858085 Z"
							id="path1035"
						></path>
						<path
							d="M449.345185,67.8256883 L463.122278,67.8256883 L463.122278,64.116471 C463.122278,54.5784836 456.76362,50.3393781 447.755521,50.3393781 C440.337086,50.3393781 435.568092,54.0485954 432.918651,60.9371419 L418.081782,57.7578127 C421.261111,44.510608 432.918651,36.0323969 448.285409,36.0323969 C469.480936,36.0323969 480.0787,46.6301607 480.0787,67.2958001 L480.0787,116.575402 L466.301607,116.575402 L464.711943,105.977638 C459.413061,113.925961 450.93485,117.635178 439.807198,117.635178 C425.500217,117.635178 415.432341,109.156967 415.432341,93.2603214 C414.902453,77.8935639 428.679546,67.8256883 449.345185,67.8256883 L449.345185,67.8256883 Z M441.92675,103.858085 C447.225632,103.858085 452.524514,102.268421 456.233732,98.5592033 C460.472837,94.849986 462.59239,90.0809923 462.59239,83.722334 L462.59239,82.1326695 L448.285409,82.1326695 C438.217533,82.1326695 431.858875,86.9016632 431.858875,94.3200978 C431.858875,99.6189797 435.568092,103.858085 441.92675,103.858085 L441.92675,103.858085 Z"
							id="path1037"
						></path>
						<path
							d="M494.915569,38.1519497 L510.282327,38.1519497 L511.871992,45.0404962 L512.931768,46.1002725 L513.991544,45.0404962 C517.170873,39.7416143 525.649084,36.5622851 532.537631,36.5622851 C552.673382,36.5622851 564.330922,49.8094899 564.330922,68.3555765 L564.330922,117.10529 L547.3745,117.10529 L547.3745,68.3555765 C546.844612,57.7578127 541.54573,51.3991544 530.418078,51.3991544 C520.350203,51.3991544 512.40188,59.3474773 511.871992,69.4153529 L511.871992,117.10529 L494.915569,117.10529 L494.915569,38.1519497 Z"
							id="path1039"
						></path>
						<path
							d="M654.941803,117.10529 L639.575045,117.10529 L637.985381,110.216744 L636.925604,109.156967 L635.865828,110.216744 C630.566946,115.515625 622.618623,118.694955 614.140412,118.694955 C592.414996,118.694955 573.86891,101.738532 573.86891,77.3636757 C573.86891,52.988819 592.414996,36.0323969 614.140412,36.0323969 C622.618623,36.0323969 631.096834,39.2117261 635.865828,44.510608 L636.925604,45.5703843 L637.985381,44.510608 L637.985381,0.52988819 L654.941803,0.52988819 L654.941803,117.10529 Z M615.730077,51.3991544 C599.833431,51.3991544 591.35522,63.0566946 591.35522,77.3636757 C591.35522,91.6706569 599.833431,103.328197 615.730077,103.328197 C630.037058,103.328197 640.104934,91.6706569 640.104934,77.3636757 C640.104934,63.5865828 630.037058,51.3991544 615.730077,51.3991544 Z"
							id="path1041"
						></path>
						<path
							d="M703.161628,67.8256883 L716.938721,67.8256883 L716.938721,64.116471 C716.938721,54.5784836 710.580063,50.3393781 701.571964,50.3393781 C694.153529,50.3393781 689.384535,54.0485954 686.735094,60.9371419 L671.898225,57.7578127 C675.077554,44.510608 686.735094,36.0323969 702.101852,36.0323969 C723.297379,36.0323969 733.895143,46.6301607 733.895143,67.2958001 L733.895143,116.575402 L720.11805,116.575402 L718.528386,105.977638 C713.229504,113.925961 704.751293,117.635178 693.623641,117.635178 C679.31666,117.635178 669.248784,109.156967 669.248784,93.2603214 C668.718896,77.8935639 681.966101,67.8256883 703.161628,67.8256883 L703.161628,67.8256883 Z M695.743193,103.858085 C701.042075,103.858085 706.340957,102.268421 710.050175,98.5592033 C714.28928,94.849986 716.408833,90.0809923 716.408833,83.722334 L716.408833,82.1326695 L702.101852,82.1326695 C692.033976,82.1326695 685.675318,86.9016632 685.675318,94.3200978 C685.675318,99.6189797 689.384535,103.858085 695.743193,103.858085 L695.743193,103.858085 Z"
							id="path1043"
						></path>
					</g>
					<g id="g1059" transform="translate(229.841051, 0.000000)">
						<path
							d="M137.5,203 C155.772727,203 171,189.551724 171,173 L104,173 C104,189.551724 118.212121,203 137.5,203 L137.5,203 Z"
							id="path1047"
						></path>
						<g id="g1057">
							<path
								d="M250.659259,62.9117647 C258.777778,56.8235294 262.837037,46.6764706 262.837037,35.5147059 C262.837037,15.2205882 246.6,0 226.303704,0 C213.111111,0 201.948148,6.08823529 195.859259,16.2352941 C177.592593,7.10294118 157.296296,3.04411765 137,3.04411765 C115.688889,3.04411765 95.3925926,8.11764706 78.1407407,16.2352941 C72.0518519,6.08823529 60.8888889,0 47.6962963,0 C27.4,0 11.162963,16.2352941 11.162963,35.5147059 C11.162963,46.6764706 16.237037,55.8088235 23.3407407,62.9117647 C8.11851852,85.2352941 0,110.602941 0,139.014706 C0,214.102941 60.8888889,276 137,276 C212.096296,276 274,215.117647 274,139.014706 C274,110.602941 265.881481,84.2205882 250.659259,62.9117647 L250.659259,62.9117647 Z M138.014815,264.838235 C69.0074074,264.838235 12.1777778,208.014706 12.1777778,139.014706 C12.1777778,112.632353 20.2962963,88.2794118 33.4888889,67.9852941 C45.6666667,49.7205882 62.9185185,34.5 83.2148148,25.3676471 C99.4518519,17.25 118.733333,13.1911765 138.014815,13.1911765 C157.296296,13.1911765 176.577778,17.25 192.814815,25.3676471 C213.111111,35.5147059 229.348148,49.7205882 242.540741,67.9852941 C255.733333,88.2794118 263.851852,112.632353 263.851852,139.014706 C263.851852,208.014706 207.022222,264.838235 138.014815,264.838235 Z"
								id="path1049"
							></path>
							<path
								d="M138.014815,165.397059 C140.044444,166.411765 161.355556,158.294118 161.355556,148.147059 C161.355556,142.058824 144.103704,141.044118 138.014815,141.044118 C131.925926,141.044118 114.674074,142.058824 114.674074,148.147059 C113.659259,158.294118 135.985185,166.411765 138.014815,165.397059 Z"
								id="path1051"
							></path>
							<path
								d="M217.17037,80.1617647 C204.992593,72.0441176 178.607407,67.9852941 168.459259,81.1764706 C168.459259,81.1764706 155.266667,95.3823529 168.459259,109.588235 C181.651852,123.794118 190.785185,138 193.82963,149.161765 C196.874074,161.338235 201.948148,165.397059 211.081481,165.397059 C220.214815,165.397059 238.481481,151.191176 241.525926,127.852941 C243.555556,104.514706 229.348148,87.2647059 217.17037,80.1617647 L217.17037,80.1617647 Z M189.77037,109.588235 C185.711111,109.588235 182.666667,106.544118 182.666667,102.485294 C182.666667,98.4264706 185.711111,95.3823529 189.77037,95.3823529 C193.82963,95.3823529 196.874074,98.4264706 196.874074,102.485294 C196.874074,106.544118 193.82963,109.588235 189.77037,109.588235 Z"
								id="path1053"
							></path>
							<path
								d="M106.555556,81.1764706 C96.4074074,67.9852941 70.0222222,72.0441176 57.8444444,80.1617647 C45.6666667,88.2794118 31.4592593,104.514706 33.4888889,127.852941 C35.5185185,151.191176 53.7851852,165.397059 63.9333333,165.397059 C73.0666667,165.397059 78.1407407,160.323529 81.1851852,149.161765 C84.2296296,136.985294 94.3777778,123.794118 106.555556,109.588235 C119.748148,94.3676471 106.555556,81.1764706 106.555556,81.1764706 L106.555556,81.1764706 Z M87.2740741,109.588235 C83.2148148,109.588235 80.1703704,106.544118 80.1703704,102.485294 C80.1703704,98.4264706 83.2148148,95.3823529 87.2740741,95.3823529 C91.3333333,95.3823529 94.3777778,98.4264706 94.3777778,102.485294 C94.3777778,106.544118 91.3333333,109.588235 87.2740741,109.588235 Z"
								id="path1055"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Foodpanda;

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export const useGetBrandName = (brandId: number) => {
	const { getAccessToken } = useAuth();

	const fetchBrandName = async (brandId: number) => {
		const res = await configApi.get(`/brands/${brandId}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return res.data;
	};

	return useQuery(['brandName', brandId], () => fetchBrandName(brandId), {
		enabled: !!brandId,
	});
};

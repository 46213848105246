import React from 'react';
import { InputSwitch, InputSwitchProps } from '../InputSwitch/InputSwitch';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type FormSwitchProps = InputSwitchProps & {
	inputWrapperProps?: Omit<InputWrapperProps, 'error' | 'label'>;
	error?: React.ReactNode;
	label?: React.ReactNode;
	width?: React.CSSProperties['width'];
	isRequired?: boolean;
	checked?: boolean;
	isInvalid?: boolean;
};

export const FormSwitch = React.forwardRef<HTMLInputElement, FormSwitchProps>(
	(
		{
			inputWrapperProps,
			error,
			label,
			width,
			checked,
			isInvalid,
			isRequired,
			...rest
		},
		ref
	) => {
		return (
			<InputWrapper
				{...inputWrapperProps}
				label={label}
				width={width}
				hasAsterisk={isRequired}
				error={error || isInvalid}
				inputContainerProps={{ style: { alignSelf: 'center' } }}
			>
				<InputSwitch
					checked={checked}
					{...rest}
					ref={ref}
					css={{ alignSelf: 'center' }}
				/>
			</InputWrapper>
		);
	}
);

FormSwitch.displayName = 'FormSwitch';

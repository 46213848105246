import React from 'react';
import {
	Box,
	Input,
	Tooltip,
	ActionIcon,
	SwitchProps,
	Switch
} from '@mantine/core';
import Icon from '../assets/icons';

export type SwitchFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	width?:string;
	required?: boolean;
	switchLabel?: React.ReactNode;
} & Omit<SwitchProps, 'label'>;

export const SwitchField = React.forwardRef<HTMLDivElement, SwitchFieldProps>(
	({ label, error, hint, required, switchLabel, width, ...rest }, ref) => {

		const labelWidth = (width) && (Number(width.replace('px','')) * (25/100))

		return (
			<Box
				ref={ref}
				sx={{
					display: 'grid',
					gridTemplateColumns: ((width && labelWidth) 
						? String(labelWidth)+'px '+String(labelWidth*3)+'px' 
						: '240px 1fr'),
					alignItems: 'flex-start',
				}}
			>
				<div>
					<Input.Label required={required} mr="lg">
						{label}
						{!hint ? null : (
						<Tooltip label={hint} multiline position="bottom-start" width={200}>
							<ActionIcon
								size="xs"
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon fontSize="small" name="info" />
							</ActionIcon>
						</Tooltip>
					)}
					</Input.Label>
				</div>

				<Box my="auto">
					<Switch error={error} {...rest} label={switchLabel} />
					{error ? (
						<Input.Error my="10px">This field is required</Input.Error>
					) : null}
				</Box>
			</Box>
		);
	}
);

SwitchField.displayName = 'SwitchField';

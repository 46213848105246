import React from 'react';
import {
	Avatar,
	Button,
	Field,
	Formik,
	FormTextInput,
	InputWrapper,
	Title,
	Text,
	Divider,
	Spinner,
	Stack,
	ViewContainer,
	CancelButton,
} from '../../components';
import { usePostPasswordRecovery } from '../../hooks/api/password/usePostPasswordRecovery';
import { useGetCompanyName } from '../../hooks/api/managements/useGetCompanyName';
import { useEditUser } from 'hooks/api/managements/useEditUser';
import { useAppState } from '../../providers/AppStateProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { toFormikValidationSchema } from 'lib/helpers';
import { Paper } from 'components/Paper';
import { useNavigate } from 'react-router-dom';
import { accountSchema } from 'schemas/account.schema';
import { useGetBrands } from '../../hooks/brands';
import dayjs from 'dayjs';
import OutletOptions from './OutletOptions';
import Icon from '../../assets/icons';
import { Box, Grid } from '@mantine/core';

export interface UserInfo {
	name: string;
	email: string;
	username: string;
	terminal_pin: string;
	is_superadmin: boolean;
	is_active: boolean;
	brand_outlet: Record<string, number[]>;
	role?: string;
}

const AccountSettings = () => {
	const { setIsSidebarHidden, selectedCompany } = useAppState();
	const { user, logout, userId } = useAuth();

	React.useEffect(() => {
		setIsSidebarHidden(true);
		return () => setIsSidebarHidden(false);
	}, []);

	const { mutate, isLoading } = usePostPasswordRecovery();
	const notify = useNotification();
	const navigate = useNavigate();

	const [first, second] = (user?.name ?? '').split(' ');
	const nameInitial = (first?.[0] ?? '') + (second?.[0] ?? '');
	const { name: roleName } =
		user?.roles.find((role: any) => role.company_id == selectedCompany) || {};
	const roleIds = user?.roles.map((role: any) => role.id) || [];

	//get company brands
	const { data: companyBrands } = useGetBrands(selectedCompany as number);

	// get all the company brand ids
	const companyBrandIds = companyBrands?.brands?.map((singleBrand:any) => parseInt(singleBrand.id))

	const { data: company, isFetching } = useGetCompanyName(selectedCompany);

	const { mutate: editUser, isLoading: editUserLoading } = useEditUser();
	console.log(user?.brand_outlet)
	return (
		<Box bg="gray.2" h="100%">
			<Grid w="100%" px="5%" maw="1400px" m="auto" pt="xl">
				<Grid.Col p="lg" md={12} lg={4}>
					<div
						style={{
							position: 'relative',
							marginBottom: '-20px',
							zIndex: 2,
							padding: '0 25px',
						}}
					>
						<Avatar style={{ height: '60px', width: '60px' }}>
							{nameInitial}
						</Avatar>
					</div>
					<Paper style={{ position: 'relative', padding: '30px' }}>
						<Title order={4}>{user?.name}</Title>
						<Text color="#787E84">{user?.email}</Text>

						<Text color="#787E84" style={{ marginBottom: '30px' }}>
							{roleName}
						</Text>
						<Text color="#787E84">LAST LOGIN</Text>
						<div>{dayjs(user?.last_login_at).fromNow()}</div>
					</Paper>
				</Grid.Col>
				<Grid.Col p="lg" md={12} lg={8}>
					<Formik
						enableReinitialize={true}
						initialValues={{
							name: user?.name || '',
							email: user?.email || '',
							role: roleName || '',
							username: user?.username || '',
							terminal_pin: user?.terminal_pin || '',
							is_superadmin: user?.is_superadmin || false,
							is_active: user?.is_active || false,
							brand_outlet: user?.brand_outlet || {},
							roles: roleIds || [],
						}}
						validationSchema={toFormikValidationSchema(accountSchema)}
						onSubmit={(values, { resetForm }) => {
							const { role, ...updatedUserInfo }: UserInfo = values;
							editUser(
								{ updatedUserInfo, userId: userId },
								{
									onSuccess: () => {
										notify.success('User successfully updated');
									},
									onError: () => {
										resetForm();
										notify.error('Error updating user');
									},
								}
							);
						}}
					>
						{({ values, errors, touched, submitForm, resetForm }) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '10px',
										marginTop: '30px',
									}}
								>
									<Field
										width="720px"
										as={FormTextInput}
										name="name"
										label="Name"
										isRequired
										error={errors.name && touched.name ? errors.name : null}
									/>

									<Field
										width="720px"
										as={FormTextInput}
										name="email"
										label="Email"
										isRequired
										error={errors.email && touched.email ? errors.email : null}
									/>
									<Field
										width="720px"
										as={FormTextInput}
										name="username"
										label="Username"
										isRequired
										error={
											errors.username && touched.username
												? errors.username
												: null
										}
									/>
									<InputWrapper
										width="720px"
										direction="row"
										label="Dashboard password"
										hasAsterisk
										hint="Password must be at least 8 characters, and include at least 1 special character, 1 number and 1 uppercase letter"
									>
										<Stack
											align="center"
											gap={3}
											style={{
												marginTop: '15px',
												color: '#E77615',
											}}
										>
											<Icon name="sync" fontSize="small" />

											<Text
												style={{
													cursor: !isLoading ? 'pointer' : 'cursor',
												}}
												onClick={() =>
													mutate(
														{ email: user?.email },
														{
															onSuccess: () => {
																notify.success(
																	`Password reset email has been sent to your email`
																);
															},
															onSettled: () => {
																logout();
															},
														}
													)
												}
											>
												Reset Password
											</Text>
										</Stack>
									</InputWrapper>
									<Field
										width="720px"
										as={FormTextInput}
										isReadOnly
										bgColor="#f8f9fa"
										name="is_superadmin"
										label="Super Administrator Role"
										value={values.is_superadmin ? 'Active' : 'Inactive'}
										isRequired
									/>
									{
										!values.is_superadmin && (
											<Field
												width="720px"
												as={FormTextInput}
												isReadOnly
												bgColor="#f8f9fa"
												name="role"
												label="Role"
												// style={{ marginLeft: '-18px' }}
												isRequired
											/>
										)
									}

									<Field
										width="720px"
										as={FormTextInput}
										name="terminal_pin"
										label="Terminal 8 digit PIN"
										isRequired
										error={
											errors.terminal_pin && touched.terminal_pin
												? errors.terminal_pin
												: null
										}
									/>

									{isFetching ? (
										<Spinner color="black" />
									) : (
										company && (
											<div >
												<Divider
													size="1px"
													style={{ color: '#E8EAED', padding: '15px' }}
												/>
												<Title order={2}>Brands & Outlets</Title>
												<Title order={6} color="#787E84">
													{company?.name}
												</Title>

												<div
													style={{ 
														display: 'flex', 
														flexDirection: 'column', 
														gap:'10px',
														marginTop:'15px' }}
												>
													{
														
														!values.is_superadmin && (
															
															companyBrandIds?.map((brand_id:any, index:number) => (
																			
																	(user?.brand_outlet[brand_id] != undefined) &&
																		<OutletOptions
																			key={index} 
																			brandId={brand_id} 
																			brandOutlets={user?.
																				brand_outlet[String(brand_id)]} />

																
															))
														)
													
													}
												</div>
											</div>
										)
									)}

									<div
										style={{ display: 'flex', gap: '10px', marginTop: '20px' }}
									>
										<Button
											onClick={() => {
												submitForm();
											}}
											loading={editUserLoading}
										>
											Save Changes
										</Button>
										<CancelButton
											onClick={() => {
												resetForm();
												navigate('/dashboard');
											}}
										>
											Cancel
										</CancelButton>
									</div>
								</div>
							);
						}}
					</Formik>
				</Grid.Col>
			</Grid>
		</Box>
	);
};

export default AccountSettings;

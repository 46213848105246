import {
	Accordion,
	AccordionItemProps,
	ActionIcon,
	Button,
	Checkbox,
	Divider,
	Group,
	Menu,
	Text,
	Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import React from 'react';
import Icon from 'assets/icons';
import { LastUpdatedLabel, Stack } from 'components';
import { useNavigate } from 'lib/navigate';
import { ProductRoutes } from 'router/constants/Products.routes';
import { useCategoriesContext } from './context';
import DuplicateCategory from './modals/DuplicateCategory';
import Products from './Products';
import { nanoid } from 'nanoid';
import RequirePermissions from 'guards/RequirePermissions';

type Props = Omit<AccordionItemProps, 'pos'> & {
	value: string;
	categoryId: string | number;
	categoryName: string;
	categoryGroup: string;
	description: string;
	acctCategory: string;
	lastUpdated: Date;
	updatedBy: string;
	products: any[];
	pos: string[] | null;
};

const Category = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			value,
			categoryId,
			categoryName,
			categoryGroup,
			description,
			acctCategory,
			products,
			lastUpdated,
			updatedBy: editor,
			pos,
		},
		ref
	) => {
		const navigate = useNavigate();

		// Name truncation
		const [isNameTruncated, setIsNameTruncated] =
			React.useState<boolean>(false);
		const nameRef = React.useRef<HTMLDivElement>(null);

		// Description truncation
		const [isDescriptionTruncated, setIsDescriptionTruncated] =
			React.useState<boolean>(false);
		const descriptionRef = React.useRef<HTMLDivElement>(null);

		React.useLayoutEffect(() => {
			if (nameRef.current) {
				if (nameRef.current.clientWidth < nameRef.current.scrollWidth) {
					setIsNameTruncated(true);
				} else {
					setIsNameTruncated(false);
				}
			}

			if (descriptionRef.current) {
				if (
					descriptionRef.current.clientWidth <
					descriptionRef.current.scrollWidth
				) {
					setIsDescriptionTruncated(true);
				} else {
					setIsDescriptionTruncated(false);
				}
			}
		}, [nameRef, descriptionRef, categoryName]);

		const { selectedProducts, setSelectedProducts } = useCategoriesContext();
		const productIds = products.map(({ id }) => id);

		const selectedProductsInThisCategory = Object.entries(selectedProducts)
			.filter(([key, value]) => !!value && productIds.includes(+key))
			?.map(([key]) => +key);

		return (
			<Accordion.Item ref={ref} value={value} my="md">
				<Accordion.Control
					sx={(theme) => ({ background: theme.colors.gray[0] })}
				>
					<Group w="100%" position="apart">
						<Group>
							<Stack
								direction={'row'}
								width={'300px'}
								style={{ justifyContent: 'flex-start' }}
							>
								<Text truncate ref={nameRef}>
									{categoryName}
								</Text>
								{isNameTruncated && (
									<Tooltip label={categoryName} position="right">
										<ActionIcon>
											<Icon name="info" />
										</ActionIcon>
									</Tooltip>
								)}
							</Stack>

							{!categoryGroup ? null : (
								<>
									<Divider size="sm" orientation="vertical" />
									<Text>{categoryGroup}</Text>
								</>
							)}

							{!description ? null : (
								<Stack
									direction={'row'}
									style={{ justifyContent: 'flex-start', maxWidth: '200px' }}
								>
									<Divider size="sm" orientation="vertical" />
									<Text truncate ref={descriptionRef}>
										{description}
									</Text>

									{isDescriptionTruncated && (
										<Tooltip label={description} position="right">
											<ActionIcon>
												<Icon name="info" />
											</ActionIcon>
										</Tooltip>
									)}
								</Stack>
							)}

							<Divider size="sm" orientation="vertical" />
							<Text
								size="sm"
								color="gray"
							>{`${products.length} Products`}</Text>
						</Group>
						<Group>
							<Text>{acctCategory}</Text>
							<Text>{!pos ? '0' : pos?.length} POS</Text>
							{!pos ? null : (
								<Tooltip
									label={pos?.map((e) => (
										<Text key={nanoid(6)}>{e}</Text>
									))}
								>
									<ActionIcon>
										<Icon name="info" />
									</ActionIcon>
								</Tooltip>
							)}
						</Group>
					</Group>
				</Accordion.Control>
				<Accordion.Panel p={0}>
					{/* Product Actions  */}
					<Group align="center" p="sm" w="100%" position="apart">
						<Group>
							<Checkbox
								disabled={!products?.length}
								checked={
									selectedProductsInThisCategory.length === productIds.length
								}
								indeterminate={
									selectedProductsInThisCategory.length > 0 &&
									selectedProductsInThisCategory.length < productIds.length
								}
								label={`${products.length} Products`}
								onChange={(e) => {
									if (e?.currentTarget) {
										setSelectedProducts((p) => ({
											...p,
											...Object.fromEntries(
												productIds.map((p) => [
													p,
													e?.currentTarget?.checked || false,
												])
											),
										}));
									}
								}}
							/>
						</Group>
						<Group spacing="xl">
							<RequirePermissions permissions={['Categories.Create']}>
								<Button
									variant="light"
									leftIcon={<Icon name="add" />}
									onClick={() =>
										navigate(
											`${ProductRoutes.CreateProduct}?categoryId=${categoryId}`
										)
									}
								>
									Create Product
								</Button>
							</RequirePermissions>
							<Menu position="bottom-end">
								<Menu.Target>
									<ActionIcon size="lg">
										<Icon name="morehort" />
									</ActionIcon>
								</Menu.Target>
								<Menu.Dropdown>
									<Menu.Item
										onClick={() =>
											navigate(
												ProductRoutes.EditCategory.replace(
													':categoryId',
													categoryId.toString()
												)
											)
										}
									>
										Configure
									</Menu.Item>
									<RequirePermissions permissions={['Categories.Create']}>
										<Menu.Item
											onClick={() => {
												modals.open({
													size: 'xl',
													children: (
														<DuplicateCategory
															currentCategoryName={categoryName}
															currentCategoryId={categoryId.toString()}
														/>
													),
												});
											}}
										>
											Make a copy
										</Menu.Item>
									</RequirePermissions>
								</Menu.Dropdown>
							</Menu>
						</Group>
					</Group>
					{/* Products */}
					<Products products={products} />
					<Group w="100%" position="right" p="lg">
						<LastUpdatedLabel lastUpdated={lastUpdated} editor={editor} />
					</Group>
				</Accordion.Panel>
			</Accordion.Item>
		);
	}
);

Category.displayName = 'Category';

export default Category;

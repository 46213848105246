import React from 'react';
import AccountSettings from '../../views/User/AccountSettings';
import { ManagementRoutes } from '../constants/Management.routes';
import { ProtectedRoute } from '../ProtectedRoute';

export const managementRoutes = [
	{
		name: 'User Account Settings',
		path: ManagementRoutes.UserAccountSettings,
		element: ( 
			<ProtectedRoute isUserRoute={true}>
				<AccountSettings />
			</ProtectedRoute>
		),
	},
];

import React from 'react';
import { _UseGetCategoriesParams } from '../../../../hooks';
import { useAppState } from '../../../../providers/AppStateProvider';

export type TCategoriesContext = {
	selectedProducts: Record<string, boolean>;
	setSelectedProducts: React.Dispatch<
		React.SetStateAction<Record<string, boolean>>
	>;
	numSelectProducts: number;
	searchParams: _UseGetCategoriesParams;
	setSearchParams: React.Dispatch<
		React.SetStateAction<_UseGetCategoriesParams>
	>;
	disableDnd: boolean;
	setDisableDnd: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CategoriesContext = React.createContext<TCategoriesContext>({
	selectedProducts: {},
	setSelectedProducts: () => {
		return;
	},
	numSelectProducts: 0,
	searchParams: {} as any,
	setSearchParams: () => {
		return;
	},
	disableDnd: false,
	setDisableDnd: () => {
		return;
	},
});

export const CategoriesContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [selectedProducts, setSelectedProducts] = React.useState<
		Record<string, boolean>
	>({});

	const [disableDnd, setDisableDnd] = React.useState(false);
	const { selectedBrand } = useAppState();

	const [searchParams, setSearchParams] =
		React.useState<_UseGetCategoriesParams>({
			brand_id: selectedBrand!,
			requested_page: 1,
			search_by: 'category',
			search_keyword: '',
			sort: 'updated_at:desc',
			category_group_id: [''],
			with_opt_groups: '',
			include_pos: 'yes',
		});

	React.useEffect(() => {
		setSearchParams((p) => ({
			...p,
			brand_id: selectedBrand!,
		}));
	}, [selectedBrand]);

	const value = {
		selectedProducts,
		setSelectedProducts,
		numSelectProducts: Object.entries(selectedProducts).filter(
			([, value]) => !!value
		).length,
		searchParams,
		setSearchParams,
		disableDnd,
		setDisableDnd,
	};

	return (
		<CategoriesContext.Provider value={value}>
			{children}
		</CategoriesContext.Provider>
	);
};

export const useCategoriesContext = () => React.useContext(CategoriesContext);

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetPaymentMethodById(id: number) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['paymentmethods', id],
		async () =>
			configApi
				.get(`/payment-methods/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!id }
	);
}

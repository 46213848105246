import {
	ActionIcon,
	Box,
	Flex,
	Grid,
	Group,
	Image,
	Menu,
	Text,
} from '@mantine/core';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import Icon from '../../assets/icons';
import { useFileManagerContext } from './FileManager';
import { modals } from '@mantine/modals';
import AddImageToFolder from './AddImageToFolder';

export type FileListProps = {
	files?: {
		Key: string;
		LastModified: string;
		ETag: string;
		Size: number;
		StorageClass: string;
		Url: string;
		Filename: string;
	}[];
	title: string;
};

export const FileList = React.forwardRef<HTMLDivElement, FileListProps>(
	({ files, title }, ref) => {
		const { setSelected, selected } = useFileManagerContext();

		if (!files) {
			return null;
		}

		return (
			<Box component="ul" sx={{ listStyle: 'none' }} w="100%">
				{files?.map(({ Url, Key, Size, Filename }) => {
					const isFileSelected = selected === Url;
					return (
						<Box
							component="li"
							key={`${title}-file-${Url}`}
							sx={{ width: '100%' }}
						>
							<Grid
								w="100%"
								p="sm"
								sx={(theme) => ({
									background: isFileSelected ? theme.colors.cyan[0] : 'inherit',
									':hover': {
										background: isFileSelected
											? theme.colors.cyan[1]
											: theme.colors.gray[1],
									},
								})}
								align="center"
							>
								<Grid.Col
									span={2}
									onClick={() => {(!isFileSelected) ? setSelected(Url) : setSelected("")}}
									sx={{ cursor: 'pointer' }}
								>
									<Image
										withPlaceholder
										width={80}
										height={60}
										fit="contain"
										src={Url}
										alt={Key}
									/>
								</Grid.Col>
								<Grid.Col
									span={8}
									onClick={() => {(!isFileSelected) ? setSelected(Url) : setSelected("")}}
									sx={{ cursor: 'pointer' }}
								>
									<Flex h={60} w={300} align="center">
										<Text truncate>{Key.split('/').at(-1)}</Text>
									</Flex>
								</Grid.Col>
								<Grid.Col span={2}>
									<Group position="right">
										<Text>{prettyBytes(Size)}</Text>
										<Menu>
											<Menu.Target>
												<ActionIcon>
													<Icon name="morehort" />
												</ActionIcon>
											</Menu.Target>
											<Menu.Dropdown>
												<Menu.Item
													onClick={() => {
														modals.openConfirmModal({
															children: (
																<AddImageToFolder url={Url} fileName={Filename} />
															),
															labels: {
																confirm: 'Add',
																cancel: 'Cancel',
															},
														});
													}}
												>
													Add Image to Folder
												</Menu.Item>
											</Menu.Dropdown>
										</Menu>
									</Group>
								</Grid.Col>
							</Grid>
						</Box>
					);
				})}
			</Box>
		);
	}
);

FileList.displayName = 'FileList';

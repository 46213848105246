import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCreateCategoryGroup() {
	const { getAccessToken } = useAuth();
	return useMutation(async ({ values }: { values: any }) =>
		configApi
			.post('/categorygroups', values, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

import React, { useState, useEffect } from 'react';
import { Add, Archive } from '@mui/icons-material';
import {
	Button,
	Box,
	EmptyState,
	SelectBrand,
	Stack,
	ViewContainer,
} from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useGetBrands } from 'hooks/brands';
import { useGetOutlets } from 'hooks/outlets';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useNavigate } from '../../../lib/navigate';
import dayjs from 'dayjs';
import Icon from '../../../assets/icons';
import RequirePermissions from '../../../guards/RequirePermissions';

const Outlets = () => {
	const { selectedCompany, selectedBrand } = useAppState();
	const { data: response_brands } = useGetBrands(selectedCompany || 0);

	const { data: response } = useGetOutlets(Number(selectedBrand || 0));

	const navigate = useNavigate();

	const breadcrumbsArray = [
		{
			label: 'Outlets'
		},
	];

	const getSelectedRow = (row: any) => {
		navigate(`/configuration/outlets/${row.original.id}`);
	};

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} />
			<Box
				style={{
					position: 'relative',
					marginBottom: '20px',
					marginTop: '20px',
				}}
			>
				{response_brands?.brands?.length > 0 && (
					<>
						<Box>
							<SelectBrand />
						</Box>

						<Box>
							<Stack style={{ position: 'absolute', top: 0, right: 0 }}>
								<RequirePermissions permissions={['Outlets.Archive']}>
									<Button
										style={{ color: 'black' }}
										color="gray.3"
										type="button"
										leftIcon={<Icon name="archive" />}
										onClick={() => navigate('/configuration/outlets/archived')}
									>
										Archived
									</Button>
								</RequirePermissions>
								<RequirePermissions permissions={['Outlets.Create']}>
									<Button
										type="button"
										leftIcon={<Icon name="add" />}
										onClick={() => navigate('/configuration/outlets/create')}
									>
										Create Outlets
									</Button>
								</RequirePermissions>
							</Stack>
						</Box>
					</>
				)}
			</Box>

			<SimpleDataGrid
				data={response?.outlets || []}
				// columnVisibility={{ id: false }}
				columns={[
					{ accessorKey: 'name', header: 'Name' },
					{ accessorKey: 'id', header: 'Outlet ID' },
					{
						accessorKey: 'updated_at',
						header: 'Last Updated',
						cell: (row: any) =>
							dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
					},
				]}
				onClickHandler={getSelectedRow}
			/>
			{response_brands?.brands?.length === 0 ? (
				<>
					<EmptyState
						resource="brands"
						secondaryMessage="Please create Brand before creating Outlet."
						CallToAction={
							<Button
								type="button"
								leftIcon={<Add fontSize="inherit" />}
								onClick={() => navigate('/configuration/brands/create')}
								compact
							>
								Create Brand
							</Button>
						}
					/>
				</>
			) : response?.outlets?.length === 0 ? (
				<EmptyState resource="outlets" />
			) : null}
		</ViewContainer>
	);
};

export default Outlets;

import React from 'react';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from 'react-router-dom';
import App from '../App';

import { configurationRoutes } from './routes/configurationRoutes';
import { loginRoutes } from './routes/loginRoutes';
import { errorRoutes } from './routes/errorRoutes';
import { productsRoutes } from './routes/productsRoutes';
import { reportsRoutes } from './routes/reportsRoutes';
import { integrationRoutes } from './routes/integrationRoutes';
import PasswordRecovery from 'views/Public/PasswordRecovery';
import PasswordReset from 'views/Public/PasswordReset';
import AccountActivation from 'views/Public/AccountActivation';
import Public from 'views/Public';
import Home from 'views/Home';
import { managementRoutes } from './routes/managementRoutes';
import { permissionRoutes } from './routes/permissionsRoutes';
import Login from '../views/Login';
import Company from '../views/Public/Company';
import { Loader, LoadingOverlay } from '@mantine/core';
import Redirect from '../views/Redirect';
import ThemeProvider from '../providers/ThemeProvider';

const router = createBrowserRouter([
	{
		path: '/password',
		element: <Public />,
		children: [
			{
				path: 'recovery',
				element: <PasswordRecovery />,
			},
			{
				path: 'reset',
				element: <PasswordReset />,
			},
			{
				path: 'activate',
				element: <AccountActivation />,
			},
		],
	},
	{
		path: '/login',
		element: <Public />,
		children: [{ path: '', element: <Login /> }],
	},
	{
		path: '/company',
		element: (
			<ThemeProvider>
				<Company />
			</ThemeProvider>
		),
	},
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '',
				element: <Navigate to="/login" />,
			},
			{
				path: '/dashboard',
				element: <Home />,
			},
			{
				path: '/redirect',
				element: <Redirect />,
			},
			// ...loginRoutes,
			...errorRoutes,
			...productsRoutes,
			...configurationRoutes,
			...reportsRoutes,
			...managementRoutes,
			...permissionRoutes,
			{
				path: '*',
				element: <Navigate to="/404" />,
			},
			// ...integrationRoutes
		],
	},
]);

const Router = () => {
	return <RouterProvider router={router} />;
};

export default Router;

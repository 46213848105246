import { Box, Button, Group, Title } from '@mantine/core';
import React from 'react';
import { Formik, MultiSelectField } from 'components';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useCopyProducts } from 'hooks/api/categorygroups/useCopyProducts';
import { useGetCategories } from 'hooks';
import { useAppState } from 'providers/AppStateProvider';
import { useCategoriesContext } from '../context';

type Props = {
	refetch: () => void;
	modalId: string;
	productIds: number[];
};

const CopyProducts = ({ refetch, modalId, productIds }: Props) => {
	const { mutate, isLoading } = useCopyProducts();
	const { selectedBrand } = useAppState();
	const { selectedProducts } = useCategoriesContext();

	const { data: categories } = useGetCategories({
		brand_id: selectedBrand!,
		dropdown: 'yes',
	});

	return (
		<Formik
			initialValues={{
				product_ids: productIds,
				category_ids: [],
			}}
			onSubmit={(value) =>
				mutate(value, {
					onSuccess: () => {
						refetch();
						modals.close(modalId);
						notifications.show({
							message: `Products has been successfully duplicated`,
						});
					},
				})
			}
			enableReinitialize
		>
			{({ values, submitForm, setFieldValue }) => {
				return (
					<Box p="lg">
						<Title>Copy products to each selected category</Title>
						<Box mt="xl" mb="160px">
							<MultiSelectField
								searchable
								dropdownPosition="bottom"
								label="Select categories"
								placeholder="Select categories"
								data={
									categories?.categories?.map(({ id, name }) => ({
										value: String(id),
										label: name,
									})) || []
								}
								onChange={(values) => {
									setFieldValue(
										'category_ids',
										values.map((e) => +e)
									);
								}}
							/>
						</Box>
						<Group position="right">
							<Button
								size="md"
								color="gray"
								onClick={() => modals.close(modalId)}
							>
								Cancel
							</Button>
							<Button
								size="md"
								disabled={
									!values?.category_ids?.length ||
									!values?.product_ids?.length ||
									isLoading
								}
								onClick={() => submitForm()}
							>
								Copy Products
							</Button>
						</Group>
					</Box>
				);
			}}
		</Formik>
	);
};

export default CopyProducts;

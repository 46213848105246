import React from 'react';
import { Button, Div, Image, Stack, Text } from '../components';
import error403 from 'assets/images/error-403.jpg';
import { useAppState } from '../providers/AppStateProvider';
import { useNavigate } from '../lib/navigate';

const Error403 = () => {
	const { setIsSidebarHidden: set } = useAppState();
	const navigate = useNavigate();

	React.useEffect(() => {
		set(true);
		return () => set(false);
	}, []);

	return (
		<Div
			style={{
				display: 'grid',
				height: 'calc(100vh - 62px)',
				width: '100%',
				placeItems: 'center',
			}}
		>
			<Stack direction="column" gap={20} align="center">
				<Image src={error403} alt="500 Server Error" width="724px" />
				<Text weight={600} size="24px">
					Access Forbidden
				</Text>
				<Text size="16px">
					We are sorry, but you do not have access to this page.
				</Text>
				<Button onClick={() => navigate('/')}>Go to Dashboard</Button>
			</Stack>
		</Div>
	);
};

export default Error403;

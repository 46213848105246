import {
	ActionIcon,
	Box,
	Grid,
	Group,
	Stack,
	Tabs,
	Text,
	Tooltip,
} from '@mantine/core';
import React from 'react';
import Icon from '../../../assets/icons';
import {
	Breadcrumbs,
	CheckBoxGrid,
	TextField,
	ViewContainer,
} from '../../../components';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useNavigate } from '../../../lib/navigate';
import { PermissionRoutes } from '../../../router/constants/Permissions.routes';

const SuperadminView = () => {
	useDisableSelectCompany();
	const navigate = useNavigate();
	return (
		<ViewContainer
			title={
				<Group position="apart">
					<Breadcrumbs
						items={[
							{
								label: 'Roles',
								onClick: () => navigate(PermissionRoutes.Roles),
							},
							{ label: 'Superadmin' },
						]}
					/>
				</Group>
			}
		>
			<Grid align="center" my="40px">
				<Grid.Col md={12} lg={8}>
					<Box>
						<TextField
							name="name"
							label="Role Name"
							required
							value="Super Administrator"
							disabled
						/>
					</Box>
				</Grid.Col>
			</Grid>
			<Tabs defaultValue="dashboard">
				<Tabs.List>
					<Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
					<Tabs.Tab value="terminal">Terminal</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="dashboard" py="xl">
					<Stack spacing="lg">
						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="Products"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Menu', value: 'Menu' },
								{ label: 'Categories', value: 'Categories' },
								{ label: 'Category Groups', value: 'CategoryGroups' },
								{ label: 'Option Groups', value: 'OptionGroups' },
								{ label: 'Kitchen Groups', value: 'KitchenGroups' },
							]}
						/>
						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="Configuration"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Companies', value: 'Companies' },
								{ label: 'Brands', value: 'Brands' },
								{ label: 'Outlets', value: 'Outlets' },
							]}
						/>
						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="Point of Sales"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Point of Sales List', value: 'PosList' },
								{
									label: 'Point of Sales Configuration',
									value: 'PosConfig',
								},
								{ label: 'Sync to POS', value: 'PosSync' },
								{ label: 'Orders', value: 'Orders' },
								{ label: 'Closings', value: 'Closings' },
							]}
							omit={[
								'PosList.Create',
								'PosList.Edit',
								'PosList.Archive',
								'PosList.Delete',
								'PosConfig.Archive',
								'PosSync.Create',
								'PosSync.Archive',
								'PosSync.Delete',
								'Orders.Create',
								'Orders.Archive',
								'Orders.Delete',
								'Closings.Create',
								'Closings.Delete',
								'Closings.Archive',
							]}
						/>
						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="Payment"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Payment methods', value: 'PaymentMethods' },
								{
									label: 'Discounts',
									value: 'Discounts',
								},
							]}
							omit={['PaymentMethods.Archive', 'Discounts.Archive']}
						/>

						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="Permissions"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Users', value: 'Users' },
								{ label: 'Roles', value: 'Roles' },
							]}
							omit={['Users.Archive', 'Roles.Archive']}
						/>

						<CheckBoxGrid
							selectAll
							disabled
							enableReinitialize
							title="FS Integration"
							headers={[
								{ label: 'Create', value: 'Create' },
								{ label: 'Edit', value: 'Edit' },
								{ label: 'Archive', value: 'Archive' },
								{ label: 'Delete', value: 'Delete' },
								{ label: 'View', value: 'View' },
							]}
							rows={[{ label: 'BC Integration', value: 'BcIntegration' }]}
							omit={[
								'BcIntegration.Create',
								'BcIntegration.Archive',
								'BcIntegration.Delete',
							]}
						/>
						<Box>
							<CheckBoxGrid
								selectAll
								disabled
								enableReinitialize
								title="Reports"
								headers={[
									{ label: 'Download', value: 'Download' },
									{ label: 'View', value: 'View' },
								]}
								rows={[
									{ label: 'Finance', value: 'Finance' },
									{ label: 'Operations', value: 'Operations' },
								]}
							/>
						</Box>
						<Box>
							<CheckBoxGrid
								selectAll
								disabled
								enableReinitialize
								title="Reports"
								headers={[{ label: 'View', value: 'View' }]}
								rows={[{ label: 'Analytics', value: 'Analytics' }]}
							/>
						</Box>
					</Stack>
				</Tabs.Panel>
				<Tabs.Panel value="terminal" py="xl">
					<CheckBoxGrid
						selectAll
						disabled
						enableReinitialize
						initialValues={['Terminal.Full']}
						headers={[
							{
								label: (
									<Group spacing={0}>
										<Text size="12px" weight={500} align="center" color="gray">
											BASIC
										</Text>
										<Tooltip
											w="200px"
											multiline
											label={
												<small>
													User can access all functional features of Terminal
													except manager functions. Manager pin and password is
													mandatory for access to manager functions
												</small>
											}
										>
											<ActionIcon variant="transparent">
												<Icon name="info" />
											</ActionIcon>
										</Tooltip>
									</Group>
								),
								value: 'Basic',
							},
							{
								label: (
									<Group spacing={0}>
										<Text size="12px" weight={500} align="center" color="gray">
											FULL
										</Text>
										<Tooltip
											w="200px"
											multiline
											label={
												<small>
													User can access all functional features of Terminal
													including manager functions. Manager pin and password
													is not required for access to manager functions.
												</small>
											}
										>
											<ActionIcon variant="transparent">
												<Icon name="info" />
											</ActionIcon>
										</Tooltip>
									</Group>
								),
								value: 'Full',
							},
						]}
						rows={[{ label: 'Access', value: 'Terminal' }]}
					/>
				</Tabs.Panel>
			</Tabs>
		</ViewContainer>
	);
};

export default SuperadminView;

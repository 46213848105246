import React from 'react';
import {
	Box,
	Input,
	Tooltip,
	ActionIcon,
	SelectProps,
	Select,
	InputLabelProps,
	BoxProps,
} from '@mantine/core';
import Icon from '../assets/icons';

export type SelectFieldProps = {
	label?: React.ReactNode;
	labelWidth?: number;
	labelProps?: InputLabelProps;
	wrapperProps?: BoxProps;
	width?:number;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
	bottomSection?: React.ReactNode;
} & SelectProps;

export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
	(
		{
			label,
			error,
			hint,
			required,
			labelProps,
			labelWidth,
			wrapperProps,
			bottomSection,
			width,
			...rest
		},
		ref
	) => {
		return (
			<Box
				ref={ref}
				sx={{
					display: 'grid',
					gridTemplateColumns: `${labelWidth || 240}px ${width+'px' || '1fr'}`,
					alignItems: 'flex-start',
				}}
				{...wrapperProps}
			>
				<div>
					<Input.Label
						style={{
							padding:"16px 0",
							display:"inline-block"
						}} 
						required={required} 
						{...labelProps}>
						{label}
					</Input.Label>
					{!hint ? null : (
						<Tooltip label={hint} position="bottom-start">
							<ActionIcon
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon name="info" />
							</ActionIcon>
						</Tooltip>
					)}
				</div>
				<Box>
					<Select
						error={error}
						width={width}
						{...rest}
						styles={{
							input: {
								padding: '27px 18px',
							},
						}}
					/>
					{error ? (
						<Input.Error my="10px">This field is required</Input.Error>
					) : null}
					{!bottomSection ? null : bottomSection}
				</Box>
			</Box>
		);
	}
);

SelectField.displayName = 'SelectField';

import styled from '@emotion/styled';
import { theme } from '../../providers/ThemeProvider/index';
import { InputProps } from './Input';

const readOnlyStyles = `
  border: 1px solid transparent;
  pointer-events: none;
`;

const invalidStyles = `
  color: ${theme.colors.red};
  border: 1px solid ${theme.colors.red};
`;

const validStyles = `
  color: ${theme.colors.green};
  border: 1px solid ${theme.colors.green};
`;

export const StyledInput = styled.input<InputProps>`
	border: 1px solid ${({ theme }) => theme.colors.grey10};
	border-radius: 4px;
	padding: 16.5px 18px;
	padding-left: ${({ leftIcon }) => leftIcon && '45px'};
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.font.sansSerif};
	width: 100%;

	::placeholder {
		font-family: ${({ theme }) => theme.font.sansSerif};
	}

	:focus {
		outline: none;
		background-color: ${({ theme }) => theme.colors.grey05};
	}

	:disabled {
		color: ${({ theme }) => theme.colors.grey50};
	}

	/* Input invalid styles */
	${({ isInvalid }) => isInvalid && invalidStyles};

	/* Input valid styles */
	${({ isValid }) => isValid && validStyles};

	/* Input valid styles */
	${({ isReadOnly }) => isReadOnly && readOnlyStyles}

	/* Input background color */
	${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
`;

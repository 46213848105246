
import { useQuery} from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';

export function useGetLinkedGrabCategories(id: number) {
	const { getAccessToken } = useAuth();
	const  getLinks = async() => {

		const response = await configApi.get(`selling-times/${id}/categories`,{
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	}

	return useQuery(['selling_times_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})

}
import { useQuery } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetUsers({
	companyId,
	searchKeyword,
	isActive,
}: {
	companyId: string | number;
	searchKeyword?: string;
	isActive?: 'yes' | 'no';
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['users', companyId, searchKeyword, isActive],
		async () => {
			const params: Record<string, any> = {
				company_id: companyId,
			};

			if (isActive) {
				params.is_active = isActive;
			}
			if (searchKeyword) {
				params.search_keyword = searchKeyword;
			}

			return authApi
				.get('/users', {
					params,
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data);
		},
		{
			enabled: !!companyId,
		}
	);
}

import axios from 'axios';

export const apiRoutes: Record<
	| 'configApi'
	| 'authApi'
	| 'reportAnalyticApi'
	| 'txReadApi'
	| 'txDumpApi'
	| 'socketApi',
	Record<string, string>
> = {
	configApi: {
		local: 'http://localhost:20003/api/v1',
		develop: 'https://dev-menu-config.a5tec.com.sg/api/v1',
		staging: 'https://staging-menu-config.a5tec.com.sg/api/v1',
		production: 'https://menu-config.a5tec.com.sg/api/v1',
	},
	authApi: {
		local: 'http://localhost:20000/api/v1',
		develop: 'https://dev-auth.a5tec.com.sg/api/v1',
		staging: 'https://staging-auth.a5tec.com.sg/api/v1',
		production: 'https://auth.a5tec.com.sg/api/v1',
	},
	txReadApi: {
		local: 'http://localhost:20002/api/v1',
		develop: 'https://dev-read.a5tec.com.sg/api/v1',
		staging: 'https://staging-read.a5tec.com.sg/api/v1',
		production: 'https://read.a5tec.com.sg/api/v1',
	},
	txDumpApi: {
		local: 'http://localhost:20001/api/v1',
		develop: 'https://dev-dump.a5tec.com.sg/api/v1',
		staging: 'https://staging-dump.a5tec.com.sg/api/v1',
		production: 'https://dump.a5tec.com.sg/api/v1',
	},
	reportAnalyticApi: {
		local: 'http://localhost:20005/api/v1',
		develop: 'https://dev-report-analytics.a5tec.com.sg/api/v1',
		staging: 'https://staging-report-analytics.a5tec.com.sg/api/v1',
		production: 'https://report-analytics.a5tec.com.sg/api/v1',
	},
	socketApi: {
		local: 'https://dev-socket.a5tec.com.sg/',
		develop: 'https://dev-socket.a5tec.com.sg/',
		staging: 'https://staging-socket.a5tec.com.sg/',
		production: 'https://socket.a5tec.com.sg/',
	},
};

export const authApi = axios.create({
	baseURL: apiRoutes.authApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Credentials': true,
		'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type',
		'Access-Control-Allow-Methods':
			'GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS',
	},
});

export const configApi = axios.create({
	baseURL: apiRoutes.configApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});

export const txReadApi = axios.create({
	baseURL: apiRoutes.txReadApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});

export const txDumpApi = axios.create({
	baseURL: apiRoutes.txDumpApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
});

export const socketApi = axios.create({
	baseURL: `${
		apiRoutes.socketApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local']
	}api/v1`,
});

export const reportAnalyticApi = axios.create({
	baseURL:
		apiRoutes.reportAnalyticApi[
			process.env.REACT_APP_DEPLOYMENT_ENV || 'local'
		],
});

import React from 'react';
import { useAppState } from 'providers/AppStateProvider';
import { useGetOutlets } from 'hooks/outlets';
import { useGetPos } from 'hooks/api/pos/useGetPos';
import {
	Breadcrumbs,
	Div,
	SelectBrand,
	Text,
	Stack,
	ViewContainer,
} from 'components';
import { Select } from '@mantine/core';
import { SelectOutlet } from 'components/SelectOutlet';
import { BarChart } from './Charts/BarChart';
import dayjs from 'dayjs';

import { useGetHourlySales } from 'hooks/api/analytics/useGetHourlySales';
import { useGetDailySales } from 'hooks/api/analytics/useGetDailySales';

const Analytics = () => {
	const [selectedOutlet, setSelectedOutlet] = React.useState<number | string>(
		'all'
	);
	const [selectedPOS, setSelectedPOS] = React.useState<number | string | null>(
		'all'
	);

	const { selectedBrand } = useAppState();

	const [selectedParams, setSelectedParams] = React.useState<{
		type: string;
		id: string | number | null;
	}>({
		type: 'brand',
		id: selectedBrand,
	});

	const { data: response_outlets } = useGetOutlets(selectedBrand ?? 0);
	const { data: response_pos } = useGetPos({
		brandId: selectedBrand || 0,
		outletId: selectedOutlet === 'all' ? null : selectedOutlet,
	});

	const { data: data_hourly_sales } = useGetHourlySales(selectedParams);
	const { data: data_daily_sales } = useGetDailySales(selectedParams);

	return (
		<ViewContainer>
			<Breadcrumbs
				items={[
					{
						label: 'Analytics',
						onClick: () => null,
					},
				]}
			/>
			<Stack>
				<SelectBrand
					onChangeHandler={(value) => {
						if (value) {
							if (value) {
								setSelectedParams({ type: 'brand', id: +value });
								setSelectedOutlet('all');
								setSelectedPOS('all');
							}
						}
					}}
				/>
				<Select
					miw="350px"
					data={[
						{ value: 'all', label: 'All Outlets' },
						...(response_outlets?.outlets?.map((e: any) => ({
							value: String(e?.id),
							label: e?.name,
						})) ??
							([] || [])),
					]}
					value={selectedOutlet?.toString()}
					onChange={(value) => {
						if (value) {
							if (value === 'all') {
								setSelectedParams({ type: 'brand', id: selectedBrand });
								setSelectedOutlet('all');
							} else {
								setSelectedParams({ type: 'outlet', id: +value });
								setSelectedOutlet(+value);
							}
						}
						setSelectedPOS('all');
					}}
				/>
				<Select
					miw="350px"
					data={[
						{ value: 'all', label: 'All Point of Sales' },
						...(response_pos?.pos?.map((e: any) => ({
							value: String(e?.id),
							label: e?.name,
						})) ??
							([] || [])),
					]}
					value={selectedPOS?.toString()}
					onChange={(value) => {
						if (value) {
							if (value === 'all') {
								setSelectedPOS('all');
								if (selectedOutlet) {
									setSelectedParams({ type: 'outlet', id: selectedOutlet });
								} else {
									setSelectedParams({ type: 'brand', id: selectedBrand });
								}
							} else {
								setSelectedPOS(+value);
								setSelectedParams({ type: 'terminal', id: +value });
							}
						}
					}}
				/>
			</Stack>

			<Div style={{ width: '100%', marginTop: '20px' }}>
				<Text size={'20px'} weight={600}>
					Hourly Sales
				</Text>

				<BarChart
					labels={
						data_hourly_sales?.sales_list?.map((e: any) =>
							dayjs(e.date).format('DD/MM hh A')
						) ?? []
					}
					data={data_hourly_sales?.sales_list?.map((e: any) => e.sales) ?? []}
					type="Hourly"
				/>
			</Div>

			<Div style={{ width: '100%', marginTop: '20px' }}>
				<Text size={'20px'} weight={600}>
					Daily Sales
				</Text>

				<BarChart
					labels={
						data_daily_sales?.sales_list?.map((e: any) =>
							dayjs(e.date).format('DD MMM YYYY')
						) ?? []
					}
					data={data_daily_sales?.sales_list?.map((e: any) => e.sales) ?? []}
					type="Daily"
				/>
			</Div>
		</ViewContainer>
	);
};

export default Analytics;

import { useDebouncedValue } from '@mantine/hooks';
import React from 'react';
import Icon from '../../../../assets/icons';
import {
	Button,
	Menu,
	FormTextInput,
	useFormikContext,
} from '../../../../components';
import { Paper } from '../../../../components/Paper';
import { useGetCategories } from '../../../../hooks';
import { useAppState } from '../../../../providers/AppStateProvider';

const AddCategories = () => {
	const { selectedBrand } = useAppState();
	const { setFieldValue, values } = useFormikContext<any>();
	const { data: categories } = useGetCategories({ brand_id: selectedBrand! });

	const [search, setSearch] = React.useState<string>('');

	const [_search] = useDebouncedValue(search, 500);

	const _selectedCategories = React.useMemo(() => {
		const categoriesSet: Set<number> = new Set(values?._categories);
		if (values?._categoryGroups) {
			Object.values(values?._categoryGroups).forEach((e) => {
				(e as number[]).forEach((f) => categoriesSet.add(f));
			});
		}
		return Array.from(categoriesSet);
	}, [values]);

	const _categoriesList = React.useMemo(() => {
		if (_search.length) {
			return categories?.categories
				.filter((e) => !_selectedCategories?.includes(e.id))
				.filter((e) => e.name?.toLowerCase()?.includes(_search?.toLowerCase()));
		}

		return categories?.categories?.filter(
			(e) => !_selectedCategories?.includes(e.id)
		);
	}, [categories, _search, _selectedCategories]);

	return (
		<Menu width={415} offset={-50} position="bottom-start">
			<Menu.Target>
				<Button compact variant="light" leftIcon={<Icon name="add" />}>
					Add
				</Button>
			</Menu.Target>
			<Menu.Dropdown
				style={{
					border: 0,
					boxShadow: 'none',
					background: 'transparent',
				}}
			>
				<FormTextInput
					value={search}
					onChange={(e) => setSearch(e.currentTarget.value)}
					placeholder="Search..."
					isClearable
				/>
				<Paper style={{ height: '400px', overflow: 'scroll' }}>
					{_categoriesList?.map((e) => (
						<Menu.Item
							key={e.id}
							onClick={() => {
								if (e.category_group_id) {
									setFieldValue(`_categoryGroups.${e.category_group_id}`, [
										...(values[`_categoryGroups`][e.category_group_id] || []),
										e.id,
									]);
								} else {
									setFieldValue('_categories', [...values._categories, e.id]);
								}
							}}
						>
							{e.name}
						</Menu.Item>
					))}
				</Paper>
			</Menu.Dropdown>
		</Menu>
	);
};

export default AddCategories;

import React from 'react';

export function useRedirectWhenRefresh() {
	React.useEffect(() => {
		localStorage.setItem('otopos-redirect-refresh', 'yes');
		return () => {
			localStorage.setItem('otopos-redirect-refresh', 'no');
		};
	}, []);
}

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { TCategoryGroupSchema } from '../../../schemas/categorygroup.schema';

export function useGetCategoryGroupById({
	categoryGroupId,
}: {
	categoryGroupId: string | number;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['category-groups', categoryGroupId],
		async (): Promise<TCategoryGroupSchema & { payment_methods: number[] }> =>
			configApi
				.get(`categorygroups/${categoryGroupId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!categoryGroupId,
		}
	);
}

import { Select, SelectItem, SelectProps } from '@mantine/core';
import React from 'react';
import { useGetBrands } from '../../hooks/brands';
import { useAppState } from '../../providers/AppStateProvider';

export type SelectBrandProps = {
	isDisabled?: boolean;
	useData?: (data: SelectItem[]) => void;
	onChangeHandler?: (value: string) => void;
} & Omit<SelectProps, 'data'>;

export const SelectBrand = React.forwardRef<HTMLInputElement, SelectBrandProps>(
	({ isDisabled, disabled, useData, onChangeHandler, ...rest }, ref) => {
		const { selectedCompany, setBrand, selectedBrand } = useAppState();
		const { data } = useGetBrands(selectedCompany!);

		const _options: { value: string; label: string }[] = React.useMemo(() => {
			return data?.brands?.map((e: any) => ({
				value: e.id.toString(),
				label: e.name,
			}));
		}, [data?.brands]);

		React.useEffect(() => {
			if (useData) {
				useData(_options);
			}
		}, [_options]);

		return (
			<Select
				miw="192px"
				maw="300px"
				disabled={isDisabled || disabled}
				ref={ref}
				{...rest}
				placeholder="Select Brand"
				onChange={(value) => {
					if (value) {
						if (onChangeHandler) {
							onChangeHandler(value);
						}
						setBrand(+value);
					}
				}}
				value={selectedBrand?.toString()}
				data={_options ?? []}
			/>
		);
	}
);

SelectBrand.displayName = 'SelectBrand';

export const useSelectBrand = () => {
	const { selectedCompany } = useAppState();
	const { data } = useGetBrands(selectedCompany!);

	const brands: { value: string; label: string }[] = React.useMemo(() => {
		return data?.brands?.map((e: any) => ({
			value: e.id.toString(),
			label: e.name,
		}));
	}, [data?.brands]);

	const hasBrands = !!brands?.length;

	return { brands, data, hasBrands };
};

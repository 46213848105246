import { z } from 'zod';
import { BaseSchema } from './base.schema';

export const CompanySchema = BaseSchema.extend({
	name: z
		.string()
		.max(80, 'Only a maximum of 80 characters is allowed')
		.min(1, 'This field is required.'),
	bc_name: z
		.string().min(1, 'This field is required.')
		.max(80, 'Only a maximum of 80 characters is allowed'),
	uen: z
		.string().regex(
			new RegExp('^[a-zA-Z0-9]*$'),
			'This field can only contain letters and numbers'
		)
		.max(30, 'Only a maximum of 30 characters is allowed')
		.min(1, 'This field is required.'),
	gst_number: z
		.string()
		.regex(
			new RegExp('^[a-zA-Z0-9]*$'),
			'This field can only contain letters and numbers'
		)
		.max(30, 'Only a maximum of 30 characters is allowed').optional(),
		client_id: z
		.string()
		.max(60, 'Only a maximum of 60 characters is allowed'),
		client_secret: z
		.string()
		.max(60, 'Only a maximum of 60 characters is allowed'),
	updated_by: z.string()
})

export type CompanyInput = z.infer<typeof CompanySchema>;

export const CompanyCreationSchema = CompanySchema.pick({
    name: true,
    uen: true,
    gst_number: true,
	client_id: true,
	client_secret: true,
	updated_by: true
})

export type CompanyCreationInput = z.infer<typeof CompanyCreationSchema>

export const CompanyUpdateSchema = CompanySchema.pick({
    name: true,
    uen: true,
    gst_number: true,
	client_id: true,
	client_secret: true,
	updated_by: true
})

export type CompanyUpdateInput =  z.infer<typeof CompanyUpdateSchema>

export const CompanyUpdateSchemaWithBC = CompanySchema.pick({
    name: true,
	bc_name: true,
    uen: true,
    gst_number: true,
	client_id: true,
	client_secret: true,
	updated_by: true
})
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../assets/icons';
import { Chip, DataGrid, EmptyState, Spinner, Text } from '../../../components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { useGetClosings } from '../../../hooks/api/closings/useGetClosings';
import { useGetPos } from '../../../hooks/api/pos/useGetPos';
import { useGetPosById } from '../../../hooks/api/pos/useGetPosById';
import { useAppState } from '../../../providers/AppStateProvider';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';

const Closing = ({ outlet_id }: { outlet_id: number | null }) => {
	const { selectedBrand } = useAppState();
	const { data: posData } = useGetPos({
		brandId: selectedBrand!,
	});
	const { data: closingsData, isFetching: isFetchingClosingData } =
		useGetClosings({
			limit: '30',
			outlet_id: outlet_id?.toString() ?? '',
			pos_ids: posData?.pos?.map((e: any) => e.id).join(','),
		});
	const navigate = useNavigate();

	return !closingsData?.closings.length ? (
		<EmptyState resource="closings" />
	) : (
		<>
			{!isFetchingClosingData ? (
				<SimpleDataGrid
					my="lg"
					data={closingsData?.closings}
					columnVisibility={{
						id: false,
					}}
					columns={[
						{
							header: 'Point of Sale',
							accessorKey: 'pos_id',
							cell: ({ getValue }) => {
								const { data: pos } = useGetPosById(
									(getValue() as number).toString()
								);
								return `${pos?.outlet_name} - ${pos?.name}`;
							},
						},
						{
							accessorKey: 'id',
						},
						{
							accessorKey: 'status',
							header: 'Status',
							cell: ({ getValue }) => {
								const status = getValue<string>();
								const isClosed = status === 'closed';
								return (
									<Chip
										leftIcon={<Icon name={isClosed ? 'cancel' : 'checkmark'} />}
										color={isClosed ? 'red' : 'green'}
									>
										{isClosed ? 'Closed' : 'Opened'}
									</Chip>
								);
							},
						},
						{
							header: 'Opening Date',
							accessorKey: 'session_start',
							cell: ({ getValue }) => {
								const date = getValue() as string;
								return dayjs(date).format('DD/MM/YYYY h:mm:ss A');
							},
						},
						{
							header: 'Closing Date',
							accessorKey: 'session_end',
							cell: ({ getValue }) => {
								const date = getValue() as string;
								return !date ? '-' : dayjs(date).format('DD/MM/YYYY h:mm:ss A');
							},
						},
					]}
					onClickHandler={(row) =>
						navigate(
							ConfigurationRoutes.ViewClosing.replace(
								':closingUid',
								row?.original?.uid
							)
						)
					}
				/>
			) : (
				<Spinner />
			)}
		</>
	);
};

export default Closing;

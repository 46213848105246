import styled from '@emotion/styled';
import { FormCheckBoxProps } from './FormCheckBox';

export const StyledFormCheckBox = styled.input<FormCheckBoxProps>`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	border-radius: 4px;
	width: 0px;
	height: 22px;

	:checked ~ span[data-label='styled-checkmark'] {
		background-color: ${({ theme }) => theme.colors.secondary};
		border: 1.5px solid ${({ theme }) => theme.colors.secondary};
	}

	:checked ~ span[data-label='styled-checkmark']::after {
		display: block;
	}

	${({ isDeterminate, theme }) =>
		isDeterminate &&
		`
~ span[data-label='styled-indeterminatemark']::after {
  display: block;
}

~ span[data-label='styled-indeterminatemark'] {
  background-color: ${theme.colors.secondary};
  border: 1.5px solid ${theme.colors.secondary};
}
`}

	:indeterminate ~ span[data-label='styled-indeterminatemark']::after {
		display: block;
	}

	:indeterminate ~ span[data-label='styled-indeterminatemark'] {
		background-color: ${({ theme }) => theme.colors.grey30};
		border: 1.5px solid ${({ theme }) => theme.colors.grey30};
	}
`;

export const StyledCheckmark = styled.span<{ isDisabled: boolean }>`
	position: absolute;
	border-radius: 4px;
	height: 22px;
	width: 22px;
	border: 1.5px solid
		${({ theme, isDisabled }) =>
			isDisabled ? theme.colors.grey10 : theme.colors.grey30};

	:after {
		content: '';
		position: absolute;
		display: none;
		left: 5px;
		top: 1px;
		width: 6px;
		height: 12px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

export const StyledIndeterminateMark = styled.span`
	position: absolute;
	border-radius: 4px;
	height: 22px;
	width: 22px;

	:after {
		content: '';
		position: absolute;
		display: none;
		top: 9px;
		left: 3px;
		width: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
	}
`;

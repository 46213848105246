import { Add } from '@mui/icons-material';
import { Row } from '@tanstack/react-table';
import {
	ActionIcon,
	Button,
	Collapse,
	Div,
	EmptyState,
	FormCheckBox,
	FormMultiSelect,
	Menu,
	Select,
	SelectBrand,
	Stack,
	Tabs,
	Text,
	Tooltip,
	ViewContainer,
} from 'components';
import { SimpleDataGrid } from '../../components/SimpleDataGrid';
import dayjs from 'dayjs';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../../assets/icons';
import { useGetPaymentMethodsByBrandId } from '../../hooks/api/paymentmethods/useGetPaymentMethods';
import { useGetPos } from '../../hooks/api/pos/useGetPos';
import { useGetBrands } from 'hooks/brands';
import { useGetOutlets } from '../../hooks/outlets';
import { useSyncPos } from '../../hooks/socket/useSyncPos';
import { useAppState } from '../../providers/AppStateProvider';
import { useModals } from '../../providers/ModalsProvider';
import DiscountsTooltip from './PointOfSale/DiscountsTooltip';
import Orders from './PointOfSale/Orders';
import PaymentTypes from './PointOfSale/PaymentTypes';
import StatusChip from './PointOfSale/StatusChip';
import PosSync from './PosSync';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Closing from './PointOfSale/Closing';
import { useAuth } from 'providers/AuthProvider';
import RequirePermissions from '../../guards/RequirePermissions';
import axios from 'axios';
import { useNotification } from 'providers/NotificationProvider';
import { configApi } from 'apis';

const PointOfSale = () => {
	const [params] = useSearchParams();
	const tabParam = params.get('tab');
	const [selectedTab, setSelectedTab] = React.useState<string>(
		tabParam ?? 'settings'
	);
	const [selectedPage, setSelectedPage] = React.useState<number>(1);

	const [searchParams, setSearchParams] = React.useState<{
		outlet_id?: number;
		payment_methods?: number[];
	}>({
		outlet_id: undefined,
		payment_methods: undefined,
	});

	const { selectedCompany, selectedBrand } = useAppState();
	const { data: pos } = useGetPos({
		brandId: selectedBrand || 0,
		outletId: searchParams?.outlet_id,
		paymentMethods: searchParams?.payment_methods,
		requestedPage: selectedPage,
	});
	const { showModal } = useModals();
	const { mutate: syncPos } = useSyncPos();
	const { data: paymentMethodsData } = useGetPaymentMethodsByBrandId({
		brandId: selectedBrand?.toString() || '0',
		isFiltered: false,
		discount_id: null,
	});
	const { data: response_brands } = useGetBrands(selectedCompany || 0);
	const { data: response_outlets } = useGetOutlets(selectedBrand || 0);

	const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

	const navigate = useNavigate();
	const toast = useNotification();

	const getSelectedRow = (row: any) => {
		navigate(`/configuration/pos/${row.original.id}`, { replace: true });
	};

	const { accessToken } = useAuth();

	return (
		<ViewContainer title="Point of Sale">
			<Stack align="center" style={{ margin: '20px 0px' }}>
				<SelectBrand
					onChangeHandler={() => {
						setSearchParams((p) => ({
							...p,
							outlet_id: undefined,
						}));
					}}
				/>
				{/* Select outlet: */}
				<Select
					w="400px"
					data={[{ value: '[]', label: 'All Outlets' }].concat(
						response_outlets?.outlets?.map((e: any) => ({
							value: e.id.toString(),
							label: e.name,
						})) ?? []
					)}
					value={String(searchParams.outlet_id || '[]')}
					onChange={(value) => {
						if (value === '[]') {
							setSearchParams((p) => ({
								...p,
								outlet_id: undefined,
							}));
						} else if (value) {
							setSearchParams((p) => ({
								...p,
								outlet_id: +value,
							}));
						}
					}}
				/>
				<RequirePermissions permissions={['PosConfig.Create']}>
					{response_brands?.brands?.length ? (
						<Button
							style={{ position: 'absolute', right: '1vw' }}
							type="button"
							leftIcon={<Add fontSize="inherit" />}
							onClick={() => navigate('/configuration/pos/create')}
						>
							Create POS
						</Button>
					) : null}
				</RequirePermissions>
			</Stack>
			{response_brands?.brands?.length === 0 ? (
				<EmptyState
					resource="Point Of Sales"
					secondaryMessage="Please create Brand and Outlet before creating POS."
					CallToAction={
						<RequirePermissions permissions={['Brands.Create']}>
							<Button
								type="button"
								leftIcon={<Add fontSize="inherit" />}
								onClick={() => navigate('/configuration/brands/create')}
								size="md"
							>
								Create Brand
							</Button>
						</RequirePermissions>
					}
				/>
			) : response_outlets?.outlets?.length === 0 ? (
				<EmptyState
					resource="Point Of Sales"
					secondaryMessage="Please create Outlet before creating POS."
					CallToAction={
						<RequirePermissions permissions={['Outlets.Create']}>
							<Button
								type="button"
								leftIcon={<Add fontSize="inherit" />}
								onClick={() => navigate('/configuration/outlets/create')}
								size="md"
							>
								Create Outlets
							</Button>
						</RequirePermissions>
					}
				/>
			) : (
				<Tabs defaultValue={selectedTab}>
					<Tabs.List>
						<RequirePermissions permissions={['PosConfig.View']}>
							<Tabs.Tab
								onClick={() => setSelectedTab('settings')}
								value="settings"
							>
								Settings
							</Tabs.Tab>
						</RequirePermissions>
						<RequirePermissions permissions={['Orders.View']}>
							<Tabs.Tab onClick={() => setSelectedTab('orders')} value="orders">
								Orders
							</Tabs.Tab>
						</RequirePermissions>
						<RequirePermissions permissions={['Closings.View']}>
							<Tabs.Tab
								onClick={() => setSelectedTab('closing')}
								value="closing"
							>
								Closing
							</Tabs.Tab>
						</RequirePermissions>
					</Tabs.List>
					<RequirePermissions permissions={['PosConfig.View']}>
					<Tabs.Panel value="settings">
						<Div
							style={{
								maxWidth: '920px',
								display: 'grid',
								gridTemplateColumns: 'auto 220px',
								gap: '20px',
								padding: '20px 0px',
							}}
						>
							<FormMultiSelect
								width="920px"
								label="Filter POS by Payment Method:"
								showPlaceholder
								disableAllOptions
								options={
									paymentMethodsData?.paymentMethods?.map((e) => ({
										value: e.id.toString(),
										label: e.name,
									})) || []
								}
								onChange={(items) => {
									if (items.length) {
										setSearchParams((p) => ({
											...p,
											payment_methods: items.map((e) => +e.value),
										}));
									} else {
										setSearchParams((p) => ({
											...p,
											payment_methods: undefined,
										}));
									}
								}}
								placeholder={
									searchParams?.payment_methods?.length
										? `${searchParams?.payment_methods?.length} Payment Method${
												searchParams?.payment_methods?.length > 1 ? 's' : ''
												// eslint-disable-next-line no-mixed-spaces-and-tabs
										  } selected`
										: 'Choose a Payment Method'
								}
							/>
						</Div>

						<Collapse in={selectedRows.length >= 1}>
							<Stack
								css={{ padding: '20px' }}
								align="center"
								width="100%"
								justify="flex-start"
							>
								<FormCheckBox checked readOnly />
								<Text
									css={{ margin: 0, marginLeft: '20px' }}
								>{`${selectedRows.length} POS Selected`}</Text>
								<RequirePermissions permissions={['PosSync.Edit']}>
									<Button
										leftIcon={<Icon name="sync" />}
										onClick={() => {
											showModal({
												title: '',
												// hideCloseButton: true,
												props: {
													style: { borderRadius: '20px', padding: '0px' },
													hideCloseButton: true,
													hideTitle: true,
												},
												closeOnClickOutside: false,
												body: <PosSync posIds={selectedRows} />,
											});
										}}
									>
										Sync to POS
									</Button>
								</RequirePermissions>
							</Stack>
						</Collapse>
						{pos?.pos?.length === 0 ? (
							<EmptyState
								resource="Point Of Sales"
								secondaryMessage="Your Point of Sales will appear here"
							/>
						) : (
							<SimpleDataGrid
								data={pos?.pos ?? []}
								columns={[
									{
										accessorKey: 'name',
										header: 'Name',
										cell: (row: any) => (
											<Text
												style={{ whiteSpace: 'nowrap' }}
												w={'300px'}
												truncate
											>
												{row.row.original.outlet_name as string} -{' '}
												{row.getValue() as string}
											</Text>
										),
									},
									{
										accessorKey: 'id',
										header: 'POS ID',
									},
									{
										accessorKey: 'sync_status',
										header: 'Status',
										cell: ({ getValue }: { getValue: any }) =>
											getValue() ? (
												<StatusChip>{getValue() as string}</StatusChip>
											) : null,
										// <StatusChip>{getValue() as string}</StatusChip>
									},
									{
										accessorKey: 'payment_methods',
										header: 'Payment Types',
										cell: ({ row }: { row: Row<any> }) => {
											return <PaymentTypes posId={row?.original?.id} />;
										},
									},
									{
										accessorKey: 'discounts',
										header: 'Discounts',
										
										cell: ({ getValue }: { getValue: any }) => {
											const discounts: Array<{id:number, name:string}> 
												= getValue() as Array<{id:number, name:string}>;
											return (
												<Stack align="center">
													<Text style={{ whiteSpace: 'nowrap' }}>
														{discounts
															? `${discounts.length} Discounts`
															: 'No Discounts'}
													</Text>
													{discounts?.length ? (
													<Tooltip
														position="right"
														offset={0}
														label={<DiscountsTooltip 
															discounts={discounts} />}>
														<Div
															css={() => ({
																cursor: 'pointer',
																height: '32px',
																width: '32px',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
															})}
														>
															<Icon name="info"/>
														</Div>
													</Tooltip>
												) : null} 
												</Stack>
											);
										},
									},
									{
										accessorKey: 'updated_at',
										header: 'Last updated',
										cell: ({ getValue }: { getValue: any }) => (
											<Text style={{ whiteSpace: 'nowrap' }}>
												{dayjs(getValue() as string).format(
													'DD/MM/YYYY h:mm:ss A'
												)}
											</Text>
										),
									},
									{
										accessorKey: 'id',
										header: '',
										cell: ({ getValue, row }: { getValue: any; row: any }) => {
											return (
												<>
													<RequirePermissions permissions={['PosSync.Edit']}>
														<Menu position="bottom-start">
															<Menu.Target>
																<ActionIcon>
																	<Icon name="morehort" />
																</ActionIcon>
															</Menu.Target>
															<Menu.Dropdown>
																<Menu.Item
																	onClick={() =>
																		syncPos({
																			posId: getValue() as string,
																			posName: row.getValue('name'),
																			event_uuid: uuidv4(),
																			accessToken: accessToken,
																		})
																	}
																>
																	Sync
																</Menu.Item>
																<Menu.Item
																		style={{
																			display: row?.original?.grab_store_id
																				? 'block'
																				: 'none',
																		}}
																		onClick={async () => {
																			toast.success('Sync to Grab started');
																			configApi
																				.post(`/grab/self/triggersync`, {
																					partnerMerchantID: row?.original?.id,
																					grabStoreID:
																						row?.original?.grab_store_id,
																				})
																				.then(() => {
																					toast.success('Grab Sync Success');
																				})
																				.catch((err) => {
																					toast.error(
																						err?.response?.data?.error ??
																							'Grab sync failed'
																					);
																				});
																		}}
																	>
																		Sync to Grab menu
																	</Menu.Item>
															</Menu.Dropdown>
														</Menu>
													</RequirePermissions>
												</>
											);
										},
										meta: {
											isNotClickable: true,
										},
									},
								]}
								onClickHandler={getSelectedRow}
							/>
						)}
					</Tabs.Panel>
					</RequirePermissions>
					<RequirePermissions permissions={['Orders.View']}>
						<Tabs.Panel value="orders">
							<Orders outlet_id={searchParams?.outlet_id ?? null} />
						</Tabs.Panel>
					</RequirePermissions>
					<RequirePermissions permissions={['Closings.View']}>
						<Tabs.Panel value="closing">
							<Closing outlet_id={searchParams?.outlet_id ?? null} />
						</Tabs.Panel>
					</RequirePermissions>
				</Tabs>
			)}
		</ViewContainer>
	);
};

export default PointOfSale;

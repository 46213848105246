import {
	Avatar,
	Burger,
	Button,
	Group,
	Header,
	HeaderProps,
	MediaQuery,
	Menu,
} from '@mantine/core';
import React from 'react';
import { Brand, SelectCompany } from '../components';
import { useGetUser } from '../hooks/api/managements/useGetUser';
import { useGetPosSyncBrands } from '../hooks/api/pos/useGetPosSyncBrands';
import { useNavigate } from '../lib/navigate';
import { useAppState } from '../providers/AppStateProvider';
import { useAuth } from '../providers/AuthProvider';
import { ConfigurationRoutes } from '../router/constants/Configuration.routes';
import { ManagementRoutes } from '../router/constants/Management.routes';
import packageJson from '../../package.json';
import otoposLogo from '../views/assets/otopos-logo-orange.png';

export type TopbarProps = Omit<HeaderProps, 'children' | 'height'> & {
	toggleSidebar: () => void;
	isOpened: boolean;
};

const Topbar = React.forwardRef<HTMLElement, TopbarProps>(
	({ toggleSidebar, isOpened, ...rest }, ref) => {
		const navigate = useNavigate();
		const { setBrand } = useAppState();
		const { data: brandsToSync } = useGetPosSyncBrands();
		const { logout, userId } = useAuth();
		const { data: currentUser } = useGetUser(userId || null);
		const [first, second] = (currentUser?.name ?? '').split(' ');
		const nameInitial = (first?.[0] ?? '') + (second?.[0] ?? '');

		return (
			<Header {...rest} height="62px" ref={ref}>
				<Group
					noWrap
					align="center"
					px="1rem"
					w="100%"
					h="56px"
					position="apart"
					spacing={20}
				>
					<Group h="56px" align="center" noWrap>
						<MediaQuery largerThan="md" styles={{ display: 'none' }}>
							<Burger
								opened={isOpened}
								onClick={() => toggleSidebar()}
								size="sm"
								mr="xl"
							/>
						</MediaQuery>
						<Brand imgUrl={otoposLogo} onClick={() => navigate('/')} />
						<SelectCompany />
					</Group>
					<Group h="56px" align="center" noWrap>
						<Menu trigger="hover">
							<Menu.Target>
								<Button
									sx={{
										background:
											'transparent linear-gradient(106deg, #A23FB5 0%, #E77615 100%) 0% 0% no-repeat padding-box',
									}}
								>
									Unsynced POS
								</Button>
							</Menu.Target>
							{brandsToSync?.length ? (
								<Menu.Dropdown>
									{brandsToSync?.map(({ id, name }: any) => (
										<Menu.Item
											key={`brands-to-sync-${name}`}
											onClick={() => {
												setBrand(id);
												navigate(ConfigurationRoutes.Pos);
											}}
										>
											{name}
										</Menu.Item>
									))}
								</Menu.Dropdown>
							) : null}
						</Menu>
						<Menu width={350} position="bottom-end">
							<Menu.Target>
								<Avatar
									sx={{ cursor: 'pointer' }}
									radius="xl"
									size="md"
									color="cyan"
								>
									{nameInitial}
								</Avatar>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item>
									<h5>{currentUser?.name}</h5>
									<p>{currentUser?.email}</p>
								</Menu.Item>
								<Menu.Divider />
								<Menu.Item
									onClick={() => navigate(ManagementRoutes.UserAccountSettings)}
								>
									Manage my account
								</Menu.Item>
								<Menu.Item
									onClick={() => {
										logout();
									}}
								>
									Log out
								</Menu.Item>
								<Menu.Label>{`v${packageJson.version}`}</Menu.Label>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</Group>
			</Header>
		);
	}
);

Topbar.displayName = 'Topbar';

export default Topbar;

import { Title, UnstyledButtonProps } from '@mantine/core';
import { UnstyledButton, Image } from 'components';
import React from 'react';

export type BrandProps = UnstyledButtonProps & {
	onClick: () => void;
	imgUrl:string
};

export const Brand = React.forwardRef<HTMLDivElement, BrandProps>(
	({ onClick, imgUrl, ...props }, ref) => (
		<UnstyledButton {...props} onClick={onClick}>
			<Image src={imgUrl} alt="otopos-logo" width="108px" />
		</UnstyledButton>
	)
);

Brand.displayName = 'Brand';

import styled from '@emotion/styled'
import { Div } from '../Div';
import { PaginationProps } from './Pagination';

type StyledPaginationProps = Omit<
	PaginationProps,
	'totalPages' | 'initialPage' | 'hasPreviousPage' | 'hasNextPage'
>;

export const StyledPagination = styled(Div)<StyledPaginationProps>`
	display: flex;
	align: center;
	direction: column;
	gap: 11px;
`;

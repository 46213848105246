import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetCategoryGrabStatus(id?: number){
    const { getAccessToken } = useAuth();
    const getOneGrabStatus = async() => {
        const response = await configApi.get(`categories/${id}/grab`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
    }

    return useQuery(['categories','is_grab', id], getOneGrabStatus, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
    // return useQuery(
	// 	['is_grab_category', categoryId],
	// 	async (): Promise<{
	// 		is_grab_category: boolean;
	// 	}> =>
	// 		configApi
	// 			.get(`categories/${categoryId}/grab`, {
	// 				headers: {
	// 					Authorization: `Bearer ${await getAccessToken()}`,
	// 				},
	// 			})
	// 			.then(({ data }) => data),
	// 	{
	// 		enabled: !!categoryId,
	// 	}
	// );
}
/* eslint-disable no-mixed-spaces-and-tabs */
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { DividerProps } from './Divider';

const verticalStyles = ({
	margin,
	size,
	color,
	theme,
}: DividerProps & { theme: Theme }) => `
margin: 0 ${margin ?? 0};
display: inline-block;
min-height: 2ch;
width: 0;
height: 100%;
border: 0;
border-left: ${size ?? '1px'} solid ${
	color ? theme.colors[color] : theme.colors.grey70
};
`;

const horizontalStyles = ({
	margin,
	size,
	color,
	theme,
}: DividerProps & { theme: Theme }) => `
margin: ${margin ?? 0} 0;
border: 0;
border-top: ${size ?? '1px'} solid ${
	color ? theme.colors[color] : theme.colors.grey70
};
width: 100%;
`;

export const StyledDivider = styled.hr<DividerProps>`
	${(props) =>
		props.orientation === 'vertical'
			? verticalStyles(props)
			: horizontalStyles(props)}
`;

import React from 'react';
import { Div, Text } from '../../../../components';

const OrderVoidingIndicator = () => {
	return (
		<Div
			css={(theme) => ({
				background: theme.colors.secondary,
				width: '736px',
				height: '50px',
				position: 'absolute',
				display: 'grid',
				placeItems: 'center',
				top: 0,
			})}
		>
			<Text color="white" style={{ letterSpacing: '0.2em' }}>
				VOIDING IN PROGRESS
			</Text>
		</Div>
	);
};

export default OrderVoidingIndicator;

import { Title } from '@mantine/core';
import React from 'react';
import { useFormikContext } from '../../../components';
import SetOutletsForBrands from './BrandsAndOutlets/BrandOutlet';

const BrandsAndOutlets = () => {
	const { values } = useFormikContext<any>();
	return (
		<>
			<Title order={2}>Brands & Outlets</Title>
			{values?.companies
				?.sort((a: number, b: number) => a - b)
				.map((e: number | string, i: number) => (
					<SetOutletsForBrands
						companyId={e}
						key={`set-outlets-for-brands-${e}-${i}`}
					/>
				))}
		</>
	);
};

export default BrandsAndOutlets;

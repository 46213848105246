import React from 'react';
import { Box, Flex, Text, Checkbox, Table, Title } from '@mantine/core';

export type SingleSelectCheckBoxGridProps = {
	initialValues?: string[];
	headers: { value: string; label: React.ReactNode }[];
	rows: { value: string; label: React.ReactNode }[];
	title?: React.ReactNode;
	onChange?: (selected: string[]) => void;
	enableReinitialize?: boolean;
	disabled?: boolean;
};

export const SingleSelectCheckBoxGrid = React.forwardRef<
	HTMLTableElement,
	SingleSelectCheckBoxGridProps
>(
	(
		{
			title,
			headers,
			rows,
			onChange,
			initialValues,
			enableReinitialize,
			disabled,
		},
		ref
	) => {
		const [selected, setSelected] = React.useState<Record<string, string>>(
			initialValues?.reduce((acc, val) => {
				const [row, header] = val.split('.')
				acc[row] = header;
				return acc;
			}, {} as any) || {}
		);

		React.useEffect(() => {
			if (initialValues && enableReinitialize) {
				setSelected(
					initialValues.reduce((acc, val) => {
						const [row, header] = val.split('.')
						acc[row] = header;
						return acc;
					}, {} as any)
				);
			}
		}, [initialValues, enableReinitialize]);

		React.useEffect(() => {
			if (onChange) {
				const selectionsInStrings = Object.entries(selected)
					.filter(([, value]) => value)
					.map(([key,value]) => key+'.'+value);
				onChange(selectionsInStrings);
			}
			
		}, [selected]);

		return (
			<Table
				ref={ref}
				verticalSpacing="sm"
				horizontalSpacing="xl"
				withColumnBorders
				withBorder
				w="min-content"
			>
				<thead>
					<tr>
						<th style={{ width: '250px' }}>
							<Title order={3} my="md" w="250px">
								{title}
							</Title>
						</th>
						{headers.map((e) => (
							<th key={e.value}>
								<Flex w="80px" justify="center">
									<Text weight={600} size="sm" color="gray" align="center">
										{typeof e.label === 'string'
											? e?.label?.toUpperCase()
											: e.label}
									</Text>
								</Flex>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row) => {
						const options: string[] = [];
						return (
							<tr key={row.value}>
								<td style={{ width: '250px' }}>
									<Text weight={600} w="250px">
										{row?.label}
									</Text>
								</td>

								{headers.map((header) => {
									const option = row.value + '.' + header.value;
									options.push(option);

									const isChecked = 
										selected[row.value] === header.value;

									return (
										<Box
											key={option}
											component="td"
											sx={() => ({
												width: '70px',
												background: 'inherit',
											})}
										>
											<Flex w="100%" justify="center">
												{
													<Checkbox
														disabled={disabled}
														checked={isChecked}
														onChange={() =>
															setSelected((p) => {

																const updatedSelect = { ...p };
																(updatedSelect[row.value] === header.value)
																	? updatedSelect[row.value] = ''
																	: updatedSelect[row.value] = header.value
																
																return updatedSelect
															})
														}
													/>
												}
											</Flex>
										</Box>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
		);
	}
);

SingleSelectCheckBoxGrid.displayName = 'SingleSelectCheckBoxGrid';

import React from 'react';
import { Button, Div, ModalWrapper, Stack } from 'components';
import styled from '@emotion/styled';

type Data = {
	id: number;
	name: string;
};

type DataArray = {
	[key: string]: string[];
};

export type ModalPropsString = {
	close: () => void;
	data?: Data;
};

export type ModalPropsArray = {
	close: () => void;
	data?: DataArray;
};

export const StyledDiv = styled.div`
	background-color: ${({ theme }) => theme.colors.grey05};
	padding: 1rem;
	margin: 1rem 0;

	p {
		color: ${({ theme }) => theme.colors.grey50};
	}

	ul {
		margin-top: 0;
		list-style-type: none;
		padding: 0;
	}
`;

export const LinkedMallModal = ({ close, data }: ModalPropsArray) => {
	return (
		<>
			{data && (
				<ModalWrapper>
					<h3>
						This mall is used in the following places, please unlink before
						deleting:
					</h3>

					<StyledDiv>
						<p>OUTLETS</p>
						<ul>
							{data.outlets.map((el, index) => {
								return <li key={index}>{el}</li>;
							})}
						</ul>
					</StyledDiv>
					<Stack
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button style={{ float: 'right' }} type="button" onClick={close}>
							Ok
						</Button>
					</Stack>
				</ModalWrapper>
			)}
		</>
	);
};

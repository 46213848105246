import React from 'react';
import { PaymentMethodGroup } from 'components';
import nanoid from 'lib/nanoid';
import PaymentType from './PaymentType';
import { useGetPaymentMethodsByPosId } from '../../../hooks/api/paymentmethods/useGetPaymentMethods';

type Props = {
	posId: number;
};

const PaymentTypes = ({ posId }: Props) => {
	const { data } = useGetPaymentMethodsByPosId(posId.toString());
	return !data?.length ? (
		<span>No Payment Types</span>
	) : (
		<PaymentMethodGroup>
			{data?.map((e) => (
				<PaymentType paymentMethodId={e.id} key={nanoid(6)} />
			))}
		</PaymentMethodGroup>
	);
};

export default PaymentTypes;

import React, { PropsWithChildren } from 'react';

type TAppState = {
	selectedCompany: number | null;
	isCompanyBCIntegrated: boolean;
	selectedBrand: number | null;
	setCompany: (companyId: number) => void;
	setBCIntegration: (bcIntegrationStatus: boolean) => void;
	setBrand: (brandId: number) => void;
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
	isSidebarHidden: boolean;
	setIsSidebarHidden: (isSidebarHidden: boolean) => void;
	events: any[];
	setEvents: React.Dispatch<React.SetStateAction<any[]>>;
	isSelectCompanyDisabled: boolean;
	toggleSelectCompany: () => void;
};

const initialState: TAppState = {
	selectedCompany: null,
	isCompanyBCIntegrated: false,
	selectedBrand: null,
	setCompany: () => {
		return;
	},
	setBCIntegration: () => {
		return;
	},
	setBrand: () => {
		return;
	},
	setDirty: () => {
		return;
	},
	isDirty: false,
	isSidebarHidden: false,
	setIsSidebarHidden: () => {
		return;
	},
	events: [],
	setEvents: () => {
		return;
	},
	isSelectCompanyDisabled: false,
	toggleSelectCompany: () => {
		return;
	},
};

const AppState = React.createContext<TAppState>(initialState);

const AppStateProvider: React.FC<PropsWithChildren<unknown>> = ({
	children,
}) => {
	const [appState, setAppState] = React.useState<TAppState>({
		...initialState,
		selectedCompany: null,
		isCompanyBCIntegrated: false,
		selectedBrand: null,
		isDirty: false,
		isSidebarHidden: false,
		isSelectCompanyDisabled: false,
	});

	const [events, setEvents] = React.useState<any[]>([]);

	const setCompany = React.useCallback((companyId: number) => {
		setAppState((p) => ({
			...p,
			selectedCompany: companyId,
		}));
	}, []);

	const setBCIntegration = React.useCallback(
		(bcIntegrationStatus: boolean) =>
			setAppState((p) => ({
				...p,
				isCompanyBCIntegrated: bcIntegrationStatus,
			})),
		[]
	);

	const setBrand = React.useCallback(
		(brandId: number) =>
			setAppState((p) => ({
				...p,
				selectedBrand: brandId,
			})),
		[]
	);

	const setDirty = React.useCallback(
		(dirty: boolean) =>
			setAppState((p) => ({
				...p,
				isDirty: dirty,
			})),
		[]
	);

	const setIsSidebarHidden = React.useCallback(
		(isSidebarHidden: boolean) =>
			setAppState((p) => ({
				...p,
				isSidebarHidden,
			})),
		[]
	);

	const toggleSelectCompany = React.useCallback(() => {
		setAppState((p) => ({
			...p,
			isSelectCompanyDisabled: !p.isSelectCompanyDisabled,
		}));
	}, []);

	const value = {
		...appState,
		setCompany,
		setBCIntegration,
		setBrand,
		setDirty,
		events,
		setEvents,
		setIsSidebarHidden,
		toggleSelectCompany,
	};

	return <AppState.Provider value={value}>{children}</AppState.Provider>;
};

export default AppStateProvider;

export function useAppState() {
	return React.useContext(AppState);
}

import { AppShell } from '@mantine/core';
import { useAuth } from 'providers/AuthProvider';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppState } from '../providers/AppStateProvider';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { useGetBrandsForDropdown } from '../hooks/brands';

const AppContainer: React.FC = () => {
	const [opened, setOpened] = React.useState<boolean>(false);
	const { isAuthenticated } = useAuth();
	const { isSidebarHidden, selectedCompany, setBrand } = useAppState();

	const { data: brands } = useGetBrandsForDropdown(selectedCompany);

	// useGetBCIntegrationStatus(user?.companies?.[0], {
	// 	onSuccess: (data: boolean) => setBCIntegration(data),
	// });
  // Comment out because we drop BC and considering if we might bring it back next time

	React.useEffect(() => {
		if (brands?.brands?.length) {
			setBrand(brands?.brands?.at(0)?.id);
		} else {
			setBrand(0);
		}
	}, []);

	return (
		<AppShell
			styles={{
				main:{
					width:'95vw'
				}
			}}
			padding={isSidebarHidden ? 0 : 'md'}
			navbarOffsetBreakpoint={isSidebarHidden ? 10000 : 'md'}
			navbar={isAuthenticated ? <Sidebar hidden={!opened} /> : <></>}
			header={
				isAuthenticated ? (
					<Topbar
						toggleSidebar={() => setOpened((p) => !p)}
						isOpened={opened}
					/>
				) : (
					<></>
				)
			}
			hidden={!isAuthenticated}
		>
			<Outlet />
		</AppShell>
	);
};

export default AppContainer;

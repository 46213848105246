import React from 'react';
import { Stack, Title } from '@mantine/core';
import { MultiSelectField, FormPriceInput } from 'components';
import { Field, useFormikContext } from 'formik';

export const ProductGrabIntegration = ({
	hidden,
	optionGroups,
}: {
	hidden: boolean;
	optionGroups: any;
}) => {
	const { values, setFieldValue } = useFormikContext<any>();
	return (
		<Stack spacing={20} style={{ display: hidden ? 'none' : 'flex' }}>
			<Title order={2}>Grab Integration</Title>

			<Field
				width="600px"
				placeholder="0.00"
				value={parseFloat(values?.grab_price) ?? 0.0}
				min={0.01}
				precision={2}
				step={0.1}
				as={FormPriceInput}
				onChange={(value: any) => {
					if (value === '') {
						value = 0;
					}
					setFieldValue('grab_price', value);
				}}
				label="Grab Selling Price ($) (GST Exclusive)"
				name="grab_price"
			/>
			<MultiSelectField
				width="611px"
				label="Grab Option Groups"
				data={
					optionGroups?.map((e: any) => ({
						value: e.id.toString(),
						label: e.name,
					})) || []
				}
				defaultValue={values?.grab_option_groups?.map((e: number) => String(e))}
				value={values?.grab_option_groups?.map((e: number) => String(e))}
				onChange={(selectedItems) => {
					setFieldValue(
						'grab_option_groups',
						selectedItems.map((e) => +e)
					);
				}}
			/>
		</Stack>
	);
};

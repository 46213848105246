import { Button } from '@mantine/core';
import styled from '@emotion/styled';

type ChildrenProp = {
	children?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	onClick?: () => void;
};

const CancelButton = styled(Button)<ChildrenProp>`
	background-color: #dee2e6;
	color: #2a2e33;

	:hover {
		background-color: #b2b5b8;
	}
`;

export { CancelButton };

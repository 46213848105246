import { reportAnalyticApi } from 'apis';
import { useQuery } from 'react-query';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetDailySales({
	type,
	id,
}: {
	type: string;
	id: number | string | null;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['daily_sales', `${type}_${id}`],
		async () =>
			reportAnalyticApi
				.get(`/sales/${type}/${id}/daily`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ enabled: !!id }
	);
}

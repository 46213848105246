import { authApi } from '../../../apis';
import { useQuery } from 'react-query';
import { useAuth } from '../../../providers/AuthProvider';

export const useGetUser = (userId: number | null) => {
	const { getAccessToken } = useAuth();

	const fetchUser = async (userId: number | null) => {
		const res = await authApi.get(`/users/${userId}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return res.data;
	};

	return useQuery(['userId', userId], () => fetchUser(userId), {
		enabled: !!userId,
		refetchOnWindowFocus: false,
	});
};

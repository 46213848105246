import React from 'react';
import { Div, ViewContainer } from 'components';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useGetArchivedBrands } from 'hooks/brands';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from '../../../lib/navigate';
import dayjs from 'dayjs';

const BrandsArchived = () => {
	const { selectedCompany, setBrand } = useAppState();
	const [sortOrder, setSortOrder] = React.useState<string>('name:asc');
	const [selectedPage, setSelectedPage] = React.useState<number>(1);
	const { data, refetch } = useGetArchivedBrands(selectedCompany || 0);
	const navigate = useNavigate();

	const getSelectedRow = (row: any) => {
		navigate(`/configuration/brands/${row.original.id}`);
	};

	const breadcrumbsArray = [
		{
			label: 'Brands',
			onClick: () => navigate('/configuration/brands'),
		},
		{
			label: 'Archived',
			onClick: () => navigate('#'),
		},
	];

	React.useEffect(() => {
		refetch();
	}, [sortOrder]);

	return (
		<>
			<ViewContainer>
				<Div style={{ position: 'relative', marginBottom: '20px' }}>
					<Breadcrumbs items={breadcrumbsArray} />
				</Div>
				<SimpleDataGrid
					data={data?.brands || []}
					columnVisibility={{ id: false }}
					columns={[
						{ accessorKey: 'id', header: 'Id' },
						{ accessorKey: 'name', header: 'Name' },
						{
							accessorKey: 'updated_at',
							header: 'Last Updated',
							cell: (row: any) =>
								dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssA'),
						},
					]}
					onClickHandler={getSelectedRow}
				/>
			</ViewContainer>
		</>
	);
};

export default BrandsArchived;

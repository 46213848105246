import React from 'react';
import { z } from 'zod';
import Icon from '../../assets/icons';
import {
	Alert,
	Button,
	Div,
	Field,
	Formik,
	FormTextInput,
	Stack,
	Text,
	Title,
} from '../../components';
import { usePostPasswordRecovery } from '../../hooks/api/password/usePostPasswordRecovery';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useNotification } from 'providers/NotificationProvider';

const validationSchema = z.object({
	email: z
		.string({ required_error: 'This field is required' })
		.email('Please enter a valid email'),
});

const PasswordRecovery = () => {
	const navigate = useNavigate();
	const notify = useNotification();

	const { mutate, isSuccess, isLoading } = usePostPasswordRecovery();
	return (
		<Formik
			initialValues={{ email: '' }}
			onSubmit={({ email }) =>
				mutate(
					{ email },
					{
						onError: (error: any) => {
							const statusCode = error.response?.status;
							if (statusCode == 429) {
								notify.error('Please try again later');
							}
						},
					}
				)
			}
			validationSchema={toFormikValidationSchema(validationSchema)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ values, errors, submitForm }) => {
				return (
					<Div>
						<Title>Can&apos;t log in?</Title>
						<Text size="14px" color="grey">
							We&apos;ll send a recovery link to your email to help you reset
							it.
						</Text>
						<Div style={{ margin: '24px 0' }}>
							{isSuccess ? (
								<Alert icon={<Icon name="checkcircle" />}>
									Sent <strong>successfully</strong>, please check your email
								</Alert>
							) : (
								<Field
									as={FormTextInput}
									name="email"
									isRequired
									label="Email"
									inputWrapperProps={{
										direction: 'column',
										labelProps: {
											style: { padding: '0' },
										},
									}}
									error={errors['email'] ?? null}
									isDisabled={isLoading}
								/>
							)}
						</Div>
						{!isSuccess ? (
							<Stack width="100%" direction="column">
								<Button
                w="100%"
                onClick={() => submitForm()}
                loading={isLoading}
								>
									Send recovery link
								</Button>
								<Button
									color="white"
									disabled={isLoading}
                  w="100%"
									onClick={() => navigate('/login')}
								>
									Back to login
								</Button>
							</Stack>
						) : null}
					</Div>
				);
			}}
		</Formik>
	);
};
export default PasswordRecovery;

import { useMutation } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCreateRole() {
	const { getAccessToken } = useAuth();
	return useMutation(
		async (payload: {
			name: string;
			description: string;
			company_id: number;
			permissions: string[];
		}) =>
			authApi
				.post('/roles', payload, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledSwitchLabel, StyledToggleSwitch } from './FormToggle.styled';

export type FormToggleProps = Omit<
	DefaultProps<HTMLInputElement>,
	'onChange'
> & {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	trueLabel?: React.ReactNode;
	falseLabel?: React.ReactNode;
};

export const FormToggle = React.forwardRef<HTMLInputElement, FormToggleProps>(
	({ checked, onChange, trueLabel, falseLabel, ...rest }, ref) => {
		const [isChecked, setIsChecked] = React.useState<boolean>(false);

		React.useEffect(() => {
			if (typeof checked === 'boolean') {
				setIsChecked(checked);
			}
		}, [checked]);

		return (
			<>
				<div
					style={{
						display: 'inline-flex',
						height: '36px',
						alignItems: 'center',
					}}
				>
					<StyledToggleSwitch>
						<input
							type="checkbox"
							checked={isChecked}
							onChange={() => {
								setIsChecked((p) => {
									onChange && onChange(!p);
									return !p;
								});
							}}
							{...rest}
							ref={ref}
						/>
						<span className="switch" />
					</StyledToggleSwitch>
					<StyledSwitchLabel>
						{isChecked ? trueLabel ?? 'Yes' : falseLabel ?? 'No'}
					</StyledSwitchLabel>
				</div>
			</>
		);
	}
);

FormToggle.displayName = 'FormToggle';

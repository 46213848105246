import { useQuery } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetRoleById(roleId: string | number) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['roles', 'roleId', roleId],
		async () =>
			authApi
				.get(`/roles/${roleId}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!roleId,
		}
	);
}

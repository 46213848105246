import React from 'react';
import { useParams } from 'providers/RouterProvider';
import {
	Alert,
	Button,
	Box,
	FormTextInput,
	FormSelectInput,
	LastUpdatedLabel,
	ViewContainer,
	Stack,
	ImageUploader,
} from 'components';
import { Text } from '@mantine/core';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { InputTagsWrapper } from 'components/InputTags/InputTagsWrapper';
import { InputSwitchWrapper } from 'components/InputSwitch/InputSwitchWrapper';
import { Field, Formik } from 'formik';
import { useNotification } from 'providers/NotificationProvider';
import { useModals } from 'providers/ModalsProvider';
import { useNavigate } from '../../../lib/navigate';
import {
	useGetOneBrand,
	useUpdateBrand,
	useCheckLinks,
	useUnarchiveBrand,
} from 'hooks/brands';
import { useGetCompaniesForDropdown } from 'hooks/companies';
import {
	BrandSchemaUpdate,
	BrandSchemaUpdateType,
} from 'schemas/brands.schema';
import {
	ArchiveEntityModal,
	DeleteEntityModal,
	LinkedEntityModal,
} from './Modals';
import { useAuth } from 'providers/AuthProvider';
import Icon from '../../../assets/icons';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import RequirePermissions from '../../../guards/RequirePermissions';

const BrandsView = () => {
	useDisableSelectCompany();
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useNotification();
	const modal = useModals();
	const {
		data: brand,
		isFetching: fetchingBrand,
		status,
	} = useGetOneBrand(Number(id));
	const { mutateAsync: updateOneBrand } = useUpdateBrand();
	const { mutateAsync: unarchiveBrand } = useUnarchiveBrand();
	const { refetch: checkLinks } = useCheckLinks(Number(id));

	const { data: response, isFetching: fetchingCompanies } =
		useGetCompaniesForDropdown();
	const { permissions, user } = useAuth();
	const initialValues: BrandSchemaUpdateType = {
		name: '',
		sales_tax: 0.0,
		company_id: 0,
		is_sales_tax_in_item_price: false,
		email_recipients: [],
		image_url: '',
		updated_by: user?.name || '',
	};

	const onDeleteButtonClickHandler = async () => {
		await checkLinks().then((e) => {
			const data = e.data.data;
			if (
				data?.outlets?.length > 0 ||
				data?.pos?.length > 0 ||
				data?.users?.length > 0
			) {
				const _data = { ...data, action: 'deleting' };
				modal.showModal({
					title: '',
					body: <LinkedEntityModal close={modal.closeModal} data={_data} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteEntityModal
							close={modal.closeModal}
							data={{ id: Number(id), name: brand.name }}
						/>
					),
				});
			}
		});
	};

	const onArchiveButtonClickHandler = async () => {
		if (!brand.is_archived) {
			await checkLinks().then((e) => {
				const data = e.data.data;
				if (
					data?.outlets?.length > 0 ||
					data?.pos?.length > 0 ||
					data?.users?.length > 0
				) {
					const _data = { ...data, action: 'deleting' };
					modal.showModal({
						title: '',
						body: <LinkedEntityModal close={modal.closeModal} data={_data} />,
					});
				} else {
					modal.showModal({
						title: '',
						body: (
							<ArchiveEntityModal
								close={modal.closeModal}
								data={{ id: Number(id), name: brand.name }}
							/>
						),
					});
				}
			});
		} else {
			unarchiveBrand(Number(id))
				.then(() => {
					navigate('/configuration/brands');
					toast.success('Brand has been successfully unarchived');
				})
				.catch(() => {
					toast.error('An error occured');
				});
		}
	};

	if (status === 'loading' || fetchingBrand || fetchingCompanies) {
		return <Box>Loading...</Box>;
	}

	if (!fetchingBrand && !brand) {
		navigate('/configuration/brands');
		toast.error('Brand not found');
	}

	const companyList = response.companies.map((a: any) => ({
		value: a.id,
		label: a.name,
	}));

	const breadcrumbsArray = [
		{
			label: 'Brands',
			onClick: () => navigate('/configuration/brands'),
		},
		brand.is_archived && {
			label: 'Archived',
			onClick: () => navigate('/configuration/brands/archived'),
		},
		{
			label: brand.name,
			onClick: () => navigate('#'),
		},
	];

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Breadcrumbs items={breadcrumbsArray} />

				<Box
					style={{
						display: 'inline-flex',
						position: 'absolute',
						right: 0,
						top: 0,
						gap: '15px',
					}}
				>
					<RequirePermissions permissions={['Brands.Delete']}>
						<Button
							color="red"
							leftIcon={<Icon name="delete" />}
							type="button"
							onClick={onDeleteButtonClickHandler}
						>
							Delete
						</Button>
					</RequirePermissions>
					
					<RequirePermissions permissions={['Brands.Archive']}>
						<Button
							type="button"
							color="gray.3"
							style={{ color: 'black' }}
							onClick={onArchiveButtonClickHandler}
						>
							{brand.is_archived ? 'Unarchive' : 'Archive'}
						</Button>
					</RequirePermissions>
					
				</Box>
			</Box>

			{brand.is_archived && (
				<Alert>{`You are viewing an archived (read only) version`}</Alert>
			)}

			<Box style={{ clear: 'both' }}></Box>
			<Formik
				initialValues={
					{ ...brand, updated_by: user?.name || '' } ?? initialValues
				}
				validate={(values) => {
					try {
						values.sales_tax = parseFloat(values.sales_tax);
						BrandSchemaUpdate.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					await updateOneBrand({ id, ...values })
						.then(() => {
							navigate('/configuration/brands', {}, true);
							toast.success('Brand has been successfully updated');
						})
						.catch((e) => {
							toast.error(`${e.response.status} error occured`);
						});
					actions.setSubmitting(false);
				}}
			>
				{({ values, errors, touched, dirty, setFieldValue, submitForm, isSubmitting }) => {

					(permissions?.includes("Brands.Edit"))
						&& useStopNavigateDirtyForm(dirty)
					
						return (
						<fieldset disabled={!permissions?.includes("Brands.Edit") 
							|| isSubmitting} >
							<Box style={{ width: '100%' }}>
								<Stack direction="row" gap={30}>
									<Stack direction="column" gap={10}>
										<Field
											width="600px"
											as={FormTextInput}
											name="name"
											isReadOnly={brand.is_archived}
											label="Brand Name"
											isRequired={true}
											maxLength={80}
											error={errors.name && touched.name ? errors.name : null}
										/>
										{brand.is_archived ? (
											<Field
												width="600px"
												type="text"
												as={FormTextInput}
												isReadOnly={brand.is_archived}
												name="company_name"
												label="Company Name"
												error={
													errors.sales_tax && touched.sales_tax
														? errors.sales_tax
														: null
												}
											/>
										) : (
											<FormSelectInput
												isDisabled={true}
												label="Company Name"
												defaultOption={{
													value: brand.company_id,
													label: brand.company_name,
												}}
												options={companyList}
												placeholder="Select something here"
												onChange={(items) => {
													setFieldValue('company_id', items.value);
													setFieldValue('company_name', items.label);
												}}
												width="600px"
											/>
										)}
										<Field
											width="600px"
											type="number"
											as={FormTextInput}
											isReadOnly={brand.is_archived}
											name="sales_tax"
											label="GST (%)"
											hint={`Good and Services Tax. This value will be used to calculate in sale transactions.\r\rIf not applicable, set value to 0`}
											error={
												errors.sales_tax && touched.sales_tax
													? errors.sales_tax
													: null
											}
										/>

										{brand.is_archived ? (
											<Field
												width="600px"
												type="text"
												as={FormTextInput}
												isReadOnly={brand.is_archived}
												name="is_sales_tax_in_item_price"
												label="Is Service Charge and GST included in selling price?"
												value={brand.is_sales_tax_in_item_price ? 'Yes' : 'No'}
												error={
													errors.sales_tax && touched.sales_tax
														? errors.sales_tax
														: null
												}
											/>
										) : (
											<Field
												component={InputSwitchWrapper}
												name="is_sales_tax_in_item_price"
												isReadOnly={brand.is_archived}
												isChecked={values.is_sales_tax_in_item_price}
												setIsChecked={(isChecked: boolean) => {
													if (!brand.is_archived)
														setFieldValue(
															'is_sales_tax_in_item_price',
															isChecked
														);
												}}
												width={'600px'}
												label="Is Service Charge and GST included in selling price?"
												hint="If No, Service Charge and GST will be calculated and added on top of the total bill at checkout"
											/>
										)}

										<Field
											label="Email Recipients"
											width="600px"
											direction="row"
											isReadOnly={brand.is_archived}
											tags={values.email_recipients ?? []}
											setTags={(tags: string[]) =>
												setFieldValue('email_recipients', [...tags])
											}
											component={InputTagsWrapper}
											error={errors.email_recipients}
										/>

									{!brand.is_archived && (
										<Stack
											width={'100%'}
											style={{ display: 'flex', justifyContent: 'flex-start' }}
										>
											<RequirePermissions permissions={['Brands.Edit']}>
												<Button type="submit" onClick={submitForm}>
													Save Changes
												</Button>
												<Button
													type="button"
													color="gray.3"
													style={{ color: 'black' }}
													onClick={() => navigate('/configuration/brands')}
												>
													Cancel
												</Button>
											</RequirePermissions>
										</Stack>
										
									)}
									<Box
										style={{
											alignSelf: 'flex-start',
										}}
										>
										<LastUpdatedLabel
											lastUpdated={values?.updated_at ?? new Date()}
											editor={values?.updated_by ?? ''}
										/>
									</Box>
								</Stack>
								<Stack direction="column" style={{ alignSelf: 'flex-start' }}>
									<ImageUploader
										onChange={(file) => setFieldValue('image_url', file)}
										url={values?.image_url}
										inactiveTabs={[
											'Products',
											'Category Groups',
											'Payment Methods',
											'Discounts',
											'Advertisements',
										]}
									/>
									<Text align="left" fs="italic" size="xs" color="gray" >
										Recommended Dimensions: W 300px x H 216px
									</Text>
									
								</Stack>
							</Stack>
						</Box>
						</fieldset>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default BrandsView;

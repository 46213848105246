import React from 'react';
import { DivProps } from '../Div/Div';
import { StyledPaper } from './Paper.styled';

export type PaperProps = DivProps;

export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
	(props, ref) => {
		return <StyledPaper {...props} ref={ref} />;
	}
);

Paper.displayName = 'Paper';

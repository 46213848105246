import { CSSProperties } from 'react';
import { ThemeColors } from '../types/ThemeColors';

const colors = [
	'primary',
	'secondary',
	'green',
	'red',
	'accent',
	'grey05',
	'grey10',
	'grey30',
	'grey50',
	'grey70',
	'grey100',
	'black',
	'white',
];

export function isThemeColor(
	color: ThemeColors | CSSProperties['backgroundColor']
): color is ThemeColors {
	if (colors.includes(color as string)) {
		return true;
	}
	return false;
}

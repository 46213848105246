import { useQueryClient, useMutation} from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';

export function useDeleteOneSellingTime() {
	const { getAccessToken } = useAuth();
	const deletOneSellingTime = async (id: number) => {
        console.log(id)
		const response = await configApi.delete(`selling-times/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deletOneSellingTime, {
		onSuccess: () => {
			queryClient.invalidateQueries('selling_times');
		},
	});
}
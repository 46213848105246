import { PeriodType } from "../constants";

export function isSellingTimeValid(_serviceHours:{ [k: string]: any }) : boolean {
    const _valid = Object.values(_serviceHours).every((e) => {
        const day: { openPeriodType: string; periods: [PeriodType] } = e;

        if (day.openPeriodType === 'OpenPeriod') {
            if (!day.periods[0].startTime || !day.periods[0].endTime) {
                return false;
            }
        }
        return true;
    });

    return _valid
}
export const DiscountEnum = Object.freeze({
	TYPE: {
		FIXED_AMOUNT: 'fixed_amount',
		FIXED_PERCENT: 'fixed_percent',
		VAR_AMOUNT: 'var_amount',
		VAR_PERCENT: 'var_percent',
	},
	AUTO_APPLY_ON: {
		SPECIFIC_PAY_METHOD: 'specific_pay_method',
		SPECIFIC_PRODUCT: 'specific_product',
		MANUAL: 'manual',
	},
	SCOPE: 
	{
		ORDER: 'order_discount',
		PRODUCT: 'product_discount'
	}
});

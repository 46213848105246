import React from 'react';
import { PaymentMethod } from '../../../components';
import { useGetPaymentMethodById } from '../../../hooks/api/paymentmethods/useGetPaymentMethodById';

type Props = {
	paymentMethodId: number;
};

const PaymentType = ({ paymentMethodId }: Props) => {
	const { data } = useGetPaymentMethodById(paymentMethodId);

	return data ? (
		<PaymentMethod src={data.image_url ?? null} type={data.name} />
	) : null;
};

export default PaymentType;

import React from 'react';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	FormTextInput,
	SelectBrand,
	Stack,
	ViewContainer,
} from 'components';
import ServiceHoursList from './ServiceHours';

import { Box, Divider, Grid, Group, JsonInput } from '@mantine/core';
import { Field, Formik } from 'formik';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from '../../../lib/navigate';
import { ProductRoutes } from 'router/constants/Products.routes';
import dayjs from 'dayjs';
import { SellingTimesSchemaUpdate } from 'schemas/sellingtimes';
import { useNotification } from 'providers/NotificationProvider';
import { useGetLinkedGrabCategories } from 'hooks/api/sellingtimes/useGetLinkedGrabCategories';
import { useUpdateOneSellingTime } from 'hooks/api/sellingtimes/useUpdateOneSellingTime';
import { useGetOneSellingTime } from 'hooks/api/sellingtimes/useGetOneSellingTime';
import { useParams } from 'providers/RouterProvider';
import { useModals } from 'providers/ModalsProvider';
import Icon from '../../../assets/icons';
import { LinkedSellingTimeModal, DeleteSellingTimeModal } from './Modals';
import { isSellingTimeValid } from './helpers/isSellingTimeValid';

export const EditSellingTime = () => {
	const { id } = useParams();
	const toast = useNotification();
	const modal = useModals();
	const navigate = useNavigate();
	const { selectedBrand, setDirty } = useAppState();
	const { data: response_selling_time, isFetching } = useGetOneSellingTime(
		Number(id)
	);
	const { mutateAsync: updateSellingTime } = useUpdateOneSellingTime();
	const { refetch: checkLinks } = useGetLinkedGrabCategories(Number(id));

	const onDeleteButtonClickHandler = async () => {
		await checkLinks().then((e) => {
			const data = e?.data ?? [];

			if (data?.categories?.length > 0) {
				modal.showModal({
					title: '',
					body: <LinkedSellingTimeModal close={modal.closeModal} data={data} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteSellingTimeModal
							close={modal.closeModal}
							data={{
								id: Number(id),
								name: response_selling_time?.selling_time?.name ?? '',
							}}
						/>
					),
				});
			}
		});
	};

	const initialValues = {
		id: response_selling_time?.selling_time?.id ?? 0,
		name: response_selling_time?.selling_time?.name ?? '',
		brand_id: response_selling_time?.selling_time?.brand_id ?? selectedBrand,
		startTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		endTime: dayjs(new Date()).add(3, 'years').format('YYYY-MM-DD HH:mm:ss'),
		serviceHours: {
			mon: response_selling_time?.selling_time?.serviceHours?.mon ?? {
				openPeriodType: 'CloseAllDay',
			},
			tue: response_selling_time?.selling_time?.serviceHours?.tue ?? {
				openPeriodType: 'CloseAllDay',
			},
			wed: response_selling_time?.selling_time?.serviceHours?.wed ?? {
				openPeriodType: 'CloseAllDay',
			},
			thu: response_selling_time?.selling_time?.serviceHours?.thu ?? {
				openPeriodType: 'CloseAllDay',
			},
			fri: response_selling_time?.selling_time?.serviceHours?.fri ?? {
				openPeriodType: 'CloseAllDay',
			},
			sat: response_selling_time?.selling_time?.serviceHours?.sat ?? {
				openPeriodType: 'CloseAllDay',
			},
			sun: response_selling_time?.selling_time?.serviceHours?.sun ?? {
				openPeriodType: 'CloseAllDay',
			},
		},
	};

	return (
		<ViewContainer>
			<Breadcrumbs
				items={[
					{
						label: 'Selling Times',
						onClick: () => navigate(ProductRoutes.SellingTimes),
					},
					{
						label: response_selling_time?.selling_time?.name ?? '',
						onClick: () => navigate('#'),
					},
				]}
			/>

			{!isFetching && (
				<Formik
					initialValues={initialValues}
					validate={(values) => {
						try {
							SellingTimesSchemaUpdate.parse(values);
						} catch (error: any) {
							return error.formErrors.fieldErrors;
						}
					}}
					onSubmit={async (values) => {
						const { serviceHours, ...rest } = values;

						// // Check if any day has either startTime or endTime only
						if (!isSellingTimeValid(serviceHours)) {
							toast.error(
								'Days with Open Periods must have both start and end time.'
							);
						} else {
							await updateSellingTime(values)
								.then(() => {
									toast.success('Selling time successfully updated');
									navigate(ProductRoutes.SellingTimes, {}, true);
								})
								.catch(() => {
									toast.error('An error occured');
								});
						}
					}}
				>
					{({ values, errors, touched, dirty, setFieldValue, submitForm }) => {
						React.useEffect(() => {
							if (dirty) {
								setDirty(true);
							}

							return () => setDirty(false);
						}, [dirty]);

						return (
							<Div style={{ marginTop: '20px' }}>
								<Box style={{ position: 'relative', marginBottom: '20px' }}>
									<SelectBrand defaultValue={values?.brand_id} disabled />
									{/* <JsonInput
										value={JSON.stringify({ values, errors }, null, 2)}
										autosize
										maxRows={20}
									/> */}

									<Button
										style={{
											display: 'inline-flex',
											position: 'absolute',
											right: 0,
											top: 0,
											gap: '15px',
										}}
										color="red"
										leftIcon={<Icon name="delete" />}
										onClick={onDeleteButtonClickHandler}
									>
										Delete
									</Button>
								</Box>

								<Divider my="20px" />

								<Stack style={{ margin: '20px 0' }}>
									<Field
										width="600px"
										as={FormTextInput}
										name="name"
										label="Selling Time Name"
										isRequired={true}
										maxLength={80}
										error={errors.name && touched.name ? errors.name : null}
									/>
								</Stack>

								<ServiceHoursList
									setFieldValue={setFieldValue}
									serviceHours={values?.serviceHours}
								/>

								<Group
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										margin: '20px 0',
									}}
								>
									<Button onClick={submitForm}>Save Changes</Button>
									<Button
										color="gray.3"
										style={{ color: 'black' }}
										onClick={() => navigate(ProductRoutes.SellingTimes)}
									>
										Cancel
									</Button>
								</Group>
							</Div>
						);
					}}
				</Formik>
			)}
		</ViewContainer>
	);
};

import React from 'react';
import {
	CheckBoxGrid,
	Div,
	Stack,
	useFormikContext,
} from '../../../components';
import { TCreateRole } from '../../../schemas/roles.schema';

const DashboardPanel = () => {
	const { setFieldValue, values } = useFormikContext<TCreateRole>();
	return (
		<Stack direction="column" gap={30} align="left">
			<CheckBoxGrid
				title="Products"
				initialValues={values?.permissions?.product}
				headers={[
					{ label: 'Create', value: 'Create' },
					{ label: 'Edit', value: 'Edit' },
					{ label: 'Archive', value: 'Archive' },
					{ label: 'Delete', value: 'Delete' },
					{ label: 'View', value: 'View' },
				]}
				rows={[
					{ label: 'Menu', value: 'Menu' },
					{ label: 'Categories', value: 'Categories' },
					{ label: 'Category Groups', value: 'CategoryGroups' },
					{ label: 'Option Groups', value: 'OptionGroups' },
					{ label: 'Kitchen Groups', value: 'KitchenGroups' },
				]}
				onChange={(values) => {
					setFieldValue('permissions.product', values);
				}}
				omit={[
					'Menu.Create',
					'Menu.Archive',
					'Menu.Delete',
					'KitchenGroups.Archive',
					'CategoryGroups.Archive',
				]}
				// showSelectAll
        // We do not let user to "select all". Because having that feature will mean, we need to programmatically check certain conditions and auto-check or auto-uncheck certain checkboxes for the user. That is quite difficult and complex. Negotiated with product to remove this for now.
			/>
			<CheckBoxGrid
				title="Configuration"
				initialValues={values?.permissions?.configuration}
				headers={[
					{ label: 'Create', value: 'Create' },
					{ label: 'Edit', value: 'Edit' },
					{ label: 'Archive', value: 'Archive' },
					{ label: 'Delete', value: 'Delete' },
					{ label: 'View', value: 'View' },
				]}
				rows={[
					{ label: 'Companies', value: 'Companies' },
					{ label: 'Brands', value: 'Brands' },
					{ label: 'Outlets', value: 'Outlets' },
				]}
				onChange={(values) => {
					setFieldValue('permissions.configuration', values);
				}}
				// showSelectAll
			/>

			<CheckBoxGrid
				title="Point of Sales"
				initialValues={values?.permissions?.pointOfSales}
				headers={[
					{ label: 'Create', value: 'Create' },
					{ label: 'Edit', value: 'Edit' },
					{ label: 'Archive', value: 'Archive' },
					{ label: 'Delete', value: 'Delete' },
					{ label: 'View', value: 'View' },
				]}
				rows={[
					{ label: 'Point of Sales List', value: 'PosList' },
					{
						label: 'Point of Sales Configuration',
						value: 'PosConfig',
					},
					{ label: 'Sync to POS', value: 'PosSync' },
					{ label: 'Orders', value: 'Orders' },
					{ label: 'Closings', value: 'Closings' },
				]}
				onChange={(values) => {
					setFieldValue('permissions.pointOfSales', values);
				}}
				// showSelectAll
				omit={[
					'PosList.Create',
					'PosList.Edit',
					'PosList.Archive',
					'PosList.Delete',
					'PosConfig.Archive',
					'PosSync.Create',
					'PosSync.View',
					'PosSync.Archive',
					'PosSync.Delete',
					'Orders.Create',
					'Orders.Archive',
					'Orders.Delete',
					'Closings.Create',
					'Closings.Delete',
					'Closings.Archive',
				]}
			/>
			<CheckBoxGrid
				title="Payment"
				initialValues={values?.permissions?.payment}
				headers={[
					{ label: 'Create', value: 'Create' },
					{ label: 'Edit', value: 'Edit' },
					{ label: 'Archive', value: 'Archive' },
					{ label: 'Delete', value: 'Delete' },
					{ label: 'View', value: 'View' },
				]}
				rows={[
					{ label: 'Payment methods', value: 'PaymentMethods' },
					{
						label: 'Discounts',
						value: 'Discounts',
					},
				]}
				onChange={(values) => {
					setFieldValue('permissions.payment', values);
				}}
				// showSelectAll
				omit={['PaymentMethods.Archive', 'Discounts.Archive']}
			/>

			<CheckBoxGrid
				title="Permissions"
				initialValues={values?.permissions?.permissions}
				headers={[
					{ label: 'Create', value: 'Create' },
					{ label: 'Edit', value: 'Edit' },
					{ label: 'Archive', value: 'Archive' },
					{ label: 'Delete', value: 'Delete' },
					{ label: 'View', value: 'View' },
				]}
				rows={[
					{ label: 'Users', value: 'Users' },
					{ label: 'Roles', value: 'Roles' },
				]}
				onChange={(values) => {
					setFieldValue('permissions.permissions', values);
				}}
				// showSelectAll
				omit={['Users.Archive', 'Roles.Archive']}
			/>
			<Div>
				<CheckBoxGrid
					title="Reports"
					initialValues={values?.permissions?.reports}
					headers={[
						{ label: 'Download', value: 'Download' },
						{ label: 'View', value: 'View' },
					]}
					rows={[
						{ label: 'Finance', value: 'Finance' },
						{ label: 'Operations', value: 'Operations' },
					]}
					onChange={(values) => {
						setFieldValue('permissions.reports', values);
					}}
					// showSelectAll
				/>
			</Div>
			<Div>
				<CheckBoxGrid
					title="Reports"
					initialValues={values?.permissions?.analytics}
					headers={[{ label: 'View', value: 'View' }]}
					rows={[{ label: 'Analytics', value: 'Analytics' }]}
					onChange={(values) => {
						setFieldValue('permissions.analytics', values);
					}}
				/>
			</Div>
		</Stack>
	);
};

export default DashboardPanel;

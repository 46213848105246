import styled from '@emotion/styled'
import { ShowProps } from './Show';

export const StyledShow = styled.span<ShowProps>`
	/* Hide the element by default */
	display: none;

	${({ largerThan }) =>
		largerThan &&
		`
  @media (min-width: ${largerThan}px) {
    display: block;
  }`}

	${({ smallerThan }) =>
		smallerThan &&
		`
  @media (max-width: ${smallerThan}px) {
    display: block;
  }`}
`;

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetDiscountById(id: number) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['discounts', id],
		async () =>
			configApi
				.get(`/discounts/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!id }
	);
}

import React, { useState } from 'react';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'providers/RouterProvider';
import { Field, Formik } from 'formik';
import {
	Alert,
	Button,
	Box,
	FormTextInput,
	LastUpdatedLabel,
	Stack,
	Spinner,
	ViewContainer,
	CancelButton,
} from 'components';
import {
	useGetOneCompany,
	useUpdateCompany,
	useUnarchiveCompany,
	useCheckLinks,
} from 'hooks/companies';
import {
	CompanyUpdateInput,
	CompanyUpdateSchema,
	CompanyUpdateSchemaWithBC,
} from 'schemas/company.schema';
import {
	LinkedCompanyModal,
	DeleteCompanyModal,
	ArchiveCompanyModal,
} from './Modals';
import { useModals } from 'providers/ModalsProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from 'providers/AppStateProvider';
import Icon from '../../../assets/icons';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';

const CompaniesView = () => {
	useDisableSelectCompany();
	const toast = useNotification();
	const modal = useModals();
	const { user } = useAuth();
	const { setDirty } = useAppState();

	// if (!user?.permissions.includes('Companies.View')) {
	// 	toast.error('403 Forbidden');
	// 	return <Navigate replace to="/configuration/companies" />;
	// }

	// const hasEditRights = user?.permissions.includes('Companies.Edit');

	const hasEditRights = true;
	const { id } = useParams();
	const { data: company, status, isFetching } = useGetOneCompany(Number(id));
	const { mutateAsync: updateOneCompany } = useUpdateCompany();
	const { mutateAsync: unarchiveCompany } = useUnarchiveCompany();

	const { refetch: checkLinks } = useCheckLinks(Number(id));

	const navigate = useNavigate();

	// Check if this company is attached to any brands, outlets, users etc before delete
	const onDeleteButtonClickHandler = async () => {
		await checkLinks().then((e) => {
			const data = e.data.data;
			if (
				data?.brands?.length > 0 ||
				data?.outlets?.length > 0 ||
				data?.pos?.length > 0 ||
				data?.users?.length > 0
			) {
				const _data = { ...data, action: 'deleting' };

				modal.showModal({
					title: '',
					body: <LinkedCompanyModal close={modal.closeModal} data={_data} />,
				});
			} else {
				modal.showModal({
					title: '',
					body: (
						<DeleteCompanyModal
							close={modal.closeModal}
							data={{ id: Number(id), name: company.name }}
						/>
					),
				});
			}
		});
	};

	// Check if this company is attached to any brands, outlets, users etc before archiving
	const onArchiveButtonClickHandler = async () => {
		if (!company.is_archived) {
			await checkLinks().then((e) => {
				const data = e.data.data;
				if (
					data?.brands?.length > 0 ||
					data?.outlets?.length > 0 ||
					data?.pos?.length > 0 ||
					data?.users?.length > 0
				) {
					const _data = { ...data, action: 'archiving' };

					modal.showModal({
						title: '',
						body: <LinkedCompanyModal close={modal.closeModal} data={_data} />,
					});
				} else {
					modal.showModal({
						title: '',
						body: (
							<ArchiveCompanyModal
								close={modal.closeModal}
								data={{ id: Number(id), name: company.name }}
							/>
						),
					});
				}
			});
		} else {
			unarchiveCompany(Number(id))
				.then(() => {
					navigate('/configuration/companies');
					toast.success('Company has been successfully unarchived');
				})
				.catch(() => {
					toast.error('An error occured');
				});
		}
	};

	if (status === 'loading' || isFetching) {
		return (
			<Box>
				<Spinner />
			</Box>
		);
	}

	const breadcrumbsArray = [
		{
			label: 'Companies',
			onClick: () => navigate('/configuration/companies'),
		},
		company.is_archived && {
			label: 'Archived',
			onClick: () => navigate('/configuration/companies/archived'),
		},
		{
			label: company.name,
			onClick: () => navigate('#'),
		},
	];

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Breadcrumbs items={breadcrumbsArray} />
				{hasEditRights && (
					<Box
						style={{
							display: 'inline-flex',
							position: 'absolute',
							right: 0,
							top: 0,
							gap: '15px',
						}}
					>
						<Button
							color="red"
							leftIcon={<Icon name="delete" />}
							onClick={onDeleteButtonClickHandler}
						>
							Delete
						</Button>

						<Button
							color="gray.3"
							style={{ color: 'black' }}
							onClick={onArchiveButtonClickHandler}
						>
							{company.is_archived ? 'Unarchive' : 'Archive'}
						</Button>
					</Box>
				)}
			</Box>
			{company.is_archived && (
				<Alert>{'You are viewing an archived (read only) version'}</Alert>
			)}
			<Formik
				initialValues={{
					id: company?.id ?? 0,
					name: company?.name ?? '',
					uen: company?.uen ?? '',
					has_bc_integration: company?.has_bc_integration ?? '',
					bc_name: company?.bc_name ?? '',
					gst_number: company?.gst_number === '-' ? '' : company?.gst_number,
					client_id: company?.client_id ?? '',
					client_secret: company?.client_secret ?? '',
					updated_by: user?.name ?? '',
				}}
				validate={(values) => {
					try {
						company.has_bc_integration
							? CompanyUpdateSchemaWithBC.parse(values)
							: CompanyUpdateSchema.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					await updateOneCompany(values)
						.then(() => {
							setDirty(false);
							navigate('/configuration/companies', {}, true);
							toast.success('Company has been successfully updated');
						})
						.catch((e) => {
							toast.error(`${e.response.status} error occured`);
						});

					actions.setSubmitting(false);
				}}
			>
				{({ values, errors, touched, dirty, submitForm }) => {
					React.useEffect(() => {
						if (dirty) {
							setDirty(true);
						}

						return () => setDirty(false);
					}, [dirty]);
					return (
						<Box>
							<Stack direction="row">
								<Stack direction="column" gap={10}>
									<Field
										width="940px"
										as={FormTextInput}
										name="name"
										label="Company Name"
										isRequired={true}
										isReadOnly={company.is_archived && hasEditRights}
										maxLength={80}
										hint={'Company is the business entity that owns Brands'}
										error={errors.name && touched.name ? errors.name : null}
									/>
									{company.has_bc_integration && (
										<Field
											width="940px"
											as={FormTextInput}
											name="bc_name"
											label="BC Company Name"
											isRequired={true}
											isReadOnly={company.is_archived && hasEditRights}
											hint={
												'BC Company Name must be the same as defined in Microsoft Business Central'
											}
											maxLength={80}
											error={
												errors.bc_name && touched.bc_name
													? errors.bc_name
													: null
											}
										/>
									)}

									<Field
										width="940px"
										as={FormTextInput}
										name="uen"
										label="UEN"
										isRequired={true}
										isReadOnly={company.is_archived && hasEditRights}
										hint={'Unique Entity Number'}
										maxLength={30}
										error={errors.uen && touched.uen ? errors.uen : null}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="gst_number"
										label="GST Number"
										isReadOnly={company.is_archived && hasEditRights}
										maxLength={30}
										error={errors.gst_number}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="client_id"
										label="Grab Client ID"
										type={'password'}
										maxLength={60}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="client_secret"
										label="Grab Client Secret"
										type={'password'}
										maxLength={60}
									/>
									{!company.is_archived && hasEditRights && (
										<Stack
											width={'100%'}
											style={{ display: 'flex', justifyContent: 'flex-start' }}
										>
											<Button onClick={submitForm}>Save Changes</Button>
											<CancelButton
												onClick={() => navigate('/configuration/companies')}
											>
												Cancel
											</CancelButton>
										</Stack>
									)}
									<Box
										style={{
											alignSelf: 'flex-start',
										}}
										>
										<LastUpdatedLabel
											lastUpdated={company?.updated_at ?? new Date()}
											editor={values?.updated_by ?? ''}
										/>
									</Box>
								</Stack>
								
							</Stack>
						</Box>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default CompaniesView;

import { useQuery } from 'react-query';
import { txReadApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetClosings({
	limit,
	outlet_id,
	pos_ids,
}: Record<string, string>) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['closings', limit, pos_ids, outlet_id],
		async () =>
			txReadApi
				.get(`/closings/dashboard`, {
					params: {
						limit,
						outlet_id,
						pos_ids,
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!limit && !!pos_ids,
		}
	);
}

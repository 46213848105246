import {z} from 'zod'
import { BaseSchema } from './base.schema'

const Period = z.object({
    startTime: z.string().optional(),
    endTime: z.string().optional(),
})

const ServiceHourObject = z.object({
    openPeriodType: z.string().optional(),
    periods: z.array(Period).optional()
    
})

export const SellingTimesSchema = BaseSchema.extend({
    name: z.string(
        {required_error: 'This field is required.'}
    ).min(1,'This field is required.')
    .max(80, 'Only a maximum of 80 characters is allowed'),
    brand_id: z.number(),
    startTime: z.string(),
    endTime: z.string(),
    serviceHours: z.object({
        mon: ServiceHourObject.optional(),
        tue: ServiceHourObject.optional(),
        wed: ServiceHourObject.optional(),
        thu: ServiceHourObject.optional(),
        fri: ServiceHourObject.optional(),
        sat: ServiceHourObject.optional(),
        sun: ServiceHourObject.optional()
    })    
})

export const SellingTimesSchemaCreate = SellingTimesSchema.pick({
    name: true, brand_id: true, startTime: true, endTime: true, serviceHours: true
})

export const SellingTimesSchemaUpdate = SellingTimesSchema.pick({
    id: true, name: true, brand_id: true, startTime: true, endTime: true, serviceHours: true
})
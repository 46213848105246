import { useQuery, useMutation, useQueryClient } from 'react-query';
import { configApi } from '../apis';
import { useAppState } from '../providers/AppStateProvider';
import { useAuth } from '../providers/AuthProvider';

type BrandType = {
	id?: number | string;
	name: string;
	company_id: number;
	image_url?: string;
	sales_tax: number;
	is_sales_tax_in_item_price: boolean;
};

export function useGetBrands(company_id: number) {
	const { getAccessToken } = useAuth();
	const getBrands = async () => {
		const response = await configApi.get(
			`brands?summary=yes&company_id=${company_id}&archived=no&deleted=no`,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	return useQuery(['brands', company_id], getBrands, {
		refetchOnWindowFocus: false,
		enabled: !!company_id,
	});
}

export function useGetArchivedBrands(company_id: number) {
	const { getAccessToken } = useAuth();
	const getBrands = async () => {
		const response = await configApi.get(
			`brands?summary=yes&company_id=${company_id}&archived=yes&deleted=no`,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	return useQuery(['brands_archived', company_id], getBrands, {
		refetchOnWindowFocus: false,
		enabled: !!company_id,
	});
}

export function useGetBrandsForDropdown(company_id: number | null) {
	const { setBrand } = useAppState();
	const { getAccessToken } = useAuth();
	const getBrands = async () => {
		const response = await configApi.get(
			`brands?summary=yes&company_id=${company_id}&deleted=no&archived=no&dropdown=yes`,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	return useQuery(['brands_dropdown', company_id], getBrands, {
		refetchOnWindowFocus: false,
		enabled: !!company_id,
		onSuccess: (data) => {
			if (data?.brands?.length) {
				setBrand(data?.brands?.at(0)?.id);
			} else {
				setBrand(0);
			}
		},
	});
}

export function useGetOneBrand(id: number) {
	const { getAccessToken } = useAuth();
	const getOneBrand = async () => {
		const response = await configApi.get(`brands/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['brands_by_id', id], getOneBrand, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function useCreateNewBrand() {
	const { getAccessToken } = useAuth();
	const createNewBrand = async (body: BrandType) => {
		const newBrand = { ...body };

		const response = await configApi.post('brands', newBrand, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(createNewBrand, {
		onSuccess: () => {
			queryClient.invalidateQueries('brands');
			queryClient.invalidateQueries('brands_dropdown');
		},
	});
}

export function useUpdateBrand() {
	const { getAccessToken } = useAuth();
	const updateBrand = async (body: BrandType) => {
		const id = body.id;
		const updatedBrand = { ...body, updated_at: new Date() };

		const response = await configApi.put(`brands/${id}`, updatedBrand, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateBrand, {
		onSuccess: () => {
			queryClient.invalidateQueries('brands');
			queryClient.invalidateQueries('brands_dropdown');
		},
	});
}

export function useDeleteBrand() {
	const { getAccessToken } = useAuth();
	const deleteOneBrand = async (id: number) => {
		const response = await configApi.delete(`brands/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOneBrand, {
		onSuccess: () => {
			queryClient.invalidateQueries('brands');
			queryClient.invalidateQueries('brands_dropdown');
		},
	});
}

export function useArchiveBrand() {
	const { getAccessToken } = useAuth();
	const archiveBrand = async (id: number) => {
		const response = await configApi.post(`brands/${id}/archive`,{}, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(archiveBrand, {
		onSuccess: () => {
			queryClient.invalidateQueries('brandsArchived');
			queryClient.invalidateQueries('brands_dropdown');
		},
	});
}

export function useUnarchiveBrand() {
	const { getAccessToken } = useAuth();
	const unarchiveCompany = async (id: number) => {
		const response = await configApi.post(`brands/${id}/unarchive`,{}, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(unarchiveCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('brandsArchived');
			queryClient.invalidateQueries('brands_dropdown');
		},
	});
}

export  function useCheckLinks(id: number) {
	const { getAccessToken } = useAuth();
	const  getLinks = async() => {

		const response = await configApi.get(`brands/${id}/checklinks`,{
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	}

	return useQuery(['brands_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})
}

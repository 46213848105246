import { Add } from '@mui/icons-material';
import React from 'react';
import {
	ActionIcon,
	Breadcrumbs,
	Button,
	Div,
	EmptyState,
	Menu,
	SelectBrand,
	Stack,
	Text,
	ViewContainer,
} from 'components';
import { SimpleDataGrid } from '../../components/SimpleDataGrid';
import Icon from 'assets/icons';
import { useGetOptionGroupsList } from 'hooks/optiongroups';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from 'providers/AppStateProvider';
import { useModals } from 'providers/ModalsProvider';
import { CopyOptionGroupModal } from './OptionGroups/Modals';
import { useGetBrands } from 'hooks/brands';
import { useAuth } from '../../providers/AuthProvider';
import RequirePermissions from '../../guards/RequirePermissions';

const OptionGroups = () => {
	const { permissions } = useAuth();
	const { selectedBrand, selectedCompany } = useAppState();
	const modal = useModals();
	const navigate = useNavigate();
	const { data: response_optiongroups, isFetching: isFetchingOptionGroups } =
		useGetOptionGroupsList(selectedBrand ?? 0);

	const { data: response_brands } = useGetBrands(Number(selectedCompany));

	const breadcrumbsArray = [
		{
			label: 'Option Groups',
			onClick: () => navigate('#'),
		},
	];

	const onClickHandler = (row: any) => {
		navigate(`/products/optiongroups/${row.original.id}`, {}, true);
	};

	const openCopyModalHandler = (id: number) => {
		modal.showModal({
			title: '',
			body: <CopyOptionGroupModal close={modal.closeModal} id={id} />,
			props: {
				style: { width: 'fit-content' },
			},
		});
	};

	return (
		<ViewContainer>
			<Stack gap={30} direction="column" width={'100%'}>
				<Breadcrumbs
					items={breadcrumbsArray}
					style={{ display: 'flex', alignSelf: 'flex-start' }}
				/>
				<Div style={{ position: 'relative', width: '100%' }}>
					<SelectBrand />
					<Stack
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
						}}
					>
						<RequirePermissions permissions={['OptionGroups.Archive']}>
							<Button
								color="gray.2"
								style={{ color: 'black' }}
								type="button"
								leftIcon={<Icon name="archive" />}
								onClick={() => navigate('#')}
							>
								Archived
							</Button>
						</RequirePermissions>
						{response_brands?.brands?.length ? (
							<RequirePermissions permissions={['OptionGroups.Create']}>
								<Button
									type="button"
									leftIcon={<Icon name="add" />}
									onClick={() => navigate('/products/optiongroups/create')}
								>
									Create Option Group
								</Button>
							</RequirePermissions>
						) : null}
					</Stack>
				</Div>
				{/* <pre>{JSON.stringify(selectedBrand, null, 2)}</pre> */}
				{/* <pre>{JSON.stringify(response_optiongroups, null, 2)}</pre> */}
				{/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
				<SimpleDataGrid
					data={response_optiongroups?.option_groups ?? []}
					columns={[
						{
							accessorKey: 'name',
							header: 'Name',
						},
						{
							accessorKey: 'option_names',
							header: 'Options',
							cell: (row: any) => {
								return (
									<Text style={{ color: '#5F6368' }}>
										{row.getValue().join(', ')}
									</Text>
								);
							},
						},
						{
							accessorKey: 'id',
							header: '',
							cell: ({ row }: any) => {
								return (
									<>
										<Menu position="bottom-start">
											<Menu.Target>
												<ActionIcon>
													<Icon name="morehort" />
												</ActionIcon>
											</Menu.Target>
											<Menu.Dropdown>
												<RequirePermissions permissions={['OptionGroups.Edit']}>
													<Menu.Item
														onClick={() => {
															openCopyModalHandler(row.original.id);
														}}
													>
														Make a copy
													</Menu.Item>
												</RequirePermissions>
											</Menu.Dropdown>
										</Menu>
									</>
								);
							},
							meta: {
								isNotClickable: true,
							},
						},
					]}
					onClickHandler={onClickHandler}
				/>
				{response_brands?.brands?.length === 0 ? (
					<EmptyState
						resource="brands"
						secondaryMessage="Please create Brand before creating Option Group."
						CallToAction={
							permissions?.includes('Brands.Create') && (
								<Button
									type="button"
									leftIcon={<Add fontSize="inherit" />}
									onClick={() => navigate('/configuration/brands/create')}
								>
									Create Brand
								</Button>
							)
						}
					/>
				) : response_optiongroups?.option_groups?.length === 0 ? (
					<EmptyState resource="option groups" />
				) : null}
			</Stack>
		</ViewContainer>
	);
};

export default OptionGroups;

import { useMutation, useQueryClient } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

type TPaymentMethod = {
	paymentmethod_id: number;
	sort_order: number;
};

type TUseCreatePos = {
	name: string;
	grab_store_id: string;
	outlet_id: number;
	service_charge: number;
	is_service_charge_in_item_price: boolean;
	has_quick_sign_in: boolean;
	is_display_cash_denominations: boolean;
	is_display_product_price: boolean;
	cash_exact_type: string;
	has_manager_pin_on_view_report: boolean;
	is_24h: boolean;
	op_opening_time: string;
	op_closing_time: string;
	closing_grace_period_hour: number;
	number_of_shift: number;
	is_change_shift_mandatory: boolean;
	is_equal_shift_interval: boolean;
	change_shift_intervals: string[];
	payment_methods: TPaymentMethod[];
	default_opening_cash: number;
	discounts: number[];
	is_preview_receipt: boolean;
	num_receipt_per_transaction: number;
	is_display_queue: boolean;
	is_allow_zero_cash_collection: boolean;
	authorised_difference: number;
	has_report_preview_before_confirm: boolean;
	advertisement_duration_seconds: number;
	updated_by: string;
};

export function useCreateNewPos() {
	const queryClient = useQueryClient();
	const { getAccessToken } = useAuth();

	const createNewPos = async (body: TUseCreatePos) => {
		const response = await configApi.post('pos', body, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useMutation(createNewPos, {
		onSuccess: () => {
			queryClient.invalidateQueries('pos');
		},
	});
}

import React from 'react';
import { useAppState } from '../providers/AppStateProvider';

export function useDisableSelectCompany() {
	const { toggleSelectCompany } = useAppState();
	React.useEffect(() => {
		toggleSelectCompany();
		return () => {
			toggleSelectCompany();
		};
	}, []);
}

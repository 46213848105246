import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useEditPaymentMethod() {
	const { getAccessToken } = useAuth();

	const editOnePaymentMethod = async ({
		paymentMethodId,
		updatedPaymentMethod,
	}: {
		paymentMethodId: string;
		updatedPaymentMethod: any;
	}) => {
		const response = await configApi.put(
			`payment-methods/${paymentMethodId}`,
			updatedPaymentMethod,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return response.data;
	};

	return useMutation(editOnePaymentMethod);
}

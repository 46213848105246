import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Div, Spinner, Stack, Text, Title } from '../../components';
import { useGetPos } from '../../hooks/api/pos/useGetPos';
import { useSyncPos } from '../../hooks/socket/useSyncPos';
import { useAppState } from '../../providers/AppStateProvider';
import { useModals } from '../../providers/ModalsProvider';

type Props = {
	posIds: string[];
};

const PosSync = ({ posIds }: Props) => {
	const { mutate } = useSyncPos();
	const { closeModal } = useModals();
	const { selectedBrand } = useAppState();
	const { data: posData } = useGetPos({ brandId: selectedBrand || 0 });

	React.useEffect(() => {
		Promise.allSettled(
			posIds.map((e: string) => {
				const eventUuid = uuidv4();
				mutate({
					posId: e,
					posName: posData?.pos?.find((each: any) => each.id === e)['name'],
					event_uuid: eventUuid,
				});
			})
		).then(() => {
			closeModal();
		});
	}, []);

	return (
		<>
			<Div
				css={(theme) => ({
					borderTopLeftRadius: '20px',
					borderTopRightRadius: '20px',
					background: theme.colors.grey10,
					color: theme.colors.grey30,
					padding: '16px',
				})}
			>
				<Text
					w="100%"
					style={{
						textAlign: 'center',
					}}
				>
					While you wait, do not close the screen
				</Text>
			</Div>
			<Stack
				direction="column"
				align="center"
				width="100%"
				css={{ padding: '36px' }}
			>
				<Title>Syncing to POS</Title>
				<Spinner color="primary" size="40px" style={{ margin: '20px 0px' }} />
			</Stack>
		</>
	);
};

export default PosSync;

import styled from '@emotion/styled'
import { InputRadioProps } from './InputRadio';

export const StyledInputRadio = styled.input<InputRadioProps>`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	border-radius: 25px;
	width: 0px;
	height: 22px;
	cursor: pointer;

	:checked ~ span[data-label='styled-radiomark'] {
		background-color: ${({ theme }) => theme.colors.white};
		border: 2.5px solid ${({ theme }) => theme.colors.secondary};
	}

	:checked ~ span[data-label='styled-radiomark']::after {
		display: block;
	}
`;

export const StyledRadiomark = styled.span`
	position: absolute;
	border-radius: 25px;
	height: 22px;
	width: 22px;
	border: 2.5px solid ${({ theme }) => theme.colors.grey30};
	cursor: pointer;

	:after {
		content: '';
		position: absolute;
		display: none;
		top: 2.5px;
		left: 2.5px;
		width: 12px;
		height: 12px;
		border-radius: 25px;
		background: ${({ theme }) => theme.colors.secondary};
	}
`;

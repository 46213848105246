import { useQuery } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../providers/AuthProvider';

type DiscountType = {
	id: number;
	name: string;
	description: string;
	status: string;
	start_date: string;
	end_date: string;
};

export function useGetDiscounts(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getDiscounts = async () => {
		const response = await configApi.get(`discounts`, {
			params: {
				brand_id,
				summary: 'yes',
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['discounts', brand_id], getDiscounts, {
		refetchOnWindowFocus: false,
		enabled: !!brand_id,
	});
}

export function useGetProductDiscounts(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getDiscounts = async () => {
		const response = await configApi.get(`discounts`, {
			params: {
				brand_id,
				summary: 'yes',
				scope: 'product_discount'
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['discounts', brand_id], getDiscounts, {
		refetchOnWindowFocus: false,
		enabled: !!brand_id,
	});
}

import React from 'react';
import AppContainer from './layouts/AppContainer';
import AppStateProvider from './providers/AppStateProvider';
import AuthProvider from './providers/AuthProvider';
import ModalsProvider from './providers/ModalsProvider';
import NotificationProvider from './providers/NotificationProvider';
import QueryProvider from './providers/QueryProvider';
import SocketProvider from './providers/SocketProvider';
import ThemeProvider from './providers/ThemeProvider';

const App = () => {
	return (
		<QueryProvider>
			<AppStateProvider>
				<AuthProvider>
					<ThemeProvider>
						<NotificationProvider>
							<ModalsProvider>
								<SocketProvider>
									<AppContainer />
								</SocketProvider>
							</ModalsProvider>
						</NotificationProvider>
					</ThemeProvider>
				</AuthProvider>
			</AppStateProvider>
		</QueryProvider>
	);
};

export default App;

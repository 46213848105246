import { useMutation, UseMutationOptions } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useCreateProduct(
	opts?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn'>
) {
	const { getAccessToken } = useAuth();
	return useMutation(
		async (values: any) =>
			configApi
				.post('/products', values, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		opts
	);
}

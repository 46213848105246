import React from 'react';
import {
	Box,
	Input,
	Tooltip,
	ActionIcon,
	Group,
	Text,
	Select,
	SelectProps,
} from '@mantine/core';

import Icon from '../assets/icons';

export type SearchableDropdownFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
} & SelectProps;

export const SearchableDropdownField = React.forwardRef<
	HTMLDivElement,
	SearchableDropdownFieldProps
>(({ label, error, hint, required, ...rest }, ref) => {
	return (
		<Box
			ref={ref}
			sx={{
				display: 'grid',
				gridTemplateColumns: '240px 1fr',
				alignItems: 'flex-start',
			}}
		>
			<div>
				<Input.Label required={required} mt="15px" mr="10px">
					{label}
				</Input.Label>
				{!hint ? null : (
					<Tooltip label={hint} position="bottom-start">
						<ActionIcon
							display="inline-block"
							variant="transparent"
							color="dark"
						>
							<Icon name="info" />
						</ActionIcon>
					</Tooltip>
				)}
			</div>
			<Box>
				<Select
					{...rest}
					styles={{
						input: {
							padding: '27px 18px',
						},
					}}
				/>
				{error ? <Input.Error my="10px">{error}</Input.Error> : null}
			</Box>
		</Box>
	);
});

export type BcItemProps = React.ComponentPropsWithoutRef<'div'> & {
	name: string;
	label: string;
	value: string;
};

export const BcItemSelectItem = React.forwardRef<HTMLDivElement, BcItemProps>(
	({ value, name, label, ...others }: BcItemProps, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap>
				<div>
					<Text size="sm">{label}</Text>
					<Text size="xs" opacity={0.65}>
						{name}
					</Text>
				</div>
			</Group>
		</div>
	)
);

BcItemSelectItem.displayName = 'BcItemSelectItem';
SearchableDropdownField.displayName = 'SearchableDropdownField';

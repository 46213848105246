import React from 'react';
import { Stack, Box, Text } from '@mantine/core';
import { useFormikContext } from '../../../../components';
import { useGetBrands } from '../../../../hooks/brands';
import { useGetOneCompany } from '../../../../hooks/companies';
import OutletDropdown from './OutletDropdown';

type Props = {
	companyId: string | number;
};

const BrandOutlet: React.FC<Props> = ({ companyId }) => {
	const { data: company } = useGetOneCompany(companyId as number, 'yes');
	const { data: brands } = useGetBrands(companyId as number);
	const { setFieldValue, values } = useFormikContext<any>();
	return (
		<Box my="lg">
			<Text color="gray" my="md">
				{company?.name}
			</Text>
			{!brands?.brands?.length ? (
				<Text>No brands</Text>
			) : (
				<Stack w="100%">
					{brands?.brands?.map((brand: any) => {
						return (
							<OutletDropdown
								key={brand.id}
								brandId={brand.id}
								brandName={brand.name}
								isAdminAssigned={
									values?.roles?.find((e: any) => e.id === companyId)
										?.is_admin || false
								}
							/>
						);
					})}
				</Stack>
			)}
		</Box>
	);
};

export default BrandOutlet;

import { z } from 'zod';
import { BaseSchema } from './base.schema';

export const CategorySchema = BaseSchema.extend({
	name: z
		.string({
			required_error: 'This field is required.',
		})
		.max(80),
	name_other_languages: z
		.object({
			zh: z.string().max(40).optional(),
			ms: z.string().max(80).optional(),
		})
		.optional(),
	description: z.string().max(1028).optional().default(''),
	dimension_code_suffix: z.string({
		required_error: 'This field is required.',
	}).max(30),
	acct_category: z.string().max(80),
	category_group_id: z.number().nullable().optional(),
	brand_id: z.number().int(),
	is_grab_category: z.boolean(),
	selling_time_id: z.number().nullable(),
	updated_by: z.string().optional().default(''),
	//
	is_archived: z.boolean().nullable(),
	pos_count: z.number().int(),
	pos: z.array(z.string()),
});

export type  TCategorySchema = z.infer<typeof CategorySchema>;

export const CreateCategorySchema = CategorySchema.pick({
	name: true,
	name_other_languages: true,
	description: true,
	acct_category: true,
	category_group_id: true,
	brand_id: true,
	is_grab_category: true,
	selling_time_id: true,
	updated_by: true,
})

export const CreateCategorySchemaWithBC = CategorySchema.pick({
	name: true,
	name_other_languages: true,
	description: true,
	dimension_code_suffix: true,
	acct_category: true,
	category_group_id: true,
	brand_id: true,
	is_grab_category: true,
	selling_time_id: true,
	updated_by: true,
});

export const EditCategorySchema = CreateCategorySchema.extend({
	id: z.number()
})

export const EditCategorySchemaWithBC = CreateCategorySchemaWithBC.extend({
	id: z.number()
})
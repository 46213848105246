import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export const useGetOutlets = (brandId: number) => {
	const { getAccessToken } = useAuth();

	const fetchOutlets = async (brandId: number) => {
		const res = await configApi.get(
			`/outlets?summary=yes&brand_id=${brandId}&dropdown=yes`,
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		return res.data;
	};
	return useQuery(['outlets', brandId], () => fetchOutlets(brandId), {
		enabled: !!brandId,
		select: (data) => data.outlets,
	});
};

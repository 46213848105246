import React from 'react';
import { Box, Grid, Stack, Text, Title } from '@mantine/core';
import { useFormikContext } from '../../../components';
import { useGetCompaniesForDropdown } from '../../../hooks/companies';
import SetCompanyRole from './UserAccess/CompanyRole';

const UserAccess = () => {
	const companies = useGetCompaniesForDropdown();
	const { errors } = useFormikContext<any>();

	const hasCompanyError = errors['roles'];
	return (
		<>
			<Title order={2}>User access</Title>
			<Text color="gray" my="sm">
				Enable the user to see the pages and allowed permissions based on the
				role allocated
			</Text>

			<Grid my="xl">
				<Grid.Col lg={4}>
					<Box>
						<Text>
							Companies{' '}
							<Text component="small" color="red">
								*
							</Text>
						</Text>
						{hasCompanyError && (
							<Text color="red" size="sm" my="sm">
								You must select at least one company and one role
							</Text>
						)}
					</Box>
				</Grid.Col>
				<Grid.Col lg={8}>
					<Stack spacing={0}>
						{companies?.data?.companies?.map((e: any) => {
							return (
								<SetCompanyRole
									key={e.id}
									companyId={e.id}
									companyName={e.name}
								/>
							);
						})}
					</Stack>
				</Grid.Col>
			</Grid>
		</>
	);
};

export default UserAccess;

import React from 'react';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { DefaultProps } from '../types/DefaultProps';
import { StyledSelectBox, StyledSelectLabel } from './FormSelectInput.styled';
import { useSelect } from 'downshift';
import { Chevron } from '../Chevron';
import { UnstyledButton } from '@mantine/core';
import { Paper } from '../Paper';
import { FormCheckBox } from '../FormCheckBox';
import { Div } from '../Div';

export type SelectItem = { value: string; label: string };

export type FormSelectInputProps = Omit<
	DefaultProps<HTMLDivElement>,
	'onChange'
> & {
	error?: React.ReactNode;

	/** Default selection for the user */
	defaultOption?: SelectItem;

	inputWrapperProps?: Omit<InputWrapperProps, 'error' | 'label'>;

	isDisabled?: boolean;

	isInvalid?: boolean;

	/** Open the select dropdown after it renders */
	isOpenInitially?: boolean;

	isRequired?: boolean;

	isValid?: boolean;

	label?: React.ReactNode;

	/** onChange handler returns the selected item */
	onChange?: (selectedItem: SelectItem) => void;

	/** List of available options for selection */
	options: SelectItem[];

	placeholder?: string;

	width?: React.CSSProperties['width'];

	showCheckbox?: boolean;

	dropdownSection?: React.ReactNode;

	reset?: boolean;

	enableReinitialize?: boolean;
};

const defaultProps: Partial<FormSelectInputProps> = {
	width: '100%',
};

export const FormSelectInput = React.forwardRef<
	HTMLDivElement,
	FormSelectInputProps
>(
	(
		{
			placeholder,
			options,
			defaultOption: initialOption,
			isOpenInitially,
			onChange,
			inputWrapperProps,
			label,
			isRequired,
			isDisabled,
			error,
			isInvalid,
			width,
			showCheckbox,
			dropdownSection,
			reset,
			enableReinitialize,
			...rest
		},
		ref
	) => {
		const [defaultOption, setDefaultOption] = React.useState(initialOption);

		const {
			isOpen,
			selectedItem,
			getToggleButtonProps,
			getLabelProps,
			getMenuProps,
			getItemProps,
			reset: resetSelectInput,
		} = useSelect({
			items: options,
			itemToString: (item) => (item ? item.label : ''),
			initialSelectedItem: defaultOption,
			initialIsOpen: isOpenInitially,
			onSelectedItemChange: ({ selectedItem }) => {
				if (onChange && selectedItem) {
					onChange(selectedItem);
				}
			},
		});

		React.useEffect(() => {
			if (initialOption) {
				setDefaultOption(initialOption);
			}
		}, [initialOption]);

		React.useEffect(() => {
			if (reset !== undefined) {
				resetSelectInput();
			}
		}, [reset]);

		const [menuRef, setMenuRef] = React.useState<HTMLElement | null>(null);
		const [selectBoxRef, setSelectBoxRef] =
			React.useState<HTMLButtonElement | null>(null);
		const widthNumber = Number(String(width).replace('px',''))

		return (
			<InputWrapper
				{...inputWrapperProps}
				label={label}
				hasAsterisk={isRequired}
				error={error || isInvalid}
				width={width}
				ref={ref}
				css={{
					opacity: isDisabled ? 0.7 : 1,
				}}
			>
				<StyledSelectBox
					{...getToggleButtonProps({ ref: (ref) => setSelectBoxRef(ref) })}
					isDisabled={isDisabled}
					{...rest}
					width={(String(widthNumber*0.75)+'px')}
				>
					<StyledSelectLabel {...getLabelProps()}>
						{selectedItem?.label ?? placeholder}
					</StyledSelectLabel>
					{!isDisabled ? (
						<Chevron
							isRotate={isOpen}
							style={{ marginLeft: '10px' }}
							color="orange"
						/>
					) : null}
				</StyledSelectBox>

				<Paper
					css={
						isOpen && !isDisabled
							? {
									backgroundColor: '#ffffff',
									padding: '4px 0',
									position: 'absolute',
									width: (String(widthNumber*0.75)+'px') ?? selectBoxRef?.offsetWidth,
									zIndex: 999,
									overflow: 'hidden',
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
							: null
					}
					{...getMenuProps({ ref: (ref) => setMenuRef(ref) })}
				>
					{isOpen && !isDisabled ? (
						<Div style={{ maxHeight: '200px', overflow: 'auto' }}>
							{options?.map((item: SelectItem, index: number) => (
								<UnstyledButton
									key={`${item.value}${index}`}
									{...getItemProps({ item, index })}
									sx={(theme) => ({
										padding: '12px 10px',
										width: (String(widthNumber*0.6)+'px'),
										backgroundColor:
											selectedItem?.value === item.value
												? theme.colors.grey05
												: theme.colors.white,
										':hover':
											selectedItem?.value !== item.value
												? {
														backgroundColor: theme.colors.grey05,
														// eslint-disable-next-line no-mixed-spaces-and-tabs
												  }
												: {},
									})}
								>
									<span>
										{showCheckbox ? (
											<FormCheckBox
												checked={selectedItem?.value === item.value}
												label={item.label}
											/>
										) : (
											item.label
										)}
									</span>
								</UnstyledButton>
							))}
						</Div>
					) : (
						<div style={{ height: 0, visibility: 'hidden' }} />
					)}
					{isOpen && !isDisabled && dropdownSection ? (
						<Div>{dropdownSection}</Div>
					) : null}
				</Paper>
			</InputWrapper>
		);
	}
);

FormSelectInput.displayName = 'FormSelectInput';
FormSelectInput.defaultProps = defaultProps;

import React from 'react';
import { FormCheckBox } from '../FormCheckBox';
import { ListGroup } from '../ListGroup';

type TOption = {
	value: string;
	label: string;
};

export type SelectableListGroupItemProps = {
	option: TOption;
	label: React.ReactNode;
	addSelectedItem: (selectedOption: TOption) => void;
	removeSelectedItem: () => void;
	checked?: boolean;
	rightSection?: React.ReactNode;
};

export const SelectableListGroupItem = React.forwardRef<
	HTMLDivElement,
	SelectableListGroupItemProps
>(
	(
		{ option, label, addSelectedItem, removeSelectedItem, checked, rightSection, ...rest },
		ref
	) => {
		// const [_checked, setChecked] = React.useState<boolean>();

		// React.useEffect(() => {
		// 	if (_checked) {
		// 		addSelectedItem(option);
		// 	} else {
		// 		removeSelectedItem();
		// 	}
		// }, [_checked]);

		// React.useEffect(() => {
		// 	setChecked(checked);
		// }, [checked]);

		return (
			<ListGroup.Item rightSection={rightSection}>
				<FormCheckBox
					label={label}
					checked={checked}
					onChange={(e) => {
						if (e.currentTarget.checked) {
							addSelectedItem(option);
						} else {
							removeSelectedItem();
						}
					}}
				/>
			</ListGroup.Item>
		);
	}
);

SelectableListGroupItem.displayName = 'SelectableListGroupItem';

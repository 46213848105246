import { useMutation, useQuery, useQueryClient } from 'react-query';
import { configApi } from '../apis';
import { useAuth } from '../providers/AuthProvider';

export type TUseGetImages = {
	company_id: string;
	prefix: string;
};

type CreateFolderType = {
	folderName: string;
	prefix: string;
};

export function useGetImages({ company_id, prefix }: TUseGetImages) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['images', { company_id, prefix }],
		async (): Promise<{
			Contents: {
				Key: string;
				LastModified: string;
				ETag: string;
				Size: number;
				StorageClass: string;
				Url: string;
				Filename: string;
			}[];
			CommonPrefixes: { Prefix: string; Folder: string }[];
			Delimiter: string;
			MaxKeys: number;
			KeyCount: number;
			Name: string;
			Prefix: string;
		}> =>
			configApi
				.get(`/images`, {
					params: { company_id, prefix },
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!company_id && !!prefix,
		}
	);
}

export function useCreateImage() {
	const { getAccessToken } = useAuth();
	return useMutation(async (formData: FormData) =>
		configApi
			.post('/images', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

export function useCreateFolder() {
	const { getAccessToken } = useAuth();
	const createFolder = async ({folderName, prefix}:CreateFolderType) => {
		const response = await configApi
				.post(`/images/createfolder?foldername=${folderName}&prefix=${prefix}`, {}, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})

		return response.data
	}
		
	const queryClient = useQueryClient();

	return useMutation(createFolder, {
		onSuccess: () => {
			queryClient.invalidateQueries('image_folder');
		},
	});
}


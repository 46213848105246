import styled from '@emotion/styled';
import { Div } from '../Div';
import { DataGridProps } from './DataGrid';

export const StyledDataGrid = styled(Div)<
	Omit<DataGridProps, 'children' | 'data' | 'columns' | 'activePage'>
>`
	border-collapse: collapse;
	table-layout: auto;
	width: 100%;

	> thead {
		background-color: ${({ theme }) => theme.colors.grey05};
		border: 3px solid ${({ theme }) => theme.colors.grey05};
		font-size: 14px;
	}

	* th {
		padding: 13px 20px;
		font-weight: 400;
		text-align: left;
	}

	tbody tr {
		border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
		position: relative;

		:hover {
			box-shadow: inset 3px 0px 0px 0px ${({ theme }) => theme.colors.primary};
			background-color: ${({ theme }) => theme.colors.grey05};
		}
	}

	* td {
		padding: 24px 20px;
	}
`;

export const StyledRow = styled(Div)`
	:hover {
		cursor: pointer;
	}
`

import styled from '@emotion/styled';

type TableHeadCol = {
	textAlign?: string;
};

type TableRowProps = {
	isDragging?: boolean;
};

type TableColProps = {
	width?: string;
	isIcon?: boolean;
};

export const Table = styled.table`
	border-radius: 4px;
`;

export const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

export const TableHeadCol = styled.th<TableHeadCol>`
	font-weight: 600;
	padding: 10px;
	text-align: ${(props) => props.textAlign ?? ''};
`;

export const TableRow = styled.tr<TableRowProps>`
	display: ${(props) => (props.isDragging ? 'table' : '')};
	width: ${(props) => (props.isDragging ? '1600px' : '1485px')};
`;

export const TableIconCol = styled.td`
	:hover {
		cursor: pointer;
	}
`;

export const TableDataCol = styled.td<TableColProps>`
	cursor: ${(props) => (props.isIcon ? 'pointer' : 'auto')};
	width: ${(props) => (props.width ? `${props.width}` : '350px')};
`;

import NotificationProvider from 'providers/NotificationProvider';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import { Div, Stack } from '../components';
import ThemeProvider from '../providers/ThemeProvider';
import loginbackground from './assets/loginbackground.png';

const client = new QueryClient();
const Public = () => {
	return (
		<QueryClientProvider client={client}>
			<ThemeProvider>
				<NotificationProvider>
					<Div
						style={{
							width: '100vw',
							height: '100vh',
							padding: '0',
							justifyContent: 'end',
							backgroundImage: `url(${loginbackground})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							overflow: 'hidden',
							position: 'fixed',
							zIndex: '-100',
						}}
					></Div>

					<Div>
						<Stack
							height="100vh"
							width="100%"
							justify="right"
							style={{ padding: '0px 10%' }}
						>
							<Div
								style={{
									width: '33%',
									maxHeight: '75%',
									background: 'white',
									borderRadius: '4px',
									padding: '3.5% 3%',
								}}
							>
								<Outlet />
							</Div>
						</Stack>
					</Div>
				</NotificationProvider>
			</ThemeProvider>
		</QueryClientProvider>
	);
};

export default Public;

import React from 'react';
import {
	NavLinkProps as MantineNavLinkProps,
	NavLink as MantineNavLink,
	Text,
} from '@mantine/core';
import { useNavigate } from '../lib/navigate';
import { useLocation } from 'react-router-dom';
import Icon, { IconProps } from '../assets/icons';
import RequirePermissions, {
	RequirePermissionsProps,
} from '../guards/RequirePermissions';

export type NavLinkProps = {
	to?: string;
	icon?: IconProps['name'];
	permissions?: RequirePermissionsProps['permissions'];
	superAdminOnly?: boolean;
} & Omit<MantineNavLinkProps, 'icon'>;

const NavLink = React.forwardRef<HTMLButtonElement, NavLinkProps>(
	({ to, icon, label, permissions, superAdminOnly, ...rest }, ref) => {
		const [opened, setOpened] = React.useState(false);
		const navigate = useNavigate();
		const { pathname } = useLocation();
		const isActive = to ? pathname.startsWith(to) : false;

		const Component = () => (
			<MantineNavLink
				opened={opened}
				active={isActive}
				icon={
					icon ? (
						<Icon
							name={icon}
							style={{ color: isActive ? 'orange' : 'inherit', fontSize: 18 }}
						/>
					) : null
				}
				label={<Text weight={isActive || opened ? 600 : 400}>{label}</Text>}
				onClick={() => {
					setOpened((p) => !p);
					if (to) {
						navigate(to);
					}
				}}
				color="cyan"
				{...rest}
				ref={ref}
			/>
		);

		return !permissions && !superAdminOnly ? (
			<Component />
		) : (
			<RequirePermissions
				permissions={permissions}
				superAdminOnly={superAdminOnly}
			>
				<Component />
			</RequirePermissions>
		);
	}
);

NavLink.displayName = 'NavLink';

export default NavLink;

import React from 'react';
import { Box, Input, Tooltip, ActionIcon, InputProps } from '@mantine/core';
import Icon from '../assets/icons';

export type TextFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	required?: boolean;
	maxLength?: number;
	hidden?: boolean;
	bottomSection?: React.ReactNode;
} & InputProps &
	React.InputHTMLAttributes<HTMLInputElement>;

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
	(
		{ label, error, hint, required, maxLength, hidden, bottomSection, ...rest },
		ref
	) => {
		return (
			<Box
				ref={ref}
				sx={{
					display: hidden ? 'none' : 'grid',
					gridTemplateColumns: '240px 1fr',
					alignItems: 'flex-start',
				}}
			>
				<div>
					<Input.Label required={required} mt="15px" mr="10px">
						{label}
					</Input.Label>
					{!hint ? null : (
						<Tooltip label={hint} position="bottom-start">
							<ActionIcon
								display="inline-block"
								variant="transparent"
								color="dark"
							>
								<Icon name="info" />
							</ActionIcon>
						</Tooltip>
					)}
				</div>
				<Box>
					<Input
						error={error}
						{...rest}
						maxLength={maxLength}
						styles={{
							input: {
								padding: '27px 18px',
							},
						}}
					/>
					{error ? <Input.Error my="10px">{error}</Input.Error> : null}
					{!bottomSection ? null : bottomSection}
				</Box>
			</Box>
		);
	}
);

TextField.displayName = 'TextField';

import styled from '@emotion/styled';
import { FormMultiSelectProps } from './FormMultiSelect';

export const StyledFormMultiSelect = styled.div<FormMultiSelectProps>`
	border: 1px solid ${({ theme }) => theme.colors.grey10};
	border-radius: 4px;
	padding: 16px 12px;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.font.sansSerif};
	width: ${({ width }) => width ?? '100%'};
	display: flex;
	align-items: center;
	justify-content: space-between;
	justify-self: start;
	pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
	background-color: ${({ isDisabled, theme }) =>
		isDisabled ? theme.colors.grey05 : 'inherit'};
`;

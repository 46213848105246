import React, { PropsWithChildren } from 'react';
import { io } from 'socket.io-client';
import { apiRoutes } from '../apis';
import { useAuth } from './AuthProvider';

const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { accessToken } = useAuth();

	React.useEffect(() => {
		const socket = io(
			apiRoutes.socketApi[process.env.REACT_APP_DEPLOYMENT_ENV || 'local'],
			{
				// HAProxy load balancer having difficulties with long polling sticky session setup
				// So for now we only support websocket connection
				transports: ['websocket'],
				auth: {
					token:
						// Payload to decode by socket server: {user_id: 23}
						// Socket server stores dashboard socket connection tied to user_id: 23
						`Bearer ${accessToken}`,
				},
			}
		);

		socket.on('connect', () => {
			console.info('connect');
		});

		socket.on('disconnect', () => {
			console.log('disconnect');
		});

		// MUST listen to 'POS_RESPONSE' event name
		// Socket server will forward response event emitted by POS as this
		socket.on('POS_RESPONSE', (data) => {
			console.log('POS_RESPONSE data', data);
			localStorage.setItem(data?.event_uuid, JSON.stringify(data));

			// This is example schema for reference:
			// If POS successfully processed push update, data =
			/*
        {
			response_type: 'SUCCESS',
			recipients: {
				dashboard_ids: [23],
			},
			event_uuid: "123e4567-e89b-12d3-a456-426614174000",
			origin_datetime: "2023-02-21T20:52:43.822Z",
			additional_param: 'additional data'
		}
      */

			// If POS failed to process push update, data =
			/*
        {
          response_type: 'ERROR',
          recipients: {
            dashboard_ids: [23],
          },
          error: 'some legit error message',
          event_uuid: "123e4567-e89b-12d3-a456-426614174000",
          origin_datetime: "2023-02-21T20:52:43.822Z",
          additional_param: 'additional data'
        }
      */
		});
		return () => {
			socket.off('connect');
			socket.off('disconnect');
			socket.off('POS_RESPONSE');
			localStorage.clear();
		};
	}, [accessToken]);
	return <>{children}</>;
};

export default SocketProvider;

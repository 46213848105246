import { Card, Tabs, Text } from '@mantine/core';
import React from 'react';
import { FilePreview } from './FilePreview';

const defaultTabs = [
	'Products',
	'Category Groups',
	'Payment Methods',
	'Brands',
	'Discounts',
	'Advertisements',
];

const FileManagerContext = React.createContext<{
	selected: string;
	setSelected: React.Dispatch<React.SetStateAction<string>>;
}>({
	selected: '',
	setSelected: () => {
		return;
	},
});

export const useFileManagerContext = () => React.useContext(FileManagerContext);

export type FileManagerProps = {
	url?: string;
	inactiveTabs?: typeof defaultTabs;
	onChange: (selected: string) => void;
};

export const FileManager = React.forwardRef<HTMLDivElement, FileManagerProps>(
	({ url = "", inactiveTabs, onChange, ...rest }, ref) => {
		if (inactiveTabs) {
			if (inactiveTabs.length >= defaultTabs.length) {
				throw Error(
					`<ImageUploader />: Length of inactive tabs cannot be equal to or more than ${defaultTabs.length}`
				);
			}
		}
		const [selected, setSelected] = React.useState(url);

		React.useEffect(() => {
			if (typeof selected == 'string') {
				onChange(selected);
			}
		}, [onChange, selected]);

		return (
			<FileManagerContext.Provider value={{ selected, setSelected }}>
				<Card w="100%" mih="450px" p={0}>
					<Tabs
						defaultValue={defaultTabs
							.filter((e) => !inactiveTabs?.includes(e))
							.at(0)}
						orientation="vertical"
						variant="pills"
						color="cyan.4"
					>
						<Tabs.List p="sm">
							{defaultTabs.map((e) => (
								<Tabs.Tab
									key={`tabs-tab-${e}`}
									value={e}
									disabled={inactiveTabs?.includes(e)}
								>
									<Text size="md">{e}</Text>
								</Tabs.Tab>
							))}
						</Tabs.List>
						{defaultTabs.map((e) => (
							<Tabs.Panel key={`tabs-panel-${e}`} value={e}>
								<FilePreview
									title={e}
									environment={
										(process.env.REACT_APP_DEPLOYMENT_ENV as any) ||
										'local'
									}
								/>
							</Tabs.Panel>
						))}
					</Tabs>
				</Card>
			</FileManagerContext.Provider>
		);
	}
);

FileManager.displayName = 'FileManager';

import { useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';

type SellingTimeType = {
    id?: number, 
    brand_id: number,
    name: string,
    startTime: string,
    endTime: string,
}

export function useCreateNewSellingTime(){
    const { getAccessToken } = useAuth();
    const createSellingTime = async (body: any ) => {
        const response = await configApi.post('selling-times', body,{
            headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
        })

        return response.data
    }

    const queryClient = useQueryClient();
	return useMutation(createSellingTime, {
		onSuccess: () => {
			queryClient.invalidateQueries('selling_times');
		},
	});
}
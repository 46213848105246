import React from 'react';
import {
	Box,
	Breadcrumbs,
	CancelButton,
	Divider,
	Field,
	Formik,
	ImageUploader,
	SelectBrand,
	TextField,
	ViewContainer,
} from '../../../components';
import { useAppState } from '../../../providers/AppStateProvider';
import { useTheme } from '../../../providers/ThemeProvider';
import { toFormikValidationSchema } from '../../../lib/helpers';
import { CreateCreateCategoryGroupSchema } from '../../../schemas/categorygroup.schema';
import { ProductRoutes } from '../../../router/constants/Products.routes';
import { useNavigate } from '../../../lib/navigate';
import { useCreateCategoryGroup } from '../../../hooks/api/categorygroups';
import {
	Button,
	Checkbox,
	Collapse,
	ColorInput,
	Flex,
	Grid,
	Group,
	Text
} from '@mantine/core';
import { FieldWrapper } from '../../../components/FieldWrapper';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { SwitchField } from '../../../components/SwitchField';
import { useGetPaymentMethods } from '../../../hooks/payments';
import { useAuth } from 'providers/AuthProvider';

const CreateCategoryGroup = () => {
	const { selectedBrand, setDirty } = useAppState();
	const { user } = useAuth();
	const { data: paymentMethodsData } = useGetPaymentMethods(selectedBrand || 0);

	const theme = useTheme();
	const navigate = useNavigate();

	const { mutate: createCategoryGroup } = useCreateCategoryGroup();

	return (
		<Formik
			initialValues={{
				is_allow_same_group_only: false,
				is_follow_pos_payment_methods: true,
				hex_color: theme.colors.primary,
				image_url: '',
				name: '',
				is_archived: false,
				name_other_languages: {
					ms: '',
					zh: '',
				},
				brand_id: selectedBrand || 0,
				payment_methods: [],
				updated_by: user?.name ?? '',
			}}
			validationSchema={toFormikValidationSchema(
				CreateCreateCategoryGroupSchema
			)}
			onSubmit={(values) =>
				createCategoryGroup(
					{ values },
					{
						onSuccess: () => {
							setDirty(false);
							navigate(ProductRoutes.CategoryGroups, {}, true);
						},
					}
				)
			}
			validateOnChange={false}
			validateOnMount={false}
			enableReinitialize
		>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				submitForm,
				dirty,
				isSubmitting,
			}) => {
				useStopNavigateDirtyForm(dirty);

				return (
					<ViewContainer>
						<Box style={{ position: 'relative', marginBottom: '20px' }}>
							<Group w="100%" position="apart">
								<Breadcrumbs
									items={[
										{
											label: 'Category Groups',
											onClick: () => navigate(ProductRoutes.CategoryGroups),
										},
										{
											label: 'Create Category Group',
										},
									]}
								/>
								
							</Group>
							<Group w="100%" position="apart">
								<SelectBrand my="lg" />
							</Group>									
							<Divider/>
						</Box>
						<Grid>
							<Grid.Col md={12} lg={8} pr="xl">
								<Flex direction="column" gap="20px">
									<Field
										as={TextField}
										name="name"
										label="Name"
										isRequired
										maxLength={80}
										error={
											touched['name'] &&
											(errors['name'] as string) &&
											'This field is required.'
										}
									/>
									<Field
										as={TextField}
										name="name_other_languages.zh"
										label="Chinese Name"
										maxLength={40}
									/>
									<Field
										as={TextField}
										name="name_other_languages.ms"
										label="Malay Name"
										maxLength={80}
									/>
									<SwitchField
										size="md"
										label="Allow transactions from same Category Group only"
										onLabel="Yes"
										offLabel="No"
										checked={values?.is_allow_same_group_only}
										onChange={(e) =>
											setFieldValue(
												'is_allow_same_group_only',
												e.currentTarget.checked
											)
										}
									/>
									{values['is_allow_same_group_only'] ? (
										<SwitchField
											size="md"
											label="Use same payment methods as POS"
											onLabel="Yes"
											offLabel="No"
											checked={values?.is_follow_pos_payment_methods}
											onChange={(e) => {
												setFieldValue(
													'is_follow_pos_payment_methods',
													e.currentTarget.checked
												);
												if (e.currentTarget.checked) {
													setFieldValue('payment_methods', []);
												}
											}}
										/>
									) : null}
									<Collapse
										in={
											values['is_allow_same_group_only'] &&
											!values['is_follow_pos_payment_methods']
										}
									>
										<FieldWrapper label="Category Group Payment Methods">
											<Checkbox.Group
												value={values?.payment_methods?.map((e) => String(e))}
												onChange={(selected) =>
													setFieldValue('payment_methods', selected)
												}
											>
												<div>
													{paymentMethodsData?.paymentMethods?.map((e: any) => (
														<Checkbox
															sx={{
																':not(:first-of-type)': {
																	borderTop: `0.5px solid gray`,
																},
															}}
															p="md"
															size="md"
															key={e?.id}
															value={String(e?.id)}
															label={e?.name}
														/>
													)) ?? []}
												</div>
											</Checkbox.Group>
										</FieldWrapper>
									</Collapse>
									<FieldWrapper label="Color">
										<ColorInput
											value={values?.hex_color}
											onChange={(color) => {
												if (color) {
													setFieldValue('hex_color', color);
												}
											}}
										/>
									</FieldWrapper>
								</Flex>
							</Grid.Col>
							<Grid.Col md={12} lg={4} pl="xl">
								<ImageUploader
									url={values['image_url'] || ''}
									onChange={(url) => setFieldValue('image_url', url)}
									inactiveTabs={[
										'Payment Methods',
										'Products',
										'Brands',
										'Discounts',
										'Advertisements',
									]}
								/>
								<Text align="left" fs="italic" size="xs" color="gray" >
									Recommended Dimensions: W 170px x H 100px
								</Text>
							</Grid.Col>
						</Grid>
						<Group mt="15px" my="20px">
							<Button disabled={isSubmitting} onClick={() => submitForm()}>
								Create
							</Button>
							<CancelButton
								disabled={isSubmitting}
								onClick={() => navigate(ProductRoutes.CategoryGroups)}
							>
								Cancel
							</CancelButton>
						</Group>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default CreateCategoryGroup;

import React from 'react';
import { Accordion } from '@mantine/core';
import {
	ActionIcon,
	Div,
	DragDropContext,
	Draggable,
	Droppable,
	ListGroup,
	useFormikContext,
} from '../../../../components';
import Icon from '../../../../assets/icons';
import { useGetCategoryGroupById } from '../../../../hooks';
import MenuPreviewCategoryItem from './MenuPreviewCategoryItem';

type Props = {
	categoryGroupId: string | number;
	categoryIds: string[] | number[];
};

const MenuPreviewCategoryGroup = ({ categoryGroupId, categoryIds }: Props) => {
	const { data: categoryGroup } = useGetCategoryGroupById({ categoryGroupId });
	const { setFieldValue, values } = useFormikContext<any>();
	return !categoryGroup?.name ? null : (
		<Accordion key={categoryGroup?.name} chevronPosition="left">
			<Accordion.Item value={categoryGroup?.name || ''} css={{ width: '100%' }}>
				<Accordion.Control>
					<Div
						css={{
							width: '100%',
							textAlign: 'left',
							marginLeft: '10px',
						}}
					>
						{categoryGroup?.name}
					</Div>
				</Accordion.Control>
				<Accordion.Panel>
					<DragDropContext
						key={categoryGroup?.name}
						onDragEnd={({ source, destination }) => {
							if (source && destination) {
								// Copy values
								const _items = [...values._categoryGroups[categoryGroupId]];
								// Get item to be removed
								const _item =
									values?._categoryGroups[categoryGroupId][source.index];
								// Remove item
								_items.splice(source.index, 1);
								// Add item
								_items.splice(destination.index, 0, _item);
								// Set new values
								setFieldValue(`_categoryGroups.${categoryGroupId}`, _items);
							}
						}}
					>
						<Droppable
							droppableId={`droppable-categoryGroup-${categoryGroup?.id}`}
							direction="vertical"
						>
							{(provided) => (
								<Div {...provided.droppableProps} ref={provided.innerRef}>
									<ListGroup isHoverable>
										{categoryIds?.map((e, idx) => (
											<MenuPreviewCategoryItem
												key={`menu-preview-category-group-${categoryGroupId}-category-${e}`}
												index={idx}
												draggableId={`category-${e}`}
												categoryId={e}
												leftSectionProps={{ style: { marginLeft: '20px' } }}
												unlinkHandler={(categoryId) => {
													const newCategories = values?._categoryGroups[
														categoryGroupId
													].filter((e: number) => e !== categoryId);

													setFieldValue(
														`_categoryGroups.${categoryGroupId}`,
														newCategories
													);
												}}
											/>
										))}
									</ListGroup>
									{provided.placeholder}
								</Div>
							)}
						</Droppable>
					</DragDropContext>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
};

export default MenuPreviewCategoryGroup;

import {reportAnalyticApi} from '../../../apis';
import {exportData} from './helpers/exportData'
import Papa from "papaparse";

type TSummaryReport = {
    fileName: string,
    report_ids: string[], terminal_ids: string[] | number[]
}

export async function useGetSummaryReport({fileName,report_ids, terminal_ids}:TSummaryReport) {
    const response = await reportAnalyticApi.post('/financial-reports/sales-summary-reports', {
        report_ids,
    terminal_ids
    })

    const parsedData = Papa.unparse(response.data.csvData);

    exportData(parsedData, `SALES SUMMARY ${fileName}.csv`, "text/csv;charset=utf-8;")

    return response.data;
}
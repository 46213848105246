import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledDiv, StyledInput, StyledSpan } from './InputPhoneNumber.styled';
import { Input } from '../Input/Input';

export type InputPhoneNumberProps = DefaultProps<HTMLInputElement> &
	React.InputHTMLAttributes<HTMLInputElement> & {
		value: number;
		isRequired?: boolean;
		isReadOnly?: boolean;
		isDisabled?: boolean;
		onChange?: React.ChangeEventHandler<HTMLInputElement>;
		width?: string;
		error?: React.ReactNode;
		maxLength?: number;
	};

const InputPhoneNumber = React.forwardRef<
	HTMLInputElement,
	InputPhoneNumberProps
>(({ value, onChange, name, error, isReadOnly, maxLength, width, ...rest }, ref) => {
	return (
		<StyledDiv width={width} error={error}>
			<StyledSpan>+65</StyledSpan>
			<StyledInput
				name={name}
				ref={ref}
				readOnly={isReadOnly}
				{...rest}
				type="text"
				value={value}
				onChange={onChange}
				maxLength={maxLength}
			/>
		</StyledDiv>
	);
});

export { InputPhoneNumber };

InputPhoneNumber.displayName = 'InputPhoneNumber';

import {
	Anchor,
	Breadcrumbs as MantineBreadcrumbs,
	BreadcrumbsProps as MantineBreadcrumbsProps,
	Title,
} from '@mantine/core';
import React from 'react';
import Icon from '../../assets/icons';
import nanoid from '../../lib/nanoid';

export type BreadcrumbItem = {
	label: string;
	onClick?: () => void;
};

export type BreadcrumbsProps = {
	separator?: React.ReactNode;
	items: BreadcrumbItem[];
} & Omit<MantineBreadcrumbsProps, 'children'>;

const defaultProps = {
	separator: <Icon name="chevronright" />,
};

export const Breadcrumbs = React.forwardRef<HTMLDivElement, BreadcrumbsProps>(
	({ items, ...rest }, ref) => {
		const _items = items?.filter((e) => !!e);

		return (
			<MantineBreadcrumbs
				my={0}
				py={0}
				ref={ref}
				separator={<Icon name="chevronright" />}
				{...rest}
			>
				{_items?.map(({ label, onClick }) =>
					!onClick ? (
						<Title key={`${nanoid(6)}-${label}`} order={3} color="dark">
							{label}
						</Title>
					) : (
						<Anchor key={`${nanoid(6)}-${label}`} onClick={onClick}>
							<Title order={3} color="gray">
								{label}
							</Title>
						</Anchor>
					)
				)}
			</MantineBreadcrumbs>
		);
	}
);

Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.defaultProps = defaultProps;

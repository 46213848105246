import { useMutation, useQueryClient } from 'react-query';
import { txDumpApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { useNotification } from '../../../providers/NotificationProvider';
import { isAxiosError } from 'axios';

export function usePutOrderIsVoiding() {
	const queryClient = useQueryClient();
	const { getAccessToken, userId } = useAuth();
	const eventUuid = uuidv4();
	const notify = useNotification();
	const { mutate: updateVoid } = useUpdateOrderIsVoiding();

	return useMutation(
		async ({ order_id, pos_id }: { order_id: string; pos_id: string }) =>
			txDumpApi
				.put(
					`/transactions/order/voiding`,
					{
						order_id,
						pos_id,
						user_id: userId,
						event_uuid: eventUuid,
					},
					{
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					}
				)
				.then(({ data }) => data),
		{
			onSuccess: (_, { order_id}) => {
				notify.success(`Void sale initiated for order ${order_id}.`);
				
				// Start a 1 second interval to listen to socket response
				const interval = setInterval(() => {
					
					const value = localStorage.getItem(eventUuid);

					if (value?.length) {

						const _value = JSON.parse(value);

						if (_value.response_type === 'SUCCESS') {
							notify.success(`Order ${order_id} has been successfully voided.`, {
								autoClose: 10000,
							});
						}

						if (_value.response_type === 'ERROR') {
							notify.error(`${order_id} failed to void. ${_value?.error??''}`, { autoClose: 10000 });
						}

						localStorage.removeItem(eventUuid)
						clearInterval(interval);
					}
				}, 1000);


				// Start a 1 min timer to close the interval
				setTimeout(() => {
					clearInterval(interval);
					notify.error(`${order_id} failed to void.`, { autoClose: 10000 });
					updateVoid({order_id, is_voiding:false});

				},10000 * 6 /** 60,000 ms */);

				queryClient.invalidateQueries(['orders', 'orderId', order_id]);
			},
			onError: (err, {order_id}) => {
				if (isAxiosError(err)) {
					// Notify of error, no timer started
					notify.error(
						`Order ${order_id} failed to void. ${err?.response?.data?.error}`,
						{
							autoClose: false,
						},
						
					);
				}
				queryClient.refetchQueries(['orders', 'orderId', order_id]);
			},
		}
	);
}


export function useUpdateOrderIsVoiding() {
	const { getAccessToken } = useAuth();

	const updateOrderIsVoiding = async ({ order_id, is_voiding }: { order_id: string; is_voiding: boolean }) => {
		const response = await txDumpApi.patch(`/transactions/${order_id}/order/voiding`,{
			order_id,
			is_voiding,
		}, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();

	return useMutation(
		updateOrderIsVoiding,
		{
			onSuccess: ({order_id}) => {
				queryClient.invalidateQueries(['orders', 'orderId', order_id]);
			},
		}
	);
}
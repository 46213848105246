import React from 'react';
import { Box, Button, Group, Stack } from '@mantine/core';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import { useDeleteUser } from 'hooks/api/users/useDeleteUser';
import { useNavigate } from 'react-router-dom';
import { PermissionRoutes } from 'router/constants/Permissions.routes';
import { useNotification } from 'providers/NotificationProvider';
import { CancelButton } from 'components';

type Data = {
	[key: string]: string[];
};

type ModalPropsString = {
	close: () => void;
	data?: Data;
};

const StyledDiv = styled.div`
	background-color: ${({ theme }) => theme.colors.grey05};
	padding: 1rem;
	margin: 1rem 0;

	.label {
		color: ${({ theme }) => theme.colors.grey50};
	}

	ul {
		margin-top: 0;
		list-style-type: none;
		padding: 0;
	}
`;

export const DeleteUserModal = ({ close, data }: ModalPropsString) => {
	const navigate = useNavigate();
	const toast = useNotification();
	const { mutateAsync: deleteUser } = useDeleteUser(Number(data?.id));
	return (
		<>
			<Box>
				<h3>Are you sure you want to delete?</h3>
				<StyledDiv>
					<Group>
						<p className="label">USER</p>
						<p>{data?.name}</p>
					</Group>
				</StyledDiv>
				<Group
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<CancelButton onClick={close}>No, Cancel</CancelButton>
					<Button
						leftIcon={<DeleteIcon />}
						type="button"
						color="red"
						onClick={() => {
							deleteUser().then((isSuccess) => {
								if (isSuccess) {
									close();
									toast.success(
										`User ${
											data?.name ?? 'undefined'
										} was successfully deleted.`
									);
									navigate(PermissionRoutes.Users);
								} else {
									toast.error('An error occured');
								}
							});
						}}
					>
						Yes, Delete
					</Button>
				</Group>
				{/* <pre>{JSON.stringify(data?.pos, null, 2)}</pre> */}
			</Box>
		</>
	);
};

import React from 'react';
import {
	Box,
	Input,
	Tooltip,
	ActionIcon,
	MultiSelectProps,
	MultiSelect,
} from '@mantine/core';
import Icon from '../assets/icons';

export type MultiSelectFieldProps = {
	label: React.ReactNode;
	error?: React.ReactNode;
	hint?: React.ReactNode;
	width?: string;
	required?: boolean;
} & MultiSelectProps;

export const MultiSelectField = React.forwardRef<
	HTMLDivElement,
	MultiSelectFieldProps
>(({ label, error, hint, required, children, width, ...rest }, ref) => {
	
	const labelWidth = (width) && (Number(width.replace('px','')) * (25/100))


	return (
		<Box
			ref={ref}
			sx={{
				display: 'grid',
				gridTemplateColumns: ((width && labelWidth) 
					? String(labelWidth)+'px '+String(labelWidth*3)+'px' 
					: '240px 1fr'),
				alignItems: 'flex-start',
			}}
		>
			<div>
				<Input.Label required={required} style={{
							padding:"16px 0",
							display:"inline-block"
						}}>
					{label}
				</Input.Label>
				{!hint ? null : (
					<Tooltip label={hint} position="bottom-start">
						<ActionIcon
							display="inline-block"
							variant="transparent"
							color="dark"
						>
							<Icon name="info" />
						</ActionIcon>
					</Tooltip>
				)}
			</div>
			<Box>
				<MultiSelect
					{...rest}
					styles={{
						input: {
							padding: '10px 18px',
						},
					}}
				/>
				{error ? (
					<Input.Error my="10px">This field is required</Input.Error>
				) : null}
				{children}
			</Box>
		</Box>
	);
});

MultiSelectField.displayName = 'MultiSelectField';

import { Box, Center, Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const Home = () => {
	const { isAuthenticated, user } = useAuth();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!isAuthenticated) {
			navigate('/redirect', { state: { redirect: '/dashboard' } });
		}
	}, []);

	return (
		<Box px="xl" py={25}>
			<Box
				sx={(theme) => ({
					position: 'relative',
					borderRadius: theme.radius.lg,
				})}
				w="100%"
				h="200px"
				bg="orange.1"
			>
				<Center h="100%" sx={{ position: 'absolute', zIndex: 10 }}>
					<Group position="apart" w="100%" px="xl">
						<Box>
							<Text size={25}>
								<strong>Welcome,</strong> {user?.name}!
							</Text>
							<Group align="center" spacing="sm">
								<Text size="xs" display="inline-block">
									LAST LOGIN
								</Text>
								<Text display="inline-block">
									{dayjs(user?.last_login_at).fromNow()}
								</Text>
							</Group>
						</Box>
						<Box></Box>
					</Group>
				</Center>
			</Box>
		</Box>
	);
};

export default Home;

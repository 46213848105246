import React from 'react';
import PropTypes from 'prop-types';
import { ImageIcon } from '../../assets/icons';
import { theme } from '../../providers/ThemeProvider/index';
import { Stack } from '../Stack';
import { StyledImageWrapper } from './Image.styled';
import { imageAssets } from './images.asset';
import { DivProps } from '../Div';

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
	width?: React.CSSProperties['width'];
	height?: React.CSSProperties['height'];
	fit?: React.CSSProperties['objectFit'];
	isPlaceholder?: boolean;
	name?: keyof typeof imageAssets;
	imageWrapperProps?: DivProps;
};

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
	(
		{
			width,
			height,
			fit,
			isPlaceholder,
			name,
			alt,
			src,
			imageWrapperProps,
			...rest
		},
		ref
	) => {
		const [isError, setIsError] = React.useState<boolean>(false);
		return (
			<StyledImageWrapper
				{...imageWrapperProps}
				width={width}
				height={height}
				fit={fit}
			>
				{isError || isPlaceholder ? (
					<Stack
						css={(theme) => ({
							background: theme.colors.grey05,
							height,
						})}
						width={width}
						align="center"
						justify="center"
					>
						<ImageIcon
							style={{ fontSize: '40px', color: theme.colors.primary }}
						/>
					</Stack>
				) : (
					<img
						{...rest}
						src={name ? imageAssets[name] : src}
						alt={name ?? alt}
						ref={ref}
						// onError={() => setIsError(true)}
					/>
				)}
			</StyledImageWrapper>
		);
	}
);

Image.displayName = 'Image';
Image.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
};

import { Navbar, NavbarProps, ScrollArea } from '@mantine/core';
import React from 'react';
import { useAppState } from '../providers/AppStateProvider';
import { ConfigurationRoutes } from '../router/constants/Configuration.routes';
import { PermissionRoutes } from '../router/constants/Permissions.routes';
import { ProductRoutes } from '../router/constants/Products.routes';
import NavLink from './NavLink';
import RequirePermissions from '../guards/RequirePermissions';

export type SidebarProps = Omit<NavbarProps, 'children'>;

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>(
	({ hidden, ...rest }, ref) => {
		const { isSidebarHidden } = useAppState();
		return (
			<Navbar
				{...rest}
				width={{ base: 260 }}
				p="40px 20px 0px 20px"
				hiddenBreakpoint={isSidebarHidden ? 10000 : 'md'}
				hidden={hidden || isSidebarHidden}
				ref={ref}
			>
				<ScrollArea>
					<NavLink label="Dashboard" to="/dashboard" />
					<RequirePermissions
						permissions={[
							'Menu.View',
							'Categories.View',
							'CategoryGroups.View',
							'OptionGroups.View',
							'KitchenGroups.View',
						]}
					>
						<NavLink label="Products" childrenOffset={0}>
							<NavLink
								label="Menu"
								icon="menu"
								to={ProductRoutes.Menu}
								permissions={['Menu.View']}
							/>
							<NavLink
								label="Categories"
								icon="categories"
								to={ProductRoutes.Categories}
								permissions={['Categories.View']}
							/>
							<NavLink
								label="Category Groups"
								icon="categorygroups"
								to={ProductRoutes.CategoryGroups}
								permissions={['CategoryGroups.View']}
							/>
							<NavLink
								label="Option Groups"
								icon="optiongroups"
								to={ProductRoutes.OptionGroups}
								permissions={['OptionGroups.View']}
							/>
							<NavLink
								label="Kitchen Groups"
								icon="kitchengroups"
								to={ProductRoutes.KitchenGroups}
								permissions={['KitchenGroups.View']}
							/>
							<NavLink
								label="Selling Times"
								icon="sellingtimes"
								to={ProductRoutes.SellingTimes}
								permissions={['Categories.View']}
							/>
						</NavLink>
					</RequirePermissions>

					<RequirePermissions
						permissions={[
							'Companies.View',
							'Brands.View',
							'Outlets.View',
							'PosList.View',
							'Orders.View',
							'Closings.View',
							'PaymentMethods.View',
							'Discounts.View',
						]}
					>
						<NavLink label="Configuration" childrenOffset={0}>
							<NavLink
								label="Malls"
								icon="malls"
								to={ConfigurationRoutes.Malls}
								superAdminOnly
							/>
							<NavLink
								label="Companies"
								icon="companies"
								to={ConfigurationRoutes.Companies}
								superAdminOnly
							/>
							<NavLink
								label="Brands"
								icon="brands"
								to={ConfigurationRoutes.Brands}
								permissions={['Brands.View']}
							/>
							<NavLink
								label="Outlets"
								icon="outlets"
								to={ConfigurationRoutes.Outlets}
								permissions={['Outlets.View']}
							/>
							<NavLink
								label="Point of Sales"
								icon="pos"
								to={ConfigurationRoutes.Pos}
								permissions={['PosList.View', 'Orders.View', 'Closings.View']}
							/>
							<NavLink
								label="Payment"
								icon="payment"
								to={ConfigurationRoutes.Payment}
								permissions={['PaymentMethods.View', 'Discounts.View']}
							/>
						</NavLink>
					</RequirePermissions>
					<RequirePermissions permissions={['Roles.View', 'Users.View']}>
						<NavLink label="Permissions" childrenOffset={0}>
							<NavLink
								label="Users"
								icon="users"
								to={PermissionRoutes.Users}
								permissions={['Users.View']}
							/>
							<NavLink
								label="Roles"
								icon="roles"
								to={PermissionRoutes.Roles}
								permissions={['Roles.View']}
							/>
						</NavLink>
					</RequirePermissions>
					{/* <NavLink label="FS Integration" childrenOffset={0}>
						<NavLink label="BC Integration" icon="bcint" to="/integration/bc" />
					</NavLink> */}
					<RequirePermissions permissions={['Finance.View']}>
						<NavLink label="Reports" childrenOffset={0}>
							<NavLink
								label="Finance Report"
								icon="bcint"
								to="reports/finance"
							/>
						</NavLink>
					</RequirePermissions>
					<RequirePermissions permissions={['Analytics.View']}>
						<NavLink label="Analytics" to="reports/analytics" />
					</RequirePermissions>
				</ScrollArea>
			</Navbar>
		);
	}
);

Sidebar.displayName = 'Sidebar';
export default Sidebar;

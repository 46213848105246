import React from 'react';
import Roles from 'views/Permissions/Roles';
import Users from 'views/Permissions/Users';
import CreateRole from '../../views/Permissions/CreateRole';
import EditRole from '../../views/Permissions/EditRole';
import CreateUser from '../../views/Permissions/CreateUser';
import EditUser from '../../views/Permissions/EditUser';
import { PermissionRoutes } from '../constants/Permissions.routes';
import { ProtectedRoute } from '../ProtectedRoute';
import SuperadminView from '../../views/Permissions/EditRole/SuperadminView';

export const permissionRoutes = [
	{
		name: 'Users',
		path: PermissionRoutes.Users,
		element: (
			<ProtectedRoute permissions={['Users.View', 'Users.Edit']}>
				<Users />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create user',
		path: PermissionRoutes.CreateUser,
		element: (
			<ProtectedRoute permissions={['Users.Create']}>
				<CreateUser />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit user',
		path: PermissionRoutes.EditUser,
		element: (
			<ProtectedRoute permissions={['Users.View', 'Users.Edit']}>
				<EditUser />
			</ProtectedRoute>
		),
	},
	{
		name: 'Roles',
		path: PermissionRoutes.Roles,
		element: (
			<ProtectedRoute permissions={['Roles.View', 'Roles.Edit']}>
				<Roles />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Role',
		path: PermissionRoutes.CreateRole,
		element: (
			<ProtectedRoute permissions={['Roles.Create']}>
				<CreateRole />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Role',
		path: PermissionRoutes.EditRole,
		element: (
			<ProtectedRoute permissions={['Roles.View', 'Roles.Edit']}>
				<EditRole />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Superadmin Role',
		path: PermissionRoutes.SuperAdminRole,
		element: (
			<ProtectedRoute permissions={['Roles.View']}>
				<SuperadminView />
			</ProtectedRoute>
		),
	},
];

import { Grid, Divider, Group, Stack, Box } from '@mantine/core';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	FormTextInput,
	LastUpdatedLabel,
	Text,
	TextField,
	ViewContainer,
} from 'components';
import { Field, Formik } from 'formik';
import { useDeleteOneMall, useUpdateOneMall } from 'hooks/malls';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useParams } from 'providers/RouterProvider';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { MallsSchemaUpdate } from 'schemas/malls';
import { configApi } from '../../../apis';
import { useNavigate } from '../../../lib/navigate';
import { useAuth } from '../../../providers/AuthProvider';
import { StyledDiv } from './Modals';
import { modals } from '@mantine/modals';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { ConfigurationRoutes } from '../../../router/constants/Configuration.routes';

const MallsView = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { getAccessToken } = useAuth();
	const { setDirty } = useAppState();
	const toast = useNotification();

	const { data: mall } = useQuery(
		['mall', id],
		async () =>
			configApi
				.get(`/malls/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!id,
		}
	);

	const { mutateAsync: updateMall } = useUpdateOneMall();

	const { mutate: remove } = useMutation(
		async ({ id }: { id: string }) =>
			configApi
				.delete(`/malls/${id}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSettled: (data) => {
				if (!data.success) {
					modals.openConfirmModal({
						withCloseButton: false,
						size: 'lg',
						title: (
							<Text size="xl" weight="bold">
								This mall is used in the following places, please unlink before
								deleting
							</Text>
						),
						children: (
							<StyledDiv>
								<p>OUTLETS</p>
								<ul>
									{data.outlets.map((el: string, i: number) => {
										return <li key={i}>{el}</li>;
									})}
								</ul>
							</StyledDiv>
						),
						labels: {
							cancel: 'OK',
							confirm: '',
						},
						cancelProps: {
							variant: 'filled',
							color: 'orange',
						},
						confirmProps: {
							style: {
								display: 'none',
							},
						},
					});
				} else {
					navigate(ConfigurationRoutes.Malls, {}, true);
				}
			},
		}
	);

	const breadcrumbsArray = [
		{
			label: 'Malls',
			onClick: () => navigate('/configuration/malls'),
		},
		{
			label: mall?.name ?? '',
			onClick: () => navigate('#'),
		},
	];

	return (
		<ViewContainer title={
			<Group position="apart">
				<Breadcrumbs items={breadcrumbsArray} />
				<Button
					w="fit-content"
					ml="auto"
					color="red"
					onClick={() => remove({ id: mall.id })}
				>
					Delete
				</Button>
			</Group>
			}>
			<Grid>
				<Grid.Col sm={12} md={12}>
					<Formik
						initialValues={mall}
						validate={(values) => {
							try {
								const { id, name, ...rest } = values;
								MallsSchemaUpdate.parse({ id, name });
							} catch (error: any) {
								return error.formErrors.fieldErrors;
							}
						}}
						onSubmit={async (values, actions) => {
							const { updated_at, outlets, ...rest } = values;
							await updateMall(rest)
								.then(() => {
									setDirty(false);
									navigate('/configuration/malls', {}, true);
									toast.success('Mall has been successfully updated');
								})
								.catch(() => {
									toast.error('An error occured');
								});
							actions.setSubmitting(false);
						}}
						enableReinitialize
					>
						{({ errors, touched, dirty, submitForm }) => {
							useStopNavigateDirtyForm(dirty);
							return (
								<Stack spacing="10px" my="30px">
									<Field
										width="600px"
										as={FormTextInput}
										name="name"
										label="Mall Name"
										isRequired={true}
										maxLength={80}
										error={errors.name && touched.name ? errors.name : null}
										style={{justifyContent:'flex-start'}}
									/>
									<Group mt={20}>
										<Button type="submit" onClick={submitForm}>
											Save Changes
										</Button>
										<CancelButton
											onClick={() => navigate('/configuration/malls')}
										>
											Cancel
										</CancelButton>
									</Group>
									<LastUpdatedLabel
										lastUpdated={mall?.updated_at ?? new Date()}
										editor={mall?.updated_by ?? ''}
									/>
								</Stack>
							);
						}}
					</Formik>
				</Grid.Col>
			</Grid>

			<Divider my="sm" />

			<Stack my="50px">
				<Text weight="bold" size="24px">
					Outlets
				</Text>
				{mall?.outlets.map((e: any, idx: number) => {
					return (
						<Box
							style={{
								borderBottom: '1px solid #E8EAED',
								padding: '25px 68px',
							}}
							key={`outlets-${idx}`}
							w="100%"
							miw="960px"
							maw="960px"
						>
							<Group position="apart">
								<Text>{e.outlet_name}</Text>
								<Text style={{ color: '#5f6368' }}>{e.company_name}</Text>
							</Group>
						</Box>
					);
				})}
			</Stack>
		</ViewContainer>
	);
};

export default MallsView;

import React from 'react';
import { Radio, SegmentedControl, Title } from '@mantine/core';
import { useFormikContext } from 'components';
import { SwitchField } from 'components/SwitchField';
import { FieldWrapper } from '../../../../components/FieldWrapper';
import { cashOptions } from '../Constants';

const PosInterface = () => {
	const { setFieldValue, values } = useFormikContext<any>();
	return (
		<>
			<Title order={2}>POS Interface</Title>
			<SwitchField
				size="lg"
				label="Quick sign in"
				onLabel="True"
				offLabel="False"
				checked={values?.has_quick_sign_in}
				onChange={(e) =>
					setFieldValue('has_quick_sign_in', e.currentTarget.checked)
				}
			/>
			<SwitchField
				size="lg"
				label="Display cash denominations"
				onLabel="True"
				offLabel="False"
				checked={values?.is_display_cash_denominations}
				onChange={(e) =>
					setFieldValue(
						'is_display_cash_denominations',
						e.currentTarget.checked
					)
				}
			/>
			<SwitchField
				size="lg"
				label="Display product price"
				onLabel="True"
				offLabel="False"
				checked={values?.is_display_product_price}
				onChange={(e) =>
					setFieldValue('is_display_product_price', e.currentTarget.checked)
				}
			/>
			<FieldWrapper label="Cash payment methods will">
				<Radio.Group
					name="cash_exact_type"
					value={values?.cash_exact_type}
					onChange={(value) => {
						if (value) {
							setFieldValue('cash_exact_type', value);
						}
					}}
				>
					{cashOptions?.map(({ value, label }) => (
						<Radio key={value} value={value} label={label} my="sm" />
					))}
				</Radio.Group>
			</FieldWrapper>
			<SwitchField
				size="lg"
				label={`Require manager PIN on "View Z-report" and "View Shift Report" function`}
				onLabel="True"
				offLabel="False"
				checked={values?.has_manager_pin_on_view_report}
				onChange={(e) =>
					setFieldValue(
						'has_manager_pin_on_view_report',
						e.currentTarget.checked
					)
				}
			/>
		</>
	);
};

export default PosInterface;

import React from 'react';
import {
	Box,
	Breadcrumbs,
	Div,
	FormSelectInput,
	LastUpdatedLabel,
	Stack,
	Tabs,
	Text,
	ViewContainer,
	Button,
	CancelButton,
	TextField,
	SelectField,
	FormTextInput,
} from 'components';
import { Image } from '@mantine/core';
import OptionsList from './OptionsList';
import { useNavigate } from '../../../lib/navigate';
import { useNotification } from 'providers/NotificationProvider';
import { Field, Formik } from 'formik';
import { useGetOptionGroupById, useUpdateOptionGroup } from 'hooks';
import { newOption } from './OptionGroup.constants';
import { ErrorType } from 'views/Configuration/PointOfSale/Constants';
import { useParams } from 'providers/RouterProvider';
import { UpdateOptionGroupFormikSchema } from 'schemas/Products/optiongroup.schema';
import { AxiosError } from 'axios';
import styled from '@emotion/styled';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const StyledDiv = styled.div`
	:hover {
		cursor: pointer;
	}
`;

const OptionGroupView = () => {
	useDisableSelectCompany();
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useNotification();
	const { permissions } = useAuth();

	const { data: response_option_group, isFetching: isFetchingOG } =
		useGetOptionGroupById(Number(id));

	const { mutateAsync: updateOptionGroup } = useUpdateOptionGroup();

	const breadcrumbsArray = [
		{
			label: 'Option Groups',
			onClick: () => navigate('/products/optiongroups'),
		},
		{
			label: response_option_group?.name ?? '',
			onClick: () => navigate('#'),
		},
	];

	const max_selections_array = ['1', '2', '3', '4', '5', 'Any'];

	const ProductsList = () => {
		const products = response_option_group?.products ?? [];

		return (
			<>
				{products?.map((product: any, idx: number) => {
					return (
						<StyledDiv
							style={{
								width: '1024px',
								borderBottom: '1px solid #E8EAED',
								padding: '25px 68px',
							}}
							onClick={() =>
								navigate(
									`/products/categories/edit/${product.product_category_id}/${product.product_id}`
								)
							}
							key={`products=${idx}`}
						>
							<Div
								style={{ display: 'flex', justifyContent: 'space-between' }}
								onClick={() => {
									navigate(
										`/products/categories/edit/${product.category_id}/${product.product_id}`
									);
								}}
							>
								<Stack direction="row">
									<Stack>
										<Div style={{ width: '150px', paddingRight: '10px' }}>
											<Text truncate>{product.product_display_label}</Text>
										</Div>
										<Image
											src={product.product_image_url}
											width="51px"
											height="38px"
											fit="contain"
											style={{ borderRadius: '4px' }}
											withPlaceholder
										/>
										<Div style={{ minHeight: '38px' }}>
											<Text
												style={{
													fontSize: '16px',
													fontWeight: '600',
													display: 'inline-flex',
													verticalAlign: 'top',
												}}
											>
												{product.product_name}
											</Text>
											<Text style={{ fontSize: '14px', color: '#5F6368' }}>
												{product.option_groups.join(', ') ?? ''}
											</Text>
										</Div>
									</Stack>
								</Stack>

								<Text style={{ color: '#5f6368' }}>{product.pos_name}</Text>
							</Div>
						</StyledDiv>
					);
				})}
			</>
		);
	};

	return (
		<ViewContainer>
			<Stack
				gap={30}
				direction="column"
				width={'100%'}
				style={{ display: 'flex', alignItems: 'flex-start' }}
			>
				<Breadcrumbs items={breadcrumbsArray} />
				{!isFetchingOG && (
					<Formik
						initialValues={{
							id: response_option_group?.id ?? 0,
							name: response_option_group?.name ?? '',
							name_other_languages:
								response_option_group?.name_other_languages ?? {
									zh: '',
									ms: '',
								},
							description: response_option_group?.description ?? '',
							min_selections: response_option_group?.min_selections ?? '0',
							max_selections: response_option_group?.max_selections ?? '1',
							brand_id: response_option_group?.brand_id ?? 0,
							options: response_option_group?.options.map((e) => ({
								...e,
								option_value: e.option_value * 100,
							})) ?? [newOption, newOption],
							updated_at: response_option_group?.updated_at ?? new Date(),
							updated_by: response_option_group?.updated_by ?? '',
						}}
						validate={(values) => {
							try {
								UpdateOptionGroupFormikSchema.parse(values);
								const errors: ErrorType = {};
							} catch (err: any) {
								return err.formErrors.fieldErrors;
							}
						}}
						onSubmit={(values, actions) => {
							const numOfDefaults = values.options.filter(
								(e) => e.is_default === true
							).length;

							const emptyOptionNames = values.options.filter(
								(e) => e.name === ''
							).length;

							if (
								values.max_selections !== 'Any' &&
								numOfDefaults > parseInt(values.max_selections ?? '1')
							) {
								toast.error(
									`Cannot mark more than the maximum number of selections (${values.max_selections} options) as default selections`
								);
							} else if (emptyOptionNames > 0) {
								toast.error(`Option name is required`);
							} else if (values.min_selections > values.max_selections) {
								toast.error(
									`Minimum number of options selected cannot be higher than maximum number of options.`
								);
							} else {
								updateOptionGroup(values, {
									onSuccess: (_) => {
										navigate('/products/optiongroups', {}, true);
										toast.success('Option Group successfully updated');
									},
									onError: (error: AxiosError) => {
										toast.error('An error occured');
									},
								});
							}
							actions.setSubmitting(false);
						}}
					>
						{({
							values,
							errors,
							touched,
							dirty,
							setFieldValue,
							submitForm,
							isSubmitting,
						}) => {
							permissions?.includes('OptionGroups.Edit') &&
								useStopNavigateDirtyForm(dirty);
							return (
								<Div style={{ width: '100%' }}>
									{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
									{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
									<Div
										style={{
											display: 'inline-flex',
											alignSelf: 'flex-start',
											position: 'relative',
											marginBottom: '20px',
										}}
									>
										<FormSelectInput
											isDisabled
											width="192px"
											defaultOption={{
												value: '0',
												label: response_option_group?.brand_name ?? '',
											}}
											options={[
												{
													value: '0',
													label: response_option_group?.brand_name ?? '',
												},
											]}
										/>
									</Div>
									<Tabs defaultValue="settings">
										<Tabs.List>
											<Tabs.Tab value="settings">Settings</Tabs.Tab>
											<Tabs.Tab value="products">Linked Products</Tabs.Tab>
										</Tabs.List>
										<Tabs.Panel value="settings">
											<fieldset
												disabled={
													!permissions?.includes('OptionGroups.Edit') ||
													isSubmitting
												}
											>
												<Box style={{ width: '100%' }}>
													<Stack direction="row" style={{ marginTop: '20px' }}>
														<Stack direction="column" gap={30}>
															<Field
																width="600px"
																as={FormTextInput}
																name="name"
																label="Option Group Name"
																isRequired={true}
																maxLength={80}
																error={
																	errors.name && touched.name
																		? errors.name
																		: null
																}
															/>

															<Field
																width="600px"
																as={FormTextInput}
																name="zh_name"
																label="Chinese Name"
																maxLength={80}
																onChange={(e: any) => {
																	setFieldValue(
																		'name_other_languages.zh',
																		e.target.value
																	);
																}}
															/>
															<Field
																width="600px"
																as={FormTextInput}
																name="ms_name"
																label="Malay Name"
																maxLength={80}
																onChange={(e: any) => {
																	setFieldValue(
																		'name_other_languages.ms',
																		e.target.value
																	);
																}}
															/>
															<Field
																width="600px"
																as={FormTextInput}
																name="description"
																label="Description"
																maxLength={80}
															/>

															<Field
																as={SelectField}
																labelWidth={160}
																width={450}
																label="Minimum number of options to be selected"
																hint={'For Grab Delivery Integration only'}
																value={values?.min_selections}
																data={[
																	{ value: '0', label: '0' },
																	...(values.options?.map(
																		(e: any, i: number) => ({
																			label: (i + 1).toString(),
																			value: (i + 1).toString(),
																		})
																	) ?? []),
																]}
																placeholder="Please select"
																onChange={(value: any) => {
																	setFieldValue('min_selections', value);
																}}
															/>

															<Field
																as={SelectField}
																labelWidth={160}
																width={450}
																label="Maximum number of options that can be selected"
																value={values?.max_selections}
																data={[
																	...(values.options?.map(
																		(e: any, i: number) => ({
																			label: (i + 1).toString(),
																			value: (i + 1).toString(),
																		})
																	) ?? []),
																]}
																placeholder="Please select"
																onChange={(value: any) => {
																	setFieldValue('max_selections', value);
																}}
															/>
														</Stack>
														<Div
															style={{
																alignSelf: 'flex-start',
																marginLeft: '100px',
															}}
														>
															<LastUpdatedLabel
																lastUpdated={values?.updated_at ?? new Date()}
																editor={values?.updated_by ?? ''}
															/>
														</Div>
													</Stack>
												</Box>

												<OptionsList optionsList={values.options} />

												<Stack
													width={'100%'}
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														marginTop: '20px',
													}}
												>
													<RequirePermissions
														permissions={['OptionGroups.Edit']}
													>
														<Button type="submit" onClick={submitForm}>
															Save Changes
														</Button>
													</RequirePermissions>

													<CancelButton
														onClick={() => navigate('/products/optiongroups')}
													>
														Cancel
													</CancelButton>
												</Stack>
											</fieldset>
										</Tabs.Panel>
										<Tabs.Panel value="products">
											<ProductsList />
										</Tabs.Panel>
									</Tabs>
								</Div>
							);
						}}
					</Formik>
				)}
			</Stack>
		</ViewContainer>
	);
};

export default OptionGroupView;

import { configApi } from 'apis';
import { useQuery } from 'react-query';
import { useAuth } from '../../../providers/AuthProvider';

export const useGetCompanyName = (companyId: number | null) => {
	const { getAccessToken } = useAuth();

	const fetchCompanyName = async (companyId: number | null) => {
		const res = await configApi.get(`/companies/${companyId}?summary=yes`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return res.data;
	};

	return useQuery(
		['companyId', companyId],
		async () => fetchCompanyName(companyId),
		{
			enabled: !!companyId,
		}
	);
};

/* eslint-disable no-mixed-spaces-and-tabs */
import { UnstyledButton } from 'components';
import { useMultipleSelection, useSelect } from 'downshift';
import React from 'react';
import { Chevron } from '../Chevron';
import { Paper } from '../Paper';
import { DefaultProps } from '../types/DefaultProps';
import { StyledFormMultiSelect } from './FormMultiSelect.styled';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { FormCheckBox } from '../FormCheckBox';
import { Chip } from '../Chip';
import { ActionIcon } from 'components';
import Icon from '../../assets/icons';
import { Stack } from '../Stack';
import { Div } from 'components/Div';

type SelectItem = { value: string; label: string };

export type FormMultiSelectProps = Omit<
	DefaultProps<HTMLSelectElement>,
	'onChange'
> & {
	placeholder?: string;

	options: SelectItem[];

	defaultSelectedOptions?: (options: SelectItem[]) => SelectItem[];

	width?: React.CSSProperties['width'];

	onChange?: (selectedItems: SelectItem[]) => void;

	onInit?: (selectedItems: SelectItem[]) => void;

	label?: string;

	allOptionsProps?: any;

	allOptionsLabel?: React.ReactNode;

	disableAllOptions?: boolean;

	inputWrapperProps?: InputWrapperProps;

	showPlaceholder?: boolean;

	error?: React.ReactNode;

	isDisabled?: boolean;
};

const defaultProps = {
	width: '100%',
};

export const FormMultiSelect = React.forwardRef<
	HTMLElement,
	FormMultiSelectProps
>(
	(
		{
			label,
			placeholder,
			allOptionsProps,
			allOptionsLabel,
			disableAllOptions,
			width,
			options,
			defaultSelectedOptions,
			onInit: initialState,
			onChange,
			inputWrapperProps,
			showPlaceholder,
			hidden,
			error,
			isDisabled,
			...rest
		},
		ref
	) => {
		const _options = React.useMemo(() => options, [options]);
		const widthNumber = Number(String(width).replace('px', ''));

		const {
			getSelectedItemProps,
			setSelectedItems,
			getDropdownProps,
			addSelectedItem,
			removeSelectedItem,
			selectedItems,
		} = useMultipleSelection<SelectItem>({
			initialSelectedItems: defaultSelectedOptions
				? defaultSelectedOptions(_options)
				: [],
			onSelectedItemsChange: ({ selectedItems }) => {
				if (onChange && selectedItems) {
					onChange(selectedItems);
				}
			},
		});

		const {
			isOpen,
			selectedItem,
			getToggleButtonProps,
			getLabelProps,
			getMenuProps,
			getItemProps,
		} = useSelect({
			items: _options,
			stateReducer: (state, actionAndChanges) => {
				const { changes, type } = actionAndChanges;
				switch (type) {
					case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
					case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
					case useSelect.stateChangeTypes.ItemClick:
						return {
							...changes,
							isOpen: true, // keep the menu open after selection.
						};
				}
				return changes;
			},
		});

		const [selectBoxRef, setSelectBoxRef] =
			React.useState<HTMLButtonElement | null>(null);

		React.useEffect(() => {
			if (initialState) {
				initialState(selectedItems);
			}
		}, []);

		const itemLabels = (
			<Stack gap={8} style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
				{selectedItems.map((item) => (
					<Chip key={item.value} color="black">
						{item.label}
						<ActionIcon
							style={{ padding: 0, margin: 0, height: 'auto', width: 'auto' }}
							onClick={(e: any) => {
								e.stopPropagation();
								removeSelectedItem(item);
							}}
						>
							<Icon name="cancel" />
						</ActionIcon>
					</Chip>
				))}
			</Stack>
		);

		return (
			<InputWrapper
				width={width}
				label={label}
				hidden={hidden}
				{...inputWrapperProps}
				error={error || null}
			>
				<StyledFormMultiSelect
					isDisabled={isDisabled}
					{...rest}
					{...getDropdownProps({
						...getToggleButtonProps({ ref: (ref) => setSelectBoxRef(ref) }),
					})}
				>
					<label {...getLabelProps()}>
						{showPlaceholder
							? placeholder
							: selectedItems.length
							? itemLabels
							: placeholder}
					</label>

					<Chevron isRotate={isOpen} color="orange" />
				</StyledFormMultiSelect>
				<Paper
					css={
						isOpen
							? {
									backgroundColor: '#ffffff',
									padding: '4px 0',
									position: 'absolute',
									width:
										String(widthNumber * 0.75) + 'px' ??
										selectBoxRef?.offsetWidth,
									zIndex: 999,
									maxHeight: '200px',
									overflow: 'auto',
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
							: null
					}
					{...getMenuProps()}
				>
					{isOpen && _options.length && !disableAllOptions ? (
						<UnstyledButton
							css={(theme) => ({
								width: String(widthNumber * 0.75) + 'px',
							})}
							{...allOptionsProps}
						>
							<FormCheckBox
								containerProps={{ style: { padding: '12px 10px' } }}
								readOnly
								isDeterminate={
									selectedItems.length > 0 &&
									selectedItems.length < _options.length
								}
								onChange={() => {
									if (selectedItems.length === 0) {
										setSelectedItems(_options);
									} else {
										setSelectedItems([]);
									}
								}}
								checked={selectedItems.length === _options.length}
								label={allOptionsLabel ?? `Select all`}
							/>
						</UnstyledButton>
					) : null}
					{isOpen
						? _options?.map((item, index: number) => (
								<UnstyledButton
									key={`${item.value}${index}`}
									// {...getSelectedItemProps({ selectedItem: item, index })}
									css={(theme) => ({
										width: String(widthNumber * 0.75) + 'px',
										padding: '0px 0px',
										backgroundColor:
											selectedItem?.value === item.value
												? theme.colors.grey05
												: theme.colors.white,
										':hover':
											selectedItem?.value !== item.value
												? {
														backgroundColor: theme.colors.grey05,
														// eslint-disable-next-line no-mixed-spaces-and-tabs
												  }
												: {},
									})}
								>
									<FormCheckBox
										containerProps={{ style: { padding: '12px 10px' } }}
										onChange={(e) => {
											e.stopPropagation();
											if (e.currentTarget.checked) {
												addSelectedItem(item);
											} else {
												// For future dev(s), 'removeSelectedItem' does not seem to work here. I have no idea why. My best guess is because each option is being rendered twice and the item gets added back as soon as it is removed.
												setSelectedItems(
													selectedItems.filter((e) => e.value !== item.value)
												);
											}
										}}
										checked={
											!!selectedItems?.find((e) => e.value === item.value)
										}
										label={item.label}
									/>
								</UnstyledButton>
						  ))
						: null}
				</Paper>
			</InputWrapper>
		);
	}
);

FormMultiSelect.displayName = 'FormMultiSelect';
FormMultiSelect.defaultProps = defaultProps;

import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';
import { Div } from '../Div';
import { Stack, StackProps } from '../Stack';
import { Tooltip } from '@mantine/core';

export type PaymentMethodGroupProps = StackProps;

export const PaymentMethodGroup = React.forwardRef<
	HTMLDivElement,
	PaymentMethodGroupProps
>(({ children, ...rest }, ref) => {
	const theme = useTheme();
	const parsedChildren = React.Children.toArray(children);
	const hasMore = parsedChildren.length - 3;

	const label = <Stack gap={5}>{parsedChildren.slice(3)}</Stack>;

	return (
		<Stack gap={5} {...rest} align="center" ref={ref}>
			{parsedChildren.slice(0, 3)}
			{hasMore >= 1 ? (
				<Tooltip
					position="right"
					offset={0}
					label={label}
					sx={{
						backgroundColor: theme.colors.white,
						border: `1px solid ${theme.colors.grey10}`,
						borderRadius: '4px',
						padding: '5px',
					}}
				>
					<Div
						css={(theme) => ({
							cursor: 'pointer',
							background: theme.colors.grey10,
							color: theme.colors.grey70,
							fontSize: '14px',
							fontWeight: 600,
							borderRadius: '25px',
							height: '32px',
							width: '32px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						})}
					>
						<Div>{hasMore}+</Div>
					</Div>
				</Tooltip>
			) : null}
		</Stack>
	);
});

PaymentMethodGroup.displayName = 'PaymentMethodGroup';

import axios, { AxiosError, isAxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAppState } from '../../providers/AppStateProvider';
import { useModals } from '../../providers/ModalsProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { useGetPosById } from '../api/pos/useGetPosById';
import { configApi } from '../../apis';

export function useSyncPos() {
	const notify = useNotification();
	const queryClient = useQueryClient();

const syncPos = async ({
	posId,
	event_uuid,
	accessToken,
}: {
	posId: string;
	posName?: string;
	event_uuid: string;
	accessToken?: string | null;
}) => {
	const notify = useNotification();

	if(!accessToken) {
		notify.error(`Failed to sync due to user has no access right.`, { autoClose: 10000 });
		return;
	}

	console.log(`Sync initiated for pos_id:${posId}, event_uuid: ${event_uuid} `);

	// send api call to menu config server
	const { data } = await configApi.post(
		`pos/${posId}/sync`,
		{
			event_uuid,
			origin_datetime: new Date().toISOString(),
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

		return data;
	};

	return useMutation(syncPos, {
		onSettled: () => {
			queryClient.refetchQueries({ queryKey: ['pos'] });
		},
		onSuccess: (_, { event_uuid, posName }) => {
			notify.success(`Sync initiated for ${posName}`);

			// Start a 1 second interval to listen to socket response
			const interval = setInterval(() => {
				const value = localStorage.getItem(event_uuid);
				if (value?.length) {
					const _value = JSON.parse(value);

					if (_value.response_type === 'SUCCESS') {
						notify.success(`${posName} has been successfully synced.`, {
							autoClose: 10000,
						});
					}

					if (_value.response_type === 'ERROR') {
						notify.error(`${posName} failed to sync. ${_value?.error??''}`, { autoClose: 10000 });
					}

					localStorage.removeItem(event_uuid)
					clearInterval(interval);
				}
			}, 1000);
			
			// Start a 2 mins timer to close the interval
			setTimeout(() => {
				clearInterval(interval);
			},10000 * 12 /** 120,000 ms */);
		},
		onError: (err, { posName }) => {
			if (isAxiosError(err)) {
				// Notify of error, no timer started
				notify.error(
					`${posName} failed to sync. ${err?.response?.data?.error}`,
					{
						autoClose: false,
					},
					
				);
			}
			queryClient.refetchQueries({ queryKey: ['pos'] });
		},
	});
}

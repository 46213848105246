import { useMutation } from 'react-query';
import { txDumpApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { useNotification } from '../../../providers/NotificationProvider';

export function useUndoClosing() {
	const notify = useNotification();

	const { getAccessToken } = useAuth();
	const undoClosing = async ({
		sessionUid,
		posId,
		eventUuid,
		userId,
	}: {
		sessionUid: string;
		posId: string;
		posName?: string;
		eventUuid: string;
		userId: number;
	}) => {
		console.log(
			`Undo closing initiated for pos_id:${posId}, event_uuid: ${eventUuid} `
		);

		// send api call to tx dump server
		const { data } = await txDumpApi.post(
			`/closings/${sessionUid}/undo`,
			{
				pos_id: posId,
				event_uuid: eventUuid,
				origin_datetime: new Date().toISOString(),
				user_id: userId,
			},
			{
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);

		return data;
	};
	return useMutation(undoClosing, {
		onSuccess: (_, { posName, eventUuid }) => {
			// Start a 2 mins timer to listen to socket response
			notify.success(`Undo closing initiated for POS ${posName}`);

			// Check socket event every second to update the user
			const socketInterval = setInterval(() => {
				// Get localstorage value
				const value = localStorage.getItem(eventUuid);
				if (value?.length) {
					const _value = JSON.parse(value);

					if (_value.response_type === 'SUCCESS') {
						notify.success(`${posName} closing has been undone.`, {
							autoClose: Infinity,
						});
					}

					if (_value.response_type === 'ERROR') {
						notify.error(`${posName} failed to undo closing.`, {
							autoClose: Infinity,
						});
					}

					clearInterval(socketInterval ?? null);
					clearTimeout(socketTimeout ?? null);
				}
			}, 1000 /** 1,000 ms */);

			// Timeout the socket request if not getting response within 12 seconds
			const socketTimeout = setTimeout(() => {
				clearInterval(socketInterval ?? null);
				notify.error(`${posName} failed to undo closing.`, {
					autoClose: Infinity,
				});
			}, 1000 * 12 /** 12,000 ms */);
		},
		onError: (_, { posName }) => {
			// Notify of error
			notify.error(`${posName} failed to sync.}`, {
				autoClose: Infinity,
			});
		},
	});
}

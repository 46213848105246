import React from 'react';
import { Div, Image, Stack, Text } from '../components';
import error500 from 'assets/images/error-500.jpg';
import { useAppState } from '../providers/AppStateProvider';

const Error500 = () => {
	const { setIsSidebarHidden: set } = useAppState();

	React.useEffect(() => {
		set(true);
		return () => set(false);
	}, []);

	return (
		<Div
			style={{
				display: 'grid',
				height: 'calc(100vh - 62px)',
				width: '100%',
				placeItems: 'center',
			}}
		>
			<Stack direction="column" gap={20} align="center">
				<Image src={error500} alt="500 Server Error" width="724px" />
				<Text weight={600} size="24px">
					Oops, something is wrong.
				</Text>
				<Text size="16px">
					We’re not exactly sure what has happened, but our servers say
					something is wrong.
				</Text>
			</Stack>
		</Div>
	);
};

export default Error500;

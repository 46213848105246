import React from 'react';
import { useParams } from 'react-router-dom';
import { Group } from '@mantine/core';
import {
	Box,
	Breadcrumbs,
	Button,
	Div,
	Divider,
	Field,
	Formik,
	FormTextInput,
	LastUpdatedLabel,
	SelectBrand,
	SelectField,
	SelectCategoryGroup,
	Stack,
	useFormikContext,
	ViewContainer,
	CancelButton,
} from '../../components';

import { GrabIntegration } from './Categories/GrabIntegration';
import { useGetCategoryById } from '../../hooks';
import { useEditCategories } from '../../hooks/api/categories/useEditCategories';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { useAuth } from 'providers/AuthProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { ProductRoutes } from '../../router/constants/Products.routes';
import {
	EditCategorySchema,
	EditCategorySchemaWithBC,
} from '../../schemas/category.schema';
import { useDisableSelectCompany } from '../../hooks/useDisableSelectCompany';
import { useGetCategoryGroups } from '../../hooks';
import RequirePermissions from '../../guards/RequirePermissions';
import { useStopNavigateDirtyForm } from '../../hooks/useStopNavigateDirtyForm';

const _EditCategory = (category: any) => {
	useDisableSelectCompany();
	const { permissions } = useAuth();
	const { selectedBrand, isCompanyBCIntegrated } = useAppState();
	const { data: categoryGroupsData } = useGetCategoryGroups({
		brandId: selectedBrand || 0,
	});

	const {
		values,
		errors,
		setFieldValue,
		dirty,
		submitForm,
		validateForm,
		setFieldError,
		isSubmitting,
	} = useFormikContext<any>();

	permissions?.includes('CategoryGroups.Edit') &&
		useStopNavigateDirtyForm(dirty);

	const notify = useNotification();
	const navigate = useNavigate();

	React.useEffect(() => {
		setFieldValue('brand_id', selectedBrand);
	}, [selectedBrand]);

	const crumbs = [
		{ label: 'Categories', onClick: () => navigate(ProductRoutes.Categories) },
		{ label: values.name },
	];

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Group w="100%" position="apart">
					<Breadcrumbs items={crumbs} />
					{/* To uncomment when delete/archive function is complete */}
					{/* <Button leftIcon={<Icon name="delete" />} color="red">
						Delete
					</Button>
					<Button color="gray.3" style={{ color: 'black' }}>
						Archive
					</Button> */}
				</Group>
				<Group w="100%" position="apart" my="xl">
					<SelectBrand css={{ marginTop: '20px' }} isDisabled />
					<LastUpdatedLabel
						lastUpdated={category?.category?.updated_at}
						editor={category?.category?.updated_by}
					/>
				</Group>
				<Divider />
			</Box>

			<fieldset
				disabled={!permissions?.includes('Categories.Edit') || isSubmitting}
			>
				<Box style={{ width: '100%', margin: '36px 0' }}>
					<Stack direction="row" gap={30}>
						<Stack direction="column" gap={10}>
							<Field
								width="600px"
								as={FormTextInput}
								label="Category Name"
								isRequired
								name="name"
								maxLength={80}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									setFieldValue('name', e.currentTarget.value);
									if (e.currentTarget.value) {
										setFieldError('name', '');
									}
								}}
								error={(errors['name'] as string) && 'This field is required.'}
							/>
							<Field
								width="600px"
								as={FormTextInput}
								label="Chinese Name"
								name="name_other_languages.zh"
								maxLength={40}
							/>
							<Field
								width="600px"
								as={FormTextInput}
								label="Malay Name"
								name="name_other_languages.ms"
								error={errors['name_other_languages.ms']}
								maxLength={80}
							/>
							<Field
								width="600px"
								as={FormTextInput}
								label="Description"
								name="description"
								maxLength={80}
							/>
							{isCompanyBCIntegrated && (
								<Field
									width="600px"
									as={FormTextInput}
									label="BC Dimension Code (Category suffix)"
									name="dimension_code_suffix"
									maxLength={30}
									error={
										(errors['dimension_code_suffix'] as string) &&
										'This field is required.'
									}
								/>
							)}
							<Field
								width="600px"
								as={FormTextInput}
								label="Accounting Category"
								name="acct_category"
								isRequired
								maxLength={80}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									setFieldValue('acct_category', e.currentTarget.value);
									if (e.currentTarget.value) {
										setFieldError('acct_category', '');
									}
								}}
								error={
									(errors['acct_category'] as string) &&
									'This field is required.'
								}
							/>

							<Field
								as={SelectField}
								label="Category Group"
								labelWidth={160}
								width={450}
								placeholder="Select Category Group"
								value={String(values?.category_group_id)}
								onChange={(value: string) => {
									setFieldValue('category_group_id', +value);
								}}
								data={[
									{ value: '0', label: 'None' },
									...(categoryGroupsData?.categoryGroups?.map((e: any) => ({
										value: e.id.toString(),
										label: e.name,
									})) || []),
								]}
							/>

							<Div
								component="a"
								css={(theme) => ({
									color: theme.colors.primary,
									fontSize: '14px',
									cursor: 'pointer',
									marginTop: '15px',
									marginLeft: '150px',
								})}
								onClick={() => navigate(ProductRoutes.CategoryGroups)}
							>
								Manage category groups
							</Div>
							<Stack
								width="100%"
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									marginTop: '15px',
								}}
							>
								<GrabIntegration />
							</Stack>
							<Stack css={{ padding: '50px 0', alignSelf: 'flex-start' }}>
								<RequirePermissions permissions={['Categories.Edit']}>
									<Button
										onClick={async () => {
											const errors = await validateForm();
											if (
												values?.is_grab_category &&
												!values?.selling_time_id
											) {
												notify.error(
													'A Grab category must have a Selling Time Group attached.'
												);
												errors['selling_time_id'] = 'Grab error';
											}

											if (Object.keys(errors).length) {
												notify.error(
													'Invalid fields is preventing you from saving the form',
													{ autoClose: 3000 }
												);
											} else {
												submitForm();
											}
										}}
									>
										Save changes
									</Button>

									<CancelButton
										onClick={() => navigate(ProductRoutes.Categories)}
									>
										Cancel
									</CancelButton>
								</RequirePermissions>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</fieldset>
		</ViewContainer>
	);
};

const EditCategory = () => {
	const { user } = useAuth();
	const { categoryId } = useParams<{ categoryId: string }>();
	const { data: category, isFetched } = useGetCategoryById(categoryId);
	const { isCompanyBCIntegrated } = useAppState();
	const navigate = useNavigate();
	const notify = useNotification();
	const { mutate } = useEditCategories({
		onSuccess: () => {
			navigate(ProductRoutes.Categories, {}, true);
			notify.success(
				<p>
					Category has been <strong>successfully</strong> saved
				</p>
			);
		},
	});

	return (
		<Formik
			initialValues={{
				id: category?.id ?? 0,
				name: category?.name ?? '',
				name_other_languages: {
					zh: category?.name_other_languages?.zh ?? '',
					ms: category?.name_other_languages?.ms ?? '',
				},
				brand_id: category?.brand_id ?? 0,
				description: category?.description ?? '',
				dimension_code_suffix: category?.dimension_code_suffix ?? '',
				acct_category: category?.acct_category ?? '',
				category_group_id: category?.category_group_id ?? 0,
				is_grab_category: category?.is_grab_category ?? false,
				selling_time_id: category?.selling_time_id ?? null,
				updated_by: user?.name ?? '',
			}}
			onSubmit={(values) => {
				if (categoryId) {
					const {
						category_group_id,
						is_grab_category,
						selling_time_id,
						...rest
					} = values;
					if (is_grab_category && !selling_time_id) {
						notify.error(
							'A Grab category must have a Selling Time Group attached.'
						);
					} else {
						mutate({
							categoryId,
							values: {
								category_group_id:
									category_group_id === 0 ? null : category_group_id,
								is_grab_category,
								selling_time_id,
								...rest,
							},
						});
					}
				}
			}}
			validationSchema={toFormikValidationSchema(
				isCompanyBCIntegrated ? EditCategorySchemaWithBC : EditCategorySchema
			)}
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
		>
			{isFetched && <_EditCategory category={category} />}
		</Formik>
	);
};

export default EditCategory;

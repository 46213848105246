import { useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useDeletePos() {
	const { getAccessToken } = useAuth();

	const deleteOnePos = async (id: number) => {
		const response = await configApi.delete(`pos/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
  
	return useMutation(deleteOnePos, {
		onSuccess: () => {
			queryClient.invalidateQueries('pos');
		},
	});
}

import styled from '@emotion/styled';
import { Div } from '../Div';
import { OverlayProps } from './Overlay';

export const StyledOverlay = styled(Div)<OverlayProps>`
	background-color: ${({ theme }) => theme.colors.black};
	height: ${({ fill }) => (fill ? '100%' : '100vh')};
	width: ${({ fill }) => (fill ? '100%' : '100vw')};
	position: ${({ fill }) => (fill ? 'absolute' : 'fixed')};
	top: 0;
	left: 0;
	opacity: 0.8;
	z-index: 999;
`;

import {
	Button,
	Grid,
	Flex,
	Group,
	Checkbox,
	ColorInput,
	Collapse,
	Text
} from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Breadcrumbs,
	CancelButton,
	Divider,
	Field,
	Formik,
	ImageUploader,
	LastUpdatedLabel,
	SelectBrand,
	TextField,
	ViewContainer,
} from '../../../components';
import { FieldWrapper } from '../../../components/FieldWrapper';
import { SwitchField } from '../../../components/SwitchField';
import { useGetCategoryGroupById } from '../../../hooks';
import { useDeleteCategoryGroupById } from '../../../hooks/api/categorygroups/useDeleteCategoryGroupById';
import { useUpdateCategoryGroupById } from '../../../hooks/api/categorygroups/useUpdateCategoryGroupById';
import { useGetPaymentMethods } from '../../../hooks/payments';
import { useNavigate } from '../../../lib/navigate';
import { useAppState } from '../../../providers/AppStateProvider';
import { useTheme } from '../../../providers/ThemeProvider';
import { ProductRoutes } from '../../../router/constants/Products.routes';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import DeleteCategoryGroup from './DeleteCategoryGroup';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

const EditCategoryGroup = () => {
	const { selectedBrand, } = useAppState();
	const { permissions: userPermissions, user } = useAuth();
	const { categoryGroupId } = useParams<{ categoryGroupId: string }>();
	const { data: paymentMethodsData } = useGetPaymentMethods(selectedBrand || 0);

	const navigate = useNavigate();
	const { data: categoryGroup } = useGetCategoryGroupById({
		categoryGroupId: categoryGroupId ?? 0,
	});
	const theme = useTheme();
	const { mutate: updateCategoryGroup } = useUpdateCategoryGroupById();
	const { mutate: deleteCategoryGroup } = useDeleteCategoryGroupById();

	return (
		<Formik
			initialValues={{
				is_allow_same_group_only:
					categoryGroup?.is_allow_same_group_only ?? false,
				is_follow_pos_payment_methods:
					categoryGroup?.is_follow_pos_payment_methods ?? true,
				hex_color: categoryGroup?.hex_color ?? theme.colors.primary,
				image_url: categoryGroup?.image_url ?? null,
				name: categoryGroup?.name ?? null,
				name_other_languages: categoryGroup?.name_other_languages ?? {
					ms: '',
					zh: '',
				},
				brand_id: categoryGroup?.brand_id || 0,
				is_archived: categoryGroup?.is_archived,
				payment_methods: categoryGroup?.payment_methods || [],
				updated_by: user?.name ?? '',
			}}
			onSubmit={(values) =>
				updateCategoryGroup(
					{ id: categoryGroup?.id || 0, values },
					{
						onSuccess: () => {
							navigate(ProductRoutes.CategoryGroups, {}, true);
						},
					}
				)
			}
			validateOnChange={false}
			validateOnMount={false}
			enableReinitialize
		>
			{({ values, touched, errors, setFieldValue, submitForm, dirty, isSubmitting }) => {
				(userPermissions?.includes("CategoryGroups.Edit"))  
					&& useStopNavigateDirtyForm(dirty)
				return (
					<ViewContainer>
						<Box style={{ position: 'relative', marginBottom: '20px' }}>
							<Group w="100%" position="apart">
								<Breadcrumbs
									items={[
										{
											label: 'Category Groups',
											onClick: () => navigate(ProductRoutes.CategoryGroups),
										},
										{
											label: categoryGroup?.name || 'Update Category Group',
										},
									]}
								/>
							</Group>
							<Group w="100%" position="apart">
								<SelectBrand
									isDisabled
									sx={{ margin: '20px 0' }}
									defaultValue={categoryGroup?.brand_id.toString()}
								/>
								<LastUpdatedLabel
									lastUpdated={categoryGroup?.updated_at || ''}
									editor={categoryGroup?.updated_by}
								/>
							</Group>									
							<Divider/>
						</Box>
						<fieldset disabled={!userPermissions?.includes("CategoryGroups.Edit") 
							|| isSubmitting} >

							<Grid>
								<Grid.Col md={12} lg={8} pr="xl">
									<Flex direction="column" gap="20px">
										<Field
											as={TextField}
											name="name"
											label="Name"
											isRequired
											maxLength={80}
											error={
												touched['name'] &&
												(errors['name'] as string) &&
												'This field is required.'
											}
										/>
										<Field
											as={TextField}
											name="name_other_languages.zh"
											label="Chinese Name"
											maxLength={40}
										/>
										<Field
											as={TextField}
											name="name_other_languages.ms"
											label="Malay Name"
											maxLength={80}
										/>
										<SwitchField
											size="md"
											label="Allow transactions from same Category Group only"
											onLabel="Yes"
											offLabel="No"
											checked={values?.is_allow_same_group_only}
											onChange={(e) =>
												setFieldValue(
													'is_allow_same_group_only',
													e.currentTarget.checked
												)
											}
										/>
										{values['is_allow_same_group_only'] ? (
											<SwitchField
												size="md"
												label="Use same payment methods as POS"
												onLabel="Yes"
												offLabel="No"
												checked={values?.is_follow_pos_payment_methods}
												onChange={(e) => {
													setFieldValue(
														'is_follow_pos_payment_methods',
														e.currentTarget.checked
													);
													if (e.currentTarget.checked) {
														setFieldValue('payment_methods', []);
													}
												}}
											/>
										) : null}
										<Collapse
											in={
												values['is_allow_same_group_only'] &&
												!values['is_follow_pos_payment_methods']
											}
										>
											<FieldWrapper label="Category Group Payment Methods">
												<Checkbox.Group
													onChange={(selected) =>
														setFieldValue('payment_methods', selected)
													}
													value={values?.payment_methods?.map((e) => String(e))}
												>
													<div>
														{paymentMethodsData?.paymentMethods?.map((e: any) => (
															<Checkbox
																sx={{
																	':not(:first-of-type)': {
																		borderTop: `0.5px solid gray`,
																	},
																}}
																p="md"
																size="md"
																key={e?.id}
																value={String(e?.id)}
																label={e?.name}
															/>
														))}
													</div>
												</Checkbox.Group>
											</FieldWrapper>
										</Collapse>
										<FieldWrapper label="Color">
											<ColorInput
												value={values?.hex_color}
												onChange={(color) => {
													if (color) {
														setFieldValue('hex_color', color);
													}
												}}
											/>
										</FieldWrapper>
									</Flex>
								</Grid.Col>
								<Grid.Col md={12} lg={4} pl="xl">
									<ImageUploader
										url={values['image_url'] || ''}
										onChange={(url) => setFieldValue('image_url', url)}
										inactiveTabs={[
											'Payment Methods',
											'Products',
											'Brands',
											'Discounts',
											'Advertisements',
										]}
									/>
									<Text align="left" fs="italic" size="xs" color="gray" >
										Recommended Dimensions: W 170px x H 100px
									</Text>
								</Grid.Col>
								<Group my="20px">
									<RequirePermissions permissions={['CategoryGroups.Edit']}>
										<Button onClick={() => submitForm()}>Save changes</Button>
										<CancelButton
											onClick={() => navigate(ProductRoutes.CategoryGroups)}
										>
											Cancel
										</CancelButton>
									</RequirePermissions>
								</Group>
							</Grid>
						</fieldset>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default EditCategoryGroup;

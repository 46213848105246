/* eslint-disable no-mixed-spaces-and-tabs */
import { Title } from '@mantine/core';
import React from 'react';
import { DefaultProps } from '../types/DefaultProps';
import { StyledViewContainer } from './ViewContainer.styled';

export type ViewContainerProps = Omit<DefaultProps<HTMLDivElement>, 'title'> & {
	title?: React.ReactNode;
};

export const ViewContainer = React.forwardRef<
	HTMLDivElement,
	ViewContainerProps
>(({ children, title, ...rest }, ref) => {
	return (
		<StyledViewContainer {...rest} ref={ref}>
			{title ? (
				typeof title === 'string' ? (
					<Title color="dark" order={2}>
						{title}
					</Title>
				) : (
					<>{title}</>
				)
			) : null}
			{children}
		</StyledViewContainer>
	);
});

ViewContainer.displayName = 'ViewContainer';

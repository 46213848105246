import React from 'react';
import {
	Box,
	ActionIcon,
	Button,
	Div,
	FormCheckBox,
	FormTextInput,
	Stack,
	Text,
	Tooltip,
} from 'components';
import {
	Table,
	TableHead,
	TableHeadCol,
	TableRow,
	TableDataCol,
} from './OptionsList.components';
import Icon from 'assets/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Field, useFormikContext } from 'formik';
import { useNotification } from 'providers/NotificationProvider';
import { currency } from 'lib';
import { newOption } from './OptionGroup.constants';
import { Group, NumberInput } from '@mantine/core';
import { useAuth } from '../../../providers/AuthProvider';
import RequirePermissions from '../../../guards/RequirePermissions';

type Options = {
	name: string;
	name_other_languages: {
		zh: string;
		ms: string;
	};
	option_value: number;
	is_default: boolean;
};

type OptionsListType = {
	optionsList: Options[];
};

const OptionsList = ({ optionsList }: OptionsListType) => {
	const { setFieldValue, errors, values } = useFormikContext<any>();
	const toast = useNotification();
	const { permissions } = useAuth();
	const deleteItem = (index: number) => {
		const _list = [...optionsList];

		if (optionsList.length - 1 < 2) {
			toast.error('There must at least be 2 options for this option group');
		} else {
			if (+values?.max_selections === _list.length) {
				setFieldValue('max_selections', (_list.length - 1).toString());
			}

			if (+values?.min_selections === _list.length) {
				setFieldValue('min_selections', (_list.length - 1).toString());
			}
			_list.splice(index, 1);
			setFieldValue('options', _list);
		}
	};
	return (
		<Stack direction="column" gap={30}>
			<Box style={{ width: '100%', marginBottom: '5px' }}>
				<Group w="100%" position="apart">
					<Text
						size={'24px'}
						weight="bold"
						style={{ display: 'flex', alignSelf: 'flex-start' }}
					>
						Options
					</Text>
					{optionsList.length < 60 && (
						<RequirePermissions permissions={['OptionGroups.Edit']}>
							<Button
								leftIcon={<Icon name="add" />}
								onClick={() => {
									setFieldValue('options', [...optionsList, newOption]);
								}}
							>
								Add option
							</Button>
						</RequirePermissions>
					)}
				</Group>
			</Box>
			<DragDropContext
				onDragEnd={({ source, destination }) => {
					if (source && destination) {
						const _list = [...optionsList];
						const [itemToRemove] = _list.splice(source.index, 1);
						_list.splice(destination?.index, 0, itemToRemove);
						setFieldValue('options', _list);
					}
				}}
			>
				{/* <pre>{JSON.stringify(optionsList, null, 2)}</pre> */}
				<Droppable droppableId={`droppable-listgroup`} direction="vertical">
					{(provided, snapshot) => (
						<Table {...provided.droppableProps} ref={provided.innerRef}>
							<TableHead>
								<TableHeadCol></TableHeadCol>
								<TableHeadCol>Name</TableHeadCol>
								<TableHeadCol>Chinese Name</TableHeadCol>
								<TableHeadCol>Malay Name</TableHeadCol>
								<TableHeadCol>Additional Cost (S$)</TableHeadCol>
								<TableHeadCol>Default</TableHeadCol>
								<TableHeadCol></TableHeadCol>
							</TableHead>
							{optionsList.map((e, i) => (
								<Draggable
									key={`option-${i}`}
									index={i}
									draggableId={`${e.name}-${i}`}
									isDragDisabled={!permissions?.includes('OptionGroups.Edit')}
								>
									{(provided, snapshot) => (
										<TableRow
											key={i}
											ref={provided.innerRef}
											{...provided.draggableProps}
										>
											{/* <pre>{JSON.stringify(e.option_value)}</pre> */}
											<TableDataCol isIcon width={'50px'}>
												{(optionsList.at(i)?.name !== '' ||
													optionsList.length > 2) && (
													<RequirePermissions
														permissions={['OptionGroups.Edit']}
													>
														<Icon
															name="delete"
															onClick={() => {
																deleteItem(i);
															}}
														/>
													</RequirePermissions>
												)}
											</TableDataCol>
											<TableDataCol width='210px'>
												<Field
													width="210px"
													as={FormTextInput}
													name={`options[${i}].name`}
													maxLength={80}
													onChange={(e: any) => {
														setFieldValue(`options[${i}.name]`, e.target.value);
													}}
												/>
											</TableDataCol>
											<TableDataCol width='210px'>
												<Field
													width="210px"
													as={FormTextInput}
													name={`options[${i}].name_other_languages.zh`}
													maxLength={80}
													onChange={(e: any) => {
														setFieldValue(
															`options[${i}.name_other_languages.zh]`,
															e.target.value
														);
													}}
												/>
											</TableDataCol>
											<TableDataCol width='210px'>
												<Field
													width="210px"
													as={FormTextInput}
													name={`options[${i}].name_other_languages.ms`}
													maxLength={80}
													onChange={(e: any) => {
														setFieldValue(
															`options[${i}.name_other_languages.ms]`,
															e.target.value
														);
													}}
												/>
											</TableDataCol>
											<TableDataCol width='120px' style={{padding:'5px'}}>
												<NumberInput
													w={120}
													name={`options[${i}].option_value`}
													type="number"
													hideControls
													precision={2}
													step={0.01}
													min={0}
													value={
														currency(optionsList?.at(i)?.option_value ?? 0, {
															fromCents: true,
														}).value ?? 0.0
													}
													onChange={(value) => {
														if (typeof value === 'number') {
															setFieldValue(
																`options[${i}].option_value`,
																currency(value).intValue
															);
														}
													}}
												/>
											</TableDataCol>
											<TableDataCol
												width={'30px'}
												style={{
													padding:'5px',
													textAlign: 'justify',
												}}
											>
												<FormCheckBox
													checked={optionsList?.at(i)?.is_default ?? false}
													onChange={(e) => {
														setFieldValue(
															`options[${i}].is_default`,
															e.currentTarget.checked
														);
													}}
												/>
											</TableDataCol>

											<TableDataCol width='30px'>
												<Div style={{width:'30px'}}>
													<RequirePermissions permissions={['OptionGroups.Edit']}>
														<Tooltip label="Reorder">
															<ActionIcon {...provided.dragHandleProps}>
																<Icon name="draghandle" />
															</ActionIcon>
														</Tooltip>
													</RequirePermissions>
												</Div>
											</TableDataCol>
										</TableRow>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Table>
					)}
				</Droppable>
			</DragDropContext>
		</Stack>
	);
};

export default OptionsList;

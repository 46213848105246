import { useMutation, useQueryClient } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useEditUser() {
	const queryClient = useQueryClient();
	const { getUserInfo, getAccessToken, userId: userIdFromAuth } = useAuth();
	return useMutation(
		async ({ userId, payload }: { userId: string | number; payload: any }) =>
			authApi
				.put(`/users/${userId}`, payload, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			onSuccess: (_, { userId }) => {
				queryClient.invalidateQueries(`['users', '${userId}']`);
				if (userIdFromAuth) {
					getUserInfo(userIdFromAuth);
				}
			},
		}
	);
}

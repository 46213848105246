import React from 'react';
import EditKitchenGroup from 'views/Products/Kitchen_Groups/KitchenGroupView';
import Categories from 'views/Products/Categories';
import CategoryGroups from 'views/Products/CategoryGroups';
import CreateCategoryGroup from 'views/Products/CategoryGroups/CreateCategoryGroup';
import EditCategoryGroup from 'views/Products/CategoryGroups/EditCategoryGroup';
import CreateCategory from 'views/Products/CreateCategory';
import CreateProduct from 'views/Products/CreateProduct';
import EditCategory from 'views/Products/EditCategories';
import EditProduct from 'views/Products/EditProduct';
import KitchenGroups from 'views/Products/Kitchen_Groups/KitchenGroups';
import CreateKitchenGroups from 'views/Products/Kitchen_Groups/KitchenGroupsCreate';
import Menu from 'views/Products/Menu/Menu';
import MenuPreview from 'views/Products/Menu/MenuPreview';
import OptionGroups from 'views/Products/OptionGroups';
import OptionGroupCreate from 'views/Products/OptionGroups/OptionGroupCreate';
import EditOptionGroup from 'views/Products/OptionGroups/OptionGroupView';
// import SellingTimes from 'views/Products/SellingTimes/SellingTimes';
import {
	SellingTimes,
	CreateSellingTime,
	EditSellingTime,
} from 'views/Products/SellingTimes';
import { ProductRoutes } from '../constants/Products.routes';
import { ProtectedRoute } from '../ProtectedRoute';
import { CategoriesContextProvider } from 'views/Products/Categories/context';

export const productsRoutes = [
	{
		name: 'Menu',
		path: ProductRoutes.Menu,
		sidebar: true,
		element: (
			<ProtectedRoute permissions={['Menu.View', 'Menu.Edit']}>
				<Menu />
			</ProtectedRoute>
		),
	},
	{
		name: 'Menu Preview',
		path: ProductRoutes.MenuPreview,
		sidebar: false,
		element: (
			<ProtectedRoute permissions={['Menu.View','Menu.Edit']}>
				<MenuPreview />
			</ProtectedRoute>
		),
	},
	{
		name: 'Categories',
		path: ProductRoutes.Categories,
		sidebar: true,
		element: (
			<ProtectedRoute permissions={['Categories.View', 'Categories.Edit']}>
				<CategoriesContextProvider>
					<Categories />
				</CategoriesContextProvider>
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Category',
		path: ProductRoutes.CreateCategory,
		element: (
			<ProtectedRoute permissions={['Categories.Create']}>
				<CreateCategory />
			</ProtectedRoute>
		),
	},
	{
		name: 'Category Groups',
		path: ProductRoutes.CategoryGroups,
		sidebar: true,
		element: (
			<ProtectedRoute
				permissions={['CategoryGroups.View', 'CategoryGroups.Edit']}
			>
				<CategoryGroups />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Category Groups',
		path: ProductRoutes.CreateCategoryGroups,
		sidebar: false,
		element: (
			<ProtectedRoute permissions={['CategoryGroups.Create']}>
				<CreateCategoryGroup />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Category Groups',
		path: ProductRoutes.EditCategoryGroups,
		sidebar: false,
		element: (
			<ProtectedRoute permissions={['CategoryGroups.View', 'CategoryGroups.Edit']}>
				<EditCategoryGroup />
			</ProtectedRoute>
		),
	},
	{
		name: 'Option Groups',
		path: ProductRoutes.OptionGroups,
		sidebar: true,
		element: (
			<ProtectedRoute permissions={['OptionGroups.View', 'OptionGroups.Edit']}>
				<OptionGroups />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Option Group',
		path: ProductRoutes.CreateOptionGroup,
		sidebar: false,
		element: (
			<ProtectedRoute permissions={['OptionGroups.Create']}>
				<OptionGroupCreate />
			</ProtectedRoute>
		),
	},
	{
		name: 'View Option Group',
		path: ProductRoutes.EditOptionGroup,
		sidebar: false,
		element: (
			<ProtectedRoute permissions={['OptionGroups.View', 'OptionGroups.Edit']}>
				<EditOptionGroup />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Product',
		path: ProductRoutes.CreateProduct,
		element: (
			<ProtectedRoute permissions={['Categories.Create']}>
				<CreateProduct />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Category',
		path: ProductRoutes.EditCategory,
		element: (
			<ProtectedRoute permissions={['Categories.View','Categories.Edit']}>
				<EditCategory />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Product',
		path: ProductRoutes.EditProduct,
		element: (
			<ProtectedRoute permissions={['Categories.View','Categories.Edit']}>
				<EditProduct />
			</ProtectedRoute>
		),
	},
	{
		name: 'Kitchen Groups',
		path: ProductRoutes.KitchenGroups,
		element: (
			<ProtectedRoute
				permissions={['KitchenGroups.View', 'KitchenGroups.Edit']}
			>
				<KitchenGroups />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Kitchen Group',
		path: ProductRoutes.CreateKitchenGroups,
		element: (
			<ProtectedRoute permissions={['KitchenGroups.Create']}>
				<CreateKitchenGroups />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Kitchen Group',
		path: ProductRoutes.EditKitchenGroups,
		element: (
			<ProtectedRoute permissions={['KitchenGroups.View', 'KitchenGroups.Edit']}>
				<EditKitchenGroup />
			</ProtectedRoute>
		),
	},
	{
		name: 'View All Selling Times',
		path: ProductRoutes.SellingTimes,
		element: (
			<ProtectedRoute permissions={['Categories.View']}>
				<SellingTimes />
			</ProtectedRoute>
		),
	},
	{
		name: 'Create Selling Time',
		path: ProductRoutes.CreateSellingTimes,
		element: (
			<ProtectedRoute permissions={['Categories.Create']}>
				<CreateSellingTime />
			</ProtectedRoute>
		),
	},
	{
		name: 'Edit Selling Time',
		path: ProductRoutes.EditSellingTimes,
		element: (
			<ProtectedRoute permissions={['Categories.Edit']}>
				<EditSellingTime />
			</ProtectedRoute>
		),
	},
];

import React from 'react';
import { Group } from '@mantine/core';
import {
	Box,
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	Divider,
	Field,
	Formik,
	FormTextInput,
	SelectBrand,
	SelectCategoryGroup,
	Stack,
	useFormikContext,
	ViewContainer,
} from '../../components';
import { GrabIntegration } from './Categories/GrabIntegration';
import { useCreateCategories } from '../../hooks/api/categories/useCreateCategories';
import { toFormikValidationSchema } from '../../lib/helpers';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { ProductRoutes } from '../../router/constants/Products.routes';
import {
	CreateCategorySchema,
	CreateCategorySchemaWithBC,
} from '../../schemas/category.schema';
import { useAuth } from 'providers/AuthProvider';

const _CreateCategory = () => {
	const { setDirty, selectedBrand, isCompanyBCIntegrated } = useAppState();

	const {
		errors,
		setFieldValue,
		dirty,
		touched,
		values,
		submitForm,
		validateForm,
		setFieldError,
	} = useFormikContext<any>();
	const notify = useNotification();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (dirty) {
			setDirty(true);
		}

		return () => setDirty(false);
	}, [dirty]);

	React.useEffect(() => {
		setFieldValue('brand_id', selectedBrand);
	}, [selectedBrand]);

	const crumbs = [
		{ label: 'Categories', onClick: () => navigate(ProductRoutes.Categories) },
		{ label: 'Create Category' },
	];

	return (
		<ViewContainer>
			<Box style={{ position: 'relative', marginBottom: '20px' }}>
				<Group w="100%" position="apart">
					<Breadcrumbs items={crumbs} />
				</Group>
				<Group w="100%" position="apart" my="xl">
					<SelectBrand css={{ marginTop: '20px' }} />
				</Group>
				<Divider />
			</Box>

			<Box style={{ width: '100%', margin: '36px 0' }}>
				<Stack direction="row" gap={30}>
					<Stack direction="column" gap={10}>
						<Field
							width="600px"
							as={FormTextInput}
							label="Category Name"
							isRequired
							name="name"
							maxLength={80}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								setFieldValue('name', e.currentTarget.value);
								if (e.currentTarget.value) {
									setFieldError('name', '');
								}
							}}
							error={(errors['name'] as string) && 'This field is required.'}
						/>
						<Field
							width="600px"
							as={FormTextInput}
							label="Chinese Name"
							name="name_other_languages.zh"
							maxLength={40}
						/>
						<Field
							width="600px"
							as={FormTextInput}
							label="Malay Name"
							name="name_other_languages.ms"
							error={errors['name_other_languages.ms']}
							maxLength={80}
						/>
						<Field
							width="600px"
							as={FormTextInput}
							label="Description"
							name="description"
							maxLength={80}
						/>
						{isCompanyBCIntegrated && (
							<Field
								width="600px"
								as={FormTextInput}
								label="BC Dimension Code (Category suffix)"
								name="dimension_code_suffix"
								maxLength={30}
								placeholder={'Example DRK'}
								isRequired
								error={
									(errors['dimension_code_suffix'] as string) &&
									'This field is required.'
								}
							/>
						)}
						<Field
							width="600px"
							as={FormTextInput}
							label="Accounting Category"
							name="acct_category"
							isRequired
							maxLength={80}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								setFieldValue('acct_category', e.currentTarget.value);
								if (e.currentTarget.value) {
									setFieldError('acct_category', '');
								}
							}}
							error={
								(errors['acct_category'] as string) && 'This field is required.'
							}
						/>
						<SelectCategoryGroup
							width="600px"
							label="Category Group"
							onChange={({ value }) =>
								setFieldValue(
									'category_group_id',
									value === '0' ? null : +value
								)
							}
						/>
						<Div
							component="a"
							css={(theme) => ({
								color: theme.colors.primary,
								fontSize: '14px',
								cursor: 'pointer',
								marginTop: '15px',
								marginLeft: '150px',
							})}
							onClick={() => navigate(ProductRoutes.CategoryGroups)}
						>
							Manage category groups
						</Div>
						<GrabIntegration />
						<Stack
							width="100%"
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								marginTop: '15px',
							}}
						>
							<Button
								onClick={async () => {
									const errors = await validateForm();
									if (values?.is_grab_category && !values?.selling_time_id) {
										notify.error(
											'A Grab category must have a Selling Time Group attached.'
										);
										errors['selling_time_id'] = 'Grab error';
									}

									if (Object.keys(errors).length) {
										notify.error(
											'Invalid fields is preventing you from saving the form',
											{ autoClose: 3000 }
										);
									} else {
										submitForm();
									}
								}}
							>
								Create
							</Button>
							<CancelButton onClick={() => navigate(ProductRoutes.Categories)}>
								Cancel
							</CancelButton>
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</ViewContainer>
	);
};

const CreateCategory = () => {
	const { setDirty, isCompanyBCIntegrated } = useAppState();
	const { user } = useAuth();
	const navigate = useNavigate();
	const notify = useNotification();
	const { mutate } = useCreateCategories({
		onSuccess: () => {
			setDirty(false);
			navigate(ProductRoutes.Categories, {}, true);
			notify.success(
				<p>
					Category has been <strong>successfully</strong> created
				</p>
			);
		},
	});
	return (
		<Formik
			initialValues={{
				name: '',
				name_other_languages: {
					zh: '',
					ms: '',
				},
				brand_id: 0,
				description: '',
				dimension_code_suffix: '',
				acct_category: '',
				category_group_id: null,
				is_grab_category: false,
				selling_time_id: null,
				updated_by: user?.name ?? '',
			}}
			onSubmit={(values) => {
				const { is_grab_category, selling_time_id, ...rest } = values;
				if (is_grab_category && !selling_time_id) {
					notify.error(
						'A Grab category must have a Selling Time Group attached.'
					);
				} else {
					mutate(values);
				}
			}}
			validationSchema={toFormikValidationSchema(
				isCompanyBCIntegrated
					? CreateCategorySchemaWithBC
					: CreateCategorySchema
			)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<_CreateCategory />
		</Formik>
	);
};

export default CreateCategory;

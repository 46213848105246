import styled from '@emotion/styled';

export type StyledTableProps<T> = HTMLTableElement & {
	data: T[];
	columns: string[];
};

export const StyledTable = styled.table`
	font-family: ${({ theme }) => theme.font.sansSerif};
	width: 100%;
	border-collapse: collapse;

	thead {
		background-color: ${({ theme }) => theme.colors.grey05};
		color: ${({ theme }) => theme.colors.grey100};
		text-align: left;
		font-size: 14px;
	}

	th {
		padding: 0.75em 3.5em;
	}

	th span {
		cursor: pointer;
	}

	th p {
		display: inline-block;
	}

	tr td {
		background-color: white;
	}

	tbody td {
		padding: 1.5em 3.5em;
		border-left-style: solid;
		border-left-color: transparent;
		border-bottom-style: solid;
		border-bottom-color: ${({ theme }) => theme.colors.grey05};
	}

	tr:hover {
		td {
			background-color: ${({ theme }) => theme.colors.grey10};
			cursor: pointer;
		}

		td:first-child {
			border-left-style: solid;
			border-left-color: ${({ theme }) => theme.colors.primary};
		}
	}
`;

import React from 'react';
import { InputRadio } from '../InputRadio';
import { ListGroup } from '../ListGroup';

export type RadioListGroupItemProps = {
	label: React.ReactNode;
	name: string;
	checked: boolean;
	onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

export const RadioListGroupItem = React.forwardRef<
	HTMLDivElement,
	RadioListGroupItemProps
>(({ label, name, onChange, checked, ...rest }, ref) => {
	return (
		<ListGroup.Item ref={ref}>
			<InputRadio
				label={label}
				name={name}
				checked={checked}
				onChange={(e) => onChange(e)}
				{...rest}
			/>
		</ListGroup.Item>
	);
});

RadioListGroupItem.displayName = 'RadioListGroupItem';

import { Group, JsonInput, Stack } from '@mantine/core';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Divider,
	SelectBrand,
	ViewContainer,
} from 'components';
import { Formik } from 'formik';
import React from 'react';
import { currency } from 'lib';

// API Hooks
import { useCreateNewPos } from 'hooks/api/pos/useCreatePos';
import { useGetPaymentMethods } from 'hooks/payments';

import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { CreatePosSchema } from 'schemas/pos.schema';
import { TPosPaymentMethod } from 'schemas/pospaymentmethods.schema';
import { useNavigate } from '../../../lib/navigate';

import dayjs from 'dayjs';
import { ErrorType } from './Constants';
import BasisSettings from './PointOfSaleCreate/BasisSettings';
import BillsAndReceipts from './PointOfSaleCreate/BillsAndReceipts';
import ClosingPosSession from './PointOfSaleCreate/ClosingPosSession';
import Discount from './PointOfSaleCreate/Discount';
import Payment from './PointOfSaleCreate/Payment';
import PosAdvertisement from './PointOfSaleCreate/PosAdvertisement';
import PosInterface from './PointOfSaleCreate/PosInterface';
import PosOperation from './PointOfSaleCreate/PosOperation';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useAuth } from 'providers/AuthProvider';
import { ConfigurationRoutes } from 'router/constants/Configuration.routes';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';

const PointOfSaleCreate = () => {
	useDisableSelectCompany();
	const navigate = useNavigate();
	const { user } = useAuth();
	const toast = useNotification();
	const { selectedBrand, setDirty, isCompanyBCIntegrated } = useAppState();

	const { data: paymentMethodsData } = useGetPaymentMethods(selectedBrand!);

	const { mutateAsync: createNewPos } = useCreateNewPos();

	const breadcrumbsArray = [
		{
			label: 'Point of Sales',
			onClick: () => navigate('/configuration/pos'),
		},
		{
			label: 'Create Point of Sales',
			onClick: () => navigate('#'),
		},
	];

	const formInitialValues = {
		name: '',
		grab_store_id: '',
		outlet_id: 0,
		service_charge: 0,
		is_service_charge_in_item_price: false,
		has_quick_sign_in: true,
		is_display_cash_denominations: true,
		is_display_product_price: true,
		cash_exact_type: 'show_exact_button',
		has_manager_pin_on_view_report: false,
		is_24h: false,
		op_opening_time: '00:00:00',
		op_closing_time: '00:30:00',
		closing_grace_period_hour: 1,
		number_of_shift: 1,
		is_change_shift_mandatory: true,
		is_equal_shift_interval: true,
		change_shift_intervals: [],
		payment_methods:
			paymentMethodsData?.paymentMethods?.map((e: any, i: number) => ({
				paymentmethod_id: e?.id,
				pos_payment_code: '',
				sort_order: i,
				is_active: e?.is_read_only ?? false,
				is_read_only: e?.is_read_only,
			})) || ([] as TPosPaymentMethod[]),
		default_opening_cash: 0,
		discounts: [],
		is_preview_receipt: false,
		num_receipt_per_transaction: 1,
		is_display_queue: false,
		is_allow_zero_cash_collection: false,
		authorised_difference: 0,
		has_report_preview_before_confirm: false,
		advertisement_duration_seconds: 1,
		advertisements: [] as string[],
		updated_by: user?.name ?? '',
	};

	return (
		<ViewContainer
			title={
				<>
					<Breadcrumbs items={breadcrumbsArray} />
					<Group w="100%" position="apart">
						<SelectBrand my="xl" />
					</Group>
				</>
			}
		>
			<Formik
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				enableReinitialize
				initialValues={formInitialValues}
				validate={(values) => {
					try {
						CreatePosSchema.parse(values);
						const errors: ErrorType = {};

						// Check if there is valid outlet selected
						if (values.outlet_id === 0) {
							errors.outlet_id = 'Please select a valid outlet';
						}

						// Validates that the closing time comes after the opening time
						if (
							!values.is_24h &&
							!dayjs(`1970-01-01 ${values.op_closing_time}`).isAfter(
								dayjs(`1970-01-01 ${values.op_opening_time}`)
							)
						) {
							errors.op_closing_time =
								'Closing time must be after opening time';
						}

						// Validates that the number of shift timings chosen is 1 less than number of total shifts
						if (
							values.number_of_shift > 1 &&
							!values.is_equal_shift_interval &&
							values.number_of_shift - values.change_shift_intervals.length !==
								1
						) {
							errors.change_shift_intervals =
								'Total selected intervals must be one less than number of shifts';
						}

						// Validates pos_payment_code exist if payment method selected
						if (
							isCompanyBCIntegrated &&
							values.payment_methods.some(
								(p: any) => p.is_active && !p.pos_payment_code
							)
						) {
							errors.payment_methods = 'This field is required';
						}

						return errors;
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values, actions) => {
					const {
						authorised_difference,
						default_opening_cash,
						...restOfValues
					} = values;

					await createNewPos({
						authorised_difference: currency(authorised_difference).intValue,
						default_opening_cash: currency(default_opening_cash).intValue,
						...restOfValues,
					})
						.then((newPos) => {
							setDirty(false);
							navigate('/configuration/pos', {}, true);
							toast.success('POS has been successfully created');
						})
						.catch((error) => {
							toast.error(error?.response?.data?.error ?? 'An error occured');
						});
				}}
			>
				{({ values, errors, dirty, submitForm }) => {
					useStopNavigateDirtyForm(dirty);

					return (
						<>
							<Divider />

							<Stack mt={30} w="100%" spacing="xl">
								<BasisSettings showDeviceCode={false} />
								<Divider />
								<PosInterface />
								<Divider />
								<PosOperation />
								<Divider />
								<Payment />
								<Divider />
								<Discount />
								<Divider />
								<BillsAndReceipts />
								<Divider />
								<ClosingPosSession />
								<Divider />
								<PosAdvertisement />
								<Group my="xl">
									<Button onClick={() => submitForm()}>Create</Button>
									<CancelButton
										onClick={() => navigate(ConfigurationRoutes.Pos)}
									>
										Cancel
									</CancelButton>
								</Group>
							</Stack>
						</>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

export default PointOfSaleCreate;

import React from 'react';
import { Add, Archive } from '@mui/icons-material';
import {
	ActionIcon,
	Breadcrumbs,
	Button,
	Div,
	Menu,
	SelectBrand,
	Stack,
	ViewContainer,
	Text,
	Tooltip,
	EmptyState,
} from 'components';

import { JsonInput } from '@mantine/core';
import { SimpleDataGrid } from '../../../components/SimpleDataGrid';
import { useNavigate } from '../../../lib/navigate';
import { useGetBrands } from 'hooks/brands';
import { useGetKitchenGroups } from 'hooks/kitchengroups';
import { useAppState } from 'providers/AppStateProvider';
import { useModals } from 'providers/ModalsProvider';
// import { LinkedPOSModal } from './Modals';
import dayjs from 'dayjs';
import Icon from 'assets/icons';
import { useAuth } from 'providers/AuthProvider';
import { useGetSellingTimes } from 'hooks/api/sellingtimes/useGetSellingTimes';
import { ProductRoutes } from 'router/constants/Products.routes';

export const SellingTimes = () => {
	const { permissions } = useAuth();
	const modal = useModals();
	const navigate = useNavigate();
	const breadcrumbsArray = [
		{
			label: 'Selling Times',
			onClick: () => navigate('#'),
		},
	];

	const { selectedCompany, selectedBrand } = useAppState();
	const { data: brandsData } = useGetBrands(selectedCompany!);
	// const { data: response_kitchen_groups } = useGetKitchenGroups(
	// 	selectedBrand ?? 0
	// );

	const { data: response_selling_times } = useGetSellingTimes(
		selectedBrand ?? 0
	);

	const dummyData = [
		{
			name: 'Selling Time 1',
			updated_at: new Date(),
		},
		{
			name: 'Selling Time 2',
			updated_at: new Date(),
		},
		{
			name: 'Selling Time 3',
			updated_at: new Date(),
		},
	];

	return (
		<ViewContainer>
			<Breadcrumbs items={breadcrumbsArray} />
			<Div style={{ position: 'relative', marginTop: '10px' }}>
				<SelectBrand />

				{/* <JsonInput value={JSON.stringify(response_selling_times, null, 2)} /> */}

				<Stack gap={10} style={{ marginBottom: '10px' }}>
					<Div
						style={{
							position: 'absolute',
							right: 0,
							top: 0,
						}}
					>
						{brandsData?.brands?.length ? (
							<Button
								type="button"
								leftIcon={<Add fontSize="inherit" />}
								onClick={() => navigate(ProductRoutes.CreateSellingTimes)}
							>
								Create Selling Time
							</Button>
						) : null}
					</Div>

					<Div></Div>
				</Stack>

				<SimpleDataGrid
					data={response_selling_times?.selling_times}
					columns={[
						{
							accessorKey: 'name',
							header: 'Name',
						},
						{
							accessorKey: 'updated_at',
							header: 'Last Updated',
							cell: (row: any) =>
								dayjs(row.getValue()).format('DD/MM/YYYY hh:mm:ssa'),
						},
					]}
					onClickHandler={(row) =>
						navigate(`/products/sellingtimes/${row.original.id}`)
					}
				/>

				{brandsData?.brands?.length === 0 ? (
					<EmptyState
						resource="brands"
						secondaryMessage="Please create Brand before creating Selling Time."
						CallToAction={
							permissions?.includes('Brands.Create') && (
								<Button
									type="button"
									leftIcon={<Add fontSize="inherit" />}
									onClick={() => navigate('/configuration/brands/create')}
								>
									Create Brand
								</Button>
							)
						}
					/>
				) : (
					response_selling_times?.selling_times?.length === 0 && (
						<EmptyState resource="Selling Time" />
					)
				)}
			</Div>
		</ViewContainer>
	);
};

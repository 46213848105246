import React from 'react';
import { useAuth } from '../providers/AuthProvider';

export type TPermission =
	| 'Menu.Edit'
	| 'Menu.View'
	| 'Categories.Create'
	| 'Categories.Edit'
	| 'Categories.Delete'
	| 'Categories.Archive'
	| 'Categories.View'
	| 'CategoryGroups.Create'
	| 'CategoryGroups.Edit'
	| 'CategoryGroups.Delete'
	| 'CategoryGroups.Archive'
	| 'CategoryGroups.View'
	| 'OptionGroups.Create'
	| 'OptionGroups.Edit'
	| 'OptionGroups.Delete'
	| 'OptionGroups.Archive'
	| 'OptionGroups.View'
	| 'OptionGroups.Create'
	| 'KitchenGroups.Create'
	| 'KitchenGroups.Edit'
	| 'KitchenGroups.Delete'
	| 'KitchenGroups.Archive'
	| 'KitchenGroups.View'
	| 'Companies.Create'
	| 'Companies.Edit'
	| 'Companies.Archive'
	| 'Companies.Delete'
	| 'Companies.View'
	| 'Brands.Create'
	| 'Brands.Edit'
	| 'Brands.Archive'
	| 'Brands.Delete'
	| 'Brands.View'
	| 'Outlets.Create'
	| 'Outlets.Edit'
	| 'Outlets.Archive'
	| 'Outlets.Delete'
	| 'Outlets.View'
	| 'PosList.View'
	| 'PosConfig.Create'
	| 'PosConfig.Edit'
	| 'PosConfig.Delete'
	| 'PosConfig.View'
	| 'PosSync.Edit'
	| 'PosSync.View'
	| 'Orders.Edit'
	| 'Orders.View'
	| 'Closings.Edit'
	| 'Closings.View'
	| 'PaymentMethods.Create'
	| 'PaymentMethods.Edit'
	| 'PaymentMethods.Delete'
	| 'PaymentMethods.View'
	| 'Discounts.Create'
	| 'Discounts.Edit'
	| 'Discounts.Delete'
	| 'Discounts.View'
	| 'Finance.Download'
	| 'Finance.View'
	| 'Operations.Download'
	| 'Operations.View'
	| 'Analytics.View'
	| 'Users.Create'
	| 'Users.Edit'
	| 'Users.Delete'
	| 'Users.View'
	| 'Roles.Create'
	| 'Roles.Edit'
	| 'Roles.Delete'
	| 'Roles.View'
	| 'BcIntegration.Edit'
	| 'BcIntegration.View';

export type RequirePermissionsProps = React.PropsWithChildren<{
	permissions?: TPermission[];
	superAdminOnly?: boolean;
}>;

const RequirePermissions: React.FC<RequirePermissionsProps> = ({
	children,
	permissions: requiredPermissions,
	superAdminOnly,
}) => {
	const { permissions: userPermissions, user } = useAuth();
	if (superAdminOnly) {
		const isAllowed = !!user?.is_superadmin;
		if (isAllowed) {
			return <>{children}</>;
		}
	}

	if (requiredPermissions && !superAdminOnly) {
		const isAllowed = requiredPermissions.some((e) =>
			userPermissions?.includes(e)
		);
		if (isAllowed) {
			return <>{children}</>;
		}
	}

	return null;
};

export default RequirePermissions;

import { useQuery } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from 'providers/AuthProvider';


export function useGetSellingTimes(brand_id: number) {
	const { getAccessToken } = useAuth();
	const getSellingTimes = async (): Promise<any> => {
		const response = await configApi.get(`selling-times`, {
			params: { brand_id },
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['selling_times', brand_id], getSellingTimes, {
		refetchOnWindowFocus: false,
		enabled: !!brand_id,
	});
}
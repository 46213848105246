import {
	Flex,
	Menu,
	MenuProps,
	Text,
	Title,
	UnstyledButton,
} from '@mantine/core';
import React from 'react';
import Icon from '../../assets/icons';
import { useNavigate } from '../../lib/navigate';
import {
	useGetCompaniesForDropdown,
	useGetBCIntegrationStatus,
} from '../../hooks/companies';
import { useAppState } from '../../providers/AppStateProvider';
import { useAuth } from '../../providers/AuthProvider';
import { ConfigurationRoutes } from '../../router/constants/Configuration.routes';
import jwtDecode from 'jwt-decode';
import { modals } from '@mantine/modals';

export type SelectCompanyProps = MenuProps;

export const SelectCompany = React.forwardRef<HTMLElement, SelectCompanyProps>(
	(props, ref) => {
		const { user, isAuthenticated, getAccessToken } = useAuth();
		const companiesOfUser = user?.roles?.map((e: any) => e.company_id);
		const { data, isFetching } = useGetCompaniesForDropdown({
			requested_ids: companiesOfUser,
		});
		const {
			selectedCompany,
			setCompany,
			setBCIntegration,
			isSelectCompanyDisabled,
		} = useAppState();

		useGetBCIntegrationStatus(selectedCompany!, {
			onSuccess: (data: boolean) => setBCIntegration(data),
		});

		const navigate = useNavigate();

		return !isFetching ? (
			<UnstyledButton
				w="352px"
				px="10px"
				sx={{
					pointerEvents: isSelectCompanyDisabled ? 'none' : 'inherit',
				}}
				onClick={() => {
					modals.openConfirmModal({
						children: (
							<>
								<Title mb="lg" order={3}>
									You are switching company
								</Title>
								<Text size="lg" my="lg">
									Any unsaved changes will be lost. Are you sure?
								</Text>
							</>
						),
						onConfirm: async () => {
							const access_token = await getAccessToken();
							const decoded: Record<string, any> = jwtDecode(access_token);
							navigate('/company', {
								state: {
									sub: decoded?.sub,
									decoded,
									data: {
										access_token,
									},
								},
							});
						},
						size: 'xl',
						cancelProps: {
							size: 'lg',
							style: {
								backgroundColor: '#dee2e6',
								color: '#2a2e33',
								borderStyle: 'none',
							},
						},
						confirmProps: {
							size: 'lg',
						},

						labels: {
							confirm: 'Switch company',
							cancel: 'Cancel',
						},
					});
				}}
			>
				<Flex>
					<Text
						align="left"
						w="100%"
						color={isSelectCompanyDisabled ? 'gray' : 'dark'}
					>
						{data?.companies.find((e: any) => {
							return e.id.toString() === selectedCompany?.toString();
						})?.['name'] ?? ''}
					</Text>
					{isSelectCompanyDisabled ? null : <Icon name="chevrondown" />}
				</Flex>
			</UnstyledButton>
		) : null;
	}
);

SelectCompany.displayName = 'SelectCompany';

import styled from '@emotion/styled';
import currency from 'currency.js';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Breadcrumbs,
	Button,
	Div,
	SelectBrand,
	Text,
	ViewContainer,
} from '../../../../components';
import { useGetClosingByUuid } from '../../../../hooks/api/closings/useGetClosingByUuid';
import { useUndoClosing } from '../../../../hooks/api/closings/useUndoClosing';
import { useGetOnlinePos } from '../../../../hooks/api/pos/useGetOnlinePos';
import { useNavigate } from '../../../../lib/navigate';
import { ConfigurationRoutes } from '../../../../router/constants/Configuration.routes';
import { v4 as uuidv4 } from 'uuid';
import { useGetPosById } from '../../../../hooks/api/pos/useGetPosById';
import { useAuth } from '../../../../providers/AuthProvider';
import { useGetClosings } from '../../../../hooks/api/closings/useGetClosings';
import RequirePermissions from '../../../../guards/RequirePermissions';


const Timeline = styled(Div)`
	display: flex;
	flex-direction: column;
`;

const StyledTimelineItem = styled(Div)`
	position: relative;
	display: flex;
	align-items: flex-start;

	::before {
		display: block;
		content: '';
		width: 1px;
		margin-right: 20px;
	}

	:not(:last-child) {
		::before {
			height: 80px;
			border: 1px solid ${({ theme }) => theme.colors.grey30};
			margin-right: 20px;
		}
	}
`;

const TimelineItem: React.FC<
	React.PropsWithChildren & { isActive?: boolean }
> = ({ children, isActive }) => (
	<StyledTimelineItem>
		<Div
			css={(theme) => ({
				width: isActive ? '25px' : '17px',
				height: isActive ? '25px' : '17px',
				borderRadius: '25px',
				border: isActive ? `4px solid ${theme.colors.secondary}` : 'none',
				backgroundColor: isActive ? theme.colors.white : theme.colors.secondary,
				position: 'absolute',
				left: isActive ? -11 : -7,
				top: 0,
			})}
		/>
		{children}
	</StyledTimelineItem>
);

const Grid = styled(Div)`
	display: grid;
	grid-template-columns: 200px 240px auto;
`;

const ViewClosing = () => {
	const { closingUid } = useParams();

	const { data: closing } = useGetClosingByUuid(closingUid);

	const openingCash = currency(closing?.opening_cash_amount, {
		fromCents: true,
		symbol: '',
	});

	const cashCollected = currency(closing?.cash_collection_amount, {
		fromCents: true,
		symbol: '',
	});

	const balance = openingCash.add(cashCollected);

	const { data: posOnline } = useGetOnlinePos();
	const { mutate } = useUndoClosing();
	const isPosOnline = posOnline?.includes(+closing?.pos_id);

	const { data: lastClosing } = useGetClosings({
		limit: '1',
		pos_ids: closing?.pos_id,
	});

	const showUndoButton = lastClosing?.closings?.at(0)?.uid === closing?.uid;

	const canUndoClosing =
		showUndoButton &&
		isPosOnline &&
		(closing?.status === 'closed' || closing?.status === 'undoing') &&
		closing?.can_undo;

	const { data: pos } = useGetPosById(+closing?.pos_id);
	const { userId } = useAuth();

	const undoClosingHandler = () => {
		const eventUuid = uuidv4();
		mutate({
			posId: closing?.pos_id,
			posName: pos?.name,
			sessionUid: closing?.uid,
			eventUuid,
			userId: userId || 1,
		});
	};

	const navigate = useNavigate();

	return (
		<ViewContainer
			title={
				<Breadcrumbs
					items={[
						{
							label: 'Closing',
							onClick: () => navigate(ConfigurationRoutes.Pos + '?tab=closing'),
						},
						{
							label: closing?.pos_id || '',
						},
					]}
				/>
			}
		>
			{/* <pre>{JSON.stringify(posOnline, null, 2)}</pre> */}
			{/* 
			<pre>{JSON.stringify(closing, null, 2)}</pre> */}
			<SelectBrand isDisabled css={{ margin: '40px 0' }} />
			<Div>
				<Timeline>
					<TimelineItem>
						<Div
							css={(theme) => ({
								width: '17px',
								height: '17px',
								borderRadius: '25px',
								backgroundColor: theme.colors.secondary,
								position: 'absolute',
								left: -7,
								top: 0,
							})}
						/>
						<Grid>
							<Text>To Open</Text>
							<Text>{`Opening Cash ($)`}</Text>
							<Text>{openingCash.format()}</Text>
						</Grid>
					</TimelineItem>
					<TimelineItem>
						<Grid>
							<Text>Opened</Text>
							<Text>{`Total Cash Collected ($)`}</Text>
							<Text>{cashCollected.format()}</Text>
						</Grid>
					</TimelineItem>
					<TimelineItem isActive>
						<Grid>
							<Text w="200px">Closed</Text>
							<Text>{`Balance ($)`}</Text>
							<Text>{balance.format()}</Text>
						</Grid>
					</TimelineItem>
				</Timeline>
			</Div>
			<Div css={{ margin: '50px 0px' }}>
				<RequirePermissions permissions={['Closings.Edit']}>
					<Button
						color="red"
						disabled={!canUndoClosing}
						onClick={undoClosingHandler}
					>
						{closing?.status === 'undoing' ? 'Undoing...' : 'Undo Closing'}
					</Button>
				</RequirePermissions>	
				{!isPosOnline ? (
					<Text size="14px" style={{ margin: '10px 0px' }}>
						POS is offline
					</Text>
				) : null}
			</Div>
		</ViewContainer>
	);
};

export default ViewClosing;

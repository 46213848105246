import React from 'react';
import { useGetCategoryGroups } from '../../hooks';
import { useAppState } from '../../providers/AppStateProvider';
import { FormSelectInput, FormSelectInputProps } from '../FormSelectInput';

export type SelectCategoryGroupProps = Omit<FormSelectInputProps, 'options'> & {
	/** Category group id */
	defaultSelected?: string;
};

export const SelectCategoryGroup = React.forwardRef<
	HTMLDivElement,
	SelectCategoryGroupProps
>(({ defaultSelected, ...rest }, ref) => {
	const { selectedBrand } = useAppState();
	const { data: categoryGroupsData } = useGetCategoryGroups({
		brandId: selectedBrand || 0,
	});

	const options = [
		{ value: '0', label: 'None' },
		...(categoryGroupsData?.categoryGroups?.map((e) => ({
			value: e.id.toString(),
			label: e.name,
		})) || []),
	];

	return (
		<FormSelectInput
			{...rest}
			ref={ref}
			defaultOption={
				defaultSelected
					? options?.find((e) => e.value === defaultSelected)
					: { value: '0', label: 'None' }
			}
			options={options || []}
		/>
	);
});

SelectCategoryGroup.displayName = 'SelectCategoryGroup';

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

type TUSeGetPos = {
	brandId: number;
	outletId?: number | string | null;
	paymentMethods?: number[];
	requestedPage?: number;
};

export function useGetPos({
	brandId,
	outletId,
	paymentMethods,
	requestedPage,
}: TUSeGetPos) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['pos', brandId, outletId, paymentMethods, requestedPage],
		async () =>
			configApi
				.get(`pos`, {
					params: {
						summary: 'yes',
						brand_id: brandId,
						outlet_id: outletId,
						payment_methods: paymentMethods?.join(','),
						requested_page: requestedPage,
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!brandId,
			refetchInterval: 60 * 1000 /** 60,000 ms = 60 secs */,
			refetchOnWindowFocus: true,
		}
	);
}

import styled from '@emotion/styled';
import { SpinnerProps } from './Spinner';

export const StyledSpinner = styled.span<SpinnerProps>`
	width: ${({ size }) => size ?? '16px'};
	height: ${({ size }) => size ?? '16px'};
	border: 2.5px solid
		${({ color, theme }) => (color ? theme.colors[color] : theme.colors.white)};
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

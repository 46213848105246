import {
	ActionIcon,
	Box,
	Button,
	Checkbox,
	Group,
	NumberInput,
	Select,
	SelectItem,
	Table,
	Text,
	Title,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useFormikContext } from 'formik';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { configApi } from '../../../apis';
import Icon from '../../../assets/icons';

const LocalisedPricing = () => {
	const { categoryId } = useParams();

	const { values, setFieldValue } = useFormikContext<{
		is_open_price: boolean;
		price: number;
		localized_price: { pos_id: number; is_hidden: boolean; price: number }[];
	}>();

	// const [listValues, listHandler] = useListState<any>([]);
	const [options, optionsHandler] = useListState<SelectItem>([]);

	useQuery(
		`pos/categories?category_id=${categoryId}`,
		() =>
			configApi
				.get(`/pos/categories?category_id=${categoryId}`)
				.then(({ data }) => data),
		{
			onSuccess: (data) => {
				optionsHandler.setState(
					data?.pos?.map((e: { id: number; pos_name: string }) => ({
						value: e?.id?.toString(),
						label: e?.pos_name,
					}))
				);
			},
		}
	);

	const selectedPos = values?.localized_price?.map(
		(e: { pos_id: number; is_hidden: boolean; price: number }) =>
			String(e.pos_id)
	);

	return (
		<Box my="lg">
			<Title order={3}>Localised Pricing</Title>
			<Table w="min-content" my="lg">
				<thead>
					<tr>
						<th style={{ width: '50px' }}></th>
						<th>
							<Text w={350} align="center">
								Point of Sale
							</Text>
						</th>
						<th>
							<Text w={25} align="center">
								Hidden
							</Text>
						</th>
						<th>
							<Text w={125} align="center">
								Price
							</Text>
						</th>
					</tr>
				</thead>
				<tbody>
					{values?.localized_price?.map(
						(
							e: { pos_id: number; is_hidden: boolean; price: number },
							i: number
						) => (
							<tr key={i}>
								<td>
									<ActionIcon
										onClick={() => {
											setFieldValue(
												'localized_price',
												values?.localized_price?.filter((_, idx) => idx !== i)
											);
										}}
									>
										<Icon name="delete" />
									</ActionIcon>
								</td>
								<td>
									<Select
										data={options.map((e) => ({
											...e,
											disabled: selectedPos.includes(e.value),
										}))}
										value={String(e.pos_id)}
										onChange={(value) => {
											if (value) {
												setFieldValue(`localized_price.${i}.pos_id`, +value);
											}
										}}
									/>
								</td>
								<td>
									<Group position="center" w="100%">
										<Checkbox
											checked={e.is_hidden}
											onChange={(e) => {
												setFieldValue(
													`localized_price.${i}.is_hidden`,
													e.currentTarget.checked
												);
											}}
										/>
									</Group>
								</td>
								<td>
									<NumberInput
										precision={2}
										w={125}
										hideControls
										value={e.price}
										min={0}
										max={9999.99}
										disabled={values?.is_open_price ?? false}
										onChange={(value) => {
											if (value === "") {
												value = 0;
											}
											setFieldValue(`localized_price.${i}.price`, value);
										}}
									/>
								</td>
							</tr>
						)
					)}
				</tbody>
			</Table>
			<Button
				my="lg"
				leftIcon={<Icon name="add" />}
				variant="light"
				disabled={
					!categoryId || values?.localized_price?.length === options.length
				}
				onClick={() => {
					const pos_id = options
						?.filter((e) => !selectedPos.includes(e.value))
						?.at(0)?.value;

					if (pos_id) {
						const newLocalizedPrices = [
							...values.localized_price,
							{
								pos_id: +pos_id,
								price: values?.price,
								is_hidden: false,
							},
						];
						setFieldValue('localized_price', newLocalizedPrices);
					}
				}}
			>
				Add New
			</Button>
			{/* <pre>
				{JSON.stringify(
					{
						filtered: options?.filter((e) => !selectedPos.includes(e.value)),
						selectedPos,
						listValues,
					},
					null,
					2
				)}
			</pre> */}
		</Box>
	);
};

export default LocalisedPricing;

import React from 'react';
import { Div, DivProps } from '../Div/Div';
import styled from '@emotion/styled';

export type StackProps = DivProps & {
	/** Determines the flex-direction of 100%s */
	direction?: React.CSSProperties['flexDirection'];
	/** Gap within flex box in pixels */
	gap?: number;
	justify?: React.CSSProperties['justifyContent'];
	align?: React.CSSProperties['alignItems'];
	width?: React.CSSProperties['width'];
	height?: React.CSSProperties['height'];
};

const StyledStack = styled(Div)<StackProps>`
	display: flex;
	box-sizing: border-box;
	flex-direction: ${({ direction }) => direction ?? 'row'};
	justify-content: ${({ justify }) => justify ?? 'center'};
	align-items: ${({ align }) => align ?? 'center'};
	gap: ${({ gap }) => gap ? `${gap}px` : '10px'};
	width: ${({ width }) => width ?? 'fit-content'};
	height: ${({ height }) => height ?? 'auto'};
`;

export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
	({ children, ...rest }, ref) => {
		return (
			<StyledStack ref={ref} {...rest}>
				{children}
			</StyledStack>
		);
	}
);

Stack.displayName = 'Stack';

import styled from '@emotion/styled';
import { ChevronProps } from './Chevron';

export const StyledChervon = styled.span<ChevronProps>`
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 22px;
	height: 22px;
	border: 2px solid transparent;
	border-radius: 100px;
	transition: all 0.2s ease-in-out;

	${({ isRotate }) => isRotate && `transform: rotate(180deg);`}

	::after {
		content: '';
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 10px;
		height: 10px;
		border-bottom: 2px solid ${({ color }) => color};
		border-left: 2px solid ${({ color }) => color};
		transform: rotate(-45deg);
		right: 2px;
		top: 2px;
	}
`;

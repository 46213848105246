import { reportAnalyticApi } from '../../../apis';
import { exportData } from './helpers/exportData';
import Papa from 'papaparse';

type ZReport = {
	fileName: string,
	report_ids: string[];
	terminal_ids: string[] | number[];
};

export async function useGetZReport({ fileName, report_ids, terminal_ids }: ZReport) {
	const response = await reportAnalyticApi.post(
		'/financial-reports/z-reports',
		{
			report_ids,
			terminal_ids,
		}
	);

	const parsedData = Papa.unparse(response.data);

	exportData(parsedData, `Z-REPORT ${fileName}.csv`, 'text/csv;charset=utf-8;');

	return response.data;
}

import { Box, Image, Stack, Text, TextInput } from '@mantine/core';
import React from 'react';

type AddImageToFolderProps = {
	url: string;
	fileName: string;
};

const AddImageToFolder = ({ url, fileName }: AddImageToFolderProps) => {
	return (
		<Box>
			<Stack>
				<Image
					fit="contain"
					src={url}
					withPlaceholder
					width={180}
					height={160}
				/>
				<Text>{fileName}</Text>
				<TextInput label="Folder" />
			</Stack>
		</Box>
	);
};

export default AddImageToFolder;

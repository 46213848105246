import { useMutation, useQueryClient } from 'react-query';
import { configApi } from 'apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useDeletePaymentMethod() {
	const { getAccessToken } = useAuth();

	const deleteOnePaymentMethod = async (id: number) => {
		const response = await configApi.delete(`payment-methods/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOnePaymentMethod, {
		onSuccess: () => {
			queryClient.invalidateQueries('payment_methods');
		},
	});
}

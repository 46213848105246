import {
	useQuery,
	useMutation,
	useQueryClient,
	UseQueryOptions,
} from 'react-query';
import { configApi } from '../apis';
import { useAuth } from '../providers/AuthProvider';

type CompanyType = {
	id?: number | string;
	name: string;
	bc_name?: string;
	uen: string;
	gst_number?: string;
};

export type ExpandedCompanyType = CompanyType & {
	has_bc_integration: number;
	is_archived: number;
	created_at: string;
	updated_at: string;
};

export function useGetCompanies() {
	const { getAccessToken } = useAuth();
	const getCompanies = async () => {
		const response = await configApi.get(`companies`, {
			params: {
				deleted: 'no',
				archived: 'no',
				is_summary: 'true',
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['companies'], getCompanies, {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});
}

export function useArchivedCompanies() {
	const { getAccessToken } = useAuth();
	const getCompanies = async () => {
		const response = await configApi.get(`companies`, {
			params: {
				deleted: 'no',
				archived: 'yes',
				is_summary: 'true',
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['companies_archived'], getCompanies);
}

export function useGetCompaniesForDropdown(_params?: {
	selectedPage?: number;
	sortOrder?: string;
	requested_ids?: number[];
}) {
	const { getAccessToken } = useAuth();
	const getCompanies = async () => {
		const response = await configApi.get(`companies`, {
			params: {
				dropdown: 'yes',
				deleted: 'no',
				archived: 'no',
				requested_page: _params?.selectedPage,
				sort_order: _params?.sortOrder,
				requested_ids: _params?.requested_ids?.join(','),
			},
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(
		['companies_dropdown', _params?.requested_ids],
		getCompanies,
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		}
	);
}

export function useUpdateCompany() {
	const { getAccessToken } = useAuth();
	const updateCompany = async (body: CompanyType) => {
		const id = body.id;

		const response = await configApi.put(`companies/${id}`, body, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(updateCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('companies');
			queryClient.invalidateQueries('companies_dropdown');
		},
	});
}

export function useGetOneCompany(id: number, summary = 'no') {
	const { getAccessToken } = useAuth();
	const getOneCompany = async () => {
		const response = await configApi.get(`companies/${id}?summary=${summary}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	return useQuery(['companies_by_id', id], getOneCompany, {
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

export function useCreateNewCompany() {
	const { getAccessToken } = useAuth();
	const createNewCompany = async (body: CompanyType) => {
		const newCompany = {
			...body,
			has_bc_integration: false,
			is_archived: false,
			created_at: new Date().toDateString(),
			updated_at: new Date().toDateString(),
		};

		const response = await configApi.post('companies', newCompany, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(createNewCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('companies');
			queryClient.invalidateQueries('companies_dropdown');
		},
	});
}

export function useDeleteOneCompany() {
	const { getAccessToken } = useAuth();
	const deleteOneCompany = async (id: number) => {
		const response = await configApi.delete(`companies/${id}`, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(deleteOneCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('companies');
			queryClient.invalidateQueries('companies_dropdown');
		},
	});
}

export function useArchiveCompany() {
	const { getAccessToken } = useAuth();
	const archiveCompany = async (id: number) => {
		const response = await configApi.post(`companies/${id}/archive`,{}, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(archiveCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('companiesArchived');
			queryClient.invalidateQueries('companies_dropdown');
		},
	});
}

export function useUnarchiveCompany() {
	const { getAccessToken } = useAuth();
	const unarchiveCompany = async (id: number) => {
		const response = await configApi.post(`companies/${id}/unarchive`,{}, {
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	};

	const queryClient = useQueryClient();
	return useMutation(unarchiveCompany, {
		onSuccess: () => {
			queryClient.invalidateQueries('companiesArchived');
			queryClient.invalidateQueries('companies_dropdown');
		},
	});
}

export function useCheckLinks(id: number) {
	const { getAccessToken } = useAuth();
	const  getLinks = async() => {

		const response = await configApi.get(`companies/${id}/checklinks`,{
			headers: {
				Authorization: `Bearer ${await getAccessToken()}`,
			},
		});
		return response.data;
	}

	return useQuery(['companies_links', id], getLinks, {refetchOnWindowFocus: false, enabled: !!id})

}

export async function useGetBCIntegrationStatus(
	id: number,
	opts?: Omit<
		UseQueryOptions<unknown, unknown, boolean, string[]>,
		'queryKey' | 'queryFn'
	>
) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['bcStatus'],
		async () =>
			configApi
				.get(`companies/${id}/getBCStatus`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{ enabled: !!id, ...opts }
	);
}

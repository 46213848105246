import styled from '@emotion/styled';
import {
	Box,
	Button,
	CancelButton,
	FormSelectInput,
	FormTextInput,
	InputPhoneNumberWrapper,
	Stack,
	Text,
	ViewContainer,
} from 'components';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Field, Formik } from 'formik';
import { useGetBrands } from 'hooks/brands';
import { useGetOneCompany } from 'hooks/companies';
import { useCreateNewOutlet } from 'hooks/outlets';
import { useGetMalls } from 'hooks/malls';
import { useAppState } from 'providers/AppStateProvider';
import { useModals } from 'providers/ModalsProvider';
import { useNotification } from 'providers/NotificationProvider';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
	OutletSchemaCreate,
	OutletSchemaCreateWithBC,
} from 'schemas/outlet.schema';
import { useNavigate } from '../../../lib/navigate';
import { DiscardCreationModal } from './Modals';
import { useAuth } from 'providers/AuthProvider';
import { ErrorType } from '../PointOfSale/Constants';

type BrandType = {
	id?: number;
	value: string;
	label: string;
};

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	background-color: #e8eaed;
	border: none;
`;

const OutletsCreate = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const { selectedCompany, selectedBrand, isCompanyBCIntegrated, setDirty } =
		useAppState();
	const {
		data: response,
		isFetching: fetchingBrands,
		refetch: refetchBrands,
	} = useGetBrands(selectedCompany || 0);

	const { mutateAsync: createNewOutlet } = useCreateNewOutlet();
	const { data: response_malls } = useGetMalls();

	const currencyList = [{ value: '1', label: 'SGD' }];
	const toast = useNotification();
	const modal = useModals();

	const breadcrumbsArray = [
		{
			label: 'Outlets',
			onClick: () => navigate('/configuration/outlets'),
		},
		{
			label: 'Create',
			onClick: () => navigate('#'),
		},
	];

	if ((!fetchingBrands && response.brands.length === 0) || !selectedBrand) {
		return <Navigate to={'/configuration/outlets'} replace />;
	} else {
		return (
			<ViewContainer>
				<Box style={{ position: 'relative', marginBottom: '20px' }}>
					<Breadcrumbs items={breadcrumbsArray} />
				</Box>

				<Box style={{ clear: 'both' }}></Box>
				{!fetchingBrands && (
					<Formik
						initialValues={{
							name: '',
							mall_id: null,
							brand_id: selectedBrand ?? 0,
							currency_id: 1,
							address_line1: '',
							address_line2: '',
							zip_code: '',
							phone_number: '',
							tax_reg_number: '',
							outlet_code: '',
							dimension_code_prefix: '',
							dimension_code_discount: '',
							updated_by: user?.name || '',
						}}
						validate={(values) => {
							try {
								if (isCompanyBCIntegrated) {
									OutletSchemaCreateWithBC.parse(values);
								} else {
									const {
										dimension_code_prefix,
										dimension_code_discount,
										...restOfValues
									} = values;
									OutletSchemaCreate.parse(restOfValues);
								}
							} catch (error: any) {
								return error.formErrors.fieldErrors;
							}
						}}
						onSubmit={async (values, actions) => {
							await createNewOutlet(values)
								.then(() => {
									setDirty(false);
									navigate('/configuration/outlets', {}, true);
									toast.success('Outlets has been successfully created');
								})
								.catch((error) => {
									toast.error('An error occured');
								});
							actions.setSubmitting(false);
						}}
					>
						{({
							values,
							errors,
							dirty,
							touched,
							setFieldValue,
							submitForm,
						}) => {
							React.useEffect(() => {
								if (dirty) {
									setDirty(true);
								}

								return () => setDirty(false);
							}, [dirty]);
							return (
								<Box style={{ width: '100%'}}>
									<Stack direction="row" gap={30}>
											<Stack direction="column" gap={10}>
												{!fetchingBrands && (
													<FormSelectInput
														width="940px"
														label="Brand"
														placeholder="Select Brand"
														defaultOption={{
															value: selectedBrand?.toString() ?? '0',
															label:
																response.brands?.find(
																	(e: any) => e.id === selectedBrand
																)?.name ?? '',
														}}
														onChange={({ value }) =>
															setFieldValue('brand_id', +value)
														}
														options={
															response?.brands?.map((e: any) => ({
																value: e.id.toString(),
																label: e.name,
															})) ?? []
														}
													/>
												)}

												<Field
													width="940px"
													as={FormTextInput}
													name="name"
													label="Outlet Name"
													isRequired={true}
													maxLength={80}
													error={errors.name && touched.name 
														? errors.name : null}
												/>

												<FormSelectInput
													label="Mall"
													defaultOption={{
														value: '0',
														label: 'No mall selected.',
													}}
													options={[
														{
															value: null,
															label: '',
														},
														...(response_malls?.malls?.map((e: any) => ({
															value: e.id,
															label: e.name,
														})) ?? []),
													]}
													placeholder="Select a mall here"
													onChange={(items: BrandType) => {
														if (items.value)
															setFieldValue('mall_id', Number(items.value));
														else setFieldValue('mall_id', null);
													}}
													width="940px"
												/>

												{isCompanyBCIntegrated ? (
													<>
														<Field
															width="940px"
															as={FormTextInput}
															name="dimension_code_prefix"
															label="BC Dimension Code (Outlet prefix)"
															isRequired={true}
															maxLength={30}
															error={
																errors.dimension_code_prefix &&
																touched.dimension_code_prefix
																	? errors.dimension_code_prefix
																	: null
															}
															placeholder="Example: ET-ARC"
														/>

														<Field
															width="940px"
															as={FormTextInput}
															name="dimension_code_discount"
															label="BC Dimension Code (Discount)"
															isRequired={true}
															maxLength={80}
															error={
																errors.dimension_code_discount &&
																touched.dimension_code_discount
																	? errors.dimension_code_discount
																	: null
															}
															placeholder="Example: ET-ARC-COM"
														/>
													</>
												) : (
													<Field
														width="940px"
														as={FormTextInput}
														name="outlet_code"
														label="Outlet Code"
														maxLength={30}
														error={
															errors.outlet_code && touched.outlet_code
																? errors.outlet_code
																: null
														}
														placeholder="Example: ET-ARC"
													/>
												)}
												

												<Divider />

												<Text
													component={'h1'}
													style={{ fontSize: '1.625rem', left: 0, width: '100%' }}
												>
													Address
												</Text>

												<Field
													width="940px"
													as={FormTextInput}
													name="address_line1"
													label="Street"
													isRequired={true}
													maxLength={80}
													error={
														errors.address_line1 && touched.address_line1
															? errors.address_line1
															: null
													}
												/>

												<Field
													width="940px"
													as={FormTextInput}
													name="address_line2"
													label="Unit"
													isRequired={false}
													maxLength={30}
													error={
														errors.address_line2 && touched.address_line2
															? errors.address_line2
															: null
													}
												/>

												<Field
													width="940px"
													as={FormTextInput}
													name="zip_code"
													label="Postal Code"
													isRequired={true}
													maxLength={30}
													error={
														errors.zip_code && touched.zip_code
															? errors.zip_code
															: null
													}
												/>

												<Divider />

												<Field
													width="940px"
													as={InputPhoneNumberWrapper}
													name="phone_number"
													label="Phone Number"
													isRequired={true}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
														setFieldValue('phone_number', e.target.value)
													}
													maxLength={8}
													error={
														errors.phone_number && touched.phone_number
															? errors.phone_number
															: null
													}
												/>

												<FormSelectInput
													label="Currency"
													defaultOption={{
														value: currencyList[0] ? currencyList[0].value : '',
														label: currencyList[0] ? currencyList[0].label : '',
													}}
													options={currencyList}
													placeholder="Select a brand here"
													onChange={(items: BrandType) => {
														// console.log(items);
														setFieldValue('currency_id', Number(items.value));
													}}
													width="940px"
												/>

												<Stack
													width={'100%'}
													style={{ display: 'flex', justifyContent: 'flex-start' }}
												>
													<Button type="submit" onClick={submitForm}>
														Create
													</Button>
													<CancelButton
														onClick={() => navigate('/configuration/outlets')}
													>
														Cancel
													</CancelButton>
												</Stack>
											</Stack>
										</Stack>
									</Box>
							);
						}}
					</Formik>
				)}
			</ViewContainer>
		);
	}
};

export default OutletsCreate;

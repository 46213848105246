import React from 'react';
import { DivProps } from '../Div';
import { ThemeColors } from '../types/ThemeColors';
import { StyledChip } from './Chip.styled';

export type ChipProps = Omit<DivProps, 'color'> & {
	color?: ThemeColors;
	leftIcon?: React.ReactNode;
};

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
	({ children, leftIcon, ...rest }, ref) => {
		return (
			<StyledChip {...rest} ref={ref}>
				{leftIcon ?? null}
				{children}
			</StyledChip>
		);
	}
);

Chip.displayName = 'Chip';

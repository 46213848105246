import { LoadingOverlay } from '@mantine/core';
import { useAuth } from 'providers/AuthProvider';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { RequirePermissionsProps } from '../guards/RequirePermissions';

export const ProtectedRoute: React.FC<{
	children: JSX.Element;
	superAdminOnly?: boolean;
	permissions?: RequirePermissionsProps['permissions'];
	isUserRoute?: boolean;
}> = ({ children, superAdminOnly, permissions: requiredPermissions, isUserRoute }) => {
	const { isAuthenticated, permissions: userPermissions, user } = useAuth();

	if (!isAuthenticated) {
		return <Navigate to="/redirect" state={{ redirect: '/dashboard' }} />;
	}

	if (superAdminOnly) {
		const isAllowed = user?.is_superadmin;

		if (!isAllowed) {
			return <Navigate to="/403" />;
		}
	}

	if (requiredPermissions) {

		const isAllowed = requiredPermissions?.some((e) =>
			userPermissions?.includes(e)
		);

		if (!isAllowed && !isUserRoute) {
			return <Navigate to="/403" replace />;
		}
	}

	return isAuthenticated ? children : <LoadingOverlay visible />;
};

import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	Formik,
	SelectBrand,
	Stack,
	Text,
	ViewContainer
} from '../../../components';
import { useAuth } from '../../../providers/AuthProvider';
import { useGetPosById } from '../../../hooks/api/pos/useGetPosById';
import { usePostPosSortOrder } from '../../../hooks/api/pos/usePostPosSortOrder';
import { useGetPosMenuById } from '../../../hooks/menus';
import { useNavigate } from '../../../lib/navigate';
import { useNotification } from '../../../providers/NotificationProvider';
import { ProductRoutes } from '../../../router/constants/Products.routes';
import AddCategories from './MenuPreview/AddCategories';
import MenuPreviewCategories from './MenuPreview/MenuPreviewCategories';
import MenuPreviewCategoryGroups from './MenuPreview/MenuPreviewCategoryGroups';
import MenuPreviewProducts from './MenuPreview/MenuPreviewProducts';
import { EmptyState } from '../../../components';
import { useStopNavigateDirtyForm } from '../../../hooks/useStopNavigateDirtyForm';
import { useDisableSelectCompany } from '../../../hooks/useDisableSelectCompany';
import RequirePermissions from '../../../guards/RequirePermissions';

const MenuPreview = () => {
	useDisableSelectCompany();
	const { posId } = useParams();
	const { data: pos } = useGetPosById(posId);
	const { data: menu } = useGetPosMenuById(posId || '0');
	const { mutate: patchSortOrder, isLoading } = usePostPosSortOrder();
	const navigate = useNavigate();
	const notify = useNotification();
	const { permissions: userPermissions } = useAuth();

	/**
	 * Submit handler
	 * 1. Link categories to POS
	 * 2. Patch sort order for categories
	 */

	const goToEditCategory = (selectedCategory: number | null) => {
		selectedCategory
			? navigate(`/products/categories/edit/${selectedCategory}`)
			: notify.error('Please select a category');
	};

	const [selectedCategory, setSelectedCategory] = React.useState<number | null>(
		null
	);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				posId,
				_categories: menu?.categories
					?.filter((e: any) => !e.category_group_id)
					?.map((e: any) => e.id),
				_categoryGroups: menu?.category_groups?.reduce(
					(acc: Record<string, any>, val: any) => {
						acc[val.id] = menu?.categories
							?.filter((category: any) => category.category_group_id === val.id)
							?.map((e: any) => e.id);

						return acc;
					},
					{}
				),
			}}
			onSubmit={(values) =>
				patchSortOrder(
					{
						pos_id: +(values?.posId || 0),
						categories: values._categories?.concat(
							Object.values(values?._categoryGroups).flat()
						),
					},
					{
						onSuccess: () => {
							notify.success(`${pos.name} Menu Updated`);
							navigate(ProductRoutes.Menu, {}, true);
						},
					}
				)
			}
		>
			{({ values, submitForm, dirty }) => {
				
				(userPermissions?.includes("Categories.Edit")) 
					&& useStopNavigateDirtyForm(dirty);

				const allCategories: number[] = React.useMemo(() => {
					return values._categories?.concat(
						Object.values(values?._categoryGroups).flat()
					);
				}, [values]);

				return (
					<ViewContainer>
						<Div style={{ position: 'relative', width: '95%' }}>
							<Breadcrumbs
								style={{ margin: '20px 0px' }}
								items={[
									{
										label: 'Menu',
										onClick: () => navigate(ProductRoutes.Menu),
									},
									{
										label: pos?.name || 'Menu Preview',
									},
								]}
							/>
							<RequirePermissions permissions={['PosConfig.View']}>
								<Button
									style={{ position: 'absolute', right: '1vw', top: 0 }}
									type="button"
									onClick={() => navigate(`/configuration/pos/${values.posId}`)}
								>
									Configure POS
								</Button>
							</RequirePermissions>
						</Div>
						<Div style={{ margin: '20px 0px' }}>
							<SelectBrand isDisabled />
						</Div>

						{!allCategories?.length ? (
							<Div style={{ display: 'grid', placeItems: 'center' }}>
								<EmptyState resource="categories" />
								<Div style={{ margin: '20px 0px' }}>
									<RequirePermissions permissions={['Categories.Edit']}>
										<AddCategories />
									</RequirePermissions>
								</Div>
							</Div>
						) : (
							<>
								<Div
									css={(theme) => ({
										width: '95%',
										display: 'grid',
										gridTemplateColumns: '30% 70%',
										height: '630px',
										border: `1px solid ${theme.colors.grey10}`,
										borderRadius: '4px',
									})}
								>
									{/* Left */}
									<Div
										css={(theme) => ({
											width: '100%',
											height: '630px',
											borderRight: `1px solid ${theme.colors.grey10}`,
										})}
									>
										<Stack
											align="center"
											width="100%"
											justify="space-between"
											css={(theme) => ({
												padding: '16px 20px',
												borderBottom: `1px solid ${theme.colors.grey10}`,
											})}
										>
											<Text weight={600} style={{ letterSpacing: '0.05em' }}>
												CATEGORIES
											</Text>
											<RequirePermissions permissions={['Categories.Edit']}>
												<AddCategories />
											</RequirePermissions>
										</Stack>
										<Div
											css={{
												height: 'calc(100% - 70.5px)',
												overflow: 'scroll',
											}}
										>
											<MenuPreviewCategories
												selectedCategory={selectedCategory}
												setSelectedCategory={setSelectedCategory}
												userPermissions={userPermissions}
											/>
											<MenuPreviewCategoryGroups />
										</Div>
									</Div>
									{/* Right */}
									<Div css={{ height: '630px' }}>
										<Stack
											align="center"
											width="100%"
											justify="space-between"
											css={{
												padding: '16px 20px',
											}}
										>
											<Text weight={600} style={{ letterSpacing: '0.05em' }}>
												PRODUCTS
											</Text>
											{selectedCategory && (
												<RequirePermissions permissions={['Categories.View']}>
													<Button
														compact
														variant="light"
														onClick={() => goToEditCategory(selectedCategory)}
													>
														Go to category
													</Button>
												</RequirePermissions>
											)}
										</Stack>
										<Div
											css={{
												height: 'calc(100% - 70.5px)',
												overflow: 'scroll',
											}}
										>
											<MenuPreviewProducts />
										</Div>
									</Div>
								</Div>
							</>
						)}
						<Stack align="center" style={{ margin: '20px 0px' }}>
							<RequirePermissions permissions={['Categories.Edit']}>
								<Button onClick={() => submitForm()} disabled={isLoading}>
									Save changes
								</Button>
							</RequirePermissions>
							<CancelButton onClick={() => navigate(ProductRoutes.Menu)}>
								Cancel
							</CancelButton>
						</Stack>
					</ViewContainer>
				);
			}}
		</Formik>
	);
};

export default MenuPreview;

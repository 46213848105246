import React from 'react';
import Error403 from 'views/Error403';
import Error404 from 'views/Error404';
import Error500 from 'views/Error500';

export const errorRoutes = [
	{
		name: 'Error 500',
		path: '/500',
		element: <Error500 />,
	},
	{
		name: 'Error 403',
		path: '/403',
		element: <Error403 />,
	},
	{
		name: 'Error 404',
		path: '/404',
		element: <Error404 />,
	},
];

import { Select, SelectProps } from '@mantine/core';
import React from 'react';
import { useGetOutlets } from '../hooks/outlets';

type SelectOutletProps = {
	brandId: number;
} & Omit<SelectProps, 'data'>;

export const SelectOutlet = ({ brandId, ...rest }: SelectOutletProps) => {
	const { data } = useGetOutlets(brandId);

	const _data = React.useMemo(() => {
		if (data?.outlets?.length) {
			return [
				{ label: 'All Outlets', value: null },
				...data.outlets.map((e: any) => ({
					label: e.name,
					value: e?.id?.toString(),
				})),
			];
		} else {
			return [];
		}
	}, [data]);

	const thereAreNoOutlets = !_data.length;

	return (
		<>
			<Select
				miw="350px"
				disabled={thereAreNoOutlets}
				data={_data}
				defaultValue={null}
				{...rest}
			/>
		</>
	);
};

export const useSelectOutlet = (brandId: number) => {
	const { data } = useGetOutlets(brandId);

	const outlets = React.useMemo(() => {
		if (data?.outlets?.length) {
			return [
				{ label: 'All Outlets', value: null },
				...data.outlets.map((e: any) => ({
					label: e.name,
					value: e.id,
				})),
			];
		} else {
			return [];
		}
	}, [data]);

	const hasOutlets = !!outlets?.length;

	return { outlets, data, hasOutlets };
};

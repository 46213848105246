import { Button, Group, Title } from '@mantine/core';
import { Text } from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { NumberField, SelectField, TextField } from '../../../../components';
import { useGetOutlets } from '../../../../hooks/outlets';
import { useAppState } from '../../../../providers/AppStateProvider';
import { modals } from '@mantine/modals';
import {
	ResetDeviceCodeWarning,
	ResetOfflineDeviceCodeWarning,
} from '../Modals';
import { useUnpairPos } from '../../../../hooks/api/pos/useUnpairPos';
import { useGetOnlinePos } from 'hooks/api/pos/useGetOnlinePos';

const BasisSettings = ({ showDeviceCode }: { showDeviceCode: boolean }) => {
	const { setFieldValue, values, errors } = useFormikContext<any>();
	const { selectedBrand } = useAppState();
	const { data: response_outlets } = useGetOutlets(selectedBrand ?? 0);

	const { data: onlinePos } = useGetOnlinePos();

	const isPosOnline = React.useMemo(() => {
		return onlinePos?.includes(values?.id);
	}, [onlinePos]);

	const { mutate: unpair } = useUnpairPos();
	return (
		<>
			<Title order={2}>POS Basic Settings</Title>
			<TextField
				w="690px"
				label="Point of Sale Name"
				required
				maxLength={80}
				value={values?.name}
				error={errors.name as string}
				onChange={(e) => setFieldValue('name', e.currentTarget.value)}
			/>
			<TextField
				w="690px"
				label="Grab Store ID"
				maxLength={60}
				value={values?.grab_store_id}
				error={errors.grab_store_id as string}
				onChange={(e) => setFieldValue('grab_store_id', e.currentTarget.value)}
			/>
			<SelectField
				label="Outlet"
				labelWidth={240}
				width={690}
				name="outlet"
				value={String(values?.outlet_id)}
				placeholder="Select an outlet here"
				data={
					response_outlets?.outlets?.map((e: any) => ({
						value: String(e?.id),
						label: e?.name,
					})) || []
				}
				onChange={(value) => {
					setFieldValue('outlet_id', Number(value));
				}}
				error={errors.outlet_id as string}
			/>
			<Group w="690px" position="apart">
				{!showDeviceCode ? null : (
					<>
						<TextField
							value={values?.device_code?.match(/.{1,4}/g)?.join('   ')}
							variant="unstyled"
							readOnly
							required
							label="Device Code"
						/>
						<Group spacing="lg">
							<Text>{values?.is_paired ? 'Paired' : 'Unpaired'}</Text>
							<Button
								variant="subtle"
								onClick={() => {
									// if the pos is unpaired, don't need to show warning
									if (!values?.is_paired) {
										unpair({ posId: values?.id });
									} else {
										modals.openConfirmModal({
											labels: {
												cancel: 'Cancel',
												confirm: 'Unpair',
											},
											size: 'xl',
											onConfirm: () => {
												unpair({ posId: values?.id });
											},
											children: isPosOnline ? (
												<ResetDeviceCodeWarning
													close={() => {
														return;
													}}
													data={{
														id: values.id,
														outlet_name: values.outlet_name,
														pos_name: values.name,
													}}
													setFieldValue={setFieldValue}
												/>
											) : (
												<ResetOfflineDeviceCodeWarning
													close={() => {
														return;
													}}
													data={{
														id: values.id,
														outlet_name: values.outlet_name,
														pos_name: values.name,
													}}
													setFieldValue={setFieldValue}
												/>
											),
										});
									}
								}}
							>
								Reset
							</Button>
						</Group>
					</>
				)}
			</Group>
			<NumberField
				w="690px"
				label="Service Charge (%)"
				value={values?.service_charge}
				onChange={(e) => {
					setFieldValue('service_charge', e.currentTarget?.valueAsNumber);
				}}
				hint="If not applicable, set to 0"
			/>
			{/* Hide this for FS requested by Product Team 2023 */}
			{/* <SwitchField
				label="Is Service Charge included in the selling price?"
				onLabel="Yes"
				offLabel="No"
				size="lg"
				checked={values?.is_service_charge_in_item_price}
				onChange={(e) =>
					setFieldValue(
						'is_service_charge_in_item_price',
						e.currentTarget.checked
					)
				}
			/> */}
		</>
	);
};

export default BasisSettings;

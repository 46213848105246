import styled from '@emotion/styled';
import { Stack } from '../Stack';
import { ListGroupProps } from './ListGroup';

export const StyledListGroup = styled(Stack)<ListGroupProps>`
	display: inline-block;
	gap: ${({ gap }) => (gap ? `${gap}px` : '0px')};

	li:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
	}
`;

import { Box, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import Foodpanda from './logos/Foodpanda';
import Grab from './logos/Grab';
import MasterCard from './logos/MasterCard';
import Visa from './logos/Visa';

export const paymentMethodContent = {
	cash: <>Cash</>,
	creditcard: (
		<Stack sx={{ height: '10px' }} align="center">
			<MasterCard />
			<Visa />
		</Stack>
	),
	grab: <Grab />,
	foodpanda: <Foodpanda />,
};

export type PaymentMethodProps = {
	src?: string;
	type: React.ReactNode;
};

export const PaymentMethod = React.forwardRef<
	HTMLDivElement,
	PaymentMethodProps
>(({ src, type, ...rest }, ref) => {
	return (
		<Box
			sx={(theme) => ({
				border: `0.5px solid ${theme.colors.gray[3]}`,
				overflow: 'hidden',
				borderRadius: '8px',
				width: '50px',
				height: '26px',
				display: 'grid',
				placeContent: 'center',
			})}
		>
			{!src ? (
				<Text size="xs">{type}</Text>
			) : (
				<Image src={src} alt={src} width={50} height={26} fit="contain" />
			)}
		</Box>
	);
});

PaymentMethod.displayName = 'PaymentMethod';

import React from 'react';
import { useParams } from 'react-router-dom';
import Icon from '../../assets/icons';
import { useNavigate } from '../../lib/navigate';
import { ActionIcon } from '@mantine/core';
import { DivProps } from '../Div';
import { Text, TextProps } from '@mantine/core';
import { StyledPaginationSimple } from './PaginationSimple.styled';

export type PaginationSimpleProps = DivProps & {
	links: string[];
	activeIndex: number;
};

export const PaginationSimple = React.forwardRef<
	HTMLDivElement,
	PaginationSimpleProps
>(({ links, activeIndex, ...rest }, ref) => {
	const [active, setActive] = React.useState<number>(activeIndex);
	const navigate = useNavigate();

	React.useEffect(() => {
		setActive(activeIndex);
	}, [activeIndex]);

	return (
		<StyledPaginationSimple {...rest} links={links} ref={ref}>
			<ActionIcon onClick={() => navigate(links[active - 1])}>
				<Icon name="chevronleft" />
			</ActionIcon>
			<Text>
				{active + 1} / {links.length}
			</Text>
			<ActionIcon onClick={() => navigate(links[active + 1])}>
				<Icon name="chevronright" />
			</ActionIcon>
		</StyledPaginationSimple>
	);
});

PaginationSimple.displayName = 'PaginationSimple';

import React from 'react';
import { Div, Divider, Stack, Text } from 'components';
import { currency } from 'lib';

type Props = {
	receipt: Record<string, any>;
};

const OrderReceipt = ({ receipt }: Props) => {
	return (
		<Div
			style={{
				background: '#ffffff',
				width: '355px',
				borderRadius: '4px',
				padding: '25px 15px',
			}}
		>
			<Div>
				<Text weight={600} style={{ textAlign: 'center', width: '100%' }}>
					{receipt?.brand_name}
				</Text>
				<Text style={{ textAlign: 'center', width: '100%' }}>
					{receipt?.outlet_name}
				</Text>
				<Text style={{ textAlign: 'center', width: '100%' }}>
					{receipt?.outlet_address_line1}
				</Text>
				<Text style={{ textAlign: 'center', width: '100%' }}>
					{receipt?.outlet_address_line2}
				</Text>
				<Text style={{ textAlign: 'center', width: '100%' }}>
					TEL: {receipt?.outlet_phone_number}
				</Text>
				<Text style={{ textAlign: 'center', width: '100%' }}>
					{(receipt?.tax_reg_number &&
						`GST Reg No.: ${receipt?.tax_reg_number}`) ||
						`UEN: ${receipt?.uen}`}
				</Text>
			</Div>
			<Divider style={{ borderStyle: 'dashed', margin: '10px 0' }} size="2px" />

			<Div style={{ padding: '0 8px' }}>
				<Text style={{ textAlign: 'left', width: '100%' }}>
					BILL NO: {receipt?.order_id}
				</Text>
				<Text style={{ textAlign: 'left', width: '100%' }}>
					SALES TIME: {receipt?.order_datetime}
				</Text>

				<Text style={{ textAlign: 'left', width: '100%' }}>
					POS: {'000' + receipt?.pos_id}
				</Text>
				<Text style={{ textAlign: 'left', width: '100%' }}>
					CASHIER: {receipt?.cashier}
				</Text>
				{/* check if product_name contains Takeaway */}

				<Text weight={800}>
					{/* eslint-disable no-mixed-spaces-and-tabs  */}
					{!receipt
						? ''
						: receipt?.items.some(
								(e: any) =>
									!!e.product_name && e.product_name.includes('Takeaway')
						  )
						? 'TAKEAWAY'
						: 'DINE IN'}
				</Text>
			</Div>

			<Divider style={{ borderStyle: 'dashed', margin: '10px 0' }} size="2px" />
			<Div>
				{receipt?.items
					.filter((e: any) => !!e.product_name)
					?.map((e: any, idx: number) => (
						<>
							<Stack
								key={idx}
								width="100%"
								justify="space-evenly"
								align="start"
							>
								<Text style={{ marginTop: '4px' }} size={13}>
									{e.quantity}
								</Text>
								<Text style={{ width: '200px' }} size={20}>
									{e.product_name} @
									{currency(e.price, { fromCents: true }).format()}
								</Text>

								<Text style={{ marginTop: '4px' }}>
									{currency(e.price, { fromCents: true })
										.multiply(e?.quantity)
										.format()}
								</Text>
							</Stack>
							<Div style={{ paddingLeft: '40px' }}>
								{e.options?.map((p: any, idx: number) => (
									<Div key={idx}>
										<Text>- {p.option_name?.toLowerCase()}</Text>
									</Div>
								))}
								{e.discount && (
									<Div key={idx}>
										<Text>
											- {e.discount?.discount_name} (-
											{currency(e.discount?.total_discount_amount, {
												fromCents: true,
											}).format()}
											)
										</Text>
									</Div>
								)}
							</Div>
						</>
					))}
			</Div>
			<Divider style={{ borderStyle: 'dashed', margin: '10px 0' }} size="2px" />
			<Div style={{ padding: '0 15px' }}>
				<Stack justify="space-between" width="100%">
					<Text>SUBTOTAL</Text>
					<Text>
						{currency(receipt?.amount_total, { fromCents: true }).format()}
					</Text>
				</Stack>
				{receipt?.amount_service_charge > 0 && (
					<Stack justify="space-between" width="100%">
						<Text>
							{receipt?.service_charge_percentage}% SVR CHRG{' '}
							{receipt?.is_service_charge_in_item_price && '(Incl.)'}
						</Text>
						<Text>
							{currency(receipt?.amount_service_charge, {
								fromCents: true,
							}).format()}
						</Text>
					</Stack>
				)}
				{receipt?.amount_tax > 0 && (
					<Stack justify="space-between" width="100%">
						<Text>
							{receipt?.sales_tax}% GST
							{receipt?.is_sales_tax_in_item_price && '(Incl.)'}
						</Text>
						<Text>
							{currency(receipt?.amount_tax, { fromCents: true }).format()}
						</Text>
					</Stack>
				)}
				{receipt?.items
					.filter((e: any) => !!e.discount_name)
					?.map((e: any, idx: number) => (
						<Stack key={idx} justify="space-between" width="100%">
							<Text style={{ width: '200px' }}>{e.discount_name} </Text>
							<Text>
								-
								{currency(e.total_discount_amount, {
									fromCents: true,
								}).format()}
							</Text>
						</Stack>
					))}
				<Divider
					style={{ borderStyle: 'dashed', margin: '10px 0' }}
					size="2px"
				/>

				<Stack justify="space-between" width="100%">
					<Text size={22}>TOTAL </Text>
					<Text size={22}>
						{currency(receipt?.amount_due, { fromCents: true }).format()}
					</Text>
				</Stack>
				<Stack justify="space-between" width="100%">
					<Text>
						{receipt?.payment_method_name}{' '}
						{receipt?.is_cash_payment && '(CASH)'}
					</Text>
					<Text>
						{currency(receipt?.amount_paid, { fromCents: true }).format()}
					</Text>
				</Stack>
				{receipt?.amount_change > 0 && (
					<Stack justify="space-between" width="100%">
						<Text>CHANGE</Text>
						<Text>
							{currency(receipt?.amount_change, { fromCents: true }).format()}
						</Text>
					</Stack>
				)}
			</Div>

			<Div style={{ margin: '40px' }}>
				<Text style={{ textAlign: 'center', width: '100%' }} size={12}>
					THANK YOU FOR DINING WITH US!
				</Text>
				<Text
					style={{ textAlign: 'center', width: '100%' }}
					size={12}
					weight={600}
				>
					{'<< CUSTOMER COPY >>'}
				</Text>
			</Div>
		</Div>
	);
};

export default OrderReceipt;

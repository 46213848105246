import {z} from 'zod'
import { BaseSchema } from './base.schema'
import { PosEnum } from '../constants/pos.enum';

export const PosSchema = BaseSchema.extend({
    name: z.string({required_error: 'This field is required.'}).min(1, 'This field is required.').max(80, 'Only a maximum of 80 characters is allowed'),
    outlet_id: z.number(),
    service_charge: z.number({required_error: 'This field is required'}).min(0).max(99.99).or(z.string().min(1, 'This field is required.')),
	is_service_charge_in_item_price: z.boolean(),
    has_quick_sign_in: z.boolean(),
    is_display_cash_denominations: z.boolean(),
    is_display_product_price: z.boolean(),
    cash_exact_type: z.enum([
        PosEnum.CASH_EXACT_TYPE.SHOW_EXACT_BUTTON,
        PosEnum.CASH_EXACT_TYPE.HIDE_EXACT_BUTTON,
        PosEnum.CASH_EXACT_TYPE.AUTO_POPULATE_TENDER,
    ]),
    has_manager_pin_on_view_report: z.boolean(),
    is_24h: z.boolean(),
    op_opening_time: z.string({required_error: 'This field is required.'}),
    op_closing_time: z.string().nullable(),
    closing_grace_period_hour: z.number(),
    number_of_shift: z.number(),
    is_change_shift_mandatory: z.boolean(),
    is_equal_shift_interval: z.boolean(),
    change_shift_intervals: z.array(z.string()),
    payment_methods: z.array(z.object({
        paymentmethod_id: z.number(),
        pos_payment_code: z.string().max(30).nullable(),
        sort_order: z.number(),
        is_active: z.boolean(),
        is_read_only: z.boolean()
    })),
    default_opening_cash: z.number().int().min(0).max(99999),
    discounts: z.array(z.number()),
    is_preview_receipt: z.boolean(),
    is_paired: z.boolean(),
    num_receipt_per_transaction: z.number(),
    is_display_queue: z.boolean(),
    is_allow_zero_cash_collection: z.boolean(),
    authorised_difference: z.number().min(0).max(99999),
    has_report_preview_before_confirm: z.boolean(),
    advertisement_duration_seconds: z.number(),
    updated_by: z.string()
})

export type PosSchemaType = z.infer<typeof PosSchema>

export const CreatePosSchema = PosSchema.pick({
    name: true,
    outlet_id: true,
    service_charge: true,
    is_service_charge_in_item_price: true,
    has_quick_sign_in: true,
    is_display_cash_denominations: true,
    is_display_product_price: true,
    cash_exact_type: true,
    has_manager_pin_on_view_report: true,
    is_24h: true,
    op_opening_time: true,
    op_closing_time: true,
    closing_grace_period_hour: true,
    number_of_shift: true,
    is_change_shift_mandatory: true,
    is_equal_shift_interval: true,
    change_shift_intervals: true,
    payment_methods: true,
    default_opening_cash: true,
    discounts: true,
    is_preview_receipt: true,
    num_receipt_per_transaction: true,
    is_display_queue: true,
    is_allow_zero_cash_collection: true,
    authorised_difference: true,
    has_report_preview_before_confirm: true,
    advertisement_duration_seconds: true,
    updated_by: true
})

export type CreatePosSchemaType = z.infer<typeof CreatePosSchema>

export const UpdatePosSchema = CreatePosSchema.extend({
    id: z.number(),
    payment_methods: z.array(z.object({
        id: z.number(),
        pos_payment_code: z.string().max(30).nullable(),
        sort_order: z.number(),
        is_active: z.boolean(),
        is_read_only: z.boolean()
    })),
})

export type UpdatePosSchemaType = z.infer<typeof UpdatePosSchema>
import {
	ActionIcon,
	Box,
	Button,
	Checkbox,
	Group,
	NumberInput,
	Select,
	SelectItem,
	Table,
	Text,
	Title,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useFormikContext } from 'formik';
import React from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { configApi } from '../../../apis';
import Icon from '../../../assets/icons';

const LocalisedPricing = () => {
	const [searchParams] = useSearchParams();

	const categoryId = searchParams.get('categoryId');

	const { values, errors, setFieldValue } = useFormikContext<any>();

	const [listValues, listHandler] = useListState<any>([]);
	const [options, optionsHandler] = useListState<SelectItem>([]);

	useQuery(
		`pos/categories?category_id=${categoryId}`,
		() =>
			configApi
				.get(`/pos/categories?category_id=${categoryId}`)
				.then(({ data }) => data),
		{
			onSuccess: (data) => {
				optionsHandler.setState(
					data?.pos?.map((e: { id: number; pos_name: string }) => ({
						value: e?.id?.toString(),
						label: e?.pos_name,
					}))
				);
			},
		}
	);

	const selectedPos = listValues.map((e) => e.pos_id);

	React.useEffect(() => {
		if (listValues) {
			setFieldValue(
				'localized_price',
				listValues.map((e) => ({
					...e,
					pos_id: +e.pos_id,
				}))
			);
		}
	}, [listValues]);

	return (
		<Box style={{ width: '100%' }}>
			<Title order={3}>Localised Pricing</Title>
			{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
			<Table w="min-content" my="lg">
				<thead>
					<tr>
						<th style={{ width: '50px' }}></th>
						<th>
							<Text w={350} align="center">
								Point of Sale
							</Text>
						</th>
						<th>
							<Text w={25} align="center">
								Hidden
							</Text>
						</th>
						<th>
							<Text w={125} align="center">
								Price
							</Text>
						</th>
					</tr>
				</thead>
				<tbody>
					{listValues?.map((e, i) => (
						<tr key={i}>
							<td>
								<ActionIcon
									onClick={() => {
										listHandler.remove(i);
									}}
								>
									<Icon name="delete" />
								</ActionIcon>
							</td>
							<td>
								<Select
									data={options.map((e) => ({
										...e,
										disabled: selectedPos.includes(e.value),
									}))}
									value={e.pos_id}
									onChange={(value) => {
										if (value) {
											listHandler.setItemProp(i, 'pos_id', value);
										}
									}}
								/>
							</td>
							<td>
								<Group position="center" w="100%">
									<Checkbox
										checked={e.is_hidden}
										onChange={(e) => {
											listHandler.setItemProp(
												i,
												'is_hidden',
												e.currentTarget.checked
											);
										}}
									/>
								</Group>
							</td>
							<td>
								<NumberInput
									precision={2}
									disabled={values?.is_open_price ?? false}
									w={125}
									hideControls
									min={0}
									max={9999.99}
									value={e.price}
									onChange={(value) => {
										if (value === "") {
											value = 0.00;
										}
										listHandler.setItemProp(i, 'price', value);
									}}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<Button
				my="lg"
				leftIcon={<Icon name="add" />}
				variant="light"
				disabled={!categoryId || listValues.length === options.length}
				onClick={() => {
					listHandler.append({
						pos_id: options
							?.filter((e) => !selectedPos.includes(e.value))
							?.at(0)?.value,
						price: values?.price,
						is_hidden: false,
					});

					// optionsHandler.remove(0);
				}}
			>
				Add New
			</Button>
			{/* <pre>
				{JSON.stringify(
					{
						filtered: options?.filter((e) => !selectedPos.includes(e.value)),
						selectedPos,
						listValues,
					},
					null,
					2
				)}
			</pre> */}
		</Box>
	);
};

export default LocalisedPricing;

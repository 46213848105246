import { useMutation } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function usePostResetPassword() {
	const { getAccessToken } = useAuth();
	return useMutation(
		async (payload: {
			email: string;
			reset_password_token: string;
			password: string;
		}) =>
			authApi
				.post(`/password/reset`, payload, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

export const ConfigurationRoutes = {
	// Malls
	Malls: '/configuration/malls',
	ViewMalls: '/configuration/malls/:id',
	// Companies
	Companies: '/configuration/companies',
	ArchivedCompanies: '/configuration/companies/archived',
	ViewCompany: '/configuration/companies/:id',
	CreateCompany: '/configuration/companies/create',
	// Brands
	Brands: '/configuration/brands',
	ArchivedBrands: '/configuration/brands/archived',
	ViewBrand: '/configuration/brands/:id',
	CreateBrand: '/configuration/brands/create',
	// Outlets
	Outlets: '/configuration/outlets',
	ArchivedOutlets: '/configuration/outlets/archived',
	ViewOutlet: '/configuration/outlets/:id',
	CreateOutlet: '/configuration/outlets/create',
	// POS
	Pos: '/configuration/pos',
	CreatePos: '/configuration/pos/create',
	ViewPos: '/configuration/pos/:id',
	// Closing
	ViewClosing: '/configuration/closings/:closingUid',
	// Payment/Discounts
	Payment: '/configuration/payment',
	Discount: '/configuration/payment?tab=discount',
	CreateOrderDiscount: '/configuration/payment/discount/create',
	CreateProductDiscount: '/configuration/payment/discount/product/create',
	CreatePayment: '/configuration/payment/create',
	ViewOrderDiscount: '/configuration/payment/discount/:id',
	ViewProductDiscount: '/configuration/payment/discount/product/:id',
	ViewPayment: '/configuration/payment/:id',
	Order: '/orders/:orderId',
};

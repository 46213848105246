import styled from '@emotion/styled'
import { Div } from '../Div';
import { PaginationSimpleProps } from './PaginationSimple';

export const StyledPaginationSimple = styled(Div)<
	Omit<PaginationSimpleProps, 'activeIndex'>
>`
	display: flex;
	gap: 20px;
	align-items: center;
`;

import { useMutation } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useUpdateDiscount() {
	const { getAccessToken } = useAuth();
	return useMutation(async (values: any) =>
		configApi
			.put(`/discounts/${values.id}`, values, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';
import { TCategoryGroupSchema } from '../../../schemas/categorygroup.schema';

export async function getCategoryGroups(): Promise<{
	categoryGroups: TCategoryGroupSchema[];
	total_records: number;
}> {
	const { data } = await configApi.get(`categorygroups`);
	return data;
}

export function useGetCategoryGroups({
	brandId,
}: {
	brandId: string | number;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['category-groups', brandId],
		async (): Promise<{
			categoryGroups: TCategoryGroupSchema[];
			num_of_pages: number;
		}> =>
			configApi
				.get(`categorygroups`, {
					params: { brandId: brandId },
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

type TCategoryGroupList = {
	brand_id: string | number;
	requested_page: string | number;
	dropdown: string;
	categoryGroups: TCategoryGroupSchema[];
	num_of_pages: number;
	total_records: number;
};

export function useGetCategoryGroupsList({
	brand_id,
	requested_page,
	dropdown,
}: {
	brand_id: string | number;
	requested_page: string | number;
	dropdown: string;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['category-groups-list', brand_id, requested_page],
		async (): Promise<TCategoryGroupList> =>
			configApi
				.get(`categorygroups`, {
					params: { brand_id, requested_page, dropdown },
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data)
	);
}

import { z } from 'zod';

export const accountSchema = z.object({
	name: z
		.string({
			required_error: 'Name is required',
		})
		.max(80, '80 chars maximum'),

	email: z
		.string({
			required_error: 'Email is required',
		})
		.email('Not a valid email'),
	username: z
		.string({
			required_error: 'Username is required',
		})
		.max(80),
	terminal_pin: z
		.string({
			required_error: 'Terminal pin is required',
		})
		.regex(/^\d+$/, '0-9 only')
		.length(8, 'Must be 8 digits'),
});

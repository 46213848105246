/* eslint-disable no-mixed-spaces-and-tabs */
import { usePagination } from '@mantine/hooks';
import { ActionIcon, Stack } from 'components';
import React from 'react';
import Icon from '../../assets/icons';
import { DivProps } from '../Div';
import { StyledPagination } from './Pagination.styled';
import { PaginationButton } from './PaginationButton';

export type PaginationProps = Omit<DivProps, 'onChange'> & {
	hasPreviousPage?: boolean;
	hasNextPage?: boolean;
	nextPageHandler?: (activePage: number) => void;
	prevPageHandler?: (activePage: number) => void;
	onChange?: (activePage: number) => void;
	initialPage?: number;
	totalPages: number;
	totalRecords?: number;
	pageSize?: number;
};

const defaultProps: Partial<PaginationProps> = {
	initialPage: 1,
};

export const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
	(
		{
			hasPreviousPage,
			hasNextPage,
			nextPageHandler,
			prevPageHandler,
			initialPage,
			totalPages,
			totalRecords = 0,
			pageSize = 10,
			onChange,
			...rest
		},
		ref
	) => {
		const [activePage, setActivePage] = React.useState<number>(1);
		const { range } = usePagination({
			total: totalPages,
			page: activePage,
			siblings: 1,
			boundaries: 1,
			initialPage,
		});
		const currentPage = activePage * pageSize;

		React.useEffect(() => {
			if (initialPage) {
				setActivePage(initialPage);
			}
		}, []);

		const _nextPageHandler = () => {
			setActivePage((p) => {
				const newPage = p + 1;
				if (newPage <= totalPages) {
					nextPageHandler && nextPageHandler(newPage);
					return newPage;
				}
				return p;
			});
		};

		const _previousPageHandler = () => {
			setActivePage((p) => {
				const newPage = p - 1;
				if (newPage >= 1) {
					prevPageHandler && prevPageHandler(newPage);
					return newPage;
				}
				return p;
			});
		};

		React.useEffect(() => {
			if (onChange) {
				onChange(activePage);
			}
		}, [activePage]);

		return (
			<>
				<Stack width="100%" justify="flex-start" css={{ padding: '16px 0' }}>
					{totalRecords !== 0 &&
						`Showing ${
							currentPage < totalRecords ? currentPage : totalRecords
						} out of ${totalRecords}`}
				</Stack>

				<StyledPagination {...rest} ref={ref}>
					<ActionIcon
						disabled={hasPreviousPage || activePage === 1}
						onClick={_previousPageHandler}
					>
						<Icon name="chevronleft" />
					</ActionIcon>
					{range.map((e, i) => {
						return e === 'dots' ? (
							<div
								style={{ display: 'grid', placeItems: 'center' }}
								key={`dots-${i}`}
							>
								<Icon name="morehort" />
							</div>
						) : (
							<ActionIcon
								key={e}
								onClick={() => {
									setActivePage(e);
								}}
							>
								{e}
							</ActionIcon>
						);
					})}
					<ActionIcon
						disabled={hasNextPage || activePage === totalPages}
						onClick={_nextPageHandler}
					>
						<Icon name="chevronright" />
					</ActionIcon>
				</StyledPagination>
			</>
		);
	}
);

Pagination.displayName = 'Pagination';
Pagination.defaultProps = defaultProps;

import React from 'react';
import { Global } from '@emotion/react';
import { theme } from './theme';

export const GlobalStyles = () => (
	<Global
		styles={{
			'*': {
				boxSizing: 'border-box',
			},
			body: {
				fontFamily: theme.font.sansSerif,
			},
			'p, li, ul': {
				margin: 0,
				padding: 0,
			},
			a: {
				textDecoration: 'none',
			},
			fieldset:{
				border:0
			},
		}}
	/>
);

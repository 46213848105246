import React from 'react';
import {
	Breadcrumbs,
	Button,
	CancelButton,
	Div,
	FormTextInput,
	SelectBrand,
	Stack,
	ViewContainer,
} from 'components';
import ServiceHoursList from './ServiceHours';

import { Box, Divider, Grid, Group, JsonInput } from '@mantine/core';
import { Field, Formik } from 'formik';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from '../../../lib/navigate';
import { ProductRoutes } from 'router/constants/Products.routes';
import dayjs from 'dayjs';
import { SellingTimesSchemaCreate } from 'schemas/sellingtimes';
import { useNotification } from 'providers/NotificationProvider';
import { useCreateNewSellingTime } from 'hooks/api/sellingtimes/useCreateSellingTime';
import { isSellingTimeValid } from './helpers/isSellingTimeValid';

export const CreateSellingTime = () => {
	const toast = useNotification();
	const navigate = useNavigate();
	const { selectedBrand, setDirty } = useAppState();
	const { mutateAsync: createSellingTime } = useCreateNewSellingTime();

	const initialValues = {
		name: '',
		brand_id: selectedBrand,
		startTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		endTime: dayjs(new Date()).add(3, 'years').format('YYYY-MM-DD HH:mm:ss'),
		serviceHours: {
			mon: { openPeriodType: 'CloseAllDay' },
			tue: { openPeriodType: 'CloseAllDay' },
			wed: { openPeriodType: 'CloseAllDay' },
			thu: { openPeriodType: 'CloseAllDay' },
			fri: { openPeriodType: 'CloseAllDay' },
			sat: { openPeriodType: 'CloseAllDay' },
			sun: { openPeriodType: 'CloseAllDay' },
		},
	};

	return (
		<ViewContainer>
			<Breadcrumbs
				items={[
					{
						label: 'Selling Times',
						onClick: () => navigate(ProductRoutes.SellingTimes),
					},
					{
						label: 'Create Selling Time',
						onClick: () => navigate('#'),
					},
				]}
			/>

			<Formik
				initialValues={initialValues}
				validate={(values) => {
					try {
						SellingTimesSchemaCreate.parse(values);
					} catch (error: any) {
						return error.formErrors.fieldErrors;
					}
				}}
				onSubmit={async (values) => {
					const { serviceHours, ...rest } = values;
					// // Check if any day has either startTime or endTime only
					if (!isSellingTimeValid(serviceHours)) {
						toast.error(
							'Days with Open Periods must have both start and end time.'
						);
					} else {
						await createSellingTime(values)
							.then(() => {
								toast.success('Selling time successfully updated');
								navigate(ProductRoutes.SellingTimes, {}, true);
							})
							.catch(() => {
								toast.error('An error occured');
							});
					}
				}}
			>
				{({ values, errors, touched, dirty, setFieldValue, submitForm }) => {
					React.useEffect(() => {
						if (dirty) {
							setDirty(true);
						}

						return () => setDirty(false);
					}, [dirty]);

					return (
						<Div style={{ marginTop: '20px' }}>
							<SelectBrand />
							{/* <JsonInput
								value={JSON.stringify({ values, errors }, null, 2)}
								autosize
								maxRows={20}
							/> */}

							<Divider my={'20px'} />

							<Stack style={{ margin: '20px 0' }}>
								<Field
									width="600px"
									as={FormTextInput}
									name="name"
									label="Selling Time Name"
									isRequired={true}
									maxLength={80}
									error={errors.name && touched.name ? errors.name : null}
								/>
							</Stack>

							<ServiceHoursList
								setFieldValue={setFieldValue}
								serviceHours={values?.serviceHours}
							/>

							<Group
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									margin: '20px 0',
								}}
							>
								<Button onClick={submitForm}>Create</Button>
								<Button
									color="gray.3"
									style={{ color: 'black' }}
									onClick={() => navigate(ProductRoutes.SellingTimes)}
								>
									Cancel
								</Button>
							</Group>
						</Div>
					);
				}}
			</Formik>
		</ViewContainer>
	);
};

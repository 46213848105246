import React from 'react';
import nanoid from '../../../lib/nanoid';
import Discount from './Discount';

type Props = {
	discounts: Array<{id:number, name:string}>;
};

const DiscountsTooltip = ({ discounts }: Props) => {
	return (
		<div>
			{discounts.map((e) => (
				<Discount discountName={e.name} key={nanoid(6)} />
			))}
		</div>
	);
};

export default DiscountsTooltip;

import { useQuery } from 'react-query';
import { configApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetPosSyncBrands() {
	const { getAccessToken } = useAuth();
	return useQuery(['pos-sync'], async () =>
		configApi
			.get(`pos/sync/brands`, {
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			})
			.then(({ data }) => data)
	);
}

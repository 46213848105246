import { Box, Skeleton } from '@mantine/core';
import React from 'react';
import { MultiSelectField, useFormikContext } from '../../../../components';
import { useGetOutlets } from '../../../../hooks/outlets';

type Props = {
	brandId: number;
	brandName: string;
	isAdminAssigned: boolean;
};

const OutletDropdown: React.FC<Props> = ({
	brandId,
	brandName,
	isAdminAssigned,
}) => {
	const { data: outlets } = useGetOutlets(brandId);

	const { setFieldValue, values } = useFormikContext<any>();

	React.useEffect(() => {
		if (!outlets) return;
		if (isAdminAssigned) {
			setFieldValue(
				`brand_outlet.${brandId}`,
				outlets?.outlets?.map((e: any) => String(e.id))
			);
		}
	}, [outlets, isAdminAssigned]);

	return (
		<Box w="100%">
			<MultiSelectField
				w="100%"
				label={brandName}
				data={
					outlets?.outlets?.map((e: any) => ({
						value: e.id.toString(),
						label: e.name,
					})) || []
				}
				placeholder={
					!outlets?.outlets?.length
						? 'No Brands'
						: isAdminAssigned
						? 'Entire brand'
						: 'Please select'
				}
				value={values?.brand_outlet[String(brandId)]}
				disabled={!outlets?.outlets?.length || isAdminAssigned}
				onChange={(selected) => {
					setFieldValue(`brand_outlet.${brandId}`, selected);
				}}
			/>
		</Box>
	);
};

export default OutletDropdown;

import { Box } from '@mantine/core';
import React from 'react';
import {
	FormMultiSelect,
	MultiSelectField,
	useFormikContext,
} from '../../../../components';
import { useGetOutlets } from '../../../../hooks/outlets';

type Props = {
	brandId: number;
	brandName: string;
	isAdminAssigned: boolean;
};

const OutletDropdown: React.FC<Props> = ({
	brandId,
	brandName,
	isAdminAssigned,
}) => {
	const { data: outlets } = useGetOutlets(brandId);

	const { setFieldValue, values } = useFormikContext<any>();

	React.useEffect(() => {
		if (!outlets) return;
		if (isAdminAssigned) {
			setFieldValue(
				`brand_outlet.${brandId}`,
				outlets?.outlets.map((e: any) => String(e.id))
			);
		}
	}, [outlets, isAdminAssigned]);

	const [selectedOutlets, setSelectedOutlets] = React.useState<string[]>([]);

	if (!outlets?.outlets) {
		return null;
	}

	return (
		<Box w="100%">
			<MultiSelectField
				w="100%"
				label={brandName}
				data={outlets.outlets.map((e: any) => ({
					value: e.id.toString(),
					label: e.name,
				}))}
				disabled={!outlets.outlets.length || isAdminAssigned}
				onChange={(selected) => {
					setFieldValue(`brand_outlet.${brandId}`, selected);
				}}
			/>
		</Box>
	);
};

export default OutletDropdown;

import React from 'react';
import {
	Button,
	ButtonProps,
	Modal,
	ModalProps,
	Stack,
} from '../../components';

export type ConfirmModalProps = ModalProps & {
	confirmLabel?: React.ReactNode;
	cancelLabel?: React.ReactNode;
	onConfirm?: () => void;
	onCancel?: () => void;
	confirmLabelProps?: ButtonProps;
	cancelLabelProps?: ButtonProps;
};

export const ConfirmModal = React.forwardRef<HTMLDivElement, ConfirmModalProps>(
	(
		{
			children,
			open,
			onClose,
			confirmLabel,
			confirmLabelProps,
			onConfirm,
			cancelLabel,
			cancelLabelProps,
			onCancel,
			...rest
		},
		ref
	) => {
		return (
			<Modal open={open} onClose={onClose} {...rest} ref={ref}>
				{children}
				<Stack width="100%" align="center" justify="right">
					<Button
						variant="light"
						color="red"
						{...cancelLabelProps}
						onClick={() => {
							onCancel && onCancel();
						}}
					>
						{cancelLabel ?? 'Cancel'}
					</Button>
					<Button
						{...confirmLabelProps}
						onClick={() => {
							onConfirm && onConfirm();
						}}
					>
						{confirmLabel ?? 'Confirm'}
					</Button>
				</Stack>
			</Modal>
		);
	}
);

ConfirmModal.displayName = 'ConfirmModal';

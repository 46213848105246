import React from 'react';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import {
	Button,
	Div,
	FormTextInput,
	ViewContainer,
	Stack,
	CancelButton,
} from 'components';
import { useAppState } from 'providers/AppStateProvider';
import { useNotification } from 'providers/NotificationProvider';
import { useModals } from 'providers/ModalsProvider';
import { Field, Formik } from 'formik';
import { Navigate } from 'react-router-dom';
import { useCreateNewCompany } from 'hooks/companies';
import {
	CompanyCreationSchema,
	CompanyCreationInput,
} from 'schemas/company.schema';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from '../../../lib/navigate';

const CompaniesCreate = () => {
	const toast = useNotification();
	const modal = useModals();
	const { user } = useAuth();
	// if (!user?.permissions.includes('Companies.Create')) {
	// 	toast.error('403 Forbidden');
	// 	return <Navigate replace to="/configuration/companies" />;
	// }

	const navigate = useNavigate();
	const { setCompany, setBCIntegration, setDirty } = useAppState();
	const { mutateAsync: createNewCompany } = useCreateNewCompany();
	const initialValues: CompanyCreationInput = {
		name: '',
		uen: '',
		gst_number: '',
		client_id: '',
		client_secret: '',
		updated_by: user?.name ?? '',
	};

	const breadcrumbsArray = [
		{
			label: 'Companies',
			onClick: () => navigate('/configuration/companies'),
		},
		{
			label: 'Create Company',
			onClick: () => navigate('#'),
		},
	];

	return (
		<>
			<ViewContainer>
				<Breadcrumbs items={breadcrumbsArray} />
				<Formik
					initialValues={initialValues}
					validate={(values) => {
						try {
							CompanyCreationSchema.parse(values);
						} catch (error: any) {
							return error.formErrors.fieldErrors;
						}
					}}
					onSubmit={(values, actions) => {
						createNewCompany(values, {
							onSuccess: (newCompany) => {
								setDirty(false);
								setCompany(+newCompany.id);
								setBCIntegration(false);
								navigate('/configuration/companies', {}, true);
								toast.success('Company has been successfully created');
							},
							onError: (_) => {
								toast.error('An error occured');
							},
						});
					}}
				>
					{({ values, errors, touched, dirty, submitForm }) => {
						React.useEffect(() => {
							if (dirty) {
								setDirty(true);
							}

							return () => setDirty(false);
						}, [dirty]);
						return (
							<Div style={{ marginTop: '20px' }}>
								<Stack direction="column" gap={10}>
									<Field
										width="940px"
										as={FormTextInput}
										name="name"
										label="Company Name"
										isRequired={true}
										hint={'Company is the business entity that owns Brands'}
										maxLength={80}
										error={errors.name && touched.name ? errors.name : null}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="uen"
										label="UEN"
										isRequired={true}
										hint={'Unique Entity Number'}
										maxLength={30}
										error={errors.uen && touched.uen ? errors.uen : null}
									/>
									<Field
										as={FormTextInput}
										width="940px"
										name="gst_number"
										label="GST Number"
										maxLength={30}
										error={errors.gst_number}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="client_id"
										label="Grab Client ID"
										type={'password'}
										maxLength={60}
									/>
									<Field
										width="940px"
										as={FormTextInput}
										name="client_secret"
										label="Grab Client Secret"
										type={'password'}
										maxLength={60}
									/>

									<Stack
										width={'100%'}
										style={{ display: 'flex', justifyContent: 'flex-start' }}
									>
										<Button type="submit" onClick={submitForm}>
											Create
										</Button>
										<CancelButton
											onClick={() => navigate('/configuration/companies')}
										>
											Cancel
										</CancelButton>
									</Stack>
								</Stack>
							</Div>
						);
					}}
				</Formik>
			</ViewContainer>
		</>
	);
};

export default CompaniesCreate;

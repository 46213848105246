import { useQuery } from 'react-query';
import { txReadApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetClosingByUuid(sessionUuid: string | undefined) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['closing', sessionUuid],
		async () =>
			txReadApi
				.get(`/closings/${sessionUuid}`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
			enabled: !!sessionUuid,
		}
	);
}

import React from 'react';
import { useParams } from 'react-router-dom';
import {
	CheckBoxGrid,
	Stack,
	useFormikContext,
	Box,
} from '../../../components';
import { useGetRoleById } from '../../../hooks/api/roles/useGetRoleById';
import { TCreateRole } from '../../../schemas/roles.schema';

const DashboardPanel = () => {
	const { setFieldValue } = useFormikContext<TCreateRole>();
	const { roleId } = useParams();
	const { data: role, isLoading } = useGetRoleById(roleId!);
	return (
		<Stack direction="column" gap={30} align="left">
			{isLoading ? null : (
				<>
					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="Products"
						initialValues={role?.permissions_by_sections?.product}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[
							{ label: 'Menu', value: 'Menu' },
							{ label: 'Categories', value: 'Categories' },
							{ label: 'Category Groups', value: 'CategoryGroups' },
							{ label: 'Option Groups', value: 'OptionGroups' },
							{ label: 'Kitchen Groups', value: 'KitchenGroups' },
						]}
						onChange={(values) => {
							setFieldValue('permissions.product', values);
						}}
						omit={[
							'Menu.Create',
							'Menu.Archive',
							'Menu.Delete',
							'KitchenGroups.Archive',
							'CategoryGroups.Archive',
						]}
						// showSelectAll
					/>
					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="Configuration"
						initialValues={role?.permissions_by_sections?.configuration}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[
							{ label: 'Companies', value: 'Companies' },
							{ label: 'Brands', value: 'Brands' },
							{ label: 'Outlets', value: 'Outlets' },
						]}
						onChange={(values) => {
							setFieldValue('permissions.configuration', values);
						}}
						// showSelectAll
					/>
					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="Point of Sales"
						initialValues={role?.permissions_by_sections?.pointOfSales}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[
							{ label: 'Point of Sales List', value: 'PosList' },
							{
								label: 'Point of Sales Configuration',
								value: 'PosConfig',
							},
							{ label: 'Sync to POS', value: 'PosSync' },
							{ label: 'Orders', value: 'Orders' },
							{ label: 'Closings', value: 'Closings' },
						]}
						onChange={(values) => {
							setFieldValue('permissions.pointOfSales', values);
						}}
						// showSelectAll
						omit={[
							'PosList.Create',
							'PosList.Edit',
							'PosList.Archive',
							'PosList.Delete',
							'PosConfig.Archive',
							'PosSync.Create',
							'PosSync.View',
							'PosSync.Archive',
							'PosSync.Delete',
							'Orders.Create',
							'Orders.Archive',
							'Orders.Delete',
							'Closings.Create',
							'Closings.Delete',
							'Closings.Archive',
						]}
					/>
					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="Payment"
						initialValues={role?.permissions_by_sections?.payment}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[
							{ label: 'Payment methods', value: 'PaymentMethods' },
							{
								label: 'Discounts',
								value: 'Discounts',
							},
						]}
						onChange={(values) => {
							setFieldValue('permissions.payment', values);
						}}
						// showSelectAll
						omit={['PaymentMethods.Archive', 'Discounts.Archive']}
					/>

					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="Permissions"
						initialValues={role?.permissions_by_sections?.permissions}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[
							{ label: 'Users', value: 'Users' },
							{ label: 'Roles', value: 'Roles' },
						]}
						onChange={(values) => {
							setFieldValue('permissions.permissions', values);
						}}
						// showSelectAll
						omit={['Users.Archive', 'Roles.Archive']}
					/>

					<CheckBoxGrid
						disabled={role?.is_admin}
						enableReinitialize
						title="FS Integration"
						initialValues={role?.permissions_by_sections?.fsIntegration}
						headers={[
							{ label: 'Create', value: 'Create' },
							{ label: 'Edit', value: 'Edit' },
							{ label: 'Archive', value: 'Archive' },
							{ label: 'Delete', value: 'Delete' },
							{ label: 'View', value: 'View' },
						]}
						rows={[{ label: 'BC Integration', value: 'BcIntegration' }]}
						onChange={(values) => {
							setFieldValue('permissions.fsIntegration', values);
						}}
						// showSelectAll
						omit={[
							'BcIntegration.Create',
							'BcIntegration.Archive',
							'BcIntegration.Delete',
						]}
					/>
					<Box>
						<CheckBoxGrid
							disabled={role?.is_admin}
							enableReinitialize
							title="Reports"
							initialValues={role?.permissions_by_sections?.reports}
							headers={[
								{ label: 'Download', value: 'Download' },
								{ label: 'View', value: 'View' },
							]}
							rows={[
								{ label: 'Finance', value: 'Finance' },
								{ label: 'Operations', value: 'Operations' },
							]}
							onChange={(values) => {
								setFieldValue('permissions.reports', values);
							}}
							// showSelectAll
						/>
					</Box>
					<Box>
						<CheckBoxGrid
							disabled={role?.is_admin}
							enableReinitialize
							title="Reports"
							initialValues={role?.permissions_by_sections?.analytics}
							headers={[{ label: 'View', value: 'View' }]}
							rows={[{ label: 'Analytics', value: 'Analytics' }]}
							onChange={(values) => {
								setFieldValue('permissions.analytics', values);
							}}
						/>
					</Box>
				</>
			)}
		</Stack>
	);
};

export default DashboardPanel;

import { dayjs } from '../../lib/dayjs';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';
import { Box, BoxProps, Text } from '@mantine/core';

export type LastUpdatedLabelProps = BoxProps & {
	lastUpdated: Date | string;
	editor?: string;
};

export const LastUpdatedLabel = React.forwardRef<
	HTMLDivElement,
	LastUpdatedLabelProps
>(({ lastUpdated, editor, ...rest }, ref) => {
	return (
		<Box {...rest} ref={ref}>
			<Text style={{ display: 'inline' }} size="xs" color="gray">
				Last Updated: {dayjs().to(dayjs(lastUpdated))}
			</Text>{' '}
			{editor ? (
				<Text style={{ display: 'inline' }} weight={500} size="xs" color="gray">
					by {editor}
				</Text>
			) : null}
		</Box>
	);
});

LastUpdatedLabel.displayName = 'LastUpdatedLabel';

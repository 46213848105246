import { Group } from '@mantine/core';
import React from 'react';
import Icon from '../../assets/icons';
import { EmptyState, SelectBrand, Text, ViewContainer } from '../../components';
import { SimpleDataGrid } from '../../components/SimpleDataGrid';
import { Button } from '@mantine/core';
import { useGetBrands } from '../../hooks/brands';
import { useGetCategoryGroups } from '../../hooks';
import { useNavigate } from '../../lib/navigate';
import { useAppState } from '../../providers/AppStateProvider';
import { ProductRoutes } from '../../router/constants/Products.routes';
import { Add } from '@mui/icons-material';
import { useAuth } from '../../providers/AuthProvider';
import RequirePermissions from '../../guards/RequirePermissions';

const CategoryGroups = () => {
	const { selectedCompany, selectedBrand } = useAppState();
	const { permissions } = useAuth();
	const { data: brandsData } = useGetBrands(selectedCompany!);
	const { data: response_categoryGroups } = useGetCategoryGroups({
		brandId: selectedBrand || 0,
	});

	const navigate = useNavigate();
	return (
		<ViewContainer title="Category Groups">
			<Group my="20px" align="center" position="apart">
				{/* <pre>{JSON.stringify(response_categoryGroups, null, 2)}</pre> */}

				<SelectBrand />
				<Group align="center">
					{/* <Button
						color="gray.2"
						style={{ color: 'black' }}
						variant="light"
						leftIcon={<Icon name="archive" />}
					>
						Archived
					</Button> */}
					{brandsData?.brands?.length ? (
						<RequirePermissions permissions={['CategoryGroups.Create']}>
							<Button
								leftIcon={<Icon name="add" />}
								onClick={() => navigate(ProductRoutes.CreateCategoryGroups)}
							>
								Create Category Group
							</Button>
						</RequirePermissions>	
					) : null}
				</Group>
			</Group>
			<SimpleDataGrid
				data={response_categoryGroups?.categoryGroups ?? []}
				columns={[
					{
						accessorKey: 'id',
						header: 'Name',
						cell: ({ row }) => {
							const values = row.original as any;
							return <Text>{values['name']}</Text>;
						},
					},
					{
						accessorKey: 'payment_methods',
						header: 'Payment Methods',
						cell: ({ row }) => {
							const values = row.original as any;
							const paymentMethods = values['payment_methods'];

							if (values['is_follow_pos_payment_methods']) {
								return <Text>Follow POS Configuration</Text>;
							}
							if (paymentMethods.length) {
								return <Text>{paymentMethods.join(', ')}</Text>;
							}
							return <Text>—</Text>;
						},
					},
				]}
				onClickHandler={(row) => {
					navigate(
						ProductRoutes.EditCategoryGroups.replace(
							':categoryGroupId',
							row?.original?.id
						)
					);
				}}
			/>
			{brandsData?.brands?.length === 0 ? (
				<EmptyState
					resource="brands"
					secondaryMessage="Please create Brand before creating a Category Group."
					CallToAction={
						permissions?.includes('Brands.Create') && (
							<Button
								type="button"
								leftIcon={<Add fontSize="inherit" />}
								onClick={() => navigate('/configuration/brands/create')}
							>
								Create Brand
							</Button>
						)
					}
				/>
			) : response_categoryGroups?.categoryGroups?.length === 0 ? (
				<EmptyState resource="category groups" />
			) : null}
		</ViewContainer>
	);
};

export default CategoryGroups;

import { useQuery } from 'react-query';
import { socketApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetOnlinePos() {
	const { getAccessToken } = useAuth();
	return useQuery(
		['pos-online'],
		async (): Promise<number[]> =>
			socketApi
				.get('/connections/pos', {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			keepPreviousData: true,
		}
	);
}

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
	ActionIcon,
	Box,
	BoxProps,
	Flex,
	Group,
	Pagination,
	Text,
} from '@mantine/core';
import {
	ColumnDef,
	ColumnFiltersState,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import Icon from '../assets/icons';
import { StyledRow } from './DataGrid';
import styled from '@emotion/styled';
import {
	RankingInfo,
	rankItem,
	compareItems,
} from '@tanstack/match-sorter-utils';

export type DataGridProps = BoxProps & {
	/** Data array to display in table */
	data: Record<string, any>[];
	/** Array of column definitions */
	columns: ColumnDef<unknown>[];
	onSortChange?: (sort: string | null) => void;
	onClickHandler?: (row: Row<any>) => void;
	columnVisibility?: Record<string, boolean>;
	columnFilters?: ColumnFiltersState;
	globalFilter?: string;
};

export const StyledSimpleDataGrid = styled(Box)<any>`
	border-collapse: collapse;
	table-layout: auto;
	width: 100%;

	> thead {
		background-color: ${({ theme }) => theme.colors.grey05};
		border: 3px solid ${({ theme }) => theme.colors.grey05};
		font-size: 14px;
	}

	* th {
		padding: 13px 20px;
		font-weight: 400;
		text-align: left;
	}

	tbody tr {
		border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
		position: relative;

		:hover {
			box-shadow: inset 3px 0px 0px 0px ${({ theme }) => theme.colors.primary};
			background-color: ${({ theme }) => theme.colors.grey05};
		}
	}

	* td {
		padding: 24px 20px;
	}
`;

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);

	// Store the itemRank info
	addMeta({
		itemRank,
	});

	// Return if the item should be filtered in/out
	return itemRank.passed;
};

export const SimpleDataGrid = React.forwardRef<HTMLElement, DataGridProps>(
	(
		{
			data,
			columns,
			onSortChange,
			onClickHandler,
			columnVisibility,
			columnFilters,
			globalFilter,
			...rest
		},
		ref
	) => {
		const [sorting, setSorting] = React.useState<SortingState>([]);
		const [pageIndex, setPageIndex] = React.useState<number>(0)
		const table = useReactTable({
			data,
			columns,
			filterFns: {
				fuzzy: fuzzyFilter,
			},
			state: {
				sorting,
				columnVisibility,
				columnFilters,
				globalFilter,
				pagination: {
					pageIndex:pageIndex,
					pageSize:30
				}
			},
			globalFilterFn: fuzzyFilter,
			getFilteredRowModel: getFilteredRowModel(),
			onSortingChange: setSorting,
			getPaginationRowModel: getPaginationRowModel(),
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			enableMultiRowSelection: true,
		});

		React.useEffect(() => {
			table.setPageIndex(pageIndex);
		}, [table, pageIndex]);

		React.useEffect(() => {
			if (onSortChange && sorting.length) {
				const [sortItem] = sorting;
				const _sort = `${sortItem.id}:${
					sortItem['desc'] === true ? 'desc' : 'asc'
				}`;
				onSortChange(_sort);
			}
			if (onSortChange && !sorting.length) {
				onSortChange(null);
			}
		}, [sorting]);

		return !data || !data?.length ? null : (
			<>
				<StyledSimpleDataGrid {...rest} component="table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									const _sortOrder = header.column.getIsSorted();
									const canSort = header.column.getCanSort();
									const sortIcon = () => {
										if (_sortOrder === 'asc') {
											return (
												<Icon
													name="arrowup"
													style={{ height: '15px', width: '15px' }}
												/>
											);
										} else if (_sortOrder === 'desc') {
											return (
												<Icon
													name="arrowdown"
													style={{ height: '15px', width: '15px' }}
												/>
											);
										} else {
											return (
												<Icon
													name="unfoldmore"
													style={{ height: '15px', width: '15px' }}
												/>
											);
										}
									};

									return (
										<th key={header.id}>
											<Flex direction="row" align="center">
												{flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
												{canSort ? (
													<ActionIcon
														style={{
															padding: '0',
															height: '10px',
															width: '10px',
														}}
														onClick={header.column.getToggleSortingHandler()}
													>
														{sortIcon()}
													</ActionIcon>
												) : null}
											</Flex>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{table?.getRowModel()?.rows?.map((row) => {
							return (
								<StyledRow component="tr" key={row.id}>
									{row.getVisibleCells().map((cell, idx) => (
										<td
											key={cell.id}
											onClick={() =>
												!cell.column.columnDef.meta?.isNotClickable &&
												onClickHandler &&
												onClickHandler(row)
											}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</StyledRow>
							);
						})}
					</tbody>
				</StyledSimpleDataGrid>
				<Group w="100%" position="apart" my="xl">
					<Text>
					{`Showing ${((pageIndex)*30)+1} - 
						${(table?.getRowModel()?.rows?.length < 30) 
							? table?.getRowModel()?.rows?.length+(pageIndex)*30
							: table?.getRowModel()?.rows?.length*(pageIndex+1)} 
							out of ${data.length}`}
					</Text>
					<Pagination
						total={Math.ceil(table?.getFilteredRowModel().rows.length / 30)}
						onChange={(page) => {
							setPageIndex(page - 1);
						}}
					/>
				</Group>
			</>
		);
	}
);

SimpleDataGrid.displayName = 'SimpleDatagrid';

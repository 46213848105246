import { Box, Group, Loader, Text, Title } from '@mantine/core';
import React from 'react';
import { useQuery } from 'react-query';
import { configApi } from '../../../../apis';
import { useAuth } from '../../../../providers/AuthProvider';

type Props = {
	productId?: number | string;
	productName?: string;
};

const DeleteProduct = ({ productId, productName }: Props) => {
	const { getAccessToken } = useAuth();

	const { data: links } = useQuery(
		['product', 'checklinks'],
		async () =>
			configApi
				.get(`/products/${productId}/checklinks`, {
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!productId,
		}
	);

	const hasLinks = !!links?.pos?.length;

	const title = hasLinks
		? 'This category is used in the following places, are you sure you want to delete its product?'
		: 'Are you sure you want to delete?';

	const body = hasLinks ? (
		<Box
			p="lg"
			sx={(theme) => ({
				background: theme.colors.gray[2],
				borderRadius: theme.radius.md,
			})}
			my="lg"
		>
			<Text weight={600} mb="sm">
				MENU
			</Text>
			{links?.pos?.map((e: string, i: number) => (
				<Text key={i}>{e}</Text>
			))}
		</Box>
	) : (
		<Box
			p="lg"
			sx={(theme) => ({
				background: theme.colors.gray[2],
				borderRadius: theme.radius.md,
			})}
			my="lg"
		>
			<Group>
				<Text>PRODUCT</Text>
				<Text>{productName}</Text>
			</Group>
		</Box>
	);

	return !productId ? (
		<Loader />
	) : (
		<>
			<Title order={2}>{title}</Title>
			{body}
		</>
	);
};

export default DeleteProduct;

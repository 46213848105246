import React from 'react';
import { Box, Flex, Text, Checkbox, Table, Title } from '@mantine/core';

export type CheckBoxGridProps = {
	initialValues?: string[];
	headers: { value: string; label: React.ReactNode }[];
	rows: { value: string; label: React.ReactNode }[];
	omit?: string[];
	title?: React.ReactNode;
	onChange?: (selected: string[]) => void;
	showSelectAll?: boolean;
	enableReinitialize?: boolean;
	disabled?: boolean;
	selectAll?: boolean;
};

export const CheckBoxGrid = React.forwardRef<
	HTMLTableElement,
	CheckBoxGridProps
>(
	(
		{
			title,
			headers,
			rows,
			omit,
			onChange,
			initialValues,
			enableReinitialize,
			disabled,
			selectAll,
		},
		ref
	) => {
		const [selected, setSelected] = React.useState<Record<string, boolean>>(
			initialValues?.reduce((acc, val) => {
				acc[val] = true;
				return acc;
			}, {} as any) || {}
		);

		React.useEffect(() => {
			if (initialValues && enableReinitialize) {
				setSelected(
					initialValues.reduce((acc, val) => {
						acc[val] = true;
						return acc;
					}, {} as any)
				);
			}
		}, [initialValues, enableReinitialize]);

		React.useEffect(() => {
			if (onChange) {
				const selectionsInStrings = Object.entries(selected)
					.filter(([, value]) => value)
					.map(([key]) => key);
				onChange(selectionsInStrings);
			}
		}, [selected]);

		return (
			<Table
				ref={ref}
				verticalSpacing="sm"
				horizontalSpacing="xl"
				withColumnBorders
				withBorder
				w="min-content"
			>
				<thead>
					<tr>
						<th style={{ width: '250px' }}>
							<Title order={3} my="md" w="250px">
								{title}
							</Title>
						</th>
						{headers.map((e) => (
							<th key={e.value}>
								<Flex w="80px" justify="center">
									<Text weight={600} size="sm" color="gray" align="center">
										{typeof e.label === 'string'
											? e?.label?.toUpperCase()
											: e.label}
									</Text>
								</Flex>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row) => {
						const options: string[] = [];
						return (
							<tr key={row.value}>
								<td style={{ width: '250px' }}>
									<Text weight={600} w="250px">
										{row?.label}
									</Text>
								</td>

								{headers.map((header) => {
									const option = row.value + '.' + header.value;
									options.push(option);

									const isOmitted = omit?.includes(option);
									return (
										<Box
											key={option}
											component="td"
											sx={(theme) => ({
												width: '70px',
												background: isOmitted
													? theme.colors.gray[1]
													: 'inherit',
											})}
										>
											<Flex w="100%" justify="center">
												{!isOmitted ? (
													<Checkbox
														disabled={disabled}
														checked={selectAll || selected[option]}
														onChange={(e) =>
															setSelected((p) => ({
																...p,
																[option]: e.currentTarget.checked,
															}))
														}
													/>
												) : null}
											</Flex>
										</Box>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
		);
	}
);

CheckBoxGrid.displayName = 'CheckBoxGrid';

import { useQuery } from 'react-query';
import { authApi } from '../../../apis';
import { useAuth } from '../../../providers/AuthProvider';

export function useGetRolesByCompany({
	companyId,
	includePermissions,
}: {
	companyId: string | number;
	includePermissions?: boolean;
}) {
	const { getAccessToken } = useAuth();
	return useQuery(
		['roles', 'companyId', companyId],
		async () =>
			authApi
				.get(`/roles`, {
					params: {
						company_id: companyId,
						include_permissions: includePermissions,
					},
					headers: {
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				})
				.then(({ data }) => data),
		{
			enabled: !!companyId,
			refetchOnWindowFocus: false,
		}
	);
}
